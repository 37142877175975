import billingInitialState from './Overview/config/initialState';
import typesBilling from './Overview/config/constant';
import * as commonReducerBilling from './Overview/config/reducerGenerator';

const INITIAL_STATE = ({
  overview: { ...billingInitialState() }
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region Billing
    case typesBilling.MANAGER_BILLING_CHECK_CEP_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_CHECK_CEP_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_CHECK_CEP_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_CHECK_CEP_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_CHECK_CEP_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_CHECK_CEP_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_LOAD_INFO_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_LOAD_INFO_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_LOAD_INFO_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_LOAD_INFO_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_LOAD_INFO_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_LOAD_INFO_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_ADD_CARD_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_ADD_CARD_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_ADD_CARD_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_ADD_CARD_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_ADD_CARD_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_ADD_CARD_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_UPDATE_CONFIG_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_UPDATE_CONFIG_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_CONFIG_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_UPDATE_CONFIG_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_CONFIG_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_UPDATE_CONFIG_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_UPDATE_DATA_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_UPDATE_DATA_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_DATA_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_UPDATE_DATA_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_DATA_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_UPDATE_DATA_FETCH_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_PAY_NOW_REQUEST: return commonReducerBilling.MANAGER_BILLING_PAY_NOW_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_PAY_NOW_ERROR: return commonReducerBilling.MANAGER_BILLING_PAY_NOW_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_PAY_NOW_SUCCESS: return commonReducerBilling.MANAGER_BILLING_PAY_NOW_SUCCESS(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_SUCCESS(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_REQUEST: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_REQUEST(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_ERROR: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_ERROR(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_SUCCESS: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_SUCCESS(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_TEMP: return commonReducerBilling.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_TEMP(typesBilling.SECTION, state, action);

    case typesBilling.MANAGER_BILLING_SET_USER_COUNT: return commonReducerBilling.MANAGER_BILLING_SET_USER_COUNT(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_PACKAGE_DATA: return commonReducerBilling.MANAGER_BILLING_SET_PACKAGE_DATA(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_PAYMENT_METHOD: return commonReducerBilling.MANAGER_BILLING_SET_PAYMENT_METHOD(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_PAYMENT_NEW_METHOD: return commonReducerBilling.MANAGER_BILLING_SET_PAYMENT_NEW_METHOD(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_BILLING_DATA: return commonReducerBilling.MANAGER_BILLING_SET_BILLING_DATA(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_BILLING_CONFIG: return commonReducerBilling.MANAGER_BILLING_SET_BILLING_CONFIG(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_PAYMENT_HISTORY: return commonReducerBilling.MANAGER_BILLING_SET_PAYMENT_HISTORY(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_TAB_ACTIVE: return commonReducerBilling.MANAGER_BILLING_SET_TAB_ACTIVE(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_RESET_STATE: return commonReducerBilling.MANAGER_BILLING_RESET_STATE(typesBilling.SECTION, state, action);
    case typesBilling.MANAGER_BILLING_SET_SECTION_DRAWER: return commonReducerBilling.MANAGER_BILLING_SET_SECTION_DRAWER(typesBilling.SECTION, state, action);
    //#endregion

    default:
      return state;
  }
};