export const PERFIL_CONFIGURATION_SECTIONS = (state, action) => {
  const { drawerState } = action.payload;
  return ({
    ...state,
    drawer: {
      ...state.drawer,
      ...drawerState,
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_TOGGLE_DRAWER = (state, action) => {

  return ({
    ...state,
      drawer: {
        ...state.drawer,
        isDone: !state.drawer.isDone,
      }
  });
}

export const OPEN_PERFIL_CONFIGURATION_MEDICALRECORD_TOGGLE_DRAWER = (state, action) => {
  return ({
    ...state,
      drawer: {
        ...state.drawer,
        isDone: action.payload,
      }
  });
}