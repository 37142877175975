import MomentTimeZone from 'moment-timezone';

window.MomentTimeZone = MomentTimeZone;

export default () => {
  if (
    Intl &&
    typeof Intl.DateTimeFormat === 'function' &&
    typeof Intl.DateTimeFormat().resolvedOptions === 'function' &&
    Intl.DateTimeFormat().resolvedOptions().timeZone
  ) {
    const _timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    MomentTimeZone.tz.setDefault(_timezone);
  }
};