export default () => ({
  isExitClient: false,
  enableSave: false,

  fetching: {
    getSearchClient: false,
    getClientById: false,
    getSocialBond: false,
    saveNewAvatar: false,
    postClient: false,
    postSocialBond: false,
    deletingSocialBond: false
  },

  api: {
    patientId: 0,
    patientName: '',
    shouldDestroyModal: false,
    bondDialog: {
      client: false,
      create: false,
      update: false
    },
    bondProfileViewed: {},
    listSocialBonds: [],
    socialBond: {
      socialBondId: 0,
      name: null,
      email: null,
      cpf: null,
      tel: null,
      cel: null,
      isResponsible: false,
      isBureaucraticallyResponsible: false
    },

    generalData: {
      name: null,
      email: null,
      maritalStatus: 0,
      profession: null,
      sex: null,
      birthdate: null,
      cpf: null,
      avatar: null
    },
    address: {
      cep: null,
      address: null,
      addressNumber: null,
      addressComplement: null,
      neighborhood: null,
      stateId: 0,
      cityId: 0
    }
  },

  sections: {
    done: true,
    activeKey: 'tab:general'
  }
});
