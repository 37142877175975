/*eslint-disable no-throw-literal*/
import axios from 'axios';
import interceptor from './interceptor';

import * as types from '@App/constants/endpoints';

const _axios = interceptor();

const LOGIN = 'login';
const AUTH = 'auth';
const AUTO = 'auto';
const TOKEN = 'token';
const VERIFY = 'verify';

export const signIn = ({ email, password, gRecaptchaResponse }) => {
  // const pathUrl = `${types.API_AUTH}/${LOGIN}`;
  const pathUrl = `${types.API_AUTH}/${LOGIN}/recaptchaVerified`;
  const params = { email, password, gRecaptchaResponse };

  return new Promise((resolve, reject) => {
    axios
      .post(pathUrl, params)
      .then(response => {
        if (response && response.data && response.data.success) {
          resolve({ ...response.data.data, token: response.data.token });
        } else {
          reject({ status: 401, message: 'Login inválido!' });
        }
      })
      .catch(reject);
  });
};

export const verifyToken = token => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      url: `${types.API_AUTH}/${AUTH}`,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    axios(options)
      .then(response => resolve(response.data))
      .catch(reject);
  });
};

export const createAccount = params => {
  const pathUrl = `${types.API_ACCOUNT}/user`;
  return new Promise((resolve, reject) => {
    axios
      .post(pathUrl, params)
      .then(response => {
        if (!response || !response.data || !response.data.success) {
          if (response.data.response.status === 401) {
            reject(response.data.errorMsg);
            return;
          }
          reject({ message: 'Esta empresa já existe!', redirect: false });
          return;
        }

        resolve(response.data);
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          reject({
            message:
              'Já existe um usuário com este CPF ou E-mail. Faça Login com seu usuário e senha cadastrados',
            redirect: true
          });
          return;
        }
        reject();
      });
  });
};

export const tokenLogin = token => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'POST',
      url: `${types.API_AUTH}/${LOGIN}/${AUTO}/${TOKEN}`,
      headers: { Authorization: `Bearer ${token}` }
    };

    axios(options)
      .then(response =>
        resolve({ ...response.data.data, token: response.data.token })
      )
      .catch(reject);
  });
};

export const autoLogin = async ({ appId }) => {
  const pathUrl = `${types.API_AUTH}/${LOGIN}/${AUTO}/${TOKEN}/${appId}`;
  const params = {};

  try {
    let response = await _axios.post(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const verifyRecoverToken = async params => {
  const pathUrl = `${types.API_AUTH}/${LOGIN}/${TOKEN}/${VERIFY}`;

  try {
    let response = await _axios.post(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
        name: error.response.data.name
      };
    throw error;
  }
};
