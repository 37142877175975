export default ({
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_REFERRAL_CREATE : 'modal:perfil:configuration:medicalRecord:referral:create',
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_REFERRAL_UPDATE : 'modal:perfil:configuration:medicalRecord:referral:update',
  SET_REFERRAL_NAME: 'SET_REFERRAL_NAME',
  SET_REFERRAL_DESCRIPTION: 'SET_REFERRAL_DESCRIPTION',
  LIST_REFERRALS_FETCH_REQUEST: 'LIST_REFERRALS_FETCH_REQUEST',
  LIST_REFERRALS_FETCH_ERROR: 'LIST_REFERRALS_FETCH_ERROR',
  LIST_REFERRALS_FETCH_SUCCESS: 'LIST_REFERRALS_FETCH_SUCCESS',
  CREATE_REFERRAL_FETCH_REQUEST: 'CREATE_REFERRAL_FETCH_REQUEST',
  CREATE_REFERRAL_FETCH_ERROR: 'CREATE_REFERRAL_FETCH_ERROR',
  CREATE_REFERRAL_FETCH_SUCCESS: 'CREATE_REFERRAL_FETCH_SUCCESS',
  UPDATE_REFERRAL_FETCH_REQUEST: 'UPDATE_REFERRAL_FETCH_REQUEST',
  UPDATE_REFERRAL_FETCH_ERROR: 'UPDATE_REFERRAL_FETCH_ERROR',
  UPDATE_REFERRAL_FETCH_SUCCESS: 'UPDATE_REFERRAL_FETCH_SUCCESS',
  GET_REFERRAL_FETCH_REQUEST: 'GET_REFERRAL_FETCH_REQUEST',
  GET_REFERRAL_FETCH_ERROR: 'GET_REFERRAL_FETCH_ERROR',
  GET_REFERRAL_FETCH_SUCCESS: 'GET_REFERRAL_FETCH_SUCCESS',
  PUSH_NEW_REFERRAL_TO_LIST: 'PUSH_NEW_REFERRAL_TO_LIST',
  RESET_REFERRAL_FORM: 'RESET_REFERRAL_FORM',
  UPDATE_ITEM_ON_LIST: 'UPDATE_ITEM_ON_LIST'
});