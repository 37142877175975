import { combineReducers } from 'redux';
import { reducer as FormRFReducer } from 'redux-form';
import { reducer as ToastrReducer } from 'react-redux-toastr';

import ConfigReducer from './config';

import { history } from '../store';

import MainReducer from '@Common/main/reducer';
import SnackBarReducer from '@Common/snackBar/reducer';
import ModalReducer from '@Common/modal/reducer';
import CardReducer from '@Common/card/reducer';
import NoPermissionDialogReducer from '@Common/NoPermissionDialog/reducer';
import ScoresFieldReferenceDataDialogReducer from '@Common/ScoresFieldReferenceDataDialog/reducer';
import FieldModelDialogReducer from '@App/common/FieldModelDialog/reducer';

import LoginReducer from '@Components/login/reducer';
import IntegrationsReducer from '@Components/integrations/reducer';
import UserReducer from '@Components/users/reducer';
import StartedReducer from '@Components/started/reducer';
import PurchaseReducer from '@Components/purchase/config/reducer';
import BusinessReducer from '@Components/managerBusiness/reducer';
import WorkspacesReducer from '@Components/workspaces/reducer';
import InviteReducer from '@Components/invite/open/reducer';
import PlansReducer from '@Components/plans/reducer';
import PerfilReducer from '@Components/perfil/reducer';
import PermissionReducer from '@Components/permission/reducer';
import ConfigurationReducer from '@Components/configuration/reducer';
import ClientReducer from '@Components/client/reducer';
import BillingReducer from '@Components/billing/reducer';
import ModalFieldsReducer from '@Components/ModalFields/reducer';
import PrescriptionModel from '@Components/perfil/Configuration/MedicalRecord/Prescription/config/reducer';
import CertificateModel from '@Components/perfil/Configuration/MedicalRecord/Certificate/config/reducer';
import ReferralModel from '@Components/perfil/Configuration/MedicalRecord/Referral/config/reducer';
import ReportModel from '@Components/perfil/Configuration/MedicalRecord/Report/config/reducer';
import RequestModel from '@Components/perfil/Configuration/MedicalRecord/Request/config/reducer';
import MedicalRecordReducer from '@Components/medicalRecord/reducer';
import DashboardReducer from '@Components/dashboard/reducer';
import FormEngineReducer from '@Components/common/formEngine/reducer';
import PdfReducer from '@Components/pdfGenerator/reducer';
import Documents from '@App/components/documents/reducer';
import Reports from '@App/components/reports/reducer';
import dashboardData from '@App/components/dashboardData/reducer';

import { connectRouter } from 'connected-react-router';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    billing: BillingReducer,
    business: BusinessReducer,
    cardState: CardReducer,
    clientState: ClientReducer,
    config: ConfigReducer,
    configuration: ConfigurationReducer,
    dashboard: DashboardReducer,
    form: FormRFReducer,
    formEngine: FormEngineReducer,
    integrations: IntegrationsReducer,
    invite: InviteReducer,
    login: LoginReducer,
    main: MainReducer,
    medicalRecordState: MedicalRecordReducer,
    medicalRecordsModelPrescription: PrescriptionModel,
    medicalRecordsModelCertificate: CertificateModel,
    medicalRecordsModelReferral: ReferralModel,
    medicalRecordsModelReport: ReportModel,
    medicalRecordsModelRequest: RequestModel,
    modalField: ModalFieldsReducer,
    modalState: ModalReducer,
    perfil: PerfilReducer,
    permission: PermissionReducer,
    permissionDialog: NoPermissionDialogReducer,
    scoresReferenceDialog: ScoresFieldReferenceDataDialogReducer,
    fieldModelReferenceDialog: FieldModelDialogReducer,
    plans: PlansReducer,
    purchase: PurchaseReducer,
    snackbar: SnackBarReducer,
    started: StartedReducer,
    toastr: ToastrReducer,
    user: UserReducer,
    workspaces: WorkspacesReducer,
    pdf: PdfReducer,
    documents: Documents,
    reports: Reports,
    dashboardData: dashboardData,
  });

export default createRootReducer;
