export default ({
  PDF_SETTINGS_SET_HEADER: 'PDF_SETTINGS_SET_HEADER',
  PDF_SETTINGS_CHANGE_CONFIG: 'PDF_SETTINGS_CHANGE_CONFIG',
  PDF_SETTINGS_SET_FOOTER: 'PDF_SETTINGS_SET_FOOTER',
  PDF_SETTINGS_SET_VIEW_PATIENT: 'PDF_SETTINGS_SET_VIEW_PATIENT',

  PDF_SETTINGS_FETCH_REQUEST: 'PDF_SETTINGS_FETCH_REQUEST',
  PDF_SETTINGS_FETCH_SUCCESS: 'PDF_SETTINGS_FETCH_SUCCESS',
  PDF_SETTINGS_FETCH_FAILED: 'PDF_SETTINGS_FETCH_FAILED',
  PDF_SETTINGS_SAVE_SUCCESS: 'PDF_SETTINGS_SAVE_SUCCESS',
  PDF_SETTINGS_SAVE_FAILED: 'PDF_SETTINGS_SAVE_FAILED',
  PDF_SETTINGS_SAVE_REQUEST: 'PDF_SETTINGS_SAVE_REQUEST',

  GET_PDF_INFO_SUCCESS: 'GET_PDF_INFO_SUCCESS',
  GET_PDF_INFO_FAILED: 'GET_PDF_INFO_FAILED',
  GET_PDF_INFO_REQUEST: 'GET_PDF_INFO_REQUEST',
  
  RESET_STATE: 'RESET_STATE'
})