export default {
  isFirstLoading: false,
  error: {
    done: false,
    messages: []
  },
  fetching: {
    stateAndCity: false,
    stateAndCityError: false,
    sendInvite: false,
    sendInviteError: false,
    workspaces: false,
    workspacesError: false,
    createProfessional: false,
    isCorrectingInvite: false,
    isCreateWorkspaceError: false,
    isCreateWorkspace: false
  },
  api: {
    perfilWorkspace: [],
    perfilAccount: [],
    listCityIndexed: {},
    listState: [],
    listWorkspace: [],
    councils: [],
    business: {
      id: 0,
      name: ''
    },
    emailCorrected: false,
    searchedUser: {
      hasInvite: false,
      inviteEmail: false,
      data: {
        id: null,
        name: null,
        email: null,
        sex: null,
        cpf: null,
        photo: null,
        cityId: null,
        stateId: null,
        cel: null,
        tel: null,
        birthdate: null,
        complement: null,
        address: null,
        numberAddress: null,
        neighborhood: null,
        cep: null,
        active: null
      }
    }
  }
};
