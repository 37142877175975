import initialStateOverview from './overview/initialState';
import types                from './constant';

const INITIAL_STATE = {
  overview: {...initialStateOverview}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.STARTED_OVERVIEW_IS_FIRST_LOADING: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          isFirstLoading: action.payload
        }
      });
    }
    case types.STARTED_OVERVIEW_MODAL_OPENED: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          modal: {
            ...state.overview.modal,
            done: true
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_MODAL_CLOSED: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          modal:{
            ...state.overview.modal,
            done: false
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_REQUEST: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          fetching: {
            ...state.overview.fetching,
            isUserDashboard: true,
            isUserDashboardError: false
          },
          api: {
            ...state.overview.api,
            listWorkspace: [],
            listAccount: []
          },
          error: {
            done: false,
            message: []
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_ERROR: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          isFirstLoading: false,
          fetching: {
            ...state.overview.fetching,
            isUserDashboard: false,
            isUserDashboardError: true
          },
          error: {
            done: true,
            message: [
              ...state.overview.error.message,
              action.payload.message
            ]
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_SUCCESS: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          isFirstLoading: false,
          fetching: {
            ...state.overview.fetching,
            isUserDashboard: false
          },
          api: {
            ...state.overview.api,
            listWorkspace: action.payload.listWorkspace,
            listAccount: action.payload.listAccount
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_REQUEST: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          fetching: {
            ...state.overview.fetching,
            isCreateAccount: true,
            isCreateAccountError: false
          },
          error: {
            done: false,
            message: []
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_ERROR: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          fetching: {
            ...state.overview.fetching,
            isCreateAccount: false,
            isCreateAccountError: true
          },
          error: {
            ...state.overview.error,
            done: true,
            message: [
              ...state.overview.error.message,
              action.payload.message
            ]
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_SUCCESS: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          fetching: {
            ...state.overview.fetching,
            isCreateAccount: false
          },
          modal:{
            ...state.overview.modal,
            done: false
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_REQUEST: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          fetching: {
            ...state.overview.fetching,
            isSelectedBusiness: true,
            isSelectedBusinessError: false
          },
          error: {
            done: false,
            message: []
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_ERROR: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          fetching: {
            ...state.overview.fetching,
            isSelectedBusiness: false,
            isSelectedBusinessError: true
          },
          error: {
            done: true,
            message: [
              ...state.overview.error.message,
              action.payload.message
            ]
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_SUCCESS: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          isFirstLoading: false,
          fetching: {
            ...state.overview.fetching,
            isSelectedBusiness: false
          },
          business: {
            ...state.overview.business,
            ...action.payload
          }
        }
      });
    }
    case types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS: {
      return ({
        ...state,
        overview: {
          ...state.overview,
          business: {
            ...state.overview.business,
            ...action.payload
          }
        }
      });
    }
    default:
      return state;
  }
};