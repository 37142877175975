export default ({
  SECTION : 'overview',

  CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_REQUEST: 'CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_REQUEST',
  CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_SUCCESS : 'CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_SUCCESS',
  CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_ERROR   : 'CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_ERROR',

  CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_REQUEST: 'CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_REQUEST',
  CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_SUCCESS : 'CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_SUCCESS',
  CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_ERROR   : 'CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_ERROR',

  CLIENT_OVERVIEW_SET_RESET_SEARCH_CLIENT : 'CLIENT_OVERVIEW_SET_RESET_SEARCH_CLIENT',

  CLIENT_OVERVIEW_RESET_STATE : 'CLIENT_OVERVIEW_RESET_STATE',
});
