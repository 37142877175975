import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles }         from '@material-ui/core/styles';
import { toastr }             from 'react-redux-toastr';
import Grid                   from '@material-ui/core/Grid';
import Paper                  from '@material-ui/core/Paper';

import { styles }             from './style';
import * as actions           from './action';
import selector               from '../selector';
import * as actionsConfig     from '@Actions/config';

import Header                 from './header';
import FormUser               from './formUser';

class Register extends Component {
  state = { recaptchaToken: null };

  render () {
    const { classes, estado, city, fetching } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.item}>
          <div className={classes.contentForms}>
            <Paper className={classes.control}>
              <Header classes={classes} />
              <Grid container direction={'column'} alignItems='center' className={classes.rootSteps}>
                <Grid item >
                  <span className={classes.headerFormActive}>{'Informações do Usuário'}</span>
                </Grid>

                <Grid item>
                  <FormUser
                    classes={classes}
                    onSubmit={this.onSubmit}
                    fetching={fetching}
                    dataSource={{ estado, city }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  }

  componentDidMount () {
    this.fetchAll();
    
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const prevFetching    = prevProps.fetching;
    const currentFetching = this.props.fetching;
    const errorRegister   = this.props.errorRegister;

    if (currentFetching.createAccountError && currentFetching.createAccountError !== prevFetching.createAccountError && errorRegister.done) {
      errorRegister.message.forEach(msg => toastr.error('Atenção', msg, { preventDuplicates: false }));
    }
  }

  onSubmit = (values) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const invite = params.get('invite');    
    this.props.createAccount(invite, values.gRecaptchaResponse1, values.gRecaptchaResponse2);
  }

  onClickBackLogin = () => {
    this.props.hideShowVerifyEmail();
    this.props.redirectRoute('/');
  }

  onClickResendEmail = () => {
    this.props.resendEmail();
  }

  fetchAll () {
    this.props.getState();
    this.props.getCity();
  }
};

const mapStateToProps = state => ({
  estado                : selector.getState(state),
  city                  : selector.getCity(state),
  errorRegister         : selector.getMessageError(state),
  isShowVerifyEmail     : selector.getIsShowVerifyEmail(state),
  fetching : {
    createAccount         : selector.getFetchingCreateAccount(state),
    createAccountError    : selector.getFetchingCreateAccountError(state),
    resendEmail           : selector.getFetchingResendEmail(state)
  }
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getState            : actions.getState,
  getCity             : actions.getCity,
  createAccount       : actions.createAccount,
  hideShowVerifyEmail : actions.hideShowVerifyEmail,
  resendEmail         : actions.resendEmail,
  redirectRoute       : actionsConfig.routeRedirect,
}, dispatch);

Register = withStyles(styles)(Register);
Register = connect(mapStateToProps, mapDispatchToProps)(Register);

export default Register;