import moment         from 'moment';
import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';
import { put }        from '../util';
import { del }        from '../util';

const MEDICALRECORD = 'medicalrecords';
const ATTENDANCE    = 'attendance';
const PRESCRIPTION  = 'prescription';
const CERTIFICATE   = 'certificate';
const REPORT        = 'report';
const REQUEST       = 'request';
const REFERRAL      = 'referral';
const MODEL         = 'model';
const TIMELINE      = 'timeline';
const SECTION       = 'section';
const RELEASE       = 'release';
const PATIENT       = 'patient';
const HISTORY       = 'history';
const MEDIA         = 'media';
const ATTACHMENT    = 'attachment';
const EXAM          = 'exam';
const INFO          = 'info';
const ALBUM         = 'album';
const PHOTO         = 'photo';
const VIDEO         = 'video';
const CONFIG        = 'config';
const SHARING       = 'sharing';
const DELTA         = 'delta';
const FILTER        = 'filter';
const SECTIONS      = 'sections';
const SEND          = 'send'; 
const FORM          = 'form';
const ANSWER        = 'answer';
const FORMS         = 'forms';
const CORELINE      = 'coreline';
// const IMAGE         = 'image';
// const FILE          = 'file';


//#region [GET]
export const loadMainTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${TIMELINE}`;
  pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const getMedicalRecordsAttendance = async ({ header, query }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REPORT}/${FILTER}${query}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const getSectionsMedicalRecordsConfig = async ({ header }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SECTIONS}/${CONFIG}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const createAttendanceAnswerPatient = async ({ header, params}) =>  {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SEND}/${FORM}`;

  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const getAttendanceAnswerPatient = async ({ header, patientId}) =>  {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${ANSWER}/${PATIENT}/${patientId}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const getAttendanceAnswerPatientById = async ({ header, attendanceAnswerId}) =>  { 
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${FORMS}/${ANSWER}/${attendanceAnswerId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
}


export const loadCertificateTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${CERTIFICATE}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadPrescriptionTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${PRESCRIPTION}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadReferralTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${REFERRAL}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadReportTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${REPORT}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadRequestTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${REQUEST}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadExamTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${EXAM}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadMediaTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${MEDIA}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadAttachmentTimeline = async ({ header, patientId, lastLoadedPage }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${ATTACHMENT}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const loadSectionTimeline = async ({ header, patientId, lastLoadedPage, sectionName }) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${SECTION}/${HISTORY}`;
  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}&sectionName=${sectionName}`;
  else pathUrl = `${pathUrl}?&sectionName=${sectionName}`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAttendanceById = async ({header, attendanceId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${attendanceId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getPrescriptionModelById = async ({header, medicalRecordsAttendanceId, prescriptionModelId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${PRESCRIPTION}/${MODEL}/${prescriptionModelId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getCertificateModelById = async ({header, medicalRecordsAttendanceId, certificateModelId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${CERTIFICATE}/${MODEL}/${certificateModelId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getReportModelById = async ({header, medicalRecordsAttendanceId, reportModelId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${REPORT}/${MODEL}/${reportModelId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getRequestModelById = async ({header, medicalRecordsAttendanceId, requestModelId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${REQUEST}/${MODEL}/${requestModelId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getReferralModelById = async ({header, medicalRecordsAttendanceId, referralModelId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${REFERRAL}/${MODEL}/${referralModelId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAttendancesByPatientId = async ({header, patientId, lastLoadedPage}) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${ATTENDANCE}s`;

  if (lastLoadedPage) pathUrl += `?lastLoadedPage=${lastLoadedPage}`;

  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAlbumPhoto = async ({header, patientId, lastLoadedPage}) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${ALBUM}/${PHOTO}`;

  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;

  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAlbumVideo = async ({header, patientId, lastLoadedPage}) => {
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PATIENT}/${patientId}/${ALBUM}/${VIDEO}`;

  if (lastLoadedPage) pathUrl = `${pathUrl}?lastLoadedPage=${lastLoadedPage}`;


  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getMedicalRecordsAttendancePrescriptionDelta = async ({header, medicalRecordsAttendanceId, medicalRecordsAttendancePrescriptionId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${PRESCRIPTION}/${medicalRecordsAttendancePrescriptionId}/${DELTA}`;
  const axios   = interceptor(header);
  const request = await get(axios, pathUrl);
  return request;
};

export const getMedicalRecordsAttendanceCertificateDelta = async ({header, medicalRecordsAttendanceId, medicalRecordsAttendanceCertificateId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${CERTIFICATE}/${medicalRecordsAttendanceCertificateId}/${DELTA}`;
  const axios   = interceptor(header);
  const request = await get(axios, pathUrl);
  return request;
};

export const getMedicalRecordsAttendanceReferralDelta = async ({header, medicalRecordsAttendanceId, medicalRecordsAttendanceReferralId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${REFERRAL}/${medicalRecordsAttendanceReferralId}/${DELTA}`;
  const axios   = interceptor(header);
  const request = await get(axios, pathUrl);
  return request;
};

export const getMedicalRecordsAttendanceRequestDelta = async ({header, medicalRecordsAttendanceId, medicalRecordsAttendanceRequestId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${REQUEST}/${medicalRecordsAttendanceRequestId}/${DELTA}`;
  const axios   = interceptor(header);
  const request = await get(axios, pathUrl);
  return request;
};

export const getMedicalRecordsAttendanceReportDelta = async ({header, medicalRecordsAttendanceId, medicalRecordsAttendanceReportId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}/${REPORT}/${medicalRecordsAttendanceReportId}/${DELTA}`;
  const axios   = interceptor(header);
  const request = await get(axios, pathUrl);
  return request;
};

//#endregion

//#region [POST]
export const postAttendance = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendancePrescription = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PRESCRIPTION}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceCertificate = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${CERTIFICATE}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceReport = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REPORT}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceRequest = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REQUEST}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceReferral = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REFERRAL}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceSection = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SECTION}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceSectionAnswerPatient = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SECTION}/${ANSWER}/${PATIENT}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAttendanceExam = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${EXAM}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postPrescriptionInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PRESCRIPTION}/${INFO}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postCertificateInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${CERTIFICATE}/${INFO}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postReferralInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REFERRAL}/${INFO}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postRequestInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REQUEST}/${INFO}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postReportInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REPORT}/${INFO}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postSectionInfo = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SECTION}/${INFO}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region [PUT]
export const putAttendancePrescription = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PRESCRIPTION}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceCertificate = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${CERTIFICATE}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceReport = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REPORT}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceRequest = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REQUEST}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceReferral = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REFERRAL}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceSection = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SECTION}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceCoreLine = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${CORELINE}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceSectionAnswerPatient = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${SECTION}/${ANSWER}/${PATIENT}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};


export const putAttendanceRelease = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${RELEASE}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceAnswerPatientRelease = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${ANSWER}/${PATIENT}/${RELEASE}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceMedia = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${MEDIA}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceExam = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${EXAM}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putMedicalRecordsAttendanceShareConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${CONFIG}/${SHARING}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAttendanceAnswerPatientById = async ({ header, params, attendanceAnswerId}) =>  { 
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${ANSWER}/${attendanceAnswerId}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
}
//#endregion

//#region [DELETE]
export const delAttendance = async ({header, params, medicalRecordsAttendanceId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${medicalRecordsAttendanceId}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const deleteAttendanceAnswerPatientById = async ({ header, attendanceAnswerId}) =>  { 
  let pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${ANSWER}/${attendanceAnswerId}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl);
}

export const delAttendancePrescription = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${PRESCRIPTION}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const delAttendanceCertificate = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${CERTIFICATE}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const delAttendanceReport = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REPORT}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const delAttendanceRequest = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REQUEST}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const delAttendanceReferral = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${REFERRAL}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const delAttendanceExam = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ATTENDANCE}/${EXAM}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion