import userOverviewInitialState         from './Overview/initialState';
import userCreateInitialState           from './Create/initialState';
import updateInitialState               from './Update/initialState';

import typesOverview                    from './Overview/constant';
import typesCreate                      from './Create/constant';
import typesUpdate                      from './Update/constant';

import * as commonReducerUserOverview   from './Overview/reducerGenerator';
import * as commonReducerUserCreate     from './Create/reducerGenerator';
import * as commonReducerUpdate         from './Update/reducerGenerator';

const INITIAL_STATE = {
  overview  : {...userOverviewInitialState},
  create    : {...userCreateInitialState},
  update    : {...updateInitialState}
};

export default (state = INITIAL_STATE, action) => {
  if (typeof commonReducerUserOverview[action.type] === 'function') return commonReducerUserOverview[action.type](typesOverview.SECTION, state, action);
  else if (typeof commonReducerUserCreate[action.type] === 'function') return commonReducerUserCreate[action.type](typesCreate.SECTION, state, action);
  else if (typeof commonReducerUpdate[action.type] === 'function') return commonReducerUpdate[action.type](typesUpdate.SECTION, state, action);
  else return state;
};