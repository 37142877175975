const initialStateSignIn = {
  message: '',
  fetching : false,
  fetchingError: false,
  authenticated: false,
  checkedLocalStorage: false,
  api: {
    signIn: {
      userId    : 0,
      userName  : '',
      token     : '',
      email     : '',
      companys  : []
    }
  },
  validation : {
    email           : false,
    emailFormat     : false,
    password        : false,
    passwordFormat  : false
  }
};

export default initialStateSignIn;