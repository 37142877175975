export default {
  fields: {
    fetching: {
      getFields: false,
      getFieldModelById: false,
    },
    api: {
      tableFields: [],
    },
  },
  fieldsFromManager: {
    fetching: {
      getFieldModelById: false,
      getFieldsFromManager: false,
    },
    api: {
      tableFieldsFromManager: [],
    },
  },
};
