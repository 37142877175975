import initialState from './initialState';
const _initialState = initialState();

export const MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingInfo: true
      }
    }
  };
};

export const MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingInfo: false
      }
    }
  };
};

export const MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingInfo: false
      }
    }
  };
};

export const MANAGER_BILLING_LOAD_INFO_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingInfo: true
      }
    }
  };
};

export const MANAGER_BILLING_LOAD_INFO_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingInfo: false
      }
    }
  };
};

export const MANAGER_BILLING_LOAD_INFO_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingInfo: false
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_CONFIG_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_CONFIG_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_CONFIG_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: false
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_DATA_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_DATA_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_DATA_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: false
      }
    }
  };
};

export const MANAGER_BILLING_ADD_CARD_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        creatingCard: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_ADD_CARD_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        creatingCard: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_ADD_CARD_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        creatingCard: false
      }
    }
  };
};

export const MANAGER_BILLING_GET_PLANS_MODEL_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingPlans: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_GET_PLANS_MODEL_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingPlans: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_GET_PLANS_MODEL_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        loadingPlans: false
      },
      api: {
        ...state[section].api,
        plans: action.payload
      }
    }
  };
};

export const MANAGER_BILLING_CHECK_CEP_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isCheckingCep: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_CHECK_CEP_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isCheckingCep: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_CHECK_CEP_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isCheckingCep: false
      },
      billingData: {
        ...state[section].billingData,
        zipcode: action.payload.cep || null,
        address: action.payload.logradouro || null,
        neighborhood: action.payload.bairro || null,
        state: action.payload.estado || null,
        city: action.payload.cidade || null
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingData: false
      },
      billingConfig: {
        ...state[section].billingConfig,
        accountPackage: {
          ...state[section].billingConfig.accountPackage,
          defaultUsersLimit: action.payload
        }
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isSolicitingChangeNumUsers: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      changeUserSolicitation: {
        ...state[section].changeUserSolicitation,
        ...action.payload
      },
      fetching: {
        ...state[section].fetching,
        isSolicitingChangeNumUsers: false
      }
    }
  };
};


export const MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_TEMP = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      changeUserSolicitation: {
        ...state[section].changeUserSolicitation,
        tempUserQuantity: action.payload
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingPackage: true
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingPackage: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        updatingPackage: false
      }
    }
  };
};

export const MANAGER_BILLING_SET_PAYMENT_NEW_METHOD = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      newBillingConfig: {
        ...state[section].newBillingConfig,
        ...state[section].billingConfig
      }
    }
  };
};

export const MANAGER_BILLING_SET_PAYMENT_METHOD = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      newBillingConfig: {
        ...state[section].newBillingConfig,
        paymentMethodTypeId: action.payload.paymentMethodTypeId
          ? action.payload.paymentMethodTypeId
          : state[section].newBillingConfig.paymentMethodTypeId,
        editEnabled: action.payload.editEnabled
          ? action.payload.editEnabled
          : state[section].newBillingConfig.editEnabled,
        card: action.payload.card
          ? { ...state[section].newBillingConfig.card, ...action.payload.card }
          : { ...state[section].newBillingConfig.card }
      }
    }
  };
};

export const MANAGER_BILLING_SET_PACKAGE_DATA = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      packageData: {
        ...state[section].packageData,
        ...action.payload
      }
    }
  };
};

export const MANAGER_BILLING_SET_BILLING_DATA = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      billingData: {
        ...state[section].billingData,
        ...action.payload
      }
    }
  };
};

export const MANAGER_BILLING_SET_BILLING_CONFIG = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      billingConfig: {
        // ...state[section].billingConfig,
        ...action.payload.billingConfig,
        card: {
          ...action.payload.billingConfig.card,
          /*expirationMonth:
            action.payload.billingConfig.card.expirationMonth < 9
              ? `0${action.payload.billingConfig.card.expirationMonth}`
              : `${action.payload.billingConfig.card.expirationMonth}`*/
        }
      }
    }
  };
};

export const MANAGER_BILLING_SET_PAYMENT_HISTORY = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      paymentHistory: action.payload
    }
  };
};

export const MANAGER_BILLING_RESET_STATE = (section, state, action) => {

  return {
    ...state,
    [section]: {
      ..._initialState,
      sections: {
        ...state[section].sections,
        activeKey: (state[section].sections.activeKey === '' ? 'tab:subscription' : 'tab:invoice')
      }
    }
  };
};

export const MANAGER_BILLING_SET_USER_COUNT = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        billingConfig: {
          ...state[section].billingConfig,
          accountPackage: {
            ...state[section].billingConfig.accountPackage,
            defaultUsersLimit: action.payload
          }
        }
      }
    }
  };
};

export const MANAGER_BILLING_SET_TAB_ACTIVE = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        activeKey: action.payload
      }
    }
  };
};

export const MANAGER_BILLING_SET_SECTION_DRAWER = (section, state, action) => {
  const { sectionState } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        ...sectionState
      }
    }
  };
};

export const MANAGER_BILLING_PAY_NOW_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isPaying: true
      },
      section: {
        ...state[section],
        payNowMethodTypeId: null
      },
      error: {
        done: false,
        message: []
      }
    }
  };
};

export const MANAGER_BILLING_PAY_NOW_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isPaying: false
      },
      error: {
        done: true,
        message: [...state[section].error.message, action.payload.message]
      }
    }
  };
};

export const MANAGER_BILLING_PAY_NOW_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isPaying: false
      },
      section: {
        ...state[section],
        payNowMethodTypeId: action.payload
      }
    }
  };
};