import axios from 'axios';
import * as util from '@Util/';

export default props => {
  const fetch = axios.create();

  fetch.interceptors.request.use(
    function (config) {
      const auth = JSON.parse(util.getCookie('auth') || '{}');

      if (auth && auth.hasOwnProperty('token'))
        config.headers.common['Authorization'] = `Bearer ${auth.token}`;

      if (props && props.hasOwnProperty('token') && props.token)
        config.headers.common['Authorization'] = `Bearer ${props.token}`;

      if (props && props.hasOwnProperty('account') && props.account)
        config.headers.common['Account'] = props.account;

      if (props && props.hasOwnProperty('accountId') && props.accountId)
        config.headers.common['Account'] = props.accountId;

      if (props && props.hasOwnProperty('workspace') && props.workspace)
        config.headers.common['Workspace'] = props.workspace;

      if (props && props.hasOwnProperty('workspaceId') && props.workspaceId)
        config.headers.common['Workspace'] = props.workspaceId;

      if (props && props.hasOwnProperty('groupPermission'))
        config.headers.common['group-permission'] = props.groupPermission;

      if (props && props.hasOwnProperty('formOrigin'))
        config.headers.common['form-origin'] = props.formOrigin;

      if (props && props.hasOwnProperty('userId'))
        config.headers.common['user'] =
          props.userId;
      
      if (props && props.hasOwnProperty('medicalRecordsAttendance'))
        config.headers.common['medical-records-attendance'] =
          props.medicalRecordsAttendance;

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return fetch;
};

/**
 * FORM-ORIGIN
 * 1 - NORMAL
 * 2 - PRONTUARIO
 */
