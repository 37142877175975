import _ from 'lodash';
import initialState from './initialState'

export const SAVE_PHOTO_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, 'Erro ao tentar salvar a foto']
    }
  }
});

export const SAVE_PHOTO_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      business: {
        ...state[section].api.business,
        logo: action.payload
      }
    },
    error: {
      done: false,
      messages: []
    }
  }
})

export const IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const GET_WORKSPACES_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      workspace: true,
      workspaceError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const WORKSPACE_CHECK_CEP_INFO_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      cepData: action.payload
    },
  }
});

export const GET_WORKSPACES_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      workspace: false,
      workspaceError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const GET_WORKSPACES_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      workspace:  false
    },
    api: {
      ...state[section].api,
      listWorkspace: action.payload.listWorkspace,
      workspaces: {
        ...state[section].api.workspaces,
        ...action.payload.workspaces
      }
    },
    loadingUser: {
      ...state[section].loadingUser,
      ...action.payload.loadingUser
    }
  }
});

export const MODAL_OPENED_NEW_WORKSPACE = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    modal: {
      ...state[section].modal,
      create: action.payload
    }
  }
});

export const MODAL_OPENED_UPDATE_WORKSPACE = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    modal: {
      ...state[section].modal,
      update: action.payload
    }
  }
});

export const MODAL_OPENED_UPDATE_USER_WORKSPACE = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    modal: {
      ...state[section].modal,
      user: action.payload
    }
  }
});

export const MODAL_CLOSED = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    modal: {
      ...state[section].modal,
      create: false,
      update: false,
      user  : false
    }
  }
});

export const CREATE_WORKSPACES_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCreateWorkspace: true,
      isCreateWorkspaceError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const CREATE_WORKSPACES_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCreateWorkspace: false,
      isCreateWorkspaceError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const CREATE_WORKSPACES_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCreateWorkspace: false
    },
    modal:{
      ...state[section].modal,
      create: false
    }
  }
});

export const UPDATE_WORKSPACES_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isUpdateWorkspace: true,
      isUpdateWorkspaceError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const UPDATE_WORKSPACES_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isUpdateWorkspace: false,
      isUpdateWorkspaceError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const UPDATE_WORKSPACES_FETCH_SUCCESS = (section, state, action) => {
  debugger
  const { cityId, stateId, numberAddress, avatarSource, ...rest } = action.payload;
  debugger
  Object.keys(rest).map(key => {
    if(rest[key] === null) {
      rest[key] = ""
    }
  });
  const usedValues = _.pick(rest, [
    'name',
    'number',
    'email',
    'site',
    'whatsapp',
    'cep',
    'address',
    'complement',
    'neighborhood',
  ])
  
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isUpdateWorkspace: false
      },
      api: {
        ...state[section].api,
        business: {
          ...usedValues,
          numberAddress: numberAddress || initialState.api.business.numberAddress,
          dropState: stateId || initialState.api.business.dropState,
          dropCity: cityId || initialState.api.business.dropCity,
          logo: avatarSource
        },
      }
    }
  })
};

export const GET_USERS_BY_WORKSPACE_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    loadingUser: {
      ...state[section].loadingUser,
      [action.payload.workspaceId]: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const GET_USERS_BY_WORKSPACE_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    loadingUser: {
      ...state[section].loadingUser,
      [action.payload.workspaceId]: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const GET_WORKSPACE_INFO_SUCCESS = (section, state, action) => {
  const { data } =  action.payload;
  debugger
  const { cityId , stateId, numberAddress, avatarSource, ...rest } = data;
  
  Object.keys(rest).map(key => {
    if(rest[key] === null) {
      rest[key] = ""
    }
  });
  const usedValues = _.pick(rest, [
    'name',
    'number',
    'email',
    'site',
    'whatsapp',
    'cep',
    'address',
    'complement',
    'neighborhood',
    'cnpj',
    'businessName'
  ]);
  
  return ({
    ...state,
    [section]: {
      ...state[section],
      loadingWorkspaces: {
        ...state[section].loadingWorkspaces,
        [action.payload.workspaceId]: false
      },
      api: {
        ...state[section].api,
        business: {
          ...state[section].business,
          [action.payload.workspaceId]: {
            ...usedValues,
            numberAddress,
            dropState:  stateId,
            dropCity: cityId,
            logo: avatarSource
          }
        },
      }
    }
  })
};

export const SET_CHECKED_CEP = (section, state, action) => {
  const {
    address,
    neighborhood,
    workspaceId,
    cep,
    dropState,
    dropCity,
    complement,
    numberAddress
  } = action.payload;
  
  return ({
    ...state,
    [section]: {
      ...state[section],
      loadingWorkspaces: {
        ...state[section].loadingWorkspaces,
        [workspaceId]: false
      },
      api: {
        ...state[section].api,
        business: {
          ...state[section].api.business,
          [workspaceId]: {
            ...state[section].api.business[workspaceId],
            address,
            neighborhood,
            cep,
            complement,
            numberAddress,
            dropState : dropState || state[section].api.business[workspaceId].dropState,
            dropCity: dropCity || state[section].api.business[workspaceId].dropCity,
          }
        },
      }
    }
  })
};

export const GET_WORKSPACE_INFO_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    loadingWorkspaces: {
      ...state[section].loadingWorkspaces,
      [action.payload.workspaceId]: true
    },
    error: {
      done: false,
      messages: []
    }
  }
})

export const GET_WORKSPACE_INFO_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    loadingWorkspaces: {
      ...state[section].loadingWorkspaces,
      [action.payload.workspaceId]: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const GET_USERS_BY_WORKSPACE_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    loadingUser: {
      ...state[section].loadingUser,
      [action.payload.workspaceId]: false
    },
    api: {
      ...state[section].api,
      workspaces: {
        ...state[section].api.workspaces,
        ...action.payload.workspaces
      }
    }
  }
});

