import { reset } from 'redux-form';
import { push } from 'connected-react-router';

import * as types from '../constants';
import * as routes from '@Constants/router';
import * as util from '@Util';
import systemConfigs from '@Constants/systemConfigs';

export const setSignInFetchRequest = () => ({
  type: types.LOGIN_SIGNIN_FETCH_REQUEST
});
export const setSignInFetchError = data => ({
  type: types.LOGIN_SIGNIN_FETCH_ERROR,
  payload: data
});
export const setSignInFetchSuccess = data => ({
  type: types.LOGIN_SIGNIN_FETCH_SUCCESS,
  payload: data
});
export const setAuth = data => ({ type: types.LOGIN_SET_AUTH, payload: data });

export const autoLogin = token => (dispatch, getState, api) => {
  dispatch(setSignInFetchRequest());
  return api.login
    .tokenLogin(token)
    .then(response => {
      const auth = { ...response };

      util.setCookie('auth', JSON.stringify(auth), systemConfigs.ROOT_DOMAIN);

      dispatch([
        setSignInFetchSuccess(response),
        setAuth({ ...auth }),
        reset('SignIn'),
        push(`/${routes.ROUTE_STARTED}`)
      ]);
    })
    .catch(() => {
      dispatch(push('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
      dispatch(setSignInFetchError());
    });
};
