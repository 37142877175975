import * as endpoints from '@App/constants/endpoints';
import interceptor from './interceptor';

const axios = interceptor();
const WORKSPACE = 'workspace';
const USER = 'user';
const INATIVE = 'inactivate';
const ATIVE = 'activate';
const APPS = 'apps';
const PERMISSION = 'permission';
const PROFILE = 'profile';

export const getWorkspace = async ({ workspaceId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw error.response.status;
    throw error;
  }
};

export const createWorkspace = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}`;

  try {
    let response = await axios.post(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};

export const updateWorkspace = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}`;

  try {
    let response = await axios.put(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw error.response.status;
    throw error;
  }
};

export const updateUserByWorkspace = async ({
  workspaceId,
  userId,
  workspaceProfileId,
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}/${userId}/${PERMISSION}/${PROFILE}`;

  try {
    let response = await axios.put(pathUrl, { workspaceProfileId });
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};

export const getUsersByWorkspace = async workspaceId => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}s`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw error.response.status;
    throw error;
  }
};

export const getUserByIdWorkspace = async ({ workspaceId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}/${userId}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw error.response.status;
    throw error;
  }
};

export const setInativeUserWorkspace = async ({ workspaceId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}/${userId}/${INATIVE}`;

  try {
    let response = await axios.put(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};

export const getUserWorkspaceApps = async workspaceId => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}/${APPS}`;
  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw error.response.status;
    throw error;
  }
};

export const setAtiveUserWorkspace = async ({ workspaceId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}/${userId}/${ATIVE}`;

  try {
    let response = await axios.put(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};
