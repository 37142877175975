export default {
  isFirstLoading: true,
  fetching: {
    isUserDashboard: false,
    isUserDashboardError: false,
    isSelectedBusiness: false,
    isSelectedBusinessError: false,
    isFetchingStep: false,
    isFetchingConfirmation: false,
    isLoadingPurchaseRequest: false,
    isRetrievingPackages: false,
    isCheckingCep: false,
    isRetrievingStatesList: false,
  },
  currentStep: 0,
  statesList: [],

  packageData: {
    packageModelId: null,
    workspacesLimit: 1,
    usersLimit: 1,
    coupon: ''
  },

  packages: [],

  billingData: {
    invalidCep: null,
    checkedCep: null,
    useCnpj: false,
    firstName: null,
    lastName: null,
    corporateName: null,
    email: null,
    zipcode: null,
    cpf: null,
    cnpj: null,
    number: null,
    address: null,
    neighborhood: null,
    state: null,
    city: null,
    complement: null
  },

  paymentMethod: {
    hasPaymentMethod: false,
    editEnabled: false,
    accountPurchaseRequestStatusId: null,
    purchaseInInstallment: null,
    numberInstallments: null,
    paymentMethodTypeId: 2,
    card: {
      cardNumber: 0,
      first4Digits: 0,
      last4Digits: 0,
      expirationMonth: 0,
      expirationYear: 0,
      holderName: '',
      cvv: ''
    }
  },

  resume: {
    workspacesQuantity: 1,
    usersQuantity: 1,
    packageName: null,
    packagePrice: null,
    installmentPrice: null
  },

  businessData: {
    accountName: null,
    firstWorkspaceName: null
  },

  api: {
    listWorkspace: [],
    listAccount: [],
    successfulPayment: false,
    successfulInfo: {
      paymentMethodTypeId: null,
      boleto: {
        barcode: null,
        boletoId: null,
        url: null
      }
    },
    purchaseRequest: {
      purchaseRequestId: null,
      packageModelId: null,
      workspacesLimit: null,
      usersLimit: null,
      packageName: null,
      packagePrice: null,
      accountName: null,
      firstWorkspaceName: null,
      firstName: null,
      lastName: null,
      corporateName: null,
      email: null,
      number: null,
      cpf: null,
      cnpj: null,
      address: null,
      neighborhood: null,
      state: null,
      city: null,
      complement: null,
      accountPurchaseRequestStatusId: null,
      purchaseInInstallment: null,
      numberInstallments: null,
      paymentMethodTypeId: null,
      card: {
        first4Digits: null,
        expirationMonth: null,
        expirationYear: null,
        holderName: null
      }
    }
  },
  error: {
    done: true,
    message: []
  }
};
