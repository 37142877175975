import { reset } from 'redux-form';
import { push } from 'connected-react-router';
import * as Sentry from '@sentry/react';

import * as types from '../constants';
import * as routes from '@Constants/router';

import * as actionsStarted from '@Components/started/action';
import * as util from '@Util/';
import systemConfigs from '@Constants/systemConfigs';

export const setSignInInvalidEmail = () => ({
  type: types.LOGIN_SIGNIN_INVALID_EMAIL,
});
export const setSignInInvalidEmailFormat = () => ({
  type: types.LOGIN_SIGNIN_INVALID_EMAIL_FORMAT,
});
export const setSignInInvalidPassword = () => ({
  type: types.LOGIN_SIGNIN_INVALID_PASSWORD,
});
export const setSignInInvalidPasswordFormat = () => ({
  type: types.LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT,
});
export const setSignInFetchRequest = () => ({
  type: types.LOGIN_SIGNIN_FETCH_REQUEST,
});
export const setClearFormError = () => ({
  type: types.LOGIN_SIGNIN_CLEAR_FORM_ERROR,
});
export const setSignInFetchError = (data) => ({
  type: types.LOGIN_SIGNIN_FETCH_ERROR,
  payload: data,
});
export const setSignInFetchSuccess = (data) => ({
  type: types.LOGIN_SIGNIN_FETCH_SUCCESS,
  payload: data,
});
export const setSignInAuthenticated = (data) => ({
  type: types.LOGIN_SIGNIN_AUTHENTICATED,
  payload: data,
});
export const setSignInLogout = () => ({ type: types.LOGIN_SIGNIN_LOGOUT });
export const setAuth = (data) => ({
  type: types.LOGIN_SET_AUTH,
  payload: data,
});
export const setAuthPermissionRequest = () => ({
  type: types.LOGIN_SET_AUTH_PERMISSION_REQUEST,
});
export const setAuthPermission = (data) => ({
  type: types.LOGIN_SET_AUTH_PERMISSION,
  payload: data,
});
export const setUserName = (data) => ({
  type: types.LOGIN_SET_USER_NAME,
  payload: { newName: data },
});

export const signIn = (params) => (dispatch, getState, api) => {
  dispatch(setSignInFetchRequest());
  return api.login
    .signIn(params)
    .then((response) => {
      const auth = { ...response };

      util.setCookie('auth', JSON.stringify(auth), systemConfigs.ROOT_DOMAIN);

      window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.remove('grecaptcha-show');

      dispatch([
        setSignInFetchSuccess(response),
        setAuth({ ...auth }),
        setAuthPermission({ permissions: auth.permissions }),
        reset('SignIn'),
        push(routes.ROUTE_STARTED),
      ]);
    })
    .catch((error) => {
      Sentry.captureException(error);
      if (
        error.response.status === 401 ||
        error.response.status === 402 ||
        error.response.status === 422
      ) {
        if (error.response.data.reCaptchaType) {
          dispatch(setSignInFetchError(error.response.data.message));
        } else {
          dispatch(setSignInFetchError('Login inválido!'));
        }
        return;
      }

      if (error.response.status === 403) {
        dispatch(setSignInFetchError('Email não verificado!'));
        return;
      }

      if (error.response.status === 404) {
        dispatch(setSignInFetchError('Usuário inválido!'));
        return;
      }

      dispatch(setSignInFetchError());
    });
};

export const verifyToken = (token) => (dispatch, getState, api) => {
  return api.login
    .verifyToken(token)
    .then((data) => {
      const state = getState();
      if (data.success) {
        if (!data.userId === state.login.auth.userId) {
          util.deleteCookie('auth', '', '');
          dispatch(setSignInLogout());
          dispatch(push('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
          return;
        }

        const auth = JSON.parse(util.getCookie('auth') || '{}');
        actionsStarted.selectedBusinessFetchSuccess(auth.business);
      } else {
        util.deleteCookie('auth', '', '');
        dispatch(setSignInLogout());
        dispatch(push('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
      }
    })
    .catch((error) => {
      util.deleteCookie('auth', '', '');
      dispatch(setSignInLogout());
      dispatch(push('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
    });
};
