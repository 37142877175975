import { createSelector } from 'reselect';

export default ({
  getListWorkspace  : state => state.started.overview.api.listWorkspace,
  getListAccount    : state => state.started.overview.api.listAccount,
  getStatusError    : state => state.started.overview.error,
  getBusiness       : state => state.started.overview.business,

  getIsFirstLoading: createSelector(
    state => state.started.overview.isFirstLoading,
    done  => done
  ),
  getIsFetching: createSelector(
    state => state.started.overview.fetching.isUserDashboard,
    done  => done
  ),
  getIsCreateAccount: createSelector(
    state => state.started.overview.fetching.isCreateAccount,
    done  => done
  ),
  getIsDoneModal: createSelector(
    state => state.started.overview.modal.done,
    done  => done
  ),
  getBusinessId: createSelector(
    state => state.started.overview.business.id,
    businessId => businessId
  ),
  getAccountId: createSelector(
    state => state.started.overview.business.accountId,
    businessId => businessId
  ),
  getEnviromentType: createSelector(
    state => state.started.overview.business.url,
    state => state
  )
});