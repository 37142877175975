import {
  SNACKBAR_SHOW,
  SNACKBAR_HIDE
} from './constants';

let timeout = null;

export const hideSnackbar = () => (dispatch, getState, api) => {
  clearTimeout(timeout);
  dispatch(({ type: SNACKBAR_HIDE }));
};

export const showSnackbar = data => (dispatch, getState, api) => {
  dispatch(hideSnackbar());

  clearTimeout(timeout);
  timeout = setTimeout(() => dispatch({ type: SNACKBAR_SHOW, payload: data }), 800);
};
