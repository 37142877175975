import types              from './constant';
import initialState       from './initialState';
import * as commonReducer from './commonReducer';

const INITIAL_STATE = ({ ...initialState });

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_REFERRAL_NAME: return commonReducer.SET_REFERRAL_NAME(state, action);
    case types.SET_REFERRAL_DESCRIPTION: return commonReducer.SET_REFERRAL_DESCRIPTION(state, action);
    case types.CREATE_REFERRAL_FETCH_ERROR: return commonReducer.CREATE_REFERRAL_FETCH_ERROR(state, action);
    case types.CREATE_REFERRAL_FETCH_REQUEST: return commonReducer.CREATE_REFERRAL_FETCH_REQUEST(state, action);
    case types.CREATE_REFERRAL_FETCH_SUCCESS: return commonReducer.CREATE_REFERRAL_FETCH_SUCCESS(state, action);
    case types.LIST_REFERRALS_FETCH_REQUEST: return commonReducer.LIST_REFERRALS_FETCH_REQUEST(state, action);
    case types.LIST_REFERRALS_FETCH_ERROR: return commonReducer.LIST_REFERRALS_FETCH_ERROR(state, action);
    case types.LIST_REFERRALS_FETCH_SUCCESS: return commonReducer.LIST_REFERRALS_FETCH_SUCCESS(state, action);
    case types.UPDATE_REFERRAL_FETCH_REQUEST: return commonReducer.UPDATE_REFERRAL_FETCH_REQUEST(state, action);
    case types.UPDATE_REFERRAL_FETCH_ERROR: return commonReducer.UPDATE_REFERRAL_FETCH_ERROR(state, action);
    case types.UPDATE_REFERRAL_FETCH_SUCCESS: return commonReducer.UPDATE_REFERRAL_FETCH_SUCCESS(state, action);
    case types.GET_REFERRAL_FETCH_REQUEST: return commonReducer.GET_REFERRAL_FETCH_REQUEST(state, action);
    case types.GET_REFERRAL_FETCH_ERROR: return commonReducer.GET_REFERRAL_FETCH_ERROR(state, action);
    case types.GET_REFERRAL_FETCH_SUCCESS: return commonReducer.GET_REFERRAL_FETCH_SUCCESS(state, action);
    case types.PUSH_NEW_REFERRAL_TO_LIST: return commonReducer.PUSH_NEW_REFERRAL_TO_LIST(state, action);
    case types.RESET_REFERRAL_FORM: return commonReducer.RESET_REFERRAL_FORM(state, action);
    case types.UPDATE_ITEM_ON_LIST: return commonReducer.UPDATE_ITEM_ON_LIST(state, action);
    default: return state
  }
};