export default ({
  SECTION : 'patient',

  GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_REQUEST: 'GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_REQUEST',
  GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_SUCCESS: 'GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_SUCCESS',
  GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_ERROR: 'GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_ERROR',

  CREATE_FORM_EMPTY_ATTENDANCE_ANSWER_FETCH_REQUEST: 'CREATE_FORM_EMPTY_ATTENDANCE_ANSWER_FETCH_REQUEST',
  CREATE_FORM_EMPTY_ATTENDANCE_ANSWER_FETCH_SUCCESS: 'CREATE_FORM_EMPTY_ATTENDANCE_ANSWER_FETCH_SUCCESS',
  CREATE_FORM_EMPTY_ATTENDANCE_ANSWER_FETCH_ERROR: 'CREATE_FORM_EMPTY_ATTENDANCE_ANSWER_FETCH_ERROR',

  MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_ANSWER_PATIENT_FETCH_SUCCESS: 'MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_ANSWER_PATIENT_FETCH_SUCCESS',

  GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_REQUEST: 'GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_REQUEST',
  GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_SUCCESS: 'GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_SUCCESS',
  GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_ERROR: 'GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_ERROR',

  SET_TARGET_FORM_ANSWER_PATIENT: 'SET_TARGET_FORM_ANSWER_PATIENT',

  SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_REQUEST: 'SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_REQUEST',
  SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_SUCCESS: 'SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_SUCCESS',
  SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_ERROR: 'SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_ERROR',
});
