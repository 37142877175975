import React from 'react';
import { defineMessages } from 'react-intl';

export default defineMessages({
  titleInformationHelper: {
    id: 'usecore.text.titleInformationHelper',
    defaultMessage: `<div>Informação sobre Título de campos
    {br}{br}
    Todo campo criado na UseCore possui um Título e um Título Visível.
    {br}{br}
    Título é o nome do campo. Os campos na UseCore são únicos, ou seja, não podem ter Nomes, Tipos e Unidades de Medidas iguais.
    Por isso, cada campo com tipos e unidades iguais, devem obrigatoriamente, ter títulos diferentes. Você pode ter nomes mais técnicos ou até com padrão de catalogação, se desejar.
    Mas não se preocupe, o próprio sistema controla e te avisa quando houver coincidências de Títulos.
    {br}{br}
    Título Visível é o nome do campo que será visível nas telas do sistema e nas impressões. 
    Este título pode ser repetido e não há controle de repetição. 
    Fique livre para escolher o que mais te ajudará no dia a dia.</div>`,
  },
  allParamsVersionMatch: {
    id: 'usecore.text.allParamsVersionMatch',
    defaultMessage: `<div>
    Já existe um campo criado com o mesmo Título,{br}
    Tipo de Campo e Unidade de Medida.{br}
    Você deseja voltar ou atualizar o campo existente
    com essas novas informações?
    </div>`,
  },
  versionAlterTitle: {
    id: 'usecore.text.versionAlterTitle',
    defaultMessage: `<div>
    Você está editando um campo e alterando o{br}
    Título do Campo{br}
    Você deseja voltar ou criar um novo campo com{br}
    o novo título?
    </div>`,
  },
  versionDiffDataType: {
    id: 'usecore.text.versionDiffDataType',
    defaultMessage: `<div>
    Já existe um campo criado com o mesmo Título{br}
    mas com Tipo de Campo diferente.{br}
    Você deseja voltar ou criar um novo campo com{br}
    essas novas informações?
    </div>`,
  },
  versionDiffMeasurementUnit: {
    id: 'usecore.text.versionDiffMeasurementUnit',
    defaultMessage: `<div>
    Já existe um campo criado com o mesmo Título e
    Tipo de Campo mas com Unidade de Medida diferente.{br}
    Você deseja voltar ou criar um novo campo com{br}
    essas novas informações?
    </div>`,
  },
  fieldModelEditScreen: {
    id: 'usecore.text.fieldModelEditScreen',
    defaultMessage: `<div>
    Este é um campo MODELO disponibilizado para o seu uso.{br}
    Modificações neste campo não são permitidas.{br}
    Caso deseje utilizar um campo com uma descrição ou opções de respostas diferentes,
    busque por outros campos modelos disponibilizados ou crie um campo personalizado.
    </div>`,
  },
  attention: {
    id: 'usecore.labels.attention',
    defaultMessage: 'Atenção',
  },
  save: {
    id: 'usecore.labels.save',
    defaultMessage: 'Salvar',
  },
  blockModelEdit: {
    id: 'usecore.labels.blockModelEdit',
    defaultMessage: `Edição Bloqueada`,
  },
  attachedPreProgrammedFieldRemoveWarning: {
    id: 'usecore.text.attachedPreProgrammedFieldRemoveWarning',
    defaultMessage: `<div>
    Você esta removendo do formulário um campo que possui vínculo com outros campos. {br}
    Ao remover um campo com vínculo, todos os campos ligados a este serão removidos. {br}

    Os campos vinculados, que serão removidos, são: {br}{br}
    </div>`,
  },
});