export default ({
  fetching: {
    getMedicalRecord: false,
    getAccessGroups: false,
    getMedicalRecordMembers: false,

    postAccessGroup: false,

    putMedicalRecordConfig: false,
    putAccessGroup: false
  },
  api: {
    getMedicalRecord: {
      attendanceAccessConfig: {
        isPublic: false,
        userHasSharingControl: false
      }
    },
   /**
    * @type {Object[]} accessGroups
    * @property {number} accessGroups[].medicalRecordsAccessGroupId
    * @property {string} accessGroups[].name
   */
    accessGroups: [],
    accessGroupById: {
      /**
       * @type {Number[]} members
      */
      members: [],
      medicalRecordsAccessGroupId: 0,
      name: '',
    },
    /**
     * @type {Object[]} listMembers
     * @property {number} listMembers[].userId
     * @property {string} listMembers[].name
    */
    listMembers: []
  }
});