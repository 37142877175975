import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';
import { put }        from '../util';

const MEDICAL_RECORDS = 'medicalrecords';
const PRESCRIPTION = 'prescription';
const REFERRAL = 'referral';
const REPORT = 'report';
const REQUEST = 'request';
const CERTIFICATE = 'certificate';

//#region POST
export const createPrescriptionModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${PRESCRIPTION}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createReferralModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REFERRAL}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createReportModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REPORT}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createRequestModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REQUEST}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createCertificateModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${CERTIFICATE}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region POST
export const updatePrescriptionModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${PRESCRIPTION}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateReferralModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REFERRAL}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateReportModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REPORT}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateRequestModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REQUEST}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateCertificateModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${CERTIFICATE}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};
//#endregion

//#region GET
export const listPrescriptionsModel = async ({ header }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${PRESCRIPTION}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getPrescriptionModel = async ({ header, prescriptionModelId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${PRESCRIPTION}/${prescriptionModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const listCertificatesModel = async ({ header }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${CERTIFICATE}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getCertificateModel = async ({ header, certificateModelId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${CERTIFICATE}/${certificateModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const listReferralsModel = async ({ header }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REFERRAL}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getReferralModel = async ({ header, referralModelId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REFERRAL}/${referralModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const listReportsModel = async ({ header }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REPORT}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getReportModel = async ({ header, reportModelId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REPORT}/${reportModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const listRequestsModel = async ({ header }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REQUEST}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getRequestModel = async ({ header, requestModelId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICAL_RECORDS}/${REQUEST}/${requestModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion
