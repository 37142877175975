export default {
  SECTION: 'workspace',
  CONTEXT_MODAL_DELETE_PERFIL: 'PermissionWorkPanelHeaderModalDeletePerfil',

  FORM_PERMISSION_WORKSPACE_PANEL_CREATE:
    'form:permission:workspace:panel:create',
  FORM_PERMISSION_WORKSPACE_PANEL_UPDATE:
    'form:permission:workspace:panel:update',

  MODAL_PERMISSION_WORKSPACE_PANEL_CREATE:
    'modal:permission:workspace:panel:create',
  MODAL_PERMISSION_WORKSPACE_PANEL_UPDATE:
    'modal:permission:workspace:panel:update',

  PERMISSION_WORKSPACE_IS_FIRST_LOADING:
    'PERMISSION_WORKSPACE_IS_FIRST_LOADING',

  PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_REQUEST:
    'PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_REQUEST',
  PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_ERROR:
    'PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_ERROR',
  PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_SUCCESS:
    'PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_SUCCESS',

  PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_REQUEST:
    'PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_REQUEST',
  PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_ERROR:
    'PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_ERROR',
  PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_SUCCESS:
    'PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_SUCCESS',

  PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_REQUEST:
    'PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_REQUEST',
  PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_ERROR:
    'PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_ERROR',
  PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_SUCCESS:
    'PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_SUCCESS',

  PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_REQUEST:
    'PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_REQUEST',
  PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_ERROR:
    'PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_ERROR',
  PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_SUCCESS:
    'PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_SUCCESS',

  PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_REQUEST:
    'PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_REQUEST',
  PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_ERROR:
    'PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_ERROR',
  PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_SUCCESS:
    'PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_SUCCESS',

  PERMISSION_WORKSPACE_SET_PERMISSION: 'PERMISSION_WORKSPACE_SET_PERMISSION',
  PERMISSION_WORKSPACE_SET_ADMIN_PERMISSION_PROFILE:
    'PERMISSION_WORKSPACE_SET_ADMIN_PERMISSION_PROFILE',

  PERMISSION_WORKSPACE_SET_SEARCH_PERFIL:
    'PERMISSION_WORKSPACE_SET_SEARCH_PERFIL',
  PERMISSION_WORKSPACE_SET_SEARCH_FUNCTIONALITIES:
    'PERMISSION_WORKSPACE_SET_SEARCH_FUNCTIONALITIES',

  PERMISSION_WORKSPACE_SET_PERMISSION_UPDATE_PROFILES:
    'PERMISSION_WORKSPACE_SET_PERMISSION_UPDATE_PROFILES',
  PERMISSION_WORKSPACE_SET_PERMISSION_UPDATE_FUNCTIONALITIES:
    'PERMISSION_WORKSPACE_SET_PERMISSION_UPDATE_FUNCTIONALITIES',

  PERMISSION_WORKSPACE_SET_PERFIL_SELECTED:
    'PERMISSION_WORKSPACE_SET_PERFIL_SELECTED',
  PERMISSION_WORKSPACE_SET_APP_FUNCTIONALITY:
    'PERMISSION_WORKSPACE_SET_APP_FUNCTIONALITY',
  PERMISSION_WORKSPACE_SET_APP_LIST_FUNCTIONALITY:
    'PERMISSION_WORKSPACE_SET_APP_LIST_FUNCTIONALITY',
  PERMISSION_WORKSPACE_SET_APP_ID_SELECTED:
    'PERMISSION_WORKSPACE_SET_APP_ID_SELECTED',

  PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_APPS:
    'PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_APPS',
  PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_FUNCTIONALITY:
    'PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_FUNCTIONALITY',
  PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_WORKSPACE_FUNCTIONALITY:
    'PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_WORKSPACE_FUNCTIONALITY',

  PERMISSION_WORKSPACE_SET_LIST_PROFILES:
    'PERMISSION_WORKSPACE_SET_LIST_PROFILES',
  PERMISSION_WORKSPACE_SET_PROFILES: 'PERMISSION_WORKSPACE_SET_PROFILES'
};
