import { createSelector } from 'reselect';

export default ({
  getMenuDataSource: state => state.config.menu.dataSource,

  getNameRoute: createSelector(
    state => state.config.route.name,
    state => state
  ),

  getNameDrawer: createSelector(
    state => state.config.route.nameDrawer,
    state => state
  ),

  getMenuIsDone: createSelector(
    state => state.config.menu.done,
    state => state
  )
});