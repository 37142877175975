import createInitialState             from './create/initialState';
import typesCreate                    from './create/constant';
import * as commonReducerCreate       from './create/reducerGenerator';

import overviewInitialState           from './overview/initialState';
import typesOverview                  from './overview/constant';
import * as commonReducerOverview     from './overview/reducerGenerator';

const INITIAL_STATE = {
  overview: {...overviewInitialState},
  create: {...createInitialState}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case typesCreate.CREATE_WORKSPACES_SAVE_AVATAR_ERROR:                           return commonReducerCreate.SAVE_PHOTO_ERROR(typesCreate.SECTION, state, action);
    case typesCreate.CREATE_WORKSPACES_SAVE_AVATAR_SUCCESS:                         return commonReducerCreate.SAVE_PHOTO_SUCCESS(typesCreate.SECTION, state, action);
    case typesCreate.CREATE_WORKSPACES_FETCH_ERROR:                                 return commonReducerCreate.CREATE_WORKSPACES_FETCH_ERROR(typesCreate.SECTION, state, action);
    case typesCreate.CREATE_WORKSPACES_FETCH_REQUEST:                               return commonReducerCreate.CREATE_WORKSPACES_FETCH_REQUEST(typesCreate.SECTION, state, action);
    
    case typesOverview.WORKSPACES_OVERVIEW_SAVE_AVATAR_ERROR:                       return commonReducerOverview.SAVE_PHOTO_ERROR(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_SAVE_AVATAR_SUCCESS:                     return commonReducerOverview.SAVE_PHOTO_SUCCESS(typesOverview.SECTION, state, action);
    
    case typesOverview.WORKSPACES_OVERVIEW_IS_FIRST_LOADING:                        return commonReducerOverview.IS_FIRST_LOADING(typesOverview.SECTION, state, action);
    
    case typesOverview.WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_REQUEST:            return commonReducerOverview.GET_WORKSPACES_FETCH_REQUEST(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_ERROR:              return commonReducerOverview.GET_WORKSPACES_FETCH_ERROR(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_SUCCESS:            return commonReducerOverview.GET_WORKSPACES_FETCH_SUCCESS(typesOverview.SECTION, state, action);
  
    case typesOverview.WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_REQUEST:         return commonReducerOverview.UPDATE_WORKSPACES_FETCH_REQUEST(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_SUCCESS:         return commonReducerOverview.UPDATE_WORKSPACES_FETCH_SUCCESS(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_ERROR:           return commonReducerOverview.UPDATE_WORKSPACES_FETCH_ERROR(typesOverview.SECTION, state, action);

    case typesOverview.WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_REQUEST:              return commonReducerOverview.GET_WORKSPACE_INFO_REQUEST(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_ERROR:                return commonReducerOverview.GET_WORKSPACE_INFO_ERROR(typesOverview.SECTION, state, action);
    case typesOverview.WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_SUCCESS:              return commonReducerOverview.GET_WORKSPACE_INFO_SUCCESS(typesOverview.SECTION, state, action);

    case typesOverview.WORKSPACE_CHECK_CEP_INFO_FETCH_SUCCESS:                      return commonReducerOverview.SET_CHECKED_CEP(typesOverview.SECTION, state, action);
    default:
      return state;
  }
};