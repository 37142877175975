/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from './interceptor';

const axios = interceptor();
const USER = 'user';
const SEARCH = 'search';
const DASHBOARD = 'dashboard';
const UPDATE = 'update';
const PASSWORD = 'password';
const ACCOUNT = 'account';
const WORKSPACE = 'workspace';
const RECOVER = 'recover';
const PROFESSIONAL = 'professional';
const COUNCILS = 'councils';

const _get = async props => {
  const { pathUrl } = props;

  try {
    return (await axios.get(pathUrl)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const getUserDashboard = async ({ userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${userId}/${DASHBOARD}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const getProfessionalCouncils = async () => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${PROFESSIONAL}/${COUNCILS}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const getSearchedUser = async (field, value) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${SEARCH}/${field}/${value}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const getListUsersAccount = async ({ businessId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${businessId}/${USER}s`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const getListUsersWorkspace = async ({ businessId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${businessId}/${USER}s`;
  return await _get({ pathUrl });
};

export const getById = async ({ userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${userId}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const savePerfil = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}`;

  try {
    let response = await axios.put(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const recoverEmail = async ({ email }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${RECOVER}/${PASSWORD}`;
  const params = { email };

  try {
    await axios.post(pathUrl, params);
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const updatePasswordFromEmail = async ({ newPassword, token }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${UPDATE}/${PASSWORD}/${token}`;
  const params = { newPassword };

  try {
    await axios.put(pathUrl, params);
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const updatePassword = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${UPDATE}/${PASSWORD}`;

  try {
    let response = await axios.put(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};
