import initialStateOverview from './initialState';
import types from './constant';

const INITIAL_STATE = {
  ...initialStateOverview,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.PURCHASE_IS_FIRST_LOADING: {
      return {
        ...state,
        isFirstLoading: action.payload,
      };
    }

    case types.CHECK_CEP_INFO_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isCheckingCep: true,
        },
        billingData: {
          ...state.billingData,
          checkedCep: null,
        },
        error: {
          done: false,
          message: [],
        },
      };
    }

    case types.CHECK_CEP_INFO_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isCheckingCep: false,
        },
        billingData: {
          ...state.billingData,
          checkedCep: action.payload.checkedCep,
          invalidCep: true,
          address: null,
          neighborhood: null,
          state: null,
          city: null,
        },
        error: {
          done: true,
          message: [...state.error.message, action.payload.message],
        },
      };
    }

    case types.CHECK_CEP_INFO_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isCheckingCep: false,
        },
        billingData: {
          ...state.billingData,
          address: action.payload.logradouro || null,
          neighborhood: action.payload.bairro || null,
          state: action.payload.estado || null,
          city: action.payload.cidade || null,
          checkedCep: action.payload.cep || null,
          invalidCep: false,
        },
      };
    }

    //
    case types.PURCHASE_REQUEST_CONFIRM_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingConfirmation: true,
        },
      };
    }

    case types.PURCHASE_REQUEST_CONFIRM_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingConfirmation: false,
        },
      };
    }

    case types.PURCHASE_REQUEST_CONFIRM_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingConfirmation: false,
        },
        api: {
          ...state.api,
          successfulPayment: true,
          successfulInfo: {
            paymentMethodTypeId: action.payload.paymentMethodTypeId,
            boleto: action.payload.boleto,
          },
        },
      };
    }

    case types.PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: true,
        },
      };
    }

    case types.PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: true,
        },
      };
    }

    case types.PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_BILLING_DATA_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: true,
        },
      };
    }

    case types.PURCHASE_UPDATE_BILLING_DATA_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_BILLING_DATA_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: true,
        },
      };
    }

    case types.PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    case types.PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isFetchingStep: false,
        },
      };
    }

    // Get plans model reducers
    case types.PURCHASE_GET_PLANS_MODEL_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRetrievingPackages: true,
        },
        error: {
          done: false,
          message: [],
        },
      };
    }

    case types.PURCHASE_GET_PLANS_MODEL_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRetrievingPackages: false,
        },
        error: {
          done: true,
          message: [...state.error.message, action.payload.message],
        },
      };
    }

    case types.PURCHASE_GET_PLANS_MODEL_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        fetching: {
          ...state.fetching,
          isRetrievingPackages: false,
        },
        api: {
          ...state.api,
        },
      };
    }

    // Get states list reducers
    case types.PURCHASE_GET_STATES_LIST_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRetrievingStatesList: true,
        },
        error: {
          done: false,
          message: [],
        },
      };
    }

    case types.PURCHASE_GET_STATES_LIST_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRetrievingStatesList: false,
        },
        error: {
          done: true,
          message: [...state.error.message, action.payload.message],
        },
      };
    }

    case types.PURCHASE_GET_STATES_LIST_FETCH_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        fetching: {
          ...state.fetching,
          isRetrievingStatesList: false,
        },
      };
    }

    // Get user purchase reducers
    case types.PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingPurchaseRequest: true,
        },
        api: {
          ...state.api,
          listWorkspace: [],
          listAccount: [],
        },
        error: {
          done: false,
          message: [],
        },
      };
    }
    case types.PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isLoadingPurchaseRequest: false,
        },
        error: {
          done: true,
          message: [...state.error.message, action.payload.message],
        },
      };
    }

    case types.PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_SUCCESS: {
      return {
        ...state,
        packageData: {
          ...state.packageData,
          packageModelId: action.payload.packageModelId,
          workspacesLimit: action.payload.workspacesLimit,
          usersLimit: action.payload.usersLimit,
        },
        billingData: {
          ...state.billingData,
          useCnpj: action.payload.cnpj ? true : false,
          firstName: action.payload.firstName || null,
          lastName: action.payload.lastName || null,
          email: action.payload.email || null,
          cpf: action.payload.cpf || null,
          cnpj: action.payload.cnpj || null,
          address: action.payload.address || null,
          neighborhood: action.payload.neighborhood || null,
          state: action.payload.state || null,
          city: action.payload.city || null,
          complement: action.payload.complement || null,
          zipcode: action.payload.zipcode || null,
          number: action.payload.number || null,
        },
        paymentMethod: {
          ...state.paymentMethod,
          hasPaymentMethod: action.payload.hasPaymentMethod,
          editEnabled: !action.payload.hasPaymentMethod,
          accountPurchaseRequestStatusId:
            action.payload.accountPurchaseRequestStatusId,
          purchaseInInstallment: action.payload.purchaseInInstallment,
          numberInstallments: action.payload.numberInstallments,
          paymentMethodTypeId: action.payload.paymentMethodTypeId,
          card: {
            ...state.paymentMethod.card,
            cardNumber: null,
            first4Digits:
              action.payload.card && action.payload.card.first4Digits
                ? action.payload.card.first4Digits
                : null,
            last4Digits:
              action.payload.card && action.payload.card.last4Digits
                ? action.payload.card.last4Digits
                : null,
            expirationMonth:
              action.payload.card && action.payload.card.expirationMonth
                ? action.payload.card.expirationMonth
                : null,
            expirationYear:
              action.payload.card && action.payload.card.expirationYear
                ? action.payload.card.expirationYear
                : null,
            holderName:
              action.payload.card && action.payload.card.holderName
                ? action.payload.card.holderName
                : null,
            cvc: null,
          },
        },
        businessData: {
          ...state.paymentMethod,
          accountName: action.payload.accountName || null,
          firstWorkspaceName: action.payload.firstWorkspaceName || null,
        },
        fetching: {
          ...state.fetching,
          isLoadingPurchaseRequest: false,
        },
        api: {
          ...state.api,
          purchaseRequest: { ...action.payload },
        },
      };
    }

    case types.PURCHASE_NEXT_STEP: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
      };
    }

    case types.PURCHASE_PREVIOUS_STEP: {
      return {
        ...state,
        currentStep: state.currentStep - 1,
      };
    }

    case types.PURCHASE_GET_USER_DASHBOARD_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isUserDashboard: true,
          isUserDashboardError: false,
        },
        api: {
          ...state.api,
          listWorkspace: [],
          listAccount: [],
        },
        error: {
          done: false,
          message: [],
        },
      };
    }
    case types.PURCHASE_GET_USER_DASHBOARD_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isUserDashboard: false,
          isUserDashboardError: true,
        },
        error: {
          done: true,
          message: [...state.error.message, action.payload.message],
        },
      };
    }
    case types.PURCHASE_GET_USER_DASHBOARD_FETCH_SUCCESS: {
      return {
        ...state,
        isFirstLoading: false,
        fetching: {
          ...state.fetching,
          isUserDashboard: false,
        },
        api: {
          ...state.api,
          listWorkspace: action.payload.listWorkspace,
          listAccount: action.payload.listAccount,
        },
      };
    }
    case types.PURCHASE_SET_PACKAGE_DATA: {
      return {
        ...state,
        packageData: {
          ...state.packageData,
          ...action.payload,
        },
      };
    }
    case types.PURCHASE_SET_BILLING_DATA: {
      return {
        ...state,
        billingData: {
          ...state.billingData,
          ...action.payload,
        },
      };
    }
    case types.PURCHASE_SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: {
          ...state.paymentMethod,
          ...action.payload,
          paymentMethodTypeId: action.payload.paymentMethodTypeId
            ? action.payload.paymentMethodTypeId
            : state.paymentMethod.paymentMethodTypeId,
          card: action.payload.card
            ? { ...state.paymentMethod.card, ...action.payload.card }
            : { ...state.paymentMethod.card },
        },
      };
    }
    case types.PURCHASE_SET_BUSINESS_DATA: {
      return {
        ...state,
        businessData: {
          ...state.businessData,
          ...action.payload,
        },
      };
    }
    case types.PURCHASE_SET_RESUME_DATA: {
      return {
        ...state,
        resume: {
          ...state.resume,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};
