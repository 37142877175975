export default ({
  isFirstLoading: true,
  fetching: {
    getListSections: false,
    listFormModels: false,
    saveSection: false
  },
  inputValue: '',
  inputOrderValue: null,
  api: {
    listPrivate: [],
    listSharing: [],
    accountMedicalRecordsModel: {
      medicalRecordsModelId: 0,
      accountMedicalRecordsModelId: 0,
      accountId: 0,
      userIdCreated: 0,
      listSections: [],
      createdAt: ''
    }
  }
});