import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';

const ACCOUNT = 'account';
const DASHBOARD = 'dashboard';

//#region GET
export const loadDashboard = async ({header, params}) => {
  const { accountId } =  params;
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${DASHBOARD}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion
