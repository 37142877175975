import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import LazyRouteName from '@Common/lazyRouteName';

let DocumentPage = ({ match, ...rest }) => {
  return (
    <Switch>
      <LazyRouteName
        exact
        path={match.url}
        {...rest}
        name={'document'}
        component={() => import('@Components/documents/shared/pages/Document/index')}
      />
      <Route
        render={props => (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )}
      />
    </Switch>
  );
};

export default DocumentPage;