export default {
  isFirstLoading: true,

  fetching: {
    getAccountProfiles: false,
    getAccountFunctionalities: false,
    getAccountProfileById: false,

    postAccountProfile: false,

    putAccountProfile: false,

    deleteAccountProfile: false
  },

  search: {
    listProfiles: [],
    listFunctionalities: []
  },

  api: {
    profiles: {},
    functionalities: {},

    listProfiles: [],
    listFunctionalities: [],
    adminProfile: {},
    profileSelected: {
      id: 0,
      name: '',
      sigla: '',
      functionalitiesIds: []
    }
  }
};
