import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, put } from '../util';

const FAVORITES = 'favorites';


//#region GET
export const getFavorites = async ({ header }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${FAVORITES}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const createFavorites = async ({ header, params }) => {
    const pathUrl = `${endpoints.API_FORMS}/${FAVORITES}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const deleteFavorites = async ({ header, params }) => {
    const pathUrl = `${endpoints.API_FORMS}/${FAVORITES}/${params.id}`;
  const axios = interceptor(header);
  return await delete(axios, pathUrl);
}

export const updateFavorites = async ({ header, params }) => {
    const pathUrl = `${endpoints.API_FORMS}/${FAVORITES}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
}
