export default ({
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_CERTIFICATE_CREATE : 'modal:perfil:configuration:medicalRecord:certificate:create',
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_CERTIFICATE_UPDATE : 'modal:perfil:configuration:medicalRecord:certificate:update',
  SET_CERTIFICATE_NAME: 'SET_CERTIFICATE_NAME',
  SET_CERTIFICATE_DESCRIPTION: 'SET_CERTIFICATE_DESCRIPTION',
  LIST_CERTIFICATES_FETCH_REQUEST: 'LIST_CERTIFICATES_FETCH_REQUEST',
  LIST_CERTIFICATES_FETCH_ERROR: 'LIST_CERTIFICATES_FETCH_ERROR',
  LIST_CERTIFICATES_FETCH_SUCCESS: 'LIST_CERTIFICATES_FETCH_SUCCESS',
  CREATE_CERTIFICATE_FETCH_REQUEST: 'CREATE_CERTIFICATE_FETCH_REQUEST',
  CREATE_CERTIFICATE_FETCH_ERROR: 'CREATE_CERTIFICATE_FETCH_ERROR',
  CREATE_CERTIFICATE_FETCH_SUCCESS: 'CREATE_CERTIFICATE_FETCH_SUCCESS',
  UPDATE_CERTIFICATE_FETCH_REQUEST: 'UPDATE_CERTIFICATE_FETCH_REQUEST',
  UPDATE_CERTIFICATE_FETCH_ERROR: 'UPDATE_CERTIFICATE_FETCH_ERROR',
  UPDATE_CERTIFICATE_FETCH_SUCCESS: 'UPDATE_CERTIFICATE_FETCH_SUCCESS',
  GET_CERTIFICATE_FETCH_REQUEST: 'GET_CERTIFICATE_FETCH_REQUEST',
  GET_CERTIFICATE_FETCH_ERROR: 'GET_CERTIFICATE_FETCH_ERROR',
  GET_CERTIFICATE_FETCH_SUCCESS: 'GET_CERTIFICATE_FETCH_SUCCESS',
  PUSH_NEW_CERTIFICATE_TO_LIST: 'PUSH_NEW_CERTIFICATE_TO_LIST',
  RESET_CERTIFICATE_FORM: 'RESET_CERTIFICATE_FORM',
  UPDATE_ITEM_ON_LIST: 'UPDATE_ITEM_ON_LIST'
});