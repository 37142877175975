import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get } from '../util';

const CATEGORY_MODEL = 'category';
const MANAGER = 'manager'
const FORMS = 'forms';

//#region GET
export const getCategory = async ({ header }) => { 
  const pathUrl = `${endpoints.API_FORMS}/${MANAGER}/${CATEGORY_MODEL}/${FORMS}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
