export default {
  SECTION: 'create',

  CREATE_WORKSPACES_FETCH_REQUEST: 'CREATE_WORKSPACES_FETCH_REQUEST',
  CREATE_WORKSPACES_FETCH_SUCCESS: 'CREATE_WORKSPACES_FETCH_SUCCESS',
  CREATE_WORKSPACES_FETCH_ERROR: 'CREATE_WORKSPACES_FETCH_ERROR',
  CREATE_WORKSPACES_SAVE_AVATAR_SUCCESS: 'CREATE_WORKSPACES_SAVE_AVATAR_SUCCESS',
  CREATE_WORKSPACES_SAVE_AVATAR_ERROR: 'CREATE_WORKSPACES_SAVE_AVATAR_ERROR',

  CREATE_WORKSPACES_CHECK_CEP_INFO_FETCH_SUCCESS: 'CREATE_WORKSPACES_CHECK_CEP_INFO_FETCH_SUCCESS',
  CREATE_WORKSPACES_CHECK_CEP_INFO_FETCH_ERROR: 'CREATE_WORKSPACES_CHECK_CEP_INFO_FETCH_ERROR',

};
