export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listFormModels: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS = (state, action) => {
  const { listFormModels } = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listFormModels: false
          },
          api: {
            ...state.configuration.medicalRecord.forms.api,
            listFormModels,
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_GLOBAL_FETCH_SUCCESS = (state, action) => {
  const { listFormModelsGlobals = [] } = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listFormModels: false
          },
          api: {
            ...state.configuration.medicalRecord.forms.api,
            listFormModelsGlobals
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listFormModels: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listDrafts: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS = (state, action) => {
  const { listDrafts } = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listDrafts: false
          },
          api: {
            ...state.configuration.medicalRecord.forms.api,
            listDrafts
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            listDrafts: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createFormEmpty: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createFormEmpty: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createFormEmpty: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createDraft: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createDraft: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createDraft: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createDraft: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createDraft: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            createDraft: false
          }
        }
      }
    }
  });
};

export const PERFIL_FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS = (
  state,
  action
) => {
  const { status } = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            getCategories: status,
          },
        }
      }
    }
  });
}

export const PERFIL_FORM_GET_CATEGORIES_FORM_FETCH_SUCCESS = (
  state, action
) => {
  const { categoriesForms } = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            getCategories: false,
          },
          api: {
            ...state.configuration.medicalRecord.forms.api,
            categoriesForms
          }
        }
      }
    }
  });
}

export const PERFIL_FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS = (
  state,
  action
) => {
  const { categoriesAllForms } = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            getCategories: false,
          },
          api: {
            ...state.configuration.medicalRecord.forms.api,
            categoriesAllForms
          }
        }
      }
    }
  });

}

export const SET_SIDEBAR_BUTTON_SELECT_FORM_PERFIL = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          sidebar: {
            ...state.configuration.medicalRecord.forms.sidebar,
            ...action.payload,
          }
        }
      }
    }
  });
}

export const SET_TAB_SELECTED_BUTTON_SELECT_FORM_PERFIL = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          tabSelected: action.payload
        }
      }
    }
  });
}

export const SET_FORMS_FILTER_FORM_PERFIL = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          api: {
            ...state.configuration.medicalRecord.forms.api,
            filter: action.payload
          }
        }
      }
    }
  });
}

export const SET_CATEGORY_SELECTED_FORM_PERFIL = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          api: {
            ...state.configuration.medicalRecord.forms.api,
            categorySelected: action.payload
          }
        }
      }
    }
  });
}

export const SET_AUTHORS_FORMS_PERFIL = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          api: {
            ...state.configuration.medicalRecord.forms.api,
            authors: action.payload,
          }
        }
      }
    }
  });
}

export const SET_FAVORITES_FORM_MODELS_PERFIL = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          api: {
            ...state.configuration.medicalRecord.forms.api,
            favorites: {
              ...state.configuration.medicalRecord.forms.api.favorites,
              ...action.payload,
            }
          }
        }
      }
    }
  });
}

export const SAVE_SECTION_FORM_PERFIL_CONFIG_FETCH_REQUEST = (
  state, action
) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        forms: {
          ...state.configuration.medicalRecord.forms,
          fetching: {
            ...state.configuration.medicalRecord.forms.fetching,
            saveSections: action.payload,
          },
        }
      }
    }
  });
}