import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const   FOLDERMODEL = 'folder';

//#region GET
export const getFolder = async ({ header, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}?folderType=${folderType}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFolderById = async ({ header, folderId, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}/${folderId}?folderType=${folderType}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createFolder = async ({ header, params, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}?folderType=${folderType}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateFolder = async ({ header, params, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}?folderType=${folderType}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateFolderChildren = async ({ header, params, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}/folderChildren?folderType=${folderType}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateFolderDocument = async ({ header, params, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}/folderDocument?folderType=${folderType}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const moveDocument = async ({ header, params, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}/moveDocument?folderType=${folderType}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE
export const deleteFolder = async ({ header, params, folderType }) => {
  const pathUrl = `${endpoints.API_DOCS}/${FOLDERMODEL}?folderType=${folderType}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};


//endregion
