import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';
import axiosRef from 'axios';

import { get, del, post, put } from '../util';

const MODELS = 'models';
const INACTIVATE = 'inactivate';
const LIST = 'list';
const PUBLISHED = 'published';
const MANAGER = 'manager';

//#region GET
export const getDocsModels = async ({ header }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getDocsModelsManager = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${MODELS}/${LIST}/${PUBLISHED}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const getDocModelManagerById = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${MODELS}/${PUBLISHED}/${params.modelId}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const createDocByDocModelManager = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${MODELS}/duplicateModel/${params.docModelId}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const getDocModelById = async ({ header, docModelId }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}/${docModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getDocsModelsPublished = async ({ header }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}/${LIST}/${MODELS}/${PUBLISHED}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const duplicateModelDocById = async ({ header, docModelId }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}/duplicateModel/${docModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion /list/models/published

//#region POST
export const createDocModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateDocModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const inactivateDocModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}/${INACTIVATE}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};
//endregion

//#region DELETE
export const deleteDocModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${MODELS}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//endregion
