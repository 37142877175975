import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const   SIGNATURE = 'signature';
const CREATE = 'create';
const SIGNATORY = 'signatory';
const DOCUMENT = 'document';
const SIGNATORIES = 'signatories'
const UPDATE = 'update';
const CHECK = 'check';
const AUTHENTICITY = 'authenticity';
const CODE = 'code';
const EMAIL = 'email';
const VALIDATION = 'validation';
const DOWNLOAD = 'download';



//#region POST
export const createSignature= async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${CREATE}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

export const updateSignature= async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${UPDATE}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const getSignatureDocumentBySignatoryId = async ({ header, signatoryId }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${DOCUMENT}/${SIGNATORY}/${signatoryId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const downloadDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${DOWNLOAD}/${DOCUMENT}/${params.documentId}?type=${params.type}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const getSignatureByDocumentId = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${SIGNATORIES}/${params.documentId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
}

export const updateSignatory = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${DOCUMENT}/${SIGNATORY}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
}

export const sendSignature = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${DOCUMENT}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const checkSignatureAuthenticity = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${CHECK}/${AUTHENTICITY}/${params.signatureId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl, params);
}

export const codeSendEmail = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${SIGNATURE}/${CODE}/${VALIDATION}/${params.signatoryId}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}
