import types        from './constants';
import initialState from './initialState';

const INITIAL_STATE = {
  ...initialState
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DRAWER_OPEN: {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          done: true
        }
      };
    }
    case types.DRAWER_CLOSED: {
      return { ...state, drawer: { ...state.drawer, done: false } };
    }
    case types.DRAWER_TOGGLE: {
      return { ...state, drawer: { ...state.drawer, done: !state.drawer.done } };
    }
    case types.MAIN_GET_STATE_CITY: {
      return ({
        ...state,
        api: {
          ...state.api,
          listCityIndexed: {
            ...state.api.listCityIndexed,
            ...action.payload.listCityIndexed
          },
          listState: action.payload.listState
        }
      });
    }
    case types.DRAWER_SET_MANAGER_APP_BAR: {
      return ({
        ...state,
        appBar: {
          ...state.appBar,
          ...action.payload
        }
      });
    }

    case types.DRAWER_RESET_APP_BAR: {
      return ({
        ...state,
        appBar: {
          context: null,
          goBackValue: null
        }
      });
    }
    default:
      return state;
  }
};