export const USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      usersAccount: true,
      usersAccountError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      usersAccount: false,
      usersAccountError: true
    },
    error: {
      ...state[section].error,
      done: true
    }
  }
});

export const USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      usersAccount: false
    },
    api: {
      ...state[section].api,
      listUsersAccount: action.payload.listUsersAccount
    }
  }
});

export const USER_OVERVIEW_INVITE_CORRECTION_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_OVERVIEW_INVITE_CORRECTION_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    },
    api: {
      ...state[section].api,
      emailModifyDialog: false
    }
  }
});

export const USER_OVERVIEW_INVITE_CORRECTION_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    },
    error: {
      ...state[section].error,
      done: true
      // messages: [...state[section].error.messages, action.payload.userFeedback]
    }
  }
});

export const USER_OVERVIEW_RESEND_INVITATION_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_OVERVIEW_RESEND_INVITATION_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    },
    error: {
      ...state[section].error,
      done: true
    }
  }
});

export const USER_OVERVIEW_RESEND_INVITATION_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    }
  }
});

export const USER_OVERVIEW_DELETE_INVITATION_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_OVERVIEW_DELETE_INVITATION_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    },
    error: {
      ...state[section].error,
      done: true
    }
  }
});

export const USER_OVERVIEW_DELETE_INVITATION_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    }
  }
});

export const USER_OVERVIEW_INACTIVATE_USER_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_OVERVIEW_INACTIVATE_USER_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    },
    error: {
      ...state[section].error,
      done: true
    }
  }
});

export const USER_OVERVIEW_INACTIVATE_USER_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      executingAction: false
    }
  }
});

export const USER_OVERVIEW_SET_USERS_LIMIT = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      maxUser: action.payload.defaultUsersLimit,
      userCount: action.payload.usersQnt
    }
  }
});
