

export const SET_DOCUMENT_SHARED_SUCCESS = (state, action) => {
    return ({
        ...state,
        shared: {
            ...state.shared,
            document: action.payload
        }
    });
};

export const SET_REQUIRE_PASSWORD = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            requirePassword: action.payload
        }
    });
};

export const SET_NOT_FOUND_DOCUMENT = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            notFoundDocument: action.payload
        }
    });
};

export const SET_FETCH_GET_SHARED_DOCUMENT = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            fetchDocument: action.payload
        }
    });
}

export const SET_BACKGROUND_DOCUMENT = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            background: action.payload
        }
    });
}
export const SET_TABLE_OF_CONTENTS_SHARED = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            tableOfContents: action.payload
        }
    });
}

export const SET_TOGGLE_SIDEBAR_SHARED = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            sidebar: !state.shared.sidebar
        }
    });
}

export const SET_HISTORY_PAGE_SHARED = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            historyPage: action.payload
        }
    });
}

export const SET_PAGE_DOC_SHARED = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            pageDoc: action.payload
        }
    });
}

export const SET_CHANGE_PAGE_SHARED = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            changePage: action.payload
        }
    });
}

export const SET_FETCH_REQUEST_iNITITAL_GET_SHARED_DOCUMENT = (state, action) => {
    return ({
        ...state,
        shared:{
            ...state.shared,
            fetchRequestInitialDocument: action.payload
        }
    });
}
