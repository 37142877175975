/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

const ACCOUNT = 'account';
const WORKSPACE = 'workspace';
const QUANTITY = 'quantity';
const USER = 'user';
const INATIVE = 'inactivate';
const ATIVE = 'activate';
const INTEGRATION = 'integration';
const EMAIL = 'email';
const RESEND = 'resend';
const CONFIRM = 'confirm';
const PERMISSION = 'permission';
const PROFILE = 'profile';
const PATIENT = 'patient';
const BOND = 'bond';
const IMPORT = 'import';
const WITHOUT = 'without';
const DOCUMENTS = 'documents';
const INFO = 'info';
const SEARCH = 'search';
const LIST = 'list';
const PROFESSIONAL = 'professional'
const GROUP = 'group';

const _get = async props => {
  const { axios, pathUrl } = props;

  try {
    return (await axios.get(pathUrl)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

const _post = async (axios, pathUrl, params = {}) => {
  try {
    return (await axios.post(pathUrl, params)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

const _put = async (axios, pathUrl, params = {}) => {
  try {
    return (await axios.put(pathUrl, params)).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

const _delete = async (axios, pathUrl, params) => {
  try {
    return (await axios.delete(pathUrl, { data: params })).data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

//#region GET
export const getWorkspaces = async ({ accountId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${WORKSPACE}s`;
  const axios = interceptor();
  return await _get({ axios, pathUrl });
};

export const getAccountById = async ({ businessId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${businessId}`;
  const axios = interceptor();
  return await _get({ axios, pathUrl });
};

export const getUserByIdAccount = async ({ accountId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}`;
  const axios = interceptor();
  return await _get({ axios, pathUrl });
};

export const getUsersSearchByWorkspace = async ({accountId, workspaceId, value=''}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${WORKSPACE}/${workspaceId}/${USER}s/${SEARCH}?value=${value}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getUserInfoByIdAccount = async ({ accountId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${INFO}`;
  const axios = interceptor();
  return await _get({ axios, pathUrl });
};

export const getSearchClient = async ({ accountId, workspaceId, value }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}?value=${value}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getSearchClientAccount = async ({ accountId, workspaceId, value }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${ACCOUNT}/list/accountId?value=${value}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getAllClients = async ({ accountId, workspaceId, value = '' }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}?value=${value}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getClientsByBirthday = async ({ accountId, workspaceId, startAt, endAt }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/byBirthday?startAt=${startAt}&endAt=${endAt}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getClientsByHangingAttendance = async ({ accountId, workspaceId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/byHangingAttendance`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getClientById = async ({ accountId, workspaceId, clientId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${clientId}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getClientsByIds = async ({ accountId, clientIds }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${LIST}/ids`;
  const axios = interceptor({ accountId });
  const params = { clientIds };
  return await _post(axios, pathUrl, params);
};

export const getProfessionalsByIds = async ({ accountId, professionalsIds }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PROFESSIONAL}/${LIST}/ids`;
  const axios = interceptor({ accountId });
  const params = { professionalsIds };
  return await _post(axios, pathUrl, params);
};

export const getSocialBond = async ({ accountId, workspaceId, patientId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${patientId}/${BOND}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _get({ axios, pathUrl });
};

export const getUsersQuantity = async ({ accountId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}s/${QUANTITY}`;
  const axios = interceptor({ accountId });
  return await _get({ axios, pathUrl });
};

export const getGroupPermissionByAccountId = async ({ accountId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${GROUP}/${ACCOUNT}`;
  const axios = interceptor({ accountId });
  return await _get({ axios, pathUrl });
};

// export const getBillingData = async ({ accountId, header }) => {
//   const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${DATA}`;
//   const axios = interceptor(header);
//   const params = { accountId };
//   return await _get({axios, pathUrl, params});
// };

// export const getBillingConfig = async ({ accountId, header }) => {
//   const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${CONFIG}`;
//   const axios = interceptor(header);
//   const params = { accountId };
//   return await _get({axios, pathUrl, params});
// };
//#endregion

//#region POST
export const importLocusprimePatients = async ({ token, header }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${IMPORT}/${PATIENT}s`;
  const axios = interceptor(header);
  const params = { token };
  return await _post(axios, pathUrl, params);
};

export const createAccount = async ({ name, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}`;
  const axios = interceptor();
  const params = { name, userId };
  return await _post(axios, pathUrl, params);
};

export const createIntegration = async ({ key }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INTEGRATION}`;
  const axios = interceptor();
  const params = { key };
  return await _post(axios, pathUrl, params);
};

export const resendEmail = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${EMAIL}/${RESEND}/${CONFIRM}`;
  const axios = interceptor();
  return await _post({ axios, pathUrl, params });
};

export const postClient = async ({ accountId, workspaceId, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _post(axios, pathUrl, params);
};

export const postSocialBond = async ({ accountId, workspaceId, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${BOND}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _post(axios, pathUrl, params);
};

export const postClientAndSocialBond = async ({
  accountId,
  workspaceId,
  params
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${WITHOUT}/${DOCUMENTS}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateAccount = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}`;
  const axios = interceptor();
  return await _put(axios, pathUrl, params);
};

export const updateUserByBusiness = async ({
  accountId,
  userId,
  accountProfileId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${PERMISSION}/${PROFILE}`;
  const axios = interceptor();
  return await _put(axios, pathUrl, { accountProfileId });
};

export const setInativeUserAccount = async ({ accountId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${INATIVE}`;
  const axios = interceptor();
  return await _put(axios, pathUrl);
};

export const setAtiveUserAccount = async ({ accountId, userId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${ATIVE}`;
  const axios = interceptor();
  return await _put(axios, pathUrl);
};

export const putClient = async ({ accountId, workspaceId, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _put(axios, pathUrl, params);
};

export const putSocialBond = async ({ accountId, workspaceId, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${BOND}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _put(axios, pathUrl, params);
};

export const deleteSocialBond = async ({
  accountId,
  workspaceId,
  socialBondId,
  patientId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PATIENT}/${BOND}/${socialBondId}`;
  const axios = interceptor({ accountId, workspaceId });
  return await _delete(axios, pathUrl, { patientId });
};

// export const updateBillingData = async ({ header, params }) => {
//   const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${DATA}`;
//   const axios   = interceptor(header);
//   return await _put(axios, pathUrl, params);
// };
//#endregion

// Script
// export const updateUserPermissions = async () => {
//   const params = {
//     oldAccFunctIds: [14, 15],
//     newAccFunctIds: [9],
//     oldWorksFunctIds: [5],
//     newWorksFunctIds: [6, 5],
//     toDeleteFunctionalityIds: [7, 16],
//     accountFunctIdsToUpdate: [
//       1,
//       1,
//       6,
//       3,
//       2,
//       7,
//       null,
//       5,
//       4,
//       8,
//       null,
//       null,
//       null,
//       null,
//       null,
//       null,
//       10,
//       11,
//       12,
//       13
//     ],
//     workspaceFunctIdsToUpdate: [4, 1, 3, 5, null, 2]
//   };
//   const pathUrl = `${endpoints.API_ACCOUNT}/account/update/users/permissions`;
//   const axios = interceptor();
//   return await _put(axios, pathUrl, params);
// };
