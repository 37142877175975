import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';

const EXAM  = 'exam';
const FORM  = 'form';

//#region GET
export const getSearchExam = async ({header, value}) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXAM}?value=${value}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const addExamByExamsDataId = async ({header, formId, examsDataId, examId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FORM}/${formId}/${EXAM}/${examsDataId}/${examId}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl);
};
//#endregion