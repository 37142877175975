import types from './constant';

const INITIAL_STATE = {
  isFirstLoading: true,
  redirect: false,
  error: {
    done    : false,
    messages: []
  },
  fetching: {
    openInvite: false,
    openInviteError: false,
    confirmInvite: false,
    confirmInviteError: false
  },
  api: {
    inviteData: {
      id: 0,
      token: ''
    },
    inviteUser: {
      id: 0,
      name: '',
      email: ''
    }
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.INVITE_OPEN_IS_FIRST_LOADING: {
      return ({
        ...state,
        isFirstLoading: action.payload
      });
    }
    case types.INVITE_OPEN_FETCH_REQUEST: {
      return ({
        ...state,
        isFirstLoading: false,
        fetching: {
          ...state.fetching,
          openInvite: true,
          openInviteError: false
        },
        api: {
          ...state.api,
          inviteData: {
            ...state.api.inviteData,
            id: 0,
            token: ''
          },
          inviteUser: {
            ...state.api.inviteUser,
            name: '',
            email: ''
          }
        },
        error: {
          done: false,
          messages: []
        }
      });
    }
    case types.INVITE_OPEN_FETCH_ERROR: {
      return ({
        ...state,
        isFirstLoading: false,
        fetching: {
          ...state.fetching,
          openInvite: false,
          openInviteError: true
        },
        error: {
          ...state.error,
          done: true,
          messages: [
            ...state.error.messages,
            action.payload.message
          ]
        }
      });
    }
    case types.INVITE_OPEN_FETCH_SUCCESS: {
      return ({
        ...state,
        isFirstLoading: false,
        fetching: {
          ...state.fetching,
          openInvite: false
        },
        api: {
          ...state.api,
          inviteData: {
            ...state.api.inviteData,
            ...action.payload.inviteData
          },
          inviteUser: {
            ...state.api.inviteUser,
            ...action.payload.inviteUser
          }
        }
      });
    }
    case types.INVITE_CONFIRM_FETCH_REQUEST: {
      return ({
        ...state,
        fetching: {
          ...state.fetching,
          confirmInvite: true,
          confirmInviteError: false
        },
        error: {
          done: false,
          messages: []
        }
      });
    }
    case types.INVITE_CONFIRM_FETCH_ERROR: {
      return ({
        ...state,
        fetching: {
          ...state.fetching,
          confirmInvite: false,
          confirmInviteError: true
        },
        error: {
          ...state.error,
          done: true,
          messages: [
            ...state.error.messages,
            action.payload.message
          ]
        }
      });
    }
    case types.INVITE_CONFIRM_FETCH_SUCCESS: {
      return ({
        ...state,
        isFirstLoading: false,
        fetching: {
          ...state.fetching,
          confirmInvite: false
        },
        redirect: true
      });
    }
    default:
      return state;
  }
};