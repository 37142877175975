import overviewInitialState from './Overview/initialState';
import types from './Overview/constant';
import * as commonReducerOverview from './Overview/reducerGenerator';

import perfilConfigurationMedicalRecordOverview from './Configuration/MedicalRecord/Overview/containers/initialState';
import * as commonReducerPerfilConfigurationMedicalRecordOverview from './Configuration/MedicalRecord/Overview/containers/reducerGenerator';

import perfilConfigurationMedicalRecordFields from './Configuration/MedicalRecord/Fields/config/initialState';
import * as commonReducerPerfilConfigurationMedicalRecordFields from './Configuration/MedicalRecord/Fields/config/reducerGenerator';

import perfilConfigurationMedicalRecordForms from './Configuration/MedicalRecord/Forms/config/initialState';
import * as commonReducerPerfilConfigurationMedicalRecordForms from './Configuration/MedicalRecord/Forms/config/reducerGenerator';

import initialStatePerfilConfigurationMedicalRecordSections from './Configuration/MedicalRecord/Sections/config/initialState';
import * as commonReducerPerfilConfigurationMedicalRecordSections from './Configuration/MedicalRecord/Sections/config/reducerGenerator';

import initialStateDrawer from './Configuration/DrawerSections/config/initialState';
import * as commonReducerDrawer from './Configuration/DrawerSections/config/reducerGenerator';

const INITIAL_STATE = {
  overview: { ...overviewInitialState },
  configuration: {
    medicalRecord: {
      ...perfilConfigurationMedicalRecordFields,
      overview: { ...perfilConfigurationMedicalRecordOverview },
      forms: { ...perfilConfigurationMedicalRecordForms },
      sections: { ...initialStatePerfilConfigurationMedicalRecordSections },
    },
  },
  drawer: { ...initialStateDrawer },
};

export default (state = INITIAL_STATE, action) => {
  if (typeof commonReducerOverview[action.type] === 'function') {
    return commonReducerOverview[action.type](types.SECTION, state, action);
  } else if (
    typeof commonReducerPerfilConfigurationMedicalRecordOverview[
      action.type
    ] === 'function'
  ) {
    return commonReducerPerfilConfigurationMedicalRecordOverview[action.type](
      state,
      action
    );
  } else if (
    typeof commonReducerPerfilConfigurationMedicalRecordFields[action.type] ===
    'function'
  ) {
    return commonReducerPerfilConfigurationMedicalRecordFields[action.type](
      state,
      action
    );
  } else if (
    typeof commonReducerPerfilConfigurationMedicalRecordForms[action.type] ===
    'function'
  ) {
    return commonReducerPerfilConfigurationMedicalRecordForms[action.type](
      state,
      action
    );
  } else if (
    typeof commonReducerPerfilConfigurationMedicalRecordSections[
      action.type
    ] === 'function'
  ) {
    return commonReducerPerfilConfigurationMedicalRecordSections[action.type](
      state,
      action
    );
  } else if (typeof commonReducerDrawer[action.type] === 'function') {
    return commonReducerDrawer[action.type](state, action);
  } else {
    return state;
  }
};
