export default ({
  isFirstLoading: true,
  error: {
    done    : false,
    messages: []
  },
  fetching: {
    planInfo        : false,
    planInfoError   : false
  },
  api: {
    infoPlan: {
      usersLimit      : 0,
      workspacesLimit : 0,
      price           : 0,
      namePlan        : ''
    },
    infoSummary: {
      userTotal     : 0,
      workspaceTotal: 0
    }
  },
});