export default ({
  isFirstLoading: true,
  error: {
    done    : false,
    messages: []
  },
  fetching: {
    workspace: false,
    workspaceError: false,
    isCreateWorkspace: false,
    isCreateWorkspaceError: false,
    isUpdateWorkspace: false,
    isUpdateWorkspaceError: false,
    isUpdateUserWorkspace: false,
    isUpdateUserWorkspaceError: false
  },
  loadingWorkspaces: {},
  api: {
    workspaces: {},
    listWorkspace: [],
    checkCep: null,
    business: {}
  },
  form: {
    workspace: '',
    user: ''
  }
});