export default {
  estado: [],
  city: {},
  showVerifyEmail: false,
  resendEmail: '',
  captchaVerified: false,
  fetching: {
    estado: false,
    estadoError: false,
    city: false,
    cityError: false,
    validateStep: false,
    validateStepError: false,
    validateCompany: false,
    validateCompanyError: false,
    resendEmail: false
  },
  step: {
    value: 0,
    active: 1
  },
  error: {
    done: false,
    message: []
  }
};