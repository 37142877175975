import integrationsInitialState             from './overview/initialState';
import typesIntegrations                from './overview/constant';
import * as commonReducerIntegrations   from './overview/reducerGenerator';

const INITIAL_STATE = {
  ...integrationsInitialState,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region INTEGRATIONS
    case typesIntegrations.BUSINESS_INTEGRATION_SET_IMPORT_LOCUSPRIME_PATIENTS_TOKEN:  return commonReducerIntegrations.BUSINESS_INTEGRATION_SET_IMPORT_LOCUSPRIME_PATIENTS_TOKEN(typesIntegrations.SECTION, state, action);

    case typesIntegrations.BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_REQUEST: return commonReducerIntegrations.BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_REQUEST(typesIntegrations.SECTION, state, action);
    case typesIntegrations.BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_SUCCESS: return commonReducerIntegrations.BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_SUCCESS(typesIntegrations.SECTION, state, action);
    case typesIntegrations.BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_ERROR: return commonReducerIntegrations.BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_ERROR(typesIntegrations.SECTION, state, action);

    //#endregion
    default:
      return state;
  }
};