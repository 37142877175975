import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { Icon } from 'antd';
import classnames from 'classnames';

import * as util from '@Util';

class Form extends Component {
  state = { sendTimeoutCount: 30, clearIntervalId: null };

  render() {
    const {
      // isLoading,
      fetching,
      classes,
      // onSubmit,
      handleSubmit
      // recoverSent
    } = this.props;
    const { sendTimeoutCount, clearIntervalId } = this.state;

    // let recover= true;
    const recover = clearIntervalId ? true : false;
    if (sendTimeoutCount === 0) {
      clearInterval(clearIntervalId);
      this.setState({ sendTimeoutCount: 30, clearIntervalId: null });
    }

    return (
      <form onSubmit={handleSubmit(this.sendRecoverEmail)}>
        <Grid container direction={'column'} className={classes.container}>
          <Grid item lg={12} md={12} sm={12} style={{ marginBottom: '40px' }}>
            {/* <Typography variant="h5">
              Não está conseguindo se conectar? Insira abaixo o e-mail cadastrado para recuperar sua senha.
            </Typography> */}
            <span className={classes.headerFormActive}>
              Não está conseguindo se conectar?
              <br /> Insira abaixo o e-mail cadastrado para recuperar sua senha.
            </span>
            {fetching.recoverSuccessful && (
              <span
                className={classes.headerFormActive}
                style={{ color: '#00c500' }}
              >
                <br />
                <br />
                Link de recuperação de senha enviado com sucesso no e-mail
                inserido. <br /> Este link possui duração de 2 horas.
              </span>
            )}
          </Grid>
          <Grid item style={{ width: '100%' }}>
            <FormControl className={classes.formControl}>
              <Field
                required
                type={'text'}
                name={'email'}
                placeholder={'Email'}
                label={'Email'}
                className={classes.textField}
                InputProps={{
                  classes: {
                    underline: classes.underline,
                    input: classes.input
                  }
                }}
                InputLabelProps={{ shrink: true }}
                validate={[util.email, util.required]}
                component={this.renderField}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ width: '100%' }} className={classes.actions}>
            <Grid container direction="column">
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                justify={'center'}
                className={classnames(classes.flex, classes.marginBottom10)}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button
                    // onSubmit={this.sendRecoverEmail}
                    type="submit"
                    variant={'contained'}
                    color={'primary'}
                    classes={{
                      root: classes.buttonStepRoot,
                      containedPrimary: classes.buttonStepContainedPrimary
                    }}
                    // disabled={fetching.createAccount}
                    disabled={recover}
                  >
                    <span>
                      {' '}
                      {!recover
                        ? 'Enviar e-mail de recuperação'
                        : sendTimeoutCount > 0
                          ? `${sendTimeoutCount}s Enviar`
                          : ''}
                    </span>
                    {fetching.createAccount && (
                      <span className={classes.iconLoading}>
                        {' '}
                        <Icon type={'loading'} />{' '}
                      </span>
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                className={classnames(
                  classes.flex,
                  classes.alignLeft,
                  classes.alignSelfCenter
                )}
              >
                <span
                  className={classes.buttonRegisterlabel}
                  onClick={this.redirectRoute}
                >
                  {' '}
                  Voltar para a tela inicial{' '}
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  sendRecoverEmail = values => {
    if (this.state.sendTimeoutCount === 30) {
      const id = setInterval(() => {
        this.setState({ sendTimeoutCount: this.state.sendTimeoutCount - 1 });
      }, 1000);
      this.setState({ clearIntervalId: id });
      this.props.onSubmit(values);
    }
  };

  renderField = ({ input, meta: { touched, error }, ...custom }) => {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField {...input} {...custom} variant="outlined" />
        {touched &&
          (error && (
            <FormHelperText className={classes.errorText}>
              {' '}
              {error}
            </FormHelperText>
          ))}
      </Fragment>
    );
  };

  redirectRoute = () => {
    this.props.history.push('/');
  };
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  validation: PropTypes.shape({
    email: PropTypes.bool.isRequired,
    emailFormat: PropTypes.bool.isRequired,
    password: PropTypes.bool.isRequired,
    passwordFormat: PropTypes.bool.isRequired
  }).isRequired
};

export default reduxForm({ form: 'Recover' })(withRouter(Form));
