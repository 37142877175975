export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_REQUEST = (
  state,
  action
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      fields: {
        ...state.medicalRecord.fields,
        fetching: {
          ...state.medicalRecord.fields.fetching,
          getFields: true,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_SUCCESS = (
  state,
  action
) => {
  const { tableFields } = action.payload;
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      fields: {
        ...state.medicalRecord.fields,
        fetching: {
          ...state.medicalRecord.fields.fetching,
          getFields: false,
        },
        api: {
          ...state.medicalRecord.fields.api,
          tableFields,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_ERROR = (
  state,
  action
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      fields: {
        ...state.medicalRecord.fields,
        fetching: {
          ...state.medicalRecord.fields.fetching,
          getFields: false,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FROM_MANAGER_FETCH_REQUEST =
  (state) => {
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        fieldsFromManager: {
          ...state.medicalRecord.fieldsFromManager,
          fetching: {
            ...state.medicalRecord.fieldsFromManager.fetching,
            getFieldsFromManager: true,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FROM_MANAGER_FETCH_SUCCESS =
  (state, action) => {
    const { tableFieldsFromManager } = action.payload;
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        fieldsFromManager: {
          ...state.medicalRecord.fieldsFromManager,
          fetching: {
            ...state.medicalRecord.fieldsFromManager.fetching,
            getFieldsFromManager: false,
          },
          api: {
            ...state.medicalRecord.fieldsFromManager.api,
            tableFieldsFromManager,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FROM_MANAGER_FETCH_ERROR =
  (state) => {
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        fieldsFromManager: {
          ...state.medicalRecord.fieldsFromManager,
          fetching: {
            ...state.medicalRecord.fieldsFromManager.fetching,
            getFieldsFromManager: false,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_REQUEST =
  (state, action) => {
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        fields: {
          ...state.medicalRecord.fields,
          fetching: {
            ...state.medicalRecord.fields.fetching,
            getFieldModelById: true,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_SUCCESS =
  (state, action) => {
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        fields: {
          ...state.medicalRecord.fields,
          fetching: {
            ...state.medicalRecord.fields.fetching,
            getFieldModelById: false,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_ERROR = (
  state,
  action
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      fields: {
        ...state.medicalRecord.fields,
        fetching: {
          ...state.medicalRecord.fields.fetching,
          getFieldModelById: false,
        },
      },
    },
  };
};
