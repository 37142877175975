import { toastr } from 'react-redux-toastr';
import { reset, formValueSelector } from 'redux-form';
import lodashUniq from 'lodash/uniq';

import ESTADOS from '@Constants/estados';
import CIDADES from '@Constants/cidades';
import * as util from '@Util';
import * as actionConfig from '@Actions/config';
import * as actionsSignIn from '../signIn/action';
import * as types from '../constants';
import selector from './selector';
import systemConfigs from '@Constants/systemConfigs';

export const getState = () => ({
  type: types.LOGIN_REGISTER_GET_STATE,
  payload: { estado: ESTADOS },
});

export const verifyRecaptchaFetchRequest = () => ({
  type: types.LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_REQUEST,
});
export const verifyRecaptchaFetchError = (data) => ({
  type: types.LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_ERROR,
  payload: data,
});
export const verifyRecaptchaFetchSuccess = (data) => ({
  type: types.LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_SUCCESS,
  payload: data,
});

export const getValidateStepFetchRequest = () => ({
  type: types.LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_REQUEST,
});
export const getValidateStepFetchError = (data) => ({
  type: types.LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_ERROR,
  payload: data,
});
export const getValidateStepFetchSuccess = (data) => ({
  type: types.LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_SUCCESS,
  payload: data,
});

export const createAccountFetchRequest = () => ({
  type: types.LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_REQUEST,
});
export const createAccountFetchError = (data) => ({
  type: types.LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_ERROR,
  payload: data,
});
export const createAccountFetchSuccess = (data) => ({
  type: types.LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_SUCCESS,
  payload: data,
});

export const resendEmailFetchRequest = () => ({
  type: types.LOGIN_REGISTER_RESSEND_EMAIL_FETCH_REQUEST,
});
export const resendEmailFetchError = () => ({
  type: types.LOGIN_REGISTER_RESSEND_EMAIL_FETCH_ERROR,
});
export const resendEmailFetchSuccess = () => ({
  type: types.LOGIN_REGISTER_RESSEND_EMAIL_FETCH_SUCCESS,
});

export const stepNext = (data) => ({
  type: types.LOGIN_REGISTER_STEP_NEXT,
  payload: data,
});
export const stepPrev = (data) => ({
  type: types.LOGIN_REGISTER_STEP_PREV,
  payload: data,
});

export const hideShowVerifyEmail = () => ({
  type: types.LOGIN_REGISTER_HIDE_SHOW_MESSAGE,
});

export const getCity = () => async (dispatch, getState, api) => {
  const cityIndex = {};

  let stateId = CIDADES.map((item) => item.stateId);
  stateId = lodashUniq(stateId);

  stateId.forEach((state) => {
    cityIndex[state] = CIDADES.filter((item) => item.stateId === state);
  });

  dispatch({
    type: types.LOGIN_REGISTER_GET_CITY,
    payload: { city: cityIndex },
  });
};

export const createAccount = (
  invite,
  gRecaptchaResponse1,
  gRecaptchaResponse2
) => async (dispatch, getState, api) => {
  const state = getState();
  const selectorFormUser = formValueSelector('FormUser');
  const user = selectorFormUser(
    state,
    'name',
    'email',
    'password',
    'state',
    'city',
    'cel',
    'cpf'
  );

  const data = {
    name: user.name,
    email: user.email,
    password: user.password,
    stateId: parseInt(user.state, 10),
    cityId: parseInt(user.city, 10),
    cel: user.cel.replace(/[^\d]/g, ''),
    cpf: user.cpf.replace(/[^\d]/g, ''),
    invite: invite ? invite : null,
    gRecaptchaResponse: gRecaptchaResponse1,
  };

  dispatch(createAccountFetchRequest());

  try {
    await api.login.createAccount({
      ...data,
      gRecaptchaResponse: gRecaptchaResponse2,
    });
    dispatch([
      createAccountFetchSuccess({ email: user.email }),
      reset('FormUser'),
    ]);

    dispatch([actionsSignIn.signIn(data)]);
    // toastr.success('Sucesso', 'Salvo com sucesso!');
  } catch (error) {
    if (error && error.message && error.redirect) {
      dispatch([
        createAccountFetchError({ message: error.message }),
        actionConfig.routeRedirect('/'),
      ]);
      return;
    } else if (error && error.message && !error.redirect) {
      dispatch(createAccountFetchError({ message: error.message }));
      return;
    }
    dispatch(
      createAccountFetchError({
        message: 'Não foi possível concluir a solicitação!',
      })
    );
  }
};

export const resendEmail = () => async (dispatch, getState, api) => {
  const state = getState();
  const email = selector.getEmail(state);

  try {
    dispatch(resendEmailFetchRequest());

    await api.account.resendEmail({ email });

    dispatch(resendEmailFetchSuccess());
    toastr.success('Sucesso', 'Email reenviado com sucesso!');
  } catch (error) {
    dispatch(util.managerExceptionURI(error, resendEmailFetchError));
    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

// export const verifyRecaptcha = ({token}) => async (dispatch, getState, api) => {
//   try {
//     dispatch(verifyRecaptchaFetchRequest());
//     const response = await api.recaptcha.recaptchaTokenVerification(token);

//     dispatch(verifyRecaptchaFetchSuccess(response.data));
//   } catch (error) {
//     dispatch(util.managerExceptionURI(error, verifyRecaptchaFetchError));
//     if (error && error.status === 401)
//       window.location = systemConfigs.AUTH_DOMAIN
//   }
// }
