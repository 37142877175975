import React from 'react';

import logo from '@Assets/images/logo.svg';

const Header = props => (
  <div className={props.classes.containerHeader}>
    <img src={logo} className={props.classes.toolBarImage} alt="logo" />
    {/* <h1 className={props.classes.title}>LocusPrime Comunicator</h1> */}
  </div>
);

export default Header;