export default ({
  isFirstLoading: true,
  business: {
    id: 0,
    accessType: 0,
    name: '',
    url: '',
    userApps: []
  },
  fetching: {
    isUserDashboard: false,
    isUserDashboardError: false,
    isCreateAccount: false,
    isCreateAccountError: false,
    isSelectedBusiness: false,
    isSelectedBusinessError: false
  },
  api: {
    listWorkspace: [],
    listAccount: []
  },
  error: {
    done: true,
    message: []
  },
  modal: {
    done: false
  }
});