import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const DOCUMENTS = 'documents';
const INACTIVATE = 'inactivate';
const ACCOUNT = 'account';
const LIST = 'list';
const SHARED = 'shared';

//#region GET
export const getDocuments = async ({ header }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getDocumentsAccount = async ({ header }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/${ACCOUNT}/${LIST}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getDocumentById = async ({ header, documentId }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/${documentId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const createDocByDocModel = async ({ header, docModelId }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/createDocument/${docModelId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const inactivateDocModel = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/${INACTIVATE}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};
//endregion

//#region DELETE
export const deleteDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//endregion

export const sharedDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/${SHARED}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
}

export const updateSharedDocument = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/${SHARED}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
}

export const getSharedDocument = async ({ params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${DOCUMENTS}/${SHARED}-doc`;
  const axios = interceptor();
  return await post(axios, pathUrl, params);
}
