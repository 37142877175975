import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';
import { get } from '../util';
import { del } from '../util';
import { post } from '../util';

const FILE = 'file';
const VIDEO = 'video';
const IMAGE = 'image';
const FILES = 'files';
const MEDIA = 'media';
const DOWNLOAD = 'download';
const DOCUMENTS = 'documents';
const ATTACHMENT = 'attachment';
const MEDICALRECORD = 'medicalrecords';
const DOCUMENT = 'document';
const PDF = 'pdf';
const GENERATE = 'generate';

//#region GET
export const downloadMedicalRecordsImage = async ({ header, params }) => {
  const { fileReference, fileName, medicalRecordsAttendanceId } = params;
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${medicalRecordsAttendanceId}/${MEDIA}/${IMAGE}/${FILE}/${fileReference}/${fileName}`;
  const axios = interceptor(header);
  const responseType = 'arraybuffer';
  return await get(axios, pathUrl, { responseType });
};

export const downloadMedicalRecordsAttachment = async ({ header, params }) => {
  const { fileReference, fileName, medicalRecordsAttendanceId } = params;
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${medicalRecordsAttendanceId}/${ATTACHMENT}/${FILE}/${fileReference}/${fileName}`;
  const axios = interceptor(header);
  const responseType = 'blob';
  return await get(axios, pathUrl, { responseType });
};

export const getMedicalRecordsVideoUrl = async ({ header, params }) => {
  const { fileReference, fileName, medicalRecordsAttendanceId } = params;
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${medicalRecordsAttendanceId}/${MEDIA}/${VIDEO}/${FILE}/${fileReference}/${fileName}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const downloadAttachmentDocument = async ({ params }) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${DOCUMENTS}/${FILE}/${DOWNLOAD}/${params.fileId}/${params.name}`;
  const axios = interceptor();
  const responseType = 'blob';
  return await get(axios, pathUrl, { responseType });
};

export const getUrlImageDocs = async ({name, fileId}) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${DOCUMENTS}/${FILE}/${IMAGE}/${fileId}/${name}`;
  const axios = interceptor();
  return await get(axios, pathUrl);
};

export const getDocumentPdfUrl = async ({name, fileId}) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${DOCUMENT}/${PDF}/${fileId}/${name}`;
  const axios = interceptor();
  const responseType = 'blob';
  return await get(axios, pathUrl, { responseType });
};
export const deleteImageDocs = async ({header, params}) => {
  const {fileId, name} = params;
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${DOCUMENTS}/${FILE}/${IMAGE}/${fileId}/${name}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl);
};

export const downloadMedicalRecordAttendanceByIdMediaFile = async ({
  header,
  attendanceId,
  fileReference,
  fileName,
}) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${attendanceId}/${MEDIA}/${IMAGE}/${FILE}/${fileReference}/${fileName}`;
  const axios = interceptor(header);
  const responseType = 'blob';
  return await get(axios, pathUrl, { responseType });
};
//#endregion

//#region DELETE
export const delFileMedicalRecordsAttendanceByIdMediaImageById = async ({
  header,
  attendanceId,
  attendanceMediaId,
}) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${attendanceId}/${MEDIA}/${IMAGE}/${attendanceMediaId}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl);
};

export const delFileMedicalRecordsAttendanceByIdMediaVideoById = async ({
  header,
  attendanceId,
  attendanceMediaId,
}) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${attendanceId}/${MEDIA}/${VIDEO}/${attendanceMediaId}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl);
};

export const delFileMedicalRecordsAttendanceByIdAttachment = async ({
  header,
  attendanceId,
  attendanceAttachmentId,
}) => {
  const pathUrl = `${endpoints.API_FILES}/${FILES}/${MEDICALRECORD}/${attendanceId}/${ATTACHMENT}/${attendanceAttachmentId}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl);
};

export const generateImageGraphic = async ({
  header,
  image
}) => {
  const pathUrl = `${endpoints.API_FILES}/${GENERATE}/${IMAGE}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, {image});
}

//#region
