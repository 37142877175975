import initialState from './initialState';

export const SET_RESET_SEARCH_CLIENT = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        dataSource: [],
      }
    }
  });
};

export const GET_SEARCH_CLIENT_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSearchClient: true
      }
    }
  });
};

export const GET_SEARCH_CLIENT_FETCH_SUCCESS = (section, state, action) => {
  const {dataSource} = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isFirstLoading: false,
        getSearchClient: false
      },
      api: {
        ...state[section].api,
        dataSource
      }
    }
  });
};

export const GET_SEARCH_CLIENT_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isFirstLoading: false,
        getSearchClient: false
      }
    }
  });
};

export const RESET_STATE = (section, state, action) => {
  return ({
    ...state,
    [section]: { ...initialState }
  });
};