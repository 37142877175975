import initialState from './initialState';
const _initialState = initialState();

export const RESET_STATE = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ..._initialState
    }
  };
};

export const SET_TAB_ACTIVE = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        activeKey: action.payload
      }
    }
  };
};

export const SET_BOND_PROFILE_VIEWED = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        bondProfileViewed: {
          ...action.payload
        }
      }
    }
  };
};

export const SET_BOND_DIALOG = (section, state, action) => {
  // const { value, dialogName } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        bondDialog: { ...action.payload }
      }
    }
  };
};

export const SET_SHOULD_DESTROY_MODAL = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        shouldDestroyModal: action.payload
      }
    }
  };
};

export const CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedCep: {
        ...action.payload
      }
    }
  }
});

export const CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});

export const GET_SEARCH_CLIENT_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSearchClient: true
      }
    }
  };
};

export const GET_SEARCH_CLIENT_FETCH_SUCCESS = (section, state, action) => {
  const { isExitClient, enableSave } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSearchClient: false
      },
      isExitClient,
      enableSave
    }
  };
};

export const GET_SEARCH_CLIENT_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSearchClient: false
      }
    }
  };
};

export const POST_CLIENT_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postClient: true
      }
    }
  };
};

export const POST_CLIENT_FETCH_SUCCESS = (section, state, action) => {
  const { patientId, patientName } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postClient: false
      },
      api: {
        ...state[section].api,
        patientId,
        patientName
      }
    }
  };
};

export const POST_CLIENT_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postClient: false
      }
    }
  };
};

export const POST_CLIENT_WITH_GUARDIAN_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postClient: true
      }
    }
  };
};

export const POST_CLIENT_WITH_GUARDIAN_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postClient: false
      },
      api: {
        ...state[section].api,
        patientId: action.payload
      }
    }
  };
};

export const POST_CLIENT_WITH_GUARDIAN_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postClient: false
      }
    }
  };
};

export const GET_SOCIAL_BOND_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSocialBond: true
      }
    }
  };
};

export const GET_SOCIAL_BOND_FETCH_SUCCESS = (section, state, action) => {
  const { listSocialBonds } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSocialBond: false
      },
      api: {
        ...state[section].api,
        listSocialBonds
      }
    }
  };
};

export const GET_SOCIAL_BOND_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSocialBond: false
      }
    }
  };
};

export const POST_SOCIAL_BOND_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postSocialBond: true
      }
    }
  };
};

export const POST_SOCIAL_BOND_FETCH_SUCCESS = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postSocialBond: false
      },
      api: {
        ...state[section].api,
        socialBond: {
          socialBondId: 0,
          name: null,
          email: null,
          cpf: null,
          tel: null,
          cel: null,
          isResponsible: false,
          isBureaucraticallyResponsible: false
        }
      }
    }
  };
};

export const POST_SOCIAL_BOND_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postSocialBond: false
      }
    }
  };
};

export const DELETE_SOCIAL_BOND_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deletingSocialBond: true
      }
    }
  };
};

export const DELETE_SOCIAL_BOND_FETCH_SUCCESS = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deletingSocialBond: false
      }
    }
  };
};

export const DELETE_SOCIAL_BOND_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deletingSocialBond: false
      }
    }
  };
};

export const SET_ENABLE_SAVE = (section, state, action) => {
  const done = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      enableSave: done
    }
  };
};

export const GET_CLIENT_BY_ID_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getClientById: true
      }
    }
  };
};

export const GET_CLIENT_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { generalData, address, patientId, patientName } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      enableSave: true,
      fetching: {
        ...state[section].fetching,
        getClientById: false
      },
      api: {
        ...state[section].api,
        patientId,
        patientName,
        generalData,
        address
      }
    }
  };
};

export const GET_CLIENT_BY_ID_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getClientById: false
      }
    }
  };
};

export const SET_CLIENT_ENABLE_SAVE = (section, state, action) => {
  const { enableSave } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      enableSave
    }
  };
};

export const SET_SOCIAL_BOND_BY_ID = (section, state, action) => {
  const { socialBond } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      enableSave: true,
      fetching: {
        ...state[section].fetching,
        getClientById: false
      },
      api: {
        ...state[section].api,
        socialBond: {
          ...state[section].api.socialBond,
          ...socialBond
        }
      }
    }
  };
};

export const CHANGE_SOCIAL_BOND_IS_RESPONSIBLE = (section, state, action) => {
  const { done } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        socialBond: {
          ...state[section].api.socialBond,
          isResponsible: done
        }
      }
    }
  };
};

export const NEW_AVATAR_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveNewAvatar: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const NEW_AVATAR_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveNewAvatar: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.message]
    }
  }
});

export const NEW_AVATAR_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveNewAvatar: false
    },
    api: {
      ...state[section].api,
      generalData: {
        ...state[section].api.generalData,
        avatar: action.payload.avatarSource
      }
    }
  }
});

export const CLIENT_REGISTER_SET_SECTION_DRAWER = (section, state, action) => {
  const { sectionState } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        ...sectionState
      }
    }
  };
};
