import _ from 'lodash';

export const SET_PRESCRIPTION_NAME = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: action.payload.nameValue
      }
    }
  }
})

export const SET_PRESCRIPTION_DESCRIPTION = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      description: {
        ...state.forms.modal.description,
        value: action.payload.descriptionValue
      }
    }
  }
})

export const RESET_PRESCRIPTION_FORM = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: '',
        error: false
      },
      description: {
        ...state.forms.modal.description,
        value: '',
        error: false
      }
    }
  }
})

export const PUSH_NEW_PRESCRIPTION_TO_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    prescriptionList: [
      ...state.api.prescriptionList,
      { ...action.payload }
    ]
  }
})

export const UPDATE_ITEM_ON_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    prescriptionList: _.map([ ...state.api.prescriptionList ], listItem => {
      if(listItem.id !== action.payload.id ) return listItem
      return action.payload;
    }) 
  }
})

export const LIST_PRESCRIPTIONS_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listPrescriptionModel: true
  }
})

export const LIST_PRESCRIPTIONS_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listPrescriptionModel: false
  }
})

export const LIST_PRESCRIPTIONS_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listPrescriptionModel: false
  },
  api: {
    ...state.api,
    prescriptionList: [ ...action.payload.list ]
  }
})

export const CREATE_PRESCRIPTION_FETCH_REQUEST = (state, action) => ({ 
  ...state,
  fetching: {
    ...state.fetching,
    createPrescriptionModel: true
  }
})

export const CREATE_PRESCRIPTION_FETCH_ERROR = (state, action) => ({
  ...state, 
  fetching: {
    ...state.fetching,
    createPrescriptionModel: false
  }
})

export const CREATE_PRESCRIPTION_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    createPrescriptionModel: false
  } 
})

export const UPDATE_PRESCRIPTION_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updatePrescriptionModel: true
  }
})

export const UPDATE_PRESCRIPTION_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updatePrescriptionModel: false
  }
})

export const UPDATE_PRESCRIPTION_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updatePrescriptionModel: false
  }
})

export const GET_PRESCRIPTION_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getPrescriptionModel: true
  }
})

export const GET_PRESCRIPTION_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getPrescriptionModel: false
  }
})

export const GET_PRESCRIPTION_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getPrescriptionModel: false
  },
  api: {
    ...state.api,
    prescriptionModel: { ...action.payload }
  }
})