export default [
  // {
  //   "stateId": 0,
  //   "stateName": "- Não Definido -",
  //   "stateInitials": "ND"
  // },
  {
    stateId: 5234,
    stateName: 'Acre',
    stateInitials: 'AC'
  },
  {
    stateId: 5235,
    stateName: 'Alagoas',
    stateInitials: 'AL'
  },
  {
    stateId: 5236,
    stateName: 'Amazonas',
    stateInitials: 'AM'
  },
  {
    stateId: 5237,
    stateName: 'Amapá',
    stateInitials: 'AP'
  },
  {
    stateId: 5238,
    stateName: 'Bahia',
    stateInitials: 'BA'
  },
  {
    stateId: 5239,
    stateName: 'Ceará',
    stateInitials: 'CE'
  },
  {
    stateId: 5240,
    stateName: 'Distrito Federal',
    stateInitials: 'DF'
  },
  {
    stateId: 5241,
    stateName: 'Espírito Santo',
    stateInitials: 'ES'
  },
  {
    stateId: 5242,
    stateName: 'Goiás',
    stateInitials: 'GO'
  },
  {
    stateId: 5243,
    stateName: 'Maranhão',
    stateInitials: 'MA'
  },
  {
    stateId: 5244,
    stateName: 'Minas Gerais',
    stateInitials: 'MG'
  },
  {
    stateId: 5245,
    stateName: 'Mato Grosso do Sul',
    stateInitials: 'MS'
  },
  {
    stateId: 5246,
    stateName: 'Mato Grosso',
    stateInitials: 'MT'
  },
  {
    stateId: 5247,
    stateName: 'Pará',
    stateInitials: 'PA'
  },
  {
    stateId: 5248,
    stateName: 'Paraíba',
    stateInitials: 'PB'
  },
  {
    stateId: 5249,
    stateName: 'Pernambuco',
    stateInitials: 'PE'
  },
  {
    stateId: 5250,
    stateName: 'Piauí',
    stateInitials: 'PI'
  },
  {
    stateId: 5251,
    stateName: 'Paraná',
    stateInitials: 'PR'
  },
  {
    stateId: 5252,
    stateName: 'Rio de Janeiro',
    stateInitials: 'RJ'
  },
  {
    stateId: 5253,
    stateName: 'Rio Grande do Norte',
    stateInitials: 'RN'
  },
  {
    stateId: 5254,
    stateName: 'Rondônia',
    stateInitials: 'RO'
  },
  {
    stateId: 5255,
    stateName: 'Roraima',
    stateInitials: 'RR'
  },
  {
    stateId: 5256,
    stateName: 'Rio Grande do Sul',
    stateInitials: 'RS'
  },
  {
    stateId: 5257,
    stateName: 'Santa Catarina',
    stateInitials: 'SC'
  },
  {
    stateId: 5258,
    stateName: 'Sergipe',
    stateInitials: 'SE'
  },
  {
    stateId: 5259,
    stateName: 'São Paulo',
    stateInitials: 'SP'
  },
  {
    stateId: 5260,
    stateName: 'Tocantins',
    stateInitials: 'TO'
  }
  // {
  //   "stateId": 5261,
  //   "stateName": "- Não Definido -",
  //   "stateInitials": "ND"
  // }
];
