import types              from './constant';
import initialState       from './initialState';
import * as commonReducer from './commonReducer';

const INITIAL_STATE = ({ ...initialState });

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_REQUEST_NAME: return commonReducer.SET_REQUEST_NAME(state, action);
    case types.SET_REQUEST_DESCRIPTION: return commonReducer.SET_REQUEST_DESCRIPTION(state, action);
    case types.CREATE_REQUEST_FETCH_ERROR: return commonReducer.CREATE_REQUEST_FETCH_ERROR(state, action);
    case types.CREATE_REQUEST_FETCH_REQUEST: return commonReducer.CREATE_REQUEST_FETCH_REQUEST(state, action);
    case types.CREATE_REQUEST_FETCH_SUCCESS: return commonReducer.CREATE_REQUEST_FETCH_SUCCESS(state, action);
    case types.LIST_REQUESTS_FETCH_REQUEST: return commonReducer.LIST_REQUESTS_FETCH_REQUEST(state, action);
    case types.LIST_REQUESTS_FETCH_ERROR: return commonReducer.LIST_REQUESTS_FETCH_ERROR(state, action);
    case types.LIST_REQUESTS_FETCH_SUCCESS: return commonReducer.LIST_REQUESTS_FETCH_SUCCESS(state, action);
    case types.UPDATE_REQUEST_FETCH_REQUEST: return commonReducer.UPDATE_REQUEST_FETCH_REQUEST(state, action);
    case types.UPDATE_REQUEST_FETCH_ERROR: return commonReducer.UPDATE_REQUEST_FETCH_ERROR(state, action);
    case types.UPDATE_REQUEST_FETCH_SUCCESS: return commonReducer.UPDATE_REQUEST_FETCH_SUCCESS(state, action);
    case types.GET_REQUEST_FETCH_REQUEST: return commonReducer.GET_REQUEST_FETCH_REQUEST(state, action);
    case types.GET_REQUEST_FETCH_ERROR: return commonReducer.GET_REQUEST_FETCH_ERROR(state, action);
    case types.GET_REQUEST_FETCH_SUCCESS: return commonReducer.GET_REQUEST_FETCH_SUCCESS(state, action);
    case types.PUSH_NEW_REQUEST_TO_LIST: return commonReducer.PUSH_NEW_REQUEST_TO_LIST(state, action);
    case types.RESET_REQUEST_FORM: return commonReducer.RESET_REQUEST_FORM(state, action);
    case types.UPDATE_ITEM_ON_LIST: return commonReducer.UPDATE_ITEM_ON_LIST(state, action);
    default: return state
  }
};