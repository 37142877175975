export const SAVE_PHOTO_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, 'Erro ao tentar salvar a foto']
    }
  }
});

export const CREATE_WORKSPACES_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCreateWorkspace: false,
      isCreateWorkspaceError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const CREATE_WORKSPACES_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCreateWorkspace: true,
      isCreateWorkspaceError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const SAVE_PHOTO_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      business: {
        ...state[section].api.business,
        logo: action.payload
      }
    },
    error: {
      done: false,
      messages: []
    }
  }
})

export const CHECK_CEP_INFO_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const CHECK_CEP_INFO_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedCep: {
        ...action.payload
      }
    }
  }
});

export const CHECK_CEP_INFO_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});