export default ({
  SECTION : 'create',
  CONTEXT_FORMENGINE_DRAFT_CREATE                 : 'formEngine:draft:create',
  CONTEXT_FORMENGINE_DRAFT_UPDATE                 : 'formEngine:draft:update',

  MODAL_FORMS_CREATE_FIELD                        : 'modal:formEngine:create:field',
  MODAL_FORMS_UPDATE_FIELD                        : 'modal:formEngine:update:field',
  MODAL_FORMS_SCORES_MATH                         : 'modal:formEngine:scores',
  MODAL_FORMS_GRAPHIC_CONFIG                      : 'modal:formEngine:graphic:config',
  MODAL_FORMS_PREVIEW                             : 'modal:formEngine:preview',
  MODAL_FORMS_FIELD_DESIGN                        : 'modal:formEngine:design',

  FORMS_CREATE_GET_LIST_CARD                      : 'FORMS_CREATE_GET_LIST_CARD',

  FORMS_CREATE_SET_FIELD_DROP_END                 : 'FORMS_CREATE_SET_FIELD_DROP_END',
  FORMS_CREATE_SET_MODULE_DROP_END                : 'FORMS_CREATE_SET_MODULE_DROP_END',
  FORMS_CREATE_SET_SECTION_DROP_END               : 'FORMS_CREATE_SET_SECTION_DROP_END',

  FORMS_CREATE_RESET_DYNAMIC_FORMS                : 'FORMS_CREATE_RESET_DYNAMIC_FORMS',
  FORMS_CREATE_CHANGE_INPUT_HEADER                : 'FORMS_CREATE_CHANGE_INPUT_HEADER',
  FORMS_CREATE_CHANGE_INPUT_SECTIONS              : 'FORMS_CREATE_CHANGE_INPUT_SECTIONS',
  FORMS_CREATE_CHANGE_INPUT_MODULES               : 'FORMS_CREATE_CHANGE_INPUT_MODULES',

  FORMS_CREATE_REMOVED_FIELD                      : 'FORMS_CREATE_REMOVED_FIELD',
  FORMS_CREATE_REMOVED_MODULE                     : 'FORMS_CREATE_REMOVED_MODULE',
  FORMS_CREATE_REMOVED_SECTION                    : 'FORMS_CREATE_REMOVED_SECTION',

  FORMS_CREATE_GET_LIST_FIELDS_FETCH_REQUEST      : 'FORMS_CREATE_GET_LIST_FIELDS_FETCH_REQUEST',
  FORMS_CREATE_GET_LIST_FIELDS_FETCH_SUCCESS      : 'FORMS_CREATE_GET_LIST_FIELDS_FETCH_SUCCESS',
  FORMS_CREATE_GET_LIST_FIELDS_FETCH_ERROR        : 'FORMS_CREATE_GET_LIST_FIELDS_FETCH_ERROR',

  FORMS_CREATE_GET_MODEL_BY_ID_FETCH_REQUEST      : 'FORMS_CREATE_GET_MODEL_BY_ID_FETCH_REQUEST',
  FORMS_CREATE_GET_MODEL_BY_ID_FETCH_SUCCESS      : 'FORMS_CREATE_GET_MODEL_BY_ID_FETCH_SUCCESS',
  FORMS_CREATE_GET_MODEL_BY_ID_FETCH_ERROR        : 'FORMS_CREATE_GET_MODEL_BY_ID_FETCH_ERROR',

  FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_REQUEST   : 'FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_REQUEST',
  FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_SUCCESS   : 'FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_SUCCESS',
  FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_ERROR     : 'FORMS_CREATE_SAVE_VERSION_FIELD_FETCH_ERROR',

  FORMS_CREATE_SET_SEARCH_FIELDS_TAB              : 'FORMS_CREATE_SET_SEARCH_FIELDS_TAB',
  FORMS_CREATE_SET_SEARCH_FIELDS_MANAGER_TAB      : 'FORMS_CREATE_SET_SEARCH_FIELDS_MANAGER_TAB',
  
  FORMS_CREATE_SET_NEW_SECTION                    : 'FORMS_CREATE_SET_NEW_SECTION',
  FORMS_CREATE_SET_NEW_MODULES                    : 'FORMS_CREATE_SET_NEW_MODULES',
  FORMS_CREATE_SET_NEW_FIELDS_IDS                 : 'FORMS_CREATE_SET_NEW_FIELDS_IDS',
  FORMS_CREATE_SET_NEW_DYNAMIC_FIELDS             : 'FORMS_CREATE_SET_NEW_DYNAMIC_FIELDS',

  FORMS_CREATE_MODAL_OPEN_VISIBILE                : 'FORMS_CREATE_MODAL_OPEN_VISIBILE',
  FORMS_CREATE_MODAL_CLOSE_VISIBILE               : 'FORMS_CREATE_MODAL_CLOSE_VISIBILE',

  FORMS_CREATE_MODAL_CHANGE_INPUT                 : 'FORMS_CREATE_MODAL_CHANGE_INPUT',
  FORMS_CREATE_MODAL_CHANGE_INPUT_SIZE            : 'FORMS_CREATE_MODAL_CHANGE_INPUT_SIZE',

  FORMS_CREATE_MODAL_SAVE_FIELD                   : 'FORMS_CREATE_MODAL_SAVE_FIELD',

  FORMS_CREATE_SET_FIELD_MODEL_VERSION            : 'FORMS_CREATE_SET_FIELD_MODEL_VERSION',

  FORM_CREATE_SAVE_FETCH_REQUEST                  : 'FORM_CREATE_SAVE_FETCH_REQUEST',
  FORM_CREATE_SAVE_FETCH_SUCCESS                  : 'FORM_CREATE_SAVE_FETCH_SUCCESS',
  FORM_CREATE_SAVE_FETCH_ERROR                    : 'FORM_CREATE_SAVE_FETCH_ERROR',

  FORMS_CREATE_CHANGE_ATTACH_PATIENT_MODEL        : 'FORMS_CREATE_CHANGE_ATTACH_PATIENT_MODEL',

  FORM_CREATE_GET_DRAFT_BY_ID_FETCH_REQUEST       : 'FORM_CREATE_GET_DRAFT_BY_ID_FETCH_REQUEST',
  FORM_CREATE_GET_DRAFT_BY_ID_FETCH_SUCCESS       : 'FORM_CREATE_GET_DRAFT_BY_ID_FETCH_SUCCESS',
  FORM_CREATE_GET_DRAFT_BY_ID_FETCH_ERROR         : 'FORM_CREATE_GET_DRAFT_BY_ID_FETCH_ERROR',

  FORM_CREATE_SAVE_DRAFT_FETCH_REQUEST            : 'FORM_CREATE_SAVE_DRAFT_FETCH_REQUEST',
  FORM_CREATE_SAVE_DRAFT_FETCH_SUCCESS            : 'FORM_CREATE_SAVE_DRAFT_FETCH_SUCCESS',
  FORM_CREATE_SAVE_DRAFT_FETCH_ERROR              : 'FORM_CREATE_SAVE_DRAFT_FETCH_ERROR',

  FORMS_CREATE_SET_BUILD_DRAFT_BY_ID              : 'FORMS_CREATE_SET_BUILD_DRAFT_BY_ID',

  FORMS_CREATE_SET_UPDATE_FIELD_BY_ID             : 'FORMS_CREATE_SET_UPDATE_FIELD_BY_ID',
  FORMS_CREATE_CHANGE_INPUT_DATA_FORMS            : 'FORMS_CREATE_CHANGE_INPUT_DATA_FORMS',
  FORMS_CREATE_GET_LIST_FIELDS_MANAGER_FETCH_SUCCESS:   'FORMS_CREATE_GET_LIST_FIELDS_MANAGER_FETCH_SUCCESS',
});