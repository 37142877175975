export default ({
  isFirstLoading: true,
  error: {
    done    : false,
    messages: []
  },
  fetching: {
    isAccount: false,
    isUpdateBusiness: false
  },
  api: {
    business: {
      account: '',
      package: '',
      accountId: 0,
      packageId: 0
    }
  },
  modal: {
    update: false
  }
});