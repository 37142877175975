import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Loader from 'react-loaders';

import { styles } from './style';
import * as actions from './action';
import * as util from '@Util/';

class AutoLogin extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.item}>
          <div className={classes.contentForms}>
            <Paper className={classes.control}>
              <Loader active type="line-scale" color="rgba(52, 152, 219, 1)" />
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  }

  componentWillMount() {
    util.deleteCookie('auth', '', '');
  }

  componentDidMount() {
    const { setSignIn, match } = this.props;
    const { token } = match.params;
    setSignIn(token);
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSignIn: actions.autoLogin
    },
    dispatch
  );

AutoLogin = withStyles(styles)(AutoLogin);
AutoLogin = connect(null, mapDispatchToProps)(AutoLogin);

export default AutoLogin;
