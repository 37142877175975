export const LOAD_DASHBOARD_IS_FIRST_LOADING = (state, action) => {
  return ({
    ...state,
    isFirstLoading: action.payload
  });
};

export const LOAD_DASHBOARD_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      dashboardIsFetching: true
    },
  });
};

export const LOAD_DASHBOARD_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      dashboardIsFetching: false
    },
  });
};

export const LOAD_DASHBOARD_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetching: {
      ...state.fetching,
      dashboardIsFetching: false
    },
    api: {
      ...state.api,
      dashboard: {
        ...state.api.dashboard,
        ...action.payload
      }
    }
  });
};