export default ({
  SECTION                                           : 'overview',

  SET_PERFIL_DATA_GENERAL                           : 'SET_PERFIL_DATA_GENERAL',
  PERFIL_SET_DATA_ADDRESS                           : 'PERFIL_SET_DATA_ADDRESS',
  
  PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_REQUEST      : 'PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_REQUEST',
  PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_SUCCESS      : 'PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_SUCCESS',
  PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_ERROR        : 'PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_ERROR',

  PERFIL_OVERVIEW_IS_FIRST_LOADING                  : 'PERFIL_OVERVIEW_IS_FIRST_LOADING',

  PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_REQUEST       : 'PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_REQUEST',
  PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_SUCCESS       : 'PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_SUCCESS',
  PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_ERROR         : 'PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_ERROR',
  
  PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_REQUEST    : 'PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_REQUEST',
  PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_SUCCESS    : 'PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_SUCCESS',
  PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_ERROR      : 'PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_ERROR',

  PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_REQUEST         : 'PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_REQUEST',
  PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_SUCCESS         : 'PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_SUCCESS',
  PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_ERROR           : 'PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_ERROR',

  PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_REQUEST     : 'PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_REQUEST',
  PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_SUCCESS     : 'PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_SUCCESS',
  PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_ERROR       : 'PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_ERROR'
});