export default ({
    REGISTER: {
        CATEGORY: 'register:core',
        LABELS: {
            NAME: 'name',
            EMAIL: 'email',
            CPF: 'cpf',
            CEL: 'cel',
            STATE: 'state',
            CITY: 'city',
            PASSWORD: 'password',
            CONFIRM_PASSWORD: 'password2',
            SIGNUP: 'signup'
        },
        ACTIONS: {
            REGISTER_FIELDS: 'preencheu-campo:register',
            SIGNUP_CLICK: 'clique:signup',
        }
    },
    LOGIN: {
        CATEGORY: 'login:core',
        LABELS: {
            EMAIL: 'email',
            PASSWORD: 'password',
            SIGNIN: 'login'
        },
        ACTIONS: {
            LOGIN_FIELDS: 'preencheu-campo:login',
            SIGNIN_CLICK: 'clique:login'
        },
    },
    PURCHASE: {
        CATEGORY: {
            PACKAGE: 'purchase:package:core',
            BILLING_DATA: 'purchase:billing-data:core',
            PAYMENT_METHOD: 'purchase:payment-method:core',
            ACCOUNT_BASIC_DEFINITIONS: 'purchase:account-basic-definitions:core',
            CONFIRM_DATA: 'purchase:confirm-data:core'
        },
        LABELS: {
            PACKAGE: {
                SELECT_USERS: 'selectUsers',
                SLIDER_USERS: 'sliderUsers',
                CONFIRM_PLAN: 'confirmPlan'
            },
            BILLING_DATA: {
                FIRST_NAME: 'firstName',
                LAST_NAME: 'lastName',
                EMAIL: 'email',
                CPF: 'cpf',
                CNPJ: 'cpnj',
                CORPORATE_NAME: 'corporateName',
                ZIPCODE: 'zipcode',
                ADDRESS: 'address',
                ADDRESS_NUMBER: 'number',
                COMPLEMENT: 'complement',
                NEIGHBORHOOD: 'neighborhood',
                STATE: 'state',
                CITY: 'city',
                NEXT_STEP: 'goToPaymentMethod',
                PREVIOUS_STEP: 'backToPackage'
            },
            PAYMENT_METHOD: {
                NAME: 'name',
                NUMBER: 'number',
                EXPIRATION: 'expirationValue',
                CVC: 'cvc',
                NEXT_STEP: 'goToAccountBasicDefinitions',
                PREVIOUS_STEP: 'backToBillingData'
            },
            ACCOUNT_BASIC_DEFINITIONS: {
                BUSINESS_NAME: 'businessName',
                WORKSPACE_NAME: 'workspaceName',
                NEXT_STEP: 'goToConfirmData',
                PREVIOUS_STEP: 'backToPaymentMethod'
            },
            CONFIRM_DATA: {
                CONFIRM_PAYMENT: 'confirmPayment',
                PREVIOUS_STEP: 'backToAccountBasicDefinitions'
            }
        },
        ACTIONS: {
            PACKAGE: {
                PURCHASE_PACKAGE_FIELDS: 'preencheu-campo:package',
                CONFIRM_PLAN_CLICK: 'clique:confirmar-plano'
            },
            BILLING_DATA: {
                PURCHASE_BILLING_DATA_FIELDS: 'preencheu-campo:billing-data',
                NEXT_STEP_CLICK: 'clique:payment-method',
                PREVIOUS_STEP_CLICK: 'clique:package'
            },
            PAYMENT_METHOD: {
                PURCHASE_PAYMENT_METHOD_FIELDS: 'preencheu-campo:payment-method',
                NEXT_STEP_CLICK: 'clique:account-basic-definitions',
                PREVIOUS_STEP_CLICK: 'clique:billing-data'
            },
            ACCOUNT_BASIC_DEFINITIONS: {
                PURCHASE_ACCOUNT_BASIC_DEFINITIONS_FIELDS: 'preencheu-campo:account-basic-definitions',
                NEXT_STEP_CLICK: 'clique:confirm-data',
                PREVIOUS_STEP_CLICK: 'clique:payment-method'
            },
            CONFIRM_DATA: {
                CONFIRM_PAYMENT_CLICK: 'clique:confirm-payment',
                PREVIOUS_STEP_CLICK: 'clique:account-basic-definitions'
            }
        }
    }
});