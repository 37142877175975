import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes              from 'prop-types';
import { withStyles }         from '@material-ui/core/styles';
// import Button                 from '@material-ui/core/Button';
import Snackbar               from '@material-ui/core/Snackbar';
import IconButton             from '@material-ui/core/IconButton';
import CloseIcon              from '@material-ui/icons/Close';

import { styles }             from './style';

import * as actions           from './action';

class SnackBarComponent extends Component {

  render () {
    const { classes, snackbarState } = this.props;
    const { done, message, anchorOrigin, idMessage, autoHideDuration, variant } = snackbarState;

    return (
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={done}
        autoHideDuration={autoHideDuration}
        onClose={this.onClose}
        ContentProps={{ 'aria-describedby': idMessage, classes: {root: classes[variant], message: classes.messageContent} }}
        message={
          <div className={classes.container}>
            <IconButton
              key={'close'}
              aria-label={'Close'}
              color={'inherit'}
              onClick={this.onClose}
            >
              <CloseIcon />
            </IconButton>
          <div className={classes.containerText}>
            <span id={idMessage}>{message}</span>
          </div>
        </div>
        }
        action={[
          // <Button key={'undo'} color={'inherit'} size={'small'} onClick={this.onClose}> OK </Button>,
        ]}
      ></Snackbar>
    );
  }

  onClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.props.hideSnackbar();
  };
}

const mapStateToProps = state => ({
  snackbarState : state.snackbar
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

SnackBarComponent.propTypes = {
  classes   : PropTypes.object
};

SnackBarComponent = withStyles(styles)(SnackBarComponent);
SnackBarComponent = connect(mapStateToProps, mapDispatchToProps)(SnackBarComponent);

export default SnackBarComponent;