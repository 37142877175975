import { createSelector } from 'reselect';

const getLoginContextByKey = key => state => state.login[key];
const getFetchingContextByKey = key => state => state.login.fetching[key];
const getSignInApiContextByKey = key => state => state.login.signIn.api[key];
const getAuthContextByKey = key => state => state.login.auth[key];
const getAuthBusinessContextByKey = key => state =>
  state.login.auth.business[key];
const getRegisterContextByKey = key => state => state.login.register[key];

export default {
  getAuth: getLoginContextByKey('auth'),
  getSignIn: getLoginContextByKey('signIn'),
  getState: getRegisterContextByKey('estado'),
  getCity: getRegisterContextByKey('city'),
  getMessageError: getRegisterContextByKey('error'),
  getStep: getRegisterContextByKey('step'),
  getAuthToken: getAuthContextByKey('token'),
  getPermissions: getAuthContextByKey('permissions'),

  getAuthUserId: createSelector(
    getAuthContextByKey('userId'),
    state => state
  ),
  getAuthCompanyId: createSelector(
    getAuthBusinessContextByKey('id'),
    state => state
  ),
  getAuthUserName: createSelector(
    getAuthContextByKey('name'),
    state => state
  ),
  getHasLoadedPermissions: createSelector(
    getAuthContextByKey('hasLoadedPermissions'),
    state => state
  ),
  getUserHasAccount: createSelector(
    getAuthContextByKey('userHasAccount'),
    state => state
  ),
  getToken: createSelector(
    getSignInApiContextByKey('signIn'),
    state => state.token
  ),
  getFetchingCreateAccount: createSelector(
    getRegisterContextByKey('fetching'),
    state => state.createAccount
  ),
  getFetchingCreateAccountError: createSelector(
    getRegisterContextByKey('fetching'),
    state => state.createAccountError
  ),
  getIsShowVerifyEmail: createSelector(
    getRegisterContextByKey('showVerifyEmail'),
    state => state
  ),
  getFetchingResendEmail: createSelector(
    getRegisterContextByKey('fetching'),
    state => state.resendEmail
  ),
  getIsRecoverSuccessful: createSelector(
    getFetchingContextByKey('recoverSuccessful'),
    state => state
  ),
  getIsTokenInvalid: createSelector(
    getFetchingContextByKey('isRecoverTokenInvalid'),
    state => state
  ),
  getAuthBusinessWorkspaceId: createSelector(
    getAuthBusinessContextByKey('workspaceId'),
    state => state
  ),
  getAuthBusinessAccountId: createSelector(
    getAuthBusinessContextByKey('accountId'),
    state => state
  ),
  getAuthBusinessAccountProfileId: createSelector(
    getAuthBusinessContextByKey('accountProfileId'),
    state => state
  ),
  getHeaderConfigAccount: createSelector(
    getAuthContextByKey('token'),
    getAuthBusinessContextByKey('accountId'),
    (token, accountId) => ({ token, accountId })
  ),
  getHeaderConfig: createSelector(
    getAuthContextByKey('token'),
    getAuthBusinessContextByKey('accountId'),
    getAuthBusinessContextByKey('workspaceId'),
    (token, accountId, workspaceId) => ({ token, accountId, workspaceId })
  ),
  getAuthGroupPermission: createSelector(
    getAuthContextByKey('groupPermission'),
    state => state
  ),
  getFileReferenceWorkspace: createSelector(
    getAuthBusinessContextByKey('avatarFileReference'),
    state => state
  ),
};
