import * as util from '@Util';
import * as actionConfig from '@Actions/config';
import formOrigin from '@Constants/formOrigin';
import * as actionModal from '@Common/modal/action';
import * as actionModalFields from '@Components/ModalFields/action';
import selectorLogin from '@Components/login/selector';
import types from './constant';
import systemConfigs from '@Constants/systemConfigs';

const getFieldsFetchRequest = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_REQUEST,
});
const getFieldsFetchSuccess = (data) => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_SUCCESS,
  payload: data,
});
const getFieldsFetchError = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FETCH_ERROR,
});

const getFieldsFromManagerFetchRequest = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FROM_MANAGER_FETCH_REQUEST,
});
const getFieldsFromManagerFetchSuccess = (data) => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FROM_MANAGER_FETCH_SUCCESS,
  payload: data,
});
const getFieldsFromManagerFetchError = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_FIELDS_FROM_MANAGER_FETCH_ERROR,
});

export const getFieldModelByIdFetchRequest = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_REQUEST,
});
export const getFieldModelByIdFetchSuccess = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_SUCCESS,
});
export const getFieldModelByIdFetchError = () => ({
  type: types.CONFIGURATION_MEDICALRECORD_FIELDS_GET_MODEL_BY_ID_FETCH_ERROR,
});

export const getFields =
  () =>
  async (dispatch, getState, { apiForms }) => {
    const state = getState();
    let header = selectorLogin.getHeaderConfig(state);

    header = {
      ...header,
      formOrigin: formOrigin['FORM_PRONTUARIO'],
    };

    try {
      dispatch(getFieldsFetchRequest());

      let resResponse = (
        await apiForms.field.getFields({ header, listType: 'business' })
      ).data;
      let _tableFields = [];
      let count = 1;

      for (let item of resResponse) {
        _tableFields.push({
          ...item,
          key: count,
        });
        ++count;
      }

      dispatch(getFieldsFetchSuccess({ tableFields: _tableFields }));
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(getFieldsFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
    }
  };

export const getFieldsFromManager =
  () =>
  async (dispatch, getState, { apiForms }) => {
    const state = getState();
    let header = selectorLogin.getHeaderConfig(state);

    header = {
      ...header,
      formOrigin: formOrigin['FORM_PRONTUARIO'],
    };

    try {
      dispatch(getFieldsFromManagerFetchRequest());

      let resResponse = (
        await apiForms.field.getFields({
          header,
          listType: 'manager',
        })
      ).data;
      let _tableFields = [];
      let count = 1;

      for (let item of resResponse) {
        _tableFields.push({
          ...item,
          key: count,
        });
        ++count;
      }

      dispatch(
        getFieldsFromManagerFetchSuccess({
          tableFieldsFromManager: _tableFields,
        })
      );
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(getFieldsFromManagerFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
    }
  };

export const getFieldModelById =
  (idVersionField, props = {}) =>
  async (dispatch, getState, { apiForms }) => {
    const state = getState();
    const { viewOnly } = props;
    let header = selectorLogin.getHeaderConfig(state);

    header = {
      ...header,
      formOrigin: formOrigin['FORM_PRONTUARIO'],
    };

    try {
      dispatch([
        actionModal.onOpen({
          modal: types.MODAL_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE,
        }),
        getFieldModelByIdFetchRequest(),
        actionModalFields.setGetFieldByIdLoading(),
      ]);

      const response = await apiForms.field.getFieldModelById({
        header,
        idVersionField,
      });
      const result = response.data;

      dispatch([
        getFieldModelByIdFetchSuccess(),
        actionModalFields.editFieldById({
          modal: types.MODAL_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE,
          context: types.CONTEXT_CONFIGURATION_MEDICALRECORD_FIELDS_UPDATE,
          formOrigin: formOrigin['FORM_PRONTUARIO'],
          getFieldById: false,
          viewOnly,
          field: {
            ...result,
            name: result.fieldName,
            displayName: result.displayName,
            size: 12,
          },
        }),
      ]);
    } catch (error) {
      const { msg } = error;
      const customMsg = !!msg;

      dispatch(getFieldModelByIdFetchError());

      util.managerExceptionURIMessage({ error, customMsg });

      if (error && error.status === 401)
        dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
    }
  };
