export const GET_STATE = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    estado: action.payload.estado
  }
});

export const GET_CITY = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    city: action.payload.city
  }
});

export const GET_VALIDATE_STEP_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      validateStep: true,
      validateStepError: false
    },
    error: {
      done: false,
      message: []
    }
  }
});

export const GET_VALIDATE_STEP_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      validateStep: false,
      validateStepError: true
    },
    error: {
      done: true,
      message: [ ...state[section].error.message, action.payload.message]
    }
  }
});

export const GET_VALIDATE_STEP_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      validateStep: false,
    },
  }
});

export const VERIFY_RECAPTCHA_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      verifyingCaptcha: true,
    },
    error: {
      done: false,
      message: []
    }
  }
});

export const VERIFY_RECAPTCHA_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      verifyingCaptcha: false,
    },
    error: {
      done: true,
      message: [ ...state[section].error.message, action.payload.message]
    }
  }
});

export const VERIFY_RECAPTCHA_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      verifyingCaptcha: false,
    },
    captchaVerified: true
  }
});

export const CREATE_ACCOUNT_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      createAccount: true,
      createAccountError: false
    },
    error: {
      done: false,
      message: []
    }
  }
});

export const CREATE_ACCOUNT_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      createAccount: false,
      createAccountError: true
    },
    error: {
      done: true,
      message: [ ...state[section].error.message, action.payload.message]
    }
  }
});

export const CREATE_ACCOUNT_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    showVerifyEmail: true,
    fetching: {
      ...state[section].fetching,
      createAccount: false,
    },
    resendEmail: action.payload.email
  }
});

export const STEP = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    step: {
      ...state[section].step,
      value: action.payload.value,
      active: action.payload.active
    }
  }
});

export const HIDE_SHOW_MESSAGE = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    showVerifyEmail: false
  }
});

export const RESSEND_EMAIL_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      resendEmail: true
    }
  }
});

export const RESSEND_EMAIL_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      resendEmail: false
    }
  }
});

export const RESSEND_EMAIL_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      resendEmail: false
    }
  }
});