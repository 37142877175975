import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';

const PATIENT = 'patient';

//#region GET
export const getSearchClient = async ({accountId, workspaceId, value}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PATIENT}?value=${value}`;
  const axios   = interceptor({accountId, workspaceId});
  return await get(axios, pathUrl);
};
//#endregion