import * as docs from './docs';
import * as classification from './classification';
import * as folder from './folder';
import * as document from './document';
import * as signature from './signature';
import * as authors from './authors';
import * as category from './category';

export default {
  docs,
  classification,
  folder,
  document,
  signature,
  authors,
  category,
};
