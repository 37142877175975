export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_MEDICALRECORD_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getMedicalRecord: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_MEDICALRECORD_FETCH_SUCCESS = (state, action) => {
  const {attendanceAccessConfig} = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getMedicalRecord: false
          },
          api: {
            ...state.configuration.medicalRecord.overview.api,
            getMedicalRecord: {
              ...state.configuration.medicalRecord.overview.api.getMedicalRecord,
              attendanceAccessConfig: {
                ...state.configuration.medicalRecord.overview.api.getMedicalRecord.attendanceAccessConfig,
                ...attendanceAccessConfig
              }
            }
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_MEDICALRECORD_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getMedicalRecord: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_PUT_MEDICALRECORD_CONFIG_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            putMedicalRecordConfig: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_PUT_MEDICALRECORD_CONFIG_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            putMedicalRecordConfig: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_PUT_MEDICALRECORD_CONFIG_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            putMedicalRecordConfig: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_TOGGLE_IS_PUBLIC = (state, action) => {
  const {isPublic} = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          api: {
            ...state.configuration.medicalRecord.overview.api,
            getMedicalRecord: {
              ...state.configuration.medicalRecord.overview.api.getMedicalRecord,
              attendanceAccessConfig: {
                ...state.configuration.medicalRecord.overview.api.getMedicalRecord.attendanceAccessConfig,
                isPublic
              }
            }
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_ACCESSGROUP_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getAccessGroups: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_ACCESSGROUP_FETCH_SUCCESS = (state, action) => {
  const {accessGroups} = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getAccessGroups: false
          },
          api: {
            ...state.configuration.medicalRecord.overview.api,
            accessGroups
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_ACCESSGROUP_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getAccessGroups: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_MEDICALRECORD_MEMBERS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getMedicalRecordMembers: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_MEDICALRECORD_MEMBERS_FETCH_SUCCESS = (state, action) => {
  const {accessGroupById, listMembers} = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getMedicalRecordMembers: false
          },
          api: {
            ...state.configuration.medicalRecord.overview.api,
            listMembers,
            accessGroupById: {
              ...state.configuration.medicalRecord.overview.api.accessGroupById,
              ...accessGroupById
            }
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_MEDICALRECORD_MEMBERS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            getMedicalRecordMembers: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_RESET_MODAL_FIELDS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          api: {
            ...state.configuration.medicalRecord.overview.api,
            accessGroupById: {
              members: [],
              medicalRecordsAccessGroupId: 0,
              name: '',
            },
            listMembers: []
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_SET_TRANSFER_SHARING = (state, action) => {
  const {members} = action.payload;
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          api: {
            ...state.configuration.medicalRecord.overview.api,
            accessGroupById: {
              ...state.configuration.medicalRecord.overview.api.accessGroupById,
              members
            }
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_POST_ACCESSGROUP_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            postAccessGroup: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_POST_ACCESSGROUP_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            postAccessGroup: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_POST_ACCESSGROUP_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            postAccessGroup: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_PUT_ACCESSGROUP_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            putAccessGroup: true
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_PUT_ACCESSGROUP_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            putAccessGroup: false
          }
        }
      }
    }
  });
};

export const PERFIL_CONFIGURATION_MEDICALRECORD_OVERVIEW_PUT_ACCESSGROUP_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    configuration: {
      ...state.configuration,
      medicalRecord: {
        ...state.configuration.medicalRecord,
        overview: {
          ...state.configuration.medicalRecord.overview,
          fetching: {
            ...state.configuration.medicalRecord.overview.fetching,
            putAccessGroup: false
          }
        }
      }
    }
  });
};