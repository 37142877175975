import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { toastr } from 'react-redux-toastr';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { styles } from './style';
import * as actions from './action';
import selector from '../selector';
import * as actionsConfig from '@Actions/config';

import Header from './header';
import Form from './form';

class Recover extends Component {
  state = { dialogState: false };

  componentDidMount() {
    const { fetching } = this.props;
    const { isTokenInvalid } = fetching;
    if (isTokenInvalid) this.setState({ dialogState: true });
  }

  render() {
    const { classes, fetching } = this.props;
    const { dialogState } = this.state;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.item}>
          <div className={classes.contentForms}>
            <Paper className={classes.control}>
              <Header classes={classes} />
              <Grid
                container
                direction={'column'}
                alignItems="center"
                className={classes.rootSteps}
              >
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Form
                    classes={classes}
                    onSubmit={this.onSubmit}
                    fetching={fetching}
                  />
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Grid>
        <Dialog
          open={dialogState}
          onClose={() => this.setState({ dialogState: false })}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Link expirado'}</DialogTitle>
          <DialogContent style={{ display: 'flex' }}>
            <div>⚠</div>
            <div style={{ paddingLeft: '7px' }}>
              ️O link de recuperação de senha expirou por possuir validade de 2
              horas após a solicitação.
              <br />
              <br />
              Por favor, solicite uma nova recuperação de senha.
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.setState({ dialogState: false })}
              color="primary"
              autoFocus
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }

  onSubmit = values => {
    this.props.sendEmailToRecover(values);
  };

  onClickBackLogin = () => {
    this.props.hideShowVerifyEmail();
    this.props.redirectRoute('/');
  };
}

const mapStateToProps = state => ({
  fetching: {
    recoverSuccessful: selector.getIsRecoverSuccessful(state),
    isTokenInvalid: selector.getIsTokenInvalid(state)
  }
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      sendEmailToRecover: actions.recoverEmail,
      // resendEmail         : actions.resendEmail,
      redirectRoute: actionsConfig.routeRedirect
    },
    dispatch
  );

Recover = withStyles(styles)(Recover);
Recover = connect(
  mapStateToProps,
  mapDispatchToProps
)(Recover);

export default Recover;
