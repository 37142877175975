export default {
  CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST:
    'CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST',
  CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS:
    'CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS',
  CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR:
    'CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR',

  CONFIGURATION_FIELDS_GET_LIST_MANAGER_FORM_MODELS_FETCH_REQUEST:
    'CONFIGURATION_FIELDS_GET_LIST_MANAGER_FORM_MODELS_FETCH_REQUEST',
  CONFIGURATION_FIELDS_GET_LIST_MANAGER_FORM_MODELS_FETCH_SUCCESS:
    'CONFIGURATION_FIELDS_GET_LIST_MANAGER_FORM_MODELS_FETCH_SUCCESS',
  CONFIGURATION_FIELDS_GET_LIST_MANAGER_FORM_MODELS_FETCH_ERROR:
    'CONFIGURATION_FIELDS_GET_LIST_MANAGER_FORM_MODELS_FETCH_ERROR',

  CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST:
    'CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST',
  CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS:
    'CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS',
  CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR:
    'CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR',

  CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST:
    'CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST',
  CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS:
    'CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS',
  CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR:
    'CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR',

  CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST:
    'CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST',
  CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS:
    'CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS',
  CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR:
    'CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR',

  CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST:
    'CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST',
  CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS:
    'CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS',
  CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR:
    'CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR',

  FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS:
    'FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS',
  FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS:
    'FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS',
  SET_CATEGORY_SELECTED_FORM:
    'SET_CATEGORY_SELECTED_FORM',
  SET_FORMS_FILTER_FORM:
    'SET_FORMS_FILTER_FORM',
  FORM_GET_CATEGORIES_FORM_FETCH_SUCCESS:
    'FORM_GET_CATEGORIES_FORM_FETCH_SUCCESS',
  SET_AUTHORS_FORMS:
    'SET_AUTHORS_FORMS',
  SET_SIDEBAR_BUTTON_SELECT_FORM:
    'SET_SIDEBAR_BUTTON_SELECT_FORM',
  SET_FAVORITES_FORM_MODELS:
    'SET_FAVORITES_FORM_MODELS',
  SET_FAVORITES_FORM_SELECTED:
    'SET_FAVORITES_FORM_SELECTED',
  MODAL_CONFIGURATION_MEDICALRECORD_SECTIONS_FORMS:
    'MODAL_CONFIGURATION_MEDICALRECORD_SECTIONS_FORMS',
  SAVE_SECTION_FORM_ACCOUNT_CONFIG_FETCH_REQUEST:
    'SAVE_SECTION_FORM_ACCOUNT_CONFIG_FETCH_REQUEST',
  SET_TAB_SELECTED_BUTTON_SELECT_FORM:
    'SET_TAB_SELECTED_BUTTON_SELECT_FORM'
};
