export const PERFIL_OVERVIEW_IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      getById: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      getById: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const PERFIL_OVERVIEW_GET_PERFIL_BY_ID_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      getById: false
    },
    api: {
      ...state[section].api,
      perfil: {
        ...state[section].api.perfil,
        general: {
          ...state[section].api.general,
          id: action.payload.perfil.id,
          cpf: action.payload.perfil.cpf,
          sex: action.payload.perfil.sex,
          name: action.payload.perfil.name,
          email: action.payload.perfil.email,
          birthdate: action.payload.perfil.birthdate,
          cel: action.payload.perfil.cel,
          tel: action.payload.perfil.tel,
          avatar: action.payload.perfil.avatarSource
        },
        address: {
          ...state[section].api.address,
          cep: action.payload.perfil.cep,
          stateId: action.payload.perfil.stateId,
          cityId: action.payload.perfil.cityId,
          state: action.payload.perfil.state,
          city: action.payload.perfil.city,
          complement: action.payload.perfil.complement,
          numberAddress: action.payload.perfil.numberAddress,
          neighborhood: action.payload.perfil.neighborhood,
          address: action.payload.perfil.address
        }
      }
    }
  }
});

export const PERFIL_SET_DATA_ADDRESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
    },
    api: {
      ...state[section].api,
      perfil: {
        ...state[section].api.perfil,
        general: {
          ...state[section].api.perfil.general,
        },
        address: {
          ...state[section].api.perfil.address,
          ...action.payload
        }
      }
    }
  }
});

export const PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedCep: null
    },
    fetching: {
      ...state[section].fetching,
      checkingCep: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      checkingCep: false
    },
    api: {
      ...state[section].api,
      searchedCep: null
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const PERFIL_OVERVIEW_CHECK_CEP_INFO_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      checkingCep: false
    },
    api: {
      ...state[section].api,
      searchedCep: action.payload,
      perfil: {
        ...state[section].api.perfil,
        general: {
          ...state[section].api.perfil.general,
        },
        address: {
          ...state[section].api.perfil.address,
          neighborhood: action.payload.bairro,
          address: action.payload.logradouro
        }
      }
    }
  }
});

export const PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isUpdatingPassword: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isUpdatingPassword: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.userFeedback
      ]
    }
  }
});

export const PERFIL_UPDATE_PERFIL_PASSWORD_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isUpdatingPassword: false
    },
    /* api: {
      ...state[section].api,
    } */
  }
});

export const PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      savePerfil: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      savePerfil: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const PERFIL_OVERVIEW_SAVE_PERFIL_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      savePerfil: false
    }
  }
});

export const PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveNewAvatar: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveNewAvatar: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const PERFIL_OVERVIEW_SAVE_NEW_AVATAR_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveNewAvatar: false
    },
    api: {
      ...state[section].api,
      perfil: {
        ...state[section].api.perfil,
        general: {
          ...state[section].api.perfil.general,
          avatar: action.payload.avatarSource
        }
      }
    }
  }
});