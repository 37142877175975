export default ({
  PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST   : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST',
  PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS   : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS',
  PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR     : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR',

  PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST      : 'PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST',
  PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS      : 'PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS',
  PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR        : 'PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR',

  PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST             : 'PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST',
  PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS             : 'PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS',
  PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR               : 'PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR',

  PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST        : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST',
  PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS        : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS',
  PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR          : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR',

  PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST       : 'PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST',
  PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS       : 'PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS',
  PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR         : 'PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR',

  PERFIL_FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS           : 'PERFIL_FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS',
  PERFIL_FORM_GET_CATEGORIES_FORM_FETCH_SUCCESS                    : 'PERFIL_FORM_GET_CATEGORIES_FORM_FETCH_SUCCESS',
  PERFIL_FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS                     : 'PERFIL_FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS',
  SET_SIDEBAR_BUTTON_SELECT_FORM_PERFIL                            : 'SET_SIDEBAR_BUTTON_SELECT_FORM_PERFIL',
  SET_FORMS_FILTER_FORM_PERFIL                                     : 'SET_FORMS_FILTER_FORM_PERFIL',
  SET_CATEGORY_SELECTED_FORM_PERFIL                                : 'SET_CATEGORY_SELECTED_FORM_PERFIL',
  SET_AUTHORS_FORMS_PERFIL                                         : 'SET_AUTHORS_FORMS_PERFIL',
  SET_FAVORITES_FORM_MODELS_PERFIL                                 : 'SET_FAVORITES_FORM_MODELS_PERFIL',
  MODAL_CONFIGURATION_MEDICALRECORD_SECTIONS_FORMS_PERFIL          : 'MODAL_CONFIGURATION_MEDICALRECORD_SECTIONS_FORMS_PERFIL',
  SAVE_SECTION_FORM_PERFIL_CONFIG_FETCH_REQUEST                    : 'SAVE_SECTION_FORM_PERFIL_CONFIG_FETCH_REQUEST',
  SET_TAB_SELECTED_BUTTON_SELECT_FORM_PERFIL                       : 'SET_TAB_SELECTED_BUTTON_SELECT_FORM_PERFIL',
  PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_GLOBAL_FETCH_SUCCESS : 'PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_GLOBAL_FETCH_SUCCESS'
});