export const CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_ACCOUNT_CONFIG_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          getAccountConfig: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_ACCOUNT_CONFIG_FETCH_SUCCESS = (state, action) => {
  const { _private, _sharing, _accountConfig, _accountMedicalRecordsModel } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          getAccountConfig: false
        },
        api: {
          ...state.medicalRecord.overview.api,
          accountSharingControlUsersConfig: {
            ...state.medicalRecord.overview.api.accountSharingControlUsersConfig,
            private: _private,
            sharing: _sharing
          },
          accountConfig: _accountConfig,
          accountMedicalRecordsModel: _accountMedicalRecordsModel
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_GET_ACCOUNT_CONFIG_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          getAccountConfig: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_SET_TRANSFER_SHARING = (state, action) => {
  const {_sharing} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        api: {
          ...state.medicalRecord.overview.api,
          accountSharingControlUsersConfig: {
            ...state.medicalRecord.overview.api.accountSharingControlUsersConfig,
            sharing: _sharing
          }
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_SET_TOGGLE_ACCOUNT_CONFIG_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          putAccountConfig: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_SET_TOGGLE_ACCOUNT_CONFIG_FETCH_SUCCESS = (state, action) => {
  const {name, checked} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          putAccountConfig: false
        },
        api: {
          ...state.medicalRecord.overview.api,
          accountConfig: {
            ...state.medicalRecord.overview.api.accountConfig,
            [name]: checked
          },
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_SET_TOGGLE_ACCOUNT_CONFIG_FETCH_ERROR = (state, action) => {
  const {name, checked} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          putAccountConfig: false
        },
        api: {
          ...state.medicalRecord.overview.api,
          accountConfig: {
            ...state.medicalRecord.overview.api.accountConfig,
            [name]: checked
          },
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_POST_ACCOUNT_CONFIG_SHARING_CONTROL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          postAccountConfig: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_POST_ACCOUNT_CONFIG_SHARING_CONTROL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          postAccountConfig: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_POST_ACCOUNT_CONFIG_SHARING_CONTROL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          postAccountConfig: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_DEL_ACCOUNT_CONFIG_SHARING_CONTROL_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          delAccountConfig: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_DEL_ACCOUNT_CONFIG_SHARING_CONTROL_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          delAccountConfig: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_OVERVIEW_DEL_ACCOUNT_CONFIG_SHARING_CONTROL_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      overview: {
        ...state.medicalRecord.overview,
        fetching: {
          ...state.medicalRecord.overview.fetching,
          delAccountConfig: false
        }
      }
    }
  });
};