// import bglogin        from '@Assets/images/bg-login@600.png';
// import bglogin992     from '@Assets/images/bg-login@992.png';
import bgLogin1280    from '@Assets/images/bg-login@1280.png';

export const styles = theme => ({
  root: {
    width: '100%',
    margin: 0,
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${bgLogin1280})`,
    // [theme.breakpoints.up('md')]: {
    //   backgroundImage: `url(${bglogin992})`,
    // },
    // [theme.breakpoints.up('lg')]: {
    //   backgroundImage: `url(${bgLogin1280})`,
    // }
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    marginTop: '1rem',
    width: 400,
  },
  errorText: {
    marginLeft: theme.spacing.unit,
    color: '#F44336'
  },
  paper: {
    height: 400,
    width: 100,
  },
  control: {
    padding: '2em 1.5em',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: 'none !important',
    position: 'relative',
    top: '-5em'
  },
  item: {
    padding: '0 !important'
  },
  contentForms: {
    height: '100vh',
    display: 'flex',
    flex: '1 100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rememberme: {
    fontFamily: 'sans-serif'
  },
  title: {
    fontFamily: 'sans-serif',
    fontWeight: 300,
    fontSize: '18px',
    color: '#202020',
  },
  toolBarImage: {
    width: 'auto',
    height: '5.8em'
  },
  buttonSubmit: {
    marginTop: '3rem',
    marginBottom: '1rem',
    paddingLeft: '7em',
    paddingRight: '7em',
    borderRadius: 50,
    '&:disabled': {
      cursor: 'progress',
      pointerEvents: 'auto'
    }
  },
  buttonRegisterlabel: {
    fontSize: 12,
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#888',
    '&:hover': {
      color: '#286efa',
      cursor: 'pointer'
    }
  },
  formControlCheckbox: {
    textAlign: 'left',
    margin: '.5em'
  },
  containerHeader: {
    marginTop: '1em',
    marginBottom: '3em'
  },
  underline: {
    '&:before': {
      borderBottomColor: '#286efa !important',
      '&:hover': {
        borderBottomColor: '#286efa !important'
      }
    },
    '&:after': {
      borderBottomColor: '#286efa !important',
      '&:hover': {
        borderBottomColor: '#286efa !important'
      }
    }
  },
  focused: {
    borderBottomColor: '#286efa !important'
  },
  input: {
    color: '#888'
  },
  labelRoot: {
    color: '#FFF'
  },
  raisedPrimaryOverrideButton: {
    backgroundColor: '#286efa',
    '&:hover': { backgroundColor: '#286efa' },
    '&:focus': { backgroundColor: '#286efa' }
  }
});