export const SET_MEDICAL_RECORDS_ATTENDANCES_REPORTS = (state, action) => {
  return ({
    ...state,
    medicalRecordsAttendances: action.payload
  });
};

export const FETCH_MEDICAL_RECORDS_ATTENDANCES_REPORTS = (state, action) => {
  return ({
    ...state,
    fetchMedicalRecordsAttendancesReports: action.payload
  });
}

export const GET_WORKSPACES_FILTER_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    filter:{
      ...state.filter,
      workspaces: action.payload
    }
  });
}

export const GET_USERS_FILTER_ACCOUNT_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    filter:{
      ...state.filter,
      professionals: action.payload
    }
  });
}

export const GET_CLIENT_FILTER_ACCOUNT_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    filter:{
      ...state.filter,
      clients: action.payload
    }
  });
}