// import { push }   from 'connected-react-router';

import * as types from '../constants';
import * as routes from '@Constants/router';
import * as actionSnack from '@Common/snackBar/action';
import * as actionConfig from '@Actions/config';
import * as util from '@Util/';
import systemConfigs from '@Constants/systemConfigs';

export const recoverEmailFetchRequest = () => ({
  type: types.LOGIN_RECOVER_SEND_EMAIL_FETCH_REQUEST
});
export const recoverEmailFetchError = () => ({
  type: types.LOGIN_RECOVER_SEND_EMAIL_FETCH_ERROR
});
export const recoverEmailFetchSuccess = () => ({
  type: types.LOGIN_RECOVER_SEND_EMAIL_FETCH_SUCCESS
});

export const recoverEmail = params => async (dispatch, getState, api) => {
  dispatch(recoverEmailFetchRequest());
  try {
    await api.user.recoverEmail(params);
    dispatch([
      recoverEmailFetchSuccess(),
      actionSnack.showSnackbar({
        message:
          'O link de recuperação foi enviado para seu e-mail com sucesso.',
        variant: 'success',
        autoHideDuration: 3500
      })
    ]);
  } catch (error) {
    const { userFeedback } = error;

    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([actionSnack.showSnackbar(uriMessage), recoverEmailFetchError()]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};
