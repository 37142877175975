import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';
import { put }        from '../util';
import { del }        from '../util';

const MEDICALRECORD   = 'medicalrecords';
const ACCOUNT         = 'account';
const CONFIG          = 'config';
const SECTION         = 'section';
const MANY            = 'many';

//#region GET
export const getWorkConfig = async ({header}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const postSectionAccountConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}/${ACCOUNT}/${CONFIG}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postSectionWorkConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const putSectionAccountConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}/${ACCOUNT}/${CONFIG}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putSectionManyAccountConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}/${ACCOUNT}/${CONFIG}/${MANY}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putSectionWorkConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putSectionManyWorkConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}/${MANY}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
}
//#endregion

//#region DELETE
export const delSectionAccountConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}/${ACCOUNT}/${CONFIG}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const delSection = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${SECTION}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion