import _ from 'lodash';

export const SET_REQUEST_NAME = (state, action) => ({
  ...state,
  forms: {
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: action.payload.nameValue,
      },
    },
  },
});

export const SET_REQUEST_DESCRIPTION = (state, action) => ({
  ...state,
  forms: {
    ...state.forms,
    modal: {
      ...state.forms.modal,
      description: {
        ...state.forms.modal.description,
        value: action.payload.descriptionValue,
      },
    },
  },
});

export const RESET_REQUEST_FORM = (state, action) => ({
  ...state,
  forms: {
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: '',
        error: false,
      },
      description: {
        ...state.forms.modal.description,
        value: '',
        error: false,
      },
    },
  },
});

export const PUSH_NEW_REQUEST_TO_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    requestList: [...state.api.requestList, { ...action.payload }],
  },
});

export const UPDATE_ITEM_ON_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    requestList: _.map([...state.api.requestList], (listItem) => {
      if (listItem.id !== action.payload.id) return listItem;
      return action.payload;
    }),
  },
});

export const LIST_REQUESTS_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listRequestModel: true,
  },
});

export const LIST_REQUESTS_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listRequestModel: false,
  },
});

export const LIST_REQUESTS_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listRequestModel: false,
  },
  api: {
    ...state.api,
    requestList: [...action.payload.list],
  },
});

export const CREATE_REQUEST_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    createRequestModel: true,
  },
});

export const CREATE_REQUEST_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    createRequestModel: false,
  },
});

export const CREATE_REQUEST_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    createRequestModel: false,
  },
});

export const UPDATE_REQUEST_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateRequestModel: true,
  },
});

export const UPDATE_REQUEST_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateRequestModel: false,
  },
});

export const UPDATE_REQUEST_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateRequestModel: false,
  },
});

export const GET_REQUEST_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getRequestModel: true,
  },
});

export const GET_REQUEST_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getRequestModel: false,
  },
});

export const GET_REQUEST_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getRequestModel: false,
  },
  api: {
    ...state.api,
    requestModel: { ...action.payload },
  },
});
