export const IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const GET_INFO_PLAN_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      planInfo: true,
      planInfoError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const GET_INFO_PLAN_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      planInfo: false,
      planInfoError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const GET_INFO_PLAN_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      planInfo: false
    },
    api: {
      ...state[section].api,
      infoPlan: {
        ...state[section].api.infoPlan,
        ...action.payload.infoPlan
      },
      infoSummary: {
        ...state[section].api.infoSummary,
        ...action.payload.infoSummary
      }
    }
  }
});