export default {
  SECTION: 'create',
  MODAL_CREATE_PROFESSIONAL_VINCULO: 'create:professional:vinculo',
  FORM_HEADER_ENABLE_USER_SYSTEM: 'form:header:enable:user:system',
  FORM_CREATE_PROFESSIONAL_VINCULO: 'form:create:professional:vinculo',
  FORM_CREATE_USER_PERFIL: 'form:create:user:perfil',
  FORM_CREATE_USER_PERFIL_ADDRESS: 'form:create:user:perfil:address',
  FORM_CREATE_USER_PERFIL_PROFESSIONAL: 'form:create:user:perfil:professional',
  FORM_CREATE_USER_GERAL: 'form:create:user:geral',
  FORM_CREATE_USER_GERAL_ADDRESS: 'form:create:user:geral:address',
  FORM_CREATE_USER_REGISTRATIONDATA_DADOS_CADASTRAIS:
    'form:create:user:registrationdata',
  FORM_CREATE_USER_REGISTRATIONDATA_ADRESS:
    'form:create:user:registrationdata:address',
  FORM_CREATE_USER_PERMISSION_ACCOUNT: 'form:create:user:permission:account',
  FORM_CREATE_USER_PERMISSION_WORKSPACE:
    'form:create:user:permission:workspace',

  USER_CREATE_IS_FIRST_LOADING: 'USER_CREATE_IS_FIRST_LOADING',

  USER_CREATE_GET_STATE_CITY: 'USER_CREATE_GET_STATE_CITY',

  USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_REQUEST:
    'USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_REQUEST',
  USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_SUCCESS:
    'USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_SUCCESS',
  USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_ERROR:
    'USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_ERROR',

  USER_CREATE_GET_WORKSPACES_FETCH_REQUEST:
    'USER_CREATE_GET_WORKSPACES_FETCH_REQUEST',
  USER_CREATE_GET_WORKSPACES_FETCH_SUCCESS:
    'USER_CREATE_GET_WORKSPACES_FETCH_SUCCESS',
  USER_CREATE_GET_WORKSPACES_FETCH_ERROR:
    'USER_CREATE_GET_WORKSPACES_FETCH_ERROR',

  USER_CREATE_CHECK_CEP_INFO_FETCH_REQUEST:
    'USER_CREATE_CHECK_CEP_INFO_FETCH_REQUEST',
  USER_CREATE_CHECK_CEP_INFO_FETCH_SUCCESS:
    'USER_CREATE_CHECK_CEP_INFO_FETCH_SUCCESS',
  USER_CREATE_CHECK_CEP_INFO_FETCH_ERROR:
    'USER_CREATE_CHECK_CEP_INFO_FETCH_ERROR',

  VERIFY_REGISTERED_USER_FETCH_REQUEST: 'VERIFY_REGISTERED_USER_FETCH_REQUEST',
  VERIFY_REGISTERED_USER_FETCH_SUCCESS: 'VERIFY_REGISTERED_USER_FETCH_SUCCESS',
  VERIFY_REGISTERED_USER_FETCH_ERROR: 'VERIFY_REGISTERED_USER_FETCH_ERROR',

  USER_CREATE_INVITE_CORRECTION_FETCH_REQUEST:
    'USER_CREATE_INVITE_CORRECTION_FETCH_REQUEST',
  USER_CREATE_INVITE_CORRECTION_FETCH_SUCCESS:
    'USER_CREATE_INVITE_CORRECTION_FETCH_SUCCESS',
  USER_CREATE_INVITE_CORRECTION_FETCH_ERROR:
    'USER_CREATE_INVITE_CORRECTION_FETCH_ERROR',

  USER_CREATE_SEND_INVITE_FETCH_REQUEST:
    'USER_CREATE_SEND_INVITE_FETCH_REQUEST',
  USER_CREATE_SEND_INVITE_FETCH_SUCCESS:
    'USER_CREATE_SEND_INVITE_FETCH_SUCCESS',
  USER_CREATE_SEND_INVITE_FETCH_ERROR: 'USER_CREATE_SEND_INVITE_FETCH_ERROR',

  USER_CREATE_PROFESSIONAL_FETCH_REQUEST:
    'USER_CREATE_PROFESSIONAL_FETCH_REQUEST',
  USER_CREATE_PROFESSIONAL_FETCH_SUCCESS:
    'USER_CREATE_PROFESSIONAL_FETCH_SUCCESS',
  USER_CREATE_PROFESSIONAL_FETCH_ERROR: 'USER_CREATE_PROFESSIONAL_FETCH_ERROR',

  CLEAR_USER_FOUND: 'CLEAR_USER_FOUND',

  USER_CREATE_SET_ACCOUNT: 'USER_CREATE_SET_ACCOUNT',

  USER_CREATE_SET_ALL_PERFIL: 'USER_CREATE_SET_ALL_PERFIL',
  USER_CREATE_SET_ONLY_PERFIL_WORK: 'USER_CREATE_SET_ONLY_PERFIL_WORK'
};
