import React from 'react';
import BlockUi from 'react-block-ui';
import Loader from 'react-loaders';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Button, Result } from 'antd';

import palettes from '@Constants/palletes';
import { styles } from './style';

const AppAsyncLoading = props => {
  const { classes, error, timedOut, pastDelay } = props;

  const reloadPage = () => {
    window.location.reload();
  };

  if (error) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Result
          status="warning"
          title="Ops! Algo não saiu como esperado."
          subTitle="Clique em recarregar para resolver rapidamente e continuar aproveitando tudo que a USECORE te oferece.
        Se recarregar a tela não resolver o problema, fale com o nosso suporte."
          extra={
            <Button type="primary" onClick={reloadPage}>
              Recarregar Página
            </Button>
          }
        />
      </div>
    );
  } else if (timedOut) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <BlockUi
            tag="div"
            message="Está demorando mais do que o previsto, aguarde...."
            className={classes.blockUi}
            blocking
            loader={
              <Loader
                active
                type="line-scale"
                color={palettes.colors.first[500]}
              />
            }
          />
        </Grid>
      </Grid>
    );
  } else if (pastDelay) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <BlockUi
            tag="div"
            message="Carregando Pré Configurações...."
            className={classes.blockUi}
            blocking
            loader={
              <Loader
                active
                type="line-scale"
                color={palettes.colors.first[500]}
              />
            }
          />
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export default withStyles(styles)(AppAsyncLoading);
