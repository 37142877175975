export const CONFIGURATION_MEDICALRECORD_GET_EXERCISES_FETCH_REQUEST = (
  state,
  action
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercises: {
        ...state.medicalRecord.exercises,
        fetching: {
          ...state.medicalRecord.exercises.fetching,
          getExercises: true,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_GET_BY_ID_FETCH_SUCCESS = (
  state,
  action
) => {
  const { exercise } = action.payload;
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercises: {
        ...state.medicalRecord.exercises,
        fetching: {
          ...state.medicalRecord.exercises.fetching,
          getExerciseById: false,
        },
        api: {
          ...state.medicalRecord.exercises.api,
          exercise,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_GET_BY_ID_FETCH_ERROR = (
  state
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercises: {
        ...state.medicalRecord.exercises,
        fetching: {
          ...state.medicalRecord.exercises.fetching,
          getExerciseById: false,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_GET_BY_ID_FETCH_REQUEST = (
  state
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercises: {
        ...state.medicalRecord.exercises,
        fetching: {
          ...state.medicalRecord.exercises.fetching,
          getExerciseById: true,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_SET_CATEGORY = (
  state,
  action
) => {
  const { category } = action.payload;
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercisesCategories: {
        ...state.medicalRecord.exercisesCategories,
        fetching: {
          ...state.medicalRecord.exercisesCategories.fetching,
          getExercisesCategories: false,
        },
        api: {
          ...state.medicalRecord.exercisesCategories.api,
          category,
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_GET_CATEGORIES_FETCH_SUCCESS =
  (state, action) => {
    const { categories } = action.payload;
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        exercisesCategories: {
          ...state.medicalRecord.exercisesCategories,
          fetching: {
            ...state.medicalRecord.exercisesCategories.fetching,
            getExercisesCategories: false,
          },
          api: {
            ...state.medicalRecord.exercisesCategories.api,
            categories,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_GET_CATEGORIES_FETCH_ERROR =
  (state, action) => {
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        exercisesCategories: {
          ...state.medicalRecord.exercisesCategories,
          fetching: {
            ...state.medicalRecord.exercisesCategories.fetching,
            getExercisesCategories: false,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICAL_RECORD_EXERCISES_GET_CATEGORIES_FETCH_REQUEST =
  (state, action) => {
    return {
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        exercisesCategories: {
          ...state.medicalRecord.exercisesCategories,
          fetching: {
            ...state.medicalRecord.exercisesCategories.fetching,
            getExercisesCategories: true,
          },
        },
      },
    };
  };

export const CONFIGURATION_MEDICALRECORD_GET_EXERCISES_FETCH_SUCCESS = (
  state,
  action
) => {
  const { exercises } = action.payload;
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercises: {
        ...state.medicalRecord.exercises,
        fetching: {
          ...state.medicalRecord.exercises.fetching,
          getExercises: false,
        },
        api: {
          ...state.medicalRecord.exercises.api,
          exercises
        },
      },
    },
  };
};

export const CONFIGURATION_MEDICALRECORD_GET_EXERCISES_FETCH_ERROR = (
  state,
  action
) => {
  return {
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      exercises: {
        ...state.medicalRecord.exercises,
        fetching: {
          ...state.medicalRecord.exercises.fetching,
          getExercises: false
        },
      },
    },
  };
};

