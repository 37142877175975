export default ({
  SECTION                                                   : 'overview',

  WORKSPACES_OVERVIEW_IS_FIRST_LOADING                      : 'WORKSPACES_OVERVIEW_IS_FIRST_LOADING',

  WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_REQUEST          : 'WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_REQUEST',
  WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_SUCCESS          : 'WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_SUCCESS',
  WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_ERROR            : 'WORKSPACES_OVERVIEW_GET_WORKSPACES_FETCH_ERROR',

  WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_REQUEST       : 'WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_REQUEST',
  WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_SUCCESS       : 'WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_SUCCESS',
  WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_ERROR         : 'WORKSPACES_OVERVIEW_UPDATE_WORKSPACES_FETCH_ERROR',

  WORKSPACE_CHECK_CEP_INFO_FETCH_SUCCESS: 'WORKSPACE_CHECK_CEP_INFO_FETCH_SUCCESS',
  WORKSPACE_CHECK_CEP_INFO_FETCH_ERROR: 'WORKSPACE_CHECK_CEP_INFO_FETCH_ERROR',

  WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_SUCCESS: 'WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_SUCCESS',
  WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_ERROR: 'WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_ERROR',
  WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_REQUEST: 'WORKSPACES_OVERVIEW_GET_WORKSPACE_INFO_REQUEST',

  
  WORKSPACES_OVERVIEW_SAVE_AVATAR_SUCCESS: 'WORKSPACES_OVERVIEW_SAVE_AVATAR_SUCCESS',
  WORKSPACES_OVERVIEW_SAVE_AVATAR_ERROR: 'WORKSPACES_OVERVIEW_SAVE_AVATAR_ERROR',
});