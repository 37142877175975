import _ from 'lodash';

export const SET_CERTIFICATE_NAME = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: action.payload.nameValue
      }
    }
  }
})

export const SET_CERTIFICATE_DESCRIPTION = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      description: {
        ...state.forms.modal.description,
        value: action.payload.descriptionValue
      }
    }
  }
})

export const RESET_CERTIFICATE_FORM = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: '',
        error: false
      },
      description: {
        ...state.forms.modal.description,
        value: '',
        error: false
      }
    }
  }
})

export const PUSH_NEW_CERTIFICATE_TO_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    certificateList: [
      ...state.api.certificateList,
      { ...action.payload }
    ]
  }
})

export const UPDATE_ITEM_ON_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    certificateList: _.map([ ...state.api.certificateList ], listItem => {
      if(listItem.id !== action.payload.id ) return listItem
      return action.payload;
    }) 
  }
})

export const LIST_CERTIFICATES_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listCertificateModel: true
  }
})

export const LIST_CERTIFICATES_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listCertificateModel: false
  }
})

export const LIST_CERTIFICATES_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listCertificateModel: false
  },
  api: {
    ...state.api,
    certificateList: [ ...action.payload.list ]
  }
})

export const CREATE_CERTIFICATE_FETCH_REQUEST = (state, action) => ({ 
  ...state,
  fetching: {
    ...state.fetching,
    createCertificateModel: true
  }
})

export const CREATE_CERTIFICATE_FETCH_ERROR = (state, action) => ({
  ...state, 
  fetching: {
    ...state.fetching,
    createCertificateModel: false
  }
})

export const CREATE_CERTIFICATE_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    createCertificateModel: false
  } 
})

export const UPDATE_CERTIFICATE_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateCertificateModel: true
  }
})

export const UPDATE_CERTIFICATE_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateCertificateModel: false
  }
})

export const UPDATE_CERTIFICATE_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateCertificateModel: false
  }
})

export const GET_CERTIFICATE_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getCertificateModel: true
  }
})

export const GET_CERTIFICATE_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getCertificateModel: false
  }
})

export const GET_CERTIFICATE_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getCertificateModel: false
  },
  api: {
    ...state.api,
    certificateModel: { ...action.payload }
  }
})