import initialStateSignIn from './signIn/initialState';
import initialStateRegister from './register/initialState';

import * as commonReducerRegister from './register/reducerGenerator';

import * as types from './constants';

export const INITIAL_STATE = {
  signIn: { ...initialStateSignIn },
  register: { ...initialStateRegister },
  auth: {
    active: false,
    userId: 0,
    name: '',
    email: '',
    intercomUserHash: '',
    company: {
      id: 0,
      fantasyName: '',
      permissionProfileId: 0,
      permissionProfileName: ''
    },
    business: {
      id: 0,
      accountId: 0,
      workspaceId: 0,
      name: '',
      url: '',
      userApps: []
    },
    newUser: false,
    userHasAccount: false,
    hasLoadedPermissions: false,
    permissions: {}
  },
  fetching: {
    recoverSuccessful: false,
    isRecoverTokenInvalid: false
  }
};

const LoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.LOGIN_SIGNIN_INVALID_EMAIL: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          message: '',
          validation: {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            email: true
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_INVALID_EMAIL_FORMAT: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          message: '',
          validation: {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            emailFormat: true
          }
        }
      };
    }
    case types.LOGIN_SET_USER_NAME: {
      const { newName } = action.payload;
      return {
        ...state,
        auth: {
          ...state.auth,
          name: newName
        }
      };
    }
    case types.LOGIN_SIGNIN_INVALID_PASSWORD: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          message: '',
          validation: {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            password: true
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          message: '',
          validation: {
            ...state.signIn.validation,
            ...initialStateSignIn.validation,
            passwordFormat: true
          }
        }
      };
    }
    case types.LOGIN_RECOVER_VERIFY_TOKEN_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRecoverTokenInvalid: false
        }
      };
    }
    case types.LOGIN_RECOVER_VERIFY_TOKEN_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRecoverTokenInvalid: action.payload
        }
      };
    }
    case types.LOGIN_RECOVER_VERIFY_TOKEN_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          isRecoverTokenInvalid: false
        }
      };
    }
    case types.LOGIN_RECOVER_SEND_EMAIL_FETCH_REQUEST: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          recoverSuccessful: false
        }
      };
    }
    case types.LOGIN_RECOVER_SEND_EMAIL_FETCH_ERROR: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          recoverSuccessful: false
        }
      };
    }
    case types.LOGIN_RECOVER_SEND_EMAIL_FETCH_SUCCESS: {
      return {
        ...state,
        fetching: {
          ...state.fetching,
          recoverSuccessful: true
        }
      };
    }
    case types.LOGIN_SIGNIN_FETCH_REQUEST: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          fetching: true,
          fetchingError: false,
          message: ''
        }
      };
    }
    case types.LOGIN_SIGNIN_FETCH_ERROR: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          message: action.payload,
          fetching: false,
          fetchingError: true,
          authenticated: false,
          checkedLocalStorage: false
        }
      };
    }
    case types.LOGIN_SIGNIN_FETCH_SUCCESS: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          fetching: false,
          authenticated: true,
          checkedLocalStorage: true,
          api: {
            ...state.signIn.api,
            signIn: {
              ...state.signIn.api.signIn,
              token: action.payload.token,
              userId: action.payload.userId,
              name: action.payload.name,
              email: action.payload.email,
              companys: action.payload.companys
            }
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_CLEAR_FORM_ERROR: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          validation: {
            ...state.signIn.validation,
            ...initialStateSignIn.validation
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_AUTHENTICATED: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          fetching: false,
          authenticated: true,
          checkedLocalStorage: true,
          api: {
            ...state.signIn.api,
            signIn: {
              ...state.signIn.api.signIn,
              token: action.payload.token,
              userId: action.payload.userId,
              name: action.payload.name,
              email: action.payload.email
            }
          }
        }
      };
    }
    case types.LOGIN_SIGNIN_LOGOUT: {
      return {
        ...state,
        signIn: {
          ...state.signIn,
          authenticated: false,
          checkedLocalStorage: false
        }
      };
    }
    case types.LOGIN_SET_AUTH: {
      const { permissions, ...auth } = action.payload;
      return {
        ...state,
        auth: {
          ...state.auth,
          ...auth,
          permissions: {
            ...state.auth.permissions,
            ...permissions
          }
        }
      };
    }
    case types.LOGIN_SET_AUTH_PERMISSION_REQUEST: {
      return {
        ...state,
        auth: {
          ...state.auth,
          hasLoadedPermissions: false
        }
      };
    }
    case types.LOGIN_SET_AUTH_PERMISSION: {
      const { permissions } = action.payload;
      return {
        ...state,
        auth: {
          ...state.auth,
          hasLoadedPermissions: true,
          permissions: {
            ...state.auth.permissions,
            ...permissions
          }
        }
      };
    }

    case types.LOGIN_REGISTER_GET_STATE:
      return commonReducerRegister.GET_STATE(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_GET_CITY:
      return commonReducerRegister.GET_CITY(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_STEP_NEXT:
      return commonReducerRegister.STEP(types.SECTION_REGISTER, state, action);
    case types.LOGIN_REGISTER_STEP_PREV:
      return commonReducerRegister.STEP(types.SECTION_REGISTER, state, action);
    case types.LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_REQUEST:
      return commonReducerRegister.GET_VALIDATE_STEP_FETCH_REQUEST(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_ERROR:
      return commonReducerRegister.GET_VALIDATE_STEP_FETCH_ERROR(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_SUCCESS:
      return commonReducerRegister.GET_VALIDATE_STEP_FETCH_SUCCESS(
        types.SECTION_REGISTER,
        state,
        action
      );

    case types.LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_REQUEST:
      return commonReducerRegister.VERIFY_RECAPTCHA_FETCH_REQUEST(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_ERROR:
      return commonReducerRegister.VERIFY_RECAPTCHA_FETCH_ERROR(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_SUCCESS:
      return commonReducerRegister.VERIFY_RECAPTCHA_FETCH_SUCCESS(
        types.SECTION_REGISTER,
        state,
        action
      );

    case types.LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_REQUEST:
      return commonReducerRegister.CREATE_ACCOUNT_FETCH_REQUEST(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_ERROR:
      return commonReducerRegister.CREATE_ACCOUNT_FETCH_ERROR(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_SUCCESS:
      return commonReducerRegister.CREATE_ACCOUNT_FETCH_SUCCESS(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_HIDE_SHOW_MESSAGE:
      return commonReducerRegister.HIDE_SHOW_MESSAGE(
        types.SECTION_REGISTER,
        state,
        action
      );

    case types.LOGIN_REGISTER_RESSEND_EMAIL_FETCH_REQUEST:
      return commonReducerRegister.RESSEND_EMAIL_FETCH_REQUEST(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_RESSEND_EMAIL_FETCH_ERROR:
      return commonReducerRegister.RESSEND_EMAIL_FETCH_ERROR(
        types.SECTION_REGISTER,
        state,
        action
      );
    case types.LOGIN_REGISTER_RESSEND_EMAIL_FETCH_SUCCESS:
      return commonReducerRegister.RESSEND_EMAIL_FETCH_SUCCESS(
        types.SECTION_REGISTER,
        state,
        action
      );
    default:
      return state;
  }
};

export default LoginReducer;
