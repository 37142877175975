import { toastr } from 'react-redux-toastr';

import types from './constant';
import selectorStarted from '@Components/started/selector';
import selectorLogin from '@Components/login/selector';
import * as actionLogin from '@Components/login/signIn/action';
import { setTabBilling } from '../billing/Overview/config/action';
import * as actionConfig from '@Actions/config';
import * as util from '@Util';
import systemConfigs from '@Constants/systemConfigs';
import { getUserPdfSettings } from '../pdfGenerator/action';

export const setIsFirstLoading = (data) => ({
  type: types.STARTED_OVERVIEW_IS_FIRST_LOADING,
  payload: data,
});

export const getUserDashboardFetchRequet = () => ({
  type: types.STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_REQUEST,
});
export const getUserDashboardFetchSuccess = (data) => ({
  type: types.STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_SUCCESS,
  payload: data,
});
export const getUserDashboardFetchError = (data) => ({
  type: types.STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_ERROR,
  payload: data,
});

export const createAccountFetchRequet = () => ({
  type: types.STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_REQUEST,
});
export const createAccountFetchSuccess = (data) => ({
  type: types.STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_SUCCESS,
  payload: data,
});
export const createAccountFetchError = (data) => ({
  type: types.STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_ERROR,
  payload: data,
});

export const selectedBusinessFetchRequet = () => ({
  type: types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_REQUEST,
});
export const selectedBusinessFetchSuccess = (data) => ({
  type: types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_SUCCESS,
  payload: data,
});
export const selectedBusinessFetchError = (data) => ({
  type: types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_ERROR,
  payload: data,
});

export const openedModal = () => ({
  type: types.STARTED_OVERVIEW_MODAL_OPENED,
});
export const closedModal = () => ({
  type: types.STARTED_OVERVIEW_MODAL_CLOSED,
});

export const setSelectedBusiness = (data) => ({
  type: types.STARTED_OVERVIEW_SET_SELECTED_BUSINESS,
  payload: data,
});

export const getUserDashboard = () => async (dispatch, getState, api) => {
  const state = getState();
  const userId = selectorLogin.getAuthUserId(state);
  const header = selectorLogin.getHeaderConfig(state);

  dispatch(getUserDashboardFetchRequet());

  try {
    let response = await api.user.getUserDashboard({ userId });
    const listAccount = response.data.accounts;
    const listWorkspace = response.data.workspaces;
    // if (!listAccount.length && !listWorkspace.length) {
    //   await api.apiPayment.payment.createAccountPurchaseRequest({ header });
    //   dispatch(actionConfig.routeRedirect('/purchase'));
    // } else {
      dispatch(
        getUserDashboardFetchSuccess({
          listWorkspace,
          listAccount,
        })
      );
    // }
  } catch (error) {
    dispatch(util.managerExceptionURI(error, getUserDashboardFetchError));
    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const createAccount = ({ enableIntegration, name, key }) => async (
  dispatch,
  getState,
  api
) => {
  const state = getState();
  const userId = selectorLogin.getAuthUserId(state);

  dispatch(createAccountFetchRequet());

  try {
    !enableIntegration
      ? await api.account.createAccount({ name, userId })
      : await api.account.createIntegration({ key });

    toastr.success('Sucesso', 'Salvo com sucesso!');

    dispatch([
      createAccountFetchSuccess(),
      actionConfig.resetForms('FormModalStartedCreateBusiness'),
      getUserDashboard(),
    ]);
  } catch (error) {
    dispatch(util.managerExceptionURI(error, createAccountFetchError));
    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const selectedBusiness = (data) => async (dispatch, getState, api) => {
  const state = getState();
  const _business = selectorStarted.getBusiness(state);
  const header = selectorLogin.getHeaderConfig(state);
  dispatch(selectedBusinessFetchRequet());
  const auth = JSON.parse(util.getCookie('auth') || '{}');

  if (!Object.keys(auth).length) {
    dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
    return;
  }
  const funcKeys = [...Array(14).keys()].filter((i) => i != 0);
  let permissionsBilling = {};
  funcKeys.forEach((i) => {
    permissionsBilling = { ...permissionsBilling, [i]: false };
  });
  if (data.url === 'billing') {
    auth.business = { ...data };
    auth.permissions = {
      functionalitiesIds: [12, 13],
      account: {
        ...permissionsBilling,
        12: true,
        13: true,
      },
    };
    util.setCookie('auth', JSON.stringify(auth), systemConfigs.ROOT_DOMAIN);
    dispatch([
      actionLogin.setAuthPermissionRequest(),
      actionLogin.setAuth({ ...auth }),
      actionLogin.setAuthPermission({ permissions: auth.permissions }),
      selectedBusinessFetchSuccess(data),
      setTabBilling('tab:invoice'),
      actionConfig.routeRedirect('/manager/' + data.url),
    ]);
    return;
  }
  try {
    if (data.url === 'app') {
      let resGetUser = await api.workspace.getUserWorkspaceApps(data.id);
      resGetUser = resGetUser.data;

      data.userApps = resGetUser.apps;
      data.workspaceProfileId = resGetUser.workspaceProfileId;

      const [workspaceProfile, workspaceFunctionalities, groupPermissionResponse] = await Promise.all([
        api.permission.getWorkspaceProfileById(resGetUser.workspaceProfileId),
        api.permission.getWorkspaceFunctionalities(data.accountId),
        api.apiAccount.account.getGroupPermissionByAccountId({ accountId: data.accountId, header }),
      ]);
     
      const { appIds, functionalitiesIds, appFunctionalitiesIds } = workspaceProfile || {};
      const { apps = [] } = workspaceFunctionalities || {};
      const listAccount = groupPermissionResponse?.data || [];
      auth.groupPermission = listAccount;

      const permissions = {
        appsIds: {},
        workspace: {},
      };

      functionalitiesIds.forEach(
        (item) => (permissions.workspace[item] = true)
      );

      for (let appId of appIds) {
        const { functionalities = [] } =
          apps.filter((item) => item.appId === appId)[0] || {};

        for (let functionality of functionalities) {
          const enable =
            !!appFunctionalitiesIds.filter(
              (ids) => ids === functionality.functionalityId
            )[0] || false;

          permissions.appsIds[appId] = {
            ...permissions.appsIds[appId],
            [functionality.functionalityId]: enable,
          };
        }
      }

      auth.permissions = {
        ...permissions,
        appIds,
        functionalitiesIds,
        appFunctionalitiesIds,
      };

      dispatch([
        getUserPdfSettings(data.workspaceId)
      ]);
    }


    auth.business = { ...data };
    util.setCookie('auth', JSON.stringify(auth), systemConfigs.ROOT_DOMAIN);

    dispatch([
      actionLogin.setAuthPermissionRequest(),
      actionLogin.setAuth({ ...auth }),
      actionLogin.setAuthPermission({ permissions: auth.permissions }),
      selectedBusinessFetchSuccess(data),
      actionConfig.routeRedirect(data.url),,
    ]);
  } catch (error) {
    dispatch(util.managerExceptionURI(error, selectedBusinessFetchError));
    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const updateBusiness = (props) => (dispatch, getState, api) => {
  const auth = JSON.parse(util.getCookie('auth') || '{}');

  if (!Object.keys(auth).length) {
    dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
    return;
  }

  if (auth.hasOwnProperty('business')) {
    auth.business.name = props.name;
  }

  util.setCookie('auth', JSON.stringify(auth), systemConfigs.ROOT_DOMAIN);

  dispatch(setSelectedBusiness(props));
};
