import * as account     from './account';
import * as model       from './model';
import * as section     from './section';
import * as attendance  from './attendance';
import * as coreLine    from './coreLine';
import * as coreLineAttendance    from './coreLineAttendance';
import * as filter from './filter';
import * as indicators from './indicators';

export default ({
  account,
  model,
  section,
  attendance,
  coreLine,
  filter,
  coreLineAttendance,
  indicators,
});