import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';
import axiosRef from 'axios';

import { get, del, post, put } from '../util';

const   CLASSIFICATION = 'classification';
const   GROUP = 'Group';
const INACTIVATE = 'inactivate';

//#region GET
export const getClassificationDocs = async ({ header, type }) => {
  const pathUrl = `${endpoints.API_DOCS}/${CLASSIFICATION}/${type}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getClassificationDocById = async ({ header, classificationId }) => {
  const pathUrl = `${endpoints.API_DOCS}/${CLASSIFICATION}/${classificationId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createClassificationDoc = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${CLASSIFICATION}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateClassificationDoc = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${CLASSIFICATION}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//endregion classificationGroup

//#region DELETE
export const deleteClassificationDoc = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_DOCS}/${CLASSIFICATION}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};

export const classificationDocGroup = async ({ header }) => {
    const pathUrl = `${endpoints.API_DOCS}/${CLASSIFICATION}/${CLASSIFICATION}/${GROUP}`;
    const axios = interceptor(header);
    return await get(axios, pathUrl);
  };
//endregion
