import initialState from './initialState';

export const USER_CREATE_IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const USER_CREATE_GET_STATE_CITY = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      listCityIndexed: {
        ...state[section].api.listCityIndexed,
        ...action.payload.listCityIndexed
      },
      listState: action.payload.listState
    }
  }
});

export const USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      councils: [...action.payload]
    }
  }
});

export const USER_CREATE_GET_PROFESSIONAL_COUNCILS_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true
    }
  }
});

export const USER_CREATE_CHECK_CEP_INFO_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_CREATE_CHECK_CEP_INFO_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedCep: {
        ...action.payload
      }
    }
  }
});

export const USER_CREATE_CHECK_CEP_INFO_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});

export const USER_CREATE_INVITE_CORRECTION_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      emailCorrected: false
    },
    fetching: {
      ...state[section].fetching,
      isCorrectingInvite: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_CREATE_INVITE_CORRECTION_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      emailCorrected: true
    },
    fetching: {
      ...state[section].fetching,
      isCorrectingInvite: false
    }
  }
});

export const USER_CREATE_INVITE_CORRECTION_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      emailCorrected: false
    },
    fetching: {
      ...state[section].fetching,
      isCorrectingInvite: false
    },
    error: {
      ...state[section].error,
      done: true
      // messages: [...state[section].error.messages, action.payload.userFeedback]
    }
  }
});

export const CLEAR_USER_FOUND = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedUser: {
        ...initialState.api.searchedUser
      }
    }
  }
});

export const VERIFY_REGISTERED_USER_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      emailCorrected: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const VERIFY_REGISTERED_USER_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedUser: {
        // ...state[section].api.searchedUser,
        hasInvite: action.payload.hasInvite,
        inviteEmail: action.payload.inviteEmail,
        data: {
          ...action.payload.data
        }
      }
    }
  }
});

export const VERIFY_REGISTERED_USER_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true
      // messages: [
      //   ...state[section].error.messages,
      //   action.payload.message
      // ]
    }
  }
});

export const USER_CREATE_GET_WORKSPACES_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      workspaces: true,
      workspacesError: false
    },
    api: {
      ...state[section].api,
      searchedUser: initialState.api.searchedUser
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_CREATE_GET_WORKSPACES_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      workspaces: false,
      workspacesError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});

export const USER_CREATE_GET_WORKSPACES_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      workspaces: false
    },
    api: {
      ...state[section].api,
      listWorkspace: action.payload.listWorkspace
    }
  }
});

export const USER_CREATE_SEND_INVITE_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      sendInvite: true,
      sendInviteError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_CREATE_SEND_INVITE_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      sendInvite: false,
      sendInviteError: true
    }
  }
});

export const USER_CREATE_SEND_INVITE_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      sendInvite: false
    }
  }
});

export const USER_CREATE_SET_ACCOUNT = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      business: {
        ...state[section].api.business,
        ...action.payload
      }
    }
  }
});

export const USER_CREATE_SET_ALL_PERFIL = (section, state, action) => {
  const { perfilAccount, perfilWorkspace } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        perfilAccount,
        perfilWorkspace
      }
    }
  };
};

export const USER_CREATE_SET_ONLY_PERFIL_WORK = (section, state, action) => {
  const { perfilWorkspace } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        perfilWorkspace
      }
    }
  };
};

export const USER_CREATE_PROFESSIONAL_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      createProfessional: true
    }
  }
});

export const USER_CREATE_PROFESSIONAL_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      createProfessional: false
    }
  }
});

export const USER_CREATE_PROFESSIONAL_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      createProfessional: false
    }
  }
});

export const CHECK_CEP_INFO_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const CHECK_CEP_INFO_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      searchedCep: {
        ...action.payload
      }
    }
  }
});

export const CHECK_CEP_INFO_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});