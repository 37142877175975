import { createSelector } from 'reselect';
const getContextByKey                           = key => state => state.perfil.configuration.medicalRecord.sections[key];
const getFetchContextByKey                      = key => state => state.perfil.configuration.medicalRecord.sections.fetching[key];
const getApiContextByKey                        = key => state => state.perfil.configuration.medicalRecord.sections.api[key];
const getAccountMedicalRecordsModelContextByKey = key => state => state.perfil.configuration.medicalRecord.sections.api.accountMedicalRecordsModel[key];

export default ({
  getIsListSections: createSelector(
    getFetchContextByKey('getListSections'),
    state => state
  ),
  getIsListFormModels: createSelector(
    getFetchContextByKey('listFormModels'),
    state => state
  ),
  getIsSaveSections: createSelector(
    getFetchContextByKey('saveSection'),
    state => state
  ),

  getlistSections: createSelector(
    getAccountMedicalRecordsModelContextByKey('listSections'),
    state => state
  ),
  getAccountMedicalRecordsModelId: createSelector(
    getAccountMedicalRecordsModelContextByKey('accountMedicalRecordsModelId'),
    state => state
  ),
  getMedicalRecordsModelSectionId: createSelector(
    getAccountMedicalRecordsModelContextByKey('medicalRecordsModelSectionId'),
    state => state
  ),
  getMedicalRecordsModelId: createSelector(
    getAccountMedicalRecordsModelContextByKey('medicalRecordsModelId'),
    state => state
  ),
  getIsEnableEditSection: createSelector(
    getAccountMedicalRecordsModelContextByKey('enableEditSection'),
    state => state
  ),

  getIsModalOpen: createSelector(
    getApiContextByKey('modalOpen'),
    state => state
  ),

  getListPrivate: createSelector(
    getApiContextByKey('listPrivate'),
    state => state
  ),
  getListSharing: createSelector(
    getApiContextByKey('listSharing'),
    state => state
  ),

  getInputValue: createSelector(
    getContextByKey('inputValue'),
    state => state
  ),

  getInputNumberValue: createSelector(
    getContextByKey('inputNumberValue'),
    state => state
  )
});