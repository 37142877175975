import React, { Component, Fragment } from 'react';
import { reduxForm, Field } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import classNames from 'classnames';
import { isLargeDisplay } from '@Util/mediaQueryService';
import ReactGA from 'react-ga';
import TagManager from '@Constants/tagManager';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { RECAPTCHA_KEY }      from '@Constants/apiKey';

import { Icon } from 'antd';

import * as util from '@Util';
import tagManager from '@Constants/tagManager';

let isLarge = isLargeDisplay();

class FormUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estate: 0,
      city: 0,
      listCity: [],
      recaptchaToken: null,
      recaptchaTokenLogin: null,
    };
    this.renderField = this.renderField.bind(this);
    this.redirectRoute = this.redirectRoute.bind(this);
    this.renderSelectField = this.renderSelectField.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.executeRecaptcha();
    if (
      window.document.getElementsByClassName('grecaptcha-badge')[0] &&
      !window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.contains('grecaptcha-show')
    ) {
      window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.add('grecaptcha-show');
    }
  }

  componentDidUpdate() {
    if (
      window.document.getElementsByClassName('grecaptcha-badge')[0] &&
      !window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.contains('grecaptcha-show')
    ) {
      window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.add('grecaptcha-show');
    }
  }

  inputBlur = (inputName) => (e) => {
    const value = e.target.value;
    if (
      value.length > 0 &&
      Object.values(tagManager.REGISTER.LABELS).includes(inputName)
    ) {
      ReactGA.event({
        category: tagManager.REGISTER.CATEGORY,
        action: tagManager.REGISTER.ACTIONS.REGISTER_FIELDS,
        label: inputName,
      });
    }
  };
  executeRecaptcha = async () => {
    await this.props.googleReCaptchaProps
      .executeRecaptcha('register')
      .then((token) => {
        if (!this.state.recaptchaToken)
          this.setState({ recaptchaToken: token });
        else this.setState({ recaptchaTokenLogin: token });
      })
      .catch(() => {
        util.managerExceptionURIMessage({
          customMsg:
            'Não foi possível verificar seu acesso. Atualize a página ou entre em contato conosco.',
        });
      });
  };

  render() {
    const { classes, handleSubmit, dataSource, fetching } = this.props;
    const { listCity } = this.state;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <Grid container direction={'column'} className={classes.container}>
          <Grid item>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    { [classes.paddingRight15]: isLarge },
                    classes.marginBottom15
                  )}
                >
                  <Field
                    required
                    type={'text'}
                    name={'name'}
                    placeholder={'Nome'}
                    onBlur={this.inputBlur('name')}
                    label={'Nome'}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    validate={[util.required]}
                    component={this.renderField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.marginBottom15
                  )}
                >
                  <Field
                    required
                    type={'text'}
                    name={'email'}
                    placeholder={'Email'}
                    onBlur={this.inputBlur('email')}
                    label={'Email'}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    validate={[util.email, util.required]}
                    component={this.renderField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.marginBottom15,
                    { [classes.paddingRight15]: isLarge }
                  )}
                >
                  <Field
                    required
                    type={'text'}
                    name={'cpf'}
                    placeholder={'___.____.____-____'}
                    onBlur={this.inputBlur('cpf')}
                    label={'CPF'}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    validate={[util.required, util.validateCPF]}
                    normalize={util.normalizeCPF}
                    component={this.renderField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.marginBottom15
                  )}
                >
                  <Field
                    required
                    type={'text'}
                    name={'cel'}
                    placeholder={'(__) ____-____'}
                    onBlur={this.inputBlur('cel')}
                    label={'Contato'}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    validate={[util.required]}
                    normalize={util.normalizePhone}
                    component={this.renderField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.marginBottom15,
                    classes.formControl,
                    classes.hasLabelState,
                    { [classes.paddingRight15]: isLarge }
                  )}
                  variant="outlined"
                >
                  <Field
                    required
                    name={'state'}
                    label={'Estado'}
                    onBlur={this.inputBlur('state')}
                    labelId="input-label-estado"
                    id={'id-state'}
                    value={this.state.city}
                    props={{ dataSource: dataSource.estado }}
                    onChange={this.handleChangeEstado}
                    validate={[util.requiredValue]}
                    component={this.renderSelectField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.formControl,
                    classes.hasLabelState,
                    classes.marginBottom15
                  )}
                  variant="outlined"
                >
                  <Field
                    required
                    name={'city'}
                    label={'Cidade'}
                    onBlur={this.inputBlur('city')}
                    select
                    labelId="input-label-cidade"
                    id={'id-city'}
                    value={this.state.city === 0}
                    props={{ dataSource: listCity }}
                    onChange={this.handleChangeCity}
                    validate={[util.requiredValue]}
                    component={this.renderSelectField}
                    SelectProps={this.renderSelectField}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.marginBottom15,
                    classes.formControl,
                    { [classes.paddingRight15]: isLarge }
                  )}
                >
                  <Field
                    required
                    type={'password'}
                    name={'password'}
                    placeholder={'Senha'}
                    onBlur={this.inputBlur('password')}
                    label={'Senha'}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    validate={[util.required, util.minLength6]}
                    component={this.renderField}
                  // variant='outlined'
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  className={classNames(
                    classes.marginBottom15,
                    classes.formControl
                  )}
                >
                  <Field
                    required
                    type={'password'}
                    name={'password2'}
                    placeholder={'Confirmar Senha'}
                    onBlur={this.inputBlur('password2')}
                    label={'Confirmar Senha'}
                    className={classes.textField}
                    InputProps={{
                      classes: {
                        underline: classes.underline,
                        input: classes.input,
                      },
                    }}
                    InputLabelProps={{ shrink: true }}
                    validate={[util.required, util.minLength6]}
                    component={this.renderField}
                  // variant='outlined'
                  />
                </FormControl>
              </Grid>
                          </Grid>
          </Grid>

          <Grid item container className={classes.actions}>
            <Grid container>
              <Grid
                item
                className={classNames(
                  classes.flex,
                  classes.alignLeft,
                  classes.alignSelfCenter,
                  classes.marginBottom15
                )}
              >
                <span
                  className={classes.buttonRegisterlabel}
                  onClick={this.redirectRoute}
                >
                  {' '}
                  Voltar pro Login{' '}
                </span>
              </Grid>

              <Grid
                item
                className={classNames(classes.flex, classes.alignRight)}
              >
                <Button
                  type={'submit'}
                  variant={'contained'}
                  color={'primary'}
                  size={'medium'}
                  classes={{
                    root: classes.buttonStepRoot,
                    containedPrimary: classes.buttonStepContainedPrimary,
                    disabled: classes.disabledPrimary,
                  }}
                  disabled={fetching.createAccount}
                >
                  <span>Salvar</span>
                  {fetching.createAccount && (
                    <span className={classes.iconLoading}>
                      {' '}
                      <Icon type={'loading'} />{' '}
                    </span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderField({ input, meta: { touched, error }, ...custom }, errorInputProps) {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField
          {...input}
          {...custom}
          variant="outlined"
          error={touched && error && error.length > 0}
        />
        {touched && error && (
          <FormHelperText className={classes.errorText}>
            {' '}
            {error}
          </FormHelperText>
        )}
      </Fragment>
    );
  }

  renderSelectField(
    { input, meta: { touched, error }, ...custom },
    errorInputProps
  ) {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField
          variant="outlined"
          select
          error={touched && error && error.length > 0}
          label={custom.label}
          {...input}
          inputProps={{ name: input.name, id: custom.id }}
          SelectProps={{
            native: true,
          }}
        >
          <option value={0} key={-1}></option>
          {custom.dataSource.map((item, key) =>
            input.name === 'state' ? (
              <option key={key} value={item[`${input.name}Id`]}>
                {' '}
                {item[`${input.name}Initials`]} - {item[`${input.name}Name`]}{' '}
              </option>
            ) : (
              <option key={key} value={item[`${input.name}Id`]}>
                {' '}
                {item[`${input.name}Name`]}{' '}
              </option>
            )
          )}
        </TextField>
        {touched && error && (
          <FormHelperText className={classes.errorText}>
            {' '}
            {error}
          </FormHelperText>
        )}
      </Fragment>
    );
  }

  onSubmit(values) {
    ReactGA.event({
      category: tagManager.REGISTER.CATEGORY,
      action: tagManager.REGISTER.ACTIONS.SIGNUP_CLICK,
      label: tagManager.REGISTER.LABELS.SIGNUP,
    });
    const { estate, city } = this.state;
    new Promise(() =>
      this.executeRecaptcha()
        .then(() => {
          this.props.onSubmit({
            ...values,
            stateId: parseInt(estate, 10),
            cityId: parseInt(city, 10),
            gRecaptchaResponse1: this.state.recaptchaToken,
            gRecaptchaResponse2: this.state.recaptchaTokenLogin,
          });
        })
        .catch((e) => {})
    );
  }

  handleChangeEstado = (event) => {
    const { dataSource } = this.props;
    const value = event.target.value ? parseInt(event.target.value, 10) : 0;

    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      [`error${event.target.name}`]: false,
      listCity: dataSource.city[value],
    });
  };

  handleChangeCity = (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
      [`error${event.target.name}`]: false,
    });
  };

  redirectRoute() {
    window.document
      .getElementsByClassName('grecaptcha-badge')[0]
      .classList.remove('grecaptcha-show');
    this.props.history.push('/');
  }
}

const validate = (values) => {
  const errors = {};

  if (values.password && values.password !== values.password2)
    errors.password2 = 'As senhas tem que ser iguais!';

  return errors;
};

FormUser.propTypes = {};

export default reduxForm({ form: 'FormUser', validate })(
  withRouter(withGoogleReCaptcha(FormUser))
);
