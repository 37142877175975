// import initialState from './initialState';
import types from './constant';

const INITIAL_STATE = {
  dialogOpen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.NO_PERMISSION_DIALOG_SET_STATE: {
      return {
        ...state,
        dialogOpen: action.payload
      };
    }
    default:
      return state;
  }
};
