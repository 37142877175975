export default ({
  fetching: {
    createCertificateModel: false,
    updateCertificateModel: false,
    listCertificateModel: false,
    getCertificateModel: false
  },
  api: {
    certificateList: [],
    certificateModel: {
      certificateModelId: null,
      name: '',
      content: ''
    }
  },
  forms: {
    modal: {
      name: {
        value: '',
        error: false
      },
      description: {
        value: '',
        error: false
      }
    }
  }
});