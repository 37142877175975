// import bglogin        from '@Assets/images/bg-login@600.png';
// import bglogin992     from '@Assets/images/bg-login@992.png';
import bgLogin1280 from '@Assets/images/bg-login@1280.png';

export const styles = theme => ({
  root: {
    width: '100%',
    margin: 0,
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    // backgroundSize: 'cover',
    backgroundImage: `url(${bgLogin1280})`
    // [theme.breakpoints.up('md')]: {
    //   backgroundImage: `url(${bglogin992})`,
    // },
    // [theme.breakpoints.up('lg')]: {
    //   backgroundImage: `url(${bgLogin1280})`,
    // },
  },
  container: {
    padding: '1em',
    //marginBottom: '5em'
  },
  control: {
    padding: '2em 1.5em',
    marginBottom: '5em',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: 'none !important',
    position: 'relative',
    top: '-5em',
    [theme.breakpoints.down('sm')]: {
      top: 0,
      overflowY: 'auto'
    },
    '&$controlMessage': {
      backgroundColor: '#006efe'
    }
  },
  item: {
    padding: '0 !important'
  },
  contentForms: {
    height: '100vh',
    display: 'flex',
    flex: '1 100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100vh',
      position: 'absolute',
      alignItems: 'unset',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    '&$contentFormsMessage': {
      padding: '1em',
      flexDirection: 'column'
    }
  },
  toolBarImage: {
    width: 'auto',
    height: '5.8em'
  },
  containerHeader: {
    marginTop: '1em',
    marginBottom: '3em'
  },
  buttonStepRoot: {
    '&$disabledPrimary': {
      color: '#FFF',
      backgroundColor: '#3B7BFA',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      '&:hover': { backgroundColor: '#4F88FA' },
      '&:focus': { backgroundColor: '#4F88FA' },
      '&:active': { backgroundColor: '#4F88FA' }
    },
    '&$disabledSecondary': {
      color: '#FFF',
      backgroundColor: '#1ABC9C',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      '&:hover': { backgroundColor: '#18AB8E' },
      '&:focus': { backgroundColor: '#18AB8E' },
      '&:active': { backgroundColor: '#18AB8E' }
    }
  },
  buttonStepContainedPrimary: {
    backgroundColor: '#286efa',
    boxShadow: 'none',
    '&:hover': { backgroundColor: '#2564E4' },
    '&:focus': { backgroundColor: '#2564E4' },
    '&:active': { backgroundColor: '#2564E4' }
  },
  buttonStepContainedSecondary: {
    backgroundColor: '#18AB8E',
    '&:hover': { backgroundColor: '#169A80' },
    '&:focus': { backgroundColor: '#169A80' },
    '&:active': { backgroundColor: '#169A80' }
  },
  flex: {
    flex: 1
  },
  alignRight: {
    textAlign: 'right'
  },
  alignLeft: {
    textAlign: 'left'
  },
  errorText: {
    marginLeft: theme.spacing.unit,
    color: '#F44336',
    position: 'absolute',
    bottom: -15
  },
  textField: {
    marginTop: '1rem',
    display: 'inline-flex',
    flex: 1
  },
  formControl: {
    width: '100%'
  },
  actions: {
    marginTop: '2em',
    padding: '1em'
  },
  paddingRight15: {
    paddingRight: '15px'
  },
  marginBottom15: {
    marginBottom: '15px'
  },
  rootSteps: {
    maxWidth: 600
  },
  hasLabelState: {
    marginTop: 14
  },
  selectMenu: {
    textAlign: 'left'
  },
  headerFormActive: {
    fontSize: 20,
    color: '#888'
  },
  iconLoading: {
    display: 'inline',
    position: 'relative',
    top: -1,
    marginLeft: 5
  },
  buttonRegisterlabel: {
    fontSize: 14,
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#888',
    '&:hover': {
      color: '#286efa',
      cursor: 'pointer'
    }
  },
  alignSelfCenter: {
    alignSelf: 'center'
  },
  containerMessage: {
    display: 'flex'
  },
  iconMessage: {
    display: 'inline',
    marginRight: '1em',
    opacity: 0.7,
    color: '#FFF'
  },
  infoMessage: {
    color: '#FFF'
  },
  infoLink: {
    color: '#FFF',
    fontStyle: 'italic',
    marginTop: '1em',
    display: 'inherit',
    textDecoration: 'underline',
    fontWeight: '300',
    '&:hover': {
      color: '#FFF',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  link: {
    fontSize: 14,
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#888',
    '&:hover': {
      color: '#286efa',
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  disabledPrimary: {},
  disabledSecondary: {},
  controlMessage: {},
  contentFormsMessage: {}
});
