import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { toastr } from 'react-redux-toastr';

import { styles } from './style';

import Header from './header';
import Form from './form';
import LoadingComponent from '@Common/loading';
import selector from '../selector';
import * as actions from './action';
import * as util from '@Util';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';

class SignIn extends Component {
  _isMounted = false;
  state = { recaptchaToken: null };

  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.executeRecaptcha();
    // window.document.getElementsByClassName("grecaptcha-badge")[0].classList.add("grecaptcha-show");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  executeRecaptcha = async () => {
    const { executeRecaptcha } = this.props.googleReCaptchaProps;
      await executeRecaptcha('login')
      .then(token => {
        if (this._isMounted) this.setState({ recaptchaToken: token });
      })
      .catch(() => {
        util.managerExceptionURIMessage({
          customMsg:
            'Não foi possível verificar seu acesso. Atualize a página ou entre em contato conosco.'
        });
      }) /* .finally(() => {

      }) */;
  };

  render() {
    const { classes, signInState } = this.props;
    const { recaptchaToken } = this.state;
    // window.Intercom('shutdown');
    // window.Intercom('boot', {app_id: 'ipy1c31k'})

    const recaptchaHasLoaded = window.document.getElementsByClassName(
      'grecaptcha-badge'
    )[0]
      ? true
      : false;

    // return recaptchaHasLoaded ? (
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.item}>
          <div className={classes.contentForms}>
            <Paper className={classes.control}>
              <Header classes={classes} />
              <Form
                classes={classes}
                validation={signInState.validation}
                isLoading={signInState.fetching || !recaptchaHasLoaded}
                onSubmit={this.onSubmit}
                recaptchaHasLoaded={recaptchaHasLoaded}
                recaptchaToken={recaptchaToken}
                executeRecaptcha={this.executeRecaptcha}
              />
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
    // )
    //  : (
    //   <div style={{ backgroundColor: 'white' }}>
    //     <LoadingComponent message="Configurando ambiente para acesso seguro..." />
    //   </div>
    // );
  }

  componentDidUpdate() {
    const { signInState } = this.props;

    if (signInState.message && signInState.message.length) {
      toastr.warning('Atenção', signInState.message);
      return;
    }
  }

  componentWillMount() {
    //util.deleteCookie('auth', '', '', 8);
  }

  onSubmit(values) {
    const {
      setSignIn,
      setClearFormError,
      setSignInInvalidEmail,
      setSignInInvalidPassword
    } = this.props;

    if (!values.email) {
      setSignInInvalidEmail();
      return;
    }

    if (!values.password || values.password.length < 6) {
      setSignInInvalidPassword();
      return;
    }

    setClearFormError();
    setSignIn(values);
  }
}

const mapStateToProps = state => ({
  signInState: selector.getSignIn(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSignIn: actions.signIn,
      setClearFormError: actions.setClearFormError,
      setSignInInvalidEmail: actions.setSignInInvalidEmail,
      setSignInInvalidPassword: actions.setSignInInvalidPassword
    },
    dispatch
  );

SignIn = withStyles(styles)(SignIn);
SignIn = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export default withGoogleReCaptcha(SignIn);
