export default {
  isFirstLoading: true,

  fetching: {
    getWorkspaceProfiles: false,
    getWorkspaceFunctionalities: false,
    getWorkspaceProfileById: false,

    postWorkspaceProfile: false,

    putWorkspaceProfile: false,

    deleteWorkProfile: false
  },

  search: {
    listProfiles: [],
    listApps: []
  },

  api: {
    workspaceChecked: false,

    apps: {},
    profiles: {},
    workspaces: {},

    listApps: [],
    listProfiles: [],
    listAppsFunctionalities: [],
    listWorkspacesFunctionalities: [],

    //utilizado tanto para workspace quanto os apps
    idSelected: 0,
    functionality: {},
    listFunctionality: [],

    adminProfile: {},
    profileSelected: {
      id: 0,
      name: '',
      sigla: '',
      workspaceFunctionalitiesIds: [],
      appFunctionalitiesIds: []
    }
  }
};
