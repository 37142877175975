import types from './constant';

export const setDialogState = data => ({
  type: types.SCORES_FIELD_REFERENCE_DATA_SET_STATE,
  payload: data
});

export const setDialogTriggerState = data => ({
  type: types.SCORES_FIELD_REFERENCE_DATA_TRIGGER_SET_STATE,
  payload: data
});

export const setScoreFieldIds = data => ({
  type: types.SCORES_FIELD_REFERENCE_DATA_FIELDIDS_SET_IDS,
  payload: data
});