import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { faExclamationCircle }                    from '@fortawesome/pro-light-svg-icons';
import messages                 from '@Util/messages';
import { FormattedMessage, useIntl }     from 'react-intl'
import { FontAwesomeIcon }    from '@fortawesome/react-fontawesome';
import * as modalFieldActions   from '@Components/ModalFields/action'
import * as actions from './action';
import selectors from './selector';

const fieldModelFeedbackMessageSwitcher = (diff) => {
  switch (diff) {
    case 'title':
      return 'versionAlterTitle';
    case 'dataType':
      return 'versionDiffDataType';
    case 'measurementUnit':
      return  'versionDiffMeasurementUnit';
    default:
      return null;
  }
};

const FieldModelDialog = props => {
  const { dialogOpen, setDialogState, updateFields, isAvailable, diff, data } = props;
  const outputMessage = !isAvailable ? 'allParamsVersionMatch' : fieldModelFeedbackMessageSwitcher(diff);
  const updateButtonSaveName = !isAvailable ? 'Atualizar' : 'Criar Novo';

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogState(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: '400px',
            textAlign: 'left',
            verticalAlign: 'middle',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <FontAwesomeIcon icon={faExclamationCircle} style={{marginRight: '2%'}} color={"rgb(87,139,252)"}/>
          {'Atenção'}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', marginLeft: '32px' }}>
        <FormattedMessage
          {...messages[outputMessage]}
          values={{
            div: msg => (
              <div>
                {msg}
              </div>
            ),
            br: <br/>,
          }}
        />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogState({dialogOpen: false})}
            autoFocus
          >
            Voltar
          </Button>
          <Button
            onClick={() => onSave(props)}
            autoFocus
            type='primary'
          >
            {updateButtonSaveName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const onSave = props => {
  const {updateFields, setDialogState, data} = props;
  const {dataType, modal, field, codeBlock, totalFields, configFullyAnswered} = data;
  updateFields(dataType, modal, field, codeBlock, totalFields, configFullyAnswered);
  setDialogState({dialogOpen: false});
}


const mapStateToProps = (state, ownProps) => ({
  dialogOpen: selectors.getIsDialogOpen(state),
  diff: selectors.getFieldModelDiff(state),
  isAvailable: selectors.getIsAvailable(state),
  data: selectors.getData(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDialogState: actions.setDialogState,
      updateFields: modalFieldActions.updateFields,
    },
    dispatch
  );

FieldModelDialog.defaultProps = {
  dialogOpen: false,
  diff: '',
  isAvailable: false,
  data: {},
};

FieldModelDialog.propTypes = {
  dialogOpen: PropTypes.bool,
  diff: PropTypes.string,
  isAvailable: PropTypes.bool,
  data: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FieldModelDialog);
