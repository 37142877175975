export default {
  SECTION: 'overview',
  FORM_TAB_ADDRESS: 'form:tab:address',

  MODAL_PAYMENT_METHOD: 'modal:paymentmethod',
  MODAL_CHANGE_PLAN: 'modal:changeplan',
  MODAL_CHANGE_USERS_NUMBER: 'modal:changeusers',
  MODAL_CONFIRM_CHANGE_USERS_NUMBER: 'modal:confirmchangeusers',

  MANAGER_BILLING_SET_PACKAGE_DATA: 'MANAGER_BILLING_SET_PACKAGE_DATA',
  MANAGER_BILLING_SET_BILLING_DATA: 'MANAGER_BILLING_SET_BILLING_DATA',
  MANAGER_BILLING_SET_BILLING_CONFIG: 'MANAGER_BILLING_SET_BILLING_CONFIG',
  MANAGER_BILLING_SET_PAYMENT_METHOD: 'MANAGER_BILLING_SET_PAYMENT_METHOD',
  MANAGER_BILLING_SET_PAYMENT_NEW_METHOD:
    'MANAGER_BILLING_SET_PAYMENT_NEW_METHOD',
  MANAGER_BILLING_SET_PAYMENT_HISTORY: 'MANAGER_BILLING_SET_PAYMENT_HISTORY',

  MANAGER_BILLING_CHECK_CEP_FETCH_REQUEST:
    'MANAGER_BILLING_CHECK_CEP_FETCH_REQUEST',
  MANAGER_BILLING_CHECK_CEP_FETCH_SUCCESS:
    'MANAGER_BILLING_CHECK_CEP_FETCH_SUCCESS',
  MANAGER_BILLING_CHECK_CEP_FETCH_ERROR:
    'MANAGER_BILLING_CHECK_CEP_FETCH_ERROR',

  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_REQUEST:
    'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_REQUEST',
  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_SUCCESS:
    'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_SUCCESS',
  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_ERROR:
    'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_ERROR',
  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_REQUEST:
    'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_REQUEST',
  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_SUCCESS:
    'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_SUCCESS',
  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_ERROR:
    'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_ERROR',
  MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_TEMP: 'MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_TEMP',

  MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST:
    'MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST',
  MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS:
    'MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS',
  MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR:
    'MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR',

  MANAGER_BILLING_ADD_CARD_FETCH_REQUEST:
    'MANAGER_BILLING_ADD_CARD_FETCH_REQUEST',
  MANAGER_BILLING_ADD_CARD_FETCH_SUCCESS:
    'MANAGER_BILLING_ADD_CARD_FETCH_SUCCESS',
  MANAGER_BILLING_ADD_CARD_FETCH_ERROR: 'MANAGER_BILLING_ADD_CARD_FETCH_ERROR',

  MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_REQUEST:
    'MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_REQUEST',
  MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_SUCCESS:
    'MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_SUCCESS',
  MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_ERROR:
    'MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_ERROR',

  MANAGER_BILLING_LOAD_INFO_FETCH_REQUEST:
    'MANAGER_BILLING_LOAD_INFO_FETCH_REQUEST',
  MANAGER_BILLING_LOAD_INFO_FETCH_SUCCESS:
    'MANAGER_BILLING_LOAD_INFO_FETCH_SUCCESS',
  MANAGER_BILLING_LOAD_INFO_FETCH_ERROR:
    'MANAGER_BILLING_LOAD_INFO_FETCH_ERROR',

  MANAGER_BILLING_GET_PLANS_MODEL_FETCH_REQUEST:
    'MANAGER_BILLING_GET_PLANS_MODEL_FETCH_REQUEST',
  MANAGER_BILLING_GET_PLANS_MODEL_FETCH_SUCCESS:
    'MANAGER_BILLING_GET_PLANS_MODEL_FETCH_SUCCESS',
  MANAGER_BILLING_GET_PLANS_MODEL_FETCH_ERROR:
    'MANAGER_BILLING_GET_PLANS_MODEL_FETCH_ERROR',

  MANAGER_BILLING_UPDATE_CONFIG_FETCH_REQUEST:
    'MANAGER_BILLING_UPDATE_CONFIG_FETCH_REQUEST',
  MANAGER_BILLING_UPDATE_CONFIG_FETCH_SUCCESS:
    'MANAGER_BILLING_UPDATE_CONFIG_FETCH_SUCCESS',
  MANAGER_BILLING_UPDATE_CONFIG_FETCH_ERROR:
    'MANAGER_BILLING_UPDATE_CONFIG_FETCH_ERROR',

  MANAGER_BILLING_UPDATE_DATA_FETCH_REQUEST:
    'MANAGER_BILLING_UPDATE_DATA_FETCH_REQUEST',
  MANAGER_BILLING_UPDATE_DATA_FETCH_SUCCESS:
    'MANAGER_BILLING_UPDATE_DATA_FETCH_SUCCESS',
  MANAGER_BILLING_UPDATE_DATA_FETCH_ERROR:
    'MANAGER_BILLING_UPDATE_DATA_FETCH_ERROR',

  MANAGER_BILLING_PAY_NOW_REQUEST:
    'MANAGER_BILLING_PAY_NOW_REQUEST',
  MANAGER_BILLING_PAY_NOW_SUCCESS:
    'MANAGER_BILLING_PAY_NOW_SUCCESS',
  MANAGER_BILLING_PAY_NOW_ERROR:
    'MANAGER_BILLING_PAY_NOW_ERROR',

  MANAGER_BILLING_SET_USER_COUNT: 'MANAGER_SET_USER_COUNT',
  MANAGER_BILLING_RESET_STATE: 'MANAGER_BILLING_RESET_STATE',
  MANAGER_BILLING_SET_SECTION_DRAWER: 'MANAGER_BILLING_SET_SECTION_DRAWER',
  MANAGER_BILLING_SET_TAB_ACTIVE: 'MANAGER_BILLING_SET_TAB_ACTIVE',
  MANAGER_BILLING_SET_ENABLE_SAVE: 'MANAGER_BILLING_SET_ENABLE_SAVE',

  MANAGER_BILLING_GET_CLIENT_BY_ID_FETCH_REQUEST:
    'MANAGER_BILLING_GET_CLIENT_BY_ID_FETCH_REQUEST',
  MANAGER_BILLING_GET_CLIENT_BY_ID_FETCH_SUCCESS:
    'MANAGER_BILLING_GET_CLIENT_BY_ID_FETCH_SUCCESS',
  MANAGER_BILLING_GET_CLIENT_BY_ID_FETCH_ERROR:
    'MANAGER_BILLING_GET_CLIENT_BY_ID_FETCH_ERROR'
};
