import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as actions from './action';
import selectors from './selector';

const NoPermissionDialog = props => {
  const { dialogOpen, setDialogState } = props;

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogState(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Ausência de permissão'}
        </DialogTitle>
        <DialogContent style={{ display: 'flex' }}>
          <div>⚠</div>
          <div style={{ paddingLeft: '7px' }}>
            ️Você não possui a permissão necessária para acessar esta página ou
            para executar esta ação.
            <br />
            <br />
            Caso julgue ser necessário ter acesso a elas, entre em contato com
            seu coordenador ou administrador.
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDialogState(false)}
            color="primary"
            autoFocus
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  dialogOpen: selectors.getIsDialogOpen(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDialogState: actions.setDialogState
    },
    dispatch
  );

NoPermissionDialog.defaultProps = {
  dialogOpen: false
  // closeDialog: () => {}
};

NoPermissionDialog.propTypes = {
  dialogOpen: PropTypes.bool
  // closeDialog: PropTypes.func
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NoPermissionDialog);
