import types from '@App/constants/config';

const INITIAL_STATE = ({
  route: {
    name: '',
    nameDrawer: ''
  },
  business: {
    id: 0
  },
  menu: {
    done: false,
    dataSource: []
  }
});

const ConfigReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ROUTE_CHANGE: {
      const {name, nameDrawer} = action.payload;
      return ({
        ...state,
        route: {
          ...state.route,
          name,
          nameDrawer
        }
      });
    }
    case types.CONFIG_MENU_SET_UPDATE: {
      const {done, dataSource} = action.payload;
      return ({
        ...state,
        menu: {
          ...state.menu,
          done,
          dataSource
        }
      });
    }
    case types.CONFIG_MENU_TOGGLE_DONE: {
      const {done} = action.payload;
      return ({
        ...state,
        menu: {
          ...state.menu,
          done: done
        }
      });
    }
    default:
      return state;
  }
};

export default ConfigReducer;