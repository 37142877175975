export default ({

  PURCHASE_IS_FIRST_LOADING                    : 'PURCHASE_IS_FIRST_LOADING',

  CHECK_CEP_INFO_FETCH_REQUEST                 : 'CHECK_CEP_INFO_FETCH_REQUEST',
  CHECK_CEP_INFO_FETCH_SUCCESS                 : 'CHECK_CEP_INFO_FETCH_SUCCESS',
  CHECK_CEP_INFO_FETCH_ERROR                   : 'CHECK_CEP_INFO_FETCH_ERROR',

  PURCHASE_GET_PLANS_MODEL_FETCH_REQUEST        : 'PURCHASE_GET_PLANS_MODEL_FETCH_REQUEST',
  PURCHASE_GET_PLANS_MODEL_FETCH_SUCCESS        : 'PURCHASE_GET_PLANS_MODEL_FETCH_SUCCESS',
  PURCHASE_GET_PLANS_MODEL_FETCH_ERROR        : 'PURCHASE_GET_PLANS_MODEL_FETCH_ERROR',

  PURCHASE_GET_STATES_LIST_FETCH_REQUEST        : 'PURCHASE_GET_STATES_LIST_FETCH_REQUEST',
  PURCHASE_GET_STATES_LIST_FETCH_SUCCESS        : 'PURCHASE_GET_STATES_LIST_FETCH_SUCCESS',
  PURCHASE_GET_STATES_LIST_FETCH_ERROR          : 'PURCHASE_GET_STATES_LIST_FETCH_ERROR',

  PURCHASE_GET_USER_DASHBOARD_FETCH_REQUEST    : 'PURCHASE_GET_USER_DASHBOARD_FETCH_REQUEST',
  PURCHASE_GET_USER_DASHBOARD_FETCH_SUCCESS    : 'PURCHASE_GET_USER_DASHBOARD_FETCH_SUCCESS',
  PURCHASE_GET_USER_DASHBOARD_FETCH_ERROR      : 'PURCHASE_GET_USER_DASHBOARD_FETCH_ERROR',

  PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_REQUEST    : 'PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_REQUEST',
  PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_SUCCESS    : 'PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_SUCCESS',
  PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_ERROR      : 'PURCHASE_GET_USER_PURCHASE_REQUEST_FETCH_ERROR',

  PURCHASE_SET_PACKAGE_DATA   : 'PURCHASE_SET_PACKAGE_DATA',
  PURCHASE_SET_BILLING_DATA   : 'PURCHASE_SET_BILLING_DATA',
  PURCHASE_SET_PAYMENT_METHOD : 'PURCHASE_SET_PAYMENT_METHOD',
  PURCHASE_SET_BUSINESS_DATA  : 'PURCHASE_SET_BUSINESS_DATA',
  PURCHASE_SET_RESUME_DATA    : 'PURCHASE_SET_RESUME_DATA',

  PURCHASE_NEXT_STEP : 'PURCHASE_NEXT_STEP',
  PURCHASE_PREVIOUS_STEP : 'PURCHASE_PREVIOUS_STEP',

  PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_REQUEST : 'PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_REQUEST',
  PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_SUCCESS : 'PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_SUCCESS',
  PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_ERROR   : 'PURCHASE_UPDATE_PACKAGE_FORMAT_FETCH_ERROR',

  PURCHASE_UPDATE_BILLING_DATA_FETCH_REQUEST : 'PURCHASE_UPDATE_BILLING_DATA_FETCH_REQUEST',
  PURCHASE_UPDATE_BILLING_DATA_FETCH_SUCCESS : 'PURCHASE_UPDATE_BILLING_DATA_FETCH_SUCCESS',
  PURCHASE_UPDATE_BILLING_DATA_FETCH_ERROR   : 'PURCHASE_UPDATE_BILLING_DATA_FETCH_ERROR  ',

  PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_REQUEST : 'PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_REQUEST',
  PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_SUCCESS : 'PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_SUCCESS',
  PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_ERROR : 'PURCHASE_UPDATE_PAYMENT_METHOD_FETCH_ERROR',

  PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_REQUEST : 'PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_REQUEST',
  PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_SUCCESS : 'PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_SUCCESS',
  PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_ERROR   : 'PURCHASE_UPDATE_BUSINNES_INFORMATION_FETCH_ERROR',

  PURCHASE_REQUEST_CONFIRM_FETCH_REQUEST : 'PURCHASE_REQUEST_CONFIRM_FETCH_REQUEST',
  PURCHASE_REQUEST_CONFIRM_FETCH_SUCCESS : 'PURCHASE_REQUEST_CONFIRM_FETCH_SUCCESS',
  PURCHASE_REQUEST_CONFIRM_FETCH_ERROR   : 'PURCHASE_REQUEST_CONFIRM_FETCH_ERROR'
});