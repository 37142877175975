export default ({
  SECTION                                              : 'overview',

  STARTED_OVERVIEW_IS_FIRST_LOADING                    : 'STARTED_OVERVIEW_IS_FIRST_LOADING',

  STARTED_OVERVIEW_MODAL_OPENED                        : 'STARTED_OVERVIEW_MODAL_OPENED',
  STARTED_OVERVIEW_MODAL_CLOSED                        : 'STARTED_OVERVIEW_MODAL_CLOSED',

  STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_REQUEST    : 'STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_REQUEST',
  STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_SUCCESS    : 'STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_SUCCESS',
  STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_ERROR      : 'STARTED_OVERVIEW_GET_USER_DASHBOARD_FETCH_ERROR',

  STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_REQUEST        : 'STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_REQUEST',
  STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_SUCCESS        : 'STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_SUCCESS',
  STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_ERROR          : 'STARTED_OVERVIEW_CREATE_ACCOUNT_FETCH_ERROR',

  STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_REQUEST : 'STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_REQUEST',
  STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_SUCCESS : 'STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_SUCCESS',
  STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_ERROR   : 'STARTED_OVERVIEW_SET_SELECTED_BUSINESS_FETCH_ERROR',

  STARTED_OVERVIEW_SET_SELECTED_BUSINESS               : 'STARTED_OVERVIEW_SET_SELECTED_BUSINESS'
});