export default ({
  colors: {
    first: { //blue
      300: '#6295FB',
      500: '#286EFA',
      700: '#1E51B6'
    },
    second: { //purple
      300: '#BA68C8',
      500: '#9C27B0',
      700: '#7B1FA2'
    },
    third: { //orange
      300: '#FFB445',
      500: '#FF9800',
      700: '#BA6F00'
    }
  }
});