import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

import * as actionsConfig from '@App/actions/config';
import * as actionLogin from '@Components/login/signIn/action';
import * as actionStarted from '@Components/started/action';
import * as util from '@Util/';
import systemConfigs from '@Constants/systemConfigs';

export default function protectedRouters(Component) {
  class AuthenticatedComponent extends React.Component {
    componentWillMount() {
      const {
        authenticated,
        checkedLocalStorage,
        setSignInAuthenticated,
        setAuthPermission,
        setSignInLogout,
        setAuth,
        routeRedirect,
        setSelectedBusiness,
      } = this.props;

      const auth = JSON.parse(util.getCookie('auth') || '{}');
      if (auth.hasOwnProperty('token')) {
        const business = auth.hasOwnProperty('business')
          ? auth.business
          : { id: 0, name: '' };
        auth.business = business;
        setSignInAuthenticated(auth);
        setAuth(auth);
        setAuthPermission({
          permissions: auth.permissions ? auth.permissions : null,
        });
        setSelectedBusiness({ ...auth.business });
      } else {
        setAuth({});
      // window.location = systemConfigs.AUTH_DOMAIN;
        actionsConfig.routeRedirect('/')
      }
    }

    render() {
      return (
        <Fragment>
          <Component {...this.props} />
        </Fragment>
      );
    }

    componentWillReceiveProps(nextProps) {
      const { verifyToken } = this.props;
      verifyToken(nextProps.token);
    }
  }

  const mapStateToProps = (state) => ({
    authenticated: state.login.signIn.authenticated,
    token: state.login.signIn.api.signIn.token,
    checkedLocalStorage: state.login.signIn.checkedLocalStorage,
  });

  const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
      {
        routeRedirect: actionsConfig.routeRedirect,
        setSignInAuthenticated: actionLogin.setSignInAuthenticated,
        setAuthPermission: actionLogin.setAuthPermission,
        setAuth: actionLogin.setAuth,
        setSelectedBusiness: actionStarted.setSelectedBusiness,
        verifyToken: actionLogin.verifyToken,
      },
      dispatch
    );

  AuthenticatedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthenticatedComponent);

  return withRouter(AuthenticatedComponent);
}
