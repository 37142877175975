import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const AUTHOR = 'author'; 
const MANAGER = 'manager';
const DOCUMENST = 'documents';

//#region GET
export const getAuthors = async ({ header }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${AUTHOR}/${DOCUMENST}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

