// import bglogin        from '@Assets/images/bg-login@600.png';
// import bglogin992     from '@Assets/images/bg-login@992.png';
import bgLogin1280 from '@Assets/images/bg-login@1280.png';

export const styles = theme => ({
  root: {
    width: '100%',
    margin: 0,
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center bottom',
    // backgroundSize: 'cover',
    backgroundImage: `url(${bgLogin1280})`,
    // [theme.breakpoints.up('md')]: {
    //   backgroundImage: `url(${bglogin992})`,
    // },
    // [theme.breakpoints.up('lg')]: {
    //   backgroundImage: `url(${bgLogin1280})`,
    // },
  },
  item: {
    padding: '0 !important'
  },
  contentForms: {
    height: '100vh',
    display: 'flex',
    flex: '1 100%',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    },
    '&$contentFormsMessage': {
      padding: '1em',
      flexDirection: 'column'
    }
  },
  control: {
    padding: '2em 1.5em',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    boxShadow: 'none !important',
    position: 'relative',
    top: '-5em',
    [theme.breakpoints.down('sm')]: {
      top: 0
    },
    '&$controlMessage': {
      backgroundColor: '#006efe',
    }
  },
  toolBarImage: {
    width: 'auto',
    height: '5.8em'
  },
  containerHeader: {
    marginTop: '1em',
    marginBottom: '3em'
  },
  headerFormActive: {
    fontSize: '17px',
    color: '#888'
  },
  container: {
    padding: '1em'
  },
  actions: {
    marginTop: '2em',
    padding: '1em'
  },
  rootSteps: {
    maxWidth: 600
  },
  flex: {
    flex: 1
  },
  formControl: {
    width: '100%',
  },
  textField: {
    marginTop: '1rem',
    display: 'inline-flex',
    flex: 1
  },
  marginBottom10: {
    marginBottom: '15px'
  },
  alignLeft: {
    textAlign: 'left'
  },
  alignRight: {
    textAlign: 'right'
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  iconLoading: {
    display: 'inline',
    position: 'relative',
    top: -1,
    marginLeft: 5,
  },
  buttonRegisterlabel: {
    fontSize: 14,
    textTransform: 'none',
    textDecoration: 'underline',
    color: '#888',
    '&:hover': {
      color: '#286efa',
      cursor: 'pointer'
    }
  },
  buttonStepRoot: {
    borderRadius: 5,
    '&$disabledPrimary': {
      color: '#FFF',
      backgroundColor: '#3B7BFA',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      '&:hover': { backgroundColor: '#4F88FA' },
      '&:focus': { backgroundColor: '#4F88FA' },
      '&:active': { backgroundColor: '#4F88FA' },
    },
    '&$disabledSecondary': {
      color: '#FFF',
      backgroundColor: '#1ABC9C',
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      '&:hover': { backgroundColor: '#18AB8E' },
      '&:focus': { backgroundColor: '#18AB8E' },
      '&:active': { backgroundColor: '#18AB8E' },
    },
  },
  buttonStepContainedPrimary: {
    backgroundColor: '#286efa',
    boxShadow: 'none',
    '&:hover': { backgroundColor: '#2564E4' },
    '&:focus': { backgroundColor: '#2564E4' },
    '&:active': { backgroundColor: '#2564E4' },
  },
  buttonStepContainedSecondary: {
    backgroundColor: '#18AB8E',
    '&:hover': { backgroundColor: '#169A80' },
    '&:focus': { backgroundColor: '#169A80' },
    '&:active': { backgroundColor: '#169A80' },
  },
  disabledPrimary: {},
  disabledSecondary: {},
  controlMessage: {},
  contentFormsMessage: {}
});