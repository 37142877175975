export default ({
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_REQUEST_CREATE : 'modal:perfil:configuration:medicalRecord:request:create',
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_REQUEST_UPDATE : 'modal:perfil:configuration:medicalRecord:request:update',
  SET_REQUEST_NAME: 'SET_REQUEST_NAME',
  SET_REQUEST_DESCRIPTION: 'SET_REQUEST_DESCRIPTION',
  LIST_REQUESTS_FETCH_REQUEST: 'LIST_REQUESTS_FETCH_REQUEST',
  LIST_REQUESTS_FETCH_ERROR: 'LIST_REQUESTS_FETCH_ERROR',
  LIST_REQUESTS_FETCH_SUCCESS: 'LIST_REQUESTS_FETCH_SUCCESS',
  CREATE_REQUEST_FETCH_REQUEST: 'CREATE_REQUEST_FETCH_REQUEST',
  CREATE_REQUEST_FETCH_ERROR: 'CREATE_REQUEST_FETCH_ERROR',
  CREATE_REQUEST_FETCH_SUCCESS: 'CREATE_REQUEST_FETCH_SUCCESS',
  UPDATE_REQUEST_FETCH_REQUEST: 'UPDATE_REQUEST_FETCH_REQUEST',
  UPDATE_REQUEST_FETCH_ERROR: 'UPDATE_REQUEST_FETCH_ERROR',
  UPDATE_REQUEST_FETCH_SUCCESS: 'UPDATE_REQUEST_FETCH_SUCCESS',
  GET_REQUEST_FETCH_REQUEST: 'GET_REQUEST_FETCH_REQUEST',
  GET_REQUEST_FETCH_ERROR: 'GET_REQUEST_FETCH_ERROR',
  GET_REQUEST_FETCH_SUCCESS: 'GET_REQUEST_FETCH_SUCCESS',
  PUSH_NEW_REQUEST_TO_LIST: 'PUSH_NEW_REQUEST_TO_LIST',
  RESET_REQUEST_FORM: 'RESET_REQUEST_FORM',
  UPDATE_ITEM_ON_LIST: 'UPDATE_ITEM_ON_LIST'
});