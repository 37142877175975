export default {
  SECTION: 'update',

  USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_REQUEST:
    'USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_REQUEST',
  USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_SUCCESS:
    'USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_SUCCESS',
  USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_ERROR:
    'USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_ERROR',

  USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_REQUEST:
    'USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_REQUEST',
  USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_SUCCESS:
    'USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_SUCCESS',
  USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_ERROR:
    'USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_ERROR',

  USER_UPDATE_MODAL_PROFESSIONAL_VINCULO:
    'user:update:modal:professional:vinculo',
  USER_UPDATE_FORM_HEADER_ENABLE_USER_SYSTEM:
    'user:update:form:header:enable:user:system',
  USER_UPDATE_FORM_PROFESSIONAL_VINCULO:
    'user:update:form:professional:vinculo',
  USER_UPDATE_FORM_USER_PERFIL: 'user:update:form:user:perfil',
  USER_UPDATE_FORM_USER_PERFIL_ADDRESS: 'user:update:form:user:perfil:address',
  USER_UPDATE_FORM_USER_PERFIL_PROFESSIONAL:
    'user:update:form:user:perfil:professional',
  USER_UPDATE_FORM_USER_GERAL: 'user:update:form:user:geral',
  USER_UPDATE_FORM_USER_GERAL_ADDRESS: 'user:update:form:user:geral:address',
  USER_UPDATE_FORM_USER_REGISTRATIONDATA_PROFESSIONAL:
    'user:update:form:user:registrationdata:dados:professional',
  USER_UPDATE_FORM_USER_REGISTRATIONDATA_DADOS_CADASTRAIS:
    'user:update:form:user:registrationdata:dados:cadastrais',
  USER_UPDATE_FORM_USER_REGISTRATIONDATA_ADRESS:
    'user:update:form:user:registrationdata:dados:address',
  USER_UPDATE_FORM_USER_PERMISSION_ACCOUNT:
    'user:update:form:user:permission:account',
  USER_UPDATE_FORM_USER_PERMISSION_WORKSPACE:
    'user:update:form:user:permission:workspace',

  USER_UPDATE_IS_FIRST_LOADING: 'USER_UPDATE_IS_FIRST_LOADING',

  USER_UPDATE_GET_STATE_CITY: 'USER_UPDATE_GET_STATE_CITY',

  USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_REQUEST:
    'USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_REQUEST',
  USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_SUCCESS:
    'USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_SUCCESS',
  USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_ERROR:
    'USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_ERROR',

  USER_UPDATE_GET_WORKSPACES_FETCH_REQUEST:
    'USER_UPDATE_GET_WORKSPACES_FETCH_REQUEST',
  USER_UPDATE_GET_WORKSPACES_FETCH_SUCCESS:
    'USER_UPDATE_GET_WORKSPACES_FETCH_SUCCESS',
  USER_UPDATE_GET_WORKSPACES_FETCH_ERROR:
    'USER_UPDATE_GET_WORKSPACES_FETCH_ERROR',

  USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_REQUEST:
    'USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_REQUEST',
  USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_SUCCESS:
    'USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_SUCCESS',
  USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_ERROR:
    'USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_ERROR',

  USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_REQUEST:
    'USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_REQUEST',
  USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_SUCCESS:
    'USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_SUCCESS',
  USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_ERROR:
    'USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_ERROR',

  USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_REQUEST:
    'USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_REQUEST',
  USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_SUCCESS:
    'USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_SUCCESS',
  USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_ERROR:
    'USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_ERROR',

  USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_REQUEST:
    'USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_REQUEST',
  USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_SUCCESS:
    'USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_SUCCESS',
  USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_ERROR:
    'USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_ERROR',

  USER_UPDATE_SET_ACCOUNT: 'USER_UPDATE_SET_ACCOUNT',
  USER_UPDATE_SET_USER_VIEWED: 'USER_UPDATE_SET_USER_VIEWED',

  USER_UPDATE_SET_ALL_PERFIL: 'USER_UPDATE_SET_ALL_PERFIL',
  USER_UPDATE_SET_ONLY_PERFIL_WORK: 'USER_UPDATE_SET_ONLY_PERFIL_WORK'
};
