export default ({
  INVITE_OPEN_IS_FIRST_LOADING  : 'INVITE_OPEN_IS_FIRST_LOADING',

  INVITE_OPEN_FETCH_REQUEST     : 'INVITE_OPEN_FETCH_REQUEST',
  INVITE_OPEN_FETCH_SUCCESS     : 'INVITE_OPEN_FETCH_SUCCESS',
  INVITE_OPEN_FETCH_ERROR       : 'INVITE_OPEN_FETCH_ERROR',

  INVITE_CONFIRM_FETCH_REQUEST     : 'INVITE_CONFIRM_FETCH_REQUEST',
  INVITE_CONFIRM_FETCH_SUCCESS     : 'INVITE_CONFIRM_FETCH_SUCCESS',
  INVITE_CONFIRM_FETCH_ERROR       : 'INVITE_CONFIRM_FETCH_ERROR'
});