export const SET_ON_CLOSE = (state) => {
    return ({
        ...state,
        drawer: {
            ...state.drawer,
            isOpen: false
        }
    });
};

export const SET_ON_OPEN = (state) => {
    return ({
        ...state,
        drawer: {
            ...state.drawer,
            isOpen: true
        }
    });
};

export const SET_TOGGLE_DRAWER = (state) => {
    return ({
        ...state,
        drawer: {
            ...state.drawer,
            isOpen: !state.drawer.isOpen
        }
    });
};

export const SET_DOCUMENT_SHARED_UPDATE_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            document: action.payload
        }
    });
};

export const SET_DOCUMENT_SHARED_UPDATE_LOADING = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            isLoadindShared: action.payload
        }
    });
};

export const ADD_PAGE_DOCUMENT = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            pages: {
                ...state.api.pages,
                ...action.payload
            }
        }
    });
};

export const DELETE_DOCUMENT_BY_ID = (state, action) => {
    const drafts = state.api.draftsDocuments.filter(item => item.uuid !== action.payload);
    const documents = state.api.documentsList.filter(item => item.uuid !== action.payload);
    return ({
        ...state,
        api: {
            ...state.api,
            documentsList: documents,
            draftsDocuments: drafts,
        }
    });
};

export const GET_SEARCH_PROFESSIONAL_FETCH_REQUEST_SIGNATURE = (state) => {
    return ({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetching:{
                    ...state.signature.api.fetching,
                    getProfessionals: true
                }
            }
        }
    });
}

export const GET_SEARCH_PROFESSIONAL_FETCH_SUCCESS_SIGNATURE = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                professionals: action.payload,
                fetching:{
                    ...state.signature.api.fetching,
                    getProfessionals: false
                }
            }
        }
    })
}

export const GET_SEARCH_PROFESSIONAL_FETCH_ERROR_SIGNATURE = (state) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetching:{
                    ...state.signature.api.fetching,
                    getProfessionals: false
                }
            }
        }
    })
}

export const ADD_SIGNATORY = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                signatories: {
                    ...state.signature.api.signatories,
                    [action.payload.idSignatory]: action.payload
                }
            }
        }
    })
}

export const ADD_ALL_SIGNATORIES = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                signatories: {
                    ...action.payload
                }
            }
        }
    })
}

export const SET_INITIAL_STATE_DOCUMENT_SIGNATURE = (state, action) => {
    return({
        ...state,
        api: {
            ...state.api,
            urlPdf: ''
        },
        header: {
            isEdit: false,
            type: 'header'
         },
        signature:{
            ...state.signature,
            api:{
                clients: [],
                professionals: [],
                signatories: {},
                signatoriesSend: [],
                signatureSend: {},
                fetching:{
                    getClients: false,
                    getProfessionals: false,
                    createSignature: false,
                }
            },
            pdfUrl:'',
            signatory: {},
            fetchingSignatory: false,
            fetchingSendSignature: false,
            isDocumentSigned:false,
        }
    })
}

export const DOWNLOAD_MODEL_DOCUMENT_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getModelDocument: action.payload
            }
        }
    };