export default {
  // User row actions
  INACTIVATE: 'inactivate',
  DELETE: 'delete',
  CREATE_USER: 'create_user',
  MODIFY_EMAIL: 'modify_email',
  RESEND: 'resend',
  // ---------------
  SECTION: 'overview',

  USER_OVERVIEW_SET_USERS_LIMIT: 'USER_OVERVIEW_SET_USERS_LIMIT',

  USER_OVERVIEW_DELETE_INVITATION_FETCH_REQUEST:
    'USER_OVERVIEW_DELETE_INVITATION_FETCH_REQUEST',
  USER_OVERVIEW_DELETE_INVITATION_FETCH_SUCCESS:
    'USER_OVERVIEW_DELETE_INVITATION_FETCH_SUCCESS',
  USER_OVERVIEW_DELETE_INVITATION_FETCH_ERROR:
    'USER_OVERVIEW_DELETE_INVITATION_FETCH_ERROR',

  USER_OVERVIEW_INVITE_CORRECTION_FETCH_REQUEST:
    'USER_OVERVIEW_INVITE_CORRECTION_FETCH_REQUEST',
  USER_OVERVIEW_INVITE_CORRECTION_FETCH_SUCCESS:
    'USER_OVERVIEW_INVITE_CORRECTION_FETCH_SUCCESS',
  USER_OVERVIEW_INVITE_CORRECTION_FETCH_ERROR:
    'USER_OVERVIEW_INVITE_CORRECTION_FETCH_ERROR',

  USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_REQUEST:
    'USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_REQUEST',
  USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_SUCCESS:
    'USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_SUCCESS',
  USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_ERROR:
    'USER_OVERVIEW_GET_USERS_ACCOUNT_FETCH_ERROR',

  USER_OVERVIEW_INACTIVATE_USER_FETCH_REQUEST:
    'USER_OVERVIEW_INACTIVATE_USER_FETCH_REQUEST',
  USER_OVERVIEW_INACTIVATE_USER_FETCH_SUCCESS:
    'USER_OVERVIEW_INACTIVATE_USER_FETCH_SUCCESS',
  USER_OVERVIEW_INACTIVATE_USER_FETCH_ERROR:
    'USER_OVERVIEW_INACTIVATE_USER_FETCH_ERROR',

  USER_OVERVIEW_RESEND_INVITATION_FETCH_REQUEST:
    'USER_OVERVIEW_RESEND_INVITATION_FETCH_REQUEST',
  USER_OVERVIEW_RESEND_INVITATION_FETCH_SUCCESS:
    'USER_OVERVIEW_RESEND_INVITATION_FETCH_SUCCESS',
  USER_OVERVIEW_RESEND_INVITATION_FETCH_ERROR:
    'USER_OVERVIEW_RESEND_INVITATION_FETCH_ERROR'
};
