export default ({
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_REPORT_CREATE : 'modal:perfil:configuration:medicalRecord:report:create',
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_REPORT_UPDATE : 'modal:perfil:configuration:medicalRecord:report:update',
  SET_REPORT_NAME: 'SET_REPORT_NAME',
  SET_REPORT_DESCRIPTION: 'SET_REPORT_DESCRIPTION',
  LIST_REPORTS_FETCH_REQUEST: 'LIST_REPORTS_FETCH_REQUEST',
  LIST_REPORTS_FETCH_ERROR: 'LIST_REPORTS_FETCH_ERROR',
  LIST_REPORTS_FETCH_SUCCESS: 'LIST_REPORTS_FETCH_SUCCESS',
  CREATE_REPORT_FETCH_REQUEST: 'CREATE_REPORT_FETCH_REQUEST',
  CREATE_REPORT_FETCH_ERROR: 'CREATE_REPORT_FETCH_ERROR',
  CREATE_REPORT_FETCH_SUCCESS: 'CREATE_REPORT_FETCH_SUCCESS',
  UPDATE_REPORT_FETCH_REQUEST: 'UPDATE_REPORT_FETCH_REQUEST',
  UPDATE_REPORT_FETCH_ERROR: 'UPDATE_REPORT_FETCH_ERROR',
  UPDATE_REPORT_FETCH_SUCCESS: 'UPDATE_REPORT_FETCH_SUCCESS',
  GET_REPORT_FETCH_REQUEST: 'GET_REPORT_FETCH_REQUEST',
  GET_REPORT_FETCH_ERROR: 'GET_REPORT_FETCH_ERROR',
  GET_REPORT_FETCH_SUCCESS: 'GET_REPORT_FETCH_SUCCESS',
  PUSH_NEW_REPORT_TO_LIST: 'PUSH_NEW_REPORT_TO_LIST',
  RESET_REPORT_FORM: 'RESET_REPORT_FORM',
  UPDATE_ITEM_ON_LIST: 'UPDATE_ITEM_ON_LIST'
});