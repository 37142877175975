/*eslint-disable no-throw-literal*/
import * as endpoints   from '@App/constants/endpoints';
import interceptor      from './interceptor';
import { put }        from './util';

const axios       = interceptor();
const ACCOUNT     = 'account';
const WORKSPACE   = 'workspace';
const USER        = 'user';
const INATIVE     = 'inactivate';
const ATIVE       = 'activate';
const INTEGRATION = 'integration';
const EMAIL       = 'email';
const RESEND      = 'resend';
const CONFIRM     = 'confirm';
const PERMISSION  = 'permission';
const PROFILE     = 'profile';

export const createAccount = async ({name, userId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}`;
  const params  = { name, userId };

  try {
    await axios.post(pathUrl, params);
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const createIntegration = async ({key}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INTEGRATION}`;
  const params  = { key };

  try {
    await axios.post(pathUrl, params);
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getUserPdfSettings = async (userId, workspaceId) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${userId}/workspace/${workspaceId}/settings`;
  
  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getPdfData = async (userId, workspaceId) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${userId}/workspace/${workspaceId}/template-info`;
  
  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getWorkspaces = async ({accountId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${WORKSPACE}s`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getGroupPemissionByAccountId = async ({ accountId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${GROUP}/${ACCOUNT}`;
  try {
    let response = await axios.get(pathUrl, {accountId});
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getAccountById = async ({businessId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${businessId}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const updateAccount = async ({header, params}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}`;
  const http = interceptor(header);
  try {
    let response = await put(http, pathUrl, params);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const updateUserByBusiness = async ({accountId, userId, accountProfileId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${PERMISSION}/${PROFILE}`;

  try {
    let response = await axios.put(pathUrl, {accountProfileId});
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getUserByIdAccount = async ({accountId, userId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const setInativeUserAccount = async ({accountId, userId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${INATIVE}`;

  try {
    let response = await axios.put(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const setAtiveUserAccount = async ({accountId, userId}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${ACCOUNT}/${accountId}/${USER}/${userId}/${ATIVE}`;

  try {
    let response = await axios.put(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const resendEmail = async (params) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${EMAIL}/${RESEND}/${CONFIRM}`;

  try {
    let response = await axios.post(pathUrl, params);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const setUserPdfSettings = async (userId, params) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${USER}/${userId}/settings`;

  try {
    let response = await axios.post(pathUrl, params);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};