import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { toastr } from 'react-redux-toastr';

import { styles } from './style';

import Header from './header';
import Form from './form';

import selector from '../selector';
import * as util from '@Util/';
import * as actions from './action';

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;

    this.props.verifyRecoverToken(params.token);
  }

  render() {
    const { classes } = this.props;
    // window.Intercom('shutdown');
    // window.Intercom('boot', {app_id: 'ipy1c31k'})
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.item}>
          <div className={classes.contentForms}>
            <Paper className={classes.control}>
              <Header classes={classes} />
              <Grid item>
                <span className={classes.headerFormActive}>
                  {'Criar nova senha'}
                </span>
              </Grid>
              <Grid item>
                <Form
                  classes={classes}
                  // validation={signInState.validation}
                  onSubmit={this.onSubmit}
                  disableButton={this.disableButton}
                />
              </Grid>
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  }

  disableButton = () => {
    const { recoverFormValues } = this.props;
    if (recoverFormValues) {
      const { newPassword1, newPassword2 } = recoverFormValues;
      if (!newPassword1 || !newPassword2 || newPassword1 !== newPassword2) {
        return true;
      }
      return false;
    }
    return true;
  };

  onSubmit(values) {
    const { updateUserPassword } = this.props;
    const token = window.location.pathname.replace('/recover/', '');
    const params = { newPassword: values.newPassword1, token };
    updateUserPassword(params);
  }
}

const mapStateToProps = state => ({
  recoverFormValues: util.generalSelector.getRecoverFormValues(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateUserPassword: actions.updateUserPassword,
      verifyRecoverToken: actions.verifyRecoverToken
      // setClearFormError         : actions.setClearFormError,
      // setSignInInvalidEmail     : actions.setSignInInvalidEmail,
      // setSignInInvalidPassword  : actions.setSignInInvalidPassword
    },
    dispatch
  );

SignIn = withStyles(styles)(SignIn);
SignIn = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);

export default SignIn;
