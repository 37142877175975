import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

class Form extends Component {

  render() {
    const { isLoading, /* validation ,*/ classes, onSubmit, handleSubmit, disableButton } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Field
            type="password"
            name="newPassword1"
            placeholder="Senha"
            label='Nova senha *'
            className={classes.textField}
            InputProps={{ classes: { underline: classes.underline, input: classes.input } }}
            InputLabelProps={{ shrink: true }}
            component={this.renderField}
          />
        </div>

        <div>
          <Field
            type="password"
            name="newPassword2"
            placeholder="Confirmar senha"
            label='Confirmar senha *'
            className={classes.textField}
            InputProps={{ classes: { underline: classes.underline, input: classes.input } }}
            InputLabelProps={{ shrink: true }}
            component={this.renderField}
          />
        </div>

        <Grid container direction={'column'}>
          <Grid item>
            <Fab
              type={'submit'}
              variant={'extended'}
              color={'primary'}
              className={classes.buttonSubmit}
              classes={{ root: classes.raisedPrimaryOverrideButton }}
              disabled={disableButton()}
            >
              {isLoading ? 'Aguarde...' : 'Alterar Senha'}
            </Fab>
          </Grid>
        </Grid>

      </form>
    );
  }

  renderField = ({ input, meta: { touched, error }, ...custom }) => {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField {...input} {...custom} variant="outlined" />
        {touched && ((error && <FormHelperText className={classes.errorText}> {error}</FormHelperText>))}
      </Fragment>
    );
  }

  redirectRoute = () => {
    this.props.history.push('/');
  }

};

const validate = values => {
  const errors = {};
  const { newPassword1, newPassword2 } = values;
  if (newPassword1) {
    if (newPassword1.length < 6) errors.newPassword1 = "Senha inválida, mínimo 6 caracteres";
  } else {
    errors.newPassword1 = "Este Campo é obrigatório";
  }
  if (newPassword2) {
    if (newPassword1 && (newPassword1 !== newPassword2)) errors.newPassword2 = "As senhas são diferentes. Verifique o valor inserido";
  } else {
    errors.newPassword2 = "Este Campo é obrigatório";
  }
  return errors;
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  // isLoading : PropTypes.bool.isRequired,
  // validation   : PropTypes.shape({
  //   email         : PropTypes.bool.isRequired,
  //   emailFormat   : PropTypes.bool.isRequired,
  //   password      : PropTypes.bool.isRequired,
  //   passwordFormat: PropTypes.bool.isRequired
  // }).isRequired
};

export default reduxForm({ form: 'NewPassword', validate })(withRouter(Form));
