import map from 'lodash/map';
import compact from 'lodash/compact';


export const SET_TOGGLE_SIDEBAR = (state) => {
    return ({
        ...state,
        sidebar: {
            ...state.sidebar,
            isOpen: !state.sidebar.isOpen
        }
    });
};
export const SET_TOGGLE_EDIT_HEADER = (state, action) => {
    return ({
        ...state,
        header: {
            ...action.payload
        }
    });
};
export const SET_UPDATE_DOCUMENT = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModel: { ...action.payload }
        }
    });
};

export const SET_DOC_MODEL_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModel: action.payload
        }
    });
};

export const SET_PAGE_DOC_MODEL_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            pageDoc: action.payload
        }
    });
};

export const SET_DOC_MESSAGE_SAVE = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            modelSave: {
                message: action.payload.message,
                status: action.payload.status
            }
        }
    });
};



export const SET_TITLE_PAGE_HISTORY = (state, action) => {
    return ({
        ...state,
        historyPage: [
            action.payload
        ]
    });
};
export const ADD_PAGES_DOCUMENT = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            pages: {
                ...state.api.pages,
                ...action.payload
            }
        }
    });
};
export const SET_PAGE_HISTORY = (state, action) => {
    return ({
        ...state,
        historyPage: action.payload
    });
};
export const SET_CHANGE_PAGE = (state, action) => {
    return ({
        ...state,
        changePage: action.payload
    });
};
export const SET_USER_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            userInfo: action.payload
        }
    });
};

export const SET_CREATE_DOC_MODEL_REQUEST = (state) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            createDocModel: true
        }
    });
};

export const SET_CREATE_DOC_MODEL_SUCCESS = (state) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            createDocModel: false
        }
    });
};

export const SET_UPDATE_DOC_MODEL_BY_ID = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModel: action.payload
        }
    });
};

export const SET_DOCS_MODELS_REQUEST = (state, action) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            listDocModel: true
        }
    });
};

export const SET_DOCS_MODELS_SUCCESS = (state, action) => {
    const { models, draftsModels } = action.payload;
    return ({
        ...state,
        api: {
            ...state.api,
            docModelList: models,
            draftsModels: draftsModels
        }
    });
};

export const SET_DOCS_MODELS_MANAGER_SUCCESS = (state, action) => {
    const { models } = action.payload;
    return ({
        ...state,
        api: {
            ...state.api,
            docModelManagerList: models
        }
    });
}

export const SET_AUTHORS_DOCUMENTS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            authors: action.payload
        }
    });
}

export const SET_DOCS_MODELS_FILTER = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModelListFilter: action.payload
        }
    });
};

export const SET_DOCS_MODELS_MANAGER_FILTER = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModelManagerFilter: action.payload
        }
    });
}

export const SET_GROUP_PERMISSION = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            groupPermission: action.payload
        }
    });
}

export const GET_MODEL_DOCUMENT_MANAGER_FETCH =
    (state, action) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getModelDocument: action.payload
            }
        }
    };

export const SET_FOLDER_SELECTED = (state, action) => {
    return ({
        ...state,
        folderSelected: action.payload
    });
};

export const DOCUMENT_GET_CLASSIFICATION_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CLASSIFICATION_FETCH_ERROR =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const SET_CLASSIFICATION_SUCCESS =
    (state, action) => {
        const { classification } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    classification
                }
            }
        }
    };
export const DOCUMENT_SAVE_CLASSIFICATION_FETCH =
    (state, action) => {
        const { status } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    fetching: {
                        ...state.api.classification.fetching,
                        saveClassification: status
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CLASSIFICATION_FETCH_SUCCESS =
    (state, action) => {
        const { type, classification } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    [type]: classification.classifications,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CLASSIFICATION_GROUP_FETCH_SUCCESS =
    (state, action) => {
        const { classificationsGroup } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classificationsGroup: {
                    ...classificationsGroup
                }
            }
        }
    };

export const SET_PROPERTY_FOLDER_DOC =
    (state, action) => {
        const { folderProperty } = action.payload;
        return {
            ...state,
            modal: {
                ...state.modal,
                folderProperty: {
                    ...state.modal.folderProperty,
                    ...folderProperty,
                }
            }
        }
    };

export const DOCUMENT_SAVE_FOLDER_FETCH =
    (state, action) => {
        const { status } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    fetching: {
                        ...state.api.folder.fetching,
                        saveFolder: status
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_MODELS_FETCH_SUCCESS =
    (state, action) => {
        const { foldersModels } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersModels,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_MODELS_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_MODELS_FETCH_ERROR =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: false
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_ALL_FETCH_SUCCESS =
    (state, action) => {
        const { foldersAllDocuments } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersAllDocuments,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderDocuments: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_DOCUMENTS_FETCH_SUCCESS =
    (state, action) => {
        const { foldersDocuments } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersDocuments,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };

export const GET_DOCUMENTS_LIST_SUCCESS =
    (state, action) => {
        const { documentsList, draftsDocuments, documentsSignaturePending, documentsSignatureConcluded } = action.payload;
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocuments: false
            },
            api: {
                ...state.api,
                documentsList,
                draftsDocuments,
                documentsSignaturePending,
                documentsSignatureConcluded
            }
        }
    };
export const GET_DOCUMENTS_LIST_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocuments: true
            }
        }
    };
export const GET_DOCUMENTS_LIST_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocuments: false
            }
        }
    };

export const GET_DOCUMENT_FETCH_SUCCESS =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocument: false
            }
        }
    };

export const GET_DOCUMENT_FETCH_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocument: false
            }
        }
    };

export const GET_DOCUMENT_FETCH_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocument: true
            }
        }
    };

export const SET_DOCUMENT_BY_ID_SUCCESS_MANAGER = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            document: action.payload
        }
    });
};

export const SET_DOCUMENTS_FILTER = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            documentsListFilter: action.payload,

        }
    });
};

export const SET_SIDEBAR_BUTTON_SELECT = (state, action) => {
    return ({
        ...state,
        sidebar: {
            isOpen: state.sidebar.isOpen,
            ...action.payload
        }
    });
};

export const DOCUMENT_GET_FOLDERS_ALL_MODELS_FETCH_SUCCESS =
    (state, action) => {
        const { foldersAllModels } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersAllModels,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };

export const DOCUMENT_MOVE_FOLDER_FETCH_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                moveDocumentFolder: true
            }
        }
    };

export const DOCUMENT_MOVE_FOLDER_FETCH_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                moveDocumentFolder: false
            }
        }
    };


export const DOCUMENT_MOVE_FOLDER_FETCH_SUCCESS =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                moveDocumentFolder: false
            }
        }
    };

export const DOC_MODEL_FETCH_DELETE_SUCCESS =
    (state, action) => {
        const { docModelId } = action.payload;
        return {
            ...state,
            fetching: {
                ...state.fetching,
                deleteDocModel: false
            },
            api: {
                ...state.api,
                docModelList: compact(map(state.api.docModelList, listItem => {
                    if (listItem.uuid !== docModelId) return listItem
                })),
                draftsModels: compact(map(state.api.draftsModels, listItem => {
                    if (listItem.uuid !== docModelId) return listItem
                }
                ))
            }
        }
    }

export const GET_CLASSIFICATION_FETCH_SUCCESS =
    (state, action) => {
        const { type, classifications } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classificationsListType: {
                    ...state.api.classificationsListType,
                    [type]: classifications,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const GET_CLASSIFICATION_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classificationsListType: {
                    ...state.api.classificationsListType,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: true
                    }
                }
            }
        }
    };

export const GET_CLASSIFICATION_FETCH_ERROR =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classificationsListType: {
                    ...state.api.classificationsListType,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const SET_PATH_FOLDER = (state, action) => {
    return ({
        ...state,
        pathFolder: action.payload
    });
}

export const SET_DOC_TITLE = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docTitle: action.payload,
        }
    });
}

export const SET_TABLE_OF_CONTENTS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            tableOfContents: action.payload,
        }
    });
}

export const SET_STATE_BLOCK_FOCUS = (state, action) => {
    return ({
        ...state,
        stateBlockFocus: action.payload,
    });
}

export const DOCUMENT_GET_CATEGORIES_ALL_FETCH_SUCCESS =
    (state, action) => {
        const { categoriesAllDocuments } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                category: {
                    ...state.api.category,
                    categoriesAllDocuments,
                    fetching: {
                        ...state.api.category.fetching,
                        getCategories: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CATEGORIES_DOCUMENTS_FETCH_SUCCESS =
    (state, action) => {
        const { categoriesDocuments } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                category: {
                    ...state.api.category,
                    categoriesDocuments,
                    fetching: {
                        ...state.api.category.fetching,
                        getCategories: true
                    }
                }
            }
        }
    };