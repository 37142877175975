import map from 'lodash/map';

import initialState from './initialState';

export const MEDICALRECORD_PATIENT_RESET_STATE = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...initialState
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_MODAL_PRINT_ATTENDANCE = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      printIsOpenAttendance: action.payload
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_SECTIONS_DRAWER = (section, state, action) => {
  const { done } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        done
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_USER_INFO_DRAWER = (section, state, action) => {
  const { done } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      userInfo: {
        ...state[section].userInfo,
        done
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postAttendance: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        prescriptionDelta: true
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          prescriptionDelta: null
        }
      }
    }
  });
}
export const MEDICALRECORD_PATIENT_CHANGE_MODEL_FORM = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        changeModelForm: action.payload
      },
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        prescriptionDelta: false
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          prescriptionDelta: action.payload
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_DELTA_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        prescriptionDelta: false
      }
    }
  });
}


export const MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        certificateDelta: true
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          certificateDelta: null
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        certificateDelta: false
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          certificateDelta: action.payload
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_DELTA_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        certificateDelta: false
      }
    }
  });
}


export const MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        referralDelta: true
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          referralDelta: null
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        referralDelta: false
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          referralDelta: action.payload
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_REFERRAL_DELTA_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        referralDelta: false
      }
    }
  });
}


export const MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        requestDelta: true
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          requestDelta: null
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        requestDelta: false
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          requestDelta: action.payload
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_REQUEST_DELTA_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        requestDelta: false
      }
    }
  });
}


export const MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        reportDelta: true
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          reportDelta: null
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        reportDelta: false
      },
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          reportDelta: action.payload
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_LOAD_REPORT_DELTA_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        reportDelta: false
      }
    }
  });
}


export const MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postAttendance: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_POST_ATTENDANCE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postAttendance: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceById: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceById: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_BUILD_ATTENDANCE_BY_ID = (section, state, action) => {
  const {
    formModels,
    attendanceId,
    attendanceReleased,
    attendanceDate,
    sectionsModels,
    attendanceState,
    sectionExamsState,
    sectionMediaState,
    sectionReportState,
    listSectionsModels,
    sectionRequestState,
    sectionReferralState,
    sectionAttachmentState,
    sectionCertificateState,
    sectionPrescriptionState,
    isAnswerPatient,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        attendance: {
          ...state[section].api.attendance,
          ...attendanceState
        },
      },
      sections: {
        ...state[section].sections,
        attendanceId,
        attendanceDate,
        attendanceReleased,
        isAnswerPatient,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          ...sectionsModels
        },
        exam: {
          ...state[section].sections.exam,
          ...sectionExamsState
        },
        media: {
          ...state[section].sections.media,
          ...sectionMediaState
        },
        report: {
          ...state[section].sections.report,
          ...sectionReportState,
          comments: {
            ...state[section].sections.report.comments,
            ...sectionReportState.comments
          }
        },
        request: {
          ...state[section].sections.request,
          ...sectionRequestState,
          comments: {
            ...state[section].sections.request.comments,
            ...sectionRequestState.comments
          }
        },
        referral: {
          ...state[section].sections.referral,
          ...sectionReferralState,
          comments: {
            ...state[section].sections.referral.comments,
            ...sectionReferralState.comments
          }
        },
        attachment: {
          ...state[section].sections.attachment,
          ...sectionAttachmentState
        },
        certificate: {
          ...state[section].sections.certificate,
          ...sectionCertificateState,
          comments: {
            ...state[section].sections.certificate.comments,
            ...sectionCertificateState.comments
          }
        },
        prescription: {
          ...state[section].sections.prescription,
          ...sectionPrescriptionState,
          comments: {
            ...state[section].sections.prescription.comments,
            ...sectionPrescriptionState.comments
          }
        },
        listSectionsModels,
        formModels,
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_FORM_MODELS = (section, state, action) => {
  const {
    formModels,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        formModels
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCE_BY_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceById: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_TAB_SECTIONS = (section, state, action) => {
  const { activeKey, targetState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        activeKey,
        targetState
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListPrescriptionModel: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_SUCCESS = (section, state, action) => {
  const { listPrescriptionModel } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListPrescriptionModel: false
      },
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listModel: [...listPrescriptionModel]
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_PRESCRIPTION_MODEL_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListPrescriptionModel: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_PRESCRIPTION_MODEL_BY_ID_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_PRESCRIPTION_TEXTAREA = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_PRESCRIPTION_NEW_DESCRIPTION_CONTENT = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              btnSave: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, attendancePrescriptionId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              btnSave: false,
              attendancePrescriptionId
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_PRESCRIPTION_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              btnSave: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_SUCCESS = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_PRESCRIPTION_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          listSource: {
            ...state[section].sections.prescription.listSource,
            [targetKey]: {
              ...state[section].sections.prescription.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

//#region SECTION CERTIFICATE
export const MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListCertificateModel: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_SUCCESS = (section, state, action) => {
  const { listCertificateModel } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListCertificateModel: false
      },
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listModel: [...listCertificateModel]
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_CERTIFICATE_MODEL_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListCertificateModel: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_CERTIFICATE_MODEL_BY_ID_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_SUCCESS = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_CERTIFICATE_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              btnSave: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, attendanceCertificateId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              btnSave: false,
              attendanceCertificateId
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_CERTIFICATE_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              btnSave: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_CERTIFICATE_TEXTAREA = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listSource: {
            ...state[section].sections.certificate.listSource,
            [targetKey]: {
              ...state[section].sections.certificate.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_CERTIFICATE_NEW_DESCRIPTION_CONTENT = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    currentSectionName,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getTimeline: false
      },
      api: {
        ...state[section].api,
        mainTimeline: {
          ...state[section].api.mainTimeline,
          ...response
        },
      },
      mainTimelineData: {
        ...state[section].mainTimelineData,
        attendances: [
          ...state[section].mainTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].mainTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].mainTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        currentSectionName,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_LOAD_SECTION_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    listSections
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      listSections
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_LIST_CORE_LINES_FORM_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      listCoreLines: action.payload,
      fetching:{
        ...state[section].fetching,
        getListCoreLines: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_CORE_LINES_FORM = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        getListCoreLines: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_CORE_LINES_FORM_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        getListCoreLines: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_OPEN_CORE_LINE_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      listCoreLinesOpen: action.payload,
      fetching:{
        ...state[section].fetching,
        getListCoreLinesOpen: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      listCoreLinesByPatientId: action.payload,
      fetching:{
        ...state[section].fetching,
        getListCoreLinesByPatientId: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_REQUEST  = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        getListCoreLinesByPatientId: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_CORE_LINES_BY_PATIENT_ID_FORM_FETCH_ERROR  = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        getListCoreLinesByPatientId: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        updateCoreLineAttendance: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        updateCoreLineAttendance: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_UPDATE_OPEN_CORE_LINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        updateCoreLineAttendance: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        openCoreLineAttendance: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        openCoreLineAttendance: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_OPEN_CORE_LINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        openCoreLineAttendance: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        updateAttendanceCoreLine: true
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_SUCCESS = (section, state, action) => {
    const { coreLineAttendance } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        updateAttendanceCoreLine: false
      },
      api: {
        ...state[section].api,
        attendance:{
          ...state[section].api.attendance,
          coreLineAttendance
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_UPDATE_ATTENDANCE_CORE_LINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching:{
        ...state[section].fetching,
        updateAttendanceCoreLine: false
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_SECTION_FETCH_ERROR = (section, state, action) => {

  return ({
    ...state,
    [section]: {
      ...state[section],
      listSections: []
    }
  });
};


export const MEDICALRECORD_PATIENT_LOAD_MAIN_FILTER_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    currentSectionName,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getTimeline: false
      },
      api: {
        ...state[section].api,
        mainTimeline: {
          ...state[section].api.mainTimeline,
          ...response
        },
      },
      mainTimelineData: {
        ...state[section].mainTimelineData,
        attendances: medicalRecordsAttendances,
        medicalRecordsAccessGroups: {
          ...state[section].mainTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].mainTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        currentSectionName,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_MAIN_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getCertificateTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getCertificateTimeline: false
      },
      api: {
        ...state[section].api,
        certificateTimeline: {
          ...state[section].api.certificateTimeline,
          ...response
        },
      },
      certificateTimelineData: {
        ...state[section].certificateTimelineData,
        attendances: [
          ...state[section].certificateTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].certificateTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].certificateTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_CERTIFICATE_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getCertificateTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getPrescriptionTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getPrescriptionTimeline: false
      },
      api: {
        ...state[section].api,
        prescriptionTimeline: {
          ...state[section].api.prescriptionTimeline,
          ...response
        },
      },
      prescriptionTimelineData: {
        ...state[section].prescriptionTimelineData,
        attendances: [
          ...state[section].prescriptionTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].prescriptionTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].prescriptionTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_PRESCRIPTION_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getPrescriptionTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getReportTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getReportTimeline: false
      },
      api: {
        ...state[section].api,
        reportTimeline: {
          ...state[section].api.reportTimeline,
          ...response
        },
      },
      reportTimelineData: {
        ...state[section].reportTimelineData,
        attendances: [
          ...state[section].reportTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].reportTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].reportTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REPORT_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getReportTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getRequestTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getRequestTimeline: false
      },
      api: {
        ...state[section].api,
        requestTimeline: {
          ...state[section].api.requestTimeline,
          ...response
        },
      },
      requestTimelineData: {
        ...state[section].requestTimelineData,
        attendances: [
          ...state[section].requestTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].requestTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].requestTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REQUEST_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getRequestTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getReferralTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getReferralTimeline: false
      },
      api: {
        ...state[section].api,
        referralTimeline: {
          ...state[section].api.referralTimeline,
          ...response
        },
      },
      referralTimelineData: {
        ...state[section].referralTimelineData,
        attendances: [
          ...state[section].referralTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].referralTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].referralTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_REFERRAL_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getReferralTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSectionTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CREATE_SECTION_TIMELINE_DATA = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    sectionTimelineData: {
      ...state[section].sectionTimelineData,
      [action.payload]: {
        attendances: [],
        medicalRecordsAccessGroups: [],
        referencedUsers: [],
        lastLoadedPage: null,
        lessRecentAttendanceDateLoaded: null,
        timelineEnd: false
      }
    }
  }
})

export const MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded,
    releasedAttendancesUsers,
    targetState
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSectionTimeline: false
      },
      api: {
        ...state[section].api,
        sectionTimeline: {
          ...state[section].api.sectionTimeline,
          ...response
        },
      },
      sectionTimelineData: {
        ...state[section].sectionTimelineData,
        [targetState]: {
          ...state[section].sectionTimelineData[targetState],
          attendances: [
            ...state[section].sectionTimelineData[targetState].attendances,
            ...medicalRecordsAttendances
          ],
          medicalRecordsAccessGroups: {
            ...state[section].sectionTimelineData[targetState].medicalRecordsAccessGroups,
            ...returnedMedicalRecordsAccessGroups
          },
          referencedUsers: {
            ...state[section].sectionTimelineData[targetState].referencedUsers,
            ...returnedReferencedUsers
          },
          releasedAttendancesUsers: [
            ...releasedAttendancesUsers
          ],
          lastLoadedPage,
          lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
          timelineEnd: allAttendancesAreLoaded
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_SECTION_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSectionTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getMediaTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getMediaTimeline: false
      },
      api: {
        ...state[section].api,
        mediaTimeline: {
          ...state[section].api.mediaTimeline,
          ...response
        },
      },
      mediaTimelineData: {
        ...state[section].mediaTimelineData,
        attendances: [
          ...state[section].mediaTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].mediaTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].mediaTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_MEDIA_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getMediaTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttachmentTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lastLoadedPage,
    lessRecentAttendanceDateLoaded,
    response,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttachmentTimeline: false
      },
      api: {
        ...state[section].api,
        attachmentTimeline: {
          ...state[section].api.attachmentTimeline,
          ...response
        },
      },
      attachmentTimelineData: {
        ...state[section].attachmentTimelineData,
        attendances: [
          ...state[section].attachmentTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].attachmentTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].attachmentTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_ATTACHMENT_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttachmentTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getExamTimeline: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_SUCCESS = (section, state, action) => {
  const {
    returnedReferencedUsers,
    returnedMedicalRecordsAccessGroups,
    medicalRecordsAttendances,
    lessRecentAttendanceDateLoaded,
    response,
    lastLoadedPage,
    allAttendancesAreLoaded
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getExamTimeline: false
      },
      api: {
        ...state[section].api,
        examTimeline: {
          ...state[section].api.examTimeline,
          ...response
        },
      },
      examTimelineData: {
        ...state[section].examTimelineData,
        attendances: [
          ...state[section].examTimelineData.attendances,
          ...medicalRecordsAttendances
        ],
        medicalRecordsAccessGroups: {
          ...state[section].examTimelineData.medicalRecordsAccessGroups,
          ...returnedMedicalRecordsAccessGroups
        },
        referencedUsers: {
          ...state[section].examTimelineData.referencedUsers,
          ...returnedReferencedUsers
        },
        lastLoadedPage,
        lessRecentAttendanceDateLoaded: lessRecentAttendanceDateLoaded,
        timelineEnd: allAttendancesAreLoaded
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_EXAM_TIMELINE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getExamTimeline: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_MEDIA_IMAGE_DOWNLOAD_FETCH_SUCCESS = (section, state, action) => {
  const {
    base64url,
    targetState,
    fileReference,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetState]: {
        ...state[section][targetState],
        downloadedImages: {
          ...state[section][targetState].downloadedImages,
          [fileReference]: base64url
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_RELEASED_ATTENDANCES_PROFESSIONALS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      mainTimelineData: {
        ...state[section].mainTimelineData,
        releasedAttendancesUsers: [...action.payload]
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_NOT_RELEASED_ATTENDANCES_PROFESSIONALS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      mainTimelineData: {
        ...state[section].mainTimelineData,
        notReleasedAttendancesUsers: [...action.payload]
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_SUCCESS = (section, state, action) => {

  return ({
    ...state,
    [section]: {
      ...state[section],
      mainTimelineData: {
        ...state[section].mainTimelineData,
        requestedAttachments: { ...state[section].mainTimelineData.requestedAttachments, [action.payload]: undefined }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_REQUEST = (section, state, action) => {

  return ({
    ...state,
    [section]: {
      ...state[section],
      mainTimelineData: {
        ...state[section].mainTimelineData,
        requestedAttachments: { ...state[section].mainTimelineData.requestedAttachments, [action.payload]: true }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_MEDIA_ATTACHMENT_DOWNLOAD_FETCH_ERROR = (section, state, action) => {

  return ({
    ...state,
    [section]: {
      ...state[section],
      mainTimelineData: {
        ...state[section].mainTimelineData,
        requestedAttachments: { ...state[section].mainTimelineData.requestedAttachments, [action.payload]: undefined }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_VIDEO_URL_FETCH_REQUEST = (section, state, action) => {

  return ({
    ...state,
    [section]: {
      ...state[section],
      mainTimelineData: {
        ...state[section].mainTimelineData,
        videoUrl: null
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_LOAD_VIDEO_URL_FETCH_SUCCESS = (section, state, action) => {
  const {
    targetState,
    videoUrl,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      [targetState]: {
        ...state[section][targetState],
        videoUrl
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_PATIENT_ID = (section, state, action) => {
  const { patientId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      patientId
    }
  });
};
//#endregion

//#region SECTION REPORT
export const MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListReportModel: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_SUCCESS = (section, state, action) => {
  const { listReportModel } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListReportModel: false
      },
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listModel: [...listReportModel]
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_REPORT_MODEL_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListReportModel: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REPORT_MODEL_BY_ID_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_SUCCESS = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REPORT_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              btnSave: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, attendanceReportId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              btnSave: false,
              attendanceReportId
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REPORT_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              btnSave: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REPORT_TEXTAREA = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listSource: {
            ...state[section].sections.report.listSource,
            [targetKey]: {
              ...state[section].sections.report.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REPORT_NEW_DESCRIPTION_CONTENT = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          listKeys,
          listSource
        }
      }
    }
  });
};
//#endregion

//#region SECTION REQUEST
export const MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListRequestModel: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_SUCCESS = (section, state, action) => {
  const { listRequestModel } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListRequestModel: false
      },
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listModel: [...listRequestModel]
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_REQUEST_MODEL_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListRequestModel: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REQUEST_MODEL_BY_ID_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_SUCCESS = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REQUEST_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              btnSave: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, attendanceRequestId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              btnSave: false,
              attendanceRequestId
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REQUEST_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              btnSave: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REQUEST_TEXTAREA = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listSource: {
            ...state[section].sections.request.listSource,
            [targetKey]: {
              ...state[section].sections.request.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REQUEST_NEW_DESCRIPTION_CONTENT = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          listKeys,
          listSource
        }
      }
    }
  });
};
//#endregion

//#region SECTION REFERRAL
export const MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListReferralModel: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_SUCCESS = (section, state, action) => {
  const { listReferralModel } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListReferralModel: false
      },
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listModel: [...listReferralModel]
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_LIST_REFERRAL_MODEL_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getListReferralModel: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_REFERRAL_MODEL_BY_ID_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              loading: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_SUCCESS = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listKeys,
          listSource
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_REMOVE_REFERRAL_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              loading: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_REQUEST = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              btnSave: true,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_SUCCESS = (section, state, action) => {
  const { targetKey, attendanceReferralId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              btnSave: false,
              attendanceReferralId
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_REFERRAL_FETCH_ERROR = (section, state, action) => {
  const { targetKey } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              btnSave: false,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REFERRAL_TEXTAREA = (section, state, action) => {
  const { targetKey, content } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listSource: {
            ...state[section].sections.referral.listSource,
            [targetKey]: {
              ...state[section].sections.referral.listSource[targetKey],
              loading: false,
              content
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REFERRAL_NEW_DESCRIPTION_CONTENT = (section, state, action) => {
  const { listKeys, listSource } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          listKeys,
          listSource
        }
      }
    }
  });
};
//#endregion

export const MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_REQUEST = (section, state, action) => {
  const { targetState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            loading: true,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_SUCCESS = (section, state, action) => {
  const { targetState, modes, ...rest } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            loading: false,
            modes: {
              ...state[section].sections.sectionsModels[targetState].modes,
              ...modes
            },
            ...rest
          }
        }
      }
    }
  });
};

export const SELECT_NEW_FORM_SECTION = (section, state, action) => {
  const { targetState, selectNewForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            selectNewForm
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_UPDATE_FORM_ANSWER_AND_SECTION = (section, state, action) => {
  const { targetState, formsId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            formsId
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CREATE_FORM_ANSWER_AND_SECTION_FETCH_ERROR = (section, state, action) => {
  const { targetState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            loading: false,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ROW_CONTENT = (section, state, action) => {
  const { targetState, ...rest } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            loading: false,
            ...rest
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_DROP_MODEL_FORM = (section, state, action) => {
  const { targetState, dropModelForm } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            loading: false,
            dropModelForm
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_REQUEST = (section, state, action) => {
  const { targetState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            btnSaveLoading: true
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_SUCCESS = (section, state, action) => {
  const { targetState, ...rest } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            btnSaveLoading: false,
            ...rest
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_SECTION_FETCH_ERROR = (section, state, action) => {
  const { targetState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            btnSaveLoading: false
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveModalAttendanceRelease: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveModalAttendanceRelease: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_MODAL_ATTENDANCE_RELEASE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveModalAttendanceRelease: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getPatientById: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { patientState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getPatientById: false
      },
      userInfo: {
        ...state[section].userInfo,
        patient: {
          ...state[section].userInfo.patient,
          ...patientState
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_BY_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getPatientById: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSectionsMedicalRecordsConfig: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_SUCCESS = (section, state, action) => {
  const { sectionsModels } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSectionsMedicalRecordsConfig: false
      },
      sections: {
        ...state[section].sections,
        sectionsModelsConfig: sectionsModels
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_SECTIONS_MEDICAL_RECORDS_CONFIG_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getSectionsMedicalRecordsConfig: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        createAttendanceAnswerPatient: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_SUCCESS = (section, state, action) => {
  const { formSend } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      formSendCreated: {...formSend },
      formsSend:[
        ...state[section].formsSend,
        formSend,
      ],
      fetching: {
        ...state[section].fetching,
        createAttendanceAnswerPatient: false
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_SET_FORM_SEND_CREATED = (section, state, action) => {
  const { formSend } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      formSendCreated: {...formSend },
    }
  });
}

export const MEDICALRECORD_PATIENT_CREATE_ATTENDANCE_ANSWER_PATIENT_FETCH_ERROR =  (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        createAttendanceAnswerPatient: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceAnswerPatient: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_SUCCESS =  (section, state, action) => {
  const { attendancesAnswerPatient } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      formsSend: attendancesAnswerPatient,
      fetching: {
        ...state[section].fetching,
        getAttendanceAnswerPatient: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCE_ANSWER_PATIENT_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceAnswerPatient: false
      }
    }
  });
};


export const MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendancesByPatientId: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_SUCCESS = (section, state, action) => {
  const { summaryAttendanceState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendancesByPatientId: false
      },
      summaryAttendance: {
        ...state[section].summaryAttendance,
        ...summaryAttendanceState
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_SUMMARY_ATTENDANCE_BY_TABLE = (section, state, action) => {
  const { table } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      summaryAttendance: {
        ...state[section].summaryAttendance,
        table: [...table]
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ATTENDANCES_BY_PATIENT_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendancesByPatientId: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_MEDIA_FILES = (section, state, action) => {
  const mediaState = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          ...mediaState,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_TOGGLE_MEDIA_LIGHT_BOX = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          isOpen: !state[section].sections.media.isOpen,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_MEDIA_LIGHT_BOX_INDEX = (section, state, action) => {
  const { photoIndex } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          isOpen: true,
          photoIndex,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_MEDIA_VIDEO_BOX = (section, state, action) => {
  const { videoState } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          video: {
            ...state[section].sections.media.video,
            ...videoState,
          },
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_MEDIA_FILES_BY_ID = (section, state, action) => {
  const {
    targetState,
    file
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          listSource: {
            ...state[section].sections.media.listSource,
            [targetState]: {
              ...state[section].sections.media.listSource[targetState],
              ...file
            }
          }
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_MEDIA_CHANGE_BY_ID = (section, state, action) => {
  const {
    targetState,
    observation,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          listSource: {
            ...state[section].sections.media.listSource,
            [targetState]: {
              ...state[section].sections.media.listSource[targetState],
              observation
            }
          }
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ATTACHMENT_FILES = (section, state, action) => {
  const {
    listKeys,
    listSource
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        attachment: {
          ...state[section].sections.attachment,
          listKeys,
          listSource,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ATTACHMENT_FILES_BY_ID = (section, state, action) => {
  const {
    targetState,
    file
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        attachment: {
          ...state[section].sections.attachment,
          listSource: {
            ...state[section].sections.attachment.listSource,
            [targetState]: {
              ...state[section].sections.attachment.listSource[targetState],
              ...file
            }
          }
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_EXAM_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          searchLoading: true,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_EXAM_FETCH_SUCCESS = (section, state, action) => {
  const { listExam } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          searchLoading: false,
          listExam,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_EXAM_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          searchLoading: false,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_SEARCH_EXAM_RESET = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          listExam: [],
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          addExamDynamic: true,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          addExamDynamic: false,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_ADD_EXAM_DYNAMIC_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          addExamDynamic: false,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_UPDATE_EXAMS = (section, state, action) => {
  const {
    listKeys,
    listSource,
    listFields
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          listKeys,
          listSource,
          listFields,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_UPDATE_EXAM_FIELD_BY_ID = (section, state, action) => {
  const {
    targetState,
    fieldState,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          listFields: {
            ...state[section].sections.exam.listFields,
            [targetState]: {
              ...state[section].sections.exam.listFields[targetState],
              ...fieldState
            }
          }
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_UPDATE_EXAM_DATE_FIELD_BY_ID = (section, state, action) => {
  const {
    targetState,
    fieldState,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          listSource: {
            ...state[section].sections.exam.listSource,
            [targetState]: {
              ...state[section].sections.exam.listSource[targetState],
              ...fieldState,
            }
          }
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          saveExamFragmentField: true,
          saveExamFragmentMessage: 'Salvando...',
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_SUCCESS = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          saveExamFragmentField: false,
          saveExamFragmentMessage: 'Salvo com sucesso.',
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SAVE_EXAM_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          saveExamFragmentField: false,
          saveExamFragmentMessage: null,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ENABLE_HOUR_TABLE = (section, state, action) => {
  const {
    enableHour
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        enableHour,
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_STATUS_BAR = (section, state, action) => {
  const statusBar = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        statusBar: {
          ...state[section].sections.statusBar,
          ...statusBar
        },
      }
    }
  });
};

export const SAVE_STATUS_BUTTON = (section, state, action) => {
  const { loading, targetState, formId } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            statusSave: { [formId]: loading }
          }
        }
      }
    }
  });
}

export const MEDICALRECORD_PATIENT_CHANGE_COMMENTS_PRESCRIPTION = (section, state, action) => {
  const {
    value,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          comments: {
            ...state[section].sections.prescription.comments,
            value,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_COMMENTS_PRESCRIPTION = (section, state, action) => {
  const {
    comments,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        prescription: {
          ...state[section].sections.prescription,
          comments: {
            ...state[section].sections.prescription.comments,
            ...comments,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CHANGE_COMMENTS_CERTIFICATE = (section, state, action) => {
  const {
    value,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          comments: {
            ...state[section].sections.certificate.comments,
            value,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_COMMENTS_CERTIFICATE = (section, state, action) => {
  const {
    comments,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        certificate: {
          ...state[section].sections.certificate,
          comments: {
            ...state[section].sections.certificate.comments,
            ...comments,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REFERRAL = (section, state, action) => {
  const {
    value,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          comments: {
            ...state[section].sections.referral.comments,
            value,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_COMMENTS_REFERRAL = (section, state, action) => {
  const {
    comments,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        referral: {
          ...state[section].sections.referral,
          comments: {
            ...state[section].sections.referral.comments,
            ...comments,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REQUEST = (section, state, action) => {
  const {
    value,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          comments: {
            ...state[section].sections.request.comments,
            value,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_COMMENTS_REQUEST = (section, state, action) => {
  const {
    comments,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        request: {
          ...state[section].sections.request,
          comments: {
            ...state[section].sections.request.comments,
            ...comments,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CHANGE_COMMENTS_REPORT = (section, state, action) => {
  const {
    value,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          comments: {
            ...state[section].sections.report.comments,
            value,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_COMMENTS_REPORT = (section, state, action) => {
  const {
    comments,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        report: {
          ...state[section].sections.report,
          comments: {
            ...state[section].sections.report.comments,
            ...comments,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_CHANGE_COMMENTS_SECTION_BY_KEY = (section, state, action) => {
  const {
    targetState,
    value,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            comments: {
              ...state[section].sections.sectionsModels[targetState].comments,
              value,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_COMMENTS_SECTION_BY_KEY = (section, state, action) => {
  const {
    targetState,
    comments,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        sectionsModels: {
          ...state[section].sections.sectionsModels,
          [targetState]: {
            ...state[section].sections.sectionsModels[targetState],
            comments: {
              ...state[section].sections.sectionsModels[targetState].comments,
              ...comments,
            }
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getExamHistory: true,
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_SUCCESS = (section, state, action) => {
  const { listKeys, listRows } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getExamHistory: false,
      },
      api: {
        ...state[section].api,
        examHistory: {
          ...state[section].api.examHistory,
          listKeys,
          listRows
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_HISTORY_EXAM_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getExamHistory: false,
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          examHistory: [],
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_SUCCESS = (section, state, action) => {
  const { examHistory } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          examHistory,
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_EXAM_BY_PATIENT_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        exam: {
          ...state[section].sections.exam,
          examHistory: [],
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumPhoto: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_SUCCESS = (section, state, action) => {
  const {
    photoState,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumPhoto: false,
        album: {
          ...state[section].sections.album,
          photo: {
            ...state[section].sections.album.photo,
            ...photoState,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ALBUM_PHOTO_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumPhoto: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_RESET_ALBUM_PHOTO = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumPhoto: false,
        album: {
          ...state[section].sections.album,
          photo: { ...initialState.sections.album.photo }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_LIST_SOURCE_BY_ID = (section, state, action) => {
  const {
    targetState,
    onlyPath,
    ...data
  } = action.payload;

  const listImages = state[section].sections.album.photo.listImages;

  const _listImages = onlyPath ? [...listImages, data.pathSrc] : listImages;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        album: {
          ...state[section].sections.album,
          photo: {
            ...state[section].sections.album.photo,
            listImages: _listImages,
            listSource: {
              ...state[section].sections.album.photo.listSource,
              [targetState]: {
                ...state[section].sections.album.photo.listSource[targetState],
                ...data
              }
            },
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ALBUM_PHOTO_SELECTED = (section, state, action) => {
  const { selected } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        album: {
          ...state[section].sections.album,
          photo: {
            ...state[section].sections.album.photo,
            selected,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_PHOTO_LIGHTBOX = (section, state, action) => {
  const openLight = state[section].sections.album.photo.openLight;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        album: {
          ...state[section].sections.album,
          photo: {
            ...state[section].sections.album.photo,
            openLight: !openLight,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumVideo: true
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_SUCCESS = (section, state, action) => {
  const {
    videoState,
  } = action.payload;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumVideo: false,
        album: {
          ...state[section].sections.album,
          video: {
            ...state[section].sections.album.video,
            ...videoState,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_GET_ALBUM_VIDEO_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumVideo: false
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_RESET_ALBUM_VIDEO = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        getAlbumVideo: false,
        album: {
          ...state[section].sections.album,
          video: { ...initialState.sections.album.video }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ALBUM_VIDEO_LIST_SOURCE_BY_ID = (section, state, action) => {
  const {
    targetState,
    onlyPath,
    ...data
  } = action.payload;

  const listVideos = state[section].sections.album.video.listVideos;

  const _listVideos = onlyPath ? [...listVideos, data.pathSrc] : listVideos;

  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        album: {
          ...state[section].sections.album,
          video: {
            ...state[section].sections.album.video,
            listVideos: _listVideos,
            listSource: {
              ...state[section].sections.album.video.listSource,
              [targetState]: {
                ...state[section].sections.album.video.listSource[targetState],
                ...data
              }
            },
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_ALBUM_VIDEO_SELECTED = (section, state, action) => {
  const { selected } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        album: {
          ...state[section].sections.album,
          video: {
            ...state[section].sections.album.video,
            selected,
          }
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_TOGGLE_ALBUM_VIDEO_LIGHTBOX = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        attendanceShareConfiguration: {
          ...state[section].sections.attendanceShareConfiguration,
          ...action.payload
        }
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_UPLOAD_FILES_BY_ID = (section, state, action) => {
  const { uploadFiles } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          uploadFiles,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_SET_REMOVED_FILES_BY_ID = (section, state, action) => {
  const { removedFiles } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      sections: {
        ...state[section].sections,
        media: {
          ...state[section].sections.media,
          removedFiles,
        },
      }
    }
  });
};

export const MEDICALRECORD_PATIENT_ATTENDANCE_SET_SHARE_CONFIG = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    attendanceShareConfiguration: {
      ...state[section].attendanceShareConfiguration,
      ...action.payload,
      checkedShare: (!action.payload.isPublic && action.payload.medicalRecordsAccessGroupId)
    }
  }
})

export const MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      formToPrint: true
    }
  }
})

export const MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      formToPrint: false
    }
  }
})

export const MEDICALRECORD_PATIENT_GET_FORM_TO_PRINT_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      formToPrint: false
    },
    api: {
      ...state[section].api,
      formToPrint: { ...action.payload }
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      loadUserAccessGroups: true
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      loadUserAccessGroups: false
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_SHARE_CONFIG_LOAD_USER_ACCESS_GROUPS_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      loadUserAccessGroups: false
    },
    attendanceShareConfiguration: {
      ...state[section].attendanceShareConfiguration,
      userAccessGroups: [...action.payload]
    },
    api: {
      ...state[section].api,
      userAccessGroups: [...action.payload]
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveMedicalRecordsAttendanceShareConfig: true
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_SUCCESS = (section, state, action) => {
  const { isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveMedicalRecordsAttendanceShareConfig: false
      },

      api: medicalRecordsAttendanceId === state[section].api.attendance.medicalRecordsAttendanceId ?
        ({
          ...state[section].api,
          attendance: {
            ...state[section].api.attendance,
            isPublic,
            medicalRecordsAccessGroupId,
            medicalRecordsAttendanceId
          }
        })
        :
        ({ ...state[section].api }),
      mainTimelineData: {
        ...state[section].mainTimelineData,
        attendances: map(state[section].mainTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      certificateTimelineData: {
        ...state[section].certificateTimelineData,
        attendances: map(state[section].certificateTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      prescriptionTimelineData: {
        ...state[section].prescriptionTimelineData,
        attendances: map(state[section].prescriptionTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      referralTimelineData: {
        ...state[section].referralTimelineData,
        attendances: map(state[section].referralTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      requestTimelineData: {
        ...state[section].requestTimelineData,
        attendances: map(state[section].requestTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      reportTimelineData: {
        ...state[section].reportTimelineData,
        attendances: map(state[section].reportTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      mediaTimelineData: {
        ...state[section].mediaTimelineData,
        attendances: map(state[section].mediaTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      /*attachmentTimelineData: {
        ...state[section].attachmentTimelineData,
        attendances: map(state[section].attachmentTimelineData.attendances, attendance => {
          if(medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },*/
      sectionTimelineData: {
        ...state[section].sectionTimelineData,
        attendances: map(state[section].sectionTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      },
      examTimelineData: {
        ...state[section].examTimelineData,
        attendances: map(state[section].examTimelineData.attendances, attendance => {
          if (medicalRecordsAttendanceId === attendance.medicalRecordsAttendanceId)
            return ({ ...attendance, isPublic, medicalRecordsAccessGroupId, medicalRecordsAttendanceId })
          else return attendance;
        })
      }
    }
  })
}

export const MEDICALRECORD_PATIENT_ATTENDANCE_SAVE_SHARE_CONFIG_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      saveMedicalRecordsAttendanceShareConfig: false
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      deleteMedicalRecordsAttendance: true
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      deleteMedicalRecordsAttendance: false
    }
  }
})

export const MEDICALRECORD_PATIENT_ATTENDANCE_DELETE_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      deleteMedicalRecordsAttendance: false
    }
  }
});

export const MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  professional: {
    ...state.professional,
    fetching: {
      ...state.professional,
      getProfessional: true
    }
  }
});

export const MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  professional: {
    ...state.professional,
    data: action.payload,
  }
});

export const MEDICALRECORD_PATIENT_GET_PROFESSIONAL_BY_USER_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    professional: {
      ...state.professional,
      fetching: {
        ...state.professional,
        getProfessional: false
      }
    }
  })
};

export const MEDICALRECORD_PATIENT_SET_FETCH_LOADING_GENERATE_PDF = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    loadingGeneratePdf: action.payload
  }
});



