import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';
import { get } from '../util';
import { put } from '../util';
import { post } from '../util';

const PAYMENT = 'payment';
const PAY = 'pay';
const DEBIT = 'debit';
const PURCHASE = 'purchase';
const PENDING = 'pending';
const PACKAGE = 'package';
const BUSINNES = 'businnes';
const USERS = 'users';
const QUANTITY = 'quantity';
const BILLING = 'billing';
const DATA = 'data';
const CONFIG = 'config';
const METHOD = 'method';
const REQUEST = 'request';
const HISTORY = 'history';
const CONFIRM = 'confirm';
const INFORMATION = 'information';
const CARD = 'card';
const CREDIT = 'credit';
const PLAN = 'plan';
const CHANGE = 'change';

//#region GET
export const getUserPendingPurchase = async ({ header }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${PENDING}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getBillingData = async ({ accountId, header }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${DATA}`;
  const axios = interceptor(header);
  const params = { accountId };
  return await get(axios, pathUrl, params);
};

export const getBillingConfig = async ({ accountId, header }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${CONFIG}`;
  const axios = interceptor(header);
  const params = { accountId };
  return await get(axios, pathUrl, params);
};

export const getPaymentHistory = async ({ accountId, header }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${HISTORY}`;
  const axios = interceptor(header);
  const params = { account: accountId };
  return await get(axios, pathUrl, params);
};
//#endregion

//#region POST
export const addCreditCard = async ({ account, token, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${METHOD}/${CARD}/${CREDIT}`;
  const axios = interceptor({ account, token });
  return await post(axios, pathUrl, params);
};
export const payNow = async ({ params, header }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PAY}/${DEBIT}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

//#endregion

//#region PUT
export const updateAccountPurchaseRequestPackageFormat = async ({
  header,
  params,
  accountPurchaseRequestId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${accountPurchaseRequestId}/${PACKAGE}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateAccountPurchaseRequestBillingData = async ({
  header,
  params,
  accountPurchaseRequestId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${accountPurchaseRequestId}/${BILLING}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateAccountPurchaseRequestPaymentMethod = async ({
  header,
  params,
  accountPurchaseRequestId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${accountPurchaseRequestId}/${METHOD}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateBusinnesInformation = async ({
  header,
  params,
  accountPurchaseRequestId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${accountPurchaseRequestId}/${BUSINNES}/${INFORMATION}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const confirmPurchase = async ({
  header,
  params,
  accountPurchaseRequestId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${accountPurchaseRequestId}/${CONFIRM}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateBillingConfig = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${CONFIG}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateBillingData = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${DATA}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateAccountPackage = async ({ header, params, accountId }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${BILLING}/${accountId}/${CHANGE}/${PLAN}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateAccountUserQuantitySolicitation = async ({
  header,
  params,
  accountId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/account/${accountId}/${CHANGE}/${USERS}/limit/request`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateAccountUserQuantity = async ({
  header,
  params,
  accountId
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/account/${accountId}/${CHANGE}/${USERS}/limit/confirm`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const createAccountPurchaseRequest = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PAYMENT}/${PURCHASE}/${REQUEST}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};
//#endregion
