import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';

const PATIENT       = 'patient';
const HISTORY       = 'history';
const EXAM          = 'exam';

//#region GET
export const getSearchPatient = async ({header, value}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PATIENT}?value=${value}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getHistoryByExamId = async ({header, patientId, examId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PATIENT}/${patientId}/${HISTORY}/${EXAM}/${examId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getHistoryByExam = async ({header, patientId}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PATIENT}/${patientId}/${HISTORY}/${EXAM}s`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const postHistoryByExam = async ({header, patientId, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PATIENT}/${patientId}/${HISTORY}/${EXAM}s`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const createPatientBasic = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PATIENT}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion