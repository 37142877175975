import overviewInitialState from './Overview/container/initialState';
import typesOverview from './Overview/container/constant';
import * as commonReducerOverview from './Overview/container/reducerGenerator';

import registerInitialState from './register/config/initialState';
import typesRegister from './register/config/constant';
import * as commonReducerRegister from './register/config/reducerGenerator';

const INITIAL_STATE = {
  overview: { ...overviewInitialState },
  register: { ...registerInitialState() }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region OVERVIEW
    case typesOverview.CLIENT_OVERVIEW_SET_RESET_SEARCH_CLIENT:
      return commonReducerOverview.SET_RESET_SEARCH_CLIENT(
        typesOverview.SECTION,
        state,
        action
      );

    case typesOverview.CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_REQUEST:
      return commonReducerOverview.GET_SEARCH_CLIENT_FETCH_REQUEST(
        typesOverview.SECTION,
        state,
        action
      );
    case typesOverview.CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_SUCCESS:
      return commonReducerOverview.GET_SEARCH_CLIENT_FETCH_SUCCESS(
        typesOverview.SECTION,
        state,
        action
      );
    case typesOverview.CLIENT_OVERVIEW_GET_SEARCH_CLIENT_FETCH_ERROR:
      return commonReducerOverview.GET_SEARCH_CLIENT_FETCH_ERROR(
        typesOverview.SECTION,
        state,
        action
      );

    case typesOverview.CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_REQUEST:
      return commonReducerOverview.GET_SEARCH_CLIENT_FETCH_REQUEST(
        typesOverview.SECTION,
        state,
        action
      );
    case typesOverview.CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_SUCCESS:
      return commonReducerOverview.GET_SEARCH_CLIENT_FETCH_SUCCESS(
        typesOverview.SECTION,
        state,
        action
      );
    case typesOverview.CLIENT_OVERVIEW_GET_ALL_CLIENTS_FETCH_ERROR:
      return commonReducerOverview.GET_SEARCH_CLIENT_FETCH_ERROR(
        typesOverview.SECTION,
        state,
        action
      );

    case typesOverview.CLIENT_OVERVIEW_RESET_STATE:
      return commonReducerOverview.RESET_STATE(
        typesOverview.SECTION,
        state,
        action
      );
    //#endregion

    //#region REGISTER
    case typesRegister.CLIENT_REGISTER_SET_CLIENT_ENABLE_SAVE:
      return commonReducerRegister.SET_CLIENT_ENABLE_SAVE(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_SET_BOND_DIALOG:
      return commonReducerRegister.SET_BOND_DIALOG(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_SET_TAB_ACTIVE:
      return commonReducerRegister.SET_TAB_ACTIVE(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_SET_SHOULD_DESTROY_MODAL:
      return commonReducerRegister.SET_SHOULD_DESTROY_MODAL(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_SET_BOND_PROFILE_VIEWED:
      return commonReducerRegister.SET_BOND_PROFILE_VIEWED(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_SET_ENABLE_SAVE:
      return commonReducerRegister.SET_ENABLE_SAVE(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_RESET_STATE:
      return commonReducerRegister.RESET_STATE(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_SET_SOCIAL_BOND_BY_ID:
      return commonReducerRegister.SET_SOCIAL_BOND_BY_ID(
        typesRegister.SECTION,
        state,
        action
      );

    case typesRegister.CLIENT_REGISTER_CHANGE_SOCIAL_BOND_IS_RESPONSIBLE:
      return commonReducerRegister.CHANGE_SOCIAL_BOND_IS_RESPONSIBLE(
        typesRegister.SECTION,
        state,
        action
      );

    case typesRegister.CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_REQUEST:
      return commonReducerRegister.CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_SUCCESS:
      return commonReducerRegister.CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_ERROR:
      return commonReducerRegister.CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );

    case typesRegister.CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_REQUEST:
      return commonReducerRegister.GET_SEARCH_CLIENT_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_SUCCESS:
      return commonReducerRegister.GET_SEARCH_CLIENT_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_ERROR:
      return commonReducerRegister.GET_SEARCH_CLIENT_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_REQUEST:
      return commonReducerRegister.GET_SOCIAL_BOND_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_SUCCESS:
      return commonReducerRegister.GET_SOCIAL_BOND_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_ERROR:
      return commonReducerRegister.GET_SOCIAL_BOND_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_REQUEST:
      return commonReducerRegister.GET_CLIENT_BY_ID_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_SUCCESS:
      return commonReducerRegister.GET_CLIENT_BY_ID_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_ERROR:
      return commonReducerRegister.GET_CLIENT_BY_ID_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );

    case typesRegister.CLIENT_REGISTER_POST_CLIENT_FETCH_REQUEST:
      return commonReducerRegister.POST_CLIENT_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_CLIENT_FETCH_SUCCESS:
      return commonReducerRegister.POST_CLIENT_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_CLIENT_FETCH_ERROR:
      return commonReducerRegister.POST_CLIENT_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_REQUEST:
      return commonReducerRegister.POST_CLIENT_WITH_GUARDIAN_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_SUCCESS:
      return commonReducerRegister.POST_CLIENT_WITH_GUARDIAN_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_ERROR:
      return commonReducerRegister.POST_CLIENT_WITH_GUARDIAN_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_REQUEST:
      return commonReducerRegister.POST_SOCIAL_BOND_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_SUCCESS:
      return commonReducerRegister.POST_SOCIAL_BOND_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_ERROR:
      return commonReducerRegister.POST_SOCIAL_BOND_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_REQUEST:
      return commonReducerRegister.DELETE_SOCIAL_BOND_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_SUCCESS:
      return commonReducerRegister.DELETE_SOCIAL_BOND_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_ERROR:
      return commonReducerRegister.DELETE_SOCIAL_BOND_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );

    case typesRegister.CLIENT_REGISTER_NEW_AVATAR_FETCH_REQUEST:
      return commonReducerRegister.NEW_AVATAR_FETCH_REQUEST(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_NEW_AVATAR_FETCH_SUCCESS:
      return commonReducerRegister.NEW_AVATAR_FETCH_SUCCESS(
        typesRegister.SECTION,
        state,
        action
      );
    case typesRegister.CLIENT_REGISTER_NEW_AVATAR_FETCH_ERROR:
      return commonReducerRegister.NEW_AVATAR_FETCH_ERROR(
        typesRegister.SECTION,
        state,
        action
      );

    case typesRegister.CLIENT_REGISTER_SET_SECTION_DRAWER:
      return commonReducerRegister.CLIENT_REGISTER_SET_SECTION_DRAWER(
        typesRegister.SECTION,
        state,
        action
      );
    //#endregion

    default:
      return state;
  }
};
