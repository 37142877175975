import overviewInitialState       from './Overview/config/initialState';
import typesOverview              from './Overview/config/constant';
import * as commonReducerOverview from './Overview/config/reducerGenerator';

import patientInitialState       from './Patient/config/initialState';
import typesPatient              from './Patient/config/constant';
import * as commonReducerPatient from './Patient/config/reducerGenerator';

import patientAnswerInitialState       from './PatientAnswer/config/initialState';
import typesPatientAnswer              from './PatientAnswer/config/constant';
import * as commonReducerPatientAnswer from './PatientAnswer/config/reducerGenerator';

const INITIAL_STATE = ({
  overview: {...overviewInitialState},
  patient: {...patientInitialState, ...patientAnswerInitialState},
});

export default (state = INITIAL_STATE, action) => {

  if (typeof commonReducerOverview[action.type] === 'function') {
    return commonReducerOverview[action.type](typesOverview.SECTION, state, action);
  }
  if (typeof commonReducerPatient[action.type] === 'function') {
    return commonReducerPatient[action.type](typesPatient.SECTION, state, action);
  }
  if (typeof commonReducerPatientAnswer[action.type] === 'function') {
    return commonReducerPatientAnswer[action.type](typesPatientAnswer.SECTION, state, action);
  }
  else {
    return state;
  }
};