import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const INDICATOR = 'indicator';

//#region GET

export const listIndicators = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl, params);
};


//#region POST
export const createIndicator = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT

export const updateIndicator = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const saveCheckIndicator = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}/check`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};


//#region DELETE

export const deleteIndicator = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${INDICATOR}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion
