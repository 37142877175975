export const SET_URL_PDF_SIGNATURE = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            pdfUrl: action.payload
        }
    });
};

export const SET_SIGNATORY_SIGNATURE = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            signatory: action.payload
        }
    });
};

export const SET_FETCHING_SIGNATORY = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            fetchingSignatory: action.payload
        }
    });
};

export const SET_FETCHING_SEND_SIGNATURE = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            fetchingSendSignature: action.payload
        }
    });
};

export const SET_STATUS_SEND_SIGNATURE = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            statusSendSignature: action.payload
        }
    });
};

export const SET_IS_DOCUMENT_SIGNED = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            isDocumentSigned: action.payload
        }
    });
};

export const SET_SIGNATURE_DATA = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            signatureData: action.payload
        }
    });
};

export const SET_IS_DOCUMENT_CHECK_SIGNATURE_AUTHENTICITY = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            signatureAuthenticity: action.payload
        }
    });
};

export const FETCHING_DOCUMENT_CHECK_SIGNATURE_AUTHENTICITY = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            fetchingSignatureAuthenticity: action.payload
        }
    })
}

export const FETCHING_VALIDATION_EMAIL = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            fetchingValidationEmail: action.payload
        }
    })
}

export const SET_VALIDATION_CODE_EMAIL = (state, action) => {
    return ({
        ...state,
        signature: {
            ...state.signature,
            validationCodeEmail: action.payload
        }
    })
}
