export default ({
  SECTION                                           : 'overview',

  BUSINESS_OVERVIEW_IS_FIRST_LOADING                : 'BUSINESS_OVERVIEW_IS_FIRST_LOADING',
  
  BUSINESS_OVERVIEW_MODAL_CLOSED                    : 'BUSINESS_OVERVIEW_MODAL_CLOSED',
  BUSINESS_OVERVIEW_MODAL_UPDATE                    : 'BUSINESS_OVERVIEW_MODAL_UPDATE',

  BUSINESS_OVERVIEW_GET_BY_ID_FETCH_REQUEST         : 'BUSINESS_OVERVIEW_GET_BY_ID_FETCH_REQUEST',
  BUSINESS_OVERVIEW_GET_BY_ID_FETCH_SUCCESS         : 'BUSINESS_OVERVIEW_GET_BY_ID_FETCH_SUCCESS',
  BUSINESS_OVERVIEW_GET_BY_ID_FETCH_ERROR           : 'BUSINESS_OVERVIEW_GET_BY_ID_FETCH_ERROR',

  BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_REQUEST    : 'BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_REQUEST',
  BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_SUCCESS    : 'BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_SUCCESS',
  BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_ERROR      : 'BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_ERROR'
});