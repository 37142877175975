import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import LoadingComponent from '@Common/loading';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import * as util from '@Util';
import ReactGA from 'react-ga';
import tagManager from '@Constants/tagManager';

class Form extends Component {
  componentDidUpdate() {
    if (
      window.document.getElementsByClassName('grecaptcha-badge')[0] &&
      !window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.contains('grecaptcha-show')
    ) {
      window.document
        .getElementsByClassName('grecaptcha-badge')[0]
        .classList.add('grecaptcha-show');
    }
  }
  inputBlur = inputName => e => {
    const value = e.target.value;
    if (value.length > 0 && Object.values(tagManager.LOGIN.LABELS).includes(inputName)) {
      ReactGA.event({
        category: tagManager.LOGIN.CATEGORY,
        action: tagManager.LOGIN.ACTIONS.LOGIN_FIELDS,
        label: inputName
      });
    }
  }

  onSubmit = values => {
    ReactGA.event({
      category: tagManager.LOGIN,
      action: tagManager.LOGIN.ACTIONS.SIGNIN_CLICK,
      label: tagManager.LOGIN.LABELS.SIGNIN
    });
    const { recaptchaToken } = this.props;
    this.props.onSubmit({ ...values, gRecaptchaResponse: recaptchaToken });
    new Promise(() =>
      this.props
        .executeRecaptcha()
        .then(() => {
          return;
        })
        .catch(e => {
          toastr.warning(
            'Atenção',
            'Ocorreu algum problema. Por favor, atualize sua página e tente novamente.'
          );
        })
    );
  };

  render() {
    const {
      isLoading,
      validation,
      classes,
      recaptchaHasLoaded,
      handleSubmit
    } = this.props;

    if (!recaptchaHasLoaded)
      return (
        <div className={classes.blockUi}>
          <LoadingComponent message="Configurando ambiente para acesso seguro..." />
        </div>
      );

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div>
          <Field
            type="text"
            name="email"
            placeholder="Email"
            label="Email"
            onBlur={this.inputBlur('email')}
            error={validation.email || validation.emailFormat}
            className={classes.textField}
            InputProps={{
              classes: { input: classes.input }
            }}
            InputLabelProps={{ shrink: true }}
            validate={[util.email, util.required]}
            component={this.renderField}
          />
          {validation.email ? (
            <FormHelperText className={classes.errorText}>
              O campo email é inválido!
            </FormHelperText>
          ) : (
            ''
          )}
        </div>

        <div>
          <Field
            type="password"
            name="password"
            placeholder="Senha"
            onBlur={this.inputBlur('password')}
            label="Senha"
            error={validation.password || validation.passwordFormat}
            className={classes.textField}
            InputProps={{
              classes: { input: classes.input }
            }}
            InputLabelProps={{ shrink: true }}
            validate={[util.required, util.minLength6]}
            component={this.renderField}
          />
          {validation.password && !validation.passwordFormat ? (
            <FormHelperText className={classes.errorText}>
              Senha inválida, mínimo 6 caracteres
            </FormHelperText>
          ) : (
            ''
          )}
        </div>

        <Grid container direction={'column'}>
          <Grid item>
            <Fab
              type={'submit'}
              variant={'extended'}
              color={'primary'}
              className={classes.buttonSubmit}
              classes={{ root: classes.raisedPrimaryOverrideButton }}
              disabled={isLoading}
            >
              {isLoading ? 'Aguarde...' : 'Entrar'}
            </Fab>
          </Grid>

          <Grid container justify="center">
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <span
                className={classes.buttonRegisterlabel}
                onClick={this.redirectRouteRegister}
              >
                {' '}
                Nova conta{' '}
              </span>
            </Grid> */}
            <Grid
              item
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.mobileTopMargin}
            >
              <span
                className={classes.buttonRegisterlabel}
                onClick={this.redirectRouteRecover}
              >
                {' '}
                Recuperar senha{' '}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderField = ({ input, meta: { touched, error }, ...custom }) => {
    const { classes } = this.props;
    return (
      <Fragment>
        <TextField {...input} {...custom} variant="outlined" />
        {touched &&
          error && (
            <FormHelperText className={classes.errorText}>
              {' '}
              {error}
            </FormHelperText>
          )}
      </Fragment>
    );
  };

  // Redirect to Register Route
  // redirectRouteRegister = () => {
  //   this.props.history.push('/register');
  // };

  redirectRouteRecover = () => {
    this.props.history.push('/recover');
  };
}

Form.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  validation: PropTypes.shape({
    email: PropTypes.bool.isRequired,
    emailFormat: PropTypes.bool.isRequired,
    password: PropTypes.bool.isRequired,
    passwordFormat: PropTypes.bool.isRequired
  }).isRequired
};

export default reduxForm({ form: 'SignIn' })(withRouter(Form));
