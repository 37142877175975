import types from './constant';
import selectorLogin from '@Components/login/selector';

export const setBooleanSetting = (id, data) => {
  const type = {
    header: types.PDF_SETTINGS_SET_HEADER,
    footer: types.PDF_SETTINGS_SET_FOOTER,
    patientInfo: types.PDF_SETTINGS_SET_VIEW_PATIENT,
  }
  return ({
    type: type[id],
    payload: !!data
  })
}

export const changeConfig = (data) => ({
  type: types.PDF_SETTINGS_CHANGE_CONFIG,
  payload: data
});

export const resetState = () => ({
  type: types.RESET_STATE,
  payload: null
})

export const getUserPdfDataRequest = (data) => ({
  type: types.GET_PDF_INFO_REQUEST,
  payload: data
});

export const getUserPdfDataSuccess = (data) => ({
  type: types.GET_PDF_INFO_SUCCESS,
  payload: data
});

export const getUserPdfDataFailed = () => ({
  type: types.GET_PDF_INFO_FAILED,
  payload: null
});

export const getPdfData = () => async (dispatch, getState, api) => {
  const state = getState();
  const userId = selectorLogin.getAuthUserId(state);
  const workspaceId = selectorLogin.getAuthBusinessWorkspaceId(state);

  try {
    dispatch(getUserPdfDataRequest());
    const { data } = await api.account.getPdfData(userId, workspaceId);
    dispatch(getUserPdfDataSuccess(data));
  }
  catch (error) {
    dispatch(getUserPdfDataFailed());
  }
}

export const getUserPdfSettingsSuccess = (data) => ({
  type: types.PDF_SETTINGS_FETCH_SUCCESS,
  payload: data
});

const getUserPdfSettingsRequest = (data) => ({
  type: types.PDF_SETTINGS_FETCH_REQUEST,
});

export const getUserPdfSettingsFailed = () => ({
  type: types.PDF_SETTINGS_FETCH_FAILED,
  payload: null
});

export const getUserPdfSettings = (workId) => async (dispatch, getState, api) => {
  const state = getState();
  const userId = selectorLogin.getAuthUserId(state);
  const workspaceId = workId || selectorLogin.getAuthBusinessWorkspaceId(state);

  dispatch(getUserPdfSettingsRequest());

  try {
    const { settings } = await api.account.getUserPdfSettings(userId, workspaceId);
    dispatch(getUserPdfSettingsSuccess(settings.pdf));
  }
  catch (error) {
    dispatch(getUserPdfSettingsFailed());
  }
}

export const saveUserPdfSettingsSuccess = (data) => ({
  type: types.PDF_SETTINGS_SAVE_SUCCESS,
  payload: data
});

export const saveUserPdfSettingsFailed = () => ({
  type: types.PDF_SETTINGS_SAVE_FAILED,
  payload: null
});

export const saveUserPdfSettingsRequest = () => ({
  type: types.PDF_SETTINGS_SAVE_REQUEST,
  payload: null
});

export const saveUserPdfSettings = (onConfirm) => async (dispatch, getState, api) => {
  const state = getState();
  const userId = selectorLogin.getAuthUserId(state);
  const workspaceId = selectorLogin.getAuthBusinessWorkspaceId(state);
  const {
    header,
    footer,
    patientInfo,
    emptyResponse,
    selectedResponse,
    qualifier,
    moreInfoQualifier,
    qualifierDeficiency,
    tableQualifier,
    tableQualifierDeficiency,
    moreInfoQualifierDeficiency,
    dataProfessional,
    dataAttendance,
    dataWorkspace,
  } = state.pdf;

  dispatch(saveUserPdfSettingsRequest());
  try {
    await api.account.setUserPdfSettings(userId, {
      header,
      footer,
      patientInfo,
      emptyResponse,
      selectedResponse,
      dataProfessional,
      dataAttendance,
      dataWorkspace,
      qualifier,
      moreInfoQualifier,
      qualifierDeficiency,
      tableQualifier,
      tableQualifierDeficiency,
      moreInfoQualifierDeficiency,
      workspaceId
    });

    dispatch([
      saveUserPdfSettingsSuccess(
        {
          header,
          footer,
          patientInfo,
          emptyResponse,
          selectedResponse,
          dataProfessional,
          dataAttendance,
          dataWorkspace,
          qualifier,
          tableQualifier,
          moreInfoQualifier,
          qualifierDeficiency,
          tableQualifierDeficiency,
          moreInfoQualifierDeficiency,
        }
      ),
    ]);
    onConfirm();
  }
  catch (error) {
    dispatch(saveUserPdfSettingsFailed());
  }
}