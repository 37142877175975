import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BlockUi from 'react-block-ui';
import Loader from 'react-loaders';
import palettes from '@Constants/palletes';
import { styles } from './style';

const LoadingComponent = props => {
  const { message, classes } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <BlockUi
          tag={'div'}
          message={message}
          className={classes.blockUi}
          blocking
          loader={
            <Loader
              active
              type={'line-scale'}
              color={palettes.colors.first[500]}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

LoadingComponent.propTypes = {
  message: PropTypes.string
};

LoadingComponent.defaultProps = {
  message: 'Carregando Pré Configurações....'
};

export default withStyles(styles)(LoadingComponent);
