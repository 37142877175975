export default {
  SECTION: 'register',

  FORM_TAB_GENERAL_DATA: 'form:tab:generalData',
  FORM_TAB_ADDRESS: 'form:tab:address',
  FORM_MODAL_CREATE_BOND: 'form:modal:create:bond',
  FORM_MODAL_UPDATE_BOND: 'form:modal:update:bond',
  FORM_MODAL_CLIENT_BOND: 'form:modal:client:bond',

  MODAL_CLIENT_BOND: 'modal:client:bond',
  MODAL_CREATE_BOND: 'modal:create:bond',
  MODAL_UPDATE_BOND: 'modal:update:bond',
  MODAL_CREATE_PHOTO: 'modal:create:photo',
  MODAL_ALERT: 'modal:alert',

  CLIENT_REGISTER_RESET_STATE: 'CLIENT_REGISTER_RESET_STATE',

  CLIENT_REGISTER_SET_SECTION_DRAWER: 'CLIENT_REGISTER_SET_SECTION_DRAWER',

  CLIENT_REGISTER_SET_BOND_PROFILE_VIEWED:
    'CLIENT_REGISTER_SET_BOND_PROFILE_VIEWED',

  CLIENT_REGISTER_SET_ENABLE_SAVE: 'CLIENT_REGISTER_SET_ENABLE_SAVE',
  CLIENT_REGISTER_SET_CLIENT_ENABLE_SAVE:
    'CLIENT_REGISTER_SET_CLIENT_ENABLE_SAVE',

  CLIENT_REGISTER_SET_BOND_DIALOG: 'CLIENT_REGISTER_SET_BOND_DIALOG',

  CLIENT_REGISTER_SET_TAB_ACTIVE: 'CLIENT_REGISTER_SET_TAB_ACTIVE',

  CLIENT_REGISTER_SET_SOCIAL_BOND_BY_ID:
    'CLIENT_REGISTER_SET_SOCIAL_BOND_BY_ID',

  CLIENT_REGISTER_SET_SHOULD_DESTROY_MODAL:
    'CLIENT_REGISTER_SET_SHOULD_DESTROY_MODAL',

  CLIENT_REGISTER_CHANGE_SOCIAL_BOND_IS_RESPONSIBLE:
    'CLIENT_REGISTER_CHANGE_SOCIAL_BOND_IS_RESPONSIBLE',

  CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_REQUEST:
    'CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_REQUEST',
  CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_SUCCESS:
    'CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_SUCCESS',
  CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_ERROR:
    'CLIENT_REGISTER_CHECK_CEP_INFO_FETCH_ERROR',

  CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_REQUEST:
    'CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_REQUEST',
  CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_SUCCESS:
    'CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_SUCCESS',
  CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_ERROR:
    'CLIENT_REGISTER_GET_SEARCH_CLIENT_FETCH_ERROR',

  CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_REQUEST:
    'CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_REQUEST',
  CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_SUCCESS:
    'CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_SUCCESS',
  CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_ERROR:
    'CLIENT_REGISTER_GET_CLIENT_BY_ID_FETCH_ERROR',

  CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_REQUEST:
    'CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_REQUEST',
  CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_SUCCESS:
    'CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_SUCCESS',
  CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_ERROR:
    'CLIENT_REGISTER_GET_SOCIAL_BOND_FETCH_ERROR',

  CLIENT_REGISTER_POST_CLIENT_FETCH_REQUEST:
    'CLIENT_REGISTER_POST_CLIENT_FETCH_REQUEST',
  CLIENT_REGISTER_POST_CLIENT_FETCH_SUCCESS:
    'CLIENT_REGISTER_POST_CLIENT_FETCH_SUCCESS',
  CLIENT_REGISTER_POST_CLIENT_FETCH_ERROR:
    'CLIENT_REGISTER_POST_CLIENT_FETCH_ERROR',

  CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_REQUEST:
    'CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_REQUEST',
  CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_SUCCESS:
    'CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_SUCCESS',
  CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_ERROR:
    'CLIENT_REGISTER_POST_CLIENT_WITH_GUARDIAN_FETCH_ERROR',

  CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_REQUEST:
    'CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_REQUEST',
  CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_SUCCESS:
    'CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_SUCCESS',
  CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_ERROR:
    'CLIENT_REGISTER_POST_SOCIAL_BOND_FETCH_ERROR',

  CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_REQUEST:
    'CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_REQUEST',
  CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_SUCCESS:
    'CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_SUCCESS',
  CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_ERROR:
    'CLIENT_REGISTER_DELETE_SOCIAL_BOND_FETCH_ERROR',

  CLIENT_REGISTER_NEW_AVATAR_FETCH_REQUEST:
    'CLIENT_REGISTER_NEW_AVATAR_FETCH_REQUEST',
  CLIENT_REGISTER_NEW_AVATAR_FETCH_SUCCESS:
    'CLIENT_REGISTER_NEW_AVATAR_FETCH_SUCCESS',
  CLIENT_REGISTER_NEW_AVATAR_FETCH_ERROR:
    'CLIENT_REGISTER_NEW_AVATAR_FETCH_ERROR'
};
