export default () => ({
  sections: {
    done: true,
    activeKey: '',
    payNowMethodTypeId: null
  },

  fetching: {
    updatingData: false,
    loadingInfo: false,
    isCheckingCep: false,
    loadingPlans: false,
    creatingCard: false,
    isPaying: false,
    isSolicitingChangeNumUsers: false
  },

  api: {
    plans: []
  },

  paymentHistory: [],

  changeUserSolicitation: {
    tempUserQuantity: 0
  },

  newBillingConfig: {
    accountCreditCardId: null,
    paymentMethodTypeId: 0,
    zoopCustomerId: null,
    accountPackage: {
      id: 0,
      name: null,
      defaultWorkspacesLimit: 1,
      defaultUsersLimit: 1,
      defaultPrice: 0,
      basePrice: 0,
      pricePerUser: 0,
      pricePerWorkspace: 0,
      signatureTypeId: 0
    },
    card: {
      holderName: null,
      expirationMonth: null,
      expirationYear: null,
      cardNumber: null,
      cvv: null
    }
  },

  billingConfig: {
    accountCreditCardId: null,
    paymentMethodTypeId: 0,
    zoopCustomerId: null,
    accountPackage: {
      id: 0,
      name: null,
      defaultWorkspacesLimit: 1,
      defaultUsersLimit: 1,
      defaultPrice: 0,
      basePrice: 0,
      pricePerUser: 0,
      pricePerWorkspace: 0,
      signatureTypeId: 0
    },
    card: {
      holderName: null,
      expirationMonth: null,
      expirationYear: null,
      cardNumber: null,
      cvv: null
    }
  },

  billingData: {
    // useCnpj: null,
    firstName: null,
    lastName: null,
    cpf: null,
    cnpj: null,
    zipcode: null,
    city: null,
    state: null,
    email: null,
    address: null,
    neighborhood: null,
    complement: null
  },

  paymentInfo: {
    paymentMethodTypeId: null,
    card: {
      holderName: null,
      expirationMonth: null,
      expirationYear: null,
      cardNumber: null,
      cvv: null
    }
  },

  // packageInfo: {
  //   packageModelId: null,
  //   usersLimit: 1,
  //   workspaceLimit: 1
  // },

  packageData: {
    packageModelId: null,
    usersLimit: 1,
    workspacesLimit: 1
  }
});
