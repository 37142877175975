import { reset } from 'redux-form';
import { push } from 'connected-react-router';

import * as types from '../constants';
import * as actionSnack from '@Common/snackBar/action';
import * as actionConfig from '@Actions/config';
import * as util from '@Util';
import systemConfigs from '@Constants/systemConfigs';

export const updateUserPasswordFetchRequest = () => ({
  type: types.LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_REQUEST
});
export const updateUserPasswordFetchError = data => ({
  type: types.LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_ERROR,
  payload: data
});
export const updateUserPasswordFetchSuccess = data => ({
  type: types.LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_SUCCESS,
  payload: data
});

export const verifyRecoverFetchRequest = () => ({
  type: types.LOGIN_RECOVER_VERIFY_TOKEN_FETCH_REQUEST
});
export const verifyRecoverFetchError = data => ({
  type: types.LOGIN_RECOVER_VERIFY_TOKEN_FETCH_ERROR,
  payload: data
});
export const verifyRecoverFetchSuccess = data => ({
  type: types.LOGIN_RECOVER_VERIFY_TOKEN_FETCH_SUCCESS,
  payload: data
});

export const updateUserPassword = params => async (dispatch, getState, api) => {
  dispatch(updateUserPasswordFetchRequest());
  try {
    await api.user.updatePasswordFromEmail(params);

    dispatch([
      updateUserPasswordFetchSuccess(),
      reset('NewPassword'),
      actionSnack.showSnackbar({
        message: 'A nova senha foi cadastrada com sucesso',
        variant: 'success',
        autoHideDuration: 3500
      }),
      push('/')
    ]);
  } catch (error) {
    const { userFeedback } = error;

    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateUserPasswordFetchError()
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const verifyRecoverToken = params => async (dispatch, getState, api) => {
  dispatch(verifyRecoverFetchRequest());
  try {
    await api.login.verifyRecoverToken({ token: params });

    dispatch([verifyRecoverFetchSuccess()]);
  } catch (error) {
    const { userFeedback } = error;

    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback
    });

    if (error.name === 'TokenExpiredError') {
      dispatch([
        verifyRecoverFetchError(true),
        actionConfig.routeRedirect('/recover')
      ]);
      return;
    }

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      verifyRecoverFetchError(false)
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};
