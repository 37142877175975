import { push }         from 'connected-react-router';
import { reset, submit} from 'redux-form';

import types            from '@App/constants/config';
import selectorConfig   from '@Selector/configState';

const setMenuUpdate = data => ({ type: types.CONFIG_MENU_SET_UPDATE, payload: data });

export const setToggleMenu  = data => ({ type: types.CONFIG_MENU_TOGGLE_DONE, payload: data });
export const routeChange    = data  => ({ type: types.ROUTE_CHANGE, payload: data });
export const routeRedirect  = path  => dispatch => dispatch(push(path));
export const resetForms     = name  => dispatch => dispatch(reset(name));
export const submitForms    = name  => dispatch => dispatch(submit(name));

/**
 * Gerenciar Menu Secundario baseado no Menu Primário.
 * @param {Object[]} dataSource - Configuração para o menu secundario.
 */
export const setManagerDrawerChild = props => (dispatch, getState, api) => {
  const {dataSource} = props;
  const _dataSource = dataSource || [];

  const params = ({
    done: false,
    dataSource: _dataSource
  });

  dispatch(setMenuUpdate(params));
};

export const toggleMenuSecondary = () => (dispatch, getState, api) => {
  const state = getState();
  const done  = selectorConfig.getMenuIsDone(state);
  dispatch(setToggleMenu({done: !done}));
};