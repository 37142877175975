export const FETCH_SUCCESS_LIST_CORELINES = (state, action) => {
    return ({
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        coreLines: {
          ...state.medicalRecord.coreLines,
          fetching: {
            ...state.medicalRecord.coreLines.fetching,
            getCoreLines: false
          },
          api:{
            ...state.medicalRecord.coreLines.api,
            coreLines: action.payload,
          }
        }
      }
    });
  };

export const FETCH_LIST_CORELINES = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      coreLines: {
        ...state.medicalRecord.coreLines,
        fetching: {
          ...state.medicalRecord.coreLines.fetching,
          getCoreLines: true
        }
      }
    }
  });
}

export const FETCH_LIST_CORELINES_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      coreLines: {
        ...state.medicalRecord.coreLines,
        fetching: {
          ...state.medicalRecord.coreLines.fetching,
          getCoreLines: false
        }
      }
    }
  });
}

export const FETCHING_CORELINE_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      coreLines: {
        ...state.medicalRecord.coreLines,
        fetching: {
          ...state.medicalRecord.coreLines.fetching,
          saveCoreLine: true
        }
      }
    }
  });
}

export const FETCHING_CORELINE_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      coreLines: {
        ...state.medicalRecord.coreLines,
        fetching: {
          ...state.medicalRecord.coreLines.fetching,
          saveCoreLine: false
        }
      }
    }
  });
}

export const FETCHING_CORELINE_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      coreLines: {
        ...state.medicalRecord.coreLines,
        fetching: {
          ...state.medicalRecord.coreLines.fetching,
          saveCoreLine: false
        }
      }
    }
  });
}