/*eslint-disable no-throw-literal*/
import * as endpoints   from '@App/constants/endpoints';
import interceptor      from './interceptor';

const axios       = interceptor();
const PACKAGE     = 'package';
const LIST        = 'list';
const MODEL       = 'model';

export const getPlansList = async () => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PACKAGE}/${LIST}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getPlansModel = async () => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PACKAGE}/${MODEL}/${LIST}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
}