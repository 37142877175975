const document = {
   uuid: '',
   title: '',
   pageSize: 'a4',
   stylePage: {},
   margin: {
      left: 50,
      right: 50,
      top: 100,
      bottom: 100
   },
   header: {
      background: '',
      lines: [{ blocks: [] }],
      margin: {
         top: 10,
         bottom: 10
      },
   },
   footer: {
      background: '',
      lines: [{ blocks: [] }],
      margin: {
         top: 10,
         bottom: 10
      },
   },
   sharedConfig: {
   },
   isSignature: false,
   configSignature: {},
   sharedId: null,
   modifiedAt: '',
   accountId: null,
   workspaceId: null,
   userIdDeleted: null,
   userIdCreated: null,
   userIdModified: null,
   createdAt: '',
   status: '',
   category: '',
   guardTime: '',
   documentType: '',
   documentValidity: '',
   documentClassification: '',
   signatories: [],
   stakeholders: { clients: [], professionals: [] },
}

export default ({
   drawer: {
      isOpen: false,
   },
   drawerTabIndex: 0,
   shared: {
      requirePassword: false,
      document: document,
      notFoundDocument: false,
      fetchDocument: true,
      tableOfContents: [],
      sidebar: false,
      historyPage: [],
      pageDoc: {},
      changePage: false,
      fetchRequestInitialDocument: true,
   },
   stateBlockFocus: {
   },
   sidebar: {
      isOpen: true,
      allDocs: true,
      foldersUndefinied: false,
      myModelsDocs: false,
   },
   header: {
      isEdit: false,
      type: 'header'
   },
   fetching: {
      getDocModel: false,
      createDocModel: false,
      updateDocModel: false,
      listDocModel: false,
      createDocument: false,
      getModelDocument: false,
      getDocuments: false,
      moveDocumentFolder: false
   },
   historyPage: [{}],
   pathFolder: [],
   changePage: false,
   modal: {
      folderProperty: {
         icon: "faFolder",
         color: "rgb(31, 34, 37)"
      }
   },
   folderSelected: {},
   api: {
      category: {
         categoriesAllDocuments: [],
         fetching: {
            getCategories: false,
         }
      },
      docModelManagerFilter: {},
      groupPermission: [],
      urlPdf: '',
      requestUpdate: [],
      isLoadindShared: false,
      docModelList: [],
      docModelManagerList: [],
      authors: [],
      tableOfContents: [],
      titleDoc: '',
      docModelsPublished: [],
      documentsList: [],
      draftsDocuments: [],
      documentsSignaturePending: [],
      documentsSignatureConcluded: [],
      docModelListFilter: {
         models: [],
         draftsMoldes: [],
         filter: false
      },
      documentsListFilter: {
         documentsSignaturePending: [],
         documentsSignatureConcluded: [],
         draftsDocuments: [],
         documents: [],
         filter: false
      },

      classificationsGroup: {
         category: [],
         documentType: [],
         documentClassification: [],
         documentValidity: [],
         guardTime: [],
      },
      classification: {
         category: [],
         documentType: [],
         documentClassification: [],
         documentValidity: [],
         guardTime: [],
         classification: {},
         fetching: {
            getClassification: false,
            saveClassification: false,
         }
      },
      folder: {
         foldersModels: [],
         foldersAllModels: [],
         foldersDocuments: [],
         foldersAllDocuments: [],
         fetching: {
            getFoldersModels: false,
            saveFolder: false,
         }
      },
      modelSave: {
         message: '',
         status: false
      },
      docModel: {
         ...document
      },
      pageDoc: {
      },
      document: {
         ...document
      },
      pages: {
      },
      classificationsListType: {
         documentType: [],
      },
      userInfo: {
         address: '',
         avatarFileReference: '',
         avatarSource: null,
         birthdate: '',
         cel: null,
         cep: null,
         cityId: null,
         complement: '',
         cpf: '',
         email: '',
         id: null,
         latitude: '',
         longitude: '',
         name: '',
         neighborhood: '',
         numberAddress: null,
         pendingRegistration: null,
         photo: null,
         professionalId: null,
         sex: '',
         stateId: '',
         tel: '',
      },
   },
   signature: {
      api: {
         clients: [],
         professionals: [],
         signatories: {},
         signatoriesSend: [],
         signatureSend: {},
         signature: {},
         fetching: {
            getClients: false,
            getProfessionals: false,
            createSignature: false,
            statusCreateSignature: ''
         }
      },
      pdfUrl: '',
      signatory: {},
      signatureAuthenticity: {},
      fetchingSignatureAuthenticity: false,
      fetchingSignatory: false,
      fetchingValidationEmail: false,
      fetchingSendSignature: false,
      statusSendSignature: false,
      isDocumentSigned: false,
      validationCodeEmail: {}
   }
});