export const FETCH_SAVE_FILTER = (state, action) => {
  return ({
    ...state,
    fetchSaveFilter: action.payload
  });
};

export const FETCH_GET_LIST_FILTERS = (state, action) => {
  return ({
    ...state,
    fetchGetListFilters: action.payload
  });
};

export const SET_SAVE_FILTERS = (state, action) => {
  return ({
    ...state,
    filters: action.payload
  });
};

export const FILTER_SELECT = (state, action) => {
  return ({
    ...state,
    filter: action.payload
  });
};

export const FETCH_DELETE_FILTER = (state, action) => {
  return ({
    ...state,
    fetchDeleteFilter: action.payload
  });
};

export const SET_PATIENT_FILTER = (state, action) => {
  return ({
    ...state,
    patientsFilter: action.payload
  });
};

export const SET_CORELINES_FILTER = (state, action) => {
  return ({
    ...state,
    corelinesFilter: action.payload
  });
};

export const FETCH_LIST_CORE_LINE_MEDICAL_RECORDS = (state, action) => {
  return ({
    ...state,
    fetchListCoreLineMedicalRecords: true
  });
};

export const SET_LIST_CORE_LINES_FETCH_MEDICAL_RECORDS_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetchListCoreLineMedicalRecords: false,
    listCoreLinesMedicalRecords: action.payload
  });
};

export const FETCH_LIST_CORE_LINE_MEDICAL_RECORDS_ERROR = (state, action) => {
  return ({
    ...state,
    fetchListCoreLineMedicalRecords: false,
  });
};

export const FETCH_LIST_FORM_MODELS_MEDICAL_RECORDS_REQUEST = (state, action) => {
  return ({
    ...state,
    fetchListFormModelsMedicalRecords: true
  });
};

export const FETCH_LIST_FORM_MODELS_MEDICAL_RECORDS_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetchListFormModelsMedicalRecords: false,
    listFormModels: action.payload
  });
};

export const FETCH_LIST_FORM_MODELS_MEDICAL_RECORDS_ERROR = (state, action) => {
  return ({
    ...state,
    fetchListFormModelsMedicalRecords: false,
  });
};

export const FETCH_LIST_FORM_MANAGER_MODELS_MEDICAL_RECORDS_REQUEST = (state, action) => {
  return ({
    ...state,
    fetchListFormManagerModelsMedicalRecords: true
  });
};

export const FETCH_LIST_FORM_MANAGER_MODELS_MEDICAL_RECORDS_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetchListFormManagerModelsMedicalRecords: false,
    listFormModelsManager: action.payload
  });
};

export const FETCH_LIST_FORM_MANAGER_MODELS_MEDICAL_RECORDS_ERROR = (state, action) => {
  return ({
    ...state,
    fetchListFormManagerModelsMedicalRecords: false,
  });
};

export const SET_COLUMNS_DISPLAY = (state, action) => {
  return ({
    ...state,
    columnsDisplay: action.payload
  });
}

export const FETCH_SEARCH_FIELDS_REQUEST = (state, action) => {
  return ({
    ...state,
    fetchSearchFields: true
  });
}

export const FETCH_SEARCH_FIELDS_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetchSearchFields: false,
    searchFields: action.payload
  });
}

export const FETCH_SEARCH_FIELDS_ERROR = (state, action) => {
  return ({
    ...state,
    fetchSearchFields: false,
  });
}

export const FETCH_GET_FIELD_MODEL_BY_ID_REQUEST = (state, actions) => {
  return ({
    ...state,
    fetchGetFieldModelById: true
  });
}

export const FETCH_GET_FIELD_MODEL_BY_ID_SUCCESS = (state, actions) => {
  return ({
    ...state,
    fetchGetFieldModelById: false,
    fieldModelSelected: actions.payload
  });
}

export const FETCH_GET_FIELD_MODEL_BY_ID_ERROR = (state, actions) => {
  return ({
    ...state,
    fetchGetFieldModelById: false,
  });
}

export const FETCH_SEARCH_EXERCISES_REQUEST = (state, actions) => {
  return ({
    ...state,
    fetchSearchExercises: true
  });
}

export const FETCH_SEARCH_EXERCISES_SUCCESS = (state, actions) => {
  return ({
    ...state,
    fetchSearchExercises: false,
    fieldOptions: actions.payload
  });
}

export const FETCH_SEARCH_EXERCISES_ERROR = (state, actions) => {
  return ({
    ...state,
    fetchSearchExercises: false,
  });
}

export const FETCH_SEARCH_PROFESSIONALS_REQUEST = (state, actions) => {
  return ({
    ...state,
    fetchSearchProfessionals: true
  });
}

export const FETCH_SEARCH_PROFESSIONALS_SUCCESS = (state, actions) => {
  return ({
    ...state,
    fetchSearchProfessionals: false,
    fieldOptions: actions.payload
  });
}

export const FETCH_SEARCH_PROFESSIONALS_ERROR = (state, actions) => {
  return ({
    ...state,
    fetchSearchProfessionals: false,
  });
}

export const SET_FIELD_OPTIONS = (state, actions) => {
  return ({
    ...state,
    fieldOptions: actions.payload
  });
}

export const FETCH_FORM_MODEL_FIELDS_REQUEST = (state, actions) => {
  return ({
    ...state,
    fetchSearchFields: true
  });
}

export const FETCH_FORM_MODEL_FIELDS_SUCCESS = (state, actions) => {
  return ({
    ...state,
    fetchSearchFields: false,
    searchFields: actions.payload
  });
}

export const FETCH_FORM_MODEL_FIELDS_ERROR = (state, actions) => {
  return ({
    ...state,
    fetchSearchFields: false,
  });
}

export const FETCH_DATA_BY_PATIENT_IDS_REQUEST = (state, actions) => {
  return ({
    ...state,
    fetchDataByPatientIds: true
  });
}

export const FETCH_DATA_BY_PATIENT_IDS_SUCCESS = (state, actions) => {
  return ({
    ...state,
    fetchDataByPatientIds: false,
    dataByPatientIds: actions.payload
  });
}

export const FETCH_DATA_BY_PATIENT_IDS_ERROR = (state, actions) => {
  return ({
    ...state,
    fetchDataByPatientIds: false,
  });
}

export const SET_FIELDS_SELECTED = (state, actions) => {
  return ({
    ...state,
    fieldsSelected: actions.payload
  });
}

export const FETCH_DATA_BY_PATIENT_IDS_CORELINES_REQUEST = (state, actions) => {
  return ({
    ...state,
    fetchDataByPatientIdsCorelines: true
  });
}

export const FETCH_DATA_BY_PATIENT_IDS_CORELINES_SUCCESS = (state, actions) => {
  return ({
    ...state,
    fetchDataByPatientIdsCorelines: false,
    dataByPatientIdsCorelines: actions.payload
  });
}

export const SET_QUALIFIER_FIELDS = (state, actions) => {
  return ({
    ...state,
    qualifierFields: actions.payload
  });
}

export const DASHBOARD_SET_LIST_QUALIFIERS_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    qualifiers: action.payload,
  });
}

export const DASHBOARD_CREATE_INDICATORS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    createIndicators: true,
  });
}

export const DASHBOARD_CREATE_INDICATORS_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    createIndicators: false,
  });
}

export const DASHBOARD_CREATE_INDICATORS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    createIndicators: false,
  });
}

export const DASHBOARD_LIST_INDICATORS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetchlistIndicators: true,
  });
}

export const DASHBOARD_LIST_INDICATORS_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetchlistIndicators: false,
    indicators: action.payload,
  });
}

export const DASHBOARD_LIST_INDICATORS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetchlistIndicators: false,
  });
}

export const DASHBOARD_GET_INDICATOR_HISTORY_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetchIndicatorHistory: true,
  });
}

export const DASHBOARD_GET_INDICATOR_HISTORY_FETCH_SUCCESS = (state, action) => {
  const { indicatorId, dataGraphic, history, periodMonths } = action.payload;
  return ({
    ...state,
    fetchIndicatorHistory: false,
    indicatorHistory: {
      ...state.indicatorHistory,
      [indicatorId]: dataGraphic,
    },
    historyDataTable: {
      ...state.historyDataTable,
      [indicatorId]: history,
    },
    periodMonths: {
      ...state.periodMonths,
      [indicatorId]: periodMonths,
    }
  });
}
export const DASHBOARD_GET_INDICATOR_HISTORY_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetchIndicatorHistory: false,
  });
}

export const DASHBOARD_UPDATE_CHECK_INDICATOR_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    updateCheckIndicator: true,
  });
}

export const DASHBOARD_UPDATE_CHECK_INDICATOR_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    updateCheckIndicator: false,
  });
}

export const DASHBOARD_UPDATE_CHECK_INDICATOR_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    updateCheckIndicator: false,
  });
}

export const DASHBOARD_DELETE_INDICATOR_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    deleteIndicator: true,
  });
}

export const DASHBOARD_DELETE_INDICATOR_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    deleteIndicator: false,
  });
}

export const DASHBOARD_DELETE_INDICATOR_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    deleteIndicator: false,
  });
}

export const DASHBOARD_UPDATE_INDICATOR_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    updateIndicator: true,
  });
}

export const DASHBOARD_UPDATE_INDICATOR_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    updateIndicator: false,
  });
}

export const DASHBOARD_UPDATE_INDICATOR_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    updateIndicator: false,
  });
}

export const DASHBOARD_SET_OBJECT_QUALIFIERS_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    objectQualifiers: action.payload,
  });
}

export const DASHBOARD_GET_DATA_GROUP_PATIENTS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    fetchDataGroupPatients: action.payload,
  });
}
export const DASHBOARD_GET_DATA_GROUP_PATIENTS_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    fetchDataGroupPatients: false,
    dataGroupPatients: {
      ...state.dataGroupPatients,
      ...action.payload,
    },
  });
}
export const DASHBOARD_GET_DATA_GROUP_PATIENTS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    fetchDataGroupPatients: false,
  });
}

export const DRAWER_TABLE_TOGGLE = (state, action) => {
  return ({
    ...state,
    drawerTableOpen: action.payload
  });
}
