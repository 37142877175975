import * as actionConfig from '@Actions/config';
import * as util from '@Util';
import systemConfigs from '@Constants/systemConfigs';

import * as actionSnack from '@Common/snackBar/action';
import types from './constant';
import selector from './selector';
import selectorLogin from '@Components/login/selector';
import * as actionModal from '@Common/modal/action';
import moment from 'moment';

export const setPackageData = (data) => ({
  type: types.MANAGER_BILLING_SET_PACKAGE_DATA,
  payload: data,
});
export const setPaymentMethod = (data) => ({
  type: types.MANAGER_BILLING_SET_PAYMENT_METHOD,
  payload: data,
});
export const setPaymentNewMethod = () => ({
  type: types.MANAGER_BILLING_SET_PAYMENT_NEW_METHOD,
});
export const setBillingData = (data) => ({
  type: types.MANAGER_BILLING_SET_BILLING_DATA,
  payload: data,
});
export const setBillingConfig = (data) => ({
  type: types.MANAGER_BILLING_SET_BILLING_CONFIG,
  payload: data,
});
export const setPaymentHistory = (data) => ({
  type: types.MANAGER_BILLING_SET_PAYMENT_HISTORY,
  payload: data,
});
export const setUserCount = (data) => ({
  type: types.MANAGER_BILLING_SET_USER_COUNT,
  payload: data,
});

export const setTabActive = (data) => ({
  type: types.MANAGER_BILLING_SET_TAB_ACTIVE,
  payload: data,
});
export const resetRegister = () => ({
  type: types.MANAGER_BILLING_RESET_STATE,
});
export const setSectionDrawer = (data) => ({
  type: types.MANAGER_BILLING_SET_SECTION_DRAWER,
  payload: data,
});

export const updateAccountUserQuantityRequest = () => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_REQUEST,
});
export const updateAccountUserQuantityError = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_ERROR,
  payload: data,
});
export const updateAccountUserQuantitySuccess = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_FETCH_SUCCESS,
  payload: data,
});
export const updateAccountUserQuantityTemp = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_TEMP,
  payload: data,
});
export const updateAccountUserQuantitySolicitationRequest = () => ({
  type:
    types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_REQUEST,
});
export const updateAccountUserQuantitySolicitationError = (data) => ({
  type:
    types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_ERROR,
  payload: data,
});
export const updateAccountUserQuantitySolicitationSuccess = (data) => ({
  type:
    types.MANAGER_BILLING_UPDATE_ACCOUNT_USER_QUANTITY_SOLICITATION_FETCH_SUCCESS,
  payload: data,
});

export const updateAccountPackageRequest = () => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_REQUEST,
});
export const updateAccountPackageError = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_ERROR,
  payload: data,
});
export const updateAccountPackageSuccess = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_ACCOUNT_PACKAGE_FETCH_SUCCESS,
});

export const getPlansModelFetchRequest = () => ({
  type: types.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_REQUEST,
});
export const getPlansModelFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_SUCCESS,
  payload: data,
});
export const getPlansModelFetchError = (data) => ({
  type: types.MANAGER_BILLING_GET_PLANS_MODEL_FETCH_ERROR,
  payload: data,
});

export const checkCepInfoFetchRequest = () => ({
  type: types.MANAGER_BILLING_CHECK_CEP_FETCH_REQUEST,
});
export const checkCepInfoFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_CHECK_CEP_FETCH_SUCCESS,
  payload: data,
});
export const checkCepInfoFetchError = (data) => ({
  type: types.MANAGER_BILLING_CHECK_CEP_FETCH_ERROR,
  payload: data,
});

export const addCardFetchRequest = () => ({
  type: types.MANAGER_BILLING_ADD_CARD_FETCH_REQUEST,
});
export const addCardFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_ADD_CARD_FETCH_SUCCESS,
  payload: data,
});
export const addCardFetchError = (data) => ({
  type: types.MANAGER_BILLING_ADD_CARD_FETCH_ERROR,
  payload: data,
});

export const loadPaymentHistoryFetchRequest = () => ({
  type: types.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_REQUEST,
});
export const loadPaymentHistoryFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_SUCCESS,
  payload: data,
});
export const loadPaymentHistoryFetchError = (data) => ({
  type: types.MANAGER_BILLING_LOAD_PAYMENT_HISTORY_FETCH_ERROR,
  payload: data,
});

export const loadBillingInfoFetchRequest = () => ({
  type: types.MANAGER_BILLING_LOAD_INFO_FETCH_REQUEST,
});
export const loadBillingInfoFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_LOAD_INFO_FETCH_SUCCESS,
  payload: data,
});
export const loadBillingInfoFetchError = (data) => ({
  type: types.MANAGER_BILLING_LOAD_INFO_FETCH_ERROR,
  payload: data,
});

export const updateBillingConfigFetchRequest = () => ({
  type: types.MANAGER_BILLING_UPDATE_CONFIG_FETCH_REQUEST,
});
export const updateBillingConfigFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_CONFIG_FETCH_SUCCESS,
  payload: data,
});
export const updateBillingConfigFetchError = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_CONFIG_FETCH_ERROR,
  payload: data,
});

export const updateBillingDataFetchRequest = () => ({
  type: types.MANAGER_BILLING_UPDATE_DATA_FETCH_REQUEST,
});
export const updateBillingDataFetchSuccess = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_DATA_FETCH_SUCCESS,
  payload: data,
});
export const updateBillingDataFetchError = (data) => ({
  type: types.MANAGER_BILLING_UPDATE_DATA_FETCH_ERROR,
  payload: data,
});

export const payNowRequest = () => ({
  type: types.MANAGER_BILLING_PAY_NOW_REQUEST,
});
export const payNowSuccess = (data) => ({
  type: types.MANAGER_BILLING_PAY_NOW_SUCCESS,
  payload: data,
});
export const payNowError = (data) => ({
  type: types.MANAGER_BILLING_PAY_NOW_ERROR,
  payload: data,
});

const logError = (...args) => console.log('[ERROR]: ', ...args);

export const managerBillingContext = () => (dispatch, getState) => {
  dispatch([
    resetRegister(),
    loadAccountBillingInfo(),
    loadAvailablePackages(),
    loadAccountPaymentHistory(),
  ]);
};

export const loadAvailablePackages = () => async (dispatch, getState, api) => {
  try {
    dispatch(getPlansModelFetchRequest());
    const responsePlans = await api.plans.getPlansModel();
    dispatch(getPlansModelFetchSuccess(responsePlans.data.packages));
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({ customMsg });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      getPlansModelFetchError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const updateAccountBillingConfig = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);
  const billingConfig = selector.getNewBillingConfig(state);

  try {
    const { paymentMethodTypeId, numberInstallments, renew } = billingConfig;

    const params = {
      paymentMethodTypeId,
      numberInstallments: 1 /* renew, */,
      accountId,
    };

    dispatch(updateBillingConfigFetchRequest());

    await apiPayment.payment.updateBillingConfig({ header, params });
    dispatch([
      actionSnack.showSnackbar({
        message: 'Alteração do método de pagamento efetuada!',
        variant: 'success',
        autoHideDuration: 3500,
      }),
      dispatch(updateBillingConfigFetchSuccess()),
    ]);
  } catch (error) {
    // const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg:
        'Não foi possível salvar os dados atualizados. Tente novamente.',
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateBillingConfigFetchError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const updateAccountBillingData = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);
  const billingData = selector.getBillingData(state);

  try {
    const params = {
      ...billingData,
      accountId,
    };

    dispatch(updateBillingDataFetchRequest());

    await apiPayment.payment.updateBillingData({ header, params });

    dispatch(updateBillingDataFetchSuccess());
  } catch (error) {
    // const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg:
        'Não foi possível salvar os dados atualizados. Tente novamente.',
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateBillingDataFetchError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const getAccountBillingData = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);
  const responseData = await apiPayment.payment.getBillingData({
    accountId,
    header,
  });
  dispatch([setBillingData(responseData.data.billingData)]);
};

export const getAccountBillingConfig = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);
  const responseConfig = await apiPayment.payment.getBillingConfig({
    accountId,
    header,
  });
  dispatch([
    setBillingConfig(responseConfig.data),
    setPaymentNewMethod(responseConfig.data),
  ]);
};

export const loadAccountBillingInfo = () => async (dispatch, getState) => {
  try {
    dispatch([
      loadBillingInfoFetchRequest(),
      getAccountBillingData(),
      getAccountBillingConfig(),
    ]);
    dispatch(loadBillingInfoFetchSuccess());
  } catch (error) {
    // const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg:
        'Não foi possível buscar os dados. Atualize a tela e tente novamente.',
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      loadBillingInfoFetchError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const loadAccountPaymentHistory = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);

  try {
    dispatch(loadPaymentHistoryFetchRequest());
    const responseData = await apiPayment.payment.getPaymentHistory({
      accountId,
      header,
    });
    const isOverdue = moment
      .utc()
      .isAfter(
        moment.utc(responseData.data.nextBilling.billingDate).add(1, 'days')
      );
    const paymentHistory = [
      {
        id: null,
        paidValue: {
          next: responseData.data.nextBilling.value,
          paid: null,
          isOverdue,
        },
        paidAt: responseData.data.nextBilling.billingDate,
        paymentMethodTypeId: responseData.data.nextBilling.paymentMethodTypeId,
        invoiceId: null,
        pay: true,
        links: {
          filename: `nfe`,
          linkDownloadPDF: null,
          linkDownloadXML: null,
        },
      },
    ];
    responseData.data.paymentHistory.length > 0 &&
      responseData.data.paymentHistory.forEach((p) => {
        paymentHistory.push({
          id: p.id,
          paidValue: { paid: p.paidValue, next: null, isOverdue: false },
          paidAt: p.paidAt,
          paymentMethodTypeId: p.paymentMethodTypeId,
          pay: false,
          invoiceId: p.invoice ? p.invoice : null,
          links: p.invoice ? {
            filename: p.invoice.numero ? `nfe_${p.invoice.numero}` : '',
            linkDownloadPDF: p.invoice.linkDownloadPDF
              ? p.invoice.linkDownloadPDF
              : null,
            linkDownloadXML: p.invoice.linkDownloadXML
              ? p.invoice.linkDownloadXML
              : null,
          } : {
              filename: '',
              linkDownloadPDF: null,
            linkDownloadXML: null,
          }
        });
      });
    dispatch([
      loadPaymentHistoryFetchSuccess(),
      setPaymentHistory(paymentHistory),
    ]);
  } catch (error) {
    // const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg:
        'Não foi possível buscar os dados. Atualize a tela e tente novamente.',
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      loadPaymentHistoryFetchError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const addCreditCard = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const token = selectorLogin.getAuthToken(state);
  const billingConfig = selector.getNewBillingConfig(state);

  const {
    cardNumber,
    cvv,
    expirationMonth,
    expirationYear,
    holderName,
  } = billingConfig.card;
  const params = {
    cardNumber,
    securityCode: cvv,
    expirationMonth: Number(expirationMonth),
    expirationYear,
    holderName: holderName,
  };

  try {
    dispatch(addCardFetchRequest());
    await apiPayment.payment.addCreditCard({
      account: accountId,
      token,
      params,
    });
    dispatch(addCardFetchSuccess());

    return false;
  } catch (error) {
    const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      // customMsg: "Não foi possível buscar os dados. Atualize a tela e tente novamente."
      customMsg: userFeedback,
    });
    dispatch([actionSnack.showSnackbar(uriMessage), addCardFetchError(error)]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;

    return true;
  }
};

export const retrieveCep = () => async (
  dispatch,
  getState,
  { coreZipCode }
) => {
  const state = getState();
  const billingData = selector.getBillingData(state);

  dispatch(checkCepInfoFetchRequest());
  try {
    const { zipcode } = billingData;
    // debugger;
    const response = await coreZipCode.checkCepInformation(zipcode);
    // const response = await coreZipCode.checkCepInformation(zipcode);
    // debugger;
    dispatch(checkCepInfoFetchSuccess(response));
  } catch (error) {
    // const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: 'CEP Inexistente. Insira outro e tente novamente',
    });
    dispatch([
      actionSnack.showSnackbar(uriMessage),
      checkCepInfoFetchError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const updatePackageData = () => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);
  const packageData = selector.getPackageData(state);

  dispatch(updateAccountPackageRequest());
  try {
    await apiPayment.payment.updateAccountPackage({
      header,
      params: packageData,
      accountId,
    });

    dispatch([
      actionSnack.showSnackbar({
        message: 'O pacote foi alterado com sucesso',
        variant: 'success',
        autoHideDuration: 3500,
      }),
      dispatch(updateAccountPackageSuccess()),
    ]);
  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback /* "Não foi possível alterar o pacote. Verifique a conexão com a internet" */,
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateAccountPackageError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const payNow = () => async (dispatch, getState, { apiPayment }) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);

  dispatch(payNowRequest());
  try {
    const response = await apiPayment.payment.payNow({
      header,
      params: {
        accountId,
      },
    });
    if (response.data.paymentMethodTypeId === 2) {
      util.deleteCookie('auth', '', systemConfigs.ROOT_DOMAIN);
      dispatch([
        actionSnack.showSnackbar({
          message: 'Pagamento efetuado com sucesso',
          variant: 'success',
          autoHideDuration: 3500,
        }),
        dispatch(payNowSuccess(null)),
        (window.location = systemConfigs.AUTH_DOMAIN),
      ]);
    } else {
      dispatch([
        actionSnack.showSnackbar({
          message: 'Uma cópia do boleto foi enviada para seu e-mail',
          variant: 'success',
          autoHideDuration: 3500,
        }),
        dispatch(payNowSuccess(null)),
      ]);
      let a = document.createElement('a');
      a.href = 'data:application/pdf;base64,' + response.data.boleto.base64;
      a.download = 'core_boleto_' + moment().utc().format('MMM_YYYY');
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      //customMsg: 'Não foi possível Executar essa ação' /* "Não foi possível alterar o pacote. Verifique a conexão com a internet" */
      customMsg: userFeedback,
    });

    dispatch([actionSnack.showSnackbar(uriMessage), payNowError(error)]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const updateQuantityUserTemp = (userQty) => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);
  const usersLimit = userQty;

  dispatch(updateAccountUserQuantitySolicitationRequest());
  try {
    const response = await apiPayment.payment.updateAccountUserQuantitySolicitation(
      {
        header,
        params: { usersLimit },
        accountId,
      }
    );
    dispatch([
      updateAccountUserQuantityTemp(userQty),
      updateAccountUserQuantitySolicitationSuccess(response.data),
      actionModal.onOpen({ modal: types.MODAL_CONFIRM_CHANGE_USERS_NUMBER }),
    ]);
  } catch (error) {
    const { userFeedback } = error;

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback /* "Não foi possível alterar o pacote. Verifique a conexão com a internet" */,
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateAccountUserQuantitySolicitationError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const updateAccountUserQuantity = (params) => async (
  dispatch,
  getState,
  { apiPayment }
) => {
  const state = getState();
  const accountId = selectorLogin.getAuthBusinessAccountId(state);
  const header = selectorLogin.getHeaderConfig(state);

  dispatch(updateAccountUserQuantityRequest());
  try {
    await apiPayment.payment.updateAccountUserQuantity({
      header,
      params,
      accountId,
    });
    dispatch([
      actionSnack.showSnackbar({
        message: 'A quantidade de usuários foi alterada com sucesso',
        variant: 'success',
        autoHideDuration: 3500,
      }),
      dispatch(updateAccountUserQuantitySuccess(params.usersLimit)),
    ]);
  } catch (error) {
    const { userFeedback } = error;
    console.error('[catch]:', error.name, error.message);

    const uriMessage = util.snackBarManagerExceptionURIMessage({
      customMsg: userFeedback /* "Não foi possível alterar o pacote. Verifique a conexão com a internet" */,
    });

    dispatch([
      actionSnack.showSnackbar(uriMessage),
      updateAccountUserQuantityError(error),
    ]);

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
      // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const changeUserQuantity = (userQty) => (dispatch, getState) => {
  dispatch([setUserCount(userQty), updateAccountUserQuantity(userQty)]);
};

export const setTabBilling = (tab) => (dispatch, getState) => {
  dispatch([setTabActive(tab)]);
};
