import types from './constant';

const INITIAL_STATE = {
  dialogOpen          : false,
  dialogOpenedTrigger : false,
  fieldsIds           : [],
  badRefFieldsIds     : [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SCORES_FIELD_REFERENCE_DATA_SET_STATE: {
      return {
        ...state,
        dialogOpen: action.payload.active,
        fieldsIds: action.payload.scoresRefData
      };
    }
    case types.SCORES_FIELD_REFERENCE_DATA_TRIGGER_SET_STATE: {
      return {
        ...state,
        dialogOpenedTrigger: action.payload
      };
    }
    case types.SCORES_FIELD_REFERENCE_DATA_FIELDIDS_SET_IDS: {
      return {
        ...state,
        badRefFieldsIds: action.payload
      };
    }
    default:
      return state;
  }
};
