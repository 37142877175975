// import initialState from './initialState';
import types from './constant';

const INITIAL_STATE = {
  dialogOpen: false,
  diff: '',
  isAvailable: false,
  data: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.FIELD_MODEL_DIALOG_SET_STATE: {
      return {
        ...state,
        dialogOpen: action.payload.dialogOpen,
        diff: action.payload.diff,
        isAvailable: action.payload.isAvailable,
        data: action.payload.data,
      };
    }
    default:
      return state;
  }
};
