export default ({
  isFirstLoading: true,
  fetching: {
    getListSections: false,
    listFormModels: false,
    saveSection: false,
    removingSection: false
  },
  inputValue: '',
  inputNumberValue: null,
  api: {
    listPrivate: [],
    listSharing: [],
    modalOpen: false,
    accountMedicalRecordsModel: {
      medicalRecordsModelId: 0,
      accountMedicalRecordsModelId: 0,
      medicalRecordsModelSectionId: 0,
      accountId: 0,
      userIdCreated: 0,
      listSections: [],
      enableEditSection: false
    }
  }
});