import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reduxMiddleware from 'react-block-ui/lib/reduxMiddleware';
import reduxPromiseMiddleware from 'redux-promise-middleware';

import * as api from '@App/services';
import createRootReducer from '@App/reducers';

export const history = createBrowserHistory();

const rootStore = () => {
  if (process.env.NODE_ENV !== 'production') {
    const store = configureStore({
      reducer: createRootReducer(history),
      middleware: [
        multi,
        thunk.withExtraArgument(api),
        reduxMiddleware,
        reduxPromiseMiddleware(),
        routerMiddleware(history),
      ],
    });

    if (module.hot) {
      module.hot.accept('@App/reducers', () => {
        store.replaceReducer(createRootReducer(history));
      });
    }

    return store;
  } else {
    const store = configureStore({
      reducer: createRootReducer(history),
      middleware: [
        multi,
        thunk.withExtraArgument(api),
        reduxMiddleware,
        reduxPromiseMiddleware(),
        routerMiddleware(history),
      ],
    });

    return store;
  }
};

export default rootStore();
