import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SignInPage from '@Pages/login/signIn';
import AutoLogin from '@Pages/login/autoLogin';
import RegisterPage from '@Pages/login/register';
import RecoverPage from '@Pages/login/recover';
import PasswordPage from '@Pages/login/password';
import NoPermissionDialog from '@Common/NoPermissionDialog';
import FieldModelDialog from '@Common/FieldModelDialog';
import SharedDocument from '@Pages/documentShared'

import LazyRouteName from '@Common/lazyRouteName';
import LazyRouteProtectedName from '@Common/lazyRouteProtectedName';
import Messages from '@Common/messages';
import SnackBar from '@Common/snackBar';
//import Intercom                  from 'react-intercom';
//import { sha256 }                   from 'js-sha256';

import * as routes from '@App/constants/router';

import 'rc-table/assets/index.css';
import 'rc-pagination/assets/index.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';
import 'react-image-lightbox/style.css';
import '@Assets/css/app.css';
import systemConfigs from '@Constants/systemConfigs';
import ReactGA from 'react-ga';

class App extends React.Component {
  componentDidMount() {
    this.props.history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });
  }

  render() {
    return (
      <Fragment>
        <Switch>
          {/* <Route exact path={`${systemConfigs.AUTH_DOMAIN}`} component={SignInPage} /> */}
          <Route exact path={`/`} component={SignInPage} />
          {/* <Route path={`/${routes.REGISTER}`} component={RegisterPage} /> */}
          <Route exact path={`/${routes.RECOVER}`} component={RecoverPage} />
          <Route path={`/${routes.RECOVER}/:token`} component={PasswordPage} />
          <Route path={`/${routes.AUTOLOGIN}/:token`} component={AutoLogin} />
          {/* <LazyRouteProtectedName
            path={`/${routes.PURCHASE}`}
            component={() => import('@Pages/purchase')}
          /> */}
          <LazyRouteName
            path={`/${routes.DOCUMENT}/:sharedId`}
            component={() => import('@Pages/documentShared')} />
          <LazyRouteName
            path={`/${routes.FORMS}/${routes.ANSWER}/:attendanceAnswerId`}
            component={() => import('@Pages/attendanceAnswerPatient')} />
          <LazyRouteName
            path={`/${routes.SIGNATURE}/:signatoryId`}
            component={() => import('@Pages/signature')} />
          <LazyRouteName
            path={`/${routes.CHECK}/${routes.AUTHENTICITY}`}
            component={() => import('@Pages/authenticityCheck')} />
          <LazyRouteProtectedName
            path={`/${routes.STARTED}`}
            component={() => import('@Pages/started')}
          />
          <LazyRouteProtectedName
            path={`/${routes.ROUTE_BASIC}`}
            component={() => import('@Pages/dashboard')}
            drawer={'default'}
          />
          <LazyRouteProtectedName
            path={`/${routes.MANAGER}`}
            component={() => import('@Pages/managerBusiness')}
            drawer={'manager'}
          />
          <LazyRouteName
            path={`/${routes.INVITE}/:token`}
            component={() => import('@Pages/invite')}
          />
          <Route
            // render={props => (window.location = systemConfigs.AUTH_DOMAIN)}
            render={(props) => (
              <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
              />
            )}
          />
        </Switch>
        <NoPermissionDialog />
        <FieldModelDialog />
        <Messages />
        <SnackBar />
      </Fragment>
    );
  }
}

export default App;
