/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from './interceptor';
import { get } from './util';


export const getTokenICD = async ({header}) => {
  const pathUrl = `${endpoints.API_FORMS}/cid/token`;

  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

