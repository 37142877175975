export default ({
    filters: [],
    patientsFilter: [],
    fetchGetPatientsFilter: false,
    fetchSaveFilter: false,
    fetchGetListFilters: false,
    fetchDeleteFilter: false,
    fetchListCoreLineMedicalRecords: false,
    fetchDataByPatientIdsCorelines: false,
    fetchDataGroupPatients: false,
    fetchlistIndicators: false,
    listCoreLinesMedicalRecords: [],
    filter: {},
    listFormModelsManager: [],
    listFormModels: [],
    columnsDisplay: {
        name: true,
        age: true,
        lastAttendance: true,
        coreline: true,
        attendances: true,
        statusCoreline: true,
    },
    fieldsSelected: [],
    searchFields: [],
    fetchSearchFields: false,
    fieldModelSelected: {},
    fetchGetFieldModelById: false,
    fetchSearchProfessionals: false,
    fetchSearchExercises: false,
    fieldOptions: [],
    dataByPatientIds: [],
    fetchDataByPatientIds: false,
    dataByPatientIdsCorelines: [],
    corelinesFilter: [],
    qualifierFields: {},
    qualifiers: [],
    indicators: [],
    indicatorHistory: {},
    periodMonths: {},
    objectQualifiers: {},
    dataGroupPatients: {},
    drawerTableOpen: false,
    historyDataTable: [],
});
