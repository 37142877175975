export default {
  fields: {
    fetching: {
      getFields: false,
      getFieldModelById: false,
    },
    api: {
      tableFields: [],
    },
  },
  exercises: {
    fetching: {
      getExercises: false,
      getExerciseById: false,
    },
    api: {
      exercises: [],
      exercise: {},
    },
  },
  exercisesCategories: {
    fetching: {
      getExercisesCategories: false,
      getExercisesCategoriesById: false,
    },
    api: {
      categories: [],
      category: {},
    },
  },
};
