import types from './constant';

const INITIAL_STATE = ({});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.CARD_STATE_SET_BY_ID_INITIAL: {
      const {idCard, done} = action.payload;
      return ({
        ...state,
        [idCard]: {
          idCard,
          done
        }
      });
    }
    case types.CARD_STATE_TOGGLE_BY_ID: {
      const {idCard} = action.payload;
      return ({
        ...state,
        [idCard]: {
          ...state[idCard],
          done: !state[idCard].done
        }
      });
    }
    case types.CARD_STATE_AUTO_HIDE: {
      const newCards = action.payload;
      return ({
        ...state,
        ...newCards
      });
    }
    default:
      return state;
  }
};