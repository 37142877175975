import React      from 'react';
import { Route }  from 'react-router-dom';
import Loadable   from 'react-loadable';

import AppLoading from '@Common/appAsyncLoading';

import enhanceRouter from '@App/hoc/enhanceRouter';

const asyncComponent = Component => {
  return Loadable({
    loader  : Component,
    loading : AppLoading,
    delay: 1000,
    timeout: 10000
  });
};

const lazyRouteName = ({ component: Component, ...rest }) => {
  return <Route {...rest} component={ enhanceRouter({...rest})(asyncComponent(Component)) } />
};

lazyRouteName.defaultProps = {
  name      : '',
  nameDrawer: '',
  propsRoot : {}
};

export default lazyRouteName;