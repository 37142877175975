export default ({
  SECTION : 'overview',

  MEDICALRECORD_OVERVIEW_GET_SEARCH_CLIENT_FETCH_REQUEST : 'MEDICALRECORD_OVERVIEW_GET_SEARCH_CLIENT_FETCH_REQUEST',
  MEDICALRECORD_OVERVIEW_GET_SEARCH_CLIENT_FETCH_SUCCESS : 'MEDICALRECORD_OVERVIEW_GET_SEARCH_CLIENT_FETCH_SUCCESS',
  MEDICALRECORD_OVERVIEW_GET_SEARCH_CLIENT_FETCH_ERROR   : 'MEDICALRECORD_OVERVIEW_GET_SEARCH_CLIENT_FETCH_ERROR',

  MEDICALRECORD_OVERVIEW_SET_RESET_SEARCH_CLIENT : 'MEDICALRECORD_OVERVIEW_SET_RESET_SEARCH_CLIENT',

  MEDICALRECORD_OVERVIEW_SET_RESET_STATE : 'MEDICALRECORD_OVERVIEW_SET_RESET_STATE',
});