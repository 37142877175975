export default {
  REACT_APP_SYSTEM_MAX_LIMIT_ACCOUNTS:
    process.env.REACT_APP_SYSTEM_MAX_LIMIT_ACCOUNTS,
  ROOT_DOMAIN: process.env.REACT_APP_ROOT_DOMAIN,
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  SERVICES_URL: process.env.REACT_APP_SERVICES_URL,
  SCHEDULES_URL: process.env.REACT_APP_SCHEDULES_URL,
  REACT_APP_FORMS_TIMEOUT_SAVE_MILLISECONDS_TIME: process.env.REACT_APP_FORMS_TIMEOUT_SAVE_MILLISECONDS_TIME,
  POINTS_DECIMAL_SEPARATOR: process.env.REACT_APP_POINTS_DECIMAL_SEPARATOR,
  REACT_APP_SYSTEM_MAX_LIMIT_SIZE_UPLOAD_ATTACHMENT_MB: process.env.REACT_APP_SYSTEM_MAX_LIMIT_SIZE_UPLOAD_ATTACHMENT_MB || 25,
  REACT_APP_URL_DOCUMENT_SHARED: process.env.REACT_APP_URL_DOCUMENT_SHARED,
};
