export default ({
  fetching: {
    createPrescriptionModel: false,
    updatePrescriptionModel: false,
    listPrescriptionModel: false,
    getPrescriptionModel: false
  },
  api: {
    prescriptionList: [],
    prescriptionModel: {
      prescriptionModelId: null,
      name: '',
      content: ''
    }
  },
  forms: {
    modal: {
      name: {
        value: '',
        error: false
      },
      description: {
        value: '',
        error: false
      }
    }
  }
});