import map from 'lodash/map';
import compact from 'lodash/compact';

export const CONFIGURATION_MEDICALRECORD_SECTIONS_GET_ACCOUNT_CONFIG_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          getListSections: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_GET_ACCOUNT_CONFIG_FETCH_SUCCESS = (state, action) => {
  const { dataFetch } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          getListSections: false
        },
        api: {
          ...state.medicalRecord.sections.api,
          accountMedicalRecordsModel: dataFetch
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_GET_ACCOUNT_CONFIG_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          getListSections: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_GET_LIST_FORM_MODELS_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          listFormModels: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_GET_LIST_FORM_MODELS_FETCH_SUCCESS = (state, action) => {
  const {listPrivate, listSharing} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          listFormModels: false
        },
        api: {
          ...state.medicalRecord.sections.api,
          listPrivate,
          listSharing
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_GET_LIST_FORM_MODELS_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          listFormModels: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_DELETE_SECTION_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
      medicalRecord: {
        ...state.medicalRecord,
        sections: {
          ...state.medicalRecord.sections,
          fetching: {
            ...state.medicalRecord.sections.fetching,
            removingSection: true
          }
        }
      }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_DELETE_SECTION_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
      medicalRecord: {
        ...state.medicalRecord,
        sections: {
          ...state.medicalRecord.sections,
          fetching: {
            ...state.medicalRecord.sections.fetching,
            removingSection: false
          }
        }
      }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_DELETE_SECTION_FETCH_SUCCESS = (state, action) => {
  const {medicalRecordsModelSectionId} = action.payload;
  return ({
    ...state,
      medicalRecord: {
        ...state.medicalRecord,
        sections: {
          ...state.medicalRecord.sections,
          fetching: {
            ...state.medicalRecord.sections.fetching,
            removingSection: false
          },
          api: {
            ...state.medicalRecord.sections.api,
            accountMedicalRecordsModel: {
              ...state.medicalRecord.sections.api.accountMedicalRecordsModel,
              listSections: compact(map(state.medicalRecord.sections.api.accountMedicalRecordsModel.listSections, listItem => {
                if(listItem.medicalRecordsModelSectionId !== medicalRecordsModelSectionId) return listItem
              }))
            }
          }
        }
      }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SET_TRANSFER_SHARING = (state, action) => {
  const {listSharing} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        api: {
          ...state.medicalRecord.sections.api,
          listSharing
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_MODAL_CONFIGURATION_MEDICALRECORD_SECTIONS_RESET = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        inputValue: '',
        api: {
          ...state.medicalRecord.sections.api,
          listSharing: [],
          listPrivate: []
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SET_TITLE = (state, action) => {
  const {inputValue} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        inputValue
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SET_ORDER = (state, action) => {
  const {inputOrderValue} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        inputOrderValue
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SAVE_SECTION_ACCOUNT_CONFIG_FETCH_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          saveSection: true
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SAVE_SECTION_ACCOUNT_CONFIG_FETCH_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          saveSection: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SAVE_SECTION_ACCOUNT_CONFIG_FETCH_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        fetching: {
          ...state.medicalRecord.sections.fetching,
          saveSection: false
        }
      }
    }
  });
};

export const CONFIGURATION_MEDICALRECORD_SECTIONS_SET_MEDICALRECORDS_MODEL_SECTION_BY_ID = (state, action) => {
  const {medicalRecordsModelSectionId} = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      sections: {
        ...state.medicalRecord.sections,
        api: {
          ...state.medicalRecord.sections.api,
          accountMedicalRecordsModel: {
            ...state.medicalRecord.sections.api.accountMedicalRecordsModel,
            medicalRecordsModelSectionId
          }
        }
      }
    }
  });
};