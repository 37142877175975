import reportsInitialState from './Reports/medicalRecords/config/initialState';
import * as commonReducerReports from './Reports/medicalRecords/config/reducerGenerator';



const INITIAL_STATE = {
  ...reportsInitialState
};

export default (state = INITIAL_STATE, action) => {
  if (commonReducerReports[action.type]) {
    return commonReducerReports[action.type](state, action);
  } else {
    return state;
  }
};
