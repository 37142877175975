import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const CORELINE = 'coreline';
const ATTENDANCE = 'attendance';
const LIST = 'list';
const OPEN = 'open';

//#region GET

export const getCoreLineAttendanceByPatientId = async ({ header, patientId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${ATTENDANCE}/${CORELINE}/${LIST}/${patientId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getOpenCoreLineAttendance = async ({ header, patientId }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${ATTENDANCE}/${CORELINE}/${LIST}/${OPEN}/${patientId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};


//#region POST
export const openCoreLineAttendance = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${ATTENDANCE}/${CORELINE}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateOpenCoreLine = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${ATTENDANCE}/${CORELINE}`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE

export const deleteCoreLineAttendance = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${ATTENDANCE}/${CORELINE}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};

//#endregion
