import initialState           from './config/initialState';
import * as reducerGenerator  from './config/reducerGenerator';

const INITIAL_STATE = ({
  ...initialState,
});

export default (state = INITIAL_STATE, action) => {
  if (reducerGenerator[action.type])
    return reducerGenerator[action.type](state, action);
  else
    return state;
};