export const BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_REQUEST = (section, state, action) => ({
  ...state,
    fetching: {
      ...state.fetching,
      importLocusprimePatient: true,
    }
});

export const BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_ERROR = (section, state, action) => ({
  ...state,
    fetching: {
      ...state.fetching,
      importLocusprimePatient: false,
    }
});

export const BUSINESS_INTEGRATION_IMPORT_LOCUSPRIME_PATIENTS_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
    fetching: {
      ...state.fetching,
      importLocusprimePatient: false,
    },
    importingLocusprimePatients: true
});


export const BUSINESS_INTEGRATION_SET_IMPORT_LOCUSPRIME_PATIENTS_TOKEN = (section, state, action) => ({
  ...state,
    importLocusprimePatientsToken: action.payload
});