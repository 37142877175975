export default ({
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_PRESCRIPTION_CREATE : 'modal:perfil:configuration:medicalRecord:prescription:create',
  MODAL_PERFIL_CONFIGURATION_MEDICALRECORD_PRESCRIPTION_UPDATE : 'modal:perfil:configuration:medicalRecord:prescription:update',
  SET_PRESCRIPTION_NAME: 'SET_PRESCRIPTION_NAME',
  SET_PRESCRIPTION_DESCRIPTION: 'SET_PRESCRIPTION_DESCRIPTION',
  LIST_PRESCRIPTIONS_FETCH_REQUEST: 'LIST_PRESCRIPTIONS_FETCH_REQUEST',
  LIST_PRESCRIPTIONS_FETCH_ERROR: 'LIST_PRESCRIPTIONS_FETCH_ERROR',
  LIST_PRESCRIPTIONS_FETCH_SUCCESS: 'LIST_PRESCRIPTIONS_FETCH_SUCCESS',
  CREATE_PRESCRIPTION_FETCH_REQUEST: 'CREATE_PRESCRIPTION_FETCH_REQUEST',
  CREATE_PRESCRIPTION_FETCH_ERROR: 'CREATE_PRESCRIPTION_FETCH_ERROR',
  CREATE_PRESCRIPTION_FETCH_SUCCESS: 'CREATE_PRESCRIPTION_FETCH_SUCCESS',
  UPDATE_PRESCRIPTION_FETCH_REQUEST: 'UPDATE_PRESCRIPTION_FETCH_REQUEST',
  UPDATE_PRESCRIPTION_FETCH_ERROR: 'UPDATE_PRESCRIPTION_FETCH_ERROR',
  UPDATE_PRESCRIPTION_FETCH_SUCCESS: 'UPDATE_PRESCRIPTION_FETCH_SUCCESS',
  GET_PRESCRIPTION_FETCH_REQUEST: 'GET_PRESCRIPTION_FETCH_REQUEST',
  GET_PRESCRIPTION_FETCH_ERROR: 'GET_PRESCRIPTION_FETCH_ERROR',
  GET_PRESCRIPTION_FETCH_SUCCESS: 'GET_PRESCRIPTION_FETCH_SUCCESS',
  PUSH_NEW_PRESCRIPTION_TO_LIST: 'PUSH_NEW_PRESCRIPTION_TO_LIST',
  RESET_PRESCRIPTION_FORM: 'RESET_PRESCRIPTION_FORM',
  UPDATE_ITEM_ON_LIST: 'UPDATE_ITEM_ON_LIST'
});