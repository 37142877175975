import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';
import { get } from '../util';
import { post } from '../util';
import { put } from '../util';
import { del } from '../util';

const FIELD = 'field';
const RADIO = 'radio';
const CHECK = 'checkbox';
const MODEL = 'model';
const GROUP = 'group';
const VERSION = 'version';
const INFO_LABEL = 'infolabel';
const HISTORY = 'history';
const NUMERIC = 'numeric';
const SELECT = 'select';
const SCORES = 'scores';
const PATIENT = 'patient';
const CHECKBOX_GROUP = 'checkbox_group';
const CHECK_ONLY = 'check_only';
const CHECK_AVALIABILITY = 'availability';
const EXERCISES = 'exercises';
const MANAGER = 'manager';
const CATEGORY = 'category';
const SEARCH = 'search';
const FILTER = 'filter';
const INDICATOR = 'indicator';
const ATTENDANCE = 'attendance';
const PAIN_MAP_BODY = 'pain_map_body';
const MARKS = 'marks';
const ALL = 'all';


//#region GET
export const getFields = async ({ header, listType = 'all' }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}?listType=${listType}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const searchFields = async ({ header, search, isFieldHistory }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${SEARCH}?search=${search}&isFieldHistory=${isFieldHistory ? '1' : '0'}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getExercises = async ({ header, value, status }) => {
  let query = value && status ? `?value=${value}&status=${status}` : ''
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getExerciseById = async ({ id, header }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}/${id}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getExercisesCategories = async ({ header }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}/${CATEGORY}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelById = async ({ header, idVersionField }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${VERSION}/${idVersionField}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryNumeric = async ({
  header,
  fieldModelId,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `?${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${NUMERIC}/${PATIENT}/${patientId}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryMarks = async ({
  header,
  fieldModelId,
  idMark,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `&${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${MARKS}/${PATIENT}/${patientId}?idMark=${idMark}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryAllMarks = async ({
  header,
  fieldModelId,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `?${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${ALL}/${MARKS}/${PATIENT}/${patientId}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryExerciseNumeric = async ({
  header,
  fieldExerciseName,
  patientId,
  exerciseId
}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldExerciseName}/${HISTORY}/${EXERCISES}/${exerciseId}/${NUMERIC}/${PATIENT}/${patientId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryExerciseSelect = async ({
  header,
  fieldExerciseName,
  patientId,
  exerciseId
}) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldExerciseName}/${HISTORY}/${EXERCISES}/${exerciseId}/${SELECT}/${PATIENT}/${patientId}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryScores = async ({
  header,
  fieldModelId,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `?${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${SCORES}/${PATIENT}/${patientId}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryRadioGroup = async ({
  header,
  fieldModelId,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `?${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${RADIO}/${PATIENT}/${patientId}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryCheckboxGroup = async ({
  header,
  fieldModelId,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `?${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${CHECKBOX_GROUP}/${PATIENT}/${patientId}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFieldModelByIdHistoryCheckOnly = async ({
  header,
  fieldModelId,
  patientId,
  dateStart,
  dateEnd,
}) => {
  let query = dateStart || dateEnd ? `?${dateStart ? `dateStart=${dateStart}` : ''}${dateStart && dateEnd ? '&' : ''}${dateEnd ? `dateEnd=${dateEnd}` : ''}` : '';
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${fieldModelId}/${HISTORY}/${CHECK_ONLY}/${PATIENT}/${patientId}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getPainMapBody = async ({ header, params }) => {
  let query = `?formId=${params.formId}&fieldModelId=${params.fieldModelId}`;
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${PAIN_MAP_BODY}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getPainMapBodyAreaHistory = async ({ header, params }) => {
  let query = `?patientId=${params.patientId}&fieldModelId=${params.fieldModelId}`;
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${PAIN_MAP_BODY}/${HISTORY}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getMarkField = async ({ header, params }) => {
  let query = `?fieldModelId=${params.fieldModelId}&patientId=${params.patientId}`;
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${MARKS}${query}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};


//#endregion

//#region POST

export const getDataByPatientIds = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${FILTER}/${HISTORY}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const getHistoryFieldsByForm = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${FILTER}/${HISTORY}/${ATTENDANCE}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const getIndicatorByPatientIds = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${FILTER}/${INDICATOR}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveField = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveFieldModelVersion = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${VERSION}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveFieldInfo = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${INFO_LABEL}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveRadioGroup = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${RADIO}/${GROUP}/${MODEL}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveCheckboxGroup = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${CHECK}/${GROUP}/${MODEL}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveExercise = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const saveExerciseCategory = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}/${CATEGORY}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const checkFieldModelAvailability = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}/${CHECK_AVALIABILITY}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

//#endregion

//#region PUT
export const putFieldModelById = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${FIELD}/${MODEL}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const updateExercise = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};
export const updateExerciseCategory = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}/${CATEGORY}`;
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#endregion

//#region DELETE
export const deleteExerciseCategory = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_FORMS}/${EXERCISES}/${CATEGORY}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};

//#endregion
