export default ({
  isFirstLoading: true,
  error: {
    done: false,
    messages: []
  },
  fetching: {
    getById: false,
    savePerfil: false,
    saveNewAvatar: false,
    checkingCep: false,
    isUpdatingPassword: false
  },
  api: {
    searchedCep: null,
    perfil: {
      general: {
        id: 0,
        cpf: 0,
        sex: 0,
        name: '',
        email: '',
        birthdate: '',
        cel: '',
        tel: '',
        avatar: '',
      },
      address: {
        cep: 0,
        stateId: 0,
        cityId: 0,
        state: 0,
        city: 0,
        complement: 0,
        numberAddress: 0,
        neighborhood: '',
        address: '',
      }
    }
  }
});