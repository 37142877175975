/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from './interceptor';

// const _axios          = interceptor();
const PROFESSIONAL = 'professional';
const USER = 'user';

export const postProfessional = async ({ account, workspace, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PROFESSIONAL}`;

  try {
    const _axios = interceptor({ account, workspace });
    const response = await _axios.post(pathUrl, params);

    return response.data.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};

export const putProfessional = async ({ account, workspace, params }) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PROFESSIONAL}`;

  try {
    const _axios = interceptor({ account, workspace });
    const response = await _axios.put(pathUrl, params);

    return response.data.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};

export const getProfessionalById = async ({
  account,
  professionalId,
  workspace,
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PROFESSIONAL}/${professionalId}`;
  // const params = { workspace };

  try {
    const _axios = interceptor({ account, workspace });
    let response = await _axios.get(pathUrl);

    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};

export const getProfessionalByUserId = async ({
  account,
  userId,
  workspace,
}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PROFESSIONAL}/${USER}/${userId}`;

  try {
    const _axios = interceptor({ account, workspace });
    let response = await _axios.get(pathUrl);

    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback,
      };
    throw error;
  }
};
