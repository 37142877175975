/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor    from './interceptor';

// const _axios          = interceptor();
const PERMISSION      = 'permission';
const ACCOUNT         = 'account';
const WORKSPACE       = 'workspace';
const PROFILE         = 'profile';
const PROFILES        = `${PROFILE}s`;
const FUNCTIONALITIES = 'functionalities';

export const getAccountProfiles = async account => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${PROFILES}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.get(pathUrl);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getAccountFunctionalities = async account => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${FUNCTIONALITIES}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.get(pathUrl);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getAccountProfileById = async idProfile => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${PROFILE}/${idProfile}`;

  try {
    const _axios    = interceptor();
    const response  = await _axios.get(pathUrl);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const postAccountProfile = async ({account, params}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${PROFILE}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.post(pathUrl, params);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const putAccountProfile = async ({account, params}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${PROFILE}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.put(pathUrl, params);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getWorkspaceProfiles = async (account, workspace) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${WORKSPACE}/${PROFILES}`;

  try {
    if (!workspace) {
      const _axios    = interceptor({account});
      const response  = await _axios.get(pathUrl);
  
      return response.data.data;
    }
    else {
      const _axios    = interceptor({workspace});
      const response  = await _axios.get(pathUrl);
  
      return response.data.data;
    }
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getWorkspaceFunctionalities = async account => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${WORKSPACE}/${FUNCTIONALITIES}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.get(pathUrl);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getWorkspaceProfileById = async idProfile => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${WORKSPACE}/${PROFILE}/${idProfile}`;

  try {
    const _axios    = interceptor();
    const response  = await _axios.get(pathUrl);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const postWorkspaceProfile = async ({account, params}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${WORKSPACE}/${PROFILE}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.post(pathUrl, params);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const putWorkspaceProfile = async ({account, params}) => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${WORKSPACE}/${PROFILE}`;

  try {
    const _axios    = interceptor({account});
    const response  = await _axios.put(pathUrl, params);

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const deleteAccountProfileById = async props => {
  const {accountId, deletedAccountProfileId, replaceAccountProfileId} = props;
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${accountId}/${PROFILE}`;

  try {
    const _axios    = interceptor();

    const response  = await _axios.delete(pathUrl, {data: {deletedAccountProfileId, replaceAccountProfileId}});

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const deleteWorkProfileById = async props => {
  const {accountId, deletedWorkspaceProfileId, replaceWorkspaceProfileId} = props;
  const pathUrl = `${endpoints.API_ACCOUNT}/${PERMISSION}/${ACCOUNT}/${accountId}/${WORKSPACE}/${PROFILE}`;

  try {
    const _axios    = interceptor();
    const response  = await _axios.delete(pathUrl, {data: {deletedWorkspaceProfileId, replaceWorkspaceProfileId}});

    return response.data.data;
  }
  catch (error) {
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};