

export const SET_TOGGLE_SIDEBAR = (state) => {
    return ({
        ...state,
        sidebar: {
            ...state.sidebar,
            isOpen: !state.sidebar.isOpen
        }
    });
};
export const SET_SIDEBAR_BUTTON_SELECT = (state, action) => {
    return ({
        ...state,
        sidebar: {
            isOpen: state.sidebar.isOpen,
            ...action.payload
        }
    });
};
export const SET_TOGGLE_EDIT_HEADER = (state, action) => {
    return ({
        ...state,
        header: {
            ...action.payload
        }
    });
};
export const SET_UPDATE_DOCUMENT_REQUEST = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            document: {...action.payload}
        }
    });
};

export const SET_URL_PDF_DOCUMENT = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            urlPdf: action.payload
        }
    });

}

export const SET_DOC_MODEL_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModel: action.payload
        }
    });
};

export const SET_DOC_MESSAGE_SAVE = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            modelSave: {
                message: action.payload.message,
                status: action.payload.status
            }
        }
    });
};


export const SET_TITLE_PAGE_HISTORY = (state, action) => {
    return ({
        ...state,
        historyPage: [
            action.payload
        ]
    });
};
export const ADD_PAGES_DOCUMENT_WORKSPACE = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            pages: {
                ...state.api.pages,
                ...action.payload
            }
        }
    });
};

export const ADD_PAGES_MODELS_DOCUMENT = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            pagesModels: {
                ...state.api.pagesModels,
                ...action.payload
            }
        }
    });
};

export const SET_PAGE_HISTORY = (state, action) => {
    return ({
        ...state,
        historyPage: action.payload
    });
};
export const SET_CHANGE_PAGE = (state, action) => {
    return ({
        ...state,
        changePage: action.payload
    });
};
export const SET_USER_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            userInfo: action.payload
        }
    });
};

export const SET_CREATE_DOC_MODEL_REQUEST = (state) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            createDocModel: true
        }
    });
};

export const SET_CREATE_DOC_MODEL_SUCCESS = (state) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            createDocModel: false
        }
    });
};

export const SET_UPDATE_DOC_MODEL_BY_ID = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModel: action.payload
        }
    });
};

export const SET_DOCS_MODELS_REQUEST = (state, action) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            listDocModel: true
        }
    });
};

export const SET_DOCS_MODELS_SUCCES_PUBLISHED = (state, action) => {
    return ({
        ...state,
        fetching: {
            ...state.fetching,
            listDocModel: false
        },
        api: {
            ...state.api,
            docModelList: action.payload
        }
    });
};

export const SET_DOCS_MODELS_FILTER = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docModelListFilter: action.payload
        }
    });
};

export const SET_DOCUMENTS_FILTER = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            documentsListFilter: action.payload
        }
    });
};

export const SET_FOLDER_SELECTED = (state, action) => {
    return ({
        ...state,
        folderSelected: action.payload
    });
};

export const DOCUMENT_GET_CLASSIFICATION_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CLASSIFICATION_FETCH_ERROR =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const SET_CLASSIFICATION_SUCCESS =
    (state, action) => {
        const { classification } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    classification
                }
            }
        }
    };
export const DOCUMENT_SAVE_CLASSIFICATION_FETCH =
    (state, action) => {
        const { status } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    fetching: {
                        ...state.api.classification.fetching,
                        saveClassification: status
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CLASSIFICATION_FETCH_SUCCESS =
    (state, action) => {
        const { type, classification } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classification: {
                    ...state.api.classification,
                    [type]: classification.classifications,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const GET_CLASSIFICATION_FETCH_SUCCESS =
    (state, action) => {
        const { type, classifications } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classificationsListType: {
                    ...state.api.classificationsListType,
                    [type]: classifications,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const GET_CLASSIFICATION_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classificationsListType: {
                    ...state.api.classificationsListType,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: true
                    }
                }
            }
        }
    };

export const GET_CLASSIFICATION_FETCH_ERROR =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                classificationsListType: {
                    ...state.api.classificationsListType,
                    fetching: {
                        ...state.api.classification.fetching,
                        getClassification: false
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_CLASSIFICATION_GROUP_FETCH_SUCCESS =
    (state, action) => {
        const { classificationsGroup } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                classificationsGroup: {
                    ...classificationsGroup
                }
            }
        }
    };

export const SET_PROPERTY_FOLDER_DOC =
    (state, action) => {
        const { folderProperty } = action.payload;
        return {
            ...state,
            modal: {
                ...state.modal,
                folderProperty: {
                    ...state.modal.folderProperty,
                    ...folderProperty,
                }
            }
        }
    };

export const DOCUMENT_SAVE_FOLDER_FETCH =
    (state, action) => {
        const { status } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    fetching: {
                        ...state.api.folder.fetching,
                        saveFolder: status
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_MODELS_FETCH_SUCCESS =
    (state, action) => {
        const { folderModels } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    folderModels,
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_MODELS_FETCH_REQUEST =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_MODELS_FETCH_ERROR =
    (state, action) => {
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: false
                    }
                }
            }
        }
    };

export const CREATE_DOCUMENT_FETCH_SUCCESS =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                createDocument: false
            }
        }
    };

export const CREATE_DOCUMENT_FETCH_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                createDocument: false
            }
        }
    };

export const CREATE_DOCUMENT_FETCH_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                createDocument: true
            }
        }
    };

export const GET_MODEL_DOCUMENT_FETCH_SUCCESS =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getModelDocument: false
            }
        }
    };

export const GET_MODEL_DOCUMENT_FETCH_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getModelDocument: false
            }
        }
    };

export const GET_MODEL_DOCUMENT_FETCH_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getModelDocument: true
            }
        }
    };

export const SET_DOCUMENT_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            document: action.payload
        }
    });
};

export const GET_DOCUMENT_FETCH_SUCCESS =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocument: false
            }
        }
    };

export const GET_DOCUMENT_FETCH_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocument: false
            }
        }
    };

export const GET_DOCUMENT_FETCH_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocument: true
            }
        }
    };

export const SET_DOCUMENTS_LIST_SUCCESS =
    (state, action) => {
        const { documentsList, draftsDocuments, documentsSignaturePending, documentsSignatureConcluded } = action.payload;
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocuments: false
            },
            api: {
                ...state.api,
                documentsList,
                draftsDocuments,
                documentsSignaturePending, 
                documentsSignatureConcluded,
            }
        }
    };
export const GET_DOCUMENTS_LIST_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocuments: true
            }
        }
    };
export const GET_DOCUMENTS_LIST_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                getDocuments: false
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_ALL_FETCH_SUCCESS =
    (state, action) => {
        const { foldersAllDocuments } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersAllDocuments,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderDocuments: true
                    }
                }
            }
        }
    };

export const DOCUMENT_GET_FOLDERS_ALL_MODELS_FETCH_SUCCESS =
    (state, action) => {
        const { foldersAllModels } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersAllModels,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };
export const DOCUMENT_GET_FOLDERS_DOCUMENTS_FETCH_SUCCESS =
    (state, action) => {
        const { foldersDocuments } = action.payload;
        return {
            ...state,
            api: {
                ...state.api,
                folder: {
                    ...state.api.folder,
                    foldersDocuments,
                    fetching: {
                        ...state.api.folder.fetching,
                        getFolderModels: true
                    }
                }
            }
        }
    };

export const DOCUMENT_MOVE_FOLDER_FETCH_REQUEST =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                moveDocumentFolder: true
            }
        }
    };

export const DOCUMENT_MOVE_FOLDER_FETCH_ERROR =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                moveDocumentFolder: false
            }
        }
    };


export const DOCUMENT_MOVE_FOLDER_FETCH_SUCCESS =
    (state) => {
        return {
            ...state,
            fetching: {
                ...state.fetching,
                moveDocumentFolder: false
            }
        }
    };

export const SET_STATE_BLOCK_FOCUS = (state, action) => {
    return ({
        ...state,
        stateBlockFocus: action.payload,
    });
}

export const SET_PATH_FOLDER = (state, action) => {
    return ({
        ...state,
        pathFolder: action.payload
    });
}

export const SET_DOC_TITLE = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            docTitle: action.payload,
        }
    });
}
export const SET_PAGE_DOCUMENT_BY_ID_SUCCESS = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            pageDoc: action.payload
        }
    });
};

export const SET_REQUEST_UPDATE_DOCUMENT = (state, action) => {
    return ({
        ...state,
        api: {
            ...state.api,
            requestUpdate: action.payload
        }
    });
};

export const GET_SEARCH_CLIENT_FETCH_REQUEST_SIGNATURE = (state) => {
    return ({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetching:{
                    ...state.signature.api.fetching,
                    getClient: true
                }
            }
        }
    });
}

export const GET_SEARCH_CLIENT_FETCH_SUCCESS_SIGNATURE = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                clients: action.payload,
                fetching:{
                    ...state.signature.api.fetching,
                    getClient: false
                }
            }
        }
    })
}

export const GET_SEARCH_CLIENT_FETCH_ERROR_SIGNATURE = (state) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetching:{
                    ...state.signature.api.fetching,
                    getClient: false
                }
            }
        }
    })
}

export const ADD_STAKEHOLDER = (state, action) => {
    const stakeholders = state.api.document.stakeholders || {[action.payload.key]:[]};
    return({
        ...state,
        api: {
            ...state.api,
            document:
              {
                ...state.api.document,
                stakeholders: {
                  ...state.api.document.stakeholders,
                    [action.payload.key]: [...stakeholders[action.payload.key], action.payload.stakeholder]
                }
              }
        }
    })
}

export const FETCH_CREATE_SIGNATURE_DOCUMENT = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetching:{
                    ...state.signature.api.fetching,
                    createSignature: action.payload
                }
            }
        }
    })
}

export const FETCH_STATUS_CREATE_SIGNATURE_DOCUMENT = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetching:{
                    ...state.signature.api.fetching,
                    statusCreateSignature: action.payload
                }
            }
        }
    })
}

export const SET_SIGNATURE_DOCUMENT = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                signatureSend: action.payload
            }
        }
    })
}

export const FETCH_GET_SIGNATURE_BY_DOCUMENT_ID = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                fetchGetSignatureByDocumentId: action.payload
            }
        }
    })
}
export const SET_SIGNATORIES_DOCUMENT = (state, action) => {
    return({
        ...state,
        signature:{
            ...state.signature,
            api:{
                ...state.signature.api,
                signatoriesSend: action.payload
            }
        }
    })
}

export const CHANGE_DRAWER_TAB_INDEX  = (state, action) => {
    return ({
        ...state,
        drawerTabIndex: action.payload
    });
};