import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const CATEGORY_MODEL = 'category';
const MANAGER = 'manager'
const DOCUMENST = 'documents';

//#region GET
export const getCategory = async ({ header }) => { 
  const pathUrl = `${endpoints.API_DOCS}/${MANAGER}/${CATEGORY_MODEL}/${DOCUMENST}`;
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};
