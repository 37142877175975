import planOverviewInitialState     from './overview/initialState';
import typesOverview                from './overview/constant';
import * as commonReducerOverview   from './overview/reducerGenerator';

const INITIAL_STATE = {
  overview : {...planOverviewInitialState},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case typesOverview.PLANS_OVERVIEW_IS_FIRST_LOADING:
      return commonReducerOverview.IS_FIRST_LOADING(typesOverview.SECTION, state, action);
    case typesOverview.PLANS_OVERVIEW_GET_INFO_PLAN_FETCH_REQUEST:
      return commonReducerOverview.GET_INFO_PLAN_FETCH_REQUEST(typesOverview.SECTION, state, action);
    case typesOverview.PLANS_OVERVIEW_GET_INFO_PLAN_FETCH_SUCCESS:
      return commonReducerOverview.GET_INFO_PLAN_FETCH_SUCCESS(typesOverview.SECTION, state, action);
    case typesOverview.PLANS_OVERVIEW_GET_INFO_PLAN_FETCH_ERROR:
      return commonReducerOverview.GET_INFO_PLAN_FETCH_ERROR(typesOverview.SECTION, state, action);
    default:
      return state;
  }
};