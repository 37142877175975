export default ({
  DRAWER_OPEN   : 'DRAWER_OPEN',
  DRAWER_CLOSED : 'DRAWER_CLOSED',
  DRAWER_TOGGLE : 'DRAWER_TOGGLE',

  MAIN_GET_STATE_CITY : 'MAIN_GET_STATE_CITY',
  
  DRAWER_SET_MANAGER_APP_BAR : 'DRAWER_SET_MANAGER_APP_BAR',

  DRAWER_RESET_APP_BAR : 'DRAWER_RESET_APP_BAR'
});