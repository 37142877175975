export default ({
  fetching: {
    createReportModel: false,
    updateReportModel: false,
    listReportModel: false,
    getReportModel: false
  },
  api: {
    reportList: [],
    reportModel: {
      reportModelId: null,
      name: '',
      content: ''
    }
  },
  forms: {
    modal: {
      name: {
        value: '',
        error: false
      },
      description: {
        value: '',
        error: false
      }
    }
  }
});