import businessInitialState         from './business/initialState';
import typesBusiness                from './business/constant';
import * as commonReducerBusiness	  from './business/reducerGenerator';

import workspaceInitialState        from './workspace/initialState';
import typesWorkspace               from './workspace/constant';
import * as commonReducerWorkspace	from './workspace/reducerGenerator';

const INITIAL_STATE = ({
  business  : {...businessInitialState},
  workspace : {...workspaceInitialState}
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region BUSINESS
    case typesBusiness.PERMISSION_BUSINESS_IS_FIRST_LOADING: return commonReducerBusiness.IS_FIRST_LOADING(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_REQUEST:  return commonReducerBusiness.GET_ACCOUNT_PROFILES_FETCH_REQUEST(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_ERROR:    return commonReducerBusiness.GET_ACCOUNT_PROFILES_FETCH_ERROR(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_SUCCESS:  return commonReducerBusiness.GET_ACCOUNT_PROFILES_FETCH_SUCCESS(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_REQUEST:  return commonReducerBusiness.GET_ACCOUNT_PROFILE_BY_ID_FETCH_REQUEST(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_ERROR:    return commonReducerBusiness.GET_ACCOUNT_PROFILE_BY_ID_FETCH_ERROR(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_SUCCESS:  return commonReducerBusiness.GET_ACCOUNT_PROFILE_BY_ID_FETCH_SUCCESS(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_REQUEST:  return commonReducerBusiness.POST_ACCOUNT_PROFILE_FETCH_REQUEST(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_ERROR:    return commonReducerBusiness.POST_ACCOUNT_PROFILE_FETCH_ERROR(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_SUCCESS:  return commonReducerBusiness.POST_ACCOUNT_PROFILE_FETCH_SUCCESS(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_REQUEST:  return commonReducerBusiness.PUT_ACCOUNT_PROFILE_FETCH_REQUEST(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_ERROR:    return commonReducerBusiness.PUT_ACCOUNT_PROFILE_FETCH_ERROR(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_SUCCESS:  return commonReducerBusiness.PUT_ACCOUNT_PROFILE_FETCH_SUCCESS(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_REQUEST:  return commonReducerBusiness.DELETE_ACCOUNT_PROFILE_FETCH_REQUEST(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_ERROR:    return commonReducerBusiness.DELETE_ACCOUNT_PROFILE_FETCH_ERROR(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_SUCCESS:  return commonReducerBusiness.DELETE_ACCOUNT_PROFILE_FETCH_SUCCESS(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_SET_PERMISSION             : return commonReducerBusiness.SET_PERMISSION(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_SET_SEARCH_PERFIL          : return commonReducerBusiness.SET_SEARCH_PERFIL(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_SET_SEARCH_FUNCTIONALITIES : return commonReducerBusiness.SET_SEARCH_FUNCTIONALITIES(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_SET_ADMIN_PERMISSION_PROFILE         : return commonReducerBusiness.SET_ADMIN_PERMISSION_PROFILE(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_SET_PERMISSION_UPDATE_PROFILES         : return commonReducerBusiness.SET_PERMISSION_UPDATE_PROFILES(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_SET_PERMISSION_UPDATE_FUNCTIONALITIES  : return commonReducerBusiness.SET_PERMISSION_UPDATE_FUNCTIONALITIES(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_SET_PERFIL_SELECTED : return commonReducerBusiness.SET_PERFIL_SELECTED(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_SET_TOGGLE_CHECK_FUNCTIONALITY : return commonReducerBusiness.SET_TOGGLE_CHECK_FUNCTIONALITY(typesBusiness.SECTION, state, action);

    case typesBusiness.PERMISSION_BUSINESS_SET_LIST_PROFILES  : return commonReducerBusiness.SET_LIST_PROFILES(typesBusiness.SECTION, state, action);
    case typesBusiness.PERMISSION_BUSINESS_SET_PROFILES       : return commonReducerBusiness.SET_PROFILES(typesBusiness.SECTION, state, action);
    //#endregion BUSINESS

    //#region WORKSPACE
    case typesWorkspace.PERMISSION_WORKSPACE_IS_FIRST_LOADING: return commonReducerWorkspace.IS_FIRST_LOADING(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_REQUEST:  return commonReducerWorkspace.GET_WORKSPACE_PROFILES_FETCH_REQUEST(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_ERROR:    return commonReducerWorkspace.GET_WORKSPACE_PROFILES_FETCH_ERROR(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILES_FETCH_SUCCESS:  return commonReducerWorkspace.GET_WORKSPACE_PROFILES_FETCH_SUCCESS(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_REQUEST:  return commonReducerWorkspace.GET_WORKSPACE_PROFILE_BY_ID_FETCH_REQUEST(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_ERROR:    return commonReducerWorkspace.GET_WORKSPACE_PROFILE_BY_ID_FETCH_ERROR(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_GET_WORKSPACE_PROFILE_BY_ID_FETCH_SUCCESS:  return commonReducerWorkspace.GET_WORKSPACE_PROFILE_BY_ID_FETCH_SUCCESS(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_REQUEST:  return commonReducerWorkspace.POST_WORKSPACE_PROFILE_FETCH_REQUEST(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_ERROR:    return commonReducerWorkspace.POST_WORKSPACE_PROFILE_FETCH_ERROR(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_POST_WORKSPACE_PROFILE_FETCH_SUCCESS:  return commonReducerWorkspace.POST_WORKSPACE_PROFILE_FETCH_SUCCESS(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_REQUEST:  return commonReducerWorkspace.PUT_WORKSPACE_PROFILE_FETCH_REQUEST(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_ERROR:    return commonReducerWorkspace.PUT_WORKSPACE_PROFILE_FETCH_ERROR(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_PUT_WORKSPACE_PROFILE_FETCH_SUCCESS:  return commonReducerWorkspace.PUT_WORKSPACE_PROFILE_FETCH_SUCCESS(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_REQUEST:  return commonReducerWorkspace.DELETE_WORKSPACE_PROFILE_FETCH_REQUEST(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_ERROR:    return commonReducerWorkspace.DELETE_WORKSPACE_PROFILE_FETCH_ERROR(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_DELETE_WORKSPACE_PROFILE_FETCH_SUCCESS:  return commonReducerWorkspace.DELETE_WORKSPACE_PROFILE_FETCH_SUCCESS(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_SET_PERMISSION             : return commonReducerWorkspace.SET_PERMISSION(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_SEARCH_PERFIL          : return commonReducerWorkspace.SET_SEARCH_PERFIL(typesWorkspace.SECTION, state, action);
    // case typesWorkspace.PERMISSION_WORKSPACE_SET_SEARCH_FUNCTIONALITIES : return commonReducerWorkspace.SET_SEARCH_FUNCTIONALITIES(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_SET_ADMIN_PERMISSION_PROFILE         : return commonReducerWorkspace.SET_ADMIN_PERMISSION_PROFILE(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_PERMISSION_UPDATE_PROFILES         : return commonReducerWorkspace.SET_PERMISSION_UPDATE_PROFILES(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_PERMISSION_UPDATE_FUNCTIONALITIES  : return commonReducerWorkspace.SET_PERMISSION_UPDATE_FUNCTIONALITIES(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_SET_PERFIL_SELECTED : return commonReducerWorkspace.SET_PERFIL_SELECTED(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_APP_FUNCTIONALITY : return commonReducerWorkspace.SET_APP_FUNCTIONALITY(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_APP_LIST_FUNCTIONALITY : return commonReducerWorkspace.SET_APP_LIST_FUNCTIONALITY(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_APP_ID_SELECTED : return commonReducerWorkspace.APP_ID_SELECTED(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_APPS                    : return commonReducerWorkspace.SET_TOGGLE_CHECK_APPS(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_FUNCTIONALITY           : return commonReducerWorkspace.SET_TOGGLE_CHECK_FUNCTIONALITY(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_TOGGLE_CHECK_WORKSPACE_FUNCTIONALITY : return commonReducerWorkspace.SET_TOGGLE_CHECK_WORKSPACE_FUNCTIONALITY(typesWorkspace.SECTION, state, action);

    case typesWorkspace.PERMISSION_WORKSPACE_SET_LIST_PROFILES  : return commonReducerWorkspace.SET_LIST_PROFILES(typesWorkspace.SECTION, state, action);
    case typesWorkspace.PERMISSION_WORKSPACE_SET_PROFILES       : return commonReducerWorkspace.SET_PROFILES(typesWorkspace.SECTION, state, action);
    //#endregion

    default: return state;
  }
};