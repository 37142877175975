import { createSelector } from 'reselect';

const getApiContextByKey = (key) => (state) =>
  state.configuration.medicalRecord.forms.api[key];
const getFetchContextByKey = (key) => (state) =>
  state.configuration.medicalRecord.forms.fetching[key];
const getContextByKey = (key) => (state) =>
  state.configuration.medicalRecord.forms[key];

export default {
  getListFormModels: createSelector(
    getApiContextByKey('listFormModels'),
    (state) => state
  ),
  getListManagerFormModels: createSelector(
    getApiContextByKey('listManagerFormModels'),
    (state) => state
  ),
  getListDraft: createSelector(
    getApiContextByKey('listDrafts'),
    (state) => state
  ),
  getIsFetching: createSelector(
    getFetchContextByKey('listFormModels'),
    getFetchContextByKey('createFormEmpty'),
    getApiContextByKey('listManagerFormModels'),
    (listFetch, createFetch) => listFetch || createFetch
  ),

  getIsCreateFormEmpty: createSelector(
    getFetchContextByKey('createFormEmpty'),
    (state) => state
  ),

  getIsCreateDraft: createSelector(
    getFetchContextByKey('createDraft'),
    (state) => state
  ),

  getIsListDraft: createSelector(
    getFetchContextByKey('listDrafts'),
    (state) => state
  ),

  getIsSaveSection: createSelector(
    getFetchContextByKey('saveSections'),
    (state) => state
  ),

  getCategoriesAllForms: createSelector(
    getApiContextByKey('categoriesAllForms'),
    (state) => state
  ),

  getIsCategorySelected: createSelector(
    getApiContextByKey('categorySelected'),
    (state) => state
  ),

  getCategoriesForms: createSelector(
    getApiContextByKey('categoriesForms'),
    (state) => state
  ),

  getAuthorsForms: createSelector(
    getApiContextByKey('authors'),
    (state) => state
  ),

  getFormModelsFilter: createSelector(
    getApiContextByKey('filter'),
    (state) => state
  ),

  getSidebar: createSelector(
    getContextByKey('sidebar'),
    (state) => state
  ),

  getFavoritesForms: createSelector(
    getApiContextByKey('favorites'),
    (state) => state
  ),
};
