export default {
  isFirstLoading: true,
  fetching: {
    usersAccount: false,
    executingAction: false
  },
  api: {
    listUsersAccount: [],
    maxUser: null,
    userCount: null
  }
};
