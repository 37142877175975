export const IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const GET_ACCOUNT_PROFILES_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAccountProfiles: true
      }
    }
  };
};

export const GET_ACCOUNT_PROFILES_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAccountProfiles: false
      }
    }
  };
};

export const GET_ACCOUNT_PROFILES_FETCH_SUCCESS = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAccountProfiles: false
      }
    }
  };
};

export const GET_ACCOUNT_PROFILE_BY_ID_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAccountProfileById: true
      }
    }
  };
};

export const GET_ACCOUNT_PROFILE_BY_ID_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAccountProfileById: false
      }
    }
  };
};

export const GET_ACCOUNT_PROFILE_BY_ID_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAccountProfileById: false
      }
    }
  };
};

export const POST_ACCOUNT_PROFILE_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postAccountProfile: true
      }
    }
  };
};

export const POST_ACCOUNT_PROFILE_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postAccountProfile: false
      }
    }
  };
};

export const POST_ACCOUNT_PROFILE_FETCH_SUCCESS = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postAccountProfile: false
      }
    }
  };
};

export const PUT_ACCOUNT_PROFILE_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        putAccountProfile: true
      }
    }
  };
};

export const PUT_ACCOUNT_PROFILE_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        putAccountProfile: false
      }
    }
  };
};

export const PUT_ACCOUNT_PROFILE_FETCH_SUCCESS = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        putAccountProfile: false
      }
    }
  };
};

export const DELETE_ACCOUNT_PROFILE_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deleteAccountProfile: true
      }
    }
  };
};

export const DELETE_ACCOUNT_PROFILE_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deleteAccountProfile: false
      }
    }
  };
};

export const DELETE_ACCOUNT_PROFILE_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deleteAccountProfile: false
      }
    }
  };
};

export const SET_PERMISSION = (section, state, action) => {
  const {
    profiles,
    listProfiles,
    functionalities,
    listFunctionalities
  } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      api: {
        ...state[section].api,
        profiles,
        listProfiles,
        functionalities,
        listFunctionalities
      },
      search: {
        ...state[section].search,
        listProfiles,
        listFunctionalities
      }
    }
  };
};

export const SET_PERMISSION_UPDATE_PROFILES = (section, state, action) => {
  const { profiles, listProfiles } = action.payload;
  const _profiles = profiles || state[section].api.profiles;
  const _listProfiles = listProfiles || state[section].api.listProfiles;

  return {
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      api: {
        ...state[section].api,
        profiles: _profiles,
        listProfiles: _listProfiles
      },
      search: {
        ...state[section].search,
        listProfiles: _listProfiles
      }
    }
  };
};

export const SET_PERMISSION_UPDATE_FUNCTIONALITIES = (
  section,
  state,
  action
) => {
  const { functionalities } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        functionalities
      }
    }
  };
};

export const SET_SEARCH_PERFIL = (section, state, action) => {
  const { listProfiles } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      search: {
        ...state[section].search,
        listProfiles
      }
    }
  };
};

export const SET_SEARCH_FUNCTIONALITIES = (section, state, action) => {
  const { listFunctionalities } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      search: {
        ...state[section].search,
        listFunctionalities
      }
    }
  };
};

export const SET_PERFIL_SELECTED = (section, state, action) => {
  const { profileSelected } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        profileSelected: {
          ...state[section].api.profileSelected,
          ...profileSelected
        }
      }
    }
  };
};

export const SET_TOGGLE_CHECK_FUNCTIONALITY = (section, state, action) => {
  const { functionalities } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        functionalities
      }
    }
  };
};

export const SET_LIST_PROFILES = (section, state, action) => {
  const { listProfiles } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        listProfiles
      },
      search: {
        ...state[section].search,
        listProfiles
      }
    }
  };
};

export const SET_PROFILES = (section, state, action) => {
  const { profiles } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        profiles
      }
    }
  };
};

export const SET_ADMIN_PERMISSION_PROFILE = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        adminProfile: action.payload
      }
    }
  };
};
