export const FETCH_SUCCESS_LIST_QUALIFIERS = (state, action) => {
    return ({
      ...state,
      medicalRecord: {
        ...state.medicalRecord,
        qualifiers: {
          ...state.medicalRecord.qualifiers,
          fetching: {
            ...state.medicalRecord.qualifiers.fetching,
            getQualifiers: false
          },
          api:{
            ...state.medicalRecord.qualifiers.api,
            qualifiers: action.payload,
          }
        }
      }
    });
  };

export const FETCH_LIST_QUALIFIERS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      qualifiers: {
        ...state.medicalRecord.qualifiers,
        fetching: {
          ...state.medicalRecord.qualifiers.fetching,
          getQualifiers: true
        }
      }
    }
  });
}

export const FETCH_LIST_QUALIFIERS_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      qualifiers: {
        ...state.medicalRecord.qualifiers,
        fetching: {
          ...state.medicalRecord.qualifiers.fetching,
          getQualifiers: false
        }
      }
    }
  });
}

export const FETCHING_QUALIFIER_REQUEST = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      qualifiers: {
        ...state.medicalRecord.qualifiers,
        fetching: {
          ...state.medicalRecord.qualifiers.fetching,
          saveQualifier: true
        }
      }
    }
  });
}

export const FETCHING_QUALIFIER_SUCCESS = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      qualifiers: {
        ...state.medicalRecord.qualifiers,
        fetching: {
          ...state.medicalRecord.qualifiers.fetching,
          saveQualifier: false
        }
      }
    }
  });
}

export const FETCHING_QUALIFIER_ERROR = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      qualifiers: {
        ...state.medicalRecord.qualifiers,
        fetching: {
          ...state.medicalRecord.qualifiers.fetching,
          saveQualifier: false
        }
      }
    }
  });
}