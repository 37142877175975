export default ({
  isFirstLoading: true,
  fetching: {
    listFormModels: false,
    listDrafts: false,
    createFormEmpty: false,
    createDraft: false,
    getCategories: false,
    saveSections: false,
  },
  tabSelected: '0',
  sidebar:{
    allFormModels: true,
    categoryUndefined: false,
    categorySelected: false,
  },
  api: {
    listFormModels: [],
    listFormModelsGlobals: [],
    listDrafts: [],
    categoriesForms: [],
    categoriesAllForms: [],
    categorySelected: {},
    authors: [],
    favorites: {
      formModels: []
    },
    filter:{
      forms: [],
      isFilter: false,
    }
  }
});