import overviewInitialState         from './overview/initialState';
import typesOverview                from './overview/constant';
import * as commonReducerOverview   from './overview/reducerGenerator';

const INITIAL_STATE = {
  overview : {...overviewInitialState},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //#region USER OVERVIEW
    case typesOverview.WORKSPACES_OVERVIEW_IS_FIRST_LOADING:            return commonReducerOverview.IS_FIRST_LOADING(typesOverview.SECTION, state, action);

    case typesOverview.BUSINESS_OVERVIEW_MODAL_CLOSED:                  return commonReducerOverview.MODAL_CLOSED(typesOverview.SECTION, state, action);
    case typesOverview.BUSINESS_OVERVIEW_MODAL_UPDATE:                  return commonReducerOverview.MODAL_UPDATE(typesOverview.SECTION, state, action);

    case typesOverview.BUSINESS_OVERVIEW_GET_BY_ID_FETCH_REQUEST:       return commonReducerOverview.GET_BY_ID_FETCH_REQUEST(typesOverview.SECTION, state, action);
    case typesOverview.BUSINESS_OVERVIEW_GET_BY_ID_FETCH_SUCCESS:       return commonReducerOverview.GET_BY_ID_FETCH_SUCCESS(typesOverview.SECTION, state, action);
    case typesOverview.BUSINESS_OVERVIEW_GET_BY_ID_FETCH_ERROR:         return commonReducerOverview.GET_BY_ID_FETCH_ERROR(typesOverview.SECTION, state, action);

    case typesOverview.BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_REQUEST:  return commonReducerOverview.UPDATE_ACCOUNT_FETCH_REQUEST(typesOverview.SECTION, state, action);
    case typesOverview.BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_SUCCESS:  return commonReducerOverview.UPDATE_ACCOUNT_FETCH_SUCCESS(typesOverview.SECTION, state, action);
    case typesOverview.BUSINESS_OVERVIEW_UPDATE_ACCOUNT_FETCH_ERROR:    return commonReducerOverview.UPDATE_ACCOUNT_FETCH_ERROR(typesOverview.SECTION, state, action);
    //#endregion
    default:
      return state;
  }
};