import { createSelector } from 'reselect';

//#region Create Perfil Form 
const getCreatePerfilFormValuesByKey   = key => state => {
  const formName = "form:create:user:perfil";
  if(state.form[formName] && state.form[formName]["values"]) 
    return state.form[formName].values[key];
  return null;
};

const getCreatePerfilFormContextByKey  = key => state => {
  const formName = "form:create:user:perfil";
  if(state.form[formName])
    return state.form[formName][key];
}

const getCreatePerfilAddressFormValuesByKey   = key => state => {
  const formName = "form:create:user:perfil:address";
  if(state.form[formName] && state.form[formName].values) 
    return state.form[formName].values[key];
  return null;
};
//#endregion 

//#region Perfil Form
const getPerfilFormContextByKey        = (formName, key) => state => {
  if(state.form[formName] && state.form[formName].values) {
    return state.form[formName].values[key];
  }
}
//#endregion

//#region RegistrationData Form
const getRegistrationDataFormContextByKey        = key => state => {
  const formName = "user:update:form:user:registrationdata:dados:address";
  if(state.form[formName] && state.form[formName].values) {
    return state.form[formName].values[key];
  }
}
//#endregion


//#region Recover Password Form
const getRecoverFormContextByKey  = key => state => {
  const formName = "NewPassword";
  if(state.form[formName])
    return state.form[formName][key];
}
//#endregion

export default ({

  getCpfCreateFormPerfil: createSelector(
    getCreatePerfilFormValuesByKey('cpf'),
    state => state
  ),

  getEmailCreateFormPerfil: createSelector(
    getCreatePerfilFormValuesByKey('email'),
    state => state
  ),

  getCepCreateFormPerfil: createSelector(
    getCreatePerfilAddressFormValuesByKey('cep'),
    state => state
  ),

  getActiveFieldCreateFormPerfil: createSelector(
    getCreatePerfilFormContextByKey('active'),
    state => state
  ),

  getCepFieldFormPerfil: createSelector(
    getPerfilFormContextByKey("FormPerfilAddress", "cep"),
    state => state
  ),

  getCepFieldRegistrationData: createSelector(
    getRegistrationDataFormContextByKey("cep"),
    state => state
  ),

  getRecoverFormValues: createSelector(
    getRecoverFormContextByKey("values"),
    state => state
  )

});