export default ([
  // {
  //   "cityId": 0,
  //   "cityName": "- Não definida -",
  //   "stateId": 0
  // },
  {
    "cityId": 1680,
    "cityName": "São Francisco",
    "stateId": 5239
  },
  {
    "cityId": 1681,
    "cityName": "São Gerardo",
    "stateId": 5239
  },
  {
    "cityId": 1682,
    "cityName": "São Gonçalo do Amarante",
    "stateId": 5239
  },
  {
    "cityId": 1683,
    "cityName": "São Gonçalo do Umari",
    "stateId": 5239
  },
  {
    "cityId": 1684,
    "cityName": "São João de Deus",
    "stateId": 5239
  },
  {
    "cityId": 1685,
    "cityName": "São João do Jaguaribe",
    "stateId": 5239
  },
  {
    "cityId": 1686,
    "cityName": "São João dos Queiroz",
    "stateId": 5239
  },
  {
    "cityId": 1687,
    "cityName": "São Joaquim",
    "stateId": 5239
  },
  {
    "cityId": 1688,
    "cityName": "São Joaquim do Salgado",
    "stateId": 5239
  },
  {
    "cityId": 1689,
    "cityName": "São José",
    "stateId": 5239
  },
  {
    "cityId": 1690,
    "cityName": "São José",
    "stateId": 5239
  },
  {
    "cityId": 1691,
    "cityName": "São José",
    "stateId": 5239
  },
  {
    "cityId": 1692,
    "cityName": "São José",
    "stateId": 5239
  },
  {
    "cityId": 1693,
    "cityName": "São José das Lontras",
    "stateId": 5239
  },
  {
    "cityId": 1694,
    "cityName": "São José de Solonópole",
    "stateId": 5239
  },
  {
    "cityId": 1695,
    "cityName": "São José do Torto",
    "stateId": 5239
  },
  {
    "cityId": 1696,
    "cityName": "São Luís do Curu",
    "stateId": 5239
  },
  {
    "cityId": 1697,
    "cityName": "São Miguel",
    "stateId": 5239
  },
  {
    "cityId": 1698,
    "cityName": "São Miguel",
    "stateId": 5239
  },
  {
    "cityId": 1699,
    "cityName": "São Paulo",
    "stateId": 5239
  },
  {
    "cityId": 1700,
    "cityName": "São Pedro do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1701,
    "cityName": "São Pedro do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1702,
    "cityName": "São Romão",
    "stateId": 5239
  },
  {
    "cityId": 1703,
    "cityName": "São Sebastião",
    "stateId": 5239
  },
  {
    "cityId": 1704,
    "cityName": "São Vicente",
    "stateId": 5239
  },
  {
    "cityId": 1705,
    "cityName": "São Vicente",
    "stateId": 5239
  },
  {
    "cityId": 1706,
    "cityName": "Sapo",
    "stateId": 5239
  },
  {
    "cityId": 1707,
    "cityName": "Sapupara",
    "stateId": 5239
  },
  {
    "cityId": 1708,
    "cityName": "Sebastião de Abreu",
    "stateId": 5239
  },
  {
    "cityId": 1709,
    "cityName": "Senador Carlos Jereissati",
    "stateId": 5239
  },
  {
    "cityId": 1710,
    "cityName": "Senador Pompeu",
    "stateId": 5239
  },
  {
    "cityId": 1711,
    "cityName": "Senador Sá",
    "stateId": 5239
  },
  {
    "cityId": 1712,
    "cityName": "Sereno de Cima",
    "stateId": 5239
  },
  {
    "cityId": 1713,
    "cityName": "Serra do Félix",
    "stateId": 5239
  },
  {
    "cityId": 1714,
    "cityName": "Serragem",
    "stateId": 5239
  },
  {
    "cityId": 1715,
    "cityName": "Serrota",
    "stateId": 5239
  },
  {
    "cityId": 1716,
    "cityName": "Serrota",
    "stateId": 5239
  },
  {
    "cityId": 1717,
    "cityName": "Serrote",
    "stateId": 5239
  },
  {
    "cityId": 1718,
    "cityName": "Serrote",
    "stateId": 5239
  },
  {
    "cityId": 1719,
    "cityName": "Sitiá",
    "stateId": 5239
  },
  {
    "cityId": 1720,
    "cityName": "Sítios Novos",
    "stateId": 5239
  },
  {
    "cityId": 1721,
    "cityName": "Siupê",
    "stateId": 5239
  },
  {
    "cityId": 1722,
    "cityName": "Sobral",
    "stateId": 5239
  },
  {
    "cityId": 1723,
    "cityName": "Soledade",
    "stateId": 5239
  },
  {
    "cityId": 1724,
    "cityName": "Solonópole",
    "stateId": 5239
  },
  {
    "cityId": 1725,
    "cityName": "Suassurana",
    "stateId": 5239
  },
  {
    "cityId": 1726,
    "cityName": "Sucatinga",
    "stateId": 5239
  },
  {
    "cityId": 1727,
    "cityName": "Sucesso",
    "stateId": 5239
  },
  {
    "cityId": 1728,
    "cityName": "Sussuanha",
    "stateId": 5239
  },
  {
    "cityId": 1729,
    "cityName": "Tabainha",
    "stateId": 5239
  },
  {
    "cityId": 1730,
    "cityName": "Taboleiro",
    "stateId": 5239
  },
  {
    "cityId": 1731,
    "cityName": "Tabuleiro do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1732,
    "cityName": "Taíba",
    "stateId": 5239
  },
  {
    "cityId": 1733,
    "cityName": "Tamboril",
    "stateId": 5239
  },
  {
    "cityId": 1734,
    "cityName": "Tanques",
    "stateId": 5239
  },
  {
    "cityId": 1735,
    "cityName": "Tapera",
    "stateId": 5239
  },
  {
    "cityId": 1736,
    "cityName": "Taperuaba",
    "stateId": 5239
  },
  {
    "cityId": 1737,
    "cityName": "Tapuiara",
    "stateId": 5239
  },
  {
    "cityId": 1738,
    "cityName": "Targinos",
    "stateId": 5239
  },
  {
    "cityId": 1739,
    "cityName": "Tarrafas",
    "stateId": 5239
  },
  {
    "cityId": 1740,
    "cityName": "Tauá",
    "stateId": 5239
  },
  {
    "cityId": 1741,
    "cityName": "Tejuçuoca",
    "stateId": 5239
  },
  {
    "cityId": 1742,
    "cityName": "Tianguá",
    "stateId": 5239
  },
  {
    "cityId": 1743,
    "cityName": "Timonha",
    "stateId": 5239
  },
  {
    "cityId": 1744,
    "cityName": "Tipi",
    "stateId": 5239
  },
  {
    "cityId": 1745,
    "cityName": "Tomé",
    "stateId": 5239
  },
  {
    "cityId": 1746,
    "cityName": "Trairi",
    "stateId": 5239
  },
  {
    "cityId": 1747,
    "cityName": "Trapiá",
    "stateId": 5239
  },
  {
    "cityId": 1748,
    "cityName": "Trapiá",
    "stateId": 5239
  },
  {
    "cityId": 1749,
    "cityName": "Trici",
    "stateId": 5239
  },
  {
    "cityId": 1750,
    "cityName": "Tróia",
    "stateId": 5239
  },
  {
    "cityId": 1751,
    "cityName": "Trussu",
    "stateId": 5239
  },
  {
    "cityId": 1752,
    "cityName": "Tucunduba",
    "stateId": 5239
  },
  {
    "cityId": 1753,
    "cityName": "Tucuns",
    "stateId": 5239
  },
  {
    "cityId": 1754,
    "cityName": "Tuína",
    "stateId": 5239
  },
  {
    "cityId": 1755,
    "cityName": "Tururu",
    "stateId": 5239
  },
  {
    "cityId": 1756,
    "cityName": "Ubajara",
    "stateId": 5239
  },
  {
    "cityId": 1757,
    "cityName": "Ubaúna",
    "stateId": 5239
  },
  {
    "cityId": 1758,
    "cityName": "Ubiraçu",
    "stateId": 5239
  },
  {
    "cityId": 1759,
    "cityName": "Uiraponga",
    "stateId": 5239
  },
  {
    "cityId": 1760,
    "cityName": "Umari",
    "stateId": 5239
  },
  {
    "cityId": 1761,
    "cityName": "Umarituba",
    "stateId": 5239
  },
  {
    "cityId": 1762,
    "cityName": "Umburanas",
    "stateId": 5239
  },
  {
    "cityId": 1763,
    "cityName": "Umirim",
    "stateId": 5239
  },
  {
    "cityId": 1764,
    "cityName": "Uruburetama",
    "stateId": 5239
  },
  {
    "cityId": 1765,
    "cityName": "Uruoca",
    "stateId": 5239
  },
  {
    "cityId": 1766,
    "cityName": "Uruquê",
    "stateId": 5239
  },
  {
    "cityId": 1767,
    "cityName": "Varjota",
    "stateId": 5239
  },
  {
    "cityId": 1768,
    "cityName": "Várzea",
    "stateId": 5239
  },
  {
    "cityId": 1769,
    "cityName": "Várzea Alegre",
    "stateId": 5239
  },
  {
    "cityId": 1770,
    "cityName": "Várzea da Volta",
    "stateId": 5239
  },
  {
    "cityId": 1771,
    "cityName": "Várzea do Gilo",
    "stateId": 5239
  },
  {
    "cityId": 1772,
    "cityName": "Vazantes",
    "stateId": 5239
  },
  {
    "cityId": 1773,
    "cityName": "Ventura",
    "stateId": 5239
  },
  {
    "cityId": 1774,
    "cityName": "Vertentes do Lagedo",
    "stateId": 5239
  },
  {
    "cityId": 1775,
    "cityName": "Viçosa",
    "stateId": 5239
  },
  {
    "cityId": 1776,
    "cityName": "Viçosa do Ceará",
    "stateId": 5239
  },
  {
    "cityId": 1777,
    "cityName": "Vila Soares",
    "stateId": 5239
  },
  {
    "cityId": 1778,
    "cityName": "Brasília",
    "stateId": 5240
  },
  {
    "cityId": 1779,
    "cityName": "Brazlândia",
    "stateId": 5240
  },
  {
    "cityId": 1780,
    "cityName": "Candangolândia",
    "stateId": 5240
  },
  {
    "cityId": 1781,
    "cityName": "Ceilândia",
    "stateId": 5240
  },
  {
    "cityId": 1782,
    "cityName": "Cruzeiro",
    "stateId": 5240
  },
  {
    "cityId": 1783,
    "cityName": "Gama",
    "stateId": 5240
  },
  {
    "cityId": 1784,
    "cityName": "Guará",
    "stateId": 5240
  },
  {
    "cityId": 1785,
    "cityName": "Lago Norte",
    "stateId": 5240
  },
  {
    "cityId": 1786,
    "cityName": "Lago Sul",
    "stateId": 5240
  },
  {
    "cityId": 1787,
    "cityName": "Núcleo Bandeirante",
    "stateId": 5240
  },
  {
    "cityId": 1788,
    "cityName": "Paranoá",
    "stateId": 5240
  },
  {
    "cityId": 1789,
    "cityName": "Planaltina",
    "stateId": 5240
  },
  {
    "cityId": 1790,
    "cityName": "Recanto das Emas",
    "stateId": 5240
  },
  {
    "cityId": 1791,
    "cityName": "Riacho Fundo",
    "stateId": 5240
  },
  {
    "cityId": 1792,
    "cityName": "Samambaia",
    "stateId": 5240
  },
  {
    "cityId": 1793,
    "cityName": "Santa Maria",
    "stateId": 5240
  },
  {
    "cityId": 1794,
    "cityName": "São Sebastião",
    "stateId": 5240
  },
  {
    "cityId": 1795,
    "cityName": "Sobradinho",
    "stateId": 5240
  },
  {
    "cityId": 1796,
    "cityName": "Taguatinga",
    "stateId": 5240
  },
  {
    "cityId": 1797,
    "cityName": "Acioli",
    "stateId": 5241
  },
  {
    "cityId": 1798,
    "cityName": "Afonso Cláudio",
    "stateId": 5241
  },
  {
    "cityId": 1799,
    "cityName": "Aghá",
    "stateId": 5241
  },
  {
    "cityId": 1800,
    "cityName": "Água Doce do Norte",
    "stateId": 5241
  },
  {
    "cityId": 1801,
    "cityName": "Águia Branca",
    "stateId": 5241
  },
  {
    "cityId": 1802,
    "cityName": "Airituba",
    "stateId": 5241
  },
  {
    "cityId": 1803,
    "cityName": "Alegre",
    "stateId": 5241
  },
  {
    "cityId": 1804,
    "cityName": "Alfredo Chaves",
    "stateId": 5241
  },
  {
    "cityId": 1805,
    "cityName": "Alto Calçado",
    "stateId": 5241
  },
  {
    "cityId": 1806,
    "cityName": "Alto Caldeirão",
    "stateId": 5241
  },
  {
    "cityId": 1807,
    "cityName": "Alto Mutum Preto",
    "stateId": 5241
  },
  {
    "cityId": 1808,
    "cityName": "Alto Rio Novo",
    "stateId": 5241
  },
  {
    "cityId": 1809,
    "cityName": "Alto Santa Maria",
    "stateId": 5241
  },
  {
    "cityId": 1810,
    "cityName": "Anchieta",
    "stateId": 5241
  },
  {
    "cityId": 1811,
    "cityName": "Ângelo Frechiani",
    "stateId": 5241
  },
  {
    "cityId": 1812,
    "cityName": "Anutiba",
    "stateId": 5241
  },
  {
    "cityId": 1813,
    "cityName": "Apiacá",
    "stateId": 5241
  },
  {
    "cityId": 1814,
    "cityName": "Araçatiba",
    "stateId": 5241
  },
  {
    "cityId": 1815,
    "cityName": "Aracê",
    "stateId": 5241
  },
  {
    "cityId": 1816,
    "cityName": "Aracruz",
    "stateId": 5241
  },
  {
    "cityId": 1817,
    "cityName": "Aracui",
    "stateId": 5241
  },
  {
    "cityId": 1818,
    "cityName": "Araguaia",
    "stateId": 5241
  },
  {
    "cityId": 1819,
    "cityName": "Araraí",
    "stateId": 5241
  },
  {
    "cityId": 1820,
    "cityName": "Argolas",
    "stateId": 5241
  },
  {
    "cityId": 1821,
    "cityName": "Atílio Vivácqua",
    "stateId": 5241
  },
  {
    "cityId": 1822,
    "cityName": "Baía Nova",
    "stateId": 5241
  },
  {
    "cityId": 1823,
    "cityName": "Baixo Guandu",
    "stateId": 5241
  },
  {
    "cityId": 1824,
    "cityName": "Barra de Novo Brasil",
    "stateId": 5241
  },
  {
    "cityId": 1825,
    "cityName": "Barra de São Francisco",
    "stateId": 5241
  },
  {
    "cityId": 1827,
    "cityName": "Barra Nova",
    "stateId": 5241
  },
  {
    "cityId": 1828,
    "cityName": "Barra Seca",
    "stateId": 5241
  },
  {
    "cityId": 1829,
    "cityName": "Baunilha",
    "stateId": 5241
  },
  {
    "cityId": 1830,
    "cityName": "Bebedouro",
    "stateId": 5241
  },
  {
    "cityId": 1831,
    "cityName": "Boa Esperança",
    "stateId": 5241
  },
  {
    "cityId": 1832,
    "cityName": "Boapaba",
    "stateId": 5241
  },
  {
    "cityId": 1833,
    "cityName": "Bom Jesus do Norte",
    "stateId": 5241
  },
  {
    "cityId": 1834,
    "cityName": "Bonsucesso",
    "stateId": 5241
  },
  {
    "cityId": 1835,
    "cityName": "Braço do Rio",
    "stateId": 5241
  },
  {
    "cityId": 1836,
    "cityName": "Brejetuba",
    "stateId": 5241
  },
  {
    "cityId": 1837,
    "cityName": "Burarama",
    "stateId": 5241
  },
  {
    "cityId": 1838,
    "cityName": "Cachoeirinha de Itaúna",
    "stateId": 5241
  },
  {
    "cityId": 1839,
    "cityName": "Cachoeiro de Itapemirim",
    "stateId": 5241
  },
  {
    "cityId": 1840,
    "cityName": "Café",
    "stateId": 5241
  },
  {
    "cityId": 1841,
    "cityName": "Calogi",
    "stateId": 5241
  },
  {
    "cityId": 1842,
    "cityName": "Câmara",
    "stateId": 5241
  },
  {
    "cityId": 1843,
    "cityName": "Carapina",
    "stateId": 5241
  },
  {
    "cityId": 1844,
    "cityName": "Cariacica",
    "stateId": 5241
  },
  {
    "cityId": 1845,
    "cityName": "Castelo",
    "stateId": 5241
  },
  {
    "cityId": 1846,
    "cityName": "Celina",
    "stateId": 5241
  },
  {
    "cityId": 1847,
    "cityName": "Colatina",
    "stateId": 5241
  },
  {
    "cityId": 1848,
    "cityName": "Conceição da Barra",
    "stateId": 5241
  },
  {
    "cityId": 1849,
    "cityName": "Conceição do Castelo",
    "stateId": 5241
  },
  {
    "cityId": 1850,
    "cityName": "Conceição do Muqui",
    "stateId": 5241
  },
  {
    "cityId": 1851,
    "cityName": "Conduru",
    "stateId": 5241
  },
  {
    "cityId": 1852,
    "cityName": "Praia do Coqueiral",
    "stateId": 5241
  },
  {
    "cityId": 1853,
    "cityName": "Córrego dos Monos",
    "stateId": 5241
  },
  {
    "cityId": 1854,
    "cityName": "Cotaxé",
    "stateId": 5241
  },
  {
    "cityId": 1855,
    "cityName": "Cristal do Norte",
    "stateId": 5241
  },
  {
    "cityId": 1856,
    "cityName": "Crubixá",
    "stateId": 5241
  },
  {
    "cityId": 1857,
    "cityName": "Desengano",
    "stateId": 5241
  },
  {
    "cityId": 1858,
    "cityName": "Divino de São Lourenço",
    "stateId": 5241
  },
  {
    "cityId": 1859,
    "cityName": "Divino Espírito Santo",
    "stateId": 5241
  },
  {
    "cityId": 1860,
    "cityName": "Djalma Coutinho",
    "stateId": 5241
  },
  {
    "cityId": 1861,
    "cityName": "Domingos Martins",
    "stateId": 5241
  },
  {
    "cityId": 1862,
    "cityName": "Dona América",
    "stateId": 5241
  },
  {
    "cityId": 1863,
    "cityName": "Dores do Rio Preto",
    "stateId": 5241
  },
  {
    "cityId": 1864,
    "cityName": "Duas Barras",
    "stateId": 5241
  },
  {
    "cityId": 1865,
    "cityName": "Ecoporanga",
    "stateId": 5241
  },
  {
    "cityId": 1866,
    "cityName": "Estrela do Norte",
    "stateId": 5241
  },
  {
    "cityId": 1867,
    "cityName": "Fartura",
    "stateId": 5241
  },
  {
    "cityId": 1868,
    "cityName": "Fazenda Guandu",
    "stateId": 5241
  },
  {
    "cityId": 1869,
    "cityName": "Fundão",
    "stateId": 5241
  },
  {
    "cityId": 1870,
    "cityName": "Garrafão",
    "stateId": 5241
  },
  {
    "cityId": 1871,
    "cityName": "Gironda",
    "stateId": 5241
  },
  {
    "cityId": 1872,
    "cityName": "Goiabeiras",
    "stateId": 5241
  },
  {
    "cityId": 1873,
    "cityName": "Governador Lacerda de Aguiar",
    "stateId": 5241
  },
  {
    "cityId": 1874,
    "cityName": "Governador Lindenberg",
    "stateId": 5241
  },
  {
    "cityId": 1875,
    "cityName": "Graça Aranha",
    "stateId": 5241
  },
  {
    "cityId": 1876,
    "cityName": "Gruta",
    "stateId": 5241
  },
  {
    "cityId": 1877,
    "cityName": "Guaçuí",
    "stateId": 5241
  },
  {
    "cityId": 1878,
    "cityName": "Vila Guaraná",
    "stateId": 5241
  },
  {
    "cityId": 1879,
    "cityName": "Guarapari",
    "stateId": 5241
  },
  {
    "cityId": 1880,
    "cityName": "Guararema",
    "stateId": 5241
  },
  {
    "cityId": 1881,
    "cityName": "Ibatiba",
    "stateId": 5241
  },
  {
    "cityId": 1883,
    "cityName": "Ibicaba",
    "stateId": 5241
  },
  {
    "cityId": 1884,
    "cityName": "Ibiraçu",
    "stateId": 5241
  },
  {
    "cityId": 1885,
    "cityName": "Ibitirama",
    "stateId": 5241
  },
  {
    "cityId": 1886,
    "cityName": "Ibitiruí",
    "stateId": 5241
  },
  {
    "cityId": 1887,
    "cityName": "Ibituba",
    "stateId": 5241
  },
  {
    "cityId": 1888,
    "cityName": "Iconha",
    "stateId": 5241
  },
  {
    "cityId": 1889,
    "cityName": "Imburana",
    "stateId": 5241
  },
  {
    "cityId": 1890,
    "cityName": "Iriritiba",
    "stateId": 5241
  },
  {
    "cityId": 1891,
    "cityName": "Irundi",
    "stateId": 5241
  },
  {
    "cityId": 1892,
    "cityName": "Irupi",
    "stateId": 5241
  },
  {
    "cityId": 1893,
    "cityName": "Isabel",
    "stateId": 5241
  },
  {
    "cityId": 1894,
    "cityName": "Itabaiana",
    "stateId": 5241
  },
  {
    "cityId": 1895,
    "cityName": "Itaçu",
    "stateId": 5241
  },
  {
    "cityId": 1896,
    "cityName": "Itaguaçu",
    "stateId": 5241
  },
  {
    "cityId": 1897,
    "cityName": "Itaicí",
    "stateId": 5241
  },
  {
    "cityId": 1898,
    "cityName": "Itaimbé",
    "stateId": 5241
  },
  {
    "cityId": 1899,
    "cityName": "Itaipava",
    "stateId": 5241
  },
  {
    "cityId": 1900,
    "cityName": "Itamira",
    "stateId": 5241
  },
  {
    "cityId": 1901,
    "cityName": "Itaóca",
    "stateId": 5241
  },
  {
    "cityId": 1902,
    "cityName": "Itapecoá",
    "stateId": 5241
  },
  {
    "cityId": 1903,
    "cityName": "Itapemirim",
    "stateId": 5241
  },
  {
    "cityId": 1904,
    "cityName": "Itaperuna",
    "stateId": 5241
  },
  {
    "cityId": 1905,
    "cityName": "Itapina",
    "stateId": 5241
  },
  {
    "cityId": 1906,
    "cityName": "Itaquari",
    "stateId": 5241
  },
  {
    "cityId": 1907,
    "cityName": "Itarana",
    "stateId": 5241
  },
  {
    "cityId": 1908,
    "cityName": "Itaúnas",
    "stateId": 5241
  },
  {
    "cityId": 1909,
    "cityName": "Itauninhas",
    "stateId": 5241
  },
  {
    "cityId": 1910,
    "cityName": "Iúna",
    "stateId": 5241
  },
  {
    "cityId": 1911,
    "cityName": "Jabaquara",
    "stateId": 5241
  },
  {
    "cityId": 1912,
    "cityName": "Jacaraipe",
    "stateId": 5241
  },
  {
    "cityId": 1913,
    "cityName": "Jaciguá",
    "stateId": 5241
  },
  {
    "cityId": 1914,
    "cityName": "Jacupemba",
    "stateId": 5241
  },
  {
    "cityId": 1915,
    "cityName": "Jaguaré",
    "stateId": 5241
  },
  {
    "cityId": 1916,
    "cityName": "Jerônimo Monteiro",
    "stateId": 5241
  },
  {
    "cityId": 1917,
    "cityName": "Joaçuba",
    "stateId": 5241
  },
  {
    "cityId": 1918,
    "cityName": "João Neiva",
    "stateId": 5241
  },
  {
    "cityId": 1919,
    "cityName": "Joatuba",
    "stateId": 5241
  },
  {
    "cityId": 1920,
    "cityName": "José Carlos",
    "stateId": 5241
  },
  {
    "cityId": 1922,
    "cityName": "Lajinha",
    "stateId": 5241
  },
  {
    "cityId": 1923,
    "cityName": "Laranja da Terra",
    "stateId": 5241
  },
  {
    "cityId": 1924,
    "cityName": "Limoeiro",
    "stateId": 5241
  },
  {
    "cityId": 1925,
    "cityName": "Linhares",
    "stateId": 5241
  },
  {
    "cityId": 1926,
    "cityName": "Mangaraí",
    "stateId": 5241
  },
  {
    "cityId": 1927,
    "cityName": "Mantenópolis",
    "stateId": 5241
  },
  {
    "cityId": 1928,
    "cityName": "Marataízes",
    "stateId": 5241
  },
  {
    "cityId": 1929,
    "cityName": "Marechal Floriano",
    "stateId": 5241
  },
  {
    "cityId": 1930,
    "cityName": "Marilândia",
    "stateId": 5241
  },
  {
    "cityId": 1931,
    "cityName": "Matilde",
    "stateId": 5241
  },
  {
    "cityId": 1932,
    "cityName": "Melgaço",
    "stateId": 5241
  },
  {
    "cityId": 1933,
    "cityName": "Menino Jesus",
    "stateId": 5241
  },
  {
    "cityId": 1934,
    "cityName": "Mimoso do Sul",
    "stateId": 5241
  },
  {
    "cityId": 1935,
    "cityName": "Montanha",
    "stateId": 5241
  },
  {
    "cityId": 1936,
    "cityName": "Monte Carmelo do Rio Novo",
    "stateId": 5241
  },
  {
    "cityId": 1937,
    "cityName": "Monte Pio",
    "stateId": 5241
  },
  {
    "cityId": 1938,
    "cityName": "Monte Sinai",
    "stateId": 5241
  },
  {
    "cityId": 1939,
    "cityName": "Mucurici",
    "stateId": 5241
  },
  {
    "cityId": 1940,
    "cityName": "Mundo Novo",
    "stateId": 5241
  },
  {
    "cityId": 1941,
    "cityName": "Muniz Freire",
    "stateId": 5241
  },
  {
    "cityId": 1942,
    "cityName": "Muqui",
    "stateId": 5241
  },
  {
    "cityId": 1943,
    "cityName": "Nestor Gomes",
    "stateId": 5241
  },
  {
    "cityId": 1944,
    "cityName": "Nova Almeida",
    "stateId": 5241
  },
  {
    "cityId": 1946,
    "cityName": "Nova Venécia",
    "stateId": 5241
  },
  {
    "cityId": 1947,
    "cityName": "Nova Verona",
    "stateId": 5241
  },
  {
    "cityId": 1948,
    "cityName": "Novo Brasil",
    "stateId": 5241
  },
  {
    "cityId": 1949,
    "cityName": "Novo Horizonte",
    "stateId": 5241
  },
  {
    "cityId": 1950,
    "cityName": "Pacotuba",
    "stateId": 5241
  },
  {
    "cityId": 1951,
    "cityName": "Paineiras",
    "stateId": 5241
  },
  {
    "cityId": 1952,
    "cityName": "Palmeira",
    "stateId": 5241
  },
  {
    "cityId": 1953,
    "cityName": "Palmerino",
    "stateId": 5241
  },
  {
    "cityId": 1954,
    "cityName": "Pancas",
    "stateId": 5241
  },
  {
    "cityId": 1955,
    "cityName": "Paraju",
    "stateId": 5241
  },
  {
    "cityId": 1956,
    "cityName": "Paulista",
    "stateId": 5241
  },
  {
    "cityId": 1957,
    "cityName": "Pedro Canário",
    "stateId": 5241
  },
  {
    "cityId": 1958,
    "cityName": "Pendanga",
    "stateId": 5241
  },
  {
    "cityId": 1959,
    "cityName": "Pequiá",
    "stateId": 5241
  },
  {
    "cityId": 1960,
    "cityName": "Nossa Senhora das Graças",
    "stateId": 5241
  },
  {
    "cityId": 1961,
    "cityName": "Piaçu",
    "stateId": 5241
  },
  {
    "cityId": 1962,
    "cityName": "Pinheiros",
    "stateId": 5241
  },
  {
    "cityId": 1963,
    "cityName": "Piracema",
    "stateId": 5241
  },
  {
    "cityId": 1964,
    "cityName": "Piúma",
    "stateId": 5241
  },
  {
    "cityId": 1965,
    "cityName": "Ponte de Itabapoana",
    "stateId": 5241
  },
  {
    "cityId": 1966,
    "cityName": "Ponto Belo",
    "stateId": 5241
  },
  {
    "cityId": 1967,
    "cityName": "Pontões",
    "stateId": 5241
  },
  {
    "cityId": 1968,
    "cityName": "Porangá",
    "stateId": 5241
  },
  {
    "cityId": 1969,
    "cityName": "Barra do Riacho",
    "stateId": 5241
  },
  {
    "cityId": 1970,
    "cityName": "Praia Grande",
    "stateId": 5241
  },
  {
    "cityId": 1971,
    "cityName": "Presidente Kennedy",
    "stateId": 5241
  },
  {
    "cityId": 1972,
    "cityName": "Princesa",
    "stateId": 5241
  },
  {
    "cityId": 1973,
    "cityName": "Queimado",
    "stateId": 5241
  },
  {
    "cityId": 1974,
    "cityName": "Quilômetro 14 do Mutum",
    "stateId": 5241
  },
  {
    "cityId": 1975,
    "cityName": "Regência",
    "stateId": 5241
  },
  {
    "cityId": 1976,
    "cityName": "Riacho",
    "stateId": 5241
  },
  {
    "cityId": 1977,
    "cityName": "Ribeirão do Cristo",
    "stateId": 5241
  },
  {
    "cityId": 1978,
    "cityName": "Rio Bananal",
    "stateId": 5241
  },
  {
    "cityId": 1979,
    "cityName": "Rio Calçado",
    "stateId": 5241
  },
  {
    "cityId": 1980,
    "cityName": "Rio Muqui",
    "stateId": 5241
  },
  {
    "cityId": 1981,
    "cityName": "Rio Novo do Sul",
    "stateId": 5241
  },
  {
    "cityId": 1982,
    "cityName": "Rio Preto",
    "stateId": 5241
  },
  {
    "cityId": 1983,
    "cityName": "Rive",
    "stateId": 5241
  },
  {
    "cityId": 1984,
    "cityName": "Sagrada Família",
    "stateId": 5241
  },
  {
    "cityId": 1985,
    "cityName": "Santa Angélica",
    "stateId": 5241
  },
  {
    "cityId": 1986,
    "cityName": "Santa Cruz",
    "stateId": 5241
  },
  {
    "cityId": 1987,
    "cityName": "Santa Júlia",
    "stateId": 5241
  },
  {
    "cityId": 1988,
    "cityName": "Santa Leopoldina",
    "stateId": 5241
  },
  {
    "cityId": 1989,
    "cityName": "Santa Luzia de Mantenópolis",
    "stateId": 5241
  },
  {
    "cityId": 1990,
    "cityName": "Santa Luzia do Azul",
    "stateId": 5241
  },
  {
    "cityId": 1991,
    "cityName": "Santa Luzia do Norte",
    "stateId": 5241
  },
  {
    "cityId": 1992,
    "cityName": "Santa Maria de Jetibá",
    "stateId": 5241
  },
  {
    "cityId": 1993,
    "cityName": "Santa Marta",
    "stateId": 5241
  },
  {
    "cityId": 1994,
    "cityName": "Santa Teresa",
    "stateId": 5241
  },
  {
    "cityId": 1995,
    "cityName": "Santa Terezinha",
    "stateId": 5241
  },
  {
    "cityId": 1996,
    "cityName": "Santíssima Trindade",
    "stateId": 5241
  },
  {
    "cityId": 1997,
    "cityName": "Santo Agostinho",
    "stateId": 5241
  },
  {
    "cityId": 1998,
    "cityName": "Santo Antônio",
    "stateId": 5241
  },
  {
    "cityId": 1999,
    "cityName": "Santo Antônio do Canaã",
    "stateId": 5241
  },
  {
    "cityId": 2000,
    "cityName": "Santo Antônio do Muqui",
    "stateId": 5241
  },
  {
    "cityId": 2001,
    "cityName": "Santo Antônio do Pousalegre",
    "stateId": 5241
  },
  {
    "cityId": 2002,
    "cityName": "Santo Antônio do Quinze",
    "stateId": 5241
  },
  {
    "cityId": 2003,
    "cityName": "São Domingos do Norte",
    "stateId": 5241
  },
  {
    "cityId": 2004,
    "cityName": "São Francisco",
    "stateId": 5241
  },
  {
    "cityId": 2005,
    "cityName": "São Francisco Xavier do Guandu",
    "stateId": 5241
  },
  {
    "cityId": 2006,
    "cityName": "São Gabriel da Palha",
    "stateId": 5241
  },
  {
    "cityId": 2007,
    "cityName": "São Geraldo",
    "stateId": 5241
  },
  {
    "cityId": 2008,
    "cityName": "São Jacinto",
    "stateId": 5241
  },
  {
    "cityId": 2009,
    "cityName": "São João de Petrópolis",
    "stateId": 5241
  },
  {
    "cityId": 2010,
    "cityName": "São João de Viçosa",
    "stateId": 5241
  },
  {
    "cityId": 2011,
    "cityName": "São João do Sobrado",
    "stateId": 5241
  },
  {
    "cityId": 2013,
    "cityName": "São Jorge do Oliveira",
    "stateId": 5241
  },
  {
    "cityId": 2014,
    "cityName": "São Jorge do Tiradentes",
    "stateId": 5241
  },
  {
    "cityId": 2015,
    "cityName": "São José das Torres",
    "stateId": 5241
  },
  {
    "cityId": 2016,
    "cityName": "São José de Mantenópolis",
    "stateId": 5241
  },
  {
    "cityId": 2017,
    "cityName": "São José do Calçado",
    "stateId": 5241
  },
  {
    "cityId": 2018,
    "cityName": "São José do Sobradinho",
    "stateId": 5241
  },
  {
    "cityId": 2019,
    "cityName": "São Mateus",
    "stateId": 5241
  },
  {
    "cityId": 2020,
    "cityName": "São Pedro",
    "stateId": 5241
  },
  {
    "cityId": 2021,
    "cityName": "São Pedro de Itabapoana",
    "stateId": 5241
  },
  {
    "cityId": 2022,
    "cityName": "São Pedro de Rates",
    "stateId": 5241
  },
  {
    "cityId": 2023,
    "cityName": "São Rafael",
    "stateId": 5241
  },
  {
    "cityId": 2024,
    "cityName": "São Roque do Canaã",
    "stateId": 5241
  },
  {
    "cityId": 2025,
    "cityName": "São Tiago",
    "stateId": 5241
  },
  {
    "cityId": 2027,
    "cityName": "Sapucaia",
    "stateId": 5241
  },
  {
    "cityId": 2028,
    "cityName": "Serra",
    "stateId": 5241
  },
  {
    "cityId": 2029,
    "cityName": "Serra Pelada",
    "stateId": 5241
  },
  {
    "cityId": 2030,
    "cityName": "Sobreiro",
    "stateId": 5241
  },
  {
    "cityId": 2031,
    "cityName": "Sooretama",
    "stateId": 5241
  },
  {
    "cityId": 2032,
    "cityName": "Timbuí",
    "stateId": 5241
  },
  {
    "cityId": 2033,
    "cityName": "Todos os Santos",
    "stateId": 5241
  },
  {
    "cityId": 2034,
    "cityName": "Urânia",
    "stateId": 5241
  },
  {
    "cityId": 2035,
    "cityName": "Vargem Alta",
    "stateId": 5241
  },
  {
    "cityId": 2036,
    "cityName": "Vargem Grande do Soturno",
    "stateId": 5241
  },
  {
    "cityId": 2037,
    "cityName": "Venda Nova do Imigrante",
    "stateId": 5241
  },
  {
    "cityId": 2038,
    "cityName": "Viana",
    "stateId": 5241
  },
  {
    "cityId": 2039,
    "cityName": "Vieira Machado",
    "stateId": 5241
  },
  {
    "cityId": 2040,
    "cityName": "Vila Nelita",
    "stateId": 5241
  },
  {
    "cityId": 2041,
    "cityName": "Vila Nova de Bananal",
    "stateId": 5241
  },
  {
    "cityId": 2042,
    "cityName": "Vila Pavão",
    "stateId": 5241
  },
  {
    "cityId": 2043,
    "cityName": "Vila Valério",
    "stateId": 5241
  },
  {
    "cityId": 2044,
    "cityName": "Vila Velha",
    "stateId": 5241
  },
  {
    "cityId": 2045,
    "cityName": "Vila Verde",
    "stateId": 5241
  },
  {
    "cityId": 2046,
    "cityName": "Vinhático",
    "stateId": 5241
  },
  {
    "cityId": 2047,
    "cityName": "Vinte e Cinco de Julho",
    "stateId": 5241
  },
  {
    "cityId": 2048,
    "cityName": "Vitória",
    "stateId": 5241
  },
  {
    "cityId": 2049,
    "cityName": "Abadia de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2050,
    "cityName": "Abadiânia",
    "stateId": 5242
  },
  {
    "cityId": 2051,
    "cityName": "Acreúna",
    "stateId": 5242
  },
  {
    "cityId": 2052,
    "cityName": "Adelândia",
    "stateId": 5242
  },
  {
    "cityId": 2053,
    "cityName": "Água Fria de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2054,
    "cityName": "Água Limpa",
    "stateId": 5242
  },
  {
    "cityId": 2055,
    "cityName": "Águas Lindas de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2056,
    "cityName": "Alexânia",
    "stateId": 5242
  },
  {
    "cityId": 2057,
    "cityName": "Aloândia",
    "stateId": 5242
  },
  {
    "cityId": 2058,
    "cityName": "Alto Alvorada",
    "stateId": 5242
  },
  {
    "cityId": 2059,
    "cityName": "Alto Horizonte",
    "stateId": 5242
  },
  {
    "cityId": 2060,
    "cityName": "Alto Paraíso de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2061,
    "cityName": "Alvorada do Norte",
    "stateId": 5242
  },
  {
    "cityId": 2062,
    "cityName": "Amaralina",
    "stateId": 5242
  },
  {
    "cityId": 2063,
    "cityName": "Americano do Brasil",
    "stateId": 5242
  },
  {
    "cityId": 2064,
    "cityName": "Amorinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2065,
    "cityName": "Anápolis",
    "stateId": 5242
  },
  {
    "cityId": 2066,
    "cityName": "Anhangüera",
    "stateId": 5242
  },
  {
    "cityId": 2067,
    "cityName": "Anicuns",
    "stateId": 5242
  },
  {
    "cityId": 2068,
    "cityName": "Aparecida",
    "stateId": 5242
  },
  {
    "cityId": 2069,
    "cityName": "Aparecida de Goiânia",
    "stateId": 5242
  },
  {
    "cityId": 2070,
    "cityName": "Aparecida de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2071,
    "cityName": "Aparecida do Rio Claro",
    "stateId": 5242
  },
  {
    "cityId": 2072,
    "cityName": "Aparecida do Rio Doce",
    "stateId": 5242
  },
  {
    "cityId": 2073,
    "cityName": "Aporé",
    "stateId": 5242
  },
  {
    "cityId": 2074,
    "cityName": "Araçu",
    "stateId": 5242
  },
  {
    "cityId": 2075,
    "cityName": "Aragarças",
    "stateId": 5242
  },
  {
    "cityId": 2076,
    "cityName": "Aragoiânia",
    "stateId": 5242
  },
  {
    "cityId": 2077,
    "cityName": "Araguapaz",
    "stateId": 5242
  },
  {
    "cityId": 2078,
    "cityName": "Arenópolis",
    "stateId": 5242
  },
  {
    "cityId": 2079,
    "cityName": "Aruanã",
    "stateId": 5242
  },
  {
    "cityId": 2080,
    "cityName": "Aurilândia",
    "stateId": 5242
  },
  {
    "cityId": 2081,
    "cityName": "Auriverde",
    "stateId": 5242
  },
  {
    "cityId": 2082,
    "cityName": "Avelinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2083,
    "cityName": "Bacilândia",
    "stateId": 5242
  },
  {
    "cityId": 2084,
    "cityName": "Baliza",
    "stateId": 5242
  },
  {
    "cityId": 2085,
    "cityName": "Bandeirantes",
    "stateId": 5242
  },
  {
    "cityId": 2086,
    "cityName": "Barbosilândia",
    "stateId": 5242
  },
  {
    "cityId": 2087,
    "cityName": "Barro Alto",
    "stateId": 5242
  },
  {
    "cityId": 2088,
    "cityName": "Bela Vista de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2089,
    "cityName": "Bom Jardim de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2090,
    "cityName": "Bom Jesus de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2091,
    "cityName": "Bonfinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2092,
    "cityName": "Bonópolis",
    "stateId": 5242
  },
  {
    "cityId": 2093,
    "cityName": "Brazabrantes",
    "stateId": 5242
  },
  {
    "cityId": 2094,
    "cityName": "Britânia",
    "stateId": 5242
  },
  {
    "cityId": 2095,
    "cityName": "Buenolândia",
    "stateId": 5242
  },
  {
    "cityId": 2096,
    "cityName": "Buriti Alegre",
    "stateId": 5242
  },
  {
    "cityId": 2097,
    "cityName": "Buriti de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2098,
    "cityName": "Buritinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2099,
    "cityName": "Cabeceiras",
    "stateId": 5242
  },
  {
    "cityId": 2100,
    "cityName": "Cachoeira Alta",
    "stateId": 5242
  },
  {
    "cityId": 2101,
    "cityName": "Cachoeira de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2102,
    "cityName": "Cachoeira Dourada",
    "stateId": 5242
  },
  {
    "cityId": 2103,
    "cityName": "Caçu",
    "stateId": 5242
  },
  {
    "cityId": 2104,
    "cityName": "Caiapônia",
    "stateId": 5242
  },
  {
    "cityId": 2105,
    "cityName": "Caiçara",
    "stateId": 5242
  },
  {
    "cityId": 2106,
    "cityName": "Calcilândia",
    "stateId": 5242
  },
  {
    "cityId": 2107,
    "cityName": "Caldas Novas",
    "stateId": 5242
  },
  {
    "cityId": 2108,
    "cityName": "Caldazinha",
    "stateId": 5242
  },
  {
    "cityId": 2109,
    "cityName": "Calixto",
    "stateId": 5242
  },
  {
    "cityId": 2110,
    "cityName": "Campestre de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2111,
    "cityName": "Campinaçu",
    "stateId": 5242
  },
  {
    "cityId": 2112,
    "cityName": "Campinorte",
    "stateId": 5242
  },
  {
    "cityId": 2113,
    "cityName": "Campo Alegre de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2114,
    "cityName": "Campo Limpo",
    "stateId": 5242
  },
  {
    "cityId": 2115,
    "cityName": "Campolândia",
    "stateId": 5242
  },
  {
    "cityId": 2116,
    "cityName": "Campos Belos",
    "stateId": 5242
  },
  {
    "cityId": 2117,
    "cityName": "Campos Verdes",
    "stateId": 5242
  },
  {
    "cityId": 2118,
    "cityName": "Cana Brava",
    "stateId": 5242
  },
  {
    "cityId": 2119,
    "cityName": "Canadá",
    "stateId": 5242
  },
  {
    "cityId": 2120,
    "cityName": "Capelinha",
    "stateId": 5242
  },
  {
    "cityId": 2121,
    "cityName": "Caraíba",
    "stateId": 5242
  },
  {
    "cityId": 2122,
    "cityName": "Carmo do Rio Verde",
    "stateId": 5242
  },
  {
    "cityId": 2123,
    "cityName": "Castelândia",
    "stateId": 5242
  },
  {
    "cityId": 2124,
    "cityName": "Castrinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2125,
    "cityName": "Catalão",
    "stateId": 5242
  },
  {
    "cityId": 2126,
    "cityName": "Caturaí",
    "stateId": 5242
  },
  {
    "cityId": 2127,
    "cityName": "Cavalcante",
    "stateId": 5242
  },
  {
    "cityId": 2128,
    "cityName": "Cavalheiro",
    "stateId": 5242
  },
  {
    "cityId": 2129,
    "cityName": "Cebrasa",
    "stateId": 5242
  },
  {
    "cityId": 2130,
    "cityName": "Ceres",
    "stateId": 5242
  },
  {
    "cityId": 2131,
    "cityName": "Cezarina",
    "stateId": 5242
  },
  {
    "cityId": 2132,
    "cityName": "Chapadão do Céu",
    "stateId": 5242
  },
  {
    "cityId": 2133,
    "cityName": "Choupana",
    "stateId": 5242
  },
  {
    "cityId": 2134,
    "cityName": "Cibele",
    "stateId": 5242
  },
  {
    "cityId": 2135,
    "cityName": "Cidade Ocidental",
    "stateId": 5242
  },
  {
    "cityId": 2136,
    "cityName": "Cirilândia",
    "stateId": 5242
  },
  {
    "cityId": 2137,
    "cityName": "Cocalzinho de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2138,
    "cityName": "Colinas do Sul",
    "stateId": 5242
  },
  {
    "cityId": 2139,
    "cityName": "Córrego do Ouro",
    "stateId": 5242
  },
  {
    "cityId": 2140,
    "cityName": "Córrego Rico",
    "stateId": 5242
  },
  {
    "cityId": 2141,
    "cityName": "Corumbá de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2142,
    "cityName": "Corumbaíba",
    "stateId": 5242
  },
  {
    "cityId": 2143,
    "cityName": "Cristalina",
    "stateId": 5242
  },
  {
    "cityId": 2144,
    "cityName": "Cristianópolis",
    "stateId": 5242
  },
  {
    "cityId": 2145,
    "cityName": "Crixás",
    "stateId": 5242
  },
  {
    "cityId": 2146,
    "cityName": "Cromínia",
    "stateId": 5242
  },
  {
    "cityId": 2147,
    "cityName": "Cruzeiro do Norte",
    "stateId": 5242
  },
  {
    "cityId": 2148,
    "cityName": "Cumari",
    "stateId": 5242
  },
  {
    "cityId": 2149,
    "cityName": "Damianópolis",
    "stateId": 5242
  },
  {
    "cityId": 2150,
    "cityName": "Damolândia",
    "stateId": 5242
  },
  {
    "cityId": 2151,
    "cityName": "Davidópolis",
    "stateId": 5242
  },
  {
    "cityId": 2152,
    "cityName": "Davinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2153,
    "cityName": "Diolândia",
    "stateId": 5242
  },
  {
    "cityId": 2154,
    "cityName": "Diorama",
    "stateId": 5242
  },
  {
    "cityId": 2155,
    "cityName": "Divinópolis de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2156,
    "cityName": "Domiciano Ribeiro",
    "stateId": 5242
  },
  {
    "cityId": 2157,
    "cityName": "Doverlândia",
    "stateId": 5242
  },
  {
    "cityId": 2158,
    "cityName": "Edealina",
    "stateId": 5242
  },
  {
    "cityId": 2159,
    "cityName": "Edéia",
    "stateId": 5242
  },
  {
    "cityId": 2160,
    "cityName": "Estrela do Norte",
    "stateId": 5242
  },
  {
    "cityId": 2161,
    "cityName": "Faina",
    "stateId": 5242
  },
  {
    "cityId": 2162,
    "cityName": "Fazenda Nova",
    "stateId": 5242
  },
  {
    "cityId": 2163,
    "cityName": "Firminópolis",
    "stateId": 5242
  },
  {
    "cityId": 2164,
    "cityName": "Flores de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2165,
    "cityName": "Formosa",
    "stateId": 5242
  },
  {
    "cityId": 2166,
    "cityName": "Formoso",
    "stateId": 5242
  },
  {
    "cityId": 2167,
    "cityName": "Forte",
    "stateId": 5242
  },
  {
    "cityId": 2168,
    "cityName": "Gameleira de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2169,
    "cityName": "Geriaçu",
    "stateId": 5242
  },
  {
    "cityId": 2170,
    "cityName": "Goialândia",
    "stateId": 5242
  },
  {
    "cityId": 2171,
    "cityName": "Goianápolis",
    "stateId": 5242
  },
  {
    "cityId": 2172,
    "cityName": "Goiandira",
    "stateId": 5242
  },
  {
    "cityId": 2173,
    "cityName": "Goianésia",
    "stateId": 5242
  },
  {
    "cityId": 2174,
    "cityName": "Goiânia",
    "stateId": 5242
  },
  {
    "cityId": 2175,
    "cityName": "Goianira",
    "stateId": 5242
  },
  {
    "cityId": 2176,
    "cityName": "Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2177,
    "cityName": "Goiatuba",
    "stateId": 5242
  },
  {
    "cityId": 2178,
    "cityName": "Gouvelândia",
    "stateId": 5242
  },
  {
    "cityId": 2179,
    "cityName": "Guapó",
    "stateId": 5242
  },
  {
    "cityId": 2180,
    "cityName": "Guaraíta",
    "stateId": 5242
  },
  {
    "cityId": 2181,
    "cityName": "Guarani de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2182,
    "cityName": "Guarinos",
    "stateId": 5242
  },
  {
    "cityId": 2183,
    "cityName": "Heitoraí",
    "stateId": 5242
  },
  {
    "cityId": 2184,
    "cityName": "Hidrolândia",
    "stateId": 5242
  },
  {
    "cityId": 2185,
    "cityName": "Hidrolina",
    "stateId": 5242
  },
  {
    "cityId": 2186,
    "cityName": "Iaciara",
    "stateId": 5242
  },
  {
    "cityId": 2187,
    "cityName": "Inaciolândia",
    "stateId": 5242
  },
  {
    "cityId": 2188,
    "cityName": "Indiara",
    "stateId": 5242
  },
  {
    "cityId": 2189,
    "cityName": "Inhumas",
    "stateId": 5242
  },
  {
    "cityId": 2190,
    "cityName": "Interlândia",
    "stateId": 5242
  },
  {
    "cityId": 2191,
    "cityName": "Ipameri",
    "stateId": 5242
  },
  {
    "cityId": 2192,
    "cityName": "Iporá",
    "stateId": 5242
  },
  {
    "cityId": 2193,
    "cityName": "Israelândia",
    "stateId": 5242
  },
  {
    "cityId": 2194,
    "cityName": "Itaberaí",
    "stateId": 5242
  },
  {
    "cityId": 2195,
    "cityName": "Itaguaçu",
    "stateId": 5242
  },
  {
    "cityId": 2196,
    "cityName": "Itaguari",
    "stateId": 5242
  },
  {
    "cityId": 2197,
    "cityName": "Itaguaru",
    "stateId": 5242
  },
  {
    "cityId": 2198,
    "cityName": "Itajá",
    "stateId": 5242
  },
  {
    "cityId": 2199,
    "cityName": "Itapaci",
    "stateId": 5242
  },
  {
    "cityId": 2200,
    "cityName": "Itapirapuã",
    "stateId": 5242
  },
  {
    "cityId": 2201,
    "cityName": "Itapuranga",
    "stateId": 5242
  },
  {
    "cityId": 2202,
    "cityName": "Itarumã",
    "stateId": 5242
  },
  {
    "cityId": 2203,
    "cityName": "Itauçu",
    "stateId": 5242
  },
  {
    "cityId": 2204,
    "cityName": "Itumbiara",
    "stateId": 5242
  },
  {
    "cityId": 2205,
    "cityName": "Ivolândia",
    "stateId": 5242
  },
  {
    "cityId": 2206,
    "cityName": "Jacilândia",
    "stateId": 5242
  },
  {
    "cityId": 2207,
    "cityName": "Jandaia",
    "stateId": 5242
  },
  {
    "cityId": 2208,
    "cityName": "Jaraguá",
    "stateId": 5242
  },
  {
    "cityId": 2209,
    "cityName": "Jataí",
    "stateId": 5242
  },
  {
    "cityId": 2210,
    "cityName": "Jaupaci",
    "stateId": 5242
  },
  {
    "cityId": 2211,
    "cityName": "Jeroaquara",
    "stateId": 5242
  },
  {
    "cityId": 2212,
    "cityName": "Jesúpolis",
    "stateId": 5242
  },
  {
    "cityId": 2213,
    "cityName": "Joanápolis",
    "stateId": 5242
  },
  {
    "cityId": 2214,
    "cityName": "Joviânia",
    "stateId": 5242
  },
  {
    "cityId": 2216,
    "cityName": "Juscelino Kubitschek",
    "stateId": 5242
  },
  {
    "cityId": 2217,
    "cityName": "Jussara",
    "stateId": 5242
  },
  {
    "cityId": 2218,
    "cityName": "Lagoa do Bauzinho",
    "stateId": 5242
  },
  {
    "cityId": 2219,
    "cityName": "Lagolândia",
    "stateId": 5242
  },
  {
    "cityId": 2220,
    "cityName": "Leopoldo de Bulhões",
    "stateId": 5242
  },
  {
    "cityId": 2221,
    "cityName": "Lucilândia",
    "stateId": 5242
  },
  {
    "cityId": 2222,
    "cityName": "Luziânia",
    "stateId": 5242
  },
  {
    "cityId": 2223,
    "cityName": "Mairipotaba",
    "stateId": 5242
  },
  {
    "cityId": 2224,
    "cityName": "Mambaí",
    "stateId": 5242
  },
  {
    "cityId": 2225,
    "cityName": "Mara Rosa",
    "stateId": 5242
  },
  {
    "cityId": 2226,
    "cityName": "Marcianópolis",
    "stateId": 5242
  },
  {
    "cityId": 2227,
    "cityName": "Marzagão",
    "stateId": 5242
  },
  {
    "cityId": 2228,
    "cityName": "Matrinchã",
    "stateId": 5242
  },
  {
    "cityId": 2229,
    "cityName": "Maurilândia",
    "stateId": 5242
  },
  {
    "cityId": 2230,
    "cityName": "Meia Ponte",
    "stateId": 5242
  },
  {
    "cityId": 2231,
    "cityName": "Messianópolis",
    "stateId": 5242
  },
  {
    "cityId": 2232,
    "cityName": "Mimoso de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2233,
    "cityName": "Minaçu",
    "stateId": 5242
  },
  {
    "cityId": 2234,
    "cityName": "Mineiros",
    "stateId": 5242
  },
  {
    "cityId": 2235,
    "cityName": "Moiporá",
    "stateId": 5242
  },
  {
    "cityId": 2236,
    "cityName": "Monte Alegre de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2237,
    "cityName": "Montes Claros de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2238,
    "cityName": "Montividiu",
    "stateId": 5242
  },
  {
    "cityId": 2239,
    "cityName": "Montividiu do Norte",
    "stateId": 5242
  },
  {
    "cityId": 2240,
    "cityName": "Morrinhos",
    "stateId": 5242
  },
  {
    "cityId": 2241,
    "cityName": "Morro Agudo de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2242,
    "cityName": "Mossâmedes",
    "stateId": 5242
  },
  {
    "cityId": 2243,
    "cityName": "Mozarlândia",
    "stateId": 5242
  },
  {
    "cityId": 2244,
    "cityName": "Mundo Novo",
    "stateId": 5242
  },
  {
    "cityId": 2245,
    "cityName": "Mutunópolis",
    "stateId": 5242
  },
  {
    "cityId": 2246,
    "cityName": "Natinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2247,
    "cityName": "Nazário",
    "stateId": 5242
  },
  {
    "cityId": 2248,
    "cityName": "Nerópolis",
    "stateId": 5242
  },
  {
    "cityId": 2249,
    "cityName": "Niquelândia",
    "stateId": 5242
  },
  {
    "cityId": 2250,
    "cityName": "Nova América",
    "stateId": 5242
  },
  {
    "cityId": 2251,
    "cityName": "Nova Aurora",
    "stateId": 5242
  },
  {
    "cityId": 2252,
    "cityName": "Nova Crixás",
    "stateId": 5242
  },
  {
    "cityId": 2253,
    "cityName": "Nova Glória",
    "stateId": 5242
  },
  {
    "cityId": 2254,
    "cityName": "Nova Iguaçu de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2255,
    "cityName": "Nova Roma",
    "stateId": 5242
  },
  {
    "cityId": 2256,
    "cityName": "Nova Veneza",
    "stateId": 5242
  },
  {
    "cityId": 2257,
    "cityName": "Novo Brasil",
    "stateId": 5242
  },
  {
    "cityId": 2258,
    "cityName": "Novo Gama",
    "stateId": 5242
  },
  {
    "cityId": 2259,
    "cityName": "Novo Planalto",
    "stateId": 5242
  },
  {
    "cityId": 2260,
    "cityName": "Olaria do Angico",
    "stateId": 5242
  },
  {
    "cityId": 2261,
    "cityName": "Olhos D'Água",
    "stateId": 5242
  },
  {
    "cityId": 2262,
    "cityName": "Orizona",
    "stateId": 5242
  },
  {
    "cityId": 2263,
    "cityName": "Ouro Verde de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2264,
    "cityName": "Ouroana",
    "stateId": 5242
  },
  {
    "cityId": 2265,
    "cityName": "Ouvidor",
    "stateId": 5242
  },
  {
    "cityId": 2266,
    "cityName": "Padre Bernardo",
    "stateId": 5242
  },
  {
    "cityId": 2267,
    "cityName": "Palestina de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2268,
    "cityName": "Palmeiras de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2269,
    "cityName": "Palmelo",
    "stateId": 5242
  },
  {
    "cityId": 2270,
    "cityName": "Palminópolis",
    "stateId": 5242
  },
  {
    "cityId": 2271,
    "cityName": "Panamá",
    "stateId": 5242
  },
  {
    "cityId": 2272,
    "cityName": "Paranaiguara",
    "stateId": 5242
  },
  {
    "cityId": 2273,
    "cityName": "Paraúna",
    "stateId": 5242
  },
  {
    "cityId": 2274,
    "cityName": "Colinaçu",
    "stateId": 5242
  },
  {
    "cityId": 2275,
    "cityName": "Pedra Branca",
    "stateId": 5242
  },
  {
    "cityId": 2276,
    "cityName": "Perolândia",
    "stateId": 5242
  },
  {
    "cityId": 2277,
    "cityName": "Petrolina de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2278,
    "cityName": "Pilar de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2279,
    "cityName": "Piloândia",
    "stateId": 5242
  },
  {
    "cityId": 2280,
    "cityName": "Piracanjuba",
    "stateId": 5242
  },
  {
    "cityId": 2281,
    "cityName": "Piranhas",
    "stateId": 5242
  },
  {
    "cityId": 2282,
    "cityName": "Pirenópolis",
    "stateId": 5242
  },
  {
    "cityId": 2283,
    "cityName": "Pires Belo",
    "stateId": 5242
  },
  {
    "cityId": 2284,
    "cityName": "Pires do Rio",
    "stateId": 5242
  },
  {
    "cityId": 2285,
    "cityName": "Planaltina de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2286,
    "cityName": "Pontalina",
    "stateId": 5242
  },
  {
    "cityId": 2287,
    "cityName": "Porangatu",
    "stateId": 5242
  },
  {
    "cityId": 2288,
    "cityName": "Porteirão",
    "stateId": 5242
  },
  {
    "cityId": 2289,
    "cityName": "Portelândia",
    "stateId": 5242
  },
  {
    "cityId": 2290,
    "cityName": "Posse",
    "stateId": 5242
  },
  {
    "cityId": 2291,
    "cityName": "Posse D'Abadia",
    "stateId": 5242
  },
  {
    "cityId": 2292,
    "cityName": "Professor Jamil",
    "stateId": 5242
  },
  {
    "cityId": 2293,
    "cityName": "Quirinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2294,
    "cityName": "Registro do Araguaia",
    "stateId": 5242
  },
  {
    "cityId": 2295,
    "cityName": "Rianápolis",
    "stateId": 5242
  },
  {
    "cityId": 2297,
    "cityName": "Rio Quente",
    "stateId": 5242
  },
  {
    "cityId": 2298,
    "cityName": "Rio Verde",
    "stateId": 5242
  },
  {
    "cityId": 2299,
    "cityName": "Riverlândia",
    "stateId": 5242
  },
  {
    "cityId": 2300,
    "cityName": "Campo Limpo de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2301,
    "cityName": "Rosalândia",
    "stateId": 5242
  },
  {
    "cityId": 2302,
    "cityName": "Rubiataba",
    "stateId": 5242
  },
  {
    "cityId": 2303,
    "cityName": "Sanclerlândia",
    "stateId": 5242
  },
  {
    "cityId": 2304,
    "cityName": "Santa Bárbara de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2305,
    "cityName": "Santa Cruz das Lajes",
    "stateId": 5242
  },
  {
    "cityId": 2306,
    "cityName": "Santa Cruz de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2307,
    "cityName": "Santa Fé de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2308,
    "cityName": "Santa Helena de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2309,
    "cityName": "Santa Isabel",
    "stateId": 5242
  },
  {
    "cityId": 2310,
    "cityName": "Santa Rita do Araguaia",
    "stateId": 5242
  },
  {
    "cityId": 2311,
    "cityName": "Santa Rita do Novo Destino",
    "stateId": 5242
  },
  {
    "cityId": 2312,
    "cityName": "Santa Rosa",
    "stateId": 5242
  },
  {
    "cityId": 2313,
    "cityName": "Santa Rosa de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2314,
    "cityName": "Santa Tereza de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2315,
    "cityName": "Santa Terezinha de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2316,
    "cityName": "Santo Antônio da Barra",
    "stateId": 5242
  },
  {
    "cityId": 2317,
    "cityName": "Santo Antônio de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2318,
    "cityName": "Santo Antônio do Descoberto",
    "stateId": 5242
  },
  {
    "cityId": 2319,
    "cityName": "Santo Antônio do Rio Verde",
    "stateId": 5242
  },
  {
    "cityId": 2320,
    "cityName": "São Domingos",
    "stateId": 5242
  },
  {
    "cityId": 2321,
    "cityName": "São Francisco de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2322,
    "cityName": "São Gabriel de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2323,
    "cityName": "São João",
    "stateId": 5242
  },
  {
    "cityId": 2324,
    "cityName": "São João D'Aliança",
    "stateId": 5242
  },
  {
    "cityId": 2325,
    "cityName": "São João da Paraúna",
    "stateId": 5242
  },
  {
    "cityId": 2326,
    "cityName": "São Luís de Montes Belos",
    "stateId": 5242
  },
  {
    "cityId": 2327,
    "cityName": "São Luiz do Norte",
    "stateId": 5242
  },
  {
    "cityId": 2328,
    "cityName": "São Luiz do Tocantins",
    "stateId": 5242
  },
  {
    "cityId": 2329,
    "cityName": "São Miguel do Araguaia",
    "stateId": 5242
  },
  {
    "cityId": 2330,
    "cityName": "São Miguel do Passa Quatro",
    "stateId": 5242
  },
  {
    "cityId": 2331,
    "cityName": "São Patrício",
    "stateId": 5242
  },
  {
    "cityId": 2332,
    "cityName": "São Sebastião do Rio Claro",
    "stateId": 5242
  },
  {
    "cityId": 2333,
    "cityName": "São Simão",
    "stateId": 5242
  },
  {
    "cityId": 2334,
    "cityName": "São Vicente",
    "stateId": 5242
  },
  {
    "cityId": 2335,
    "cityName": "Sarandi",
    "stateId": 5242
  },
  {
    "cityId": 2336,
    "cityName": "Senador Canedo",
    "stateId": 5242
  },
  {
    "cityId": 2337,
    "cityName": "Serra Dourada",
    "stateId": 5242
  },
  {
    "cityId": 2338,
    "cityName": "Serranópolis",
    "stateId": 5242
  },
  {
    "cityId": 2339,
    "cityName": "Silvânia",
    "stateId": 5242
  },
  {
    "cityId": 2340,
    "cityName": "Simolândia",
    "stateId": 5242
  },
  {
    "cityId": 2341,
    "cityName": "Sítio D'Abadia",
    "stateId": 5242
  },
  {
    "cityId": 2342,
    "cityName": "Sousânia",
    "stateId": 5242
  },
  {
    "cityId": 2343,
    "cityName": "Taquaral de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2344,
    "cityName": "Taveira",
    "stateId": 5242
  },
  {
    "cityId": 2345,
    "cityName": "Teresina de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2346,
    "cityName": "Terezópolis de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2347,
    "cityName": "Lagoa Santa",
    "stateId": 5242
  },
  {
    "cityId": 2348,
    "cityName": "Três Ranchos",
    "stateId": 5242
  },
  {
    "cityId": 2349,
    "cityName": "Trindade",
    "stateId": 5242
  },
  {
    "cityId": 2350,
    "cityName": "Trombas",
    "stateId": 5242
  },
  {
    "cityId": 2351,
    "cityName": "Tupiracaba",
    "stateId": 5242
  },
  {
    "cityId": 2352,
    "cityName": "Turvânia",
    "stateId": 5242
  },
  {
    "cityId": 2353,
    "cityName": "Turvelândia",
    "stateId": 5242
  },
  {
    "cityId": 2354,
    "cityName": "Uirapuru",
    "stateId": 5242
  },
  {
    "cityId": 2355,
    "cityName": "Uruaçu",
    "stateId": 5242
  },
  {
    "cityId": 2356,
    "cityName": "Uruana",
    "stateId": 5242
  },
  {
    "cityId": 2357,
    "cityName": "Uruita",
    "stateId": 5242
  },
  {
    "cityId": 2358,
    "cityName": "Urutaí",
    "stateId": 5242
  },
  {
    "cityId": 2359,
    "cityName": "Uvá",
    "stateId": 5242
  },
  {
    "cityId": 2360,
    "cityName": "Valdelândia",
    "stateId": 5242
  },
  {
    "cityId": 2361,
    "cityName": "Valparaíso de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 2362,
    "cityName": "Varjão",
    "stateId": 5242
  },
  {
    "cityId": 2363,
    "cityName": "Vianópolis",
    "stateId": 5242
  },
  {
    "cityId": 2364,
    "cityName": "Vicentinópolis",
    "stateId": 5242
  },
  {
    "cityId": 2365,
    "cityName": "Vila Boa",
    "stateId": 5242
  },
  {
    "cityId": 2367,
    "cityName": "Vila Propício",
    "stateId": 5242
  },
  {
    "cityId": 2368,
    "cityName": "Vila Sertaneja",
    "stateId": 5242
  },
  {
    "cityId": 2369,
    "cityName": "Açailândia",
    "stateId": 5243
  },
  {
    "cityId": 2370,
    "cityName": "Afonso Cunha",
    "stateId": 5243
  },
  {
    "cityId": 2371,
    "cityName": "Água Doce do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2372,
    "cityName": "Alcântara",
    "stateId": 5243
  },
  {
    "cityId": 2373,
    "cityName": "Aldeias Altas",
    "stateId": 5243
  },
  {
    "cityId": 2374,
    "cityName": "Altamira do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2375,
    "cityName": "Alto Alegre do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2376,
    "cityName": "Alto Alegre do Pindaré",
    "stateId": 5243
  },
  {
    "cityId": 2377,
    "cityName": "Alto Parnaíba",
    "stateId": 5243
  },
  {
    "cityId": 2378,
    "cityName": "Amapá do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2379,
    "cityName": "Amarante do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2380,
    "cityName": "Anajatuba",
    "stateId": 5243
  },
  {
    "cityId": 2381,
    "cityName": "Anapurus",
    "stateId": 5243
  },
  {
    "cityId": 2382,
    "cityName": "Anil",
    "stateId": 5243
  },
  {
    "cityId": 2383,
    "cityName": "Apicum-Açu",
    "stateId": 5243
  },
  {
    "cityId": 2384,
    "cityName": "Araguanã",
    "stateId": 5243
  },
  {
    "cityId": 2385,
    "cityName": "Araióses",
    "stateId": 5243
  },
  {
    "cityId": 2386,
    "cityName": "Arame",
    "stateId": 5243
  },
  {
    "cityId": 2387,
    "cityName": "Arari",
    "stateId": 5243
  },
  {
    "cityId": 2388,
    "cityName": "Aurizona",
    "stateId": 5243
  },
  {
    "cityId": 2389,
    "cityName": "Axixá",
    "stateId": 5243
  },
  {
    "cityId": 2390,
    "cityName": "Bacabal",
    "stateId": 5243
  },
  {
    "cityId": 2391,
    "cityName": "Bacabeira",
    "stateId": 5243
  },
  {
    "cityId": 2392,
    "cityName": "Bacatuba",
    "stateId": 5243
  },
  {
    "cityId": 2393,
    "cityName": "Bacuri",
    "stateId": 5243
  },
  {
    "cityId": 2394,
    "cityName": "Bacurituba",
    "stateId": 5243
  },
  {
    "cityId": 2395,
    "cityName": "Balsas",
    "stateId": 5243
  },
  {
    "cityId": 2396,
    "cityName": "Barão de Grajaú",
    "stateId": 5243
  },
  {
    "cityId": 2397,
    "cityName": "Barão de Tromai",
    "stateId": 5243
  },
  {
    "cityId": 2398,
    "cityName": "Barra do Corda",
    "stateId": 5243
  },
  {
    "cityId": 2399,
    "cityName": "Barreirinhas",
    "stateId": 5243
  },
  {
    "cityId": 2400,
    "cityName": "Barro Duro",
    "stateId": 5243
  },
  {
    "cityId": 2401,
    "cityName": "Bela Vista do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2402,
    "cityName": "Belágua",
    "stateId": 5243
  },
  {
    "cityId": 2403,
    "cityName": "Benedito Leite",
    "stateId": 5243
  },
  {
    "cityId": 2404,
    "cityName": "Bequimão",
    "stateId": 5243
  },
  {
    "cityId": 2405,
    "cityName": "Bernardo do Mearim",
    "stateId": 5243
  },
  {
    "cityId": 2406,
    "cityName": "Boa Vista do Gurupi",
    "stateId": 5243
  },
  {
    "cityId": 2407,
    "cityName": "Boa Vista do Pindaré",
    "stateId": 5243
  },
  {
    "cityId": 2408,
    "cityName": "Bom Jardim",
    "stateId": 5243
  },
  {
    "cityId": 2409,
    "cityName": "Bom Jesus das Selvas",
    "stateId": 5243
  },
  {
    "cityId": 2410,
    "cityName": "Bom Lugar",
    "stateId": 5243
  },
  {
    "cityId": 2411,
    "cityName": "Bonfim do Arari",
    "stateId": 5243
  },
  {
    "cityId": 2412,
    "cityName": "Brejo",
    "stateId": 5243
  },
  {
    "cityId": 2413,
    "cityName": "Brejo de Areia",
    "stateId": 5243
  },
  {
    "cityId": 2414,
    "cityName": "Brejo de São Félix",
    "stateId": 5243
  },
  {
    "cityId": 2415,
    "cityName": "Buriti",
    "stateId": 5243
  },
  {
    "cityId": 2416,
    "cityName": "Buriti Bravo",
    "stateId": 5243
  },
  {
    "cityId": 2417,
    "cityName": "Buriti Cortado",
    "stateId": 5243
  },
  {
    "cityId": 2418,
    "cityName": "Buriticupu",
    "stateId": 5243
  },
  {
    "cityId": 2419,
    "cityName": "Buritirama",
    "stateId": 5243
  },
  {
    "cityId": 2420,
    "cityName": "Cachoeira Grande",
    "stateId": 5243
  },
  {
    "cityId": 2421,
    "cityName": "Cajapió",
    "stateId": 5243
  },
  {
    "cityId": 2422,
    "cityName": "Cajari",
    "stateId": 5243
  },
  {
    "cityId": 2423,
    "cityName": "Campestre do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2424,
    "cityName": "Cândido Mendes",
    "stateId": 5243
  },
  {
    "cityId": 2425,
    "cityName": "Cantanhede",
    "stateId": 5243
  },
  {
    "cityId": 2426,
    "cityName": "Capinzal do Norte",
    "stateId": 5243
  },
  {
    "cityId": 2427,
    "cityName": "Caraíba do Norte",
    "stateId": 5243
  },
  {
    "cityId": 2428,
    "cityName": "Carolina",
    "stateId": 5243
  },
  {
    "cityId": 2429,
    "cityName": "Carutapera",
    "stateId": 5243
  },
  {
    "cityId": 2430,
    "cityName": "Caxias",
    "stateId": 5243
  },
  {
    "cityId": 2431,
    "cityName": "Cedral",
    "stateId": 5243
  },
  {
    "cityId": 2432,
    "cityName": "Central do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2433,
    "cityName": "Centro do Guilherme",
    "stateId": 5243
  },
  {
    "cityId": 2434,
    "cityName": "Centro Novo do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2435,
    "cityName": "Chapadinha",
    "stateId": 5243
  },
  {
    "cityId": 2436,
    "cityName": "Cidelândia",
    "stateId": 5243
  },
  {
    "cityId": 2437,
    "cityName": "Codó",
    "stateId": 5243
  },
  {
    "cityId": 2438,
    "cityName": "Codozinho",
    "stateId": 5243
  },
  {
    "cityId": 2439,
    "cityName": "Coelho Neto",
    "stateId": 5243
  },
  {
    "cityId": 2440,
    "cityName": "Colinas",
    "stateId": 5243
  },
  {
    "cityId": 2441,
    "cityName": "Conceição do Lago-Açu",
    "stateId": 5243
  },
  {
    "cityId": 2442,
    "cityName": "Coroatá",
    "stateId": 5243
  },
  {
    "cityId": 2443,
    "cityName": "Curupa",
    "stateId": 5243
  },
  {
    "cityId": 2444,
    "cityName": "Cururupu",
    "stateId": 5243
  },
  {
    "cityId": 2445,
    "cityName": "Curva Grande",
    "stateId": 5243
  },
  {
    "cityId": 2446,
    "cityName": "Custódio Lima",
    "stateId": 5243
  },
  {
    "cityId": 2447,
    "cityName": "Davinópolis",
    "stateId": 5243
  },
  {
    "cityId": 2448,
    "cityName": "Dom Pedro",
    "stateId": 5243
  },
  {
    "cityId": 2449,
    "cityName": "Duque Bacelar",
    "stateId": 5243
  },
  {
    "cityId": 2450,
    "cityName": "Esperantinópolis",
    "stateId": 5243
  },
  {
    "cityId": 2451,
    "cityName": "Estandarte",
    "stateId": 5243
  },
  {
    "cityId": 2452,
    "cityName": "Estreito",
    "stateId": 5243
  },
  {
    "cityId": 2453,
    "cityName": "Feira Nova do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2454,
    "cityName": "Fernando Falcão",
    "stateId": 5243
  },
  {
    "cityId": 2455,
    "cityName": "Formosa da Serra Negra",
    "stateId": 5243
  },
  {
    "cityId": 2456,
    "cityName": "Fortaleza dos Nogueiras",
    "stateId": 5243
  },
  {
    "cityId": 2457,
    "cityName": "Fortuna",
    "stateId": 5243
  },
  {
    "cityId": 2458,
    "cityName": "Frecheiras",
    "stateId": 5243
  },
  {
    "cityId": 2459,
    "cityName": "Godofredo Viana",
    "stateId": 5243
  },
  {
    "cityId": 2460,
    "cityName": "Gonçalves Dias",
    "stateId": 5243
  },
  {
    "cityId": 2461,
    "cityName": "Governador Archer",
    "stateId": 5243
  },
  {
    "cityId": 2462,
    "cityName": "Governador Edson Lobão",
    "stateId": 5243
  },
  {
    "cityId": 2463,
    "cityName": "Governador Eugênio Barros",
    "stateId": 5243
  },
  {
    "cityId": 2464,
    "cityName": "Governador Luiz Rocha",
    "stateId": 5243
  },
  {
    "cityId": 2465,
    "cityName": "Governador Newton Bello",
    "stateId": 5243
  },
  {
    "cityId": 2466,
    "cityName": "Governador Nunes Freire",
    "stateId": 5243
  },
  {
    "cityId": 2467,
    "cityName": "Graça Aranha",
    "stateId": 5243
  },
  {
    "cityId": 2468,
    "cityName": "Grajaú",
    "stateId": 5243
  },
  {
    "cityId": 2469,
    "cityName": "Guimarães",
    "stateId": 5243
  },
  {
    "cityId": 2470,
    "cityName": "Humberto de Campos",
    "stateId": 5243
  },
  {
    "cityId": 2471,
    "cityName": "Ibipira",
    "stateId": 5243
  },
  {
    "cityId": 2472,
    "cityName": "Icatu",
    "stateId": 5243
  },
  {
    "cityId": 2473,
    "cityName": "Igarapé do Meio",
    "stateId": 5243
  },
  {
    "cityId": 2474,
    "cityName": "Igarapé Grande",
    "stateId": 5243
  },
  {
    "cityId": 2475,
    "cityName": "Imperatriz",
    "stateId": 5243
  },
  {
    "cityId": 2476,
    "cityName": "Itaipava do Grajaú",
    "stateId": 5243
  },
  {
    "cityId": 2477,
    "cityName": "Itamataré",
    "stateId": 5243
  },
  {
    "cityId": 2478,
    "cityName": "Itapecuru Mirim",
    "stateId": 5243
  },
  {
    "cityId": 2479,
    "cityName": "Itapera",
    "stateId": 5243
  },
  {
    "cityId": 2480,
    "cityName": "Itinga do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2481,
    "cityName": "Jatobá",
    "stateId": 5243
  },
  {
    "cityId": 2482,
    "cityName": "Jenipapo dos Vieiras",
    "stateId": 5243
  },
  {
    "cityId": 2483,
    "cityName": "João Lisboa",
    "stateId": 5243
  },
  {
    "cityId": 2484,
    "cityName": "Joselândia",
    "stateId": 5243
  },
  {
    "cityId": 2485,
    "cityName": "Junco do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2486,
    "cityName": "Lago da Pedra",
    "stateId": 5243
  },
  {
    "cityId": 2487,
    "cityName": "Lago do Junco",
    "stateId": 5243
  },
  {
    "cityId": 2488,
    "cityName": "Lago dos Rodrigues",
    "stateId": 5243
  },
  {
    "cityId": 2489,
    "cityName": "Lago Verde",
    "stateId": 5243
  },
  {
    "cityId": 2490,
    "cityName": "Lagoa do Mato",
    "stateId": 5243
  },
  {
    "cityId": 2491,
    "cityName": "Lagoa Grande do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2492,
    "cityName": "Lajeado Novo",
    "stateId": 5243
  },
  {
    "cityId": 2493,
    "cityName": "Lapela",
    "stateId": 5243
  },
  {
    "cityId": 2494,
    "cityName": "Leandro",
    "stateId": 5243
  },
  {
    "cityId": 2495,
    "cityName": "Lima Campos",
    "stateId": 5243
  },
  {
    "cityId": 2496,
    "cityName": "Loreto",
    "stateId": 5243
  },
  {
    "cityId": 2497,
    "cityName": "Luís Domingues",
    "stateId": 5243
  },
  {
    "cityId": 1,
    "cityName": "Acrelândia",
    "stateId": 5234
  },
  {
    "cityId": 2,
    "cityName": "Assis Brasil",
    "stateId": 5234
  },
  {
    "cityId": 3,
    "cityName": "Brasiléia",
    "stateId": 5234
  },
  {
    "cityId": 4,
    "cityName": "Bujari",
    "stateId": 5234
  },
  {
    "cityId": 5,
    "cityName": "Capixaba",
    "stateId": 5234
  },
  {
    "cityId": 6,
    "cityName": "Cruzeiro do Sul",
    "stateId": 5234
  },
  {
    "cityId": 7,
    "cityName": "Epitaciolândia",
    "stateId": 5234
  },
  {
    "cityId": 8,
    "cityName": "Feijó",
    "stateId": 5234
  },
  {
    "cityId": 9,
    "cityName": "Jordão",
    "stateId": 5234
  },
  {
    "cityId": 10,
    "cityName": "Mâncio Lima",
    "stateId": 5234
  },
  {
    "cityId": 11,
    "cityName": "Manoel Urbano",
    "stateId": 5234
  },
  {
    "cityId": 12,
    "cityName": "Marechal Thaumaturgo",
    "stateId": 5234
  },
  {
    "cityId": 13,
    "cityName": "Plácido de Castro",
    "stateId": 5234
  },
  {
    "cityId": 14,
    "cityName": "Porto Acre",
    "stateId": 5234
  },
  {
    "cityId": 15,
    "cityName": "Porto Walter",
    "stateId": 5234
  },
  {
    "cityId": 16,
    "cityName": "Rio Branco",
    "stateId": 5234
  },
  {
    "cityId": 17,
    "cityName": "Rodrigues Alves",
    "stateId": 5234
  },
  {
    "cityId": 18,
    "cityName": "Santa Rosa do Purus",
    "stateId": 5234
  },
  {
    "cityId": 19,
    "cityName": "Sena Madureira",
    "stateId": 5234
  },
  {
    "cityId": 20,
    "cityName": "Senador Guiomard",
    "stateId": 5234
  },
  {
    "cityId": 21,
    "cityName": "Tarauacá",
    "stateId": 5234
  },
  {
    "cityId": 22,
    "cityName": "Xapuri",
    "stateId": 5234
  },
  {
    "cityId": 23,
    "cityName": "Água Branca",
    "stateId": 5235
  },
  {
    "cityId": 24,
    "cityName": "Alazão",
    "stateId": 5235
  },
  {
    "cityId": 25,
    "cityName": "Alecrim",
    "stateId": 5235
  },
  {
    "cityId": 26,
    "cityName": "Anadia",
    "stateId": 5235
  },
  {
    "cityId": 27,
    "cityName": "Anel",
    "stateId": 5235
  },
  {
    "cityId": 28,
    "cityName": "Anum Novo",
    "stateId": 5235
  },
  {
    "cityId": 29,
    "cityName": "Anum Velho",
    "stateId": 5235
  },
  {
    "cityId": 30,
    "cityName": "Arapiraca",
    "stateId": 5235
  },
  {
    "cityId": 31,
    "cityName": "Atalaia",
    "stateId": 5235
  },
  {
    "cityId": 32,
    "cityName": "Baixa da Onça",
    "stateId": 5235
  },
  {
    "cityId": 33,
    "cityName": "Baixa do Capim",
    "stateId": 5235
  },
  {
    "cityId": 34,
    "cityName": "Bálsamo",
    "stateId": 5235
  },
  {
    "cityId": 35,
    "cityName": "Bananeiras",
    "stateId": 5235
  },
  {
    "cityId": 36,
    "cityName": "Barra de Santo Antônio",
    "stateId": 5235
  },
  {
    "cityId": 37,
    "cityName": "Barra de São Miguel",
    "stateId": 5235
  },
  {
    "cityId": 38,
    "cityName": "Barra do Bonifácio",
    "stateId": 5235
  },
  {
    "cityId": 39,
    "cityName": "Barra Grande",
    "stateId": 5235
  },
  {
    "cityId": 40,
    "cityName": "Batalha",
    "stateId": 5235
  },
  {
    "cityId": 41,
    "cityName": "Batingas",
    "stateId": 5235
  },
  {
    "cityId": 42,
    "cityName": "Belém",
    "stateId": 5235
  },
  {
    "cityId": 43,
    "cityName": "Belo Monte",
    "stateId": 5235
  },
  {
    "cityId": 44,
    "cityName": "Boa Sorte",
    "stateId": 5235
  },
  {
    "cityId": 45,
    "cityName": "Boa Vista",
    "stateId": 5235
  },
  {
    "cityId": 46,
    "cityName": "Boa Vista",
    "stateId": 5235
  },
  {
    "cityId": 47,
    "cityName": "Boca da Mata",
    "stateId": 5235
  },
  {
    "cityId": 48,
    "cityName": "Bom Jardim",
    "stateId": 5235
  },
  {
    "cityId": 49,
    "cityName": "Bonifácio",
    "stateId": 5235
  },
  {
    "cityId": 50,
    "cityName": "Branquinha",
    "stateId": 5235
  },
  {
    "cityId": 51,
    "cityName": "Cacimbinhas",
    "stateId": 5235
  },
  {
    "cityId": 52,
    "cityName": "Cajarana",
    "stateId": 5235
  },
  {
    "cityId": 53,
    "cityName": "Cajueiro",
    "stateId": 5235
  },
  {
    "cityId": 54,
    "cityName": "Caldeirões de Cima",
    "stateId": 5235
  },
  {
    "cityId": 55,
    "cityName": "Camadanta",
    "stateId": 5235
  },
  {
    "cityId": 56,
    "cityName": "Campestre",
    "stateId": 5235
  },
  {
    "cityId": 57,
    "cityName": "Campo Alegre",
    "stateId": 5235
  },
  {
    "cityId": 58,
    "cityName": "Campo Grande",
    "stateId": 5235
  },
  {
    "cityId": 59,
    "cityName": "Canaã",
    "stateId": 5235
  },
  {
    "cityId": 60,
    "cityName": "Canafístula",
    "stateId": 5235
  },
  {
    "cityId": 61,
    "cityName": "Canapi",
    "stateId": 5235
  },
  {
    "cityId": 62,
    "cityName": "Canastra",
    "stateId": 5235
  },
  {
    "cityId": 63,
    "cityName": "Cangandu",
    "stateId": 5235
  },
  {
    "cityId": 64,
    "cityName": "Capela",
    "stateId": 5235
  },
  {
    "cityId": 65,
    "cityName": "Carneiros",
    "stateId": 5235
  },
  {
    "cityId": 66,
    "cityName": "Carrasco",
    "stateId": 5235
  },
  {
    "cityId": 67,
    "cityName": "Chã Preta",
    "stateId": 5235
  },
  {
    "cityId": 68,
    "cityName": "Coité do Nóia",
    "stateId": 5235
  },
  {
    "cityId": 69,
    "cityName": "Colônia Leopoldina",
    "stateId": 5235
  },
  {
    "cityId": 70,
    "cityName": "Coqueiro Seco",
    "stateId": 5235
  },
  {
    "cityId": 71,
    "cityName": "Coruripe",
    "stateId": 5235
  },
  {
    "cityId": 72,
    "cityName": "Coruripe da Cal",
    "stateId": 5235
  },
  {
    "cityId": 73,
    "cityName": "Craíbas",
    "stateId": 5235
  },
  {
    "cityId": 74,
    "cityName": "Delmiro Gouveia",
    "stateId": 5235
  },
  {
    "cityId": 75,
    "cityName": "Dois Riachos",
    "stateId": 5235
  },
  {
    "cityId": 76,
    "cityName": "Entremontes",
    "stateId": 5235
  },
  {
    "cityId": 77,
    "cityName": "Estrela de Alagoas",
    "stateId": 5235
  },
  {
    "cityId": 78,
    "cityName": "Feira Grande",
    "stateId": 5235
  },
  {
    "cityId": 79,
    "cityName": "Feliz Deserto",
    "stateId": 5235
  },
  {
    "cityId": 81,
    "cityName": "Flexeiras",
    "stateId": 5235
  },
  {
    "cityId": 83,
    "cityName": "Gaspar",
    "stateId": 5235
  },
  {
    "cityId": 84,
    "cityName": "Girau do Ponciano",
    "stateId": 5235
  },
  {
    "cityId": 85,
    "cityName": "Ibateguara",
    "stateId": 5235
  },
  {
    "cityId": 86,
    "cityName": "Igaci",
    "stateId": 5235
  },
  {
    "cityId": 87,
    "cityName": "Igreja Nova",
    "stateId": 5235
  },
  {
    "cityId": 88,
    "cityName": "Inhapi",
    "stateId": 5235
  },
  {
    "cityId": 89,
    "cityName": "Jacaré dos Homens",
    "stateId": 5235
  },
  {
    "cityId": 90,
    "cityName": "Jacuípe",
    "stateId": 5235
  },
  {
    "cityId": 91,
    "cityName": "Japaratinga",
    "stateId": 5235
  },
  {
    "cityId": 92,
    "cityName": "Jaramataia",
    "stateId": 5235
  },
  {
    "cityId": 93,
    "cityName": "Jenipapo",
    "stateId": 5235
  },
  {
    "cityId": 94,
    "cityName": "Joaquim Gomes",
    "stateId": 5235
  },
  {
    "cityId": 95,
    "cityName": "Jundiá",
    "stateId": 5235
  },
  {
    "cityId": 96,
    "cityName": "Junqueiro",
    "stateId": 5235
  },
  {
    "cityId": 97,
    "cityName": "Lagoa da Areia",
    "stateId": 5235
  },
  {
    "cityId": 98,
    "cityName": "Lagoa da Canoa",
    "stateId": 5235
  },
  {
    "cityId": 99,
    "cityName": "Lagoa da Pedra",
    "stateId": 5235
  },
  {
    "cityId": 100,
    "cityName": "Lagoa Dantas",
    "stateId": 5235
  },
  {
    "cityId": 101,
    "cityName": "Lagoa do Caldeirão",
    "stateId": 5235
  },
  {
    "cityId": 102,
    "cityName": "Lagoa do Canto",
    "stateId": 5235
  },
  {
    "cityId": 103,
    "cityName": "Lagoa do Exú",
    "stateId": 5235
  },
  {
    "cityId": 104,
    "cityName": "Lagoa do Rancho",
    "stateId": 5235
  },
  {
    "cityId": 105,
    "cityName": "Lagoa do Rancho",
    "stateId": 5235
  },
  {
    "cityId": 106,
    "cityName": "Lajes do Caldeirão",
    "stateId": 5235
  },
  {
    "cityId": 107,
    "cityName": "Laranjal",
    "stateId": 5235
  },
  {
    "cityId": 108,
    "cityName": "Limoeiro de Anadia",
    "stateId": 5235
  },
  {
    "cityId": 109,
    "cityName": "Maceió",
    "stateId": 5235
  },
  {
    "cityId": 110,
    "cityName": "Major Isidoro",
    "stateId": 5235
  },
  {
    "cityId": 111,
    "cityName": "Mar Vermelho",
    "stateId": 5235
  },
  {
    "cityId": 112,
    "cityName": "Maragogi",
    "stateId": 5235
  },
  {
    "cityId": 113,
    "cityName": "Maravilha",
    "stateId": 5235
  },
  {
    "cityId": 114,
    "cityName": "Marechal Deodoro",
    "stateId": 5235
  },
  {
    "cityId": 115,
    "cityName": "Maribondo",
    "stateId": 5235
  },
  {
    "cityId": 116,
    "cityName": "Massaranduba",
    "stateId": 5235
  },
  {
    "cityId": 117,
    "cityName": "Mata Grande",
    "stateId": 5235
  },
  {
    "cityId": 118,
    "cityName": "Matriz de Camaragibe",
    "stateId": 5235
  },
  {
    "cityId": 119,
    "cityName": "Messias",
    "stateId": 5235
  },
  {
    "cityId": 120,
    "cityName": "Minador do Negrão",
    "stateId": 5235
  },
  {
    "cityId": 121,
    "cityName": "Monteirópolis",
    "stateId": 5235
  },
  {
    "cityId": 122,
    "cityName": "Moreira",
    "stateId": 5235
  },
  {
    "cityId": 123,
    "cityName": "Munguba",
    "stateId": 5235
  },
  {
    "cityId": 124,
    "cityName": "Murici",
    "stateId": 5235
  },
  {
    "cityId": 125,
    "cityName": "Novo Lino",
    "stateId": 5235
  },
  {
    "cityId": 126,
    "cityName": "Olho D'Água Grande",
    "stateId": 5235
  },
  {
    "cityId": 127,
    "cityName": "Olho D'Água das Flores",
    "stateId": 5235
  },
  {
    "cityId": 128,
    "cityName": "Olho D'Água de Cima",
    "stateId": 5235
  },
  {
    "cityId": 129,
    "cityName": "Olho D'Água do Casado",
    "stateId": 5235
  },
  {
    "cityId": 130,
    "cityName": "Olho D'Água dos Dandanhas",
    "stateId": 5235
  },
  {
    "cityId": 131,
    "cityName": "Olivença",
    "stateId": 5235
  },
  {
    "cityId": 132,
    "cityName": "Ouro Branco",
    "stateId": 5235
  },
  {
    "cityId": 133,
    "cityName": "Palestina",
    "stateId": 5235
  },
  {
    "cityId": 134,
    "cityName": "Palmeira de Fora",
    "stateId": 5235
  },
  {
    "cityId": 135,
    "cityName": "Palmeira dos Índios",
    "stateId": 5235
  },
  {
    "cityId": 136,
    "cityName": "Pão de Açúcar",
    "stateId": 5235
  },
  {
    "cityId": 137,
    "cityName": "Pariconha",
    "stateId": 5235
  },
  {
    "cityId": 138,
    "cityName": "Paripueira",
    "stateId": 5235
  },
  {
    "cityId": 139,
    "cityName": "Passo de Camaragibe",
    "stateId": 5235
  },
  {
    "cityId": 140,
    "cityName": "Pau D'Arco",
    "stateId": 5235
  },
  {
    "cityId": 141,
    "cityName": "Pau Ferro",
    "stateId": 5235
  },
  {
    "cityId": 142,
    "cityName": "Paulo Jacinto",
    "stateId": 5235
  },
  {
    "cityId": 143,
    "cityName": "Penedo",
    "stateId": 5235
  },
  {
    "cityId": 144,
    "cityName": "Piaçabuçu",
    "stateId": 5235
  },
  {
    "cityId": 145,
    "cityName": "Pilar",
    "stateId": 5235
  },
  {
    "cityId": 146,
    "cityName": "Pindoba",
    "stateId": 5235
  },
  {
    "cityId": 147,
    "cityName": "Piranhas",
    "stateId": 5235
  },
  {
    "cityId": 148,
    "cityName": "Poção",
    "stateId": 5235
  },
  {
    "cityId": 149,
    "cityName": "Poço da Pedra",
    "stateId": 5235
  },
  {
    "cityId": 150,
    "cityName": "Poço das Trincheiras",
    "stateId": 5235
  },
  {
    "cityId": 151,
    "cityName": "Porto Calvo",
    "stateId": 5235
  },
  {
    "cityId": 152,
    "cityName": "Porto de Pedras",
    "stateId": 5235
  },
  {
    "cityId": 153,
    "cityName": "Porto Real do Colégio",
    "stateId": 5235
  },
  {
    "cityId": 154,
    "cityName": "Poxim",
    "stateId": 5235
  },
  {
    "cityId": 155,
    "cityName": "Quebrangulo",
    "stateId": 5235
  },
  {
    "cityId": 156,
    "cityName": "Riacho do Sertão",
    "stateId": 5235
  },
  {
    "cityId": 157,
    "cityName": "Riacho Fundo de Cima",
    "stateId": 5235
  },
  {
    "cityId": 158,
    "cityName": "Rio Largo",
    "stateId": 5235
  },
  {
    "cityId": 159,
    "cityName": "Rocha Cavalcante",
    "stateId": 5235
  },
  {
    "cityId": 160,
    "cityName": "Roteiro",
    "stateId": 5235
  },
  {
    "cityId": 161,
    "cityName": "Santa Efigênia",
    "stateId": 5235
  },
  {
    "cityId": 162,
    "cityName": "Santa Luzia do Norte",
    "stateId": 5235
  },
  {
    "cityId": 163,
    "cityName": "Santana do Ipanema",
    "stateId": 5235
  },
  {
    "cityId": 164,
    "cityName": "Santana do Mundaú",
    "stateId": 5235
  },
  {
    "cityId": 165,
    "cityName": "Santo Antônio",
    "stateId": 5235
  },
  {
    "cityId": 166,
    "cityName": "São Brás",
    "stateId": 5235
  },
  {
    "cityId": 167,
    "cityName": "São José da Laje",
    "stateId": 5235
  },
  {
    "cityId": 168,
    "cityName": "São José da Tapera",
    "stateId": 5235
  },
  {
    "cityId": 169,
    "cityName": "São Luís do Quitunde",
    "stateId": 5235
  },
  {
    "cityId": 170,
    "cityName": "São Miguel dos Campos",
    "stateId": 5235
  },
  {
    "cityId": 171,
    "cityName": "São Miguel dos Milagres",
    "stateId": 5235
  },
  {
    "cityId": 172,
    "cityName": "São Sebastião",
    "stateId": 5235
  },
  {
    "cityId": 173,
    "cityName": "Sapucaia",
    "stateId": 5235
  },
  {
    "cityId": 174,
    "cityName": "Sapucaia",
    "stateId": 5235
  },
  {
    "cityId": 175,
    "cityName": "Satuba",
    "stateId": 5235
  },
  {
    "cityId": 176,
    "cityName": "Senador Rui Palmeira",
    "stateId": 5235
  },
  {
    "cityId": 177,
    "cityName": "Serra da Mandioca",
    "stateId": 5235
  },
  {
    "cityId": 178,
    "cityName": "Serra do São José",
    "stateId": 5235
  },
  {
    "cityId": 179,
    "cityName": "Taboleiro do Pinto",
    "stateId": 5235
  },
  {
    "cityId": 180,
    "cityName": "Taboquinha",
    "stateId": 5235
  },
  {
    "cityId": 181,
    "cityName": "Tanque D'Arca",
    "stateId": 5235
  },
  {
    "cityId": 182,
    "cityName": "Taquarana",
    "stateId": 5235
  },
  {
    "cityId": 183,
    "cityName": "Tatuamunha",
    "stateId": 5235
  },
  {
    "cityId": 184,
    "cityName": "Teotônio Vilela",
    "stateId": 5235
  },
  {
    "cityId": 185,
    "cityName": "Traipu",
    "stateId": 5235
  },
  {
    "cityId": 186,
    "cityName": "União dos Palmares",
    "stateId": 5235
  },
  {
    "cityId": 187,
    "cityName": "Usina Camaçari",
    "stateId": 5235
  },
  {
    "cityId": 188,
    "cityName": "Viçosa",
    "stateId": 5235
  },
  {
    "cityId": 189,
    "cityName": "Vila Aparecida",
    "stateId": 5235
  },
  {
    "cityId": 190,
    "cityName": "Vila São Francisco",
    "stateId": 5235
  },
  {
    "cityId": 191,
    "cityName": "Alvarães",
    "stateId": 5236
  },
  {
    "cityId": 192,
    "cityName": "Amatari",
    "stateId": 5236
  },
  {
    "cityId": 193,
    "cityName": "Amaturá",
    "stateId": 5236
  },
  {
    "cityId": 194,
    "cityName": "Anamã",
    "stateId": 5236
  },
  {
    "cityId": 195,
    "cityName": "Anori",
    "stateId": 5236
  },
  {
    "cityId": 196,
    "cityName": "Apuí",
    "stateId": 5236
  },
  {
    "cityId": 197,
    "cityName": "Ariaú",
    "stateId": 5236
  },
  {
    "cityId": 198,
    "cityName": "Atalaia do Norte",
    "stateId": 5236
  },
  {
    "cityId": 199,
    "cityName": "Augusto Montenegro",
    "stateId": 5236
  },
  {
    "cityId": 200,
    "cityName": "Autazes",
    "stateId": 5236
  },
  {
    "cityId": 201,
    "cityName": "Axinim",
    "stateId": 5236
  },
  {
    "cityId": 202,
    "cityName": "Badajós",
    "stateId": 5236
  },
  {
    "cityId": 203,
    "cityName": "Balbina",
    "stateId": 5236
  },
  {
    "cityId": 204,
    "cityName": "Barcelos",
    "stateId": 5236
  },
  {
    "cityId": 205,
    "cityName": "Barreirinha",
    "stateId": 5236
  },
  {
    "cityId": 206,
    "cityName": "Benjamin Constant",
    "stateId": 5236
  },
  {
    "cityId": 207,
    "cityName": "Beruri",
    "stateId": 5236
  },
  {
    "cityId": 208,
    "cityName": "Boa Vista do Ramos",
    "stateId": 5236
  },
  {
    "cityId": 209,
    "cityName": "Boca do Acre",
    "stateId": 5236
  },
  {
    "cityId": 210,
    "cityName": "Borba",
    "stateId": 5236
  },
  {
    "cityId": 211,
    "cityName": "Caapiranga",
    "stateId": 5236
  },
  {
    "cityId": 212,
    "cityName": "Cametá",
    "stateId": 5236
  },
  {
    "cityId": 213,
    "cityName": "Canumã",
    "stateId": 5236
  },
  {
    "cityId": 214,
    "cityName": "Canutama",
    "stateId": 5236
  },
  {
    "cityId": 215,
    "cityName": "Carauari",
    "stateId": 5236
  },
  {
    "cityId": 216,
    "cityName": "Careiro",
    "stateId": 5236
  },
  {
    "cityId": 217,
    "cityName": "Careiro da Várzea",
    "stateId": 5236
  },
  {
    "cityId": 218,
    "cityName": "Carvoeiro",
    "stateId": 5236
  },
  {
    "cityId": 219,
    "cityName": "Coari",
    "stateId": 5236
  },
  {
    "cityId": 220,
    "cityName": "Codajás",
    "stateId": 5236
  },
  {
    "cityId": 221,
    "cityName": "Cucuí",
    "stateId": 5236
  },
  {
    "cityId": 222,
    "cityName": "Eirunepé",
    "stateId": 5236
  },
  {
    "cityId": 223,
    "cityName": "Envira",
    "stateId": 5236
  },
  {
    "cityId": 224,
    "cityName": "Floriano Peixoto",
    "stateId": 5236
  },
  {
    "cityId": 225,
    "cityName": "Fonte Boa",
    "stateId": 5236
  },
  {
    "cityId": 226,
    "cityName": "Freguesia do Andirá",
    "stateId": 5236
  },
  {
    "cityId": 227,
    "cityName": "Guajará",
    "stateId": 5236
  },
  {
    "cityId": 228,
    "cityName": "Humaitá",
    "stateId": 5236
  },
  {
    "cityId": 229,
    "cityName": "Iauaretê",
    "stateId": 5236
  },
  {
    "cityId": 230,
    "cityName": "Içanã",
    "stateId": 5236
  },
  {
    "cityId": 231,
    "cityName": "Ipixuna",
    "stateId": 5236
  },
  {
    "cityId": 232,
    "cityName": "Iranduba",
    "stateId": 5236
  },
  {
    "cityId": 233,
    "cityName": "Itacoatiara",
    "stateId": 5236
  },
  {
    "cityId": 234,
    "cityName": "Itamarati",
    "stateId": 5236
  },
  {
    "cityId": 235,
    "cityName": "Itapiranga",
    "stateId": 5236
  },
  {
    "cityId": 236,
    "cityName": "Japurá",
    "stateId": 5236
  },
  {
    "cityId": 237,
    "cityName": "Juruá",
    "stateId": 5236
  },
  {
    "cityId": 238,
    "cityName": "Jutaí",
    "stateId": 5236
  },
  {
    "cityId": 239,
    "cityName": "Lábrea",
    "stateId": 5236
  },
  {
    "cityId": 240,
    "cityName": "Lago Preto",
    "stateId": 5236
  },
  {
    "cityId": 241,
    "cityName": "Manacapuru",
    "stateId": 5236
  },
  {
    "cityId": 242,
    "cityName": "Manaquiri",
    "stateId": 5236
  },
  {
    "cityId": 243,
    "cityName": "Manaus",
    "stateId": 5236
  },
  {
    "cityId": 244,
    "cityName": "Manicoré",
    "stateId": 5236
  },
  {
    "cityId": 245,
    "cityName": "Maraã",
    "stateId": 5236
  },
  {
    "cityId": 246,
    "cityName": "Massauari",
    "stateId": 5236
  },
  {
    "cityId": 247,
    "cityName": "Maués",
    "stateId": 5236
  },
  {
    "cityId": 248,
    "cityName": "Mocambo",
    "stateId": 5236
  },
  {
    "cityId": 249,
    "cityName": "Moura",
    "stateId": 5236
  },
  {
    "cityId": 250,
    "cityName": "Murutinga",
    "stateId": 5236
  },
  {
    "cityId": 251,
    "cityName": "Nhamundá",
    "stateId": 5236
  },
  {
    "cityId": 252,
    "cityName": "Nova Olinda do Norte",
    "stateId": 5236
  },
  {
    "cityId": 253,
    "cityName": "Novo Airão",
    "stateId": 5236
  },
  {
    "cityId": 254,
    "cityName": "Novo Aripuanã",
    "stateId": 5236
  },
  {
    "cityId": 255,
    "cityName": "Osório da Fonseca",
    "stateId": 5236
  },
  {
    "cityId": 256,
    "cityName": "Parintins",
    "stateId": 5236
  },
  {
    "cityId": 257,
    "cityName": "Pauini",
    "stateId": 5236
  },
  {
    "cityId": 258,
    "cityName": "Pedras",
    "stateId": 5236
  },
  {
    "cityId": 259,
    "cityName": "Presidente Figueiredo",
    "stateId": 5236
  },
  {
    "cityId": 260,
    "cityName": "Repartimento",
    "stateId": 5236
  },
  {
    "cityId": 261,
    "cityName": "Rio Preto da Eva",
    "stateId": 5236
  },
  {
    "cityId": 262,
    "cityName": "Santa Isabel do Rio Negro",
    "stateId": 5236
  },
  {
    "cityId": 263,
    "cityName": "Santa Rita",
    "stateId": 5236
  },
  {
    "cityId": 264,
    "cityName": "Santo Antônio do Içá",
    "stateId": 5236
  },
  {
    "cityId": 265,
    "cityName": "São Felipe",
    "stateId": 5236
  },
  {
    "cityId": 266,
    "cityName": "São Gabriel da Cachoeira",
    "stateId": 5236
  },
  {
    "cityId": 267,
    "cityName": "São Paulo de Olivença",
    "stateId": 5236
  },
  {
    "cityId": 268,
    "cityName": "São Sebastião do Uatumã",
    "stateId": 5236
  },
  {
    "cityId": 269,
    "cityName": "Silves",
    "stateId": 5236
  },
  {
    "cityId": 270,
    "cityName": "Tabatinga",
    "stateId": 5236
  },
  {
    "cityId": 271,
    "cityName": "Tapauá",
    "stateId": 5236
  },
  {
    "cityId": 272,
    "cityName": "Tefé",
    "stateId": 5236
  },
  {
    "cityId": 273,
    "cityName": "Tonantins",
    "stateId": 5236
  },
  {
    "cityId": 274,
    "cityName": "Uarini",
    "stateId": 5236
  },
  {
    "cityId": 275,
    "cityName": "Urucará",
    "stateId": 5236
  },
  {
    "cityId": 276,
    "cityName": "Urucurituba",
    "stateId": 5236
  },
  {
    "cityId": 277,
    "cityName": "Vila Pitinga",
    "stateId": 5236
  },
  {
    "cityId": 278,
    "cityName": "Abacate da Pedreira",
    "stateId": 5237
  },
  {
    "cityId": 280,
    "cityName": "Amapá",
    "stateId": 5237
  },
  {
    "cityId": 281,
    "cityName": "Amapari",
    "stateId": 5237
  },
  {
    "cityId": 282,
    "cityName": "Ambé",
    "stateId": 5237
  },
  {
    "cityId": 283,
    "cityName": "Aporema",
    "stateId": 5237
  },
  {
    "cityId": 284,
    "cityName": "Ariri",
    "stateId": 5237
  },
  {
    "cityId": 285,
    "cityName": "Bailique",
    "stateId": 5237
  },
  {
    "cityId": 286,
    "cityName": "Boca do Jari",
    "stateId": 5237
  },
  {
    "cityId": 287,
    "cityName": "Calçoene",
    "stateId": 5237
  },
  {
    "cityId": 288,
    "cityName": "Cantanzal",
    "stateId": 5237
  },
  {
    "cityId": 289,
    "cityName": "Carmo",
    "stateId": 5237
  },
  {
    "cityId": 290,
    "cityName": "Clevelândia do Norte",
    "stateId": 5237
  },
  {
    "cityId": 291,
    "cityName": "Corre Água",
    "stateId": 5237
  },
  {
    "cityId": 292,
    "cityName": "Cunani",
    "stateId": 5237
  },
  {
    "cityId": 293,
    "cityName": "Curiaú",
    "stateId": 5237
  },
  {
    "cityId": 294,
    "cityName": "Cutias",
    "stateId": 5237
  },
  {
    "cityId": 295,
    "cityName": "Fazendinha",
    "stateId": 5237
  },
  {
    "cityId": 296,
    "cityName": "Ferreira Gomes",
    "stateId": 5237
  },
  {
    "cityId": 297,
    "cityName": "Fortaleza",
    "stateId": 5237
  },
  {
    "cityId": 298,
    "cityName": "Gaivota",
    "stateId": 5237
  },
  {
    "cityId": 299,
    "cityName": "Gurupora",
    "stateId": 5237
  },
  {
    "cityId": 300,
    "cityName": "Igarapé do Lago",
    "stateId": 5237
  },
  {
    "cityId": 301,
    "cityName": "Ilha de Santana",
    "stateId": 5237
  },
  {
    "cityId": 302,
    "cityName": "Inajá",
    "stateId": 5237
  },
  {
    "cityId": 303,
    "cityName": "Itaubal",
    "stateId": 5237
  },
  {
    "cityId": 304,
    "cityName": "Laranjal do Jari",
    "stateId": 5237
  },
  {
    "cityId": 305,
    "cityName": "Livramento do Pacuí",
    "stateId": 5237
  },
  {
    "cityId": 306,
    "cityName": "Lourenço",
    "stateId": 5237
  },
  {
    "cityId": 307,
    "cityName": "Macapá",
    "stateId": 5237
  },
  {
    "cityId": 308,
    "cityName": "Mazagão",
    "stateId": 5237
  },
  {
    "cityId": 309,
    "cityName": "Mazagão Velho",
    "stateId": 5237
  },
  {
    "cityId": 310,
    "cityName": "Oiapoque",
    "stateId": 5237
  },
  {
    "cityId": 311,
    "cityName": "Paredão",
    "stateId": 5237
  },
  {
    "cityId": 312,
    "cityName": "Porto Grande",
    "stateId": 5237
  },
  {
    "cityId": 313,
    "cityName": "Pracuúba",
    "stateId": 5237
  },
  {
    "cityId": 314,
    "cityName": "Santa Luzia do Pacuí",
    "stateId": 5237
  },
  {
    "cityId": 315,
    "cityName": "Santa Maria",
    "stateId": 5237
  },
  {
    "cityId": 316,
    "cityName": "Santana",
    "stateId": 5237
  },
  {
    "cityId": 317,
    "cityName": "São Joaquim do Pacuí",
    "stateId": 5237
  },
  {
    "cityId": 318,
    "cityName": "São Sebastião do Livramento",
    "stateId": 5237
  },
  {
    "cityId": 319,
    "cityName": "São Tomé",
    "stateId": 5237
  },
  {
    "cityId": 321,
    "cityName": "Sucuriju",
    "stateId": 5237
  },
  {
    "cityId": 322,
    "cityName": "Tartarugalzinho",
    "stateId": 5237
  },
  {
    "cityId": 323,
    "cityName": "Vila Velha",
    "stateId": 5237
  },
  {
    "cityId": 324,
    "cityName": "Vitória do Jari",
    "stateId": 5237
  },
  {
    "cityId": 325,
    "cityName": "Abadia",
    "stateId": 5238
  },
  {
    "cityId": 326,
    "cityName": "Abaíra",
    "stateId": 5238
  },
  {
    "cityId": 327,
    "cityName": "Abaré",
    "stateId": 5238
  },
  {
    "cityId": 328,
    "cityName": "Abelhas",
    "stateId": 5238
  },
  {
    "cityId": 329,
    "cityName": "Abóbora",
    "stateId": 5238
  },
  {
    "cityId": 330,
    "cityName": "Abrantes",
    "stateId": 5238
  },
  {
    "cityId": 331,
    "cityName": "Acajutiba",
    "stateId": 5238
  },
  {
    "cityId": 332,
    "cityName": "Açu da Torre",
    "stateId": 5238
  },
  {
    "cityId": 333,
    "cityName": "Açudina",
    "stateId": 5238
  },
  {
    "cityId": 334,
    "cityName": "Acupe",
    "stateId": 5238
  },
  {
    "cityId": 335,
    "cityName": "Adustina",
    "stateId": 5238
  },
  {
    "cityId": 336,
    "cityName": "Afligidos",
    "stateId": 5238
  },
  {
    "cityId": 337,
    "cityName": "Afrânio Peixoto",
    "stateId": 5238
  },
  {
    "cityId": 338,
    "cityName": "Água Doce",
    "stateId": 5238
  },
  {
    "cityId": 339,
    "cityName": "Água Fria",
    "stateId": 5238
  },
  {
    "cityId": 340,
    "cityName": "Águas do Paulista",
    "stateId": 5238
  },
  {
    "cityId": 341,
    "cityName": "Aiquara",
    "stateId": 5238
  },
  {
    "cityId": 342,
    "cityName": "Alagoinhas",
    "stateId": 5238
  },
  {
    "cityId": 343,
    "cityName": "Alcobaça",
    "stateId": 5238
  },
  {
    "cityId": 344,
    "cityName": "Alegre",
    "stateId": 5238
  },
  {
    "cityId": 345,
    "cityName": "Algodão",
    "stateId": 5238
  },
  {
    "cityId": 346,
    "cityName": "Algodões",
    "stateId": 5238
  },
  {
    "cityId": 347,
    "cityName": "Almadina",
    "stateId": 5238
  },
  {
    "cityId": 348,
    "cityName": "Alto Bonito",
    "stateId": 5238
  },
  {
    "cityId": 349,
    "cityName": "Amado Bahia",
    "stateId": 5238
  },
  {
    "cityId": 350,
    "cityName": "Amaniú",
    "stateId": 5238
  },
  {
    "cityId": 351,
    "cityName": "Amargosa",
    "stateId": 5238
  },
  {
    "cityId": 352,
    "cityName": "Amélia Rodrigues",
    "stateId": 5238
  },
  {
    "cityId": 353,
    "cityName": "América Dourada",
    "stateId": 5238
  },
  {
    "cityId": 354,
    "cityName": "Américo Alves",
    "stateId": 5238
  },
  {
    "cityId": 355,
    "cityName": "Anagé",
    "stateId": 5238
  },
  {
    "cityId": 356,
    "cityName": "Andaraí",
    "stateId": 5238
  },
  {
    "cityId": 357,
    "cityName": "Andorinha",
    "stateId": 5238
  },
  {
    "cityId": 358,
    "cityName": "Angical",
    "stateId": 5238
  },
  {
    "cityId": 359,
    "cityName": "Angico",
    "stateId": 5238
  },
  {
    "cityId": 360,
    "cityName": "Anguera",
    "stateId": 5238
  },
  {
    "cityId": 361,
    "cityName": "Antas",
    "stateId": 5238
  },
  {
    "cityId": 362,
    "cityName": "Antônio Cardoso",
    "stateId": 5238
  },
  {
    "cityId": 363,
    "cityName": "Antônio Gonçalves",
    "stateId": 5238
  },
  {
    "cityId": 364,
    "cityName": "Aporá",
    "stateId": 5238
  },
  {
    "cityId": 365,
    "cityName": "Apuarema",
    "stateId": 5238
  },
  {
    "cityId": 366,
    "cityName": "Araçás",
    "stateId": 5238
  },
  {
    "cityId": 367,
    "cityName": "Aracatu",
    "stateId": 5238
  },
  {
    "cityId": 368,
    "cityName": "Araci",
    "stateId": 5238
  },
  {
    "cityId": 369,
    "cityName": "Aramari",
    "stateId": 5238
  },
  {
    "cityId": 370,
    "cityName": "Arapiranga",
    "stateId": 5238
  },
  {
    "cityId": 371,
    "cityName": "Arataca",
    "stateId": 5238
  },
  {
    "cityId": 372,
    "cityName": "Aratuípe",
    "stateId": 5238
  },
  {
    "cityId": 373,
    "cityName": "Areias",
    "stateId": 5238
  },
  {
    "cityId": 374,
    "cityName": "Arembepe",
    "stateId": 5238
  },
  {
    "cityId": 375,
    "cityName": "Argoim",
    "stateId": 5238
  },
  {
    "cityId": 376,
    "cityName": "Argolo",
    "stateId": 5238
  },
  {
    "cityId": 377,
    "cityName": "Aribice",
    "stateId": 5238
  },
  {
    "cityId": 378,
    "cityName": "Aritaguá",
    "stateId": 5238
  },
  {
    "cityId": 379,
    "cityName": "Aurelino Leal",
    "stateId": 5238
  },
  {
    "cityId": 380,
    "cityName": "Baianópolis",
    "stateId": 5238
  },
  {
    "cityId": 381,
    "cityName": "Baixa do Palmeira",
    "stateId": 5238
  },
  {
    "cityId": 382,
    "cityName": "Baixa Grande",
    "stateId": 5238
  },
  {
    "cityId": 383,
    "cityName": "Baixão",
    "stateId": 5238
  },
  {
    "cityId": 384,
    "cityName": "Baixinha",
    "stateId": 5238
  },
  {
    "cityId": 385,
    "cityName": "Baluarte",
    "stateId": 5238
  },
  {
    "cityId": 386,
    "cityName": "Banco Central",
    "stateId": 5238
  },
  {
    "cityId": 387,
    "cityName": "Banco da Vitória",
    "stateId": 5238
  },
  {
    "cityId": 388,
    "cityName": "Bandeira do Almada",
    "stateId": 5238
  },
  {
    "cityId": 389,
    "cityName": "Bandeira do Colônia",
    "stateId": 5238
  },
  {
    "cityId": 390,
    "cityName": "Bandiaçu",
    "stateId": 5238
  },
  {
    "cityId": 391,
    "cityName": "Banzaê",
    "stateId": 5238
  },
  {
    "cityId": 392,
    "cityName": "Baraúnas",
    "stateId": 5238
  },
  {
    "cityId": 393,
    "cityName": "Barcelos do Sul",
    "stateId": 5238
  },
  {
    "cityId": 394,
    "cityName": "Barra",
    "stateId": 5238
  },
  {
    "cityId": 395,
    "cityName": "Barra da Estiva",
    "stateId": 5238
  },
  {
    "cityId": 396,
    "cityName": "Barra do Choça",
    "stateId": 5238
  },
  {
    "cityId": 397,
    "cityName": "Barra do Jacuípe",
    "stateId": 5238
  },
  {
    "cityId": 398,
    "cityName": "Barra do Mendes",
    "stateId": 5238
  },
  {
    "cityId": 399,
    "cityName": "Barra do Pojuca",
    "stateId": 5238
  },
  {
    "cityId": 400,
    "cityName": "Barra do Rocha",
    "stateId": 5238
  },
  {
    "cityId": 401,
    "cityName": "Barra do Tarrachil",
    "stateId": 5238
  },
  {
    "cityId": 402,
    "cityName": "Barracas",
    "stateId": 5238
  },
  {
    "cityId": 403,
    "cityName": "Barreiras",
    "stateId": 5238
  },
  {
    "cityId": 404,
    "cityName": "Barro Alto",
    "stateId": 5238
  },
  {
    "cityId": 405,
    "cityName": "Barro Preto",
    "stateId": 5238
  },
  {
    "cityId": 406,
    "cityName": "Barro Vermelho",
    "stateId": 5238
  },
  {
    "cityId": 407,
    "cityName": "Barrocas",
    "stateId": 5238
  },
  {
    "cityId": 408,
    "cityName": "Bastião",
    "stateId": 5238
  },
  {
    "cityId": 409,
    "cityName": "Bate Pé",
    "stateId": 5238
  },
  {
    "cityId": 410,
    "cityName": "Batinga",
    "stateId": 5238
  },
  {
    "cityId": 411,
    "cityName": "Bela Flor",
    "stateId": 5238
  },
  {
    "cityId": 412,
    "cityName": "Belém da Cachoeira",
    "stateId": 5238
  },
  {
    "cityId": 413,
    "cityName": "Belmonte",
    "stateId": 5238
  },
  {
    "cityId": 414,
    "cityName": "Belo Campo",
    "stateId": 5238
  },
  {
    "cityId": 415,
    "cityName": "Belo Campo",
    "stateId": 5238
  },
  {
    "cityId": 416,
    "cityName": "Bem-Bom",
    "stateId": 5238
  },
  {
    "cityId": 417,
    "cityName": "Bendegó",
    "stateId": 5238
  },
  {
    "cityId": 418,
    "cityName": "Bento Simões",
    "stateId": 5238
  },
  {
    "cityId": 419,
    "cityName": "Biritinga",
    "stateId": 5238
  },
  {
    "cityId": 420,
    "cityName": "Boa Espera",
    "stateId": 5238
  },
  {
    "cityId": 421,
    "cityName": "Boa Nova",
    "stateId": 5238
  },
  {
    "cityId": 422,
    "cityName": "Boa União",
    "stateId": 5238
  },
  {
    "cityId": 423,
    "cityName": "Boa Vista do Lagamar",
    "stateId": 5238
  },
  {
    "cityId": 424,
    "cityName": "Boa Vista do Tupim",
    "stateId": 5238
  },
  {
    "cityId": 425,
    "cityName": "Boaçu",
    "stateId": 5238
  },
  {
    "cityId": 426,
    "cityName": "Boca do Córrego",
    "stateId": 5238
  },
  {
    "cityId": 427,
    "cityName": "Bom Jesus da Lapa",
    "stateId": 5238
  },
  {
    "cityId": 428,
    "cityName": "Bom Jesus da Serra",
    "stateId": 5238
  },
  {
    "cityId": 429,
    "cityName": "Bom Sossego",
    "stateId": 5238
  },
  {
    "cityId": 430,
    "cityName": "Bonfim da Feira",
    "stateId": 5238
  },
  {
    "cityId": 431,
    "cityName": "Boninal",
    "stateId": 5238
  },
  {
    "cityId": 432,
    "cityName": "Bonito",
    "stateId": 5238
  },
  {
    "cityId": 433,
    "cityName": "Boquira",
    "stateId": 5238
  },
  {
    "cityId": 434,
    "cityName": "Botuporã",
    "stateId": 5238
  },
  {
    "cityId": 435,
    "cityName": "Botuquara",
    "stateId": 5238
  },
  {
    "cityId": 436,
    "cityName": "Brejinho das Ametistas",
    "stateId": 5238
  },
  {
    "cityId": 437,
    "cityName": "Brejo da Serra",
    "stateId": 5238
  },
  {
    "cityId": 438,
    "cityName": "Brejo Luíza de Brito",
    "stateId": 5238
  },
  {
    "cityId": 439,
    "cityName": "Brejo Novo",
    "stateId": 5238
  },
  {
    "cityId": 440,
    "cityName": "Brejões",
    "stateId": 5238
  },
  {
    "cityId": 441,
    "cityName": "Brejolândia",
    "stateId": 5238
  },
  {
    "cityId": 442,
    "cityName": "Brotas de Macaúbas",
    "stateId": 5238
  },
  {
    "cityId": 443,
    "cityName": "Brumado",
    "stateId": 5238
  },
  {
    "cityId": 444,
    "cityName": "Bucuituba",
    "stateId": 5238
  },
  {
    "cityId": 445,
    "cityName": "Buerarema",
    "stateId": 5238
  },
  {
    "cityId": 446,
    "cityName": "Buracica",
    "stateId": 5238
  },
  {
    "cityId": 447,
    "cityName": "Buranhém",
    "stateId": 5238
  },
  {
    "cityId": 448,
    "cityName": "Buril",
    "stateId": 5238
  },
  {
    "cityId": 449,
    "cityName": "Buris de Abrantes",
    "stateId": 5238
  },
  {
    "cityId": 450,
    "cityName": "Buritirama",
    "stateId": 5238
  },
  {
    "cityId": 451,
    "cityName": "Caatiba",
    "stateId": 5238
  },
  {
    "cityId": 452,
    "cityName": "Cabaceiras do Paraguaçu",
    "stateId": 5238
  },
  {
    "cityId": 453,
    "cityName": "Cabrália",
    "stateId": 5238
  },
  {
    "cityId": 454,
    "cityName": "Cacha Pregos",
    "stateId": 5238
  },
  {
    "cityId": 455,
    "cityName": "Cachoeira",
    "stateId": 5238
  },
  {
    "cityId": 456,
    "cityName": "Cachoeira do Mato",
    "stateId": 5238
  },
  {
    "cityId": 457,
    "cityName": "Caculé",
    "stateId": 5238
  },
  {
    "cityId": 458,
    "cityName": "Caém",
    "stateId": 5238
  },
  {
    "cityId": 459,
    "cityName": "Caetanos",
    "stateId": 5238
  },
  {
    "cityId": 460,
    "cityName": "Caeté-Açu",
    "stateId": 5238
  },
  {
    "cityId": 461,
    "cityName": "Caetité",
    "stateId": 5238
  },
  {
    "cityId": 462,
    "cityName": "Cafarnaum",
    "stateId": 5238
  },
  {
    "cityId": 463,
    "cityName": "Caiçara",
    "stateId": 5238
  },
  {
    "cityId": 464,
    "cityName": "Caimbé",
    "stateId": 5238
  },
  {
    "cityId": 465,
    "cityName": "Cairu",
    "stateId": 5238
  },
  {
    "cityId": 466,
    "cityName": "Caiubi",
    "stateId": 5238
  },
  {
    "cityId": 467,
    "cityName": "Cajuí",
    "stateId": 5238
  },
  {
    "cityId": 468,
    "cityName": "Caldas do Jorro",
    "stateId": 5238
  },
  {
    "cityId": 469,
    "cityName": "Caldeirão",
    "stateId": 5238
  },
  {
    "cityId": 470,
    "cityName": "Caldeirão Grande",
    "stateId": 5238
  },
  {
    "cityId": 471,
    "cityName": "Caldeiras",
    "stateId": 5238
  },
  {
    "cityId": 472,
    "cityName": "Camacan",
    "stateId": 5238
  },
  {
    "cityId": 473,
    "cityName": "Camaçari",
    "stateId": 5238
  },
  {
    "cityId": 474,
    "cityName": "Camamu",
    "stateId": 5238
  },
  {
    "cityId": 475,
    "cityName": "Camassandi",
    "stateId": 5238
  },
  {
    "cityId": 476,
    "cityName": "Camirim",
    "stateId": 5238
  },
  {
    "cityId": 477,
    "cityName": "Campinhos",
    "stateId": 5238
  },
  {
    "cityId": 478,
    "cityName": "Campo Alegre de Lourdes",
    "stateId": 5238
  },
  {
    "cityId": 479,
    "cityName": "Campo Formoso",
    "stateId": 5238
  },
  {
    "cityId": 480,
    "cityName": "Campo Formoso",
    "stateId": 5238
  },
  {
    "cityId": 481,
    "cityName": "Camurugi",
    "stateId": 5238
  },
  {
    "cityId": 482,
    "cityName": "Canabravinha",
    "stateId": 5238
  },
  {
    "cityId": 483,
    "cityName": "Canápolis",
    "stateId": 5238
  },
  {
    "cityId": 484,
    "cityName": "Canarana",
    "stateId": 5238
  },
  {
    "cityId": 485,
    "cityName": "Canatiba",
    "stateId": 5238
  },
  {
    "cityId": 486,
    "cityName": "Canavieiras",
    "stateId": 5238
  },
  {
    "cityId": 487,
    "cityName": "Canché",
    "stateId": 5238
  },
  {
    "cityId": 488,
    "cityName": "Candeal",
    "stateId": 5238
  },
  {
    "cityId": 489,
    "cityName": "Candeias",
    "stateId": 5238
  },
  {
    "cityId": 490,
    "cityName": "Candiba",
    "stateId": 5238
  },
  {
    "cityId": 491,
    "cityName": "Cândido Sales",
    "stateId": 5238
  },
  {
    "cityId": 492,
    "cityName": "Canoão",
    "stateId": 5238
  },
  {
    "cityId": 493,
    "cityName": "Cansanção",
    "stateId": 5238
  },
  {
    "cityId": 494,
    "cityName": "Canto do Sol",
    "stateId": 5238
  },
  {
    "cityId": 495,
    "cityName": "Canudos",
    "stateId": 5238
  },
  {
    "cityId": 496,
    "cityName": "Canudos",
    "stateId": 5238
  },
  {
    "cityId": 497,
    "cityName": "Capão",
    "stateId": 5238
  },
  {
    "cityId": 498,
    "cityName": "Capela do Alto Alegre",
    "stateId": 5238
  },
  {
    "cityId": 499,
    "cityName": "Capim Grosso",
    "stateId": 5238
  },
  {
    "cityId": 500,
    "cityName": "Caraguataí",
    "stateId": 5238
  },
  {
    "cityId": 501,
    "cityName": "Caraíbas",
    "stateId": 5238
  },
  {
    "cityId": 502,
    "cityName": "Caraibuna",
    "stateId": 5238
  },
  {
    "cityId": 503,
    "cityName": "Caraípe",
    "stateId": 5238
  },
  {
    "cityId": 504,
    "cityName": "Caraiva",
    "stateId": 5238
  },
  {
    "cityId": 505,
    "cityName": "Caravelas",
    "stateId": 5238
  },
  {
    "cityId": 506,
    "cityName": "Cardeal da Silva",
    "stateId": 5238
  },
  {
    "cityId": 507,
    "cityName": "Carinhanha",
    "stateId": 5238
  },
  {
    "cityId": 508,
    "cityName": "Caripare",
    "stateId": 5238
  },
  {
    "cityId": 509,
    "cityName": "Carnaíba do Sertão",
    "stateId": 5238
  },
  {
    "cityId": 510,
    "cityName": "Carrapichel",
    "stateId": 5238
  },
  {
    "cityId": 511,
    "cityName": "Casa Nova",
    "stateId": 5238
  },
  {
    "cityId": 512,
    "cityName": "Castelo Novo",
    "stateId": 5238
  },
  {
    "cityId": 513,
    "cityName": "Castro Alves",
    "stateId": 5238
  },
  {
    "cityId": 514,
    "cityName": "Catinga do Moura",
    "stateId": 5238
  },
  {
    "cityId": 515,
    "cityName": "Catingal",
    "stateId": 5238
  },
  {
    "cityId": 516,
    "cityName": "Catolândia",
    "stateId": 5238
  },
  {
    "cityId": 517,
    "cityName": "Catolés",
    "stateId": 5238
  },
  {
    "cityId": 518,
    "cityName": "Catolezinho",
    "stateId": 5238
  },
  {
    "cityId": 519,
    "cityName": "Catu",
    "stateId": 5238
  },
  {
    "cityId": 520,
    "cityName": "Catu de Abrantes",
    "stateId": 5238
  },
  {
    "cityId": 521,
    "cityName": "Caturama",
    "stateId": 5238
  },
  {
    "cityId": 522,
    "cityName": "Cavunge",
    "stateId": 5238
  },
  {
    "cityId": 523,
    "cityName": "Central",
    "stateId": 5238
  },
  {
    "cityId": 524,
    "cityName": "Ceraima",
    "stateId": 5238
  },
  {
    "cityId": 525,
    "cityName": "Chorrochó",
    "stateId": 5238
  },
  {
    "cityId": 526,
    "cityName": "Cícero Dantas",
    "stateId": 5238
  },
  {
    "cityId": 527,
    "cityName": "Cinco Rios",
    "stateId": 5238
  },
  {
    "cityId": 528,
    "cityName": "Cipó",
    "stateId": 5238
  },
  {
    "cityId": 529,
    "cityName": "Coaraci",
    "stateId": 5238
  },
  {
    "cityId": 530,
    "cityName": "Cocos",
    "stateId": 5238
  },
  {
    "cityId": 531,
    "cityName": "Colônia",
    "stateId": 5238
  },
  {
    "cityId": 532,
    "cityName": "Comércio",
    "stateId": 5238
  },
  {
    "cityId": 533,
    "cityName": "Conceição da Feira",
    "stateId": 5238
  },
  {
    "cityId": 534,
    "cityName": "Conceição do Almeida",
    "stateId": 5238
  },
  {
    "cityId": 535,
    "cityName": "Conceição do Coité",
    "stateId": 5238
  },
  {
    "cityId": 536,
    "cityName": "Conceição do Jacuípe",
    "stateId": 5238
  },
  {
    "cityId": 537,
    "cityName": "Conde",
    "stateId": 5238
  },
  {
    "cityId": 538,
    "cityName": "Condeúba",
    "stateId": 5238
  },
  {
    "cityId": 539,
    "cityName": "Contendas do Sincorá",
    "stateId": 5238
  },
  {
    "cityId": 540,
    "cityName": "Copixaba",
    "stateId": 5238
  },
  {
    "cityId": 541,
    "cityName": "Coqueiros",
    "stateId": 5238
  },
  {
    "cityId": 542,
    "cityName": "Coquinhos",
    "stateId": 5238
  },
  {
    "cityId": 543,
    "cityName": "Coração de Maria",
    "stateId": 5238
  },
  {
    "cityId": 544,
    "cityName": "Cordeiros",
    "stateId": 5238
  },
  {
    "cityId": 545,
    "cityName": "Coribe",
    "stateId": 5238
  },
  {
    "cityId": 546,
    "cityName": "Coronel João Sá",
    "stateId": 5238
  },
  {
    "cityId": 547,
    "cityName": "Correntina",
    "stateId": 5238
  },
  {
    "cityId": 548,
    "cityName": "Corta Mão",
    "stateId": 5238
  },
  {
    "cityId": 549,
    "cityName": "Cotegipe",
    "stateId": 5238
  },
  {
    "cityId": 550,
    "cityName": "Coutos",
    "stateId": 5238
  },
  {
    "cityId": 551,
    "cityName": "Cravolândia",
    "stateId": 5238
  },
  {
    "cityId": 552,
    "cityName": "Crisópolis",
    "stateId": 5238
  },
  {
    "cityId": 553,
    "cityName": "Cristalândia",
    "stateId": 5238
  },
  {
    "cityId": 554,
    "cityName": "Cristópolis",
    "stateId": 5238
  },
  {
    "cityId": 555,
    "cityName": "Crussaí",
    "stateId": 5238
  },
  {
    "cityId": 556,
    "cityName": "Cruz das Almas",
    "stateId": 5238
  },
  {
    "cityId": 557,
    "cityName": "Cumuruxatiba",
    "stateId": 5238
  },
  {
    "cityId": 558,
    "cityName": "Cunhangi",
    "stateId": 5238
  },
  {
    "cityId": 559,
    "cityName": "Curaçá",
    "stateId": 5238
  },
  {
    "cityId": 560,
    "cityName": "Curral Falso",
    "stateId": 5238
  },
  {
    "cityId": 561,
    "cityName": "Dário Meira",
    "stateId": 5238
  },
  {
    "cityId": 562,
    "cityName": "Delfino",
    "stateId": 5238
  },
  {
    "cityId": 563,
    "cityName": "Descoberto",
    "stateId": 5238
  },
  {
    "cityId": 564,
    "cityName": "Dias Coelho",
    "stateId": 5238
  },
  {
    "cityId": 565,
    "cityName": "Dias D'Ávila",
    "stateId": 5238
  },
  {
    "cityId": 566,
    "cityName": "Diógenes Sampaio",
    "stateId": 5238
  },
  {
    "cityId": 567,
    "cityName": "Dom Basílio",
    "stateId": 5238
  },
  {
    "cityId": 568,
    "cityName": "Dom Macedo Costa",
    "stateId": 5238
  },
  {
    "cityId": 569,
    "cityName": "Dona Maria",
    "stateId": 5238
  },
  {
    "cityId": 570,
    "cityName": "Duas Barras do Morro",
    "stateId": 5238
  },
  {
    "cityId": 571,
    "cityName": "Elísio Medrado",
    "stateId": 5238
  },
  {
    "cityId": 572,
    "cityName": "Encruzilhada",
    "stateId": 5238
  },
  {
    "cityId": 573,
    "cityName": "Engenheiro França",
    "stateId": 5238
  },
  {
    "cityId": 574,
    "cityName": "Engenheiro Pontes",
    "stateId": 5238
  },
  {
    "cityId": 575,
    "cityName": "Entre Rios",
    "stateId": 5238
  },
  {
    "cityId": 576,
    "cityName": "Érico Cardoso",
    "stateId": 5238
  },
  {
    "cityId": 577,
    "cityName": "Esplanada",
    "stateId": 5238
  },
  {
    "cityId": 578,
    "cityName": "Euclides da Cunha",
    "stateId": 5238
  },
  {
    "cityId": 579,
    "cityName": "Eunápolis",
    "stateId": 5238
  },
  {
    "cityId": 580,
    "cityName": "Fátima",
    "stateId": 5238
  },
  {
    "cityId": 581,
    "cityName": "Feira da Mata",
    "stateId": 5238
  },
  {
    "cityId": 582,
    "cityName": "Feira de Santana",
    "stateId": 5238
  },
  {
    "cityId": 583,
    "cityName": "Ferradas",
    "stateId": 5238
  },
  {
    "cityId": 584,
    "cityName": "Filadélfia",
    "stateId": 5238
  },
  {
    "cityId": 585,
    "cityName": "Filanésia",
    "stateId": 5238
  },
  {
    "cityId": 586,
    "cityName": "Firmino Alves",
    "stateId": 5238
  },
  {
    "cityId": 587,
    "cityName": "Floresta Azul",
    "stateId": 5238
  },
  {
    "cityId": 588,
    "cityName": "Formosa do Rio Preto",
    "stateId": 5238
  },
  {
    "cityId": 589,
    "cityName": "França",
    "stateId": 5238
  },
  {
    "cityId": 590,
    "cityName": "Gabiarra",
    "stateId": 5238
  },
  {
    "cityId": 591,
    "cityName": "Galeão",
    "stateId": 5238
  },
  {
    "cityId": 592,
    "cityName": "Gamboa",
    "stateId": 5238
  },
  {
    "cityId": 593,
    "cityName": "Gameleira da Lapa",
    "stateId": 5238
  },
  {
    "cityId": 594,
    "cityName": "Gameleira do Assuruá",
    "stateId": 5238
  },
  {
    "cityId": 595,
    "cityName": "Gandu",
    "stateId": 5238
  },
  {
    "cityId": 596,
    "cityName": "Gavião",
    "stateId": 5238
  },
  {
    "cityId": 597,
    "cityName": "Gentio do Ouro",
    "stateId": 5238
  },
  {
    "cityId": 598,
    "cityName": "Geolândia",
    "stateId": 5238
  },
  {
    "cityId": 599,
    "cityName": "Glória",
    "stateId": 5238
  },
  {
    "cityId": 600,
    "cityName": "Gongogi",
    "stateId": 5238
  },
  {
    "cityId": 601,
    "cityName": "Governador João Durval Carneiro",
    "stateId": 5238
  },
  {
    "cityId": 602,
    "cityName": "Governador Mangabeira",
    "stateId": 5238
  },
  {
    "cityId": 603,
    "cityName": "Guagirus",
    "stateId": 5238
  },
  {
    "cityId": 604,
    "cityName": "Guaí",
    "stateId": 5238
  },
  {
    "cityId": 605,
    "cityName": "Guajeru",
    "stateId": 5238
  },
  {
    "cityId": 606,
    "cityName": "Guanambi",
    "stateId": 5238
  },
  {
    "cityId": 607,
    "cityName": "Guapira",
    "stateId": 5238
  },
  {
    "cityId": 608,
    "cityName": "Guarajuba",
    "stateId": 5238
  },
  {
    "cityId": 609,
    "cityName": "Guaratinga",
    "stateId": 5238
  },
  {
    "cityId": 610,
    "cityName": "Guerém",
    "stateId": 5238
  },
  {
    "cityId": 611,
    "cityName": "Guiné",
    "stateId": 5238
  },
  {
    "cityId": 612,
    "cityName": "Guirapa",
    "stateId": 5238
  },
  {
    "cityId": 613,
    "cityName": "Gurupá Mirim",
    "stateId": 5238
  },
  {
    "cityId": 614,
    "cityName": "Heliópolis",
    "stateId": 5238
  },
  {
    "cityId": 615,
    "cityName": "Helvecia",
    "stateId": 5238
  },
  {
    "cityId": 616,
    "cityName": "Hidrolândia",
    "stateId": 5238
  },
  {
    "cityId": 617,
    "cityName": "Humildes",
    "stateId": 5238
  },
  {
    "cityId": 618,
    "cityName": "Iaçu",
    "stateId": 5238
  },
  {
    "cityId": 619,
    "cityName": "Ibatui",
    "stateId": 5238
  },
  {
    "cityId": 620,
    "cityName": "Ibiaçu",
    "stateId": 5238
  },
  {
    "cityId": 621,
    "cityName": "Ibiajara",
    "stateId": 5238
  },
  {
    "cityId": 622,
    "cityName": "Ibiapora",
    "stateId": 5238
  },
  {
    "cityId": 623,
    "cityName": "Ibiassucê",
    "stateId": 5238
  },
  {
    "cityId": 624,
    "cityName": "Ibicaraí",
    "stateId": 5238
  },
  {
    "cityId": 625,
    "cityName": "Ibicoara",
    "stateId": 5238
  },
  {
    "cityId": 626,
    "cityName": "Ibicuí",
    "stateId": 5238
  },
  {
    "cityId": 627,
    "cityName": "Ibipeba",
    "stateId": 5238
  },
  {
    "cityId": 628,
    "cityName": "Ibipetum",
    "stateId": 5238
  },
  {
    "cityId": 629,
    "cityName": "Ibipitanga",
    "stateId": 5238
  },
  {
    "cityId": 630,
    "cityName": "Ibiquera",
    "stateId": 5238
  },
  {
    "cityId": 631,
    "cityName": "Ibiraba",
    "stateId": 5238
  },
  {
    "cityId": 632,
    "cityName": "Ibirajá",
    "stateId": 5238
  },
  {
    "cityId": 633,
    "cityName": "Ibiranhém",
    "stateId": 5238
  },
  {
    "cityId": 634,
    "cityName": "Ibirapitanga",
    "stateId": 5238
  },
  {
    "cityId": 635,
    "cityName": "Ibirapuã",
    "stateId": 5238
  },
  {
    "cityId": 636,
    "cityName": "Ibirataia",
    "stateId": 5238
  },
  {
    "cityId": 637,
    "cityName": "Ibitiara",
    "stateId": 5238
  },
  {
    "cityId": 638,
    "cityName": "Ibitiguira",
    "stateId": 5238
  },
  {
    "cityId": 639,
    "cityName": "Ibitira",
    "stateId": 5238
  },
  {
    "cityId": 640,
    "cityName": "Ibititá",
    "stateId": 5238
  },
  {
    "cityId": 641,
    "cityName": "Ibitunane",
    "stateId": 5238
  },
  {
    "cityId": 642,
    "cityName": "Ibitupa",
    "stateId": 5238
  },
  {
    "cityId": 643,
    "cityName": "Ibó",
    "stateId": 5238
  },
  {
    "cityId": 644,
    "cityName": "Ibotirama",
    "stateId": 5238
  },
  {
    "cityId": 645,
    "cityName": "Ichu",
    "stateId": 5238
  },
  {
    "cityId": 646,
    "cityName": "Icó",
    "stateId": 5238
  },
  {
    "cityId": 647,
    "cityName": "Igaporã",
    "stateId": 5238
  },
  {
    "cityId": 648,
    "cityName": "Igará",
    "stateId": 5238
  },
  {
    "cityId": 649,
    "cityName": "Igarité",
    "stateId": 5238
  },
  {
    "cityId": 650,
    "cityName": "Igatu",
    "stateId": 5238
  },
  {
    "cityId": 651,
    "cityName": "Igrapiúna",
    "stateId": 5238
  },
  {
    "cityId": 652,
    "cityName": "Iguá",
    "stateId": 5238
  },
  {
    "cityId": 653,
    "cityName": "Iguaí",
    "stateId": 5238
  },
  {
    "cityId": 654,
    "cityName": "Iguaibi",
    "stateId": 5238
  },
  {
    "cityId": 655,
    "cityName": "Iguatemi",
    "stateId": 5238
  },
  {
    "cityId": 656,
    "cityName": "Iguira",
    "stateId": 5238
  },
  {
    "cityId": 657,
    "cityName": "Iguitu",
    "stateId": 5238
  },
  {
    "cityId": 658,
    "cityName": "Ilha de Maré",
    "stateId": 5238
  },
  {
    "cityId": 659,
    "cityName": "Ilhéus",
    "stateId": 5238
  },
  {
    "cityId": 660,
    "cityName": "Indaí",
    "stateId": 5238
  },
  {
    "cityId": 661,
    "cityName": "Inema",
    "stateId": 5238
  },
  {
    "cityId": 662,
    "cityName": "Inhambupe",
    "stateId": 5238
  },
  {
    "cityId": 663,
    "cityName": "Inhata",
    "stateId": 5238
  },
  {
    "cityId": 664,
    "cityName": "Inhaúmas",
    "stateId": 5238
  },
  {
    "cityId": 665,
    "cityName": "Inhobim",
    "stateId": 5238
  },
  {
    "cityId": 666,
    "cityName": "Inúbia",
    "stateId": 5238
  },
  {
    "cityId": 667,
    "cityName": "Ipecaetá",
    "stateId": 5238
  },
  {
    "cityId": 668,
    "cityName": "Ipiaú",
    "stateId": 5238
  },
  {
    "cityId": 669,
    "cityName": "Ipirá",
    "stateId": 5238
  },
  {
    "cityId": 670,
    "cityName": "Ipiúna",
    "stateId": 5238
  },
  {
    "cityId": 671,
    "cityName": "Ipucaba",
    "stateId": 5238
  },
  {
    "cityId": 672,
    "cityName": "Ipupiara",
    "stateId": 5238
  },
  {
    "cityId": 673,
    "cityName": "Irajuba",
    "stateId": 5238
  },
  {
    "cityId": 674,
    "cityName": "Iramaia",
    "stateId": 5238
  },
  {
    "cityId": 675,
    "cityName": "Iraporanga",
    "stateId": 5238
  },
  {
    "cityId": 676,
    "cityName": "Iraquara",
    "stateId": 5238
  },
  {
    "cityId": 677,
    "cityName": "Irará",
    "stateId": 5238
  },
  {
    "cityId": 678,
    "cityName": "Irecê",
    "stateId": 5238
  },
  {
    "cityId": 679,
    "cityName": "Irundiara",
    "stateId": 5238
  },
  {
    "cityId": 680,
    "cityName": "Ita-Azul",
    "stateId": 5238
  },
  {
    "cityId": 681,
    "cityName": "Itabela",
    "stateId": 5238
  },
  {
    "cityId": 682,
    "cityName": "Itaberaba",
    "stateId": 5238
  },
  {
    "cityId": 683,
    "cityName": "Itabuna",
    "stateId": 5238
  },
  {
    "cityId": 684,
    "cityName": "Itacaré",
    "stateId": 5238
  },
  {
    "cityId": 685,
    "cityName": "Itacava",
    "stateId": 5238
  },
  {
    "cityId": 686,
    "cityName": "Itachama",
    "stateId": 5238
  },
  {
    "cityId": 687,
    "cityName": "Itacimirim",
    "stateId": 5238
  },
  {
    "cityId": 688,
    "cityName": "Itaeté",
    "stateId": 5238
  },
  {
    "cityId": 689,
    "cityName": "Itagi",
    "stateId": 5238
  },
  {
    "cityId": 690,
    "cityName": "Itagibá",
    "stateId": 5238
  },
  {
    "cityId": 691,
    "cityName": "Itagimirim",
    "stateId": 5238
  },
  {
    "cityId": 692,
    "cityName": "Itaguaçu da Bahia",
    "stateId": 5238
  },
  {
    "cityId": 693,
    "cityName": "Itaia",
    "stateId": 5238
  },
  {
    "cityId": 694,
    "cityName": "Itaibó",
    "stateId": 5238
  },
  {
    "cityId": 695,
    "cityName": "Itaipu",
    "stateId": 5238
  },
  {
    "cityId": 696,
    "cityName": "Itaítu",
    "stateId": 5238
  },
  {
    "cityId": 697,
    "cityName": "Itajaí",
    "stateId": 5238
  },
  {
    "cityId": 698,
    "cityName": "Itaju do Colônia",
    "stateId": 5238
  },
  {
    "cityId": 699,
    "cityName": "Itajubaquara",
    "stateId": 5238
  },
  {
    "cityId": 700,
    "cityName": "Itajuípe",
    "stateId": 5238
  },
  {
    "cityId": 701,
    "cityName": "Itajuru",
    "stateId": 5238
  },
  {
    "cityId": 702,
    "cityName": "Itamaraju",
    "stateId": 5238
  },
  {
    "cityId": 703,
    "cityName": "Itamari",
    "stateId": 5238
  },
  {
    "cityId": 704,
    "cityName": "Itambé",
    "stateId": 5238
  },
  {
    "cityId": 705,
    "cityName": "Itamira",
    "stateId": 5238
  },
  {
    "cityId": 706,
    "cityName": "Itamotinga",
    "stateId": 5238
  },
  {
    "cityId": 707,
    "cityName": "Itanagé",
    "stateId": 5238
  },
  {
    "cityId": 708,
    "cityName": "Itanagra",
    "stateId": 5238
  },
  {
    "cityId": 709,
    "cityName": "Itanhém",
    "stateId": 5238
  },
  {
    "cityId": 710,
    "cityName": "Itanhi",
    "stateId": 5238
  },
  {
    "cityId": 711,
    "cityName": "Itaparica",
    "stateId": 5238
  },
  {
    "cityId": 712,
    "cityName": "Itapé",
    "stateId": 5238
  },
  {
    "cityId": 713,
    "cityName": "Itapebi",
    "stateId": 5238
  },
  {
    "cityId": 714,
    "cityName": "Itapeipu",
    "stateId": 5238
  },
  {
    "cityId": 715,
    "cityName": "Itapetinga",
    "stateId": 5238
  },
  {
    "cityId": 716,
    "cityName": "Itapicuru",
    "stateId": 5238
  },
  {
    "cityId": 717,
    "cityName": "Itapirema",
    "stateId": 5238
  },
  {
    "cityId": 718,
    "cityName": "Itapitanga",
    "stateId": 5238
  },
  {
    "cityId": 719,
    "cityName": "Itapora",
    "stateId": 5238
  },
  {
    "cityId": 720,
    "cityName": "Itapura",
    "stateId": 5238
  },
  {
    "cityId": 721,
    "cityName": "Itaquara",
    "stateId": 5238
  },
  {
    "cityId": 722,
    "cityName": "Itaquaraí",
    "stateId": 5238
  },
  {
    "cityId": 723,
    "cityName": "Itarantim",
    "stateId": 5238
  },
  {
    "cityId": 724,
    "cityName": "Itati",
    "stateId": 5238
  },
  {
    "cityId": 725,
    "cityName": "Itatim",
    "stateId": 5238
  },
  {
    "cityId": 726,
    "cityName": "Itatingui",
    "stateId": 5238
  },
  {
    "cityId": 727,
    "cityName": "Itiruçu",
    "stateId": 5238
  },
  {
    "cityId": 728,
    "cityName": "Itiúba",
    "stateId": 5238
  },
  {
    "cityId": 729,
    "cityName": "Itororó",
    "stateId": 5238
  },
  {
    "cityId": 730,
    "cityName": "Ituaçu",
    "stateId": 5238
  },
  {
    "cityId": 731,
    "cityName": "Ituberá",
    "stateId": 5238
  },
  {
    "cityId": 732,
    "cityName": "Itupeva",
    "stateId": 5238
  },
  {
    "cityId": 733,
    "cityName": "Iuiu",
    "stateId": 5238
  },
  {
    "cityId": 734,
    "cityName": "Jaborandi",
    "stateId": 5238
  },
  {
    "cityId": 735,
    "cityName": "Jacaraci",
    "stateId": 5238
  },
  {
    "cityId": 736,
    "cityName": "Jacobina",
    "stateId": 5238
  },
  {
    "cityId": 737,
    "cityName": "Jacu",
    "stateId": 5238
  },
  {
    "cityId": 738,
    "cityName": "Jacuípe",
    "stateId": 5238
  },
  {
    "cityId": 739,
    "cityName": "Jacuruna",
    "stateId": 5238
  },
  {
    "cityId": 740,
    "cityName": "Jaguaquara",
    "stateId": 5238
  },
  {
    "cityId": 741,
    "cityName": "Jaguara",
    "stateId": 5238
  },
  {
    "cityId": 742,
    "cityName": "Jaguarari",
    "stateId": 5238
  },
  {
    "cityId": 743,
    "cityName": "Jaguaripe",
    "stateId": 5238
  },
  {
    "cityId": 744,
    "cityName": "Jaíba",
    "stateId": 5238
  },
  {
    "cityId": 745,
    "cityName": "Jandaíra",
    "stateId": 5238
  },
  {
    "cityId": 746,
    "cityName": "Japomirim",
    "stateId": 5238
  },
  {
    "cityId": 747,
    "cityName": "Japu",
    "stateId": 5238
  },
  {
    "cityId": 748,
    "cityName": "Jauá",
    "stateId": 5238
  },
  {
    "cityId": 749,
    "cityName": "Jequié",
    "stateId": 5238
  },
  {
    "cityId": 750,
    "cityName": "Jequiriçá",
    "stateId": 5238
  },
  {
    "cityId": 751,
    "cityName": "Jeremoabo",
    "stateId": 5238
  },
  {
    "cityId": 752,
    "cityName": "Jiribatuba",
    "stateId": 5238
  },
  {
    "cityId": 753,
    "cityName": "Jitaúna",
    "stateId": 5238
  },
  {
    "cityId": 754,
    "cityName": "João Amaro",
    "stateId": 5238
  },
  {
    "cityId": 755,
    "cityName": "João Correia",
    "stateId": 5238
  },
  {
    "cityId": 756,
    "cityName": "João Dourado",
    "stateId": 5238
  },
  {
    "cityId": 757,
    "cityName": "José Gonçalves",
    "stateId": 5238
  },
  {
    "cityId": 758,
    "cityName": "Juacema",
    "stateId": 5238
  },
  {
    "cityId": 759,
    "cityName": "Juazeiro",
    "stateId": 5238
  },
  {
    "cityId": 760,
    "cityName": "Jucuruçu",
    "stateId": 5238
  },
  {
    "cityId": 761,
    "cityName": "Juerana",
    "stateId": 5238
  },
  {
    "cityId": 762,
    "cityName": "Junco",
    "stateId": 5238
  },
  {
    "cityId": 763,
    "cityName": "Jupaguá",
    "stateId": 5238
  },
  {
    "cityId": 764,
    "cityName": "Juraci",
    "stateId": 5238
  },
  {
    "cityId": 765,
    "cityName": "Juremal",
    "stateId": 5238
  },
  {
    "cityId": 766,
    "cityName": "Jussara",
    "stateId": 5238
  },
  {
    "cityId": 767,
    "cityName": "Jussari",
    "stateId": 5238
  },
  {
    "cityId": 768,
    "cityName": "Jussiape",
    "stateId": 5238
  },
  {
    "cityId": 769,
    "cityName": "Km Sete",
    "stateId": 5238
  },
  {
    "cityId": 770,
    "cityName": "Lafaiete Coutinho",
    "stateId": 5238
  },
  {
    "cityId": 771,
    "cityName": "Lagoa Clara",
    "stateId": 5238
  },
  {
    "cityId": 772,
    "cityName": "Lagoa de Melquíades",
    "stateId": 5238
  },
  {
    "cityId": 773,
    "cityName": "Lagoa do Boi",
    "stateId": 5238
  },
  {
    "cityId": 774,
    "cityName": "Lagoa Grande",
    "stateId": 5238
  },
  {
    "cityId": 775,
    "cityName": "Lagoa Grande",
    "stateId": 5238
  },
  {
    "cityId": 776,
    "cityName": "Lagoa José Luís",
    "stateId": 5238
  },
  {
    "cityId": 777,
    "cityName": "Lagoa Preta",
    "stateId": 5238
  },
  {
    "cityId": 778,
    "cityName": "Lagoa Real",
    "stateId": 5238
  },
  {
    "cityId": 779,
    "cityName": "Laje",
    "stateId": 5238
  },
  {
    "cityId": 780,
    "cityName": "Laje do Banco",
    "stateId": 5238
  },
  {
    "cityId": 781,
    "cityName": "Lajedão",
    "stateId": 5238
  },
  {
    "cityId": 782,
    "cityName": "Lajedinho",
    "stateId": 5238
  },
  {
    "cityId": 783,
    "cityName": "Lajedo Alto",
    "stateId": 5238
  },
  {
    "cityId": 784,
    "cityName": "Lajedo do Tabocal",
    "stateId": 5238
  },
  {
    "cityId": 785,
    "cityName": "Lamarão",
    "stateId": 5238
  },
  {
    "cityId": 786,
    "cityName": "Lamarão do Passe",
    "stateId": 5238
  },
  {
    "cityId": 787,
    "cityName": "Lapão",
    "stateId": 5238
  },
  {
    "cityId": 788,
    "cityName": "Largo",
    "stateId": 5238
  },
  {
    "cityId": 789,
    "cityName": "Lauro de Freitas",
    "stateId": 5238
  },
  {
    "cityId": 790,
    "cityName": "Lençóis",
    "stateId": 5238
  },
  {
    "cityId": 791,
    "cityName": "Licínio de Almeida",
    "stateId": 5238
  },
  {
    "cityId": 792,
    "cityName": "Limoeiro do Bom Viver",
    "stateId": 5238
  },
  {
    "cityId": 793,
    "cityName": "Livramento de Nossa Senhora",
    "stateId": 5238
  },
  {
    "cityId": 794,
    "cityName": "Lucaia",
    "stateId": 5238
  },
  {
    "cityId": 795,
    "cityName": "Luís Viana",
    "stateId": 5238
  },
  {
    "cityId": 796,
    "cityName": "Lustosa",
    "stateId": 5238
  },
  {
    "cityId": 797,
    "cityName": "Macajuba",
    "stateId": 5238
  },
  {
    "cityId": 798,
    "cityName": "Macarani",
    "stateId": 5238
  },
  {
    "cityId": 799,
    "cityName": "Macaúbas",
    "stateId": 5238
  },
  {
    "cityId": 800,
    "cityName": "Macururé",
    "stateId": 5238
  },
  {
    "cityId": 801,
    "cityName": "Madre de Deus",
    "stateId": 5238
  },
  {
    "cityId": 802,
    "cityName": "Maetinga",
    "stateId": 5238
  },
  {
    "cityId": 803,
    "cityName": "Maiquinique",
    "stateId": 5238
  },
  {
    "cityId": 804,
    "cityName": "Mairi",
    "stateId": 5238
  },
  {
    "cityId": 805,
    "cityName": "Malhada",
    "stateId": 5238
  },
  {
    "cityId": 806,
    "cityName": "Malhada de Pedras",
    "stateId": 5238
  },
  {
    "cityId": 807,
    "cityName": "Mandiroba",
    "stateId": 5238
  },
  {
    "cityId": 808,
    "cityName": "Mangue Seco",
    "stateId": 5238
  },
  {
    "cityId": 809,
    "cityName": "Maniaçu",
    "stateId": 5238
  },
  {
    "cityId": 810,
    "cityName": "Manoel Vitorino",
    "stateId": 5238
  },
  {
    "cityId": 811,
    "cityName": "Mansidão",
    "stateId": 5238
  },
  {
    "cityId": 812,
    "cityName": "Mantiba",
    "stateId": 5238
  },
  {
    "cityId": 813,
    "cityName": "Mar Grande",
    "stateId": 5238
  },
  {
    "cityId": 814,
    "cityName": "Maracás",
    "stateId": 5238
  },
  {
    "cityId": 815,
    "cityName": "Maragogipe",
    "stateId": 5238
  },
  {
    "cityId": 816,
    "cityName": "Maragogipinho",
    "stateId": 5238
  },
  {
    "cityId": 817,
    "cityName": "Maraú",
    "stateId": 5238
  },
  {
    "cityId": 818,
    "cityName": "Marcionílio Souza",
    "stateId": 5238
  },
  {
    "cityId": 819,
    "cityName": "Marcolino Moura",
    "stateId": 5238
  },
  {
    "cityId": 820,
    "cityName": "Maria Quitéria",
    "stateId": 5238
  },
  {
    "cityId": 821,
    "cityName": "Maricoabo",
    "stateId": 5238
  },
  {
    "cityId": 822,
    "cityName": "Mariquita",
    "stateId": 5238
  },
  {
    "cityId": 823,
    "cityName": "Mascote",
    "stateId": 5238
  },
  {
    "cityId": 824,
    "cityName": "Massacara",
    "stateId": 5238
  },
  {
    "cityId": 825,
    "cityName": "Massaroca",
    "stateId": 5238
  },
  {
    "cityId": 826,
    "cityName": "Mata da Aliança",
    "stateId": 5238
  },
  {
    "cityId": 827,
    "cityName": "Mata de São João",
    "stateId": 5238
  },
  {
    "cityId": 828,
    "cityName": "Mataripe",
    "stateId": 5238
  },
  {
    "cityId": 829,
    "cityName": "Matina",
    "stateId": 5238
  },
  {
    "cityId": 830,
    "cityName": "Matinha",
    "stateId": 5238
  },
  {
    "cityId": 831,
    "cityName": "Medeiros Neto",
    "stateId": 5238
  },
  {
    "cityId": 832,
    "cityName": "Miguel Calmon",
    "stateId": 5238
  },
  {
    "cityId": 833,
    "cityName": "Milagres",
    "stateId": 5238
  },
  {
    "cityId": 834,
    "cityName": "Luís Eduardo Magalhães",
    "stateId": 5238
  },
  {
    "cityId": 835,
    "cityName": "Minas do Espírito Santo",
    "stateId": 5238
  },
  {
    "cityId": 836,
    "cityName": "Minas do Mimoso",
    "stateId": 5238
  },
  {
    "cityId": 837,
    "cityName": "Mirandela",
    "stateId": 5238
  },
  {
    "cityId": 838,
    "cityName": "Miranga",
    "stateId": 5238
  },
  {
    "cityId": 839,
    "cityName": "Mirangaba",
    "stateId": 5238
  },
  {
    "cityId": 840,
    "cityName": "Mirante",
    "stateId": 5238
  },
  {
    "cityId": 841,
    "cityName": "Mocambo",
    "stateId": 5238
  },
  {
    "cityId": 842,
    "cityName": "Mogiquiçaba",
    "stateId": 5238
  },
  {
    "cityId": 843,
    "cityName": "Monte Cruzeiro",
    "stateId": 5238
  },
  {
    "cityId": 844,
    "cityName": "Monte Gordo",
    "stateId": 5238
  },
  {
    "cityId": 845,
    "cityName": "Monte Recôncavo",
    "stateId": 5238
  },
  {
    "cityId": 846,
    "cityName": "Monte Santo",
    "stateId": 5238
  },
  {
    "cityId": 847,
    "cityName": "Morpará",
    "stateId": 5238
  },
  {
    "cityId": 848,
    "cityName": "Morrinhos",
    "stateId": 5238
  },
  {
    "cityId": 849,
    "cityName": "Morro das Flores",
    "stateId": 5238
  },
  {
    "cityId": 850,
    "cityName": "Morro de São Paulo",
    "stateId": 5238
  },
  {
    "cityId": 851,
    "cityName": "Morro do Chapéu",
    "stateId": 5238
  },
  {
    "cityId": 852,
    "cityName": "Mortugaba",
    "stateId": 5238
  },
  {
    "cityId": 853,
    "cityName": "Mucugê",
    "stateId": 5238
  },
  {
    "cityId": 854,
    "cityName": "Mucuri",
    "stateId": 5238
  },
  {
    "cityId": 855,
    "cityName": "Mulungu do Morro",
    "stateId": 5238
  },
  {
    "cityId": 856,
    "cityName": "Mundo Novo",
    "stateId": 5238
  },
  {
    "cityId": 857,
    "cityName": "Muniz Ferreira",
    "stateId": 5238
  },
  {
    "cityId": 858,
    "cityName": "Muquém de São Francisco",
    "stateId": 5238
  },
  {
    "cityId": 859,
    "cityName": "Muritiba",
    "stateId": 5238
  },
  {
    "cityId": 860,
    "cityName": "Mutas",
    "stateId": 5238
  },
  {
    "cityId": 861,
    "cityName": "Mutuípe",
    "stateId": 5238
  },
  {
    "cityId": 862,
    "cityName": "Nagé",
    "stateId": 5238
  },
  {
    "cityId": 863,
    "cityName": "Narandiba",
    "stateId": 5238
  },
  {
    "cityId": 864,
    "cityName": "Nazaré",
    "stateId": 5238
  },
  {
    "cityId": 865,
    "cityName": "Nilo Peçanha",
    "stateId": 5238
  },
  {
    "cityId": 866,
    "cityName": "Nordestina",
    "stateId": 5238
  },
  {
    "cityId": 867,
    "cityName": "Nova Alegria",
    "stateId": 5238
  },
  {
    "cityId": 868,
    "cityName": "Nova Brasília",
    "stateId": 5238
  },
  {
    "cityId": 869,
    "cityName": "Nova Canaã",
    "stateId": 5238
  },
  {
    "cityId": 870,
    "cityName": "Nova Fátima",
    "stateId": 5238
  },
  {
    "cityId": 871,
    "cityName": "Nova Ibiá",
    "stateId": 5238
  },
  {
    "cityId": 872,
    "cityName": "Nova Itaipê",
    "stateId": 5238
  },
  {
    "cityId": 873,
    "cityName": "Nova Itarana",
    "stateId": 5238
  },
  {
    "cityId": 874,
    "cityName": "Nova Lídice",
    "stateId": 5238
  },
  {
    "cityId": 875,
    "cityName": "Nova Redenção",
    "stateId": 5238
  },
  {
    "cityId": 876,
    "cityName": "Nova Soure",
    "stateId": 5238
  },
  {
    "cityId": 877,
    "cityName": "Nova Viçosa",
    "stateId": 5238
  },
  {
    "cityId": 878,
    "cityName": "Novo Acre",
    "stateId": 5238
  },
  {
    "cityId": 879,
    "cityName": "Novo Horizonte",
    "stateId": 5238
  },
  {
    "cityId": 880,
    "cityName": "Novo Triunfo",
    "stateId": 5238
  },
  {
    "cityId": 881,
    "cityName": "Núcleo Residencial Pilar",
    "stateId": 5238
  },
  {
    "cityId": 882,
    "cityName": "Nuguaçu",
    "stateId": 5238
  },
  {
    "cityId": 883,
    "cityName": "Olhos D'Água do Seco",
    "stateId": 5238
  },
  {
    "cityId": 884,
    "cityName": "Olhos D'Água do Serafim",
    "stateId": 5238
  },
  {
    "cityId": 885,
    "cityName": "Olindina",
    "stateId": 5238
  },
  {
    "cityId": 886,
    "cityName": "Oliveira dos Brejinhos",
    "stateId": 5238
  },
  {
    "cityId": 887,
    "cityName": "Olivença",
    "stateId": 5238
  },
  {
    "cityId": 888,
    "cityName": "Onha",
    "stateId": 5238
  },
  {
    "cityId": 889,
    "cityName": "Oriente Novo",
    "stateId": 5238
  },
  {
    "cityId": 890,
    "cityName": "Ouricana",
    "stateId": 5238
  },
  {
    "cityId": 891,
    "cityName": "Ouriçangas",
    "stateId": 5238
  },
  {
    "cityId": 892,
    "cityName": "Ouricuri do Ouro",
    "stateId": 5238
  },
  {
    "cityId": 893,
    "cityName": "Ourolândia",
    "stateId": 5238
  },
  {
    "cityId": 894,
    "cityName": "Outeiro Redondo",
    "stateId": 5238
  },
  {
    "cityId": 895,
    "cityName": "Paiol",
    "stateId": 5238
  },
  {
    "cityId": 896,
    "cityName": "Pajeú do Vento",
    "stateId": 5238
  },
  {
    "cityId": 897,
    "cityName": "Palame",
    "stateId": 5238
  },
  {
    "cityId": 898,
    "cityName": "Palmas de Monte Alto",
    "stateId": 5238
  },
  {
    "cityId": 899,
    "cityName": "Palmeiras",
    "stateId": 5238
  },
  {
    "cityId": 900,
    "cityName": "Parafuso",
    "stateId": 5238
  },
  {
    "cityId": 901,
    "cityName": "Paramirim",
    "stateId": 5238
  },
  {
    "cityId": 902,
    "cityName": "Parateca",
    "stateId": 5238
  },
  {
    "cityId": 903,
    "cityName": "Paratinga",
    "stateId": 5238
  },
  {
    "cityId": 904,
    "cityName": "Paripiranga",
    "stateId": 5238
  },
  {
    "cityId": 905,
    "cityName": "Pataíba",
    "stateId": 5238
  },
  {
    "cityId": 906,
    "cityName": "Patamuté",
    "stateId": 5238
  },
  {
    "cityId": 907,
    "cityName": "Pau a Pique",
    "stateId": 5238
  },
  {
    "cityId": 908,
    "cityName": "Pau Brasil",
    "stateId": 5238
  },
  {
    "cityId": 909,
    "cityName": "Paulo Afonso",
    "stateId": 5238
  },
  {
    "cityId": 910,
    "cityName": "Pé de Serra",
    "stateId": 5238
  },
  {
    "cityId": 911,
    "cityName": "Pedrão",
    "stateId": 5238
  },
  {
    "cityId": 912,
    "cityName": "Pedras Altas do Mirim",
    "stateId": 5238
  },
  {
    "cityId": 913,
    "cityName": "Pedro Alexandre",
    "stateId": 5238
  },
  {
    "cityId": 914,
    "cityName": "Peixe",
    "stateId": 5238
  },
  {
    "cityId": 915,
    "cityName": "Petim",
    "stateId": 5238
  },
  {
    "cityId": 916,
    "cityName": "Piabanha",
    "stateId": 5238
  },
  {
    "cityId": 917,
    "cityName": "Piatã",
    "stateId": 5238
  },
  {
    "cityId": 918,
    "cityName": "Piçarrão",
    "stateId": 5238
  },
  {
    "cityId": 919,
    "cityName": "Pilão Arcado",
    "stateId": 5238
  },
  {
    "cityId": 920,
    "cityName": "Pimenteira",
    "stateId": 5238
  },
  {
    "cityId": 921,
    "cityName": "Pindaí",
    "stateId": 5238
  },
  {
    "cityId": 922,
    "cityName": "Pindobaçu",
    "stateId": 5238
  },
  {
    "cityId": 923,
    "cityName": "Pinhões",
    "stateId": 5238
  },
  {
    "cityId": 924,
    "cityName": "Pintadas",
    "stateId": 5238
  },
  {
    "cityId": 925,
    "cityName": "Piragi",
    "stateId": 5238
  },
  {
    "cityId": 926,
    "cityName": "Piraí do Norte",
    "stateId": 5238
  },
  {
    "cityId": 927,
    "cityName": "Pirajá",
    "stateId": 5238
  },
  {
    "cityId": 928,
    "cityName": "Pirajuia",
    "stateId": 5238
  },
  {
    "cityId": 929,
    "cityName": "Piri",
    "stateId": 5238
  },
  {
    "cityId": 930,
    "cityName": "Piripá",
    "stateId": 5238
  },
  {
    "cityId": 931,
    "cityName": "Piritiba",
    "stateId": 5238
  },
  {
    "cityId": 932,
    "cityName": "Pituba",
    "stateId": 5238
  },
  {
    "cityId": 933,
    "cityName": "Planaltino",
    "stateId": 5238
  },
  {
    "cityId": 934,
    "cityName": "Planalto",
    "stateId": 5238
  },
  {
    "cityId": 935,
    "cityName": "Poço Central",
    "stateId": 5238
  },
  {
    "cityId": 936,
    "cityName": "Poço de Fora",
    "stateId": 5238
  },
  {
    "cityId": 937,
    "cityName": "Poções",
    "stateId": 5238
  },
  {
    "cityId": 938,
    "cityName": "Poços",
    "stateId": 5238
  },
  {
    "cityId": 939,
    "cityName": "Pojuca",
    "stateId": 5238
  },
  {
    "cityId": 940,
    "cityName": "Polo Petroquímico de Camaçari",
    "stateId": 5238
  },
  {
    "cityId": 941,
    "cityName": "Ponta da Areia",
    "stateId": 5238
  },
  {
    "cityId": 942,
    "cityName": "Ponto Novo",
    "stateId": 5238
  },
  {
    "cityId": 943,
    "cityName": "Porto Novo",
    "stateId": 5238
  },
  {
    "cityId": 944,
    "cityName": "Porto Seguro",
    "stateId": 5238
  },
  {
    "cityId": 945,
    "cityName": "Posto da Mata",
    "stateId": 5238
  },
  {
    "cityId": 946,
    "cityName": "Potiraguá",
    "stateId": 5238
  },
  {
    "cityId": 947,
    "cityName": "Poxim do Sul",
    "stateId": 5238
  },
  {
    "cityId": 948,
    "cityName": "Prado",
    "stateId": 5238
  },
  {
    "cityId": 949,
    "cityName": "Presidente Dutra",
    "stateId": 5238
  },
  {
    "cityId": 950,
    "cityName": "Presidente Jânio Quadros",
    "stateId": 5238
  },
  {
    "cityId": 951,
    "cityName": "Presidente Tancredo Neves",
    "stateId": 5238
  },
  {
    "cityId": 952,
    "cityName": "Prevenido",
    "stateId": 5238
  },
  {
    "cityId": 953,
    "cityName": "Quaraçu",
    "stateId": 5238
  },
  {
    "cityId": 954,
    "cityName": "Queimadas",
    "stateId": 5238
  },
  {
    "cityId": 955,
    "cityName": "Quijingue",
    "stateId": 5238
  },
  {
    "cityId": 956,
    "cityName": "Quixabá",
    "stateId": 5238
  },
  {
    "cityId": 957,
    "cityName": "Quixabeira",
    "stateId": 5238
  },
  {
    "cityId": 958,
    "cityName": "Rafael Jambeiro",
    "stateId": 5238
  },
  {
    "cityId": 959,
    "cityName": "Recife",
    "stateId": 5238
  },
  {
    "cityId": 960,
    "cityName": "Remanso",
    "stateId": 5238
  },
  {
    "cityId": 961,
    "cityName": "Remédios",
    "stateId": 5238
  },
  {
    "cityId": 962,
    "cityName": "Retirolândia",
    "stateId": 5238
  },
  {
    "cityId": 963,
    "cityName": "Riachão das Neves",
    "stateId": 5238
  },
  {
    "cityId": 964,
    "cityName": "Riachão do Jacuípe",
    "stateId": 5238
  },
  {
    "cityId": 965,
    "cityName": "Riachão do Utinga",
    "stateId": 5238
  },
  {
    "cityId": 966,
    "cityName": "Riacho da Guia",
    "stateId": 5238
  },
  {
    "cityId": 967,
    "cityName": "Riacho de Santana",
    "stateId": 5238
  },
  {
    "cityId": 968,
    "cityName": "Riacho Seco",
    "stateId": 5238
  },
  {
    "cityId": 969,
    "cityName": "Ribeira do Amparo",
    "stateId": 5238
  },
  {
    "cityId": 970,
    "cityName": "Ribeira do Pombal",
    "stateId": 5238
  },
  {
    "cityId": 971,
    "cityName": "Ribeirão do Largo",
    "stateId": 5238
  },
  {
    "cityId": 972,
    "cityName": "Ribeirão do Salto",
    "stateId": 5238
  },
  {
    "cityId": 973,
    "cityName": "Rio da Dona",
    "stateId": 5238
  },
  {
    "cityId": 974,
    "cityName": "Rio de Contas",
    "stateId": 5238
  },
  {
    "cityId": 975,
    "cityName": "Rio do Antônio",
    "stateId": 5238
  },
  {
    "cityId": 976,
    "cityName": "Rio do Braço",
    "stateId": 5238
  },
  {
    "cityId": 977,
    "cityName": "Rio do Meio",
    "stateId": 5238
  },
  {
    "cityId": 978,
    "cityName": "Rio do Pires",
    "stateId": 5238
  },
  {
    "cityId": 979,
    "cityName": "Rio Fundo",
    "stateId": 5238
  },
  {
    "cityId": 980,
    "cityName": "Rio Real",
    "stateId": 5238
  },
  {
    "cityId": 981,
    "cityName": "Rodelas",
    "stateId": 5238
  },
  {
    "cityId": 982,
    "cityName": "Ruy Barbosa",
    "stateId": 5238
  },
  {
    "cityId": 983,
    "cityName": "Saldanha",
    "stateId": 5238
  },
  {
    "cityId": 984,
    "cityName": "Salgadália",
    "stateId": 5238
  },
  {
    "cityId": 985,
    "cityName": "Salinas da Margarida",
    "stateId": 5238
  },
  {
    "cityId": 986,
    "cityName": "Salobrinho",
    "stateId": 5238
  },
  {
    "cityId": 987,
    "cityName": "Salobro",
    "stateId": 5238
  },
  {
    "cityId": 988,
    "cityName": "Salvador",
    "stateId": 5238
  },
  {
    "cityId": 989,
    "cityName": "Sambaíba",
    "stateId": 5238
  },
  {
    "cityId": 990,
    "cityName": "Santa Bárbara",
    "stateId": 5238
  },
  {
    "cityId": 991,
    "cityName": "Santa Brígida",
    "stateId": 5238
  },
  {
    "cityId": 992,
    "cityName": "Santa Cruz Cabrália",
    "stateId": 5238
  },
  {
    "cityId": 993,
    "cityName": "Santa Cruz da Vitória",
    "stateId": 5238
  },
  {
    "cityId": 994,
    "cityName": "Santa Inês",
    "stateId": 5238
  },
  {
    "cityId": 995,
    "cityName": "Santa Luzia",
    "stateId": 5238
  },
  {
    "cityId": 996,
    "cityName": "Santa Maria da Vitória",
    "stateId": 5238
  },
  {
    "cityId": 997,
    "cityName": "Santa Rita de Cássia",
    "stateId": 5238
  },
  {
    "cityId": 998,
    "cityName": "Santa Terezinha",
    "stateId": 5238
  },
  {
    "cityId": 999,
    "cityName": "Santaluz",
    "stateId": 5238
  },
  {
    "cityId": 1000,
    "cityName": "Santana",
    "stateId": 5238
  },
  {
    "cityId": 1001,
    "cityName": "Santana do Sobrado",
    "stateId": 5238
  },
  {
    "cityId": 1002,
    "cityName": "Santanópolis",
    "stateId": 5238
  },
  {
    "cityId": 1003,
    "cityName": "Santiago do Iguapé",
    "stateId": 5238
  },
  {
    "cityId": 1004,
    "cityName": "Santo Amaro",
    "stateId": 5238
  },
  {
    "cityId": 1005,
    "cityName": "Santo Antônio de Barcelona",
    "stateId": 5238
  },
  {
    "cityId": 1006,
    "cityName": "Santo Antônio de Jesus",
    "stateId": 5238
  },
  {
    "cityId": 1007,
    "cityName": "Santo Estevão",
    "stateId": 5238
  },
  {
    "cityId": 1008,
    "cityName": "Santo Inácio",
    "stateId": 5238
  },
  {
    "cityId": 1009,
    "cityName": "São Desidério",
    "stateId": 5238
  },
  {
    "cityId": 1010,
    "cityName": "São Domingos",
    "stateId": 5238
  },
  {
    "cityId": 1011,
    "cityName": "São Felipe",
    "stateId": 5238
  },
  {
    "cityId": 1012,
    "cityName": "São Félix",
    "stateId": 5238
  },
  {
    "cityId": 1013,
    "cityName": "São Félix do Coribe",
    "stateId": 5238
  },
  {
    "cityId": 1014,
    "cityName": "São Francisco do Conde",
    "stateId": 5238
  },
  {
    "cityId": 1015,
    "cityName": "São Gabriel",
    "stateId": 5238
  },
  {
    "cityId": 1016,
    "cityName": "São Gonçalo dos Campos",
    "stateId": 5238
  },
  {
    "cityId": 1017,
    "cityName": "São João da Fortaleza",
    "stateId": 5238
  },
  {
    "cityId": 1018,
    "cityName": "São João da Vitória",
    "stateId": 5238
  },
  {
    "cityId": 1019,
    "cityName": "São José da Vitória",
    "stateId": 5238
  },
  {
    "cityId": 1020,
    "cityName": "São José do Colônia",
    "stateId": 5238
  },
  {
    "cityId": 1021,
    "cityName": "São José do Jacuípe",
    "stateId": 5238
  },
  {
    "cityId": 1022,
    "cityName": "São José do Prado",
    "stateId": 5238
  },
  {
    "cityId": 1023,
    "cityName": "São José do Rio Grande",
    "stateId": 5238
  },
  {
    "cityId": 1024,
    "cityName": "São Miguel das Matas",
    "stateId": 5238
  },
  {
    "cityId": 1025,
    "cityName": "São Paulinho",
    "stateId": 5238
  },
  {
    "cityId": 1026,
    "cityName": "São Roque do Paraguaçu",
    "stateId": 5238
  },
  {
    "cityId": 1027,
    "cityName": "São Sebastião do Passe",
    "stateId": 5238
  },
  {
    "cityId": 1028,
    "cityName": "São Timóteo",
    "stateId": 5238
  },
  {
    "cityId": 1029,
    "cityName": "Sapeaçu",
    "stateId": 5238
  },
  {
    "cityId": 1030,
    "cityName": "Sátiro Dias",
    "stateId": 5238
  },
  {
    "cityId": 1031,
    "cityName": "Saubara",
    "stateId": 5238
  },
  {
    "cityId": 1032,
    "cityName": "Saudável",
    "stateId": 5238
  },
  {
    "cityId": 1033,
    "cityName": "Saúde",
    "stateId": 5238
  },
  {
    "cityId": 1034,
    "cityName": "Seabra",
    "stateId": 5238
  },
  {
    "cityId": 1035,
    "cityName": "Sebastião Laranjeiras",
    "stateId": 5238
  },
  {
    "cityId": 1036,
    "cityName": "Senhor do Bonfim",
    "stateId": 5238
  },
  {
    "cityId": 1037,
    "cityName": "Sento Sé",
    "stateId": 5238
  },
  {
    "cityId": 1038,
    "cityName": "Sergi",
    "stateId": 5238
  },
  {
    "cityId": 1039,
    "cityName": "Serra da Canabrava",
    "stateId": 5238
  },
  {
    "cityId": 1040,
    "cityName": "Serra do Ramalho",
    "stateId": 5238
  },
  {
    "cityId": 1041,
    "cityName": "Serra Dourada",
    "stateId": 5238
  },
  {
    "cityId": 1042,
    "cityName": "Serra Grande",
    "stateId": 5238
  },
  {
    "cityId": 1043,
    "cityName": "Serra Grande",
    "stateId": 5238
  },
  {
    "cityId": 1044,
    "cityName": "Serra Preta",
    "stateId": 5238
  },
  {
    "cityId": 1045,
    "cityName": "Serrinha",
    "stateId": 5238
  },
  {
    "cityId": 1046,
    "cityName": "Serrolândia",
    "stateId": 5238
  },
  {
    "cityId": 1047,
    "cityName": "Simões Filho",
    "stateId": 5238
  },
  {
    "cityId": 1048,
    "cityName": "Sítio da Baraúna",
    "stateId": 5238
  },
  {
    "cityId": 1049,
    "cityName": "Sítio do Mato",
    "stateId": 5238
  },
  {
    "cityId": 1050,
    "cityName": "Sítio do Meio",
    "stateId": 5238
  },
  {
    "cityId": 1051,
    "cityName": "Sítio do Quinto",
    "stateId": 5238
  },
  {
    "cityId": 1052,
    "cityName": "Sítio Grande",
    "stateId": 5238
  },
  {
    "cityId": 1053,
    "cityName": "Sítio Novo",
    "stateId": 5238
  },
  {
    "cityId": 1054,
    "cityName": "Soares",
    "stateId": 5238
  },
  {
    "cityId": 1055,
    "cityName": "Sobradinho",
    "stateId": 5238
  },
  {
    "cityId": 1056,
    "cityName": "Souto Soares",
    "stateId": 5238
  },
  {
    "cityId": 1057,
    "cityName": "Subaúma",
    "stateId": 5238
  },
  {
    "cityId": 1058,
    "cityName": "Sussuarana",
    "stateId": 5238
  },
  {
    "cityId": 1059,
    "cityName": "Tabocas do Brejo Velho",
    "stateId": 5238
  },
  {
    "cityId": 1060,
    "cityName": "Taboleiro do Castro",
    "stateId": 5238
  },
  {
    "cityId": 1061,
    "cityName": "Taboquinhas",
    "stateId": 5238
  },
  {
    "cityId": 1062,
    "cityName": "Taguá",
    "stateId": 5238
  },
  {
    "cityId": 1063,
    "cityName": "Tamburil",
    "stateId": 5238
  },
  {
    "cityId": 1064,
    "cityName": "Tanhaçu",
    "stateId": 5238
  },
  {
    "cityId": 1065,
    "cityName": "Tanque Novo",
    "stateId": 5238
  },
  {
    "cityId": 1066,
    "cityName": "Tanquinho",
    "stateId": 5238
  },
  {
    "cityId": 1067,
    "cityName": "Tanquinho do Poço",
    "stateId": 5238
  },
  {
    "cityId": 1068,
    "cityName": "Taperoá",
    "stateId": 5238
  },
  {
    "cityId": 1069,
    "cityName": "Tapiraípe",
    "stateId": 5238
  },
  {
    "cityId": 1070,
    "cityName": "Tapirama",
    "stateId": 5238
  },
  {
    "cityId": 1071,
    "cityName": "Tapiramutá",
    "stateId": 5238
  },
  {
    "cityId": 1072,
    "cityName": "Tapiranga",
    "stateId": 5238
  },
  {
    "cityId": 1073,
    "cityName": "Tapúia",
    "stateId": 5238
  },
  {
    "cityId": 1074,
    "cityName": "Taquarendi",
    "stateId": 5238
  },
  {
    "cityId": 1075,
    "cityName": "Taquarinha",
    "stateId": 5238
  },
  {
    "cityId": 1076,
    "cityName": "Tartaruga",
    "stateId": 5238
  },
  {
    "cityId": 1077,
    "cityName": "Tauapé",
    "stateId": 5238
  },
  {
    "cityId": 1078,
    "cityName": "Teixeira de Freitas",
    "stateId": 5238
  },
  {
    "cityId": 1079,
    "cityName": "Teodoro Sampaio",
    "stateId": 5238
  },
  {
    "cityId": 1080,
    "cityName": "Teofilândia",
    "stateId": 5238
  },
  {
    "cityId": 1081,
    "cityName": "Teolândia",
    "stateId": 5238
  },
  {
    "cityId": 1082,
    "cityName": "Terra Nova",
    "stateId": 5238
  },
  {
    "cityId": 1083,
    "cityName": "Tijuaçú",
    "stateId": 5238
  },
  {
    "cityId": 1084,
    "cityName": "Tiquaruçu",
    "stateId": 5238
  },
  {
    "cityId": 1085,
    "cityName": "Tremedal",
    "stateId": 5238
  },
  {
    "cityId": 1086,
    "cityName": "Triunfo do Sincorá",
    "stateId": 5238
  },
  {
    "cityId": 1087,
    "cityName": "Tucano",
    "stateId": 5238
  },
  {
    "cityId": 1088,
    "cityName": "Uauá",
    "stateId": 5238
  },
  {
    "cityId": 1089,
    "cityName": "Ubaíra",
    "stateId": 5238
  },
  {
    "cityId": 1090,
    "cityName": "Ubaitaba",
    "stateId": 5238
  },
  {
    "cityId": 1091,
    "cityName": "Ubatã",
    "stateId": 5238
  },
  {
    "cityId": 1092,
    "cityName": "Ubiracaba",
    "stateId": 5238
  },
  {
    "cityId": 1093,
    "cityName": "Ubiraitá",
    "stateId": 5238
  },
  {
    "cityId": 1094,
    "cityName": "Uibaí",
    "stateId": 5238
  },
  {
    "cityId": 1095,
    "cityName": "Umburanas",
    "stateId": 5238
  },
  {
    "cityId": 1096,
    "cityName": "Umbuzeiro",
    "stateId": 5238
  },
  {
    "cityId": 1097,
    "cityName": "Una",
    "stateId": 5238
  },
  {
    "cityId": 1098,
    "cityName": "Urandi",
    "stateId": 5238
  },
  {
    "cityId": 1099,
    "cityName": "Uruçuca",
    "stateId": 5238
  },
  {
    "cityId": 1100,
    "cityName": "Utinga",
    "stateId": 5238
  },
  {
    "cityId": 1101,
    "cityName": "Vale Verde",
    "stateId": 5238
  },
  {
    "cityId": 1102,
    "cityName": "Valença",
    "stateId": 5238
  },
  {
    "cityId": 1103,
    "cityName": "Valente",
    "stateId": 5238
  },
  {
    "cityId": 1104,
    "cityName": "Várzea da Roça",
    "stateId": 5238
  },
  {
    "cityId": 1105,
    "cityName": "Várzea do Caldas",
    "stateId": 5238
  },
  {
    "cityId": 1106,
    "cityName": "Várzea do Cerco",
    "stateId": 5238
  },
  {
    "cityId": 1107,
    "cityName": "Várzea do Poço",
    "stateId": 5238
  },
  {
    "cityId": 1108,
    "cityName": "Várzea Nova",
    "stateId": 5238
  },
  {
    "cityId": 1109,
    "cityName": "Várzeas",
    "stateId": 5238
  },
  {
    "cityId": 1110,
    "cityName": "Varzedo",
    "stateId": 5238
  },
  {
    "cityId": 1111,
    "cityName": "Velha Boipeba",
    "stateId": 5238
  },
  {
    "cityId": 1112,
    "cityName": "Ventura",
    "stateId": 5238
  },
  {
    "cityId": 1113,
    "cityName": "Vera Cruz",
    "stateId": 5238
  },
  {
    "cityId": 1114,
    "cityName": "Vereda",
    "stateId": 5238
  },
  {
    "cityId": 1115,
    "cityName": "Vila do Café",
    "stateId": 5238
  },
  {
    "cityId": 1116,
    "cityName": "Vitória da Conquista",
    "stateId": 5238
  },
  {
    "cityId": 1117,
    "cityName": "Volta Grande",
    "stateId": 5238
  },
  {
    "cityId": 1118,
    "cityName": "Wagner",
    "stateId": 5238
  },
  {
    "cityId": 1119,
    "cityName": "Wanderley",
    "stateId": 5238
  },
  {
    "cityId": 1120,
    "cityName": "Wenceslau Guimarães",
    "stateId": 5238
  },
  {
    "cityId": 1121,
    "cityName": "Xique-Xique",
    "stateId": 5238
  },
  {
    "cityId": 1122,
    "cityName": "Abaiara",
    "stateId": 5239
  },
  {
    "cityId": 1123,
    "cityName": "Abílio Martins",
    "stateId": 5239
  },
  {
    "cityId": 1124,
    "cityName": "Acarape",
    "stateId": 5239
  },
  {
    "cityId": 1125,
    "cityName": "Acaraú",
    "stateId": 5239
  },
  {
    "cityId": 1126,
    "cityName": "Acopiara",
    "stateId": 5239
  },
  {
    "cityId": 1127,
    "cityName": "Adrianópolis",
    "stateId": 5239
  },
  {
    "cityId": 1128,
    "cityName": "Água Verde",
    "stateId": 5239
  },
  {
    "cityId": 1129,
    "cityName": "Aguaí",
    "stateId": 5239
  },
  {
    "cityId": 1130,
    "cityName": "Aiuá",
    "stateId": 5239
  },
  {
    "cityId": 1131,
    "cityName": "Aiuaba",
    "stateId": 5239
  },
  {
    "cityId": 1132,
    "cityName": "Alagoinha",
    "stateId": 5239
  },
  {
    "cityId": 1133,
    "cityName": "Alagoinha",
    "stateId": 5239
  },
  {
    "cityId": 1134,
    "cityName": "Alcântaras",
    "stateId": 5239
  },
  {
    "cityId": 1135,
    "cityName": "Algodões",
    "stateId": 5239
  },
  {
    "cityId": 1136,
    "cityName": "Almofala",
    "stateId": 5239
  },
  {
    "cityId": 1137,
    "cityName": "Altaneira",
    "stateId": 5239
  },
  {
    "cityId": 1138,
    "cityName": "Alto Santo",
    "stateId": 5239
  },
  {
    "cityId": 1139,
    "cityName": "Amanaiara",
    "stateId": 5239
  },
  {
    "cityId": 1140,
    "cityName": "Amanari",
    "stateId": 5239
  },
  {
    "cityId": 1141,
    "cityName": "Amaniutuba",
    "stateId": 5239
  },
  {
    "cityId": 1142,
    "cityName": "Amarelas",
    "stateId": 5239
  },
  {
    "cityId": 1143,
    "cityName": "Amaro",
    "stateId": 5239
  },
  {
    "cityId": 1144,
    "cityName": "América",
    "stateId": 5239
  },
  {
    "cityId": 1145,
    "cityName": "Amontada",
    "stateId": 5239
  },
  {
    "cityId": 1146,
    "cityName": "Anauá",
    "stateId": 5239
  },
  {
    "cityId": 1147,
    "cityName": "Aningás",
    "stateId": 5239
  },
  {
    "cityId": 1148,
    "cityName": "Anjinhos",
    "stateId": 5239
  },
  {
    "cityId": 1149,
    "cityName": "Antonina do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1150,
    "cityName": "Antônio Bezerra",
    "stateId": 5239
  },
  {
    "cityId": 1151,
    "cityName": "Antônio Diogo",
    "stateId": 5239
  },
  {
    "cityId": 1152,
    "cityName": "Antônio Marques",
    "stateId": 5239
  },
  {
    "cityId": 1153,
    "cityName": "Aprazível",
    "stateId": 5239
  },
  {
    "cityId": 1154,
    "cityName": "Apuiarés",
    "stateId": 5239
  },
  {
    "cityId": 1155,
    "cityName": "Aquinópolis",
    "stateId": 5239
  },
  {
    "cityId": 1156,
    "cityName": "Aquiraz",
    "stateId": 5239
  },
  {
    "cityId": 1157,
    "cityName": "Araçás",
    "stateId": 5239
  },
  {
    "cityId": 1158,
    "cityName": "Aracati",
    "stateId": 5239
  },
  {
    "cityId": 1159,
    "cityName": "Aracatiaçu",
    "stateId": 5239
  },
  {
    "cityId": 1160,
    "cityName": "Aracatiara",
    "stateId": 5239
  },
  {
    "cityId": 1161,
    "cityName": "Aracoiaba",
    "stateId": 5239
  },
  {
    "cityId": 1162,
    "cityName": "Arajara",
    "stateId": 5239
  },
  {
    "cityId": 1163,
    "cityName": "Aranaú",
    "stateId": 5239
  },
  {
    "cityId": 1164,
    "cityName": "Arapá",
    "stateId": 5239
  },
  {
    "cityId": 1165,
    "cityName": "Arapari",
    "stateId": 5239
  },
  {
    "cityId": 1166,
    "cityName": "Araporanga",
    "stateId": 5239
  },
  {
    "cityId": 1167,
    "cityName": "Araquém",
    "stateId": 5239
  },
  {
    "cityId": 1168,
    "cityName": "Ararendá",
    "stateId": 5239
  },
  {
    "cityId": 1169,
    "cityName": "Araripe",
    "stateId": 5239
  },
  {
    "cityId": 1170,
    "cityName": "Arariús",
    "stateId": 5239
  },
  {
    "cityId": 1171,
    "cityName": "Aratama",
    "stateId": 5239
  },
  {
    "cityId": 1172,
    "cityName": "Araticum",
    "stateId": 5239
  },
  {
    "cityId": 1173,
    "cityName": "Aratuba",
    "stateId": 5239
  },
  {
    "cityId": 1174,
    "cityName": "Areial",
    "stateId": 5239
  },
  {
    "cityId": 1175,
    "cityName": "Ariscos dos Marianos",
    "stateId": 5239
  },
  {
    "cityId": 1176,
    "cityName": "Arneiroz",
    "stateId": 5239
  },
  {
    "cityId": 1177,
    "cityName": "Aroeiras",
    "stateId": 5239
  },
  {
    "cityId": 1178,
    "cityName": "Arrojado",
    "stateId": 5239
  },
  {
    "cityId": 1179,
    "cityName": "Aruaru",
    "stateId": 5239
  },
  {
    "cityId": 1180,
    "cityName": "Assaré",
    "stateId": 5239
  },
  {
    "cityId": 1181,
    "cityName": "Assunção",
    "stateId": 5239
  },
  {
    "cityId": 1182,
    "cityName": "Assunção",
    "stateId": 5239
  },
  {
    "cityId": 1183,
    "cityName": "Aurora",
    "stateId": 5239
  },
  {
    "cityId": 1184,
    "cityName": "Baixa Grande",
    "stateId": 5239
  },
  {
    "cityId": 1185,
    "cityName": "Baixio",
    "stateId": 5239
  },
  {
    "cityId": 1186,
    "cityName": "Baixio da Donana",
    "stateId": 5239
  },
  {
    "cityId": 1187,
    "cityName": "Banabuiú",
    "stateId": 5239
  },
  {
    "cityId": 1188,
    "cityName": "Bandeira",
    "stateId": 5239
  },
  {
    "cityId": 1189,
    "cityName": "Barão de Aquiraz",
    "stateId": 5239
  },
  {
    "cityId": 1190,
    "cityName": "Barbalha",
    "stateId": 5239
  },
  {
    "cityId": 1191,
    "cityName": "Barra",
    "stateId": 5239
  },
  {
    "cityId": 1192,
    "cityName": "Barra",
    "stateId": 5239
  },
  {
    "cityId": 1193,
    "cityName": "Barra",
    "stateId": 5239
  },
  {
    "cityId": 1194,
    "cityName": "Barra do Sotero",
    "stateId": 5239
  },
  {
    "cityId": 1195,
    "cityName": "Barra Nova",
    "stateId": 5239
  },
  {
    "cityId": 1196,
    "cityName": "Barreira",
    "stateId": 5239
  },
  {
    "cityId": 1197,
    "cityName": "Barreira dos Vianas",
    "stateId": 5239
  },
  {
    "cityId": 1198,
    "cityName": "Barreiras",
    "stateId": 5239
  },
  {
    "cityId": 1199,
    "cityName": "Barreiros",
    "stateId": 5239
  },
  {
    "cityId": 1200,
    "cityName": "Barreiros",
    "stateId": 5239
  },
  {
    "cityId": 1201,
    "cityName": "Barreiros",
    "stateId": 5239
  },
  {
    "cityId": 1202,
    "cityName": "Barrento",
    "stateId": 5239
  },
  {
    "cityId": 1203,
    "cityName": "Barro",
    "stateId": 5239
  },
  {
    "cityId": 1204,
    "cityName": "Barro Alto",
    "stateId": 5239
  },
  {
    "cityId": 1205,
    "cityName": "Barroquinha",
    "stateId": 5239
  },
  {
    "cityId": 1206,
    "cityName": "Baturité",
    "stateId": 5239
  },
  {
    "cityId": 1207,
    "cityName": "Baú",
    "stateId": 5239
  },
  {
    "cityId": 1208,
    "cityName": "Beberibe",
    "stateId": 5239
  },
  {
    "cityId": 1209,
    "cityName": "Bela Cruz",
    "stateId": 5239
  },
  {
    "cityId": 1210,
    "cityName": "Bela Vista",
    "stateId": 5239
  },
  {
    "cityId": 1211,
    "cityName": "Betânia",
    "stateId": 5239
  },
  {
    "cityId": 1212,
    "cityName": "Betânia",
    "stateId": 5239
  },
  {
    "cityId": 1213,
    "cityName": "Betânia",
    "stateId": 5239
  },
  {
    "cityId": 1214,
    "cityName": "Bitupitá",
    "stateId": 5239
  },
  {
    "cityId": 1215,
    "cityName": "Bixopa",
    "stateId": 5239
  },
  {
    "cityId": 1216,
    "cityName": "Boa Água",
    "stateId": 5239
  },
  {
    "cityId": 1217,
    "cityName": "Boa Esperança",
    "stateId": 5239
  },
  {
    "cityId": 1218,
    "cityName": "Boa Viagem",
    "stateId": 5239
  },
  {
    "cityId": 1219,
    "cityName": "Boa Vista",
    "stateId": 5239
  },
  {
    "cityId": 1220,
    "cityName": "Boa Vista",
    "stateId": 5239
  },
  {
    "cityId": 1221,
    "cityName": "Boa Vista do Caxitoré",
    "stateId": 5239
  },
  {
    "cityId": 1222,
    "cityName": "Bonfim",
    "stateId": 5239
  },
  {
    "cityId": 1223,
    "cityName": "Bonfim",
    "stateId": 5239
  },
  {
    "cityId": 1224,
    "cityName": "Bonhu",
    "stateId": 5239
  },
  {
    "cityId": 1225,
    "cityName": "Bonito",
    "stateId": 5239
  },
  {
    "cityId": 1226,
    "cityName": "Borges",
    "stateId": 5239
  },
  {
    "cityId": 1227,
    "cityName": "Brejinho",
    "stateId": 5239
  },
  {
    "cityId": 1228,
    "cityName": "Brejo Grande",
    "stateId": 5239
  },
  {
    "cityId": 1229,
    "cityName": "Brejo Santo",
    "stateId": 5239
  },
  {
    "cityId": 1230,
    "cityName": "Brotas",
    "stateId": 5239
  },
  {
    "cityId": 1231,
    "cityName": "Buritizal",
    "stateId": 5239
  },
  {
    "cityId": 1232,
    "cityName": "Buritizinho",
    "stateId": 5239
  },
  {
    "cityId": 1233,
    "cityName": "Cabreiro",
    "stateId": 5239
  },
  {
    "cityId": 1234,
    "cityName": "Cachoeira",
    "stateId": 5239
  },
  {
    "cityId": 1235,
    "cityName": "Cachoeira Grande",
    "stateId": 5239
  },
  {
    "cityId": 1236,
    "cityName": "Caiçara",
    "stateId": 5239
  },
  {
    "cityId": 1237,
    "cityName": "Caiçarinha",
    "stateId": 5239
  },
  {
    "cityId": 1238,
    "cityName": "Caio Prado",
    "stateId": 5239
  },
  {
    "cityId": 1239,
    "cityName": "Caioca",
    "stateId": 5239
  },
  {
    "cityId": 1240,
    "cityName": "Caipu",
    "stateId": 5239
  },
  {
    "cityId": 1241,
    "cityName": "Calabaça",
    "stateId": 5239
  },
  {
    "cityId": 1242,
    "cityName": "Caldeirão",
    "stateId": 5239
  },
  {
    "cityId": 1243,
    "cityName": "Califórnia",
    "stateId": 5239
  },
  {
    "cityId": 1244,
    "cityName": "Camará",
    "stateId": 5239
  },
  {
    "cityId": 1245,
    "cityName": "Camboas",
    "stateId": 5239
  },
  {
    "cityId": 1246,
    "cityName": "Camilos",
    "stateId": 5239
  },
  {
    "cityId": 1247,
    "cityName": "Camocim",
    "stateId": 5239
  },
  {
    "cityId": 1248,
    "cityName": "Campanário",
    "stateId": 5239
  },
  {
    "cityId": 1249,
    "cityName": "Campestre",
    "stateId": 5239
  },
  {
    "cityId": 1250,
    "cityName": "Campos Sales",
    "stateId": 5239
  },
  {
    "cityId": 1251,
    "cityName": "Canaan",
    "stateId": 5239
  },
  {
    "cityId": 1252,
    "cityName": "Canafistula",
    "stateId": 5239
  },
  {
    "cityId": 1253,
    "cityName": "Canafistula",
    "stateId": 5239
  },
  {
    "cityId": 1254,
    "cityName": "Cangati",
    "stateId": 5239
  },
  {
    "cityId": 1255,
    "cityName": "Cangati",
    "stateId": 5239
  },
  {
    "cityId": 1256,
    "cityName": "Canindé",
    "stateId": 5239
  },
  {
    "cityId": 1257,
    "cityName": "Canindezinho",
    "stateId": 5239
  },
  {
    "cityId": 1258,
    "cityName": "Canindezinho",
    "stateId": 5239
  },
  {
    "cityId": 1259,
    "cityName": "Canindezinho",
    "stateId": 5239
  },
  {
    "cityId": 1260,
    "cityName": "Capistrano",
    "stateId": 5239
  },
  {
    "cityId": 1261,
    "cityName": "Caponga",
    "stateId": 5239
  },
  {
    "cityId": 1262,
    "cityName": "Caponga da Bernarda",
    "stateId": 5239
  },
  {
    "cityId": 1263,
    "cityName": "Caracará",
    "stateId": 5239
  },
  {
    "cityId": 1264,
    "cityName": "Caridade",
    "stateId": 5239
  },
  {
    "cityId": 1265,
    "cityName": "Cariré",
    "stateId": 5239
  },
  {
    "cityId": 1266,
    "cityName": "Caririaçu",
    "stateId": 5239
  },
  {
    "cityId": 1267,
    "cityName": "Cariús",
    "stateId": 5239
  },
  {
    "cityId": 1268,
    "cityName": "Cariutaba",
    "stateId": 5239
  },
  {
    "cityId": 1269,
    "cityName": "Carmelópolis",
    "stateId": 5239
  },
  {
    "cityId": 1270,
    "cityName": "Carnaubal",
    "stateId": 5239
  },
  {
    "cityId": 1271,
    "cityName": "Carnaúbas",
    "stateId": 5239
  },
  {
    "cityId": 1272,
    "cityName": "Carnaubinha",
    "stateId": 5239
  },
  {
    "cityId": 1273,
    "cityName": "Carquejo",
    "stateId": 5239
  },
  {
    "cityId": 1274,
    "cityName": "Carrapateiras",
    "stateId": 5239
  },
  {
    "cityId": 1275,
    "cityName": "Caruataí",
    "stateId": 5239
  },
  {
    "cityId": 1276,
    "cityName": "Carvalho",
    "stateId": 5239
  },
  {
    "cityId": 1277,
    "cityName": "Carvoeiro",
    "stateId": 5239
  },
  {
    "cityId": 1278,
    "cityName": "Cascavel",
    "stateId": 5239
  },
  {
    "cityId": 1279,
    "cityName": "Castanhão",
    "stateId": 5239
  },
  {
    "cityId": 1280,
    "cityName": "Catarina",
    "stateId": 5239
  },
  {
    "cityId": 1281,
    "cityName": "Catolé",
    "stateId": 5239
  },
  {
    "cityId": 1282,
    "cityName": "Catuana",
    "stateId": 5239
  },
  {
    "cityId": 1283,
    "cityName": "Catunda",
    "stateId": 5239
  },
  {
    "cityId": 1284,
    "cityName": "Caucaia",
    "stateId": 5239
  },
  {
    "cityId": 1285,
    "cityName": "Caxitoré",
    "stateId": 5239
  },
  {
    "cityId": 1286,
    "cityName": "Caxitoré",
    "stateId": 5239
  },
  {
    "cityId": 1287,
    "cityName": "Cedro",
    "stateId": 5239
  },
  {
    "cityId": 1288,
    "cityName": "Cemoaba",
    "stateId": 5239
  },
  {
    "cityId": 1289,
    "cityName": "Chaval",
    "stateId": 5239
  },
  {
    "cityId": 1290,
    "cityName": "Choró",
    "stateId": 5239
  },
  {
    "cityId": 1291,
    "cityName": "Chorozinho",
    "stateId": 5239
  },
  {
    "cityId": 1292,
    "cityName": "Cipó dos Anjos",
    "stateId": 5239
  },
  {
    "cityId": 1293,
    "cityName": "Cococi",
    "stateId": 5239
  },
  {
    "cityId": 1294,
    "cityName": "Codiá",
    "stateId": 5239
  },
  {
    "cityId": 1295,
    "cityName": "Coité",
    "stateId": 5239
  },
  {
    "cityId": 1296,
    "cityName": "Colina",
    "stateId": 5239
  },
  {
    "cityId": 1297,
    "cityName": "Conceição",
    "stateId": 5239
  },
  {
    "cityId": 1298,
    "cityName": "Coreaú",
    "stateId": 5239
  },
  {
    "cityId": 1299,
    "cityName": "Córrego dos Fernandes",
    "stateId": 5239
  },
  {
    "cityId": 1300,
    "cityName": "Crateús",
    "stateId": 5239
  },
  {
    "cityId": 1301,
    "cityName": "Crato",
    "stateId": 5239
  },
  {
    "cityId": 1302,
    "cityName": "Crioulos",
    "stateId": 5239
  },
  {
    "cityId": 1303,
    "cityName": "Cristais",
    "stateId": 5239
  },
  {
    "cityId": 1304,
    "cityName": "Croatá",
    "stateId": 5239
  },
  {
    "cityId": 1305,
    "cityName": "Croatá",
    "stateId": 5239
  },
  {
    "cityId": 1306,
    "cityName": "Croatá",
    "stateId": 5239
  },
  {
    "cityId": 1307,
    "cityName": "Cruxati",
    "stateId": 5239
  },
  {
    "cityId": 1308,
    "cityName": "Cruz",
    "stateId": 5239
  },
  {
    "cityId": 1309,
    "cityName": "Cruz",
    "stateId": 5239
  },
  {
    "cityId": 1310,
    "cityName": "Cruz de Pedra",
    "stateId": 5239
  },
  {
    "cityId": 1311,
    "cityName": "Cruzeirinho",
    "stateId": 5239
  },
  {
    "cityId": 1312,
    "cityName": "Cuncas",
    "stateId": 5239
  },
  {
    "cityId": 1313,
    "cityName": "Curatis",
    "stateId": 5239
  },
  {
    "cityId": 1314,
    "cityName": "Curupira",
    "stateId": 5239
  },
  {
    "cityId": 1315,
    "cityName": "Custódio",
    "stateId": 5239
  },
  {
    "cityId": 1316,
    "cityName": "Daniel de Queirós",
    "stateId": 5239
  },
  {
    "cityId": 1317,
    "cityName": "Delmiro Gouveia",
    "stateId": 5239
  },
  {
    "cityId": 1318,
    "cityName": "Deputado Irapuan Pinheiro",
    "stateId": 5239
  },
  {
    "cityId": 1319,
    "cityName": "Deserto",
    "stateId": 5239
  },
  {
    "cityId": 1320,
    "cityName": "Dom Leme",
    "stateId": 5239
  },
  {
    "cityId": 1321,
    "cityName": "Dom Maurício",
    "stateId": 5239
  },
  {
    "cityId": 1322,
    "cityName": "Dom Quintino",
    "stateId": 5239
  },
  {
    "cityId": 1323,
    "cityName": "Domingos da Costa",
    "stateId": 5239
  },
  {
    "cityId": 1324,
    "cityName": "Donato",
    "stateId": 5239
  },
  {
    "cityId": 1325,
    "cityName": "Dourado",
    "stateId": 5239
  },
  {
    "cityId": 1326,
    "cityName": "Ebron",
    "stateId": 5239
  },
  {
    "cityId": 1327,
    "cityName": "Ema",
    "stateId": 5239
  },
  {
    "cityId": 1328,
    "cityName": "Ematuba",
    "stateId": 5239
  },
  {
    "cityId": 1329,
    "cityName": "Encantado",
    "stateId": 5239
  },
  {
    "cityId": 1330,
    "cityName": "Engenheiro João Tomé",
    "stateId": 5239
  },
  {
    "cityId": 1331,
    "cityName": "Engenheiro José Lopes",
    "stateId": 5239
  },
  {
    "cityId": 1332,
    "cityName": "Engenho Velho",
    "stateId": 5239
  },
  {
    "cityId": 1333,
    "cityName": "Ererê",
    "stateId": 5239
  },
  {
    "cityId": 1334,
    "cityName": "Espacinha",
    "stateId": 5239
  },
  {
    "cityId": 1335,
    "cityName": "Esperança",
    "stateId": 5239
  },
  {
    "cityId": 1336,
    "cityName": "Várzea dos Espinhos",
    "stateId": 5239
  },
  {
    "cityId": 1337,
    "cityName": "Eusébio",
    "stateId": 5239
  },
  {
    "cityId": 1338,
    "cityName": "Farias Brito",
    "stateId": 5239
  },
  {
    "cityId": 1339,
    "cityName": "Fátima",
    "stateId": 5239
  },
  {
    "cityId": 1340,
    "cityName": "Feiticeiro",
    "stateId": 5239
  },
  {
    "cityId": 1341,
    "cityName": "Feitosa",
    "stateId": 5239
  },
  {
    "cityId": 1342,
    "cityName": "Felizardo",
    "stateId": 5239
  },
  {
    "cityId": 1343,
    "cityName": "Flamengo",
    "stateId": 5239
  },
  {
    "cityId": 1344,
    "cityName": "Flores",
    "stateId": 5239
  },
  {
    "cityId": 1345,
    "cityName": "Flores",
    "stateId": 5239
  },
  {
    "cityId": 1346,
    "cityName": "Forquilha",
    "stateId": 5239
  },
  {
    "cityId": 1347,
    "cityName": "Fortaleza",
    "stateId": 5239
  },
  {
    "cityId": 1348,
    "cityName": "Fortim",
    "stateId": 5239
  },
  {
    "cityId": 1349,
    "cityName": "Frecheirinha",
    "stateId": 5239
  },
  {
    "cityId": 1350,
    "cityName": "Gado",
    "stateId": 5239
  },
  {
    "cityId": 1351,
    "cityName": "Gado dos Rodrigues",
    "stateId": 5239
  },
  {
    "cityId": 1352,
    "cityName": "Gameleira de São Sebastião",
    "stateId": 5239
  },
  {
    "cityId": 1353,
    "cityName": "Garças",
    "stateId": 5239
  },
  {
    "cityId": 1354,
    "cityName": "Gázea",
    "stateId": 5239
  },
  {
    "cityId": 1355,
    "cityName": "General Sampaio",
    "stateId": 5239
  },
  {
    "cityId": 1356,
    "cityName": "General Tibúrcio",
    "stateId": 5239
  },
  {
    "cityId": 1357,
    "cityName": "Jenipapeiro",
    "stateId": 5239
  },
  {
    "cityId": 1358,
    "cityName": "Gereraú",
    "stateId": 5239
  },
  {
    "cityId": 1359,
    "cityName": "Giqui",
    "stateId": 5239
  },
  {
    "cityId": 1360,
    "cityName": "Girau",
    "stateId": 5239
  },
  {
    "cityId": 1361,
    "cityName": "Graça",
    "stateId": 5239
  },
  {
    "cityId": 1362,
    "cityName": "Granja",
    "stateId": 5239
  },
  {
    "cityId": 1363,
    "cityName": "Granjeiro",
    "stateId": 5239
  },
  {
    "cityId": 1364,
    "cityName": "Groairas",
    "stateId": 5239
  },
  {
    "cityId": 1365,
    "cityName": "Guaiúba",
    "stateId": 5239
  },
  {
    "cityId": 1366,
    "cityName": "Guajiru",
    "stateId": 5239
  },
  {
    "cityId": 1367,
    "cityName": "Guanacés",
    "stateId": 5239
  },
  {
    "cityId": 1368,
    "cityName": "Guaraciaba do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1369,
    "cityName": "Guaramiranga",
    "stateId": 5239
  },
  {
    "cityId": 1370,
    "cityName": "Guararu",
    "stateId": 5239
  },
  {
    "cityId": 1371,
    "cityName": "Guassi",
    "stateId": 5239
  },
  {
    "cityId": 1372,
    "cityName": "Guassossé",
    "stateId": 5239
  },
  {
    "cityId": 1373,
    "cityName": "Guia",
    "stateId": 5239
  },
  {
    "cityId": 1374,
    "cityName": "Guriú",
    "stateId": 5239
  },
  {
    "cityId": 1375,
    "cityName": "Hidrolândia",
    "stateId": 5239
  },
  {
    "cityId": 1376,
    "cityName": "Holanda",
    "stateId": 5239
  },
  {
    "cityId": 1377,
    "cityName": "Horizonte",
    "stateId": 5239
  },
  {
    "cityId": 1378,
    "cityName": "Iapi",
    "stateId": 5239
  },
  {
    "cityId": 1379,
    "cityName": "Iara",
    "stateId": 5239
  },
  {
    "cityId": 1380,
    "cityName": "Ibaretama",
    "stateId": 5239
  },
  {
    "cityId": 1381,
    "cityName": "Ibiapaba",
    "stateId": 5239
  },
  {
    "cityId": 1382,
    "cityName": "Ibiapina",
    "stateId": 5239
  },
  {
    "cityId": 1383,
    "cityName": "Ibicatu",
    "stateId": 5239
  },
  {
    "cityId": 1384,
    "cityName": "Ibicuã",
    "stateId": 5239
  },
  {
    "cityId": 1385,
    "cityName": "Ibicuitaba",
    "stateId": 5239
  },
  {
    "cityId": 1386,
    "cityName": "Ibicuitinga",
    "stateId": 5239
  },
  {
    "cityId": 1387,
    "cityName": "Iborepi",
    "stateId": 5239
  },
  {
    "cityId": 1388,
    "cityName": "Ibuaçu",
    "stateId": 5239
  },
  {
    "cityId": 1389,
    "cityName": "Ibuguaçu",
    "stateId": 5239
  },
  {
    "cityId": 1390,
    "cityName": "Icapuí",
    "stateId": 5239
  },
  {
    "cityId": 1391,
    "cityName": "Icaraí",
    "stateId": 5239
  },
  {
    "cityId": 1392,
    "cityName": "Icó",
    "stateId": 5239
  },
  {
    "cityId": 1393,
    "cityName": "Icozinho",
    "stateId": 5239
  },
  {
    "cityId": 1394,
    "cityName": "Ideal",
    "stateId": 5239
  },
  {
    "cityId": 1395,
    "cityName": "Igaroi",
    "stateId": 5239
  },
  {
    "cityId": 1396,
    "cityName": "Iguatu",
    "stateId": 5239
  },
  {
    "cityId": 1397,
    "cityName": "Independência",
    "stateId": 5239
  },
  {
    "cityId": 1398,
    "cityName": "Ingazeiras",
    "stateId": 5239
  },
  {
    "cityId": 1399,
    "cityName": "Inhamuns",
    "stateId": 5239
  },
  {
    "cityId": 1400,
    "cityName": "Inhuçu",
    "stateId": 5239
  },
  {
    "cityId": 1401,
    "cityName": "Inhuporanga",
    "stateId": 5239
  },
  {
    "cityId": 1402,
    "cityName": "Ipaporanga",
    "stateId": 5239
  },
  {
    "cityId": 1403,
    "cityName": "Ipaumirim",
    "stateId": 5239
  },
  {
    "cityId": 1404,
    "cityName": "Ipu",
    "stateId": 5239
  },
  {
    "cityId": 1405,
    "cityName": "Ipueiras",
    "stateId": 5239
  },
  {
    "cityId": 1406,
    "cityName": "Ipueiras dos Gomes",
    "stateId": 5239
  },
  {
    "cityId": 1407,
    "cityName": "Iracema",
    "stateId": 5239
  },
  {
    "cityId": 1408,
    "cityName": "Irajá",
    "stateId": 5239
  },
  {
    "cityId": 1409,
    "cityName": "Irapuã",
    "stateId": 5239
  },
  {
    "cityId": 1410,
    "cityName": "Iratinga",
    "stateId": 5239
  },
  {
    "cityId": 1411,
    "cityName": "Irauçuba",
    "stateId": 5239
  },
  {
    "cityId": 1412,
    "cityName": "Isidoro",
    "stateId": 5239
  },
  {
    "cityId": 1413,
    "cityName": "Itacima",
    "stateId": 5239
  },
  {
    "cityId": 1414,
    "cityName": "Itaguá",
    "stateId": 5239
  },
  {
    "cityId": 1415,
    "cityName": "Itaiçaba",
    "stateId": 5239
  },
  {
    "cityId": 1416,
    "cityName": "Itaipaba",
    "stateId": 5239
  },
  {
    "cityId": 1417,
    "cityName": "Itaitinga",
    "stateId": 5239
  },
  {
    "cityId": 1418,
    "cityName": "Itans",
    "stateId": 5239
  },
  {
    "cityId": 1419,
    "cityName": "Itapajé",
    "stateId": 5239
  },
  {
    "cityId": 1420,
    "cityName": "Itapebussu",
    "stateId": 5239
  },
  {
    "cityId": 1421,
    "cityName": "Itapeim",
    "stateId": 5239
  },
  {
    "cityId": 1422,
    "cityName": "Itapipoca",
    "stateId": 5239
  },
  {
    "cityId": 1423,
    "cityName": "Itapiúna",
    "stateId": 5239
  },
  {
    "cityId": 1424,
    "cityName": "Itapó",
    "stateId": 5239
  },
  {
    "cityId": 1425,
    "cityName": "Itarema",
    "stateId": 5239
  },
  {
    "cityId": 1426,
    "cityName": "Itatira",
    "stateId": 5239
  },
  {
    "cityId": 1427,
    "cityName": "Jaburuna",
    "stateId": 5239
  },
  {
    "cityId": 1428,
    "cityName": "Jacampari",
    "stateId": 5239
  },
  {
    "cityId": 1429,
    "cityName": "Jacarecoara",
    "stateId": 5239
  },
  {
    "cityId": 1430,
    "cityName": "Jacaúna",
    "stateId": 5239
  },
  {
    "cityId": 1431,
    "cityName": "Jaguarão",
    "stateId": 5239
  },
  {
    "cityId": 1432,
    "cityName": "Jaguaretama",
    "stateId": 5239
  },
  {
    "cityId": 1433,
    "cityName": "Jaguaribara",
    "stateId": 5239
  },
  {
    "cityId": 1434,
    "cityName": "Jaguaribe",
    "stateId": 5239
  },
  {
    "cityId": 1435,
    "cityName": "Jaguaruana",
    "stateId": 5239
  },
  {
    "cityId": 1436,
    "cityName": "Jaibaras",
    "stateId": 5239
  },
  {
    "cityId": 1437,
    "cityName": "Jamacaru",
    "stateId": 5239
  },
  {
    "cityId": 1438,
    "cityName": "Jandrangoeira",
    "stateId": 5239
  },
  {
    "cityId": 1439,
    "cityName": "Jardim",
    "stateId": 5239
  },
  {
    "cityId": 1440,
    "cityName": "Jardim",
    "stateId": 5239
  },
  {
    "cityId": 1441,
    "cityName": "Jardimirim",
    "stateId": 5239
  },
  {
    "cityId": 1442,
    "cityName": "Jati",
    "stateId": 5239
  },
  {
    "cityId": 1443,
    "cityName": "Jijoca de Jericoacoara",
    "stateId": 5239
  },
  {
    "cityId": 1444,
    "cityName": "João Cordeiro",
    "stateId": 5239
  },
  {
    "cityId": 1445,
    "cityName": "Jordão",
    "stateId": 5239
  },
  {
    "cityId": 1446,
    "cityName": "José de Alencar",
    "stateId": 5239
  },
  {
    "cityId": 1447,
    "cityName": "Juá",
    "stateId": 5239
  },
  {
    "cityId": 1448,
    "cityName": "Juá",
    "stateId": 5239
  },
  {
    "cityId": 1449,
    "cityName": "Juatama",
    "stateId": 5239
  },
  {
    "cityId": 1450,
    "cityName": "Juazeiro de Baixo",
    "stateId": 5239
  },
  {
    "cityId": 1451,
    "cityName": "Juazeiro do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1452,
    "cityName": "Jubaia",
    "stateId": 5239
  },
  {
    "cityId": 1453,
    "cityName": "Jucás",
    "stateId": 5239
  },
  {
    "cityId": 1454,
    "cityName": "Jurema",
    "stateId": 5239
  },
  {
    "cityId": 1455,
    "cityName": "Justiniano Serpa",
    "stateId": 5239
  },
  {
    "cityId": 1456,
    "cityName": "Lacerda",
    "stateId": 5239
  },
  {
    "cityId": 1457,
    "cityName": "Ladeira Grande",
    "stateId": 5239
  },
  {
    "cityId": 1458,
    "cityName": "Lagoa de São João",
    "stateId": 5239
  },
  {
    "cityId": 1459,
    "cityName": "Lagoa do Juvenal",
    "stateId": 5239
  },
  {
    "cityId": 1460,
    "cityName": "Lagoa do Mato",
    "stateId": 5239
  },
  {
    "cityId": 1461,
    "cityName": "Lagoa dos Crioulos",
    "stateId": 5239
  },
  {
    "cityId": 1462,
    "cityName": "Lagoa Grande",
    "stateId": 5239
  },
  {
    "cityId": 1463,
    "cityName": "Lagoa Grande",
    "stateId": 5239
  },
  {
    "cityId": 1464,
    "cityName": "Lagoa Grande",
    "stateId": 5239
  },
  {
    "cityId": 1465,
    "cityName": "Lagoinha",
    "stateId": 5239
  },
  {
    "cityId": 1466,
    "cityName": "Lambedouro",
    "stateId": 5239
  },
  {
    "cityId": 1467,
    "cityName": "Lameiro",
    "stateId": 5239
  },
  {
    "cityId": 1468,
    "cityName": "Lavras da Mangabeira",
    "stateId": 5239
  },
  {
    "cityId": 1469,
    "cityName": "Lima Campos",
    "stateId": 5239
  },
  {
    "cityId": 1470,
    "cityName": "Limoeiro do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1471,
    "cityName": "Lisieux",
    "stateId": 5239
  },
  {
    "cityId": 1472,
    "cityName": "Livramento",
    "stateId": 5239
  },
  {
    "cityId": 1473,
    "cityName": "Logradouro",
    "stateId": 5239
  },
  {
    "cityId": 1474,
    "cityName": "Macambira",
    "stateId": 5239
  },
  {
    "cityId": 1475,
    "cityName": "Macaóca",
    "stateId": 5239
  },
  {
    "cityId": 1476,
    "cityName": "Macaraú",
    "stateId": 5239
  },
  {
    "cityId": 1477,
    "cityName": "Maceió",
    "stateId": 5239
  },
  {
    "cityId": 1478,
    "cityName": "Madalena",
    "stateId": 5239
  },
  {
    "cityId": 1479,
    "cityName": "Major Simplício",
    "stateId": 5239
  },
  {
    "cityId": 1480,
    "cityName": "Majorlândia",
    "stateId": 5239
  },
  {
    "cityId": 1481,
    "cityName": "Malhada Grande",
    "stateId": 5239
  },
  {
    "cityId": 1482,
    "cityName": "Mangabeira",
    "stateId": 5239
  },
  {
    "cityId": 1483,
    "cityName": "Manibu",
    "stateId": 5239
  },
  {
    "cityId": 1484,
    "cityName": "Manituba",
    "stateId": 5239
  },
  {
    "cityId": 1485,
    "cityName": "Mapuá",
    "stateId": 5239
  },
  {
    "cityId": 1486,
    "cityName": "Maracanaú",
    "stateId": 5239
  },
  {
    "cityId": 1487,
    "cityName": "Maraguá",
    "stateId": 5239
  },
  {
    "cityId": 1488,
    "cityName": "Maranguape",
    "stateId": 5239
  },
  {
    "cityId": 1489,
    "cityName": "Mararupá",
    "stateId": 5239
  },
  {
    "cityId": 1490,
    "cityName": "Marco",
    "stateId": 5239
  },
  {
    "cityId": 1491,
    "cityName": "Marinheiros",
    "stateId": 5239
  },
  {
    "cityId": 1492,
    "cityName": "Marrecas",
    "stateId": 5239
  },
  {
    "cityId": 1493,
    "cityName": "Marrocos",
    "stateId": 5239
  },
  {
    "cityId": 1494,
    "cityName": "Marruás",
    "stateId": 5239
  },
  {
    "cityId": 1495,
    "cityName": "Martinópole",
    "stateId": 5239
  },
  {
    "cityId": 1496,
    "cityName": "Massapê",
    "stateId": 5239
  },
  {
    "cityId": 1497,
    "cityName": "Mata Fresca",
    "stateId": 5239
  },
  {
    "cityId": 1498,
    "cityName": "Matias",
    "stateId": 5239
  },
  {
    "cityId": 1499,
    "cityName": "Matriz",
    "stateId": 5239
  },
  {
    "cityId": 1500,
    "cityName": "Mauriti",
    "stateId": 5239
  },
  {
    "cityId": 1501,
    "cityName": "Mel",
    "stateId": 5239
  },
  {
    "cityId": 1502,
    "cityName": "Meruoca",
    "stateId": 5239
  },
  {
    "cityId": 1503,
    "cityName": "Messejana",
    "stateId": 5239
  },
  {
    "cityId": 1504,
    "cityName": "Miguel Xavier",
    "stateId": 5239
  },
  {
    "cityId": 1505,
    "cityName": "Milagres",
    "stateId": 5239
  },
  {
    "cityId": 1506,
    "cityName": "Milhã",
    "stateId": 5239
  },
  {
    "cityId": 1507,
    "cityName": "Milton Belo",
    "stateId": 5239
  },
  {
    "cityId": 1508,
    "cityName": "Mineirolândia",
    "stateId": 5239
  },
  {
    "cityId": 1509,
    "cityName": "Miragem",
    "stateId": 5239
  },
  {
    "cityId": 1510,
    "cityName": "Miraíma",
    "stateId": 5239
  },
  {
    "cityId": 1511,
    "cityName": "Mirambé",
    "stateId": 5239
  },
  {
    "cityId": 1512,
    "cityName": "Missão Nova",
    "stateId": 5239
  },
  {
    "cityId": 1513,
    "cityName": "Missão Velha",
    "stateId": 5239
  },
  {
    "cityId": 1514,
    "cityName": "Missi",
    "stateId": 5239
  },
  {
    "cityId": 1515,
    "cityName": "Moitas",
    "stateId": 5239
  },
  {
    "cityId": 1516,
    "cityName": "Mombaça",
    "stateId": 5239
  },
  {
    "cityId": 1517,
    "cityName": "Mondubim",
    "stateId": 5239
  },
  {
    "cityId": 1518,
    "cityName": "Monguba",
    "stateId": 5239
  },
  {
    "cityId": 1519,
    "cityName": "Monsenhor Tabosa",
    "stateId": 5239
  },
  {
    "cityId": 1520,
    "cityName": "Monte Alegre",
    "stateId": 5239
  },
  {
    "cityId": 1521,
    "cityName": "Monte Alegre",
    "stateId": 5239
  },
  {
    "cityId": 1522,
    "cityName": "Monte Castelo",
    "stateId": 5239
  },
  {
    "cityId": 1523,
    "cityName": "Monte Grave",
    "stateId": 5239
  },
  {
    "cityId": 1524,
    "cityName": "Monte Sion",
    "stateId": 5239
  },
  {
    "cityId": 1525,
    "cityName": "Montenebo",
    "stateId": 5239
  },
  {
    "cityId": 1526,
    "cityName": "Morada Nova",
    "stateId": 5239
  },
  {
    "cityId": 1527,
    "cityName": "Moraújo",
    "stateId": 5239
  },
  {
    "cityId": 1528,
    "cityName": "Morrinhos",
    "stateId": 5239
  },
  {
    "cityId": 1529,
    "cityName": "Morrinhos Novos",
    "stateId": 5239
  },
  {
    "cityId": 1530,
    "cityName": "Morro Branco",
    "stateId": 5239
  },
  {
    "cityId": 1531,
    "cityName": "Mucambo",
    "stateId": 5239
  },
  {
    "cityId": 1532,
    "cityName": "Mulungu",
    "stateId": 5239
  },
  {
    "cityId": 1533,
    "cityName": "Mulungu",
    "stateId": 5239
  },
  {
    "cityId": 1534,
    "cityName": "Mumbaba",
    "stateId": 5239
  },
  {
    "cityId": 1535,
    "cityName": "Mundau",
    "stateId": 5239
  },
  {
    "cityId": 1536,
    "cityName": "Muribeca",
    "stateId": 5239
  },
  {
    "cityId": 1537,
    "cityName": "Muriti",
    "stateId": 5239
  },
  {
    "cityId": 1538,
    "cityName": "Mutambeiras",
    "stateId": 5239
  },
  {
    "cityId": 1539,
    "cityName": "Naraniu",
    "stateId": 5239
  },
  {
    "cityId": 1540,
    "cityName": "Nascente",
    "stateId": 5239
  },
  {
    "cityId": 1541,
    "cityName": "Nenenlândia",
    "stateId": 5239
  },
  {
    "cityId": 1542,
    "cityName": "Nossa Senhora do Livramento",
    "stateId": 5239
  },
  {
    "cityId": 1543,
    "cityName": "Nova Betânia",
    "stateId": 5239
  },
  {
    "cityId": 1544,
    "cityName": "Nova Betânia",
    "stateId": 5239
  },
  {
    "cityId": 1545,
    "cityName": "Nova Fátima",
    "stateId": 5239
  },
  {
    "cityId": 1546,
    "cityName": "Nova Floresta",
    "stateId": 5239
  },
  {
    "cityId": 1547,
    "cityName": "Nova Olinda",
    "stateId": 5239
  },
  {
    "cityId": 1548,
    "cityName": "Nova Russas",
    "stateId": 5239
  },
  {
    "cityId": 1549,
    "cityName": "Nova Vida",
    "stateId": 5239
  },
  {
    "cityId": 1550,
    "cityName": "Novo Assis",
    "stateId": 5239
  },
  {
    "cityId": 1551,
    "cityName": "Novo Oriente",
    "stateId": 5239
  },
  {
    "cityId": 1552,
    "cityName": "Novo Oriente",
    "stateId": 5239
  },
  {
    "cityId": 1553,
    "cityName": "Ocara",
    "stateId": 5239
  },
  {
    "cityId": 1554,
    "cityName": "Oiticica",
    "stateId": 5239
  },
  {
    "cityId": 1555,
    "cityName": "Oiticica",
    "stateId": 5239
  },
  {
    "cityId": 1556,
    "cityName": "Olho-D'Água",
    "stateId": 5239
  },
  {
    "cityId": 1557,
    "cityName": "Olho-D'Água da Bica",
    "stateId": 5239
  },
  {
    "cityId": 1558,
    "cityName": "Oliveiras",
    "stateId": 5239
  },
  {
    "cityId": 1559,
    "cityName": "Orós",
    "stateId": 5239
  },
  {
    "cityId": 1560,
    "cityName": "Pacajus",
    "stateId": 5239
  },
  {
    "cityId": 1561,
    "cityName": "Pacatuba",
    "stateId": 5239
  },
  {
    "cityId": 1562,
    "cityName": "Pacoti",
    "stateId": 5239
  },
  {
    "cityId": 1563,
    "cityName": "Pacujá",
    "stateId": 5239
  },
  {
    "cityId": 1564,
    "cityName": "Padre Cícero",
    "stateId": 5239
  },
  {
    "cityId": 1565,
    "cityName": "Padre Linhares",
    "stateId": 5239
  },
  {
    "cityId": 1566,
    "cityName": "Padre Vieira",
    "stateId": 5239
  },
  {
    "cityId": 1567,
    "cityName": "Pajeú",
    "stateId": 5239
  },
  {
    "cityId": 1569,
    "cityName": "Palestina",
    "stateId": 5239
  },
  {
    "cityId": 1570,
    "cityName": "Palestina",
    "stateId": 5239
  },
  {
    "cityId": 1571,
    "cityName": "Palestina do Norte",
    "stateId": 5239
  },
  {
    "cityId": 1572,
    "cityName": "Palhano",
    "stateId": 5239
  },
  {
    "cityId": 1573,
    "cityName": "Palmácia",
    "stateId": 5239
  },
  {
    "cityId": 1574,
    "cityName": "Palmatória",
    "stateId": 5239
  },
  {
    "cityId": 1575,
    "cityName": "Panacuí",
    "stateId": 5239
  },
  {
    "cityId": 1576,
    "cityName": "Paracuá",
    "stateId": 5239
  },
  {
    "cityId": 1577,
    "cityName": "Paracuru",
    "stateId": 5239
  },
  {
    "cityId": 1578,
    "cityName": "Paraipaba",
    "stateId": 5239
  },
  {
    "cityId": 1579,
    "cityName": "Parajuru",
    "stateId": 5239
  },
  {
    "cityId": 1580,
    "cityName": "Parambu",
    "stateId": 5239
  },
  {
    "cityId": 1581,
    "cityName": "Paramoti",
    "stateId": 5239
  },
  {
    "cityId": 1582,
    "cityName": "Parangaba",
    "stateId": 5239
  },
  {
    "cityId": 1583,
    "cityName": "Parapuí",
    "stateId": 5239
  },
  {
    "cityId": 1584,
    "cityName": "Parazinho",
    "stateId": 5239
  },
  {
    "cityId": 1585,
    "cityName": "Paripueira",
    "stateId": 5239
  },
  {
    "cityId": 1586,
    "cityName": "Passagem",
    "stateId": 5239
  },
  {
    "cityId": 1587,
    "cityName": "Passagem",
    "stateId": 5239
  },
  {
    "cityId": 1588,
    "cityName": "Passagem Funda",
    "stateId": 5239
  },
  {
    "cityId": 1589,
    "cityName": "Pasta",
    "stateId": 5239
  },
  {
    "cityId": 1590,
    "cityName": "Patacas",
    "stateId": 5239
  },
  {
    "cityId": 1591,
    "cityName": "Patriarca",
    "stateId": 5239
  },
  {
    "cityId": 1592,
    "cityName": "Pavuna",
    "stateId": 5239
  },
  {
    "cityId": 1593,
    "cityName": "Pecém",
    "stateId": 5239
  },
  {
    "cityId": 1594,
    "cityName": "Pedra Branca",
    "stateId": 5239
  },
  {
    "cityId": 1595,
    "cityName": "Pedra Branca",
    "stateId": 5239
  },
  {
    "cityId": 1596,
    "cityName": "Pedras",
    "stateId": 5239
  },
  {
    "cityId": 1597,
    "cityName": "Pedrinhas",
    "stateId": 5239
  },
  {
    "cityId": 1598,
    "cityName": "Peixe",
    "stateId": 5239
  },
  {
    "cityId": 1599,
    "cityName": "Peixe Gordo",
    "stateId": 5239
  },
  {
    "cityId": 1600,
    "cityName": "Penaforte",
    "stateId": 5239
  },
  {
    "cityId": 1601,
    "cityName": "Pentecoste",
    "stateId": 5239
  },
  {
    "cityId": 1602,
    "cityName": "Pereiro",
    "stateId": 5239
  },
  {
    "cityId": 1603,
    "cityName": "Pernambuquinho",
    "stateId": 5239
  },
  {
    "cityId": 1604,
    "cityName": "Pessoa Anta",
    "stateId": 5239
  },
  {
    "cityId": 1605,
    "cityName": "Pindoguaba",
    "stateId": 5239
  },
  {
    "cityId": 1606,
    "cityName": "Pindoretama",
    "stateId": 5239
  },
  {
    "cityId": 1607,
    "cityName": "Pio X",
    "stateId": 5239
  },
  {
    "cityId": 1608,
    "cityName": "Piquet Carneiro",
    "stateId": 5239
  },
  {
    "cityId": 1609,
    "cityName": "Pirabibu",
    "stateId": 5239
  },
  {
    "cityId": 1610,
    "cityName": "Pirangi",
    "stateId": 5239
  },
  {
    "cityId": 1611,
    "cityName": "Pires Ferreira",
    "stateId": 5239
  },
  {
    "cityId": 1612,
    "cityName": "Pitombeira",
    "stateId": 5239
  },
  {
    "cityId": 1613,
    "cityName": "Pitombeiras",
    "stateId": 5239
  },
  {
    "cityId": 1614,
    "cityName": "Plácido Martins",
    "stateId": 5239
  },
  {
    "cityId": 1615,
    "cityName": "Poço",
    "stateId": 5239
  },
  {
    "cityId": 1616,
    "cityName": "Poço Comprido",
    "stateId": 5239
  },
  {
    "cityId": 1617,
    "cityName": "Poço Comprido",
    "stateId": 5239
  },
  {
    "cityId": 1618,
    "cityName": "Poço Grande",
    "stateId": 5239
  },
  {
    "cityId": 1619,
    "cityName": "Podimirim",
    "stateId": 5239
  },
  {
    "cityId": 1620,
    "cityName": "Ponta da Serra",
    "stateId": 5239
  },
  {
    "cityId": 1621,
    "cityName": "Poranga",
    "stateId": 5239
  },
  {
    "cityId": 1622,
    "cityName": "Porfirio Sampaio",
    "stateId": 5239
  },
  {
    "cityId": 1623,
    "cityName": "Porteiras",
    "stateId": 5239
  },
  {
    "cityId": 1624,
    "cityName": "Potengi",
    "stateId": 5239
  },
  {
    "cityId": 1625,
    "cityName": "Poti",
    "stateId": 5239
  },
  {
    "cityId": 1626,
    "cityName": "Potiretama",
    "stateId": 5239
  },
  {
    "cityId": 1627,
    "cityName": "Prata",
    "stateId": 5239
  },
  {
    "cityId": 1628,
    "cityName": "Prudente de Morais",
    "stateId": 5239
  },
  {
    "cityId": 1629,
    "cityName": "Quatiguaba",
    "stateId": 5239
  },
  {
    "cityId": 1630,
    "cityName": "Queimados",
    "stateId": 5239
  },
  {
    "cityId": 1631,
    "cityName": "Quimami",
    "stateId": 5239
  },
  {
    "cityId": 1632,
    "cityName": "Quincoé",
    "stateId": 5239
  },
  {
    "cityId": 1633,
    "cityName": "Quincuncá",
    "stateId": 5239
  },
  {
    "cityId": 1634,
    "cityName": "Quitaiús",
    "stateId": 5239
  },
  {
    "cityId": 1635,
    "cityName": "Quiterianópolis",
    "stateId": 5239
  },
  {
    "cityId": 1636,
    "cityName": "Quixadá",
    "stateId": 5239
  },
  {
    "cityId": 1637,
    "cityName": "Quixariú",
    "stateId": 5239
  },
  {
    "cityId": 1638,
    "cityName": "Quixelô",
    "stateId": 5239
  },
  {
    "cityId": 1639,
    "cityName": "Quixeramobim",
    "stateId": 5239
  },
  {
    "cityId": 1640,
    "cityName": "Quixeré",
    "stateId": 5239
  },
  {
    "cityId": 1641,
    "cityName": "Gadelha",
    "stateId": 5239
  },
  {
    "cityId": 1642,
    "cityName": "Raimundo Martins",
    "stateId": 5239
  },
  {
    "cityId": 1643,
    "cityName": "Redenção",
    "stateId": 5239
  },
  {
    "cityId": 1644,
    "cityName": "Reriutaba",
    "stateId": 5239
  },
  {
    "cityId": 1645,
    "cityName": "Riachão do Banabuiú",
    "stateId": 5239
  },
  {
    "cityId": 1646,
    "cityName": "Riacho Grande",
    "stateId": 5239
  },
  {
    "cityId": 1647,
    "cityName": "Riacho Verde",
    "stateId": 5239
  },
  {
    "cityId": 1648,
    "cityName": "Riacho Vermelho",
    "stateId": 5239
  },
  {
    "cityId": 1649,
    "cityName": "Rinaré",
    "stateId": 5239
  },
  {
    "cityId": 1650,
    "cityName": "Roldão",
    "stateId": 5239
  },
  {
    "cityId": 1651,
    "cityName": "Russas",
    "stateId": 5239
  },
  {
    "cityId": 1652,
    "cityName": "Sabiaguaba",
    "stateId": 5239
  },
  {
    "cityId": 1653,
    "cityName": "Saboeiro",
    "stateId": 5239
  },
  {
    "cityId": 1654,
    "cityName": "Sacramento",
    "stateId": 5239
  },
  {
    "cityId": 1655,
    "cityName": "Salão",
    "stateId": 5239
  },
  {
    "cityId": 1656,
    "cityName": "Salitre",
    "stateId": 5239
  },
  {
    "cityId": 1657,
    "cityName": "Sambaíba",
    "stateId": 5239
  },
  {
    "cityId": 1658,
    "cityName": "Santa Ana",
    "stateId": 5239
  },
  {
    "cityId": 1659,
    "cityName": "Santa Fé",
    "stateId": 5239
  },
  {
    "cityId": 1660,
    "cityName": "Santa Felícia",
    "stateId": 5239
  },
  {
    "cityId": 1661,
    "cityName": "Santa Luzia",
    "stateId": 5239
  },
  {
    "cityId": 1662,
    "cityName": "Santa Quitéria",
    "stateId": 5239
  },
  {
    "cityId": 1663,
    "cityName": "Santa Tereza",
    "stateId": 5239
  },
  {
    "cityId": 1664,
    "cityName": "Santa Tereza",
    "stateId": 5239
  },
  {
    "cityId": 1665,
    "cityName": "Santana",
    "stateId": 5239
  },
  {
    "cityId": 1666,
    "cityName": "Santana do Acaraú",
    "stateId": 5239
  },
  {
    "cityId": 1667,
    "cityName": "Santana do Cariri",
    "stateId": 5239
  },
  {
    "cityId": 1668,
    "cityName": "Santarém",
    "stateId": 5239
  },
  {
    "cityId": 1669,
    "cityName": "Santo Antônio",
    "stateId": 5239
  },
  {
    "cityId": 1670,
    "cityName": "Santo Antônio",
    "stateId": 5239
  },
  {
    "cityId": 1671,
    "cityName": "Santo Antônio",
    "stateId": 5239
  },
  {
    "cityId": 1672,
    "cityName": "Santo Antônio",
    "stateId": 5239
  },
  {
    "cityId": 1673,
    "cityName": "Santo Antônio da Pindoba",
    "stateId": 5239
  },
  {
    "cityId": 1674,
    "cityName": "Santo Antônio dos Fernandes",
    "stateId": 5239
  },
  {
    "cityId": 1675,
    "cityName": "São Bartolomeu",
    "stateId": 5239
  },
  {
    "cityId": 1676,
    "cityName": "São Benedito",
    "stateId": 5239
  },
  {
    "cityId": 1677,
    "cityName": "São Domingos",
    "stateId": 5239
  },
  {
    "cityId": 1678,
    "cityName": "São Felipe",
    "stateId": 5239
  },
  {
    "cityId": 1679,
    "cityName": "São Francisco",
    "stateId": 5239
  },
  {
    "cityId": 2498,
    "cityName": "Magalhães de Almeida",
    "stateId": 5243
  },
  {
    "cityId": 2499,
    "cityName": "Maioba",
    "stateId": 5243
  },
  {
    "cityId": 2500,
    "cityName": "Maracaçumé",
    "stateId": 5243
  },
  {
    "cityId": 2501,
    "cityName": "Marajá do Sena",
    "stateId": 5243
  },
  {
    "cityId": 2502,
    "cityName": "Maranhãozinho",
    "stateId": 5243
  },
  {
    "cityId": 2503,
    "cityName": "Marianópolis",
    "stateId": 5243
  },
  {
    "cityId": 2504,
    "cityName": "Mata",
    "stateId": 5243
  },
  {
    "cityId": 2505,
    "cityName": "Mata Roma",
    "stateId": 5243
  },
  {
    "cityId": 2506,
    "cityName": "Matinha",
    "stateId": 5243
  },
  {
    "cityId": 2507,
    "cityName": "Matões",
    "stateId": 5243
  },
  {
    "cityId": 2508,
    "cityName": "Matões do Norte",
    "stateId": 5243
  },
  {
    "cityId": 2509,
    "cityName": "Milagres do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2510,
    "cityName": "Mirador",
    "stateId": 5243
  },
  {
    "cityId": 2511,
    "cityName": "Miranda do Norte",
    "stateId": 5243
  },
  {
    "cityId": 2512,
    "cityName": "Mirinzal",
    "stateId": 5243
  },
  {
    "cityId": 2513,
    "cityName": "Monção",
    "stateId": 5243
  },
  {
    "cityId": 2514,
    "cityName": "Montes Altos",
    "stateId": 5243
  },
  {
    "cityId": 2515,
    "cityName": "Morros",
    "stateId": 5243
  },
  {
    "cityId": 2516,
    "cityName": "Nina Rodrigues",
    "stateId": 5243
  },
  {
    "cityId": 2517,
    "cityName": "Nova Colinas",
    "stateId": 5243
  },
  {
    "cityId": 2518,
    "cityName": "Nova Iorque",
    "stateId": 5243
  },
  {
    "cityId": 2519,
    "cityName": "Nova Olinda do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2520,
    "cityName": "Olho D'Água das Cunhãs",
    "stateId": 5243
  },
  {
    "cityId": 2521,
    "cityName": "Olinda Nova do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2522,
    "cityName": "Paço do Lumiar",
    "stateId": 5243
  },
  {
    "cityId": 2523,
    "cityName": "Palmeirândia",
    "stateId": 5243
  },
  {
    "cityId": 2524,
    "cityName": "Papagaio",
    "stateId": 5243
  },
  {
    "cityId": 2525,
    "cityName": "Paraibano",
    "stateId": 5243
  },
  {
    "cityId": 2526,
    "cityName": "Parnarama",
    "stateId": 5243
  },
  {
    "cityId": 2527,
    "cityName": "Passagem Franca",
    "stateId": 5243
  },
  {
    "cityId": 2528,
    "cityName": "Pastos Bons",
    "stateId": 5243
  },
  {
    "cityId": 2529,
    "cityName": "Paulino Neves",
    "stateId": 5243
  },
  {
    "cityId": 2530,
    "cityName": "Paulo Ramos",
    "stateId": 5243
  },
  {
    "cityId": 2531,
    "cityName": "Pedreiras",
    "stateId": 5243
  },
  {
    "cityId": 2532,
    "cityName": "Pedro do Rosário",
    "stateId": 5243
  },
  {
    "cityId": 2533,
    "cityName": "Penalva",
    "stateId": 5243
  },
  {
    "cityId": 2534,
    "cityName": "Peri Mirim",
    "stateId": 5243
  },
  {
    "cityId": 2535,
    "cityName": "Peritoró",
    "stateId": 5243
  },
  {
    "cityId": 2536,
    "cityName": "Pimentel",
    "stateId": 5243
  },
  {
    "cityId": 2537,
    "cityName": "Pindaré Mirim",
    "stateId": 5243
  },
  {
    "cityId": 2538,
    "cityName": "Pinheiro",
    "stateId": 5243
  },
  {
    "cityId": 2539,
    "cityName": "Pio XII",
    "stateId": 5243
  },
  {
    "cityId": 2540,
    "cityName": "Pirapemas",
    "stateId": 5243
  },
  {
    "cityId": 2541,
    "cityName": "Poção de Pedras",
    "stateId": 5243
  },
  {
    "cityId": 2542,
    "cityName": "Porto das Gabarras",
    "stateId": 5243
  },
  {
    "cityId": 2543,
    "cityName": "Porto Franco",
    "stateId": 5243
  },
  {
    "cityId": 2544,
    "cityName": "Porto Rico do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2545,
    "cityName": "Presidente Dutra",
    "stateId": 5243
  },
  {
    "cityId": 2546,
    "cityName": "Presidente Juscelino",
    "stateId": 5243
  },
  {
    "cityId": 2547,
    "cityName": "Presidente Médici",
    "stateId": 5243
  },
  {
    "cityId": 2548,
    "cityName": "Presidente Sarney",
    "stateId": 5243
  },
  {
    "cityId": 2549,
    "cityName": "Presidente Vargas",
    "stateId": 5243
  },
  {
    "cityId": 2550,
    "cityName": "Primeira Cruz",
    "stateId": 5243
  },
  {
    "cityId": 2551,
    "cityName": "Raposa",
    "stateId": 5243
  },
  {
    "cityId": 2552,
    "cityName": "Resplandes",
    "stateId": 5243
  },
  {
    "cityId": 2553,
    "cityName": "Riachão",
    "stateId": 5243
  },
  {
    "cityId": 2554,
    "cityName": "Ribamar Fiquene",
    "stateId": 5243
  },
  {
    "cityId": 2555,
    "cityName": "Ribeirão Azul",
    "stateId": 5243
  },
  {
    "cityId": 2556,
    "cityName": "Rocado",
    "stateId": 5243
  },
  {
    "cityId": 2557,
    "cityName": "Roque",
    "stateId": 5243
  },
  {
    "cityId": 2558,
    "cityName": "Rosário",
    "stateId": 5243
  },
  {
    "cityId": 2559,
    "cityName": "Sambaíba",
    "stateId": 5243
  },
  {
    "cityId": 2560,
    "cityName": "Santa Filomena do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2561,
    "cityName": "Santa Helena",
    "stateId": 5243
  },
  {
    "cityId": 2562,
    "cityName": "Santa Inês",
    "stateId": 5243
  },
  {
    "cityId": 2563,
    "cityName": "Santa Luzia",
    "stateId": 5243
  },
  {
    "cityId": 2564,
    "cityName": "Santa Luzia do Paruá",
    "stateId": 5243
  },
  {
    "cityId": 2565,
    "cityName": "Santa Quitéria do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2566,
    "cityName": "Santa Rita",
    "stateId": 5243
  },
  {
    "cityId": 2567,
    "cityName": "Santana do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2568,
    "cityName": "Santo Amaro",
    "stateId": 5243
  },
  {
    "cityId": 2569,
    "cityName": "Santo Antônio dos Lopes",
    "stateId": 5243
  },
  {
    "cityId": 2570,
    "cityName": "São Benedito do Rio Preto",
    "stateId": 5243
  },
  {
    "cityId": 2571,
    "cityName": "São Bento",
    "stateId": 5243
  },
  {
    "cityId": 2572,
    "cityName": "São Bernardo",
    "stateId": 5243
  },
  {
    "cityId": 2573,
    "cityName": "São Domingos do Azeitão",
    "stateId": 5243
  },
  {
    "cityId": 2574,
    "cityName": "São Domingos do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2575,
    "cityName": "São Félix de Balsas",
    "stateId": 5243
  },
  {
    "cityId": 2576,
    "cityName": "São Francisco do Brejão",
    "stateId": 5243
  },
  {
    "cityId": 2577,
    "cityName": "São Francisco do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2578,
    "cityName": "São João Batista",
    "stateId": 5243
  },
  {
    "cityId": 2579,
    "cityName": "São João de Cortes",
    "stateId": 5243
  },
  {
    "cityId": 2580,
    "cityName": "São João do Carú",
    "stateId": 5243
  },
  {
    "cityId": 2581,
    "cityName": "São João do Paraíso",
    "stateId": 5243
  },
  {
    "cityId": 2582,
    "cityName": "São João do Sóter",
    "stateId": 5243
  },
  {
    "cityId": 2583,
    "cityName": "São João dos Patos",
    "stateId": 5243
  },
  {
    "cityId": 2584,
    "cityName": "São Joaquim dos Melos",
    "stateId": 5243
  },
  {
    "cityId": 2585,
    "cityName": "São José de Ribamar",
    "stateId": 5243
  },
  {
    "cityId": 2586,
    "cityName": "São José dos Basílios",
    "stateId": 5243
  },
  {
    "cityId": 2587,
    "cityName": "São Luís",
    "stateId": 5243
  },
  {
    "cityId": 2588,
    "cityName": "São Luís Gonzaga do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2589,
    "cityName": "São Mateus do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2590,
    "cityName": "São Pedro da Água Branca",
    "stateId": 5243
  },
  {
    "cityId": 2591,
    "cityName": "São Pedro dos Crentes",
    "stateId": 5243
  },
  {
    "cityId": 2592,
    "cityName": "São Raimundo das Mangabeiras",
    "stateId": 5243
  },
  {
    "cityId": 2593,
    "cityName": "São Raimundo de Codó",
    "stateId": 5243
  },
  {
    "cityId": 2594,
    "cityName": "São Raimundo do Doca Bezerra",
    "stateId": 5243
  },
  {
    "cityId": 2595,
    "cityName": "São Roberto",
    "stateId": 5243
  },
  {
    "cityId": 2596,
    "cityName": "São Vicente Ferrer",
    "stateId": 5243
  },
  {
    "cityId": 2597,
    "cityName": "Satubinha",
    "stateId": 5243
  },
  {
    "cityId": 2598,
    "cityName": "Senador Alexandre Costa",
    "stateId": 5243
  },
  {
    "cityId": 2599,
    "cityName": "Senador La Roque",
    "stateId": 5243
  },
  {
    "cityId": 2600,
    "cityName": "Serrano do Maranhão",
    "stateId": 5243
  },
  {
    "cityId": 2601,
    "cityName": "Sítio Novo",
    "stateId": 5243
  },
  {
    "cityId": 2602,
    "cityName": "Sucupira do Norte",
    "stateId": 5243
  },
  {
    "cityId": 2603,
    "cityName": "Sucupira do Riachão",
    "stateId": 5243
  },
  {
    "cityId": 2604,
    "cityName": "Tasso Fragoso",
    "stateId": 5243
  },
  {
    "cityId": 2605,
    "cityName": "Timbiras",
    "stateId": 5243
  },
  {
    "cityId": 2606,
    "cityName": "Timon",
    "stateId": 5243
  },
  {
    "cityId": 2607,
    "cityName": "Trizidela do Vale",
    "stateId": 5243
  },
  {
    "cityId": 2608,
    "cityName": "Tufilândia",
    "stateId": 5243
  },
  {
    "cityId": 2609,
    "cityName": "Tuntum",
    "stateId": 5243
  },
  {
    "cityId": 2610,
    "cityName": "Turiaçu",
    "stateId": 5243
  },
  {
    "cityId": 2611,
    "cityName": "Turilândia",
    "stateId": 5243
  },
  {
    "cityId": 2612,
    "cityName": "Tutóia",
    "stateId": 5243
  },
  {
    "cityId": 2613,
    "cityName": "Urbano Santos",
    "stateId": 5243
  },
  {
    "cityId": 2614,
    "cityName": "Vargem Grande",
    "stateId": 5243
  },
  {
    "cityId": 2615,
    "cityName": "Viana",
    "stateId": 5243
  },
  {
    "cityId": 2616,
    "cityName": "Vila Nova dos Martírios",
    "stateId": 5243
  },
  {
    "cityId": 2617,
    "cityName": "Vitória do Mearim",
    "stateId": 5243
  },
  {
    "cityId": 2618,
    "cityName": "Vitorino Freire",
    "stateId": 5243
  },
  {
    "cityId": 2619,
    "cityName": "Zé Doca",
    "stateId": 5243
  },
  {
    "cityId": 2620,
    "cityName": "Abadia dos Dourados",
    "stateId": 5244
  },
  {
    "cityId": 2621,
    "cityName": "Abaeté",
    "stateId": 5244
  },
  {
    "cityId": 2622,
    "cityName": "Abaeté dos Mendes",
    "stateId": 5244
  },
  {
    "cityId": 2623,
    "cityName": "Abaíba",
    "stateId": 5244
  },
  {
    "cityId": 2624,
    "cityName": "Abre Campo",
    "stateId": 5244
  },
  {
    "cityId": 2625,
    "cityName": "Abreus",
    "stateId": 5244
  },
  {
    "cityId": 2626,
    "cityName": "Acaiaca",
    "stateId": 5244
  },
  {
    "cityId": 2627,
    "cityName": "Açucena",
    "stateId": 5244
  },
  {
    "cityId": 2628,
    "cityName": "Acuruí",
    "stateId": 5244
  },
  {
    "cityId": 2629,
    "cityName": "Adão Colares",
    "stateId": 5244
  },
  {
    "cityId": 2630,
    "cityName": "Água Boa",
    "stateId": 5244
  },
  {
    "cityId": 2631,
    "cityName": "Água Branca de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2632,
    "cityName": "Água Comprida",
    "stateId": 5244
  },
  {
    "cityId": 2633,
    "cityName": "Água Viva",
    "stateId": 5244
  },
  {
    "cityId": 2634,
    "cityName": "Aguanil",
    "stateId": 5244
  },
  {
    "cityId": 2635,
    "cityName": "Águas de Araxá",
    "stateId": 5244
  },
  {
    "cityId": 2636,
    "cityName": "Águas de Contendas",
    "stateId": 5244
  },
  {
    "cityId": 2637,
    "cityName": "Águas Férreas",
    "stateId": 5244
  },
  {
    "cityId": 2638,
    "cityName": "Águas Formosas",
    "stateId": 5244
  },
  {
    "cityId": 2639,
    "cityName": "Águas Vermelhas",
    "stateId": 5244
  },
  {
    "cityId": 2640,
    "cityName": "Aimorés",
    "stateId": 5244
  },
  {
    "cityId": 2641,
    "cityName": "Aiuruoca",
    "stateId": 5244
  },
  {
    "cityId": 2642,
    "cityName": "Alagoa",
    "stateId": 5244
  },
  {
    "cityId": 2643,
    "cityName": "Albertina",
    "stateId": 5244
  },
  {
    "cityId": 2644,
    "cityName": "Alberto Isaacson",
    "stateId": 5244
  },
  {
    "cityId": 2645,
    "cityName": "Albertos",
    "stateId": 5244
  },
  {
    "cityId": 2646,
    "cityName": "Aldeia",
    "stateId": 5244
  },
  {
    "cityId": 2647,
    "cityName": "Alegre",
    "stateId": 5244
  },
  {
    "cityId": 2648,
    "cityName": "Alegria",
    "stateId": 5244
  },
  {
    "cityId": 2649,
    "cityName": "Além Paraíba",
    "stateId": 5244
  },
  {
    "cityId": 2650,
    "cityName": "Alexandrita",
    "stateId": 5244
  },
  {
    "cityId": 2651,
    "cityName": "Alfenas",
    "stateId": 5244
  },
  {
    "cityId": 2652,
    "cityName": "Alfredo Vasconcelos",
    "stateId": 5244
  },
  {
    "cityId": 2653,
    "cityName": "Almeida",
    "stateId": 5244
  },
  {
    "cityId": 2654,
    "cityName": "Almenara",
    "stateId": 5244
  },
  {
    "cityId": 2655,
    "cityName": "Alpercata",
    "stateId": 5244
  },
  {
    "cityId": 2656,
    "cityName": "Alpinópolis",
    "stateId": 5244
  },
  {
    "cityId": 2657,
    "cityName": "Alterosa",
    "stateId": 5244
  },
  {
    "cityId": 2658,
    "cityName": "Alto Caparaó",
    "stateId": 5244
  },
  {
    "cityId": 2659,
    "cityName": "Alto Capim",
    "stateId": 5244
  },
  {
    "cityId": 2660,
    "cityName": "Alto de Santa Helena",
    "stateId": 5244
  },
  {
    "cityId": 2661,
    "cityName": "Alto Jequitibá",
    "stateId": 5244
  },
  {
    "cityId": 2662,
    "cityName": "Alto Maranhão",
    "stateId": 5244
  },
  {
    "cityId": 2663,
    "cityName": "Alto Rio Doce",
    "stateId": 5244
  },
  {
    "cityId": 2664,
    "cityName": "Altolândia",
    "stateId": 5244
  },
  {
    "cityId": 2665,
    "cityName": "Alvação",
    "stateId": 5244
  },
  {
    "cityId": 2666,
    "cityName": "Alvarenga",
    "stateId": 5244
  },
  {
    "cityId": 2667,
    "cityName": "Alvinópolis",
    "stateId": 5244
  },
  {
    "cityId": 2668,
    "cityName": "Alvorada",
    "stateId": 5244
  },
  {
    "cityId": 2669,
    "cityName": "Alvorada de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2670,
    "cityName": "Amanda",
    "stateId": 5244
  },
  {
    "cityId": 2671,
    "cityName": "Amanhece",
    "stateId": 5244
  },
  {
    "cityId": 2672,
    "cityName": "Amarantina",
    "stateId": 5244
  },
  {
    "cityId": 2673,
    "cityName": "Amparo da Serra",
    "stateId": 5244
  },
  {
    "cityId": 2674,
    "cityName": "Andiroba",
    "stateId": 5244
  },
  {
    "cityId": 2675,
    "cityName": "Andradas",
    "stateId": 5244
  },
  {
    "cityId": 2676,
    "cityName": "Andrelândia",
    "stateId": 5244
  },
  {
    "cityId": 2677,
    "cityName": "Andrequicé",
    "stateId": 5244
  },
  {
    "cityId": 2678,
    "cityName": "Angaturama",
    "stateId": 5244
  },
  {
    "cityId": 2679,
    "cityName": "Angelândia",
    "stateId": 5244
  },
  {
    "cityId": 2680,
    "cityName": "Angicos de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2681,
    "cityName": "Angueretá",
    "stateId": 5244
  },
  {
    "cityId": 2682,
    "cityName": "Angustura",
    "stateId": 5244
  },
  {
    "cityId": 2683,
    "cityName": "Antônio Carlos",
    "stateId": 5244
  },
  {
    "cityId": 2684,
    "cityName": "Antônio Dias",
    "stateId": 5244
  },
  {
    "cityId": 2685,
    "cityName": "Antônio dos Santos",
    "stateId": 5244
  },
  {
    "cityId": 2686,
    "cityName": "Antônio Ferreira",
    "stateId": 5244
  },
  {
    "cityId": 2687,
    "cityName": "Antônio Pereira",
    "stateId": 5244
  },
  {
    "cityId": 2688,
    "cityName": "Antônio Prado de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2689,
    "cityName": "Antunes",
    "stateId": 5244
  },
  {
    "cityId": 2690,
    "cityName": "Aparecida de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2691,
    "cityName": "Araçaí",
    "stateId": 5244
  },
  {
    "cityId": 2692,
    "cityName": "Aracati de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2693,
    "cityName": "Aracitaba",
    "stateId": 5244
  },
  {
    "cityId": 2694,
    "cityName": "Araçuaí",
    "stateId": 5244
  },
  {
    "cityId": 2695,
    "cityName": "Araguari",
    "stateId": 5244
  },
  {
    "cityId": 2696,
    "cityName": "Aramirim",
    "stateId": 5244
  },
  {
    "cityId": 2697,
    "cityName": "Aranha",
    "stateId": 5244
  },
  {
    "cityId": 2698,
    "cityName": "Arantina",
    "stateId": 5244
  },
  {
    "cityId": 2699,
    "cityName": "Araponga",
    "stateId": 5244
  },
  {
    "cityId": 2700,
    "cityName": "Araporã",
    "stateId": 5244
  },
  {
    "cityId": 2701,
    "cityName": "Arapuá",
    "stateId": 5244
  },
  {
    "cityId": 2702,
    "cityName": "Araújos",
    "stateId": 5244
  },
  {
    "cityId": 2703,
    "cityName": "Araúna",
    "stateId": 5244
  },
  {
    "cityId": 2704,
    "cityName": "Araxá",
    "stateId": 5244
  },
  {
    "cityId": 2705,
    "cityName": "Arcângelo",
    "stateId": 5244
  },
  {
    "cityId": 2706,
    "cityName": "Arceburgo",
    "stateId": 5244
  },
  {
    "cityId": 2707,
    "cityName": "Arcos",
    "stateId": 5244
  },
  {
    "cityId": 2708,
    "cityName": "Areado",
    "stateId": 5244
  },
  {
    "cityId": 2709,
    "cityName": "Argenita",
    "stateId": 5244
  },
  {
    "cityId": 2710,
    "cityName": "Argirita",
    "stateId": 5244
  },
  {
    "cityId": 2711,
    "cityName": "Aricanduva",
    "stateId": 5244
  },
  {
    "cityId": 2712,
    "cityName": "Arinos",
    "stateId": 5244
  },
  {
    "cityId": 2713,
    "cityName": "Aristides Batista",
    "stateId": 5244
  },
  {
    "cityId": 2714,
    "cityName": "Ascenção",
    "stateId": 5244
  },
  {
    "cityId": 2715,
    "cityName": "Assaraí",
    "stateId": 5244
  },
  {
    "cityId": 2716,
    "cityName": "Astolfo Dutra",
    "stateId": 5244
  },
  {
    "cityId": 2717,
    "cityName": "Ataléia",
    "stateId": 5244
  },
  {
    "cityId": 2718,
    "cityName": "Augusto de Lima",
    "stateId": 5244
  },
  {
    "cityId": 2719,
    "cityName": "Avaí do Jacinto",
    "stateId": 5244
  },
  {
    "cityId": 2720,
    "cityName": "Azurita",
    "stateId": 5244
  },
  {
    "cityId": 2721,
    "cityName": "Babilônia",
    "stateId": 5244
  },
  {
    "cityId": 2722,
    "cityName": "Bação",
    "stateId": 5244
  },
  {
    "cityId": 2723,
    "cityName": "Baependi",
    "stateId": 5244
  },
  {
    "cityId": 2724,
    "cityName": "Baguari",
    "stateId": 5244
  },
  {
    "cityId": 2725,
    "cityName": "Baiões",
    "stateId": 5244
  },
  {
    "cityId": 2726,
    "cityName": "Baixa",
    "stateId": 5244
  },
  {
    "cityId": 2727,
    "cityName": "Balbinópolis",
    "stateId": 5244
  },
  {
    "cityId": 2728,
    "cityName": "Baldim",
    "stateId": 5244
  },
  {
    "cityId": 2729,
    "cityName": "Bambuí",
    "stateId": 5244
  },
  {
    "cityId": 2730,
    "cityName": "Bandeira",
    "stateId": 5244
  },
  {
    "cityId": 2731,
    "cityName": "Bandeira do Sul",
    "stateId": 5244
  },
  {
    "cityId": 2732,
    "cityName": "Bandeirantes",
    "stateId": 5244
  },
  {
    "cityId": 2733,
    "cityName": "Barão de Cocais",
    "stateId": 5244
  },
  {
    "cityId": 2734,
    "cityName": "Barão de Monte Alto",
    "stateId": 5244
  },
  {
    "cityId": 2735,
    "cityName": "Barbacena",
    "stateId": 5244
  },
  {
    "cityId": 2736,
    "cityName": "Barra Alegre",
    "stateId": 5244
  },
  {
    "cityId": 2737,
    "cityName": "Barra da Figueira",
    "stateId": 5244
  },
  {
    "cityId": 2738,
    "cityName": "Barra do Ariranha",
    "stateId": 5244
  },
  {
    "cityId": 2739,
    "cityName": "Barra do Cuieté",
    "stateId": 5244
  },
  {
    "cityId": 2740,
    "cityName": "Barra Feliz",
    "stateId": 5244
  },
  {
    "cityId": 2741,
    "cityName": "Barra Longa",
    "stateId": 5244
  },
  {
    "cityId": 2742,
    "cityName": "Barranco Alto",
    "stateId": 5244
  },
  {
    "cityId": 2743,
    "cityName": "Barreiro",
    "stateId": 5244
  },
  {
    "cityId": 2744,
    "cityName": "Barreiro Branco",
    "stateId": 5244
  },
  {
    "cityId": 2745,
    "cityName": "Barreiro da Raiz",
    "stateId": 5244
  },
  {
    "cityId": 2746,
    "cityName": "Barreiro do Rio Verde",
    "stateId": 5244
  },
  {
    "cityId": 2747,
    "cityName": "Barretos de Alvinópolis",
    "stateId": 5244
  },
  {
    "cityId": 2748,
    "cityName": "Barrocão",
    "stateId": 5244
  },
  {
    "cityId": 2749,
    "cityName": "Barroso",
    "stateId": 5244
  },
  {
    "cityId": 2750,
    "cityName": "Baú",
    "stateId": 5244
  },
  {
    "cityId": 2751,
    "cityName": "Bela Vista de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2752,
    "cityName": "Belisário",
    "stateId": 5244
  },
  {
    "cityId": 2753,
    "cityName": "Belmiro Braga",
    "stateId": 5244
  },
  {
    "cityId": 2754,
    "cityName": "Belo Horizonte",
    "stateId": 5244
  },
  {
    "cityId": 2755,
    "cityName": "Belo Oriente",
    "stateId": 5244
  },
  {
    "cityId": 2756,
    "cityName": "Belo Oriente",
    "stateId": 5244
  },
  {
    "cityId": 2757,
    "cityName": "Belo Vale",
    "stateId": 5244
  },
  {
    "cityId": 2758,
    "cityName": "Bentópolis de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2759,
    "cityName": "Berilo",
    "stateId": 5244
  },
  {
    "cityId": 2760,
    "cityName": "Berizal",
    "stateId": 5244
  },
  {
    "cityId": 2761,
    "cityName": "Bertópolis",
    "stateId": 5244
  },
  {
    "cityId": 2762,
    "cityName": "Betim",
    "stateId": 5244
  },
  {
    "cityId": 2763,
    "cityName": "Bias Fortes",
    "stateId": 5244
  },
  {
    "cityId": 2764,
    "cityName": "Bicas",
    "stateId": 5244
  },
  {
    "cityId": 2765,
    "cityName": "Bicuíba",
    "stateId": 5244
  },
  {
    "cityId": 2766,
    "cityName": "Biquinhas",
    "stateId": 5244
  },
  {
    "cityId": 2767,
    "cityName": "Bituri",
    "stateId": 5244
  },
  {
    "cityId": 2768,
    "cityName": "Boa Esperança",
    "stateId": 5244
  },
  {
    "cityId": 2769,
    "cityName": "Boa Família",
    "stateId": 5244
  },
  {
    "cityId": 2770,
    "cityName": "Boa União de Itabirinha",
    "stateId": 5244
  },
  {
    "cityId": 2771,
    "cityName": "Boa Vista de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2772,
    "cityName": "Bocaina de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2773,
    "cityName": "Bocaiúva",
    "stateId": 5244
  },
  {
    "cityId": 2774,
    "cityName": "Bom Despacho",
    "stateId": 5244
  },
  {
    "cityId": 2775,
    "cityName": "Bom Jardim de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2776,
    "cityName": "Bom Jesus da Cachoeira",
    "stateId": 5244
  },
  {
    "cityId": 2777,
    "cityName": "Bom Jesus da Penha",
    "stateId": 5244
  },
  {
    "cityId": 2778,
    "cityName": "Bom Jesus de Cardosos",
    "stateId": 5244
  },
  {
    "cityId": 2779,
    "cityName": "Bom Jesus do Amparo",
    "stateId": 5244
  },
  {
    "cityId": 2780,
    "cityName": "Bom Jesus do Divino",
    "stateId": 5244
  },
  {
    "cityId": 2781,
    "cityName": "Bom Jesus do Galho",
    "stateId": 5244
  },
  {
    "cityId": 2782,
    "cityName": "Bom Jesus do Madeira",
    "stateId": 5244
  },
  {
    "cityId": 2783,
    "cityName": "Bom Pastor",
    "stateId": 5244
  },
  {
    "cityId": 2784,
    "cityName": "Bom Repouso",
    "stateId": 5244
  },
  {
    "cityId": 2785,
    "cityName": "Bom Retiro",
    "stateId": 5244
  },
  {
    "cityId": 2786,
    "cityName": "Bom Sucesso",
    "stateId": 5244
  },
  {
    "cityId": 2787,
    "cityName": "Bom Sucesso de Patos",
    "stateId": 5244
  },
  {
    "cityId": 2788,
    "cityName": "Bonança",
    "stateId": 5244
  },
  {
    "cityId": 2789,
    "cityName": "Bonfim",
    "stateId": 5244
  },
  {
    "cityId": 2790,
    "cityName": "Bonfinópolis de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2791,
    "cityName": "Bonito de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2792,
    "cityName": "Borba Gato",
    "stateId": 5244
  },
  {
    "cityId": 2793,
    "cityName": "Borda da Mata",
    "stateId": 5244
  },
  {
    "cityId": 2794,
    "cityName": "Botelhos",
    "stateId": 5244
  },
  {
    "cityId": 2795,
    "cityName": "Botumirim",
    "stateId": 5244
  },
  {
    "cityId": 2796,
    "cityName": "Brás Pires",
    "stateId": 5244
  },
  {
    "cityId": 2797,
    "cityName": "Brasilândia de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2798,
    "cityName": "Brasília de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2799,
    "cityName": "Brasópolis",
    "stateId": 5244
  },
  {
    "cityId": 2800,
    "cityName": "Braúnas",
    "stateId": 5244
  },
  {
    "cityId": 2801,
    "cityName": "Brejaúba",
    "stateId": 5244
  },
  {
    "cityId": 2802,
    "cityName": "Brejaubinha",
    "stateId": 5244
  },
  {
    "cityId": 2803,
    "cityName": "Brejo Bonito",
    "stateId": 5244
  },
  {
    "cityId": 2804,
    "cityName": "Brejo do Amparo",
    "stateId": 5244
  },
  {
    "cityId": 2805,
    "cityName": "Brumadinho",
    "stateId": 5244
  },
  {
    "cityId": 2806,
    "cityName": "Brumal",
    "stateId": 5244
  },
  {
    "cityId": 2807,
    "cityName": "Buarque de Macedo",
    "stateId": 5244
  },
  {
    "cityId": 2808,
    "cityName": "Bueno",
    "stateId": 5244
  },
  {
    "cityId": 2809,
    "cityName": "Bueno Brandão",
    "stateId": 5244
  },
  {
    "cityId": 2810,
    "cityName": "Buenópolis",
    "stateId": 5244
  },
  {
    "cityId": 2811,
    "cityName": "Bugre",
    "stateId": 5244
  },
  {
    "cityId": 2812,
    "cityName": "Buritis",
    "stateId": 5244
  },
  {
    "cityId": 2813,
    "cityName": "Buritizeiro",
    "stateId": 5244
  },
  {
    "cityId": 2814,
    "cityName": "Caatinga",
    "stateId": 5244
  },
  {
    "cityId": 2815,
    "cityName": "Cabeceira Grande",
    "stateId": 5244
  },
  {
    "cityId": 2816,
    "cityName": "Cabo Verde",
    "stateId": 5244
  },
  {
    "cityId": 2817,
    "cityName": "Caburu",
    "stateId": 5244
  },
  {
    "cityId": 2818,
    "cityName": "Caçaratiba",
    "stateId": 5244
  },
  {
    "cityId": 2819,
    "cityName": "Caçarema",
    "stateId": 5244
  },
  {
    "cityId": 2820,
    "cityName": "Cachoeira Alegre",
    "stateId": 5244
  },
  {
    "cityId": 2821,
    "cityName": "Cachoeira da Prata",
    "stateId": 5244
  },
  {
    "cityId": 2822,
    "cityName": "Cachoeira de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2823,
    "cityName": "Cachoeira de Pajeú",
    "stateId": 5244
  },
  {
    "cityId": 2824,
    "cityName": "Cachoeira de Santa Cruz",
    "stateId": 5244
  },
  {
    "cityId": 2825,
    "cityName": "Cachoeira do Brumado",
    "stateId": 5244
  },
  {
    "cityId": 2826,
    "cityName": "Cachoeira do Campo",
    "stateId": 5244
  },
  {
    "cityId": 2827,
    "cityName": "Cachoeira do Manteiga",
    "stateId": 5244
  },
  {
    "cityId": 2828,
    "cityName": "Cachoeira do Vale",
    "stateId": 5244
  },
  {
    "cityId": 2829,
    "cityName": "Cachoeira dos Antunes",
    "stateId": 5244
  },
  {
    "cityId": 2830,
    "cityName": "Cachoeira Dourada",
    "stateId": 5244
  },
  {
    "cityId": 2831,
    "cityName": "Cachoeirinha",
    "stateId": 5244
  },
  {
    "cityId": 2832,
    "cityName": "Caetano Lopes",
    "stateId": 5244
  },
  {
    "cityId": 2833,
    "cityName": "Caetanópolis",
    "stateId": 5244
  },
  {
    "cityId": 2834,
    "cityName": "Caeté",
    "stateId": 5244
  },
  {
    "cityId": 2835,
    "cityName": "Caeté",
    "stateId": 5244
  },
  {
    "cityId": 2836,
    "cityName": "Caiana",
    "stateId": 5244
  },
  {
    "cityId": 2837,
    "cityName": "Caiapó",
    "stateId": 5244
  },
  {
    "cityId": 2838,
    "cityName": "Cajuri",
    "stateId": 5244
  },
  {
    "cityId": 2839,
    "cityName": "Caldas",
    "stateId": 5244
  },
  {
    "cityId": 2840,
    "cityName": "Calixto",
    "stateId": 5244
  },
  {
    "cityId": 2841,
    "cityName": "Camacho",
    "stateId": 5244
  },
  {
    "cityId": 2842,
    "cityName": "Camanducaia",
    "stateId": 5244
  },
  {
    "cityId": 2843,
    "cityName": "Camargos",
    "stateId": 5244
  },
  {
    "cityId": 2844,
    "cityName": "Cambuí",
    "stateId": 5244
  },
  {
    "cityId": 2845,
    "cityName": "Cambuquira",
    "stateId": 5244
  },
  {
    "cityId": 2846,
    "cityName": "Campanário",
    "stateId": 5244
  },
  {
    "cityId": 2847,
    "cityName": "Campanha",
    "stateId": 5244
  },
  {
    "cityId": 2848,
    "cityName": "Campestre",
    "stateId": 5244
  },
  {
    "cityId": 2849,
    "cityName": "Campestrinho",
    "stateId": 5244
  },
  {
    "cityId": 2850,
    "cityName": "Campina Verde",
    "stateId": 5244
  },
  {
    "cityId": 2851,
    "cityName": "Campo Alegre de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2852,
    "cityName": "Campo Alegre de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2853,
    "cityName": "Campo Azul",
    "stateId": 5244
  },
  {
    "cityId": 2854,
    "cityName": "Campo Belo",
    "stateId": 5244
  },
  {
    "cityId": 2855,
    "cityName": "Campo do Meio",
    "stateId": 5244
  },
  {
    "cityId": 2856,
    "cityName": "Campo Florido",
    "stateId": 5244
  },
  {
    "cityId": 2857,
    "cityName": "Campo Redondo",
    "stateId": 5244
  },
  {
    "cityId": 2858,
    "cityName": "Campolide",
    "stateId": 5244
  },
  {
    "cityId": 2859,
    "cityName": "Campos Altos",
    "stateId": 5244
  },
  {
    "cityId": 2860,
    "cityName": "Campos Gerais",
    "stateId": 5244
  },
  {
    "cityId": 2861,
    "cityName": "Cana Verde",
    "stateId": 5244
  },
  {
    "cityId": 2862,
    "cityName": "Canaã",
    "stateId": 5244
  },
  {
    "cityId": 2863,
    "cityName": "Canabrava",
    "stateId": 5244
  },
  {
    "cityId": 2864,
    "cityName": "Canabrava",
    "stateId": 5244
  },
  {
    "cityId": 2865,
    "cityName": "Canápolis",
    "stateId": 5244
  },
  {
    "cityId": 2866,
    "cityName": "Canastrão",
    "stateId": 5244
  },
  {
    "cityId": 2867,
    "cityName": "Candeias",
    "stateId": 5244
  },
  {
    "cityId": 2868,
    "cityName": "Canoeiros",
    "stateId": 5244
  },
  {
    "cityId": 2869,
    "cityName": "Cantagalo",
    "stateId": 5244
  },
  {
    "cityId": 2870,
    "cityName": "Caparaó",
    "stateId": 5244
  },
  {
    "cityId": 2871,
    "cityName": "Capela Nova",
    "stateId": 5244
  },
  {
    "cityId": 2872,
    "cityName": "Capelinha",
    "stateId": 5244
  },
  {
    "cityId": 2873,
    "cityName": "Capetinga",
    "stateId": 5244
  },
  {
    "cityId": 2874,
    "cityName": "Capim Branco",
    "stateId": 5244
  },
  {
    "cityId": 2875,
    "cityName": "Capinópolis",
    "stateId": 5244
  },
  {
    "cityId": 2876,
    "cityName": "Capitânia",
    "stateId": 5244
  },
  {
    "cityId": 2877,
    "cityName": "Capitão Andrade",
    "stateId": 5244
  },
  {
    "cityId": 2878,
    "cityName": "Capitão Enéas",
    "stateId": 5244
  },
  {
    "cityId": 2879,
    "cityName": "Capitólio",
    "stateId": 5244
  },
  {
    "cityId": 2880,
    "cityName": "Caputira",
    "stateId": 5244
  },
  {
    "cityId": 2881,
    "cityName": "Caraí",
    "stateId": 5244
  },
  {
    "cityId": 2882,
    "cityName": "Caranaíba",
    "stateId": 5244
  },
  {
    "cityId": 2883,
    "cityName": "Carandaí",
    "stateId": 5244
  },
  {
    "cityId": 2884,
    "cityName": "Carangola",
    "stateId": 5244
  },
  {
    "cityId": 2885,
    "cityName": "Caratinga",
    "stateId": 5244
  },
  {
    "cityId": 2886,
    "cityName": "Carbonita",
    "stateId": 5244
  },
  {
    "cityId": 2887,
    "cityName": "Serra do Cipó",
    "stateId": 5244
  },
  {
    "cityId": 2888,
    "cityName": "Careaçu",
    "stateId": 5244
  },
  {
    "cityId": 2889,
    "cityName": "Carioca",
    "stateId": 5244
  },
  {
    "cityId": 2890,
    "cityName": "Carlos Alves",
    "stateId": 5244
  },
  {
    "cityId": 2891,
    "cityName": "Carlos Chagas",
    "stateId": 5244
  },
  {
    "cityId": 2892,
    "cityName": "Carmésia",
    "stateId": 5244
  },
  {
    "cityId": 2893,
    "cityName": "Carmo da Cachoeira",
    "stateId": 5244
  },
  {
    "cityId": 2894,
    "cityName": "Carmo da Mata",
    "stateId": 5244
  },
  {
    "cityId": 2895,
    "cityName": "Carmo de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2896,
    "cityName": "Carmo do Cajuru",
    "stateId": 5244
  },
  {
    "cityId": 2897,
    "cityName": "Carmo do Paranaíba",
    "stateId": 5244
  },
  {
    "cityId": 2898,
    "cityName": "Carmo do Rio Claro",
    "stateId": 5244
  },
  {
    "cityId": 2899,
    "cityName": "Carmópolis de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2900,
    "cityName": "Carneirinho",
    "stateId": 5244
  },
  {
    "cityId": 2901,
    "cityName": "Carrancas",
    "stateId": 5244
  },
  {
    "cityId": 2902,
    "cityName": "Carvalho de Brito",
    "stateId": 5244
  },
  {
    "cityId": 2903,
    "cityName": "Carvalhópolis",
    "stateId": 5244
  },
  {
    "cityId": 2904,
    "cityName": "Carvalhos",
    "stateId": 5244
  },
  {
    "cityId": 2905,
    "cityName": "Casa Grande",
    "stateId": 5244
  },
  {
    "cityId": 2906,
    "cityName": "Cascalho Rico",
    "stateId": 5244
  },
  {
    "cityId": 2907,
    "cityName": "Cássia",
    "stateId": 5244
  },
  {
    "cityId": 2908,
    "cityName": "Cataguarino",
    "stateId": 5244
  },
  {
    "cityId": 2909,
    "cityName": "Cataguases",
    "stateId": 5244
  },
  {
    "cityId": 2910,
    "cityName": "Catajás",
    "stateId": 5244
  },
  {
    "cityId": 2911,
    "cityName": "Catas Altas",
    "stateId": 5244
  },
  {
    "cityId": 2912,
    "cityName": "Catas Altas da Noruega",
    "stateId": 5244
  },
  {
    "cityId": 2913,
    "cityName": "Catiara",
    "stateId": 5244
  },
  {
    "cityId": 2914,
    "cityName": "Catuji",
    "stateId": 5244
  },
  {
    "cityId": 2915,
    "cityName": "Catuné",
    "stateId": 5244
  },
  {
    "cityId": 2916,
    "cityName": "Catuni",
    "stateId": 5244
  },
  {
    "cityId": 2917,
    "cityName": "Catuti",
    "stateId": 5244
  },
  {
    "cityId": 2918,
    "cityName": "Caxambu",
    "stateId": 5244
  },
  {
    "cityId": 2919,
    "cityName": "Cedro do Abaeté",
    "stateId": 5244
  },
  {
    "cityId": 2920,
    "cityName": "Centenário",
    "stateId": 5244
  },
  {
    "cityId": 2921,
    "cityName": "Central de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2922,
    "cityName": "Central de Santa Helena",
    "stateId": 5244
  },
  {
    "cityId": 2923,
    "cityName": "Centralina",
    "stateId": 5244
  },
  {
    "cityId": 2924,
    "cityName": "Cervo",
    "stateId": 5244
  },
  {
    "cityId": 2925,
    "cityName": "Chácara",
    "stateId": 5244
  },
  {
    "cityId": 2926,
    "cityName": "Chalé",
    "stateId": 5244
  },
  {
    "cityId": 2927,
    "cityName": "Chapada de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2928,
    "cityName": "Chapada do Norte",
    "stateId": 5244
  },
  {
    "cityId": 2929,
    "cityName": "Chapada Gaúcha",
    "stateId": 5244
  },
  {
    "cityId": 2930,
    "cityName": "Chaveslândia",
    "stateId": 5244
  },
  {
    "cityId": 2931,
    "cityName": "Chiador",
    "stateId": 5244
  },
  {
    "cityId": 2932,
    "cityName": "Chonim",
    "stateId": 5244
  },
  {
    "cityId": 2933,
    "cityName": "Chumbo",
    "stateId": 5244
  },
  {
    "cityId": 2934,
    "cityName": "Cipotânea",
    "stateId": 5244
  },
  {
    "cityId": 2935,
    "cityName": "Cisneiros",
    "stateId": 5244
  },
  {
    "cityId": 2937,
    "cityName": "Claraval",
    "stateId": 5244
  },
  {
    "cityId": 2938,
    "cityName": "Claro de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2939,
    "cityName": "Claro dos Poções",
    "stateId": 5244
  },
  {
    "cityId": 2940,
    "cityName": "Cláudio",
    "stateId": 5244
  },
  {
    "cityId": 2941,
    "cityName": "Cláudio Manuel",
    "stateId": 5244
  },
  {
    "cityId": 2942,
    "cityName": "Cocais",
    "stateId": 5244
  },
  {
    "cityId": 2943,
    "cityName": "Coco",
    "stateId": 5244
  },
  {
    "cityId": 2944,
    "cityName": "Coimbra",
    "stateId": 5244
  },
  {
    "cityId": 2945,
    "cityName": "Coluna",
    "stateId": 5244
  },
  {
    "cityId": 2946,
    "cityName": "Comendador Gomes",
    "stateId": 5244
  },
  {
    "cityId": 2947,
    "cityName": "Comercinho",
    "stateId": 5244
  },
  {
    "cityId": 2948,
    "cityName": "Conceição da Aparecida",
    "stateId": 5244
  },
  {
    "cityId": 2949,
    "cityName": "Conceição da Barra de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2950,
    "cityName": "Conceição da Boa Vista",
    "stateId": 5244
  },
  {
    "cityId": 2951,
    "cityName": "Conceição da Brejaúba",
    "stateId": 5244
  },
  {
    "cityId": 2952,
    "cityName": "Conceição da Ibitipoca",
    "stateId": 5244
  },
  {
    "cityId": 2953,
    "cityName": "Conceição das Alagoas",
    "stateId": 5244
  },
  {
    "cityId": 2954,
    "cityName": "Conceição das Pedras",
    "stateId": 5244
  },
  {
    "cityId": 2955,
    "cityName": "Conceição de Ipanema",
    "stateId": 5244
  },
  {
    "cityId": 2956,
    "cityName": "Conceição de Itaguá",
    "stateId": 5244
  },
  {
    "cityId": 2957,
    "cityName": "Conceição de Minas",
    "stateId": 5244
  },
  {
    "cityId": 2958,
    "cityName": "Conceição de Piracicaba",
    "stateId": 5244
  },
  {
    "cityId": 2959,
    "cityName": "Conceição de Tronqueiras",
    "stateId": 5244
  },
  {
    "cityId": 2960,
    "cityName": "Conceição do Capim",
    "stateId": 5244
  },
  {
    "cityId": 2961,
    "cityName": "Conceição do Formoso",
    "stateId": 5244
  },
  {
    "cityId": 2962,
    "cityName": "Conceição do Mato Dentro",
    "stateId": 5244
  },
  {
    "cityId": 2963,
    "cityName": "Conceição do Pará",
    "stateId": 5244
  },
  {
    "cityId": 2964,
    "cityName": "Conceição do Rio Acima",
    "stateId": 5244
  },
  {
    "cityId": 2965,
    "cityName": "Conceição do Rio Verde",
    "stateId": 5244
  },
  {
    "cityId": 2966,
    "cityName": "Conceição dos Ouros",
    "stateId": 5244
  },
  {
    "cityId": 2967,
    "cityName": "Concórdia de Mucuri",
    "stateId": 5244
  },
  {
    "cityId": 2968,
    "cityName": "Condado do Norte",
    "stateId": 5244
  },
  {
    "cityId": 2969,
    "cityName": "Cônego João Pio",
    "stateId": 5244
  },
  {
    "cityId": 2970,
    "cityName": "Cônego Marinho",
    "stateId": 5244
  },
  {
    "cityId": 2971,
    "cityName": "Confins",
    "stateId": 5244
  },
  {
    "cityId": 2972,
    "cityName": "Congonhal",
    "stateId": 5244
  },
  {
    "cityId": 2973,
    "cityName": "Congonhas",
    "stateId": 5244
  },
  {
    "cityId": 2974,
    "cityName": "Congonhas do Norte",
    "stateId": 5244
  },
  {
    "cityId": 2975,
    "cityName": "Conquista",
    "stateId": 5244
  },
  {
    "cityId": 2976,
    "cityName": "Conselheiro Lafaiete",
    "stateId": 5244
  },
  {
    "cityId": 2977,
    "cityName": "Conselheiro Mata",
    "stateId": 5244
  },
  {
    "cityId": 2978,
    "cityName": "Conselheiro Pena",
    "stateId": 5244
  },
  {
    "cityId": 2979,
    "cityName": "Consolação",
    "stateId": 5244
  },
  {
    "cityId": 2980,
    "cityName": "Contagem",
    "stateId": 5244
  },
  {
    "cityId": 2981,
    "cityName": "Contrato",
    "stateId": 5244
  },
  {
    "cityId": 2982,
    "cityName": "Contria",
    "stateId": 5244
  },
  {
    "cityId": 2983,
    "cityName": "Coqueiral",
    "stateId": 5244
  },
  {
    "cityId": 2984,
    "cityName": "Coração de Jesus",
    "stateId": 5244
  },
  {
    "cityId": 2985,
    "cityName": "Cordisburgo",
    "stateId": 5244
  },
  {
    "cityId": 2986,
    "cityName": "Cordislândia",
    "stateId": 5244
  },
  {
    "cityId": 2987,
    "cityName": "Corinto",
    "stateId": 5244
  },
  {
    "cityId": 2988,
    "cityName": "Coroaci",
    "stateId": 5244
  },
  {
    "cityId": 2989,
    "cityName": "Coromandel",
    "stateId": 5244
  },
  {
    "cityId": 2990,
    "cityName": "Coronel Fabriciano",
    "stateId": 5244
  },
  {
    "cityId": 2991,
    "cityName": "Coronel Murta",
    "stateId": 5244
  },
  {
    "cityId": 2992,
    "cityName": "Coronel Pacheco",
    "stateId": 5244
  },
  {
    "cityId": 2993,
    "cityName": "Coronel Xavier Chaves",
    "stateId": 5244
  },
  {
    "cityId": 2994,
    "cityName": "Córrego Danta",
    "stateId": 5244
  },
  {
    "cityId": 2995,
    "cityName": "Córrego do Barro",
    "stateId": 5244
  },
  {
    "cityId": 2996,
    "cityName": "Córrego do Bom Jesus",
    "stateId": 5244
  },
  {
    "cityId": 2997,
    "cityName": "Córrego do Ouro",
    "stateId": 5244
  },
  {
    "cityId": 2998,
    "cityName": "Córrego Fundo",
    "stateId": 5244
  },
  {
    "cityId": 2999,
    "cityName": "Córrego Novo",
    "stateId": 5244
  },
  {
    "cityId": 3000,
    "cityName": "Córregos",
    "stateId": 5244
  },
  {
    "cityId": 3001,
    "cityName": "Correia de Almeida",
    "stateId": 5244
  },
  {
    "cityId": 3002,
    "cityName": "Correntinho",
    "stateId": 5244
  },
  {
    "cityId": 3003,
    "cityName": "Costa Sena",
    "stateId": 5244
  },
  {
    "cityId": 3004,
    "cityName": "Costas",
    "stateId": 5244
  },
  {
    "cityId": 3005,
    "cityName": "Costas da Mantiqueira",
    "stateId": 5244
  },
  {
    "cityId": 3006,
    "cityName": "Couto de Magalhães de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3007,
    "cityName": "Crisólia",
    "stateId": 5244
  },
  {
    "cityId": 3008,
    "cityName": "Crisólita",
    "stateId": 5244
  },
  {
    "cityId": 3009,
    "cityName": "Crispim Jaques",
    "stateId": 5244
  },
  {
    "cityId": 3010,
    "cityName": "Cristais",
    "stateId": 5244
  },
  {
    "cityId": 3011,
    "cityName": "Cristália",
    "stateId": 5244
  },
  {
    "cityId": 3012,
    "cityName": "Cristiano Otoni",
    "stateId": 5244
  },
  {
    "cityId": 3013,
    "cityName": "Cristina",
    "stateId": 5244
  },
  {
    "cityId": 3014,
    "cityName": "Crucilândia",
    "stateId": 5244
  },
  {
    "cityId": 3015,
    "cityName": "Cruzeiro da Fortaleza",
    "stateId": 5244
  },
  {
    "cityId": 3016,
    "cityName": "Cruzeiro dos Peixotos",
    "stateId": 5244
  },
  {
    "cityId": 3017,
    "cityName": "Cruzília",
    "stateId": 5244
  },
  {
    "cityId": 3018,
    "cityName": "Cubas",
    "stateId": 5244
  },
  {
    "cityId": 3019,
    "cityName": "Cuité Velho",
    "stateId": 5244
  },
  {
    "cityId": 3020,
    "cityName": "Cuparaque",
    "stateId": 5244
  },
  {
    "cityId": 3021,
    "cityName": "Curimataí",
    "stateId": 5244
  },
  {
    "cityId": 3022,
    "cityName": "Curral de Dentro",
    "stateId": 5244
  },
  {
    "cityId": 3023,
    "cityName": "Curvelo",
    "stateId": 5244
  },
  {
    "cityId": 3024,
    "cityName": "Datas",
    "stateId": 5244
  },
  {
    "cityId": 3025,
    "cityName": "Delfim Moreira",
    "stateId": 5244
  },
  {
    "cityId": 3026,
    "cityName": "Delfinópolis",
    "stateId": 5244
  },
  {
    "cityId": 3027,
    "cityName": "Delta",
    "stateId": 5244
  },
  {
    "cityId": 3028,
    "cityName": "Deputado Augusto Clementino",
    "stateId": 5244
  },
  {
    "cityId": 3029,
    "cityName": "Derribadinha",
    "stateId": 5244
  },
  {
    "cityId": 3030,
    "cityName": "Descoberto",
    "stateId": 5244
  },
  {
    "cityId": 3031,
    "cityName": "Desembargador Otoni",
    "stateId": 5244
  },
  {
    "cityId": 3032,
    "cityName": "Desemboque",
    "stateId": 5244
  },
  {
    "cityId": 3033,
    "cityName": "Desterro de Entre Rios",
    "stateId": 5244
  },
  {
    "cityId": 3034,
    "cityName": "Desterro do Melo",
    "stateId": 5244
  },
  {
    "cityId": 3035,
    "cityName": "Diamante de Ubá",
    "stateId": 5244
  },
  {
    "cityId": 3036,
    "cityName": "Diamantina",
    "stateId": 5244
  },
  {
    "cityId": 3037,
    "cityName": "Dias",
    "stateId": 5244
  },
  {
    "cityId": 3038,
    "cityName": "Dias Tavares",
    "stateId": 5244
  },
  {
    "cityId": 3039,
    "cityName": "Diogo de Vasconcelos",
    "stateId": 5244
  },
  {
    "cityId": 3040,
    "cityName": "Dionísio",
    "stateId": 5244
  },
  {
    "cityId": 3041,
    "cityName": "Divinésia",
    "stateId": 5244
  },
  {
    "cityId": 3042,
    "cityName": "Divino",
    "stateId": 5244
  },
  {
    "cityId": 3043,
    "cityName": "Divino das Laranjeiras",
    "stateId": 5244
  },
  {
    "cityId": 3044,
    "cityName": "Divino de Virgolândia",
    "stateId": 5244
  },
  {
    "cityId": 3045,
    "cityName": "Divino Espírito Santo",
    "stateId": 5244
  },
  {
    "cityId": 3046,
    "cityName": "Divinolândia de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3047,
    "cityName": "Divinópolis",
    "stateId": 5244
  },
  {
    "cityId": 3048,
    "cityName": "Divisa Alegre",
    "stateId": 5244
  },
  {
    "cityId": 3049,
    "cityName": "Divisa Nova",
    "stateId": 5244
  },
  {
    "cityId": 3050,
    "cityName": "Divisópolis",
    "stateId": 5244
  },
  {
    "cityId": 3051,
    "cityName": "Dois de Abril",
    "stateId": 5244
  },
  {
    "cityId": 3052,
    "cityName": "Dom Bosco",
    "stateId": 5244
  },
  {
    "cityId": 3053,
    "cityName": "Dom Cavati",
    "stateId": 5244
  },
  {
    "cityId": 3054,
    "cityName": "Dom Joaquim",
    "stateId": 5244
  },
  {
    "cityId": 3055,
    "cityName": "Dom Lara",
    "stateId": 5244
  },
  {
    "cityId": 3056,
    "cityName": "Dom Modesto",
    "stateId": 5244
  },
  {
    "cityId": 3057,
    "cityName": "Dom Silvério",
    "stateId": 5244
  },
  {
    "cityId": 3058,
    "cityName": "Dom Viçoso",
    "stateId": 5244
  },
  {
    "cityId": 3059,
    "cityName": "Dona Euzébia",
    "stateId": 5244
  },
  {
    "cityId": 3060,
    "cityName": "Dores da Vitória",
    "stateId": 5244
  },
  {
    "cityId": 3061,
    "cityName": "Dores de Campos",
    "stateId": 5244
  },
  {
    "cityId": 3062,
    "cityName": "Dores de Guanhães",
    "stateId": 5244
  },
  {
    "cityId": 3063,
    "cityName": "Dores do Indaiá",
    "stateId": 5244
  },
  {
    "cityId": 3064,
    "cityName": "Dores do Paraibuna",
    "stateId": 5244
  },
  {
    "cityId": 3065,
    "cityName": "Dores do Turvo",
    "stateId": 5244
  },
  {
    "cityId": 3066,
    "cityName": "Doresópolis",
    "stateId": 5244
  },
  {
    "cityId": 3067,
    "cityName": "Douradinho",
    "stateId": 5244
  },
  {
    "cityId": 3068,
    "cityName": "Douradoquara",
    "stateId": 5244
  },
  {
    "cityId": 3069,
    "cityName": "Doutor Campolina",
    "stateId": 5244
  },
  {
    "cityId": 3070,
    "cityName": "Doutor Lund",
    "stateId": 5244
  },
  {
    "cityId": 3071,
    "cityName": "Durandé",
    "stateId": 5244
  },
  {
    "cityId": 3072,
    "cityName": "Edgard Melo",
    "stateId": 5244
  },
  {
    "cityId": 3073,
    "cityName": "Elói Mendes",
    "stateId": 5244
  },
  {
    "cityId": 3074,
    "cityName": "Emboabas",
    "stateId": 5244
  },
  {
    "cityId": 3075,
    "cityName": "Engenheiro Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3076,
    "cityName": "Engenheiro Correia",
    "stateId": 5244
  },
  {
    "cityId": 3077,
    "cityName": "Engenheiro Navarro",
    "stateId": 5244
  },
  {
    "cityId": 3078,
    "cityName": "Engenheiro Schnoor",
    "stateId": 5244
  },
  {
    "cityId": 3079,
    "cityName": "Engenho do Ribeiro",
    "stateId": 5244
  },
  {
    "cityId": 3080,
    "cityName": "Engenho Novo",
    "stateId": 5244
  },
  {
    "cityId": 3081,
    "cityName": "Entre Folhas",
    "stateId": 5244
  },
  {
    "cityId": 3082,
    "cityName": "Entre Rios de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3083,
    "cityName": "Epaminondas Otoni",
    "stateId": 5244
  },
  {
    "cityId": 3084,
    "cityName": "Ermidinha",
    "stateId": 5244
  },
  {
    "cityId": 3085,
    "cityName": "Ervália",
    "stateId": 5244
  },
  {
    "cityId": 3086,
    "cityName": "Esmeraldas",
    "stateId": 5244
  },
  {
    "cityId": 3087,
    "cityName": "Esmeraldas de Ferros",
    "stateId": 5244
  },
  {
    "cityId": 3088,
    "cityName": "Espera Feliz",
    "stateId": 5244
  },
  {
    "cityId": 3089,
    "cityName": "Espinosa",
    "stateId": 5244
  },
  {
    "cityId": 3090,
    "cityName": "Espírito Santo do Dourado",
    "stateId": 5244
  },
  {
    "cityId": 3091,
    "cityName": "Esteios",
    "stateId": 5244
  },
  {
    "cityId": 3092,
    "cityName": "Estevão de Araújo",
    "stateId": 5244
  },
  {
    "cityId": 3093,
    "cityName": "Estiva",
    "stateId": 5244
  },
  {
    "cityId": 3094,
    "cityName": "Estrela da Barra",
    "stateId": 5244
  },
  {
    "cityId": 3095,
    "cityName": "Estrela Dalva",
    "stateId": 5244
  },
  {
    "cityId": 3096,
    "cityName": "Estrela de Jordânia",
    "stateId": 5244
  },
  {
    "cityId": 3097,
    "cityName": "Estrela do Indaiá",
    "stateId": 5244
  },
  {
    "cityId": 3098,
    "cityName": "Estrela do Sul",
    "stateId": 5244
  },
  {
    "cityId": 3099,
    "cityName": "Eugenópolis",
    "stateId": 5244
  },
  {
    "cityId": 3100,
    "cityName": "Euxenita",
    "stateId": 5244
  },
  {
    "cityId": 3101,
    "cityName": "Ewbank da Câmara",
    "stateId": 5244
  },
  {
    "cityId": 3102,
    "cityName": "Expedicionário Alício",
    "stateId": 5244
  },
  {
    "cityId": 3103,
    "cityName": "Extração",
    "stateId": 5244
  },
  {
    "cityId": 3104,
    "cityName": "Extrema",
    "stateId": 5244
  },
  {
    "cityId": 3105,
    "cityName": "Fama",
    "stateId": 5244
  },
  {
    "cityId": 3106,
    "cityName": "Faria Lemos",
    "stateId": 5244
  },
  {
    "cityId": 3107,
    "cityName": "Farias",
    "stateId": 5244
  },
  {
    "cityId": 3108,
    "cityName": "Fechados",
    "stateId": 5244
  },
  {
    "cityId": 3109,
    "cityName": "Felicina",
    "stateId": 5244
  },
  {
    "cityId": 3110,
    "cityName": "Felício dos Santos",
    "stateId": 5244
  },
  {
    "cityId": 3111,
    "cityName": "Felisburgo",
    "stateId": 5244
  },
  {
    "cityId": 3112,
    "cityName": "Felixlândia",
    "stateId": 5244
  },
  {
    "cityId": 3113,
    "cityName": "Fernandes Tourinho",
    "stateId": 5244
  },
  {
    "cityId": 3114,
    "cityName": "Fernão Dias",
    "stateId": 5244
  },
  {
    "cityId": 3115,
    "cityName": "Ferreiras",
    "stateId": 5244
  },
  {
    "cityId": 3116,
    "cityName": "Ferreirópolis",
    "stateId": 5244
  },
  {
    "cityId": 3117,
    "cityName": "Ferros",
    "stateId": 5244
  },
  {
    "cityId": 3118,
    "cityName": "Ferruginha",
    "stateId": 5244
  },
  {
    "cityId": 3119,
    "cityName": "Fervedouro",
    "stateId": 5244
  },
  {
    "cityId": 3120,
    "cityName": "Fidalgo",
    "stateId": 5244
  },
  {
    "cityId": 3121,
    "cityName": "Fidelândia",
    "stateId": 5244
  },
  {
    "cityId": 3122,
    "cityName": "Flor de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3123,
    "cityName": "Florália",
    "stateId": 5244
  },
  {
    "cityId": 3124,
    "cityName": "Floresta",
    "stateId": 5244
  },
  {
    "cityId": 3125,
    "cityName": "Florestal",
    "stateId": 5244
  },
  {
    "cityId": 3126,
    "cityName": "Florestina",
    "stateId": 5244
  },
  {
    "cityId": 3127,
    "cityName": "Fonseca",
    "stateId": 5244
  },
  {
    "cityId": 3128,
    "cityName": "Formiga",
    "stateId": 5244
  },
  {
    "cityId": 3129,
    "cityName": "Formoso",
    "stateId": 5244
  },
  {
    "cityId": 3130,
    "cityName": "Fortaleza de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3131,
    "cityName": "Fortuna de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3132,
    "cityName": "Francisco Badaró",
    "stateId": 5244
  },
  {
    "cityId": 3133,
    "cityName": "Francisco Dumont",
    "stateId": 5244
  },
  {
    "cityId": 3134,
    "cityName": "Francisco Sá",
    "stateId": 5244
  },
  {
    "cityId": 3135,
    "cityName": "Franciscópolis",
    "stateId": 5244
  },
  {
    "cityId": 3136,
    "cityName": "Frei Eustáquio",
    "stateId": 5244
  },
  {
    "cityId": 3137,
    "cityName": "Frei Gaspar",
    "stateId": 5244
  },
  {
    "cityId": 3138,
    "cityName": "Frei Inocêncio",
    "stateId": 5244
  },
  {
    "cityId": 3139,
    "cityName": "Frei Lagonegro",
    "stateId": 5244
  },
  {
    "cityId": 3140,
    "cityName": "Frei Orlando",
    "stateId": 5244
  },
  {
    "cityId": 3141,
    "cityName": "Frei Serafim",
    "stateId": 5244
  },
  {
    "cityId": 3142,
    "cityName": "Freire Cardoso",
    "stateId": 5244
  },
  {
    "cityId": 3143,
    "cityName": "Fronteira",
    "stateId": 5244
  },
  {
    "cityId": 3144,
    "cityName": "Fronteira dos Vales",
    "stateId": 5244
  },
  {
    "cityId": 3145,
    "cityName": "Fruta de Leite",
    "stateId": 5244
  },
  {
    "cityId": 3146,
    "cityName": "Frutal",
    "stateId": 5244
  },
  {
    "cityId": 3147,
    "cityName": "Funchal",
    "stateId": 5244
  },
  {
    "cityId": 3148,
    "cityName": "Funilândia",
    "stateId": 5244
  },
  {
    "cityId": 3150,
    "cityName": "Furquim",
    "stateId": 5244
  },
  {
    "cityId": 3151,
    "cityName": "Galego",
    "stateId": 5244
  },
  {
    "cityId": 3152,
    "cityName": "Galena",
    "stateId": 5244
  },
  {
    "cityId": 3153,
    "cityName": "Galiléia",
    "stateId": 5244
  },
  {
    "cityId": 3154,
    "cityName": "Gama",
    "stateId": 5244
  },
  {
    "cityId": 3155,
    "cityName": "Gameleiras",
    "stateId": 5244
  },
  {
    "cityId": 3156,
    "cityName": "Garapuava",
    "stateId": 5244
  },
  {
    "cityId": 3157,
    "cityName": "Gavião",
    "stateId": 5244
  },
  {
    "cityId": 3158,
    "cityName": "Glaucilândia",
    "stateId": 5244
  },
  {
    "cityId": 3159,
    "cityName": "Glaura",
    "stateId": 5244
  },
  {
    "cityId": 3160,
    "cityName": "Glucínio",
    "stateId": 5244
  },
  {
    "cityId": 3161,
    "cityName": "Goiabeira",
    "stateId": 5244
  },
  {
    "cityId": 3162,
    "cityName": "Goianá",
    "stateId": 5244
  },
  {
    "cityId": 3163,
    "cityName": "Goianases",
    "stateId": 5244
  },
  {
    "cityId": 3164,
    "cityName": "Gonçalves",
    "stateId": 5244
  },
  {
    "cityId": 3165,
    "cityName": "Gonzaga",
    "stateId": 5244
  },
  {
    "cityId": 3166,
    "cityName": "Gororós",
    "stateId": 5244
  },
  {
    "cityId": 3167,
    "cityName": "Gorutuba",
    "stateId": 5244
  },
  {
    "cityId": 3168,
    "cityName": "Gouvea",
    "stateId": 5244
  },
  {
    "cityId": 3169,
    "cityName": "Governador Valadares",
    "stateId": 5244
  },
  {
    "cityId": 3170,
    "cityName": "Gramínea",
    "stateId": 5244
  },
  {
    "cityId": 3171,
    "cityName": "Granada",
    "stateId": 5244
  },
  {
    "cityId": 3172,
    "cityName": "Grão Mogol",
    "stateId": 5244
  },
  {
    "cityId": 3173,
    "cityName": "Grota",
    "stateId": 5244
  },
  {
    "cityId": 3174,
    "cityName": "Grupiara",
    "stateId": 5244
  },
  {
    "cityId": 3175,
    "cityName": "Guaicui",
    "stateId": 5244
  },
  {
    "cityId": 3176,
    "cityName": "Guaipava",
    "stateId": 5244
  },
  {
    "cityId": 3177,
    "cityName": "Guanhães",
    "stateId": 5244
  },
  {
    "cityId": 3178,
    "cityName": "Guapé",
    "stateId": 5244
  },
  {
    "cityId": 3179,
    "cityName": "Guaraciaba",
    "stateId": 5244
  },
  {
    "cityId": 3180,
    "cityName": "Guaraciama",
    "stateId": 5244
  },
  {
    "cityId": 3181,
    "cityName": "Guaranésia",
    "stateId": 5244
  },
  {
    "cityId": 3182,
    "cityName": "Guarani",
    "stateId": 5244
  },
  {
    "cityId": 3183,
    "cityName": "Guaranilândia",
    "stateId": 5244
  },
  {
    "cityId": 3184,
    "cityName": "Guarará",
    "stateId": 5244
  },
  {
    "cityId": 3185,
    "cityName": "Guarataia",
    "stateId": 5244
  },
  {
    "cityId": 3186,
    "cityName": "Guarda dos Ferreiros",
    "stateId": 5244
  },
  {
    "cityId": 3187,
    "cityName": "Guarda-Mor",
    "stateId": 5244
  },
  {
    "cityId": 3188,
    "cityName": "Guardinha",
    "stateId": 5244
  },
  {
    "cityId": 3189,
    "cityName": "Guaxima",
    "stateId": 5244
  },
  {
    "cityId": 3190,
    "cityName": "Guaxupé",
    "stateId": 5244
  },
  {
    "cityId": 3191,
    "cityName": "Guidoval",
    "stateId": 5244
  },
  {
    "cityId": 3192,
    "cityName": "Guimarânia",
    "stateId": 5244
  },
  {
    "cityId": 3193,
    "cityName": "Guinda",
    "stateId": 5244
  },
  {
    "cityId": 3194,
    "cityName": "Guiricema",
    "stateId": 5244
  },
  {
    "cityId": 3195,
    "cityName": "Gurinhatã",
    "stateId": 5244
  },
  {
    "cityId": 3196,
    "cityName": "Heliodora",
    "stateId": 5244
  },
  {
    "cityId": 3197,
    "cityName": "Hematita",
    "stateId": 5244
  },
  {
    "cityId": 3198,
    "cityName": "Hermilo Alves",
    "stateId": 5244
  },
  {
    "cityId": 3199,
    "cityName": "Honorópolis",
    "stateId": 5244
  },
  {
    "cityId": 3200,
    "cityName": "Iapu",
    "stateId": 5244
  },
  {
    "cityId": 3201,
    "cityName": "Ibertioga",
    "stateId": 5244
  },
  {
    "cityId": 3202,
    "cityName": "Ibiá",
    "stateId": 5244
  },
  {
    "cityId": 3203,
    "cityName": "Ibiaí",
    "stateId": 5244
  },
  {
    "cityId": 3204,
    "cityName": "Ibiracatu",
    "stateId": 5244
  },
  {
    "cityId": 3205,
    "cityName": "Ibiraci",
    "stateId": 5244
  },
  {
    "cityId": 3206,
    "cityName": "Ibirité",
    "stateId": 5244
  },
  {
    "cityId": 3207,
    "cityName": "Ibitira",
    "stateId": 5244
  },
  {
    "cityId": 3208,
    "cityName": "Ibitiúra de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3209,
    "cityName": "Ibituruna",
    "stateId": 5244
  },
  {
    "cityId": 3210,
    "cityName": "Icaraí de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3211,
    "cityName": "Igarapé",
    "stateId": 5244
  },
  {
    "cityId": 3212,
    "cityName": "Igaratinga",
    "stateId": 5244
  },
  {
    "cityId": 3213,
    "cityName": "Iguatama",
    "stateId": 5244
  },
  {
    "cityId": 3214,
    "cityName": "Ijaci",
    "stateId": 5244
  },
  {
    "cityId": 3215,
    "cityName": "Ilhéus do Prata",
    "stateId": 5244
  },
  {
    "cityId": 3216,
    "cityName": "Ilicínea",
    "stateId": 5244
  },
  {
    "cityId": 3217,
    "cityName": "Imbé de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3218,
    "cityName": "Inconfidentes",
    "stateId": 5244
  },
  {
    "cityId": 3219,
    "cityName": "Indaiabira",
    "stateId": 5244
  },
  {
    "cityId": 3220,
    "cityName": "Independência",
    "stateId": 5244
  },
  {
    "cityId": 3221,
    "cityName": "Indianópolis",
    "stateId": 5244
  },
  {
    "cityId": 3222,
    "cityName": "Ingaí",
    "stateId": 5244
  },
  {
    "cityId": 3223,
    "cityName": "Inhaí",
    "stateId": 5244
  },
  {
    "cityId": 3224,
    "cityName": "Inhapim",
    "stateId": 5244
  },
  {
    "cityId": 3225,
    "cityName": "Inhaúma",
    "stateId": 5244
  },
  {
    "cityId": 3226,
    "cityName": "Inimutaba",
    "stateId": 5244
  },
  {
    "cityId": 3227,
    "cityName": "Ipaba",
    "stateId": 5244
  },
  {
    "cityId": 3228,
    "cityName": "Ipanema",
    "stateId": 5244
  },
  {
    "cityId": 3229,
    "cityName": "Ipatinga",
    "stateId": 5244
  },
  {
    "cityId": 3230,
    "cityName": "Ipiaçu",
    "stateId": 5244
  },
  {
    "cityId": 3231,
    "cityName": "Ipoema",
    "stateId": 5244
  },
  {
    "cityId": 3232,
    "cityName": "Ipuiúna",
    "stateId": 5244
  },
  {
    "cityId": 3233,
    "cityName": "Iraí de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3234,
    "cityName": "Itabira",
    "stateId": 5244
  },
  {
    "cityId": 3235,
    "cityName": "Itabirinha",
    "stateId": 5244
  },
  {
    "cityId": 3236,
    "cityName": "Itabirito",
    "stateId": 5244
  },
  {
    "cityId": 3237,
    "cityName": "Itaboca",
    "stateId": 5244
  },
  {
    "cityId": 3238,
    "cityName": "Itacambira",
    "stateId": 5244
  },
  {
    "cityId": 3239,
    "cityName": "Itacarambi",
    "stateId": 5244
  },
  {
    "cityId": 3240,
    "cityName": "Itaci",
    "stateId": 5244
  },
  {
    "cityId": 3241,
    "cityName": "Itacolomi",
    "stateId": 5244
  },
  {
    "cityId": 3242,
    "cityName": "Itaguara",
    "stateId": 5244
  },
  {
    "cityId": 3243,
    "cityName": "Itaim",
    "stateId": 5244
  },
  {
    "cityId": 3244,
    "cityName": "Itaipé",
    "stateId": 5244
  },
  {
    "cityId": 3245,
    "cityName": "Itajubá",
    "stateId": 5244
  },
  {
    "cityId": 3246,
    "cityName": "Itajutiba",
    "stateId": 5244
  },
  {
    "cityId": 3247,
    "cityName": "Itamarandiba",
    "stateId": 5244
  },
  {
    "cityId": 3248,
    "cityName": "Itamarati",
    "stateId": 5244
  },
  {
    "cityId": 3249,
    "cityName": "Itamarati de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3250,
    "cityName": "Itambacuri",
    "stateId": 5244
  },
  {
    "cityId": 3251,
    "cityName": "Itambé do Mato Dentro",
    "stateId": 5244
  },
  {
    "cityId": 3252,
    "cityName": "Itamirim",
    "stateId": 5244
  },
  {
    "cityId": 3253,
    "cityName": "Itamogi",
    "stateId": 5244
  },
  {
    "cityId": 3254,
    "cityName": "Itamonte",
    "stateId": 5244
  },
  {
    "cityId": 3255,
    "cityName": "Itamuri",
    "stateId": 5244
  },
  {
    "cityId": 3256,
    "cityName": "Itanhandu",
    "stateId": 5244
  },
  {
    "cityId": 3257,
    "cityName": "Itanhomi",
    "stateId": 5244
  },
  {
    "cityId": 3258,
    "cityName": "Itaobim",
    "stateId": 5244
  },
  {
    "cityId": 3259,
    "cityName": "Itapagipe",
    "stateId": 5244
  },
  {
    "cityId": 3260,
    "cityName": "Itapanhoacanga",
    "stateId": 5244
  },
  {
    "cityId": 3261,
    "cityName": "Itapecerica",
    "stateId": 5244
  },
  {
    "cityId": 3262,
    "cityName": "Itapeva",
    "stateId": 5244
  },
  {
    "cityId": 3263,
    "cityName": "Itapiru",
    "stateId": 5244
  },
  {
    "cityId": 3264,
    "cityName": "Itapirucu",
    "stateId": 5244
  },
  {
    "cityId": 3265,
    "cityName": "Itatiaiuçu",
    "stateId": 5244
  },
  {
    "cityId": 3266,
    "cityName": "Itaú de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3267,
    "cityName": "Itaúna",
    "stateId": 5244
  },
  {
    "cityId": 3268,
    "cityName": "Itauninha",
    "stateId": 5244
  },
  {
    "cityId": 3269,
    "cityName": "Itaverava",
    "stateId": 5244
  },
  {
    "cityId": 3270,
    "cityName": "Itererê",
    "stateId": 5244
  },
  {
    "cityId": 3271,
    "cityName": "Itinga",
    "stateId": 5244
  },
  {
    "cityId": 3272,
    "cityName": "Itira",
    "stateId": 5244
  },
  {
    "cityId": 3273,
    "cityName": "Itueta",
    "stateId": 5244
  },
  {
    "cityId": 3274,
    "cityName": "Ituí",
    "stateId": 5244
  },
  {
    "cityId": 3275,
    "cityName": "Ituiutaba",
    "stateId": 5244
  },
  {
    "cityId": 3276,
    "cityName": "Itumirim",
    "stateId": 5244
  },
  {
    "cityId": 3277,
    "cityName": "Iturama",
    "stateId": 5244
  },
  {
    "cityId": 3278,
    "cityName": "Itutinga",
    "stateId": 5244
  },
  {
    "cityId": 3279,
    "cityName": "Jaboticatubas",
    "stateId": 5244
  },
  {
    "cityId": 3280,
    "cityName": "Jacarandira",
    "stateId": 5244
  },
  {
    "cityId": 3281,
    "cityName": "Jacaré",
    "stateId": 5244
  },
  {
    "cityId": 3282,
    "cityName": "Jacinto",
    "stateId": 5244
  },
  {
    "cityId": 3283,
    "cityName": "Jacuí",
    "stateId": 5244
  },
  {
    "cityId": 3284,
    "cityName": "Jacutinga",
    "stateId": 5244
  },
  {
    "cityId": 3285,
    "cityName": "Jaguaraçu",
    "stateId": 5244
  },
  {
    "cityId": 3286,
    "cityName": "Jaguarão",
    "stateId": 5244
  },
  {
    "cityId": 3287,
    "cityName": "Jaguaritira",
    "stateId": 5244
  },
  {
    "cityId": 3288,
    "cityName": "Jaíba",
    "stateId": 5244
  },
  {
    "cityId": 3289,
    "cityName": "Jampruca",
    "stateId": 5244
  },
  {
    "cityId": 3290,
    "cityName": "Janaúba",
    "stateId": 5244
  },
  {
    "cityId": 3291,
    "cityName": "Januária",
    "stateId": 5244
  },
  {
    "cityId": 3292,
    "cityName": "Japaraíba",
    "stateId": 5244
  },
  {
    "cityId": 3293,
    "cityName": "Japonvar",
    "stateId": 5244
  },
  {
    "cityId": 3294,
    "cityName": "Jardinésia",
    "stateId": 5244
  },
  {
    "cityId": 3295,
    "cityName": "Jeceaba",
    "stateId": 5244
  },
  {
    "cityId": 3296,
    "cityName": "Jenipapo de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3297,
    "cityName": "Jequeri",
    "stateId": 5244
  },
  {
    "cityId": 3298,
    "cityName": "Jequitaí",
    "stateId": 5244
  },
  {
    "cityId": 3299,
    "cityName": "Jequitibá",
    "stateId": 5244
  },
  {
    "cityId": 3300,
    "cityName": "Jequitinhonha",
    "stateId": 5244
  },
  {
    "cityId": 3301,
    "cityName": "Jesuânia",
    "stateId": 5244
  },
  {
    "cityId": 3302,
    "cityName": "Joaíma",
    "stateId": 5244
  },
  {
    "cityId": 3303,
    "cityName": "Joanésia",
    "stateId": 5244
  },
  {
    "cityId": 3304,
    "cityName": "João Monlevade",
    "stateId": 5244
  },
  {
    "cityId": 3305,
    "cityName": "João Pinheiro",
    "stateId": 5244
  },
  {
    "cityId": 3306,
    "cityName": "Joaquim Felício",
    "stateId": 5244
  },
  {
    "cityId": 3307,
    "cityName": "Jordânia",
    "stateId": 5244
  },
  {
    "cityId": 3308,
    "cityName": "José Gonçalves de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3309,
    "cityName": "José Raydan",
    "stateId": 5244
  },
  {
    "cityId": 3310,
    "cityName": "Joselândia",
    "stateId": 5244
  },
  {
    "cityId": 3311,
    "cityName": "Josenópolis",
    "stateId": 5244
  },
  {
    "cityId": 3312,
    "cityName": "Juatuba",
    "stateId": 5244
  },
  {
    "cityId": 3313,
    "cityName": "Jubaí",
    "stateId": 5244
  },
  {
    "cityId": 3314,
    "cityName": "Juiraçu",
    "stateId": 5244
  },
  {
    "cityId": 3315,
    "cityName": "Juiz de Fora",
    "stateId": 5244
  },
  {
    "cityId": 3316,
    "cityName": "Junco de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3317,
    "cityName": "Juramento",
    "stateId": 5244
  },
  {
    "cityId": 3318,
    "cityName": "Juréia",
    "stateId": 5244
  },
  {
    "cityId": 3319,
    "cityName": "Juruaia",
    "stateId": 5244
  },
  {
    "cityId": 3320,
    "cityName": "Jurumirim",
    "stateId": 5244
  },
  {
    "cityId": 3321,
    "cityName": "Justinópolis",
    "stateId": 5244
  },
  {
    "cityId": 3322,
    "cityName": "Juvenília",
    "stateId": 5244
  },
  {
    "cityId": 3323,
    "cityName": "Lacerdinha",
    "stateId": 5244
  },
  {
    "cityId": 3324,
    "cityName": "Ladainha",
    "stateId": 5244
  },
  {
    "cityId": 3325,
    "cityName": "Lagamar",
    "stateId": 5244
  },
  {
    "cityId": 3326,
    "cityName": "Lagoa Bonita",
    "stateId": 5244
  },
  {
    "cityId": 3327,
    "cityName": "Lagoa da Prata",
    "stateId": 5244
  },
  {
    "cityId": 3328,
    "cityName": "Lagoa dos Patos",
    "stateId": 5244
  },
  {
    "cityId": 3329,
    "cityName": "Lagoa Dourada",
    "stateId": 5244
  },
  {
    "cityId": 3330,
    "cityName": "Lagoa Formosa",
    "stateId": 5244
  },
  {
    "cityId": 3331,
    "cityName": "Lagoa Grande",
    "stateId": 5244
  },
  {
    "cityId": 3332,
    "cityName": "Lagoa Santa",
    "stateId": 5244
  },
  {
    "cityId": 3333,
    "cityName": "Lajinha",
    "stateId": 5244
  },
  {
    "cityId": 3334,
    "cityName": "Lambari",
    "stateId": 5244
  },
  {
    "cityId": 3335,
    "cityName": "Lamim",
    "stateId": 5244
  },
  {
    "cityId": 3336,
    "cityName": "Lamounier",
    "stateId": 5244
  },
  {
    "cityId": 3337,
    "cityName": "Lapinha",
    "stateId": 5244
  },
  {
    "cityId": 3338,
    "cityName": "Laranjal",
    "stateId": 5244
  },
  {
    "cityId": 3339,
    "cityName": "Laranjeiras de Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3340,
    "cityName": "Lassance",
    "stateId": 5244
  },
  {
    "cityId": 3341,
    "cityName": "Lavras",
    "stateId": 5244
  },
  {
    "cityId": 3342,
    "cityName": "Leandro Ferreira",
    "stateId": 5244
  },
  {
    "cityId": 3343,
    "cityName": "Leme do Prado",
    "stateId": 5244
  },
  {
    "cityId": 3344,
    "cityName": "Leopoldina",
    "stateId": 5244
  },
  {
    "cityId": 3345,
    "cityName": "Levinópolis",
    "stateId": 5244
  },
  {
    "cityId": 3346,
    "cityName": "Liberdade",
    "stateId": 5244
  },
  {
    "cityId": 3347,
    "cityName": "Lima Duarte",
    "stateId": 5244
  },
  {
    "cityId": 3348,
    "cityName": "Limeira do Oeste",
    "stateId": 5244
  },
  {
    "cityId": 3349,
    "cityName": "Limeira de Mantena",
    "stateId": 5244
  },
  {
    "cityId": 3350,
    "cityName": "Lobo Leite",
    "stateId": 5244
  },
  {
    "cityId": 3351,
    "cityName": "Lontra",
    "stateId": 5244
  },
  {
    "cityId": 3352,
    "cityName": "Lourenço Velho",
    "stateId": 5244
  },
  {
    "cityId": 3353,
    "cityName": "Lufa",
    "stateId": 5244
  },
  {
    "cityId": 3354,
    "cityName": "Luisburgo",
    "stateId": 5244
  },
  {
    "cityId": 3355,
    "cityName": "Luislândia",
    "stateId": 5244
  },
  {
    "cityId": 3356,
    "cityName": "Luiz Pires de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3357,
    "cityName": "Luizlândia do Oeste",
    "stateId": 5244
  },
  {
    "cityId": 3358,
    "cityName": "Luminárias",
    "stateId": 5244
  },
  {
    "cityId": 3359,
    "cityName": "Luminosa",
    "stateId": 5244
  },
  {
    "cityId": 3360,
    "cityName": "Luz",
    "stateId": 5244
  },
  {
    "cityId": 3361,
    "cityName": "Macaia",
    "stateId": 5244
  },
  {
    "cityId": 3362,
    "cityName": "Machacalis",
    "stateId": 5244
  },
  {
    "cityId": 3363,
    "cityName": "Machado",
    "stateId": 5244
  },
  {
    "cityId": 3364,
    "cityName": "Macuco",
    "stateId": 5244
  },
  {
    "cityId": 3365,
    "cityName": "Macuco de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3366,
    "cityName": "Madre de Deus de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3367,
    "cityName": "Mãe dos Homens",
    "stateId": 5244
  },
  {
    "cityId": 3368,
    "cityName": "Major Ezequiel",
    "stateId": 5244
  },
  {
    "cityId": 3369,
    "cityName": "Major Porto",
    "stateId": 5244
  },
  {
    "cityId": 3370,
    "cityName": "Malacacheta",
    "stateId": 5244
  },
  {
    "cityId": 3371,
    "cityName": "Mamonas",
    "stateId": 5244
  },
  {
    "cityId": 3372,
    "cityName": "Manga",
    "stateId": 5244
  },
  {
    "cityId": 3373,
    "cityName": "Manhuaçu",
    "stateId": 5244
  },
  {
    "cityId": 3374,
    "cityName": "Manhumirim",
    "stateId": 5244
  },
  {
    "cityId": 3375,
    "cityName": "Mantena",
    "stateId": 5244
  },
  {
    "cityId": 3376,
    "cityName": "Mantiqueira",
    "stateId": 5244
  },
  {
    "cityId": 3377,
    "cityName": "Mantiqueira do Palmital",
    "stateId": 5244
  },
  {
    "cityId": 3378,
    "cityName": "Mar de Espanha",
    "stateId": 5244
  },
  {
    "cityId": 3379,
    "cityName": "Marambainha",
    "stateId": 5244
  },
  {
    "cityId": 3380,
    "cityName": "Maravilhas",
    "stateId": 5244
  },
  {
    "cityId": 3381,
    "cityName": "Maria da Fé",
    "stateId": 5244
  },
  {
    "cityId": 3382,
    "cityName": "Mariana",
    "stateId": 5244
  },
  {
    "cityId": 3383,
    "cityName": "Marilac",
    "stateId": 5244
  },
  {
    "cityId": 3384,
    "cityName": "Marilândia",
    "stateId": 5244
  },
  {
    "cityId": 3385,
    "cityName": "Mário Campos",
    "stateId": 5244
  },
  {
    "cityId": 3386,
    "cityName": "Maripá de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3387,
    "cityName": "Marliéria",
    "stateId": 5244
  },
  {
    "cityId": 3388,
    "cityName": "Marmelópolis",
    "stateId": 5244
  },
  {
    "cityId": 3389,
    "cityName": "Martinésia",
    "stateId": 5244
  },
  {
    "cityId": 3390,
    "cityName": "Martinho Campos",
    "stateId": 5244
  },
  {
    "cityId": 3391,
    "cityName": "Martins Guimarães",
    "stateId": 5244
  },
  {
    "cityId": 3392,
    "cityName": "Martins Soares",
    "stateId": 5244
  },
  {
    "cityId": 3393,
    "cityName": "Mata Verde",
    "stateId": 5244
  },
  {
    "cityId": 3394,
    "cityName": "Materlândia",
    "stateId": 5244
  },
  {
    "cityId": 3395,
    "cityName": "Mateus Leme",
    "stateId": 5244
  },
  {
    "cityId": 3396,
    "cityName": "Mathias Lobato",
    "stateId": 5244
  },
  {
    "cityId": 3397,
    "cityName": "Matias Barbosa",
    "stateId": 5244
  },
  {
    "cityId": 3398,
    "cityName": "Matias Cardoso",
    "stateId": 5244
  },
  {
    "cityId": 3399,
    "cityName": "Matipó",
    "stateId": 5244
  },
  {
    "cityId": 3400,
    "cityName": "Mato Verde",
    "stateId": 5244
  },
  {
    "cityId": 3401,
    "cityName": "Matozinhos",
    "stateId": 5244
  },
  {
    "cityId": 3402,
    "cityName": "Matutina",
    "stateId": 5244
  },
  {
    "cityId": 3403,
    "cityName": "Medeiros",
    "stateId": 5244
  },
  {
    "cityId": 3404,
    "cityName": "Medina",
    "stateId": 5244
  },
  {
    "cityId": 3405,
    "cityName": "Melo Viana",
    "stateId": 5244
  },
  {
    "cityId": 3406,
    "cityName": "Mendanha",
    "stateId": 5244
  },
  {
    "cityId": 3407,
    "cityName": "Mendes Pimentel",
    "stateId": 5244
  },
  {
    "cityId": 3408,
    "cityName": "Mendonça",
    "stateId": 5244
  },
  {
    "cityId": 3409,
    "cityName": "Mercês",
    "stateId": 5244
  },
  {
    "cityId": 3410,
    "cityName": "Mercês de Água Limpa",
    "stateId": 5244
  },
  {
    "cityId": 3411,
    "cityName": "Mesquita",
    "stateId": 5244
  },
  {
    "cityId": 3412,
    "cityName": "Mestre Caetano",
    "stateId": 5244
  },
  {
    "cityId": 3413,
    "cityName": "Miguel Burnier",
    "stateId": 5244
  },
  {
    "cityId": 3414,
    "cityName": "Milagre",
    "stateId": 5244
  },
  {
    "cityId": 3415,
    "cityName": "Milho Verde",
    "stateId": 5244
  },
  {
    "cityId": 3416,
    "cityName": "Minas Novas",
    "stateId": 5244
  },
  {
    "cityId": 3417,
    "cityName": "Minduri",
    "stateId": 5244
  },
  {
    "cityId": 3418,
    "cityName": "Mirabela",
    "stateId": 5244
  },
  {
    "cityId": 3419,
    "cityName": "Miradouro",
    "stateId": 5244
  },
  {
    "cityId": 3420,
    "cityName": "Miragaia",
    "stateId": 5244
  },
  {
    "cityId": 3421,
    "cityName": "Miraí",
    "stateId": 5244
  },
  {
    "cityId": 3422,
    "cityName": "Miralta",
    "stateId": 5244
  },
  {
    "cityId": 3423,
    "cityName": "Mirantão",
    "stateId": 5244
  },
  {
    "cityId": 3424,
    "cityName": "Miraporanga",
    "stateId": 5244
  },
  {
    "cityId": 3425,
    "cityName": "Miravânia",
    "stateId": 5244
  },
  {
    "cityId": 3426,
    "cityName": "Missionário",
    "stateId": 5244
  },
  {
    "cityId": 3427,
    "cityName": "Mocambeiro",
    "stateId": 5244
  },
  {
    "cityId": 3428,
    "cityName": "Mocambinho",
    "stateId": 5244
  },
  {
    "cityId": 3429,
    "cityName": "Moeda",
    "stateId": 5244
  },
  {
    "cityId": 3430,
    "cityName": "Moema",
    "stateId": 5244
  },
  {
    "cityId": 3431,
    "cityName": "Monjolinho de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3432,
    "cityName": "Monjolos",
    "stateId": 5244
  },
  {
    "cityId": 3433,
    "cityName": "Monsenhor Horta",
    "stateId": 5244
  },
  {
    "cityId": 3434,
    "cityName": "Monsenhor Isidro",
    "stateId": 5244
  },
  {
    "cityId": 3435,
    "cityName": "Monsenhor João Alexandre",
    "stateId": 5244
  },
  {
    "cityId": 3436,
    "cityName": "Monsenhor Paulo",
    "stateId": 5244
  },
  {
    "cityId": 3437,
    "cityName": "Montalvânia",
    "stateId": 5244
  },
  {
    "cityId": 3438,
    "cityName": "Monte Alegre de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3439,
    "cityName": "Monte Azul",
    "stateId": 5244
  },
  {
    "cityId": 3440,
    "cityName": "Monte Belo",
    "stateId": 5244
  },
  {
    "cityId": 3441,
    "cityName": "Monte Carmelo",
    "stateId": 5244
  },
  {
    "cityId": 3442,
    "cityName": "Monte Celeste",
    "stateId": 5244
  },
  {
    "cityId": 3443,
    "cityName": "Monte Formoso",
    "stateId": 5244
  },
  {
    "cityId": 3444,
    "cityName": "Monte Rei",
    "stateId": 5244
  },
  {
    "cityId": 3445,
    "cityName": "Monte Santo de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3446,
    "cityName": "Monte Sião",
    "stateId": 5244
  },
  {
    "cityId": 3447,
    "cityName": "Monte Verde",
    "stateId": 5244
  },
  {
    "cityId": 3448,
    "cityName": "Montes Claros",
    "stateId": 5244
  },
  {
    "cityId": 3449,
    "cityName": "Montezuma",
    "stateId": 5244
  },
  {
    "cityId": 3450,
    "cityName": "Morada Nova de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3451,
    "cityName": "Morro",
    "stateId": 5244
  },
  {
    "cityId": 3452,
    "cityName": "Morro da Garça",
    "stateId": 5244
  },
  {
    "cityId": 3453,
    "cityName": "Morro do Ferro",
    "stateId": 5244
  },
  {
    "cityId": 3454,
    "cityName": "Morro do Pilar",
    "stateId": 5244
  },
  {
    "cityId": 3455,
    "cityName": "Morro Vermelho",
    "stateId": 5244
  },
  {
    "cityId": 3456,
    "cityName": "Mucuri",
    "stateId": 5244
  },
  {
    "cityId": 3457,
    "cityName": "Mundo Novo de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3458,
    "cityName": "Munhoz",
    "stateId": 5244
  },
  {
    "cityId": 3459,
    "cityName": "Muquém",
    "stateId": 5244
  },
  {
    "cityId": 3460,
    "cityName": "Muriaé",
    "stateId": 5244
  },
  {
    "cityId": 3461,
    "cityName": "Mutum",
    "stateId": 5244
  },
  {
    "cityId": 3462,
    "cityName": "Muzambinho",
    "stateId": 5244
  },
  {
    "cityId": 3463,
    "cityName": "Nacip Raydan",
    "stateId": 5244
  },
  {
    "cityId": 3464,
    "cityName": "Nanuque",
    "stateId": 5244
  },
  {
    "cityId": 3465,
    "cityName": "Naque",
    "stateId": 5244
  },
  {
    "cityId": 3466,
    "cityName": "Naque-Nanuque",
    "stateId": 5244
  },
  {
    "cityId": 3467,
    "cityName": "Natalândia",
    "stateId": 5244
  },
  {
    "cityId": 3468,
    "cityName": "Natércia",
    "stateId": 5244
  },
  {
    "cityId": 3469,
    "cityName": "Nazaré de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3470,
    "cityName": "Nazareno",
    "stateId": 5244
  },
  {
    "cityId": 3471,
    "cityName": "Nelson de Sena",
    "stateId": 5244
  },
  {
    "cityId": 3472,
    "cityName": "Neolândia",
    "stateId": 5244
  },
  {
    "cityId": 3473,
    "cityName": "Nepomuceno",
    "stateId": 5244
  },
  {
    "cityId": 3474,
    "cityName": "Nhandutiba",
    "stateId": 5244
  },
  {
    "cityId": 3475,
    "cityName": "Nicolândia",
    "stateId": 5244
  },
  {
    "cityId": 3476,
    "cityName": "Ninheira",
    "stateId": 5244
  },
  {
    "cityId": 3477,
    "cityName": "Nova Belém",
    "stateId": 5244
  },
  {
    "cityId": 3478,
    "cityName": "Nova Era",
    "stateId": 5244
  },
  {
    "cityId": 3479,
    "cityName": "Nova Esperança",
    "stateId": 5244
  },
  {
    "cityId": 3480,
    "cityName": "Nova Lima",
    "stateId": 5244
  },
  {
    "cityId": 3481,
    "cityName": "Nova Minda",
    "stateId": 5244
  },
  {
    "cityId": 3482,
    "cityName": "Nova Módica",
    "stateId": 5244
  },
  {
    "cityId": 3483,
    "cityName": "Nova Ponte",
    "stateId": 5244
  },
  {
    "cityId": 3484,
    "cityName": "Nova Porteirinha",
    "stateId": 5244
  },
  {
    "cityId": 3485,
    "cityName": "Nova Resende",
    "stateId": 5244
  },
  {
    "cityId": 3486,
    "cityName": "Nova Serrana",
    "stateId": 5244
  },
  {
    "cityId": 3487,
    "cityName": "Nova União",
    "stateId": 5244
  },
  {
    "cityId": 3488,
    "cityName": "Novilhona",
    "stateId": 5244
  },
  {
    "cityId": 3489,
    "cityName": "Novo Cruzeiro",
    "stateId": 5244
  },
  {
    "cityId": 3490,
    "cityName": "Novo Horizonte",
    "stateId": 5244
  },
  {
    "cityId": 3491,
    "cityName": "Novo Oriente de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3492,
    "cityName": "Novorizonte",
    "stateId": 5244
  },
  {
    "cityId": 3493,
    "cityName": "Ocidente",
    "stateId": 5244
  },
  {
    "cityId": 3494,
    "cityName": "Olaria",
    "stateId": 5244
  },
  {
    "cityId": 3495,
    "cityName": "Olegário Maciel",
    "stateId": 5244
  },
  {
    "cityId": 3496,
    "cityName": "Olhos D'Água do Oeste",
    "stateId": 5244
  },
  {
    "cityId": 3497,
    "cityName": "Olhos D'Água",
    "stateId": 5244
  },
  {
    "cityId": 3498,
    "cityName": "Olímpio Campos",
    "stateId": 5244
  },
  {
    "cityId": 3499,
    "cityName": "Olímpio Noronha",
    "stateId": 5244
  },
  {
    "cityId": 3500,
    "cityName": "Oliveira",
    "stateId": 5244
  },
  {
    "cityId": 3501,
    "cityName": "Oliveira Fortes",
    "stateId": 5244
  },
  {
    "cityId": 3502,
    "cityName": "Onça de Pitangui",
    "stateId": 5244
  },
  {
    "cityId": 3503,
    "cityName": "Oratórios",
    "stateId": 5244
  },
  {
    "cityId": 3504,
    "cityName": "Orizânia",
    "stateId": 5244
  },
  {
    "cityId": 3505,
    "cityName": "Ouro Branco",
    "stateId": 5244
  },
  {
    "cityId": 3506,
    "cityName": "Ouro Fino",
    "stateId": 5244
  },
  {
    "cityId": 3507,
    "cityName": "Ouro Preto",
    "stateId": 5244
  },
  {
    "cityId": 3508,
    "cityName": "Ouro Verde de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3509,
    "cityName": "Paciência",
    "stateId": 5244
  },
  {
    "cityId": 3510,
    "cityName": "Padre Brito",
    "stateId": 5244
  },
  {
    "cityId": 3511,
    "cityName": "Padre Carvalho",
    "stateId": 5244
  },
  {
    "cityId": 3512,
    "cityName": "Padre Felisberto",
    "stateId": 5244
  },
  {
    "cityId": 3513,
    "cityName": "Padre Fialho",
    "stateId": 5244
  },
  {
    "cityId": 3514,
    "cityName": "Padre João Afonso",
    "stateId": 5244
  },
  {
    "cityId": 3515,
    "cityName": "Padre Júlio Maria",
    "stateId": 5244
  },
  {
    "cityId": 3516,
    "cityName": "Padre Paraíso",
    "stateId": 5244
  },
  {
    "cityId": 3517,
    "cityName": "Padre Pinto",
    "stateId": 5244
  },
  {
    "cityId": 3518,
    "cityName": "Padre Viegas",
    "stateId": 5244
  },
  {
    "cityId": 3519,
    "cityName": "Pai Pedro",
    "stateId": 5244
  },
  {
    "cityId": 3520,
    "cityName": "Paineiras",
    "stateId": 5244
  },
  {
    "cityId": 3521,
    "cityName": "Pains",
    "stateId": 5244
  },
  {
    "cityId": 3522,
    "cityName": "Paiolinho",
    "stateId": 5244
  },
  {
    "cityId": 3523,
    "cityName": "Paiva",
    "stateId": 5244
  },
  {
    "cityId": 3524,
    "cityName": "Palma",
    "stateId": 5244
  },
  {
    "cityId": 3525,
    "cityName": "Palmeiral",
    "stateId": 5244
  },
  {
    "cityId": 3526,
    "cityName": "Palmeirinha",
    "stateId": 5244
  },
  {
    "cityId": 3527,
    "cityName": "Palmital dos Carvalhos",
    "stateId": 5244
  },
  {
    "cityId": 3528,
    "cityName": "Palmópolis",
    "stateId": 5244
  },
  {
    "cityId": 3529,
    "cityName": "Pântano",
    "stateId": 5244
  },
  {
    "cityId": 3530,
    "cityName": "Papagaios",
    "stateId": 5244
  },
  {
    "cityId": 3531,
    "cityName": "Pará de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3532,
    "cityName": "Paracatu",
    "stateId": 5244
  },
  {
    "cityId": 3533,
    "cityName": "Paraguaçu",
    "stateId": 5244
  },
  {
    "cityId": 3534,
    "cityName": "Paraguai",
    "stateId": 5244
  },
  {
    "cityId": 3535,
    "cityName": "Paraíso Garcia",
    "stateId": 5244
  },
  {
    "cityId": 3536,
    "cityName": "Paraisópolis",
    "stateId": 5244
  },
  {
    "cityId": 3537,
    "cityName": "Paraopeba",
    "stateId": 5244
  },
  {
    "cityId": 3538,
    "cityName": "Paredão de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3539,
    "cityName": "Parque Durval de Barros",
    "stateId": 5244
  },
  {
    "cityId": 3540,
    "cityName": "Parque Industrial",
    "stateId": 5244
  },
  {
    "cityId": 3541,
    "cityName": "Passa Dez",
    "stateId": 5244
  },
  {
    "cityId": 3542,
    "cityName": "Passa Quatro",
    "stateId": 5244
  },
  {
    "cityId": 3543,
    "cityName": "Passa Tempo",
    "stateId": 5244
  },
  {
    "cityId": 3544,
    "cityName": "Passa Vinte",
    "stateId": 5244
  },
  {
    "cityId": 3545,
    "cityName": "Passabém",
    "stateId": 5244
  },
  {
    "cityId": 3546,
    "cityName": "Passagem de Mariana",
    "stateId": 5244
  },
  {
    "cityId": 3547,
    "cityName": "Passos",
    "stateId": 5244
  },
  {
    "cityId": 3548,
    "cityName": "Patis",
    "stateId": 5244
  },
  {
    "cityId": 3549,
    "cityName": "Patos de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3550,
    "cityName": "Patrimônio",
    "stateId": 5244
  },
  {
    "cityId": 3551,
    "cityName": "Patrocínio",
    "stateId": 5244
  },
  {
    "cityId": 3552,
    "cityName": "Patrocínio do Muriaé",
    "stateId": 5244
  },
  {
    "cityId": 3553,
    "cityName": "Paula Cândido",
    "stateId": 5244
  },
  {
    "cityId": 3554,
    "cityName": "Paula Lima",
    "stateId": 5244
  },
  {
    "cityId": 3555,
    "cityName": "Paulistas",
    "stateId": 5244
  },
  {
    "cityId": 3556,
    "cityName": "Pavão",
    "stateId": 5244
  },
  {
    "cityId": 3557,
    "cityName": "Pé do Morro",
    "stateId": 5244
  },
  {
    "cityId": 3558,
    "cityName": "Peçanha",
    "stateId": 5244
  },
  {
    "cityId": 3559,
    "cityName": "Pedra Azul",
    "stateId": 5244
  },
  {
    "cityId": 3560,
    "cityName": "Pedra Bonita",
    "stateId": 5244
  },
  {
    "cityId": 3561,
    "cityName": "Pedra Corrida",
    "stateId": 5244
  },
  {
    "cityId": 3562,
    "cityName": "Pedra do Anta",
    "stateId": 5244
  },
  {
    "cityId": 3563,
    "cityName": "Pedra do Indaiá",
    "stateId": 5244
  },
  {
    "cityId": 3564,
    "cityName": "Pedra do Sino",
    "stateId": 5244
  },
  {
    "cityId": 3565,
    "cityName": "Pedra Dourada",
    "stateId": 5244
  },
  {
    "cityId": 3566,
    "cityName": "Pedra Grande",
    "stateId": 5244
  },
  {
    "cityId": 3567,
    "cityName": "Pedra Menina",
    "stateId": 5244
  },
  {
    "cityId": 3568,
    "cityName": "Pedralva",
    "stateId": 5244
  },
  {
    "cityId": 3569,
    "cityName": "Pedras de Maria da Cruz",
    "stateId": 5244
  },
  {
    "cityId": 3570,
    "cityName": "Pedrinópolis",
    "stateId": 5244
  },
  {
    "cityId": 3571,
    "cityName": "Pedro Leopoldo",
    "stateId": 5244
  },
  {
    "cityId": 3572,
    "cityName": "Pedro Lessa",
    "stateId": 5244
  },
  {
    "cityId": 3573,
    "cityName": "Pedro Teixeira",
    "stateId": 5244
  },
  {
    "cityId": 3574,
    "cityName": "Pedro Versiani",
    "stateId": 5244
  },
  {
    "cityId": 3575,
    "cityName": "Penédia",
    "stateId": 5244
  },
  {
    "cityId": 3576,
    "cityName": "Penha de França",
    "stateId": 5244
  },
  {
    "cityId": 3577,
    "cityName": "Penha do Capim",
    "stateId": 5244
  },
  {
    "cityId": 3578,
    "cityName": "Penha do Cassiano",
    "stateId": 5244
  },
  {
    "cityId": 3579,
    "cityName": "Penha do Norte",
    "stateId": 5244
  },
  {
    "cityId": 3580,
    "cityName": "Penha Longa",
    "stateId": 5244
  },
  {
    "cityId": 3581,
    "cityName": "Pequeri",
    "stateId": 5244
  },
  {
    "cityId": 3582,
    "cityName": "Pequi",
    "stateId": 5244
  },
  {
    "cityId": 3583,
    "cityName": "Perdigão",
    "stateId": 5244
  },
  {
    "cityId": 3584,
    "cityName": "Perdilândia",
    "stateId": 5244
  },
  {
    "cityId": 3585,
    "cityName": "Perdizes",
    "stateId": 5244
  },
  {
    "cityId": 3586,
    "cityName": "Perdões",
    "stateId": 5244
  },
  {
    "cityId": 3587,
    "cityName": "Pereirinhas",
    "stateId": 5244
  },
  {
    "cityId": 3588,
    "cityName": "Periquito",
    "stateId": 5244
  },
  {
    "cityId": 3589,
    "cityName": "Perpétuo Socorro",
    "stateId": 5244
  },
  {
    "cityId": 3590,
    "cityName": "Pescador",
    "stateId": 5244
  },
  {
    "cityId": 3591,
    "cityName": "Petúnia",
    "stateId": 5244
  },
  {
    "cityId": 3592,
    "cityName": "Piacatuba",
    "stateId": 5244
  },
  {
    "cityId": 3593,
    "cityName": "Pião",
    "stateId": 5244
  },
  {
    "cityId": 3594,
    "cityName": "Piau",
    "stateId": 5244
  },
  {
    "cityId": 3595,
    "cityName": "Pic Sagarana",
    "stateId": 5244
  },
  {
    "cityId": 3596,
    "cityName": "Piedade de Caratinga",
    "stateId": 5244
  },
  {
    "cityId": 3597,
    "cityName": "Piedade de Ponte Nova",
    "stateId": 5244
  },
  {
    "cityId": 3598,
    "cityName": "Piedade do Paraopeba",
    "stateId": 5244
  },
  {
    "cityId": 3599,
    "cityName": "Piedade do Rio Grande",
    "stateId": 5244
  },
  {
    "cityId": 3600,
    "cityName": "Piedade dos Gerais",
    "stateId": 5244
  },
  {
    "cityId": 3601,
    "cityName": "Pilar",
    "stateId": 5244
  },
  {
    "cityId": 3602,
    "cityName": "Pimenta",
    "stateId": 5244
  },
  {
    "cityId": 3603,
    "cityName": "Pindaíbas",
    "stateId": 5244
  },
  {
    "cityId": 3604,
    "cityName": "Pingo-D'Água",
    "stateId": 5244
  },
  {
    "cityId": 3605,
    "cityName": "Pinheirinhos",
    "stateId": 5244
  },
  {
    "cityId": 3606,
    "cityName": "Pinheiros Altos",
    "stateId": 5244
  },
  {
    "cityId": 3607,
    "cityName": "Pinhotiba",
    "stateId": 5244
  },
  {
    "cityId": 3608,
    "cityName": "Pintópolis",
    "stateId": 5244
  },
  {
    "cityId": 3609,
    "cityName": "Pintos Negreiros",
    "stateId": 5244
  },
  {
    "cityId": 3610,
    "cityName": "Piracaiba",
    "stateId": 5244
  },
  {
    "cityId": 3611,
    "cityName": "Piracema",
    "stateId": 5244
  },
  {
    "cityId": 3612,
    "cityName": "Pirajuba",
    "stateId": 5244
  },
  {
    "cityId": 3613,
    "cityName": "Piranga",
    "stateId": 5244
  },
  {
    "cityId": 3614,
    "cityName": "Piranguçu",
    "stateId": 5244
  },
  {
    "cityId": 3615,
    "cityName": "Piranguinho",
    "stateId": 5244
  },
  {
    "cityId": 3616,
    "cityName": "Piranguita",
    "stateId": 5244
  },
  {
    "cityId": 3617,
    "cityName": "Pirapanema",
    "stateId": 5244
  },
  {
    "cityId": 3618,
    "cityName": "Pirapetinga",
    "stateId": 5244
  },
  {
    "cityId": 3619,
    "cityName": "Pirapora",
    "stateId": 5244
  },
  {
    "cityId": 3620,
    "cityName": "Piraúba",
    "stateId": 5244
  },
  {
    "cityId": 3621,
    "cityName": "Alto Belo",
    "stateId": 5244
  },
  {
    "cityId": 3622,
    "cityName": "Piscamba",
    "stateId": 5244
  },
  {
    "cityId": 3623,
    "cityName": "Pitangui",
    "stateId": 5244
  },
  {
    "cityId": 3624,
    "cityName": "Pitarana",
    "stateId": 5244
  },
  {
    "cityId": 3625,
    "cityName": "Piumhi",
    "stateId": 5244
  },
  {
    "cityId": 3626,
    "cityName": "Planalto de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3627,
    "cityName": "Planura",
    "stateId": 5244
  },
  {
    "cityId": 3628,
    "cityName": "Plautino Soares",
    "stateId": 5244
  },
  {
    "cityId": 3629,
    "cityName": "Poaia",
    "stateId": 5244
  },
  {
    "cityId": 3630,
    "cityName": "Poço Fundo",
    "stateId": 5244
  },
  {
    "cityId": 3631,
    "cityName": "Poções de Paineiras",
    "stateId": 5244
  },
  {
    "cityId": 3632,
    "cityName": "Poços de Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3633,
    "cityName": "Pocrane",
    "stateId": 5244
  },
  {
    "cityId": 3634,
    "cityName": "Pompéu",
    "stateId": 5244
  },
  {
    "cityId": 3635,
    "cityName": "Poncianos",
    "stateId": 5244
  },
  {
    "cityId": 3636,
    "cityName": "Pontalete",
    "stateId": 5244
  },
  {
    "cityId": 3637,
    "cityName": "Ponte Alta",
    "stateId": 5244
  },
  {
    "cityId": 3638,
    "cityName": "Ponte Alta de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3639,
    "cityName": "Ponte dos Ciganos",
    "stateId": 5244
  },
  {
    "cityId": 3640,
    "cityName": "Ponte Firme",
    "stateId": 5244
  },
  {
    "cityId": 3641,
    "cityName": "Ponte Nova",
    "stateId": 5244
  },
  {
    "cityId": 3642,
    "cityName": "Ponte Segura",
    "stateId": 5244
  },
  {
    "cityId": 3643,
    "cityName": "Pontevila",
    "stateId": 5244
  },
  {
    "cityId": 3644,
    "cityName": "Ponto Chique",
    "stateId": 5244
  },
  {
    "cityId": 3645,
    "cityName": "Ponto Chique do Martelo",
    "stateId": 5244
  },
  {
    "cityId": 3646,
    "cityName": "Ponto do Marambaia",
    "stateId": 5244
  },
  {
    "cityId": 3647,
    "cityName": "Ponto dos Volantes",
    "stateId": 5244
  },
  {
    "cityId": 3648,
    "cityName": "Porteirinha",
    "stateId": 5244
  },
  {
    "cityId": 3649,
    "cityName": "Porto Agrário",
    "stateId": 5244
  },
  {
    "cityId": 3650,
    "cityName": "Porto das Flores",
    "stateId": 5244
  },
  {
    "cityId": 3651,
    "cityName": "Porto dos Mendes",
    "stateId": 5244
  },
  {
    "cityId": 3652,
    "cityName": "Porto Firme",
    "stateId": 5244
  },
  {
    "cityId": 3653,
    "cityName": "Poté",
    "stateId": 5244
  },
  {
    "cityId": 3654,
    "cityName": "Pouso Alegre",
    "stateId": 5244
  },
  {
    "cityId": 3655,
    "cityName": "Pouso Alto",
    "stateId": 5244
  },
  {
    "cityId": 3656,
    "cityName": "Prados",
    "stateId": 5244
  },
  {
    "cityId": 3657,
    "cityName": "Prata",
    "stateId": 5244
  },
  {
    "cityId": 3658,
    "cityName": "Pratápolis",
    "stateId": 5244
  },
  {
    "cityId": 3659,
    "cityName": "Pratinha",
    "stateId": 5244
  },
  {
    "cityId": 3660,
    "cityName": "Presidente Bernardes",
    "stateId": 5244
  },
  {
    "cityId": 3661,
    "cityName": "Presidente Juscelino",
    "stateId": 5244
  },
  {
    "cityId": 3662,
    "cityName": "Presidente Kubitschek",
    "stateId": 5244
  },
  {
    "cityId": 3663,
    "cityName": "Presidente Olegário",
    "stateId": 5244
  },
  {
    "cityId": 3664,
    "cityName": "Presidente Pena",
    "stateId": 5244
  },
  {
    "cityId": 3665,
    "cityName": "Professor Sperber",
    "stateId": 5244
  },
  {
    "cityId": 3666,
    "cityName": "Providência",
    "stateId": 5244
  },
  {
    "cityId": 3667,
    "cityName": "Prudente de Morais",
    "stateId": 5244
  },
  {
    "cityId": 3668,
    "cityName": "Quartel de São João",
    "stateId": 5244
  },
  {
    "cityId": 3669,
    "cityName": "Quartel do Sacramento",
    "stateId": 5244
  },
  {
    "cityId": 3670,
    "cityName": "Quartel Geral",
    "stateId": 5244
  },
  {
    "cityId": 3671,
    "cityName": "Quatituba",
    "stateId": 5244
  },
  {
    "cityId": 3672,
    "cityName": "Queixada",
    "stateId": 5244
  },
  {
    "cityId": 3673,
    "cityName": "Queluzita",
    "stateId": 5244
  },
  {
    "cityId": 3674,
    "cityName": "Quem-Quem",
    "stateId": 5244
  },
  {
    "cityId": 3675,
    "cityName": "Quilombo",
    "stateId": 5244
  },
  {
    "cityId": 3676,
    "cityName": "Quintinos",
    "stateId": 5244
  },
  {
    "cityId": 3677,
    "cityName": "Raposos",
    "stateId": 5244
  },
  {
    "cityId": 3678,
    "cityName": "Raul Soares",
    "stateId": 5244
  },
  {
    "cityId": 3679,
    "cityName": "Ravena",
    "stateId": 5244
  },
  {
    "cityId": 3680,
    "cityName": "Realeza",
    "stateId": 5244
  },
  {
    "cityId": 3681,
    "cityName": "Recreio",
    "stateId": 5244
  },
  {
    "cityId": 3682,
    "cityName": "Reduto",
    "stateId": 5244
  },
  {
    "cityId": 3683,
    "cityName": "Resende Costa",
    "stateId": 5244
  },
  {
    "cityId": 3684,
    "cityName": "Resplendor",
    "stateId": 5244
  },
  {
    "cityId": 3685,
    "cityName": "Ressaquinha",
    "stateId": 5244
  },
  {
    "cityId": 3686,
    "cityName": "Riachinho",
    "stateId": 5244
  },
  {
    "cityId": 3687,
    "cityName": "Riacho da Cruz",
    "stateId": 5244
  },
  {
    "cityId": 3688,
    "cityName": "Riacho dos Machados",
    "stateId": 5244
  },
  {
    "cityId": 3689,
    "cityName": "Ribeirão das Neves",
    "stateId": 5244
  },
  {
    "cityId": 3690,
    "cityName": "Ribeirão de São Domingos",
    "stateId": 5244
  },
  {
    "cityId": 3691,
    "cityName": "Ribeirão Vermelho",
    "stateId": 5244
  },
  {
    "cityId": 3692,
    "cityName": "Ribeiro Junqueira",
    "stateId": 5244
  },
  {
    "cityId": 3693,
    "cityName": "Ribeiros",
    "stateId": 5244
  },
  {
    "cityId": 3694,
    "cityName": "Rio Acima",
    "stateId": 5244
  },
  {
    "cityId": 3695,
    "cityName": "Rio Casca",
    "stateId": 5244
  },
  {
    "cityId": 3696,
    "cityName": "Rio das Mortes",
    "stateId": 5244
  },
  {
    "cityId": 3697,
    "cityName": "Rio do Prado",
    "stateId": 5244
  },
  {
    "cityId": 3698,
    "cityName": "Rio Doce",
    "stateId": 5244
  },
  {
    "cityId": 3699,
    "cityName": "Rio Espera",
    "stateId": 5244
  },
  {
    "cityId": 3700,
    "cityName": "Rio Manso",
    "stateId": 5244
  },
  {
    "cityId": 3701,
    "cityName": "Rio Melo",
    "stateId": 5244
  },
  {
    "cityId": 3702,
    "cityName": "Rio Novo",
    "stateId": 5244
  },
  {
    "cityId": 3703,
    "cityName": "Rio Paranaíba",
    "stateId": 5244
  },
  {
    "cityId": 3704,
    "cityName": "Rio Pardo de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3705,
    "cityName": "Rio Piracicaba",
    "stateId": 5244
  },
  {
    "cityId": 3706,
    "cityName": "Rio Pomba",
    "stateId": 5244
  },
  {
    "cityId": 3707,
    "cityName": "Rio Pretinho",
    "stateId": 5244
  },
  {
    "cityId": 3708,
    "cityName": "Rio Preto",
    "stateId": 5244
  },
  {
    "cityId": 3709,
    "cityName": "Rio Vermelho",
    "stateId": 5244
  },
  {
    "cityId": 3710,
    "cityName": "Ritápolis",
    "stateId": 5244
  },
  {
    "cityId": 3711,
    "cityName": "Roça Grande",
    "stateId": 5244
  },
  {
    "cityId": 3712,
    "cityName": "Roças Novas",
    "stateId": 5244
  },
  {
    "cityId": 3713,
    "cityName": "Rochedo de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3714,
    "cityName": "Rodeador",
    "stateId": 5244
  },
  {
    "cityId": 3715,
    "cityName": "Rodeiro",
    "stateId": 5244
  },
  {
    "cityId": 3716,
    "cityName": "Rodrigo Silva",
    "stateId": 5244
  },
  {
    "cityId": 3717,
    "cityName": "Romaria",
    "stateId": 5244
  },
  {
    "cityId": 3718,
    "cityName": "Rosário da Limeira",
    "stateId": 5244
  },
  {
    "cityId": 3719,
    "cityName": "Rosário de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3720,
    "cityName": "Rosário do Pontal",
    "stateId": 5244
  },
  {
    "cityId": 3721,
    "cityName": "Roseiral",
    "stateId": 5244
  },
  {
    "cityId": 3722,
    "cityName": "Rubelita",
    "stateId": 5244
  },
  {
    "cityId": 3723,
    "cityName": "Rubim",
    "stateId": 5244
  },
  {
    "cityId": 3724,
    "cityName": "Sabará",
    "stateId": 5244
  },
  {
    "cityId": 3725,
    "cityName": "Sabinópolis",
    "stateId": 5244
  },
  {
    "cityId": 3726,
    "cityName": "Sacramento",
    "stateId": 5244
  },
  {
    "cityId": 3727,
    "cityName": "Salinas",
    "stateId": 5244
  },
  {
    "cityId": 3728,
    "cityName": "Salitre de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3729,
    "cityName": "Salto da Divisa",
    "stateId": 5244
  },
  {
    "cityId": 3730,
    "cityName": "Sanatório Santa Fé",
    "stateId": 5244
  },
  {
    "cityId": 3731,
    "cityName": "Santa Bárbara",
    "stateId": 5244
  },
  {
    "cityId": 3732,
    "cityName": "Santa Bárbara do Leste",
    "stateId": 5244
  },
  {
    "cityId": 3733,
    "cityName": "Santa Bárbara do Monte Verde",
    "stateId": 5244
  },
  {
    "cityId": 3734,
    "cityName": "Santa Bárbara do Tugúrio",
    "stateId": 5244
  },
  {
    "cityId": 3735,
    "cityName": "Santa Cruz da Aparecida",
    "stateId": 5244
  },
  {
    "cityId": 3736,
    "cityName": "Santa Cruz de Botumirim",
    "stateId": 5244
  },
  {
    "cityId": 3737,
    "cityName": "Santa Cruz de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3738,
    "cityName": "Santa Cruz de Salinas",
    "stateId": 5244
  },
  {
    "cityId": 3739,
    "cityName": "Santa Cruz do Escalvado",
    "stateId": 5244
  },
  {
    "cityId": 3740,
    "cityName": "Santa Cruz do Prata",
    "stateId": 5244
  },
  {
    "cityId": 3741,
    "cityName": "Santa da Pedra",
    "stateId": 5244
  },
  {
    "cityId": 3742,
    "cityName": "Santa Efigênia",
    "stateId": 5244
  },
  {
    "cityId": 3743,
    "cityName": "Santa Efigênia de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3744,
    "cityName": "Santa Fé de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3745,
    "cityName": "Santa Filomena",
    "stateId": 5244
  },
  {
    "cityId": 3746,
    "cityName": "Santa Helena de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3748,
    "cityName": "Santa Juliana",
    "stateId": 5244
  },
  {
    "cityId": 3749,
    "cityName": "Santa Luzia",
    "stateId": 5244
  },
  {
    "cityId": 3750,
    "cityName": "Santa Luzia da Serra",
    "stateId": 5244
  },
  {
    "cityId": 3751,
    "cityName": "Santa Margarida",
    "stateId": 5244
  },
  {
    "cityId": 3752,
    "cityName": "Santa Maria de Itabira",
    "stateId": 5244
  },
  {
    "cityId": 3753,
    "cityName": "Santa Maria do Salto",
    "stateId": 5244
  },
  {
    "cityId": 3754,
    "cityName": "Santa Maria do Suaçuí",
    "stateId": 5244
  },
  {
    "cityId": 3755,
    "cityName": "Santa Rita da Estrela",
    "stateId": 5244
  },
  {
    "cityId": 3756,
    "cityName": "Santa Rita de Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3757,
    "cityName": "Santa Rita de Jacutinga",
    "stateId": 5244
  },
  {
    "cityId": 3758,
    "cityName": "Santa Rita de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3759,
    "cityName": "Santa Rita de Ouro Preto",
    "stateId": 5244
  },
  {
    "cityId": 3760,
    "cityName": "Santa Rita do Cedro",
    "stateId": 5244
  },
  {
    "cityId": 3761,
    "cityName": "Santa Rita do Ibitipoca",
    "stateId": 5244
  },
  {
    "cityId": 3762,
    "cityName": "Santa Rita do Itueto",
    "stateId": 5244
  },
  {
    "cityId": 3763,
    "cityName": "Santa Rita do Rio do Peixe",
    "stateId": 5244
  },
  {
    "cityId": 3764,
    "cityName": "Santa Rita do Sapucaí",
    "stateId": 5244
  },
  {
    "cityId": 3765,
    "cityName": "Santa Rita Durão",
    "stateId": 5244
  },
  {
    "cityId": 3766,
    "cityName": "Santa Rosa da Serra",
    "stateId": 5244
  },
  {
    "cityId": 3767,
    "cityName": "Santa Rosa de Lima",
    "stateId": 5244
  },
  {
    "cityId": 3768,
    "cityName": "Santa Rosa dos Dourados",
    "stateId": 5244
  },
  {
    "cityId": 3769,
    "cityName": "Santa Teresa do Bonito",
    "stateId": 5244
  },
  {
    "cityId": 3770,
    "cityName": "Santa Terezinha de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3771,
    "cityName": "Santa Vitória",
    "stateId": 5244
  },
  {
    "cityId": 3772,
    "cityName": "Santana da Vargem",
    "stateId": 5244
  },
  {
    "cityId": 3773,
    "cityName": "Santana de Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3774,
    "cityName": "Santana de Cataguases",
    "stateId": 5244
  },
  {
    "cityId": 3775,
    "cityName": "Santana de Patos",
    "stateId": 5244
  },
  {
    "cityId": 3776,
    "cityName": "Santana de Pirapama",
    "stateId": 5244
  },
  {
    "cityId": 3777,
    "cityName": "Santana do Alfié",
    "stateId": 5244
  },
  {
    "cityId": 3778,
    "cityName": "Santana do Araçuaí",
    "stateId": 5244
  },
  {
    "cityId": 3779,
    "cityName": "Santana do Campestre",
    "stateId": 5244
  },
  {
    "cityId": 3780,
    "cityName": "Santana do Capivari",
    "stateId": 5244
  },
  {
    "cityId": 3781,
    "cityName": "Santana do Deserto",
    "stateId": 5244
  },
  {
    "cityId": 3782,
    "cityName": "Santana do Garambéu",
    "stateId": 5244
  },
  {
    "cityId": 3783,
    "cityName": "Santana do Jacaré",
    "stateId": 5244
  },
  {
    "cityId": 3784,
    "cityName": "Santana do Manhuaçu",
    "stateId": 5244
  },
  {
    "cityId": 3785,
    "cityName": "Santana do Paraíso",
    "stateId": 5244
  },
  {
    "cityId": 3786,
    "cityName": "Santana do Paraopeba",
    "stateId": 5244
  },
  {
    "cityId": 3787,
    "cityName": "Santana do Riacho",
    "stateId": 5244
  },
  {
    "cityId": 3788,
    "cityName": "Santana do Tabuleiro",
    "stateId": 5244
  },
  {
    "cityId": 3789,
    "cityName": "Santana dos Montes",
    "stateId": 5244
  },
  {
    "cityId": 3790,
    "cityName": "Santo Antônio da Boa Vista",
    "stateId": 5244
  },
  {
    "cityId": 3791,
    "cityName": "Santo Antônio da Fortaleza",
    "stateId": 5244
  },
  {
    "cityId": 3792,
    "cityName": "Santo Antônio da Vargem Alegre",
    "stateId": 5244
  },
  {
    "cityId": 3793,
    "cityName": "Santo Antônio do Amparo",
    "stateId": 5244
  },
  {
    "cityId": 3794,
    "cityName": "Santo Antônio do Aventureiro",
    "stateId": 5244
  },
  {
    "cityId": 3795,
    "cityName": "Santo Antônio do Boqueirão",
    "stateId": 5244
  },
  {
    "cityId": 3796,
    "cityName": "Santo Antônio do Cruzeiro",
    "stateId": 5244
  },
  {
    "cityId": 3797,
    "cityName": "Santo Antônio do Glória",
    "stateId": 5244
  },
  {
    "cityId": 3798,
    "cityName": "Santo Antônio do Grama",
    "stateId": 5244
  },
  {
    "cityId": 3799,
    "cityName": "Santo Antônio do Itambé",
    "stateId": 5244
  },
  {
    "cityId": 3800,
    "cityName": "Santo Antônio do Jacinto",
    "stateId": 5244
  },
  {
    "cityId": 3801,
    "cityName": "Santo Antônio do Leite",
    "stateId": 5244
  },
  {
    "cityId": 3802,
    "cityName": "Santo Antônio do Manhuaçu",
    "stateId": 5244
  },
  {
    "cityId": 3803,
    "cityName": "Santo Antônio do Monte",
    "stateId": 5244
  },
  {
    "cityId": 3804,
    "cityName": "Santo Antônio do Mucuri",
    "stateId": 5244
  },
  {
    "cityId": 3805,
    "cityName": "Santo Antônio do Norte",
    "stateId": 5244
  },
  {
    "cityId": 3806,
    "cityName": "Santo Antônio do Pirapetinga",
    "stateId": 5244
  },
  {
    "cityId": 3807,
    "cityName": "Santo Antônio do Retiro",
    "stateId": 5244
  },
  {
    "cityId": 3808,
    "cityName": "Santo Antônio do Rio Abaixo",
    "stateId": 5244
  },
  {
    "cityId": 3809,
    "cityName": "Santo Antônio dos Araújos",
    "stateId": 5244
  },
  {
    "cityId": 3810,
    "cityName": "Santo Antônio dos Campos",
    "stateId": 5244
  },
  {
    "cityId": 3811,
    "cityName": "Santo Hilário",
    "stateId": 5244
  },
  {
    "cityId": 3812,
    "cityName": "Santo Hipólito",
    "stateId": 5244
  },
  {
    "cityId": 3813,
    "cityName": "Santos Dumont",
    "stateId": 5244
  },
  {
    "cityId": 3814,
    "cityName": "São Bartolomeu",
    "stateId": 5244
  },
  {
    "cityId": 3815,
    "cityName": "São Benedito",
    "stateId": 5244
  },
  {
    "cityId": 3816,
    "cityName": "São Bento Abade",
    "stateId": 5244
  },
  {
    "cityId": 3817,
    "cityName": "São Bento de Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3818,
    "cityName": "São Brás do Suaçuí",
    "stateId": 5244
  },
  {
    "cityId": 3819,
    "cityName": "São Braz",
    "stateId": 5244
  },
  {
    "cityId": 3820,
    "cityName": "São Cândido",
    "stateId": 5244
  },
  {
    "cityId": 3821,
    "cityName": "São Domingos",
    "stateId": 5244
  },
  {
    "cityId": 3822,
    "cityName": "São Domingos da Bocaina",
    "stateId": 5244
  },
  {
    "cityId": 3823,
    "cityName": "São Domingos das Dores",
    "stateId": 5244
  },
  {
    "cityId": 3824,
    "cityName": "São Domingos do Prata",
    "stateId": 5244
  },
  {
    "cityId": 3825,
    "cityName": "São Félix de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3826,
    "cityName": "São Francisco",
    "stateId": 5244
  },
  {
    "cityId": 3827,
    "cityName": "São Francisco de Paula",
    "stateId": 5244
  },
  {
    "cityId": 3828,
    "cityName": "São Francisco de Sales",
    "stateId": 5244
  },
  {
    "cityId": 3829,
    "cityName": "São Francisco do Glória",
    "stateId": 5244
  },
  {
    "cityId": 3830,
    "cityName": "São Francisco do Humaitá",
    "stateId": 5244
  },
  {
    "cityId": 3831,
    "cityName": "São Geraldo",
    "stateId": 5244
  },
  {
    "cityId": 3832,
    "cityName": "São Geraldo",
    "stateId": 5244
  },
  {
    "cityId": 3833,
    "cityName": "São Geraldo da Piedade",
    "stateId": 5244
  },
  {
    "cityId": 3834,
    "cityName": "São Geraldo de Tumiritinga",
    "stateId": 5244
  },
  {
    "cityId": 3835,
    "cityName": "São Geraldo do Baguari",
    "stateId": 5244
  },
  {
    "cityId": 3836,
    "cityName": "São Geraldo do Baixio",
    "stateId": 5244
  },
  {
    "cityId": 3837,
    "cityName": "São Gonçalo de Botelhos",
    "stateId": 5244
  },
  {
    "cityId": 3838,
    "cityName": "São Gonçalo do Abaeté",
    "stateId": 5244
  },
  {
    "cityId": 3839,
    "cityName": "São Gonçalo do Monte",
    "stateId": 5244
  },
  {
    "cityId": 3840,
    "cityName": "São Gonçalo do Pará",
    "stateId": 5244
  },
  {
    "cityId": 3841,
    "cityName": "São Gonçalo do Rio Abaixo",
    "stateId": 5244
  },
  {
    "cityId": 3842,
    "cityName": "São Gonçalo do Rio das Pedras",
    "stateId": 5244
  },
  {
    "cityId": 3843,
    "cityName": "São Gonçalo do Rio Preto",
    "stateId": 5244
  },
  {
    "cityId": 3844,
    "cityName": "São Gonçalo do Sapucaí",
    "stateId": 5244
  },
  {
    "cityId": 3845,
    "cityName": "São Gotardo",
    "stateId": 5244
  },
  {
    "cityId": 3846,
    "cityName": "São Jerônimo dos Poções",
    "stateId": 5244
  },
  {
    "cityId": 3847,
    "cityName": "São João Batista do Glória",
    "stateId": 5244
  },
  {
    "cityId": 3848,
    "cityName": "São João da Chapada",
    "stateId": 5244
  },
  {
    "cityId": 3849,
    "cityName": "São João da Lagoa",
    "stateId": 5244
  },
  {
    "cityId": 3850,
    "cityName": "São João da Mata",
    "stateId": 5244
  },
  {
    "cityId": 3851,
    "cityName": "São João da Ponte",
    "stateId": 5244
  },
  {
    "cityId": 3852,
    "cityName": "São João da Sapucaia",
    "stateId": 5244
  },
  {
    "cityId": 3853,
    "cityName": "São João da Serra",
    "stateId": 5244
  },
  {
    "cityId": 3854,
    "cityName": "São João da Serra Negra",
    "stateId": 5244
  },
  {
    "cityId": 3855,
    "cityName": "São João da Vereda",
    "stateId": 5244
  },
  {
    "cityId": 3856,
    "cityName": "São João das Missões",
    "stateId": 5244
  },
  {
    "cityId": 3857,
    "cityName": "São João Del Rei",
    "stateId": 5244
  },
  {
    "cityId": 3858,
    "cityName": "São João do Bonito",
    "stateId": 5244
  },
  {
    "cityId": 3859,
    "cityName": "São João do Jacutinga",
    "stateId": 5244
  },
  {
    "cityId": 3860,
    "cityName": "São João do Manhuaçu",
    "stateId": 5244
  },
  {
    "cityId": 3861,
    "cityName": "São João do Manteninha",
    "stateId": 5244
  },
  {
    "cityId": 3862,
    "cityName": "São João do Oriente",
    "stateId": 5244
  },
  {
    "cityId": 3863,
    "cityName": "São João do Pacuí",
    "stateId": 5244
  },
  {
    "cityId": 3864,
    "cityName": "São João do Paraíso",
    "stateId": 5244
  },
  {
    "cityId": 3865,
    "cityName": "São João Evangelista",
    "stateId": 5244
  },
  {
    "cityId": 3866,
    "cityName": "São João Nepomuceno",
    "stateId": 5244
  },
  {
    "cityId": 3867,
    "cityName": "São Joaquim",
    "stateId": 5244
  },
  {
    "cityId": 3868,
    "cityName": "São Joaquim",
    "stateId": 5244
  },
  {
    "cityId": 3869,
    "cityName": "São Joaquim de Bicas",
    "stateId": 5244
  },
  {
    "cityId": 3870,
    "cityName": "São José da Barra",
    "stateId": 5244
  },
  {
    "cityId": 3871,
    "cityName": "São José da Bela Vista",
    "stateId": 5244
  },
  {
    "cityId": 3872,
    "cityName": "São José da Lapa",
    "stateId": 5244
  },
  {
    "cityId": 3873,
    "cityName": "São José da Pedra Menina",
    "stateId": 5244
  },
  {
    "cityId": 3874,
    "cityName": "São José da Safira",
    "stateId": 5244
  },
  {
    "cityId": 3875,
    "cityName": "São José da Varginha",
    "stateId": 5244
  },
  {
    "cityId": 3876,
    "cityName": "Goiabal",
    "stateId": 5244
  },
  {
    "cityId": 3877,
    "cityName": "São José do Acácio",
    "stateId": 5244
  },
  {
    "cityId": 3878,
    "cityName": "São José do Alegre",
    "stateId": 5244
  },
  {
    "cityId": 3879,
    "cityName": "São José do Barreiro",
    "stateId": 5244
  },
  {
    "cityId": 3880,
    "cityName": "São José do Batatal",
    "stateId": 5244
  },
  {
    "cityId": 3881,
    "cityName": "São José do Buriti",
    "stateId": 5244
  },
  {
    "cityId": 3882,
    "cityName": "São José do Divino",
    "stateId": 5244
  },
  {
    "cityId": 3883,
    "cityName": "São José do Goiabal",
    "stateId": 5244
  },
  {
    "cityId": 3884,
    "cityName": "São José do Itueto",
    "stateId": 5244
  },
  {
    "cityId": 3885,
    "cityName": "São José do Jacuri",
    "stateId": 5244
  },
  {
    "cityId": 3886,
    "cityName": "São José do Mantimento",
    "stateId": 5244
  },
  {
    "cityId": 3887,
    "cityName": "São José do Mato Dentro",
    "stateId": 5244
  },
  {
    "cityId": 3888,
    "cityName": "São José do Pântano",
    "stateId": 5244
  },
  {
    "cityId": 3889,
    "cityName": "São José do Paraopeba",
    "stateId": 5244
  },
  {
    "cityId": 3890,
    "cityName": "São José dos Lopes",
    "stateId": 5244
  },
  {
    "cityId": 3891,
    "cityName": "São José dos Salgados",
    "stateId": 5244
  },
  {
    "cityId": 3892,
    "cityName": "São Lourenço",
    "stateId": 5244
  },
  {
    "cityId": 3893,
    "cityName": "São Manoel do Guaiaçu",
    "stateId": 5244
  },
  {
    "cityId": 3894,
    "cityName": "São Mateus de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3895,
    "cityName": "São Miguel do Anta",
    "stateId": 5244
  },
  {
    "cityId": 3896,
    "cityName": "São Pedro da Garça",
    "stateId": 5244
  },
  {
    "cityId": 3897,
    "cityName": "São Pedro da União",
    "stateId": 5244
  },
  {
    "cityId": 3898,
    "cityName": "São Pedro das Tabocas",
    "stateId": 5244
  },
  {
    "cityId": 3899,
    "cityName": "São Pedro de Caldas",
    "stateId": 5244
  },
  {
    "cityId": 3900,
    "cityName": "São Pedro do Avaí",
    "stateId": 5244
  },
  {
    "cityId": 3901,
    "cityName": "São Pedro do Glória",
    "stateId": 5244
  },
  {
    "cityId": 3902,
    "cityName": "São Pedro do Jequitinhonha",
    "stateId": 5244
  },
  {
    "cityId": 3903,
    "cityName": "São Pedro do Suaçuí",
    "stateId": 5244
  },
  {
    "cityId": 3904,
    "cityName": "São Pedro dos Ferros",
    "stateId": 5244
  },
  {
    "cityId": 3905,
    "cityName": "São Roberto",
    "stateId": 5244
  },
  {
    "cityId": 3906,
    "cityName": "São Romão",
    "stateId": 5244
  },
  {
    "cityId": 3907,
    "cityName": "São Roque de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3908,
    "cityName": "São Sebastião da Barra",
    "stateId": 5244
  },
  {
    "cityId": 3909,
    "cityName": "São Sebastião da Bela Vista",
    "stateId": 5244
  },
  {
    "cityId": 3910,
    "cityName": "São Sebastião da Vala",
    "stateId": 5244
  },
  {
    "cityId": 3911,
    "cityName": "São Sebastião da Vargem Alegre",
    "stateId": 5244
  },
  {
    "cityId": 3912,
    "cityName": "São Sebastião da Vitória",
    "stateId": 5244
  },
  {
    "cityId": 3913,
    "cityName": "São Sebastião de Braúnas",
    "stateId": 5244
  },
  {
    "cityId": 3914,
    "cityName": "São Sebastião do Anta",
    "stateId": 5244
  },
  {
    "cityId": 3915,
    "cityName": "São Sebastião do Baixio",
    "stateId": 5244
  },
  {
    "cityId": 3916,
    "cityName": "São Sebastião do Barreado",
    "stateId": 5244
  },
  {
    "cityId": 3917,
    "cityName": "São Sebastião do Barreiro",
    "stateId": 5244
  },
  {
    "cityId": 3918,
    "cityName": "São Sebastião do Bonsucesso",
    "stateId": 5244
  },
  {
    "cityId": 3919,
    "cityName": "São Sebastião do Bugre",
    "stateId": 5244
  },
  {
    "cityId": 3920,
    "cityName": "São Sebastião do Gil",
    "stateId": 5244
  },
  {
    "cityId": 3921,
    "cityName": "São Sebastião do Maranhão",
    "stateId": 5244
  },
  {
    "cityId": 3922,
    "cityName": "São Sebastião do Óculo",
    "stateId": 5244
  },
  {
    "cityId": 3923,
    "cityName": "São Sebastião do Oeste",
    "stateId": 5244
  },
  {
    "cityId": 3924,
    "cityName": "São Sebastião do Paraíso",
    "stateId": 5244
  },
  {
    "cityId": 3925,
    "cityName": "São Sebastião do Pontal",
    "stateId": 5244
  },
  {
    "cityId": 3926,
    "cityName": "São Sebastião do Rio Preto",
    "stateId": 5244
  },
  {
    "cityId": 3927,
    "cityName": "São Sebastião do Rio Verde",
    "stateId": 5244
  },
  {
    "cityId": 3928,
    "cityName": "São Sebastião do Sacramento",
    "stateId": 5244
  },
  {
    "cityId": 3929,
    "cityName": "São Sebastião do Soberbo",
    "stateId": 5244
  },
  {
    "cityId": 3930,
    "cityName": "São Sebastião dos Poções",
    "stateId": 5244
  },
  {
    "cityId": 3931,
    "cityName": "São Sebastião dos Robertos",
    "stateId": 5244
  },
  {
    "cityId": 3932,
    "cityName": "São Sebastião dos Torres",
    "stateId": 5244
  },
  {
    "cityId": 3933,
    "cityName": "São Tiago",
    "stateId": 5244
  },
  {
    "cityId": 3934,
    "cityName": "São Tomás de Aquino",
    "stateId": 5244
  },
  {
    "cityId": 3935,
    "cityName": "São Thomé das Letras",
    "stateId": 5244
  },
  {
    "cityId": 3936,
    "cityName": "São Vicente",
    "stateId": 5244
  },
  {
    "cityId": 3937,
    "cityName": "São Vicente da Estrela",
    "stateId": 5244
  },
  {
    "cityId": 3938,
    "cityName": "São Vicente de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3939,
    "cityName": "São Vicente do Grama",
    "stateId": 5244
  },
  {
    "cityId": 3940,
    "cityName": "São Vicente do Rio Doce",
    "stateId": 5244
  },
  {
    "cityId": 3941,
    "cityName": "São Vítor",
    "stateId": 5244
  },
  {
    "cityId": 3942,
    "cityName": "Sapucaí",
    "stateId": 5244
  },
  {
    "cityId": 3943,
    "cityName": "Sapucaí-Mirim",
    "stateId": 5244
  },
  {
    "cityId": 3944,
    "cityName": "Sapucaia",
    "stateId": 5244
  },
  {
    "cityId": 3945,
    "cityName": "Sapucaia de Guanhães",
    "stateId": 5244
  },
  {
    "cityId": 3946,
    "cityName": "Sapucaia do Norte",
    "stateId": 5244
  },
  {
    "cityId": 3947,
    "cityName": "Sarandira",
    "stateId": 5244
  },
  {
    "cityId": 3948,
    "cityName": "Sardoá",
    "stateId": 5244
  },
  {
    "cityId": 3949,
    "cityName": "Sarzedo",
    "stateId": 5244
  },
  {
    "cityId": 3950,
    "cityName": "Saudade",
    "stateId": 5244
  },
  {
    "cityId": 3951,
    "cityName": "Sem Peixe",
    "stateId": 5244
  },
  {
    "cityId": 3952,
    "cityName": "Senador Amaral",
    "stateId": 5244
  },
  {
    "cityId": 3953,
    "cityName": "Senador Cortes",
    "stateId": 5244
  },
  {
    "cityId": 3954,
    "cityName": "Senador Firmino",
    "stateId": 5244
  },
  {
    "cityId": 3955,
    "cityName": "Senador José Bento",
    "stateId": 5244
  },
  {
    "cityId": 3956,
    "cityName": "Senador Melo Viana",
    "stateId": 5244
  },
  {
    "cityId": 3957,
    "cityName": "Senador Modestino Gonçalves",
    "stateId": 5244
  },
  {
    "cityId": 3958,
    "cityName": "Senador Mourão",
    "stateId": 5244
  },
  {
    "cityId": 3959,
    "cityName": "Senhora da Glória",
    "stateId": 5244
  },
  {
    "cityId": 3960,
    "cityName": "Senhora da Penha",
    "stateId": 5244
  },
  {
    "cityId": 3961,
    "cityName": "Senhora das Dores",
    "stateId": 5244
  },
  {
    "cityId": 3962,
    "cityName": "Senhora de Oliveira",
    "stateId": 5244
  },
  {
    "cityId": 3963,
    "cityName": "Senhora do Carmo",
    "stateId": 5244
  },
  {
    "cityId": 3964,
    "cityName": "Senhora do Porto",
    "stateId": 5244
  },
  {
    "cityId": 3965,
    "cityName": "Senhora dos Remédios",
    "stateId": 5244
  },
  {
    "cityId": 3966,
    "cityName": "Sereno",
    "stateId": 5244
  },
  {
    "cityId": 3967,
    "cityName": "Sericita",
    "stateId": 5244
  },
  {
    "cityId": 3968,
    "cityName": "Seritinga",
    "stateId": 5244
  },
  {
    "cityId": 3969,
    "cityName": "Serra Azul",
    "stateId": 5244
  },
  {
    "cityId": 3970,
    "cityName": "Serra Azul de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3971,
    "cityName": "Serra Bonita",
    "stateId": 5244
  },
  {
    "cityId": 3972,
    "cityName": "Serra da Canastra",
    "stateId": 5244
  },
  {
    "cityId": 3973,
    "cityName": "Serra da Saudade",
    "stateId": 5244
  },
  {
    "cityId": 3974,
    "cityName": "Serra das Araras",
    "stateId": 5244
  },
  {
    "cityId": 3975,
    "cityName": "Serra do Camapuã",
    "stateId": 5244
  },
  {
    "cityId": 3976,
    "cityName": "Serra do Salitre",
    "stateId": 5244
  },
  {
    "cityId": 3977,
    "cityName": "Serra dos Aimorés",
    "stateId": 5244
  },
  {
    "cityId": 3978,
    "cityName": "Serra dos Lemes",
    "stateId": 5244
  },
  {
    "cityId": 3979,
    "cityName": "Serra Nova",
    "stateId": 5244
  },
  {
    "cityId": 3980,
    "cityName": "Serrania",
    "stateId": 5244
  },
  {
    "cityId": 3981,
    "cityName": "Serranópolis de Minas",
    "stateId": 5244
  },
  {
    "cityId": 3982,
    "cityName": "Serranos",
    "stateId": 5244
  },
  {
    "cityId": 3983,
    "cityName": "Serro",
    "stateId": 5244
  },
  {
    "cityId": 3984,
    "cityName": "Sertãozinho",
    "stateId": 5244
  },
  {
    "cityId": 3985,
    "cityName": "Sete Cachoeiras",
    "stateId": 5244
  },
  {
    "cityId": 3986,
    "cityName": "Sete Lagoas",
    "stateId": 5244
  },
  {
    "cityId": 3987,
    "cityName": "Setubinha",
    "stateId": 5244
  },
  {
    "cityId": 3988,
    "cityName": "Silva Campos",
    "stateId": 5244
  },
  {
    "cityId": 3989,
    "cityName": "Silva Xavier",
    "stateId": 5244
  },
  {
    "cityId": 3990,
    "cityName": "Silvano",
    "stateId": 5244
  },
  {
    "cityId": 3991,
    "cityName": "Silveira Carvalho",
    "stateId": 5244
  },
  {
    "cityId": 3992,
    "cityName": "Silveirânia",
    "stateId": 5244
  },
  {
    "cityId": 3993,
    "cityName": "Silvestre",
    "stateId": 5244
  },
  {
    "cityId": 3994,
    "cityName": "Silvianópolis",
    "stateId": 5244
  },
  {
    "cityId": 3995,
    "cityName": "Simão Campos",
    "stateId": 5244
  },
  {
    "cityId": 3996,
    "cityName": "Simão Pereira",
    "stateId": 5244
  },
  {
    "cityId": 3997,
    "cityName": "Simonésia",
    "stateId": 5244
  },
  {
    "cityId": 3998,
    "cityName": "Sobral Pinto",
    "stateId": 5244
  },
  {
    "cityId": 3999,
    "cityName": "Sobrália",
    "stateId": 5244
  },
  {
    "cityId": 4000,
    "cityName": "Soledade de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4001,
    "cityName": "Sopa",
    "stateId": 5244
  },
  {
    "cityId": 4002,
    "cityName": "Tabajara",
    "stateId": 5244
  },
  {
    "cityId": 4003,
    "cityName": "Tabaúna",
    "stateId": 5244
  },
  {
    "cityId": 4004,
    "cityName": "Tabuão",
    "stateId": 5244
  },
  {
    "cityId": 4005,
    "cityName": "Tabuleiro",
    "stateId": 5244
  },
  {
    "cityId": 4006,
    "cityName": "Taiobeiras",
    "stateId": 5244
  },
  {
    "cityId": 4007,
    "cityName": "Taparuba",
    "stateId": 5244
  },
  {
    "cityId": 4008,
    "cityName": "Tapira",
    "stateId": 5244
  },
  {
    "cityId": 4009,
    "cityName": "Tapiraí",
    "stateId": 5244
  },
  {
    "cityId": 4010,
    "cityName": "Tapuirama",
    "stateId": 5244
  },
  {
    "cityId": 4011,
    "cityName": "Taquaraçu de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4012,
    "cityName": "Taruaçu",
    "stateId": 5244
  },
  {
    "cityId": 4013,
    "cityName": "Tarumirim",
    "stateId": 5244
  },
  {
    "cityId": 4014,
    "cityName": "Tebas",
    "stateId": 5244
  },
  {
    "cityId": 4015,
    "cityName": "Teixeiras",
    "stateId": 5244
  },
  {
    "cityId": 4016,
    "cityName": "Tejuco",
    "stateId": 5244
  },
  {
    "cityId": 4017,
    "cityName": "Teófilo Otoni",
    "stateId": 5244
  },
  {
    "cityId": 4018,
    "cityName": "Terra Branca",
    "stateId": 5244
  },
  {
    "cityId": 4019,
    "cityName": "Timóteo",
    "stateId": 5244
  },
  {
    "cityId": 4020,
    "cityName": "Tiradentes",
    "stateId": 5244
  },
  {
    "cityId": 4021,
    "cityName": "Tiros",
    "stateId": 5244
  },
  {
    "cityId": 4022,
    "cityName": "Tobati",
    "stateId": 5244
  },
  {
    "cityId": 4023,
    "cityName": "Tocandira",
    "stateId": 5244
  },
  {
    "cityId": 4024,
    "cityName": "Tocantins",
    "stateId": 5244
  },
  {
    "cityId": 4025,
    "cityName": "Tocos do Moji",
    "stateId": 5244
  },
  {
    "cityId": 4026,
    "cityName": "Toledo",
    "stateId": 5244
  },
  {
    "cityId": 4027,
    "cityName": "Tomás Gonzaga",
    "stateId": 5244
  },
  {
    "cityId": 4028,
    "cityName": "Tombos",
    "stateId": 5244
  },
  {
    "cityId": 4029,
    "cityName": "Topázio",
    "stateId": 5244
  },
  {
    "cityId": 4030,
    "cityName": "Torneiros",
    "stateId": 5244
  },
  {
    "cityId": 4031,
    "cityName": "Torreões",
    "stateId": 5244
  },
  {
    "cityId": 4032,
    "cityName": "Três Corações",
    "stateId": 5244
  },
  {
    "cityId": 4033,
    "cityName": "Três Ilhas",
    "stateId": 5244
  },
  {
    "cityId": 4034,
    "cityName": "Três Marias",
    "stateId": 5244
  },
  {
    "cityId": 4035,
    "cityName": "Três Pontas",
    "stateId": 5244
  },
  {
    "cityId": 4036,
    "cityName": "Trimonte",
    "stateId": 5244
  },
  {
    "cityId": 4037,
    "cityName": "Tuiutinga",
    "stateId": 5244
  },
  {
    "cityId": 4038,
    "cityName": "Tumiritinga",
    "stateId": 5244
  },
  {
    "cityId": 4039,
    "cityName": "Tupaciguara",
    "stateId": 5244
  },
  {
    "cityId": 4040,
    "cityName": "Tuparecê",
    "stateId": 5244
  },
  {
    "cityId": 4041,
    "cityName": "Turmalina",
    "stateId": 5244
  },
  {
    "cityId": 4042,
    "cityName": "Turvolândia",
    "stateId": 5244
  },
  {
    "cityId": 4043,
    "cityName": "Ubá",
    "stateId": 5244
  },
  {
    "cityId": 4044,
    "cityName": "Ubaí",
    "stateId": 5244
  },
  {
    "cityId": 4045,
    "cityName": "Ubaporanga",
    "stateId": 5244
  },
  {
    "cityId": 4046,
    "cityName": "Ubari",
    "stateId": 5244
  },
  {
    "cityId": 4047,
    "cityName": "Uberaba",
    "stateId": 5244
  },
  {
    "cityId": 4048,
    "cityName": "Uberlândia",
    "stateId": 5244
  },
  {
    "cityId": 4049,
    "cityName": "Umburatiba",
    "stateId": 5244
  },
  {
    "cityId": 4050,
    "cityName": "Umbuzeiro",
    "stateId": 5244
  },
  {
    "cityId": 4051,
    "cityName": "Unaí",
    "stateId": 5244
  },
  {
    "cityId": 4052,
    "cityName": "União de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4053,
    "cityName": "Uruana de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4054,
    "cityName": "Urucânia",
    "stateId": 5244
  },
  {
    "cityId": 4055,
    "cityName": "Urucuia",
    "stateId": 5244
  },
  {
    "cityId": 4056,
    "cityName": "Usina Monte Alegre",
    "stateId": 5244
  },
  {
    "cityId": 4057,
    "cityName": "Vai-Volta",
    "stateId": 5244
  },
  {
    "cityId": 4058,
    "cityName": "Valadares",
    "stateId": 5244
  },
  {
    "cityId": 4059,
    "cityName": "Valão",
    "stateId": 5244
  },
  {
    "cityId": 4060,
    "cityName": "Vale Verde de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4061,
    "cityName": "Valo Fundo",
    "stateId": 5244
  },
  {
    "cityId": 4062,
    "cityName": "Vargem Alegre",
    "stateId": 5244
  },
  {
    "cityId": 4063,
    "cityName": "Vargem Bonita",
    "stateId": 5244
  },
  {
    "cityId": 4064,
    "cityName": "Vargem Grande do Rio Pardo",
    "stateId": 5244
  },
  {
    "cityId": 4065,
    "cityName": "Vargem Linda",
    "stateId": 5244
  },
  {
    "cityId": 4066,
    "cityName": "Varginha",
    "stateId": 5244
  },
  {
    "cityId": 4067,
    "cityName": "Varjão de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4068,
    "cityName": "Várzea da Palma",
    "stateId": 5244
  },
  {
    "cityId": 4069,
    "cityName": "Varzelândia",
    "stateId": 5244
  },
  {
    "cityId": 4070,
    "cityName": "Vau-Açu",
    "stateId": 5244
  },
  {
    "cityId": 4071,
    "cityName": "Vazante",
    "stateId": 5244
  },
  {
    "cityId": 4072,
    "cityName": "Vera Cruz de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4073,
    "cityName": "Verdelândia",
    "stateId": 5244
  },
  {
    "cityId": 4074,
    "cityName": "Vereda do Paraíso",
    "stateId": 5244
  },
  {
    "cityId": 4075,
    "cityName": "Veredas",
    "stateId": 5244
  },
  {
    "cityId": 4076,
    "cityName": "Veredinha",
    "stateId": 5244
  },
  {
    "cityId": 4077,
    "cityName": "Veríssimo",
    "stateId": 5244
  },
  {
    "cityId": 4078,
    "cityName": "Vermelho",
    "stateId": 5244
  },
  {
    "cityId": 4079,
    "cityName": "Vermelho Novo",
    "stateId": 5244
  },
  {
    "cityId": 4080,
    "cityName": "Vermelho Velho",
    "stateId": 5244
  },
  {
    "cityId": 4081,
    "cityName": "Vespasiano",
    "stateId": 5244
  },
  {
    "cityId": 4082,
    "cityName": "Viçosa",
    "stateId": 5244
  },
  {
    "cityId": 4083,
    "cityName": "Vieiras",
    "stateId": 5244
  },
  {
    "cityId": 4084,
    "cityName": "Vila Bom Jesus",
    "stateId": 5244
  },
  {
    "cityId": 4085,
    "cityName": "Vila Costina",
    "stateId": 5244
  },
  {
    "cityId": 4086,
    "cityName": "Vila Nova de Minas",
    "stateId": 5244
  },
  {
    "cityId": 4087,
    "cityName": "Vila Nova dos Poções",
    "stateId": 5244
  },
  {
    "cityId": 4088,
    "cityName": "Vila Pereira",
    "stateId": 5244
  },
  {
    "cityId": 4089,
    "cityName": "Vilas Boas",
    "stateId": 5244
  },
  {
    "cityId": 4090,
    "cityName": "Virgem da Lapa",
    "stateId": 5244
  },
  {
    "cityId": 4091,
    "cityName": "Virgínia",
    "stateId": 5244
  },
  {
    "cityId": 4092,
    "cityName": "Virginópolis",
    "stateId": 5244
  },
  {
    "cityId": 4093,
    "cityName": "Virgolândia",
    "stateId": 5244
  },
  {
    "cityId": 4094,
    "cityName": "Visconde do Rio Branco",
    "stateId": 5244
  },
  {
    "cityId": 4095,
    "cityName": "Vista Alegre",
    "stateId": 5244
  },
  {
    "cityId": 4096,
    "cityName": "Vista Alegre",
    "stateId": 5244
  },
  {
    "cityId": 4097,
    "cityName": "Vitorinos",
    "stateId": 5244
  },
  {
    "cityId": 4098,
    "cityName": "Volta Grande",
    "stateId": 5244
  },
  {
    "cityId": 4099,
    "cityName": "Wenceslau Braz",
    "stateId": 5244
  },
  {
    "cityId": 4100,
    "cityName": "Zelândia",
    "stateId": 5244
  },
  {
    "cityId": 4101,
    "cityName": "Zito Soares",
    "stateId": 5244
  },
  {
    "cityId": 4102,
    "cityName": "Água Boa",
    "stateId": 5245
  },
  {
    "cityId": 4103,
    "cityName": "Água Clara",
    "stateId": 5245
  },
  {
    "cityId": 4104,
    "cityName": "Albuquerque",
    "stateId": 5245
  },
  {
    "cityId": 4105,
    "cityName": "Alcinópolis",
    "stateId": 5245
  },
  {
    "cityId": 4106,
    "cityName": "Alto Sucuriú",
    "stateId": 5245
  },
  {
    "cityId": 4107,
    "cityName": "Amambaí",
    "stateId": 5245
  },
  {
    "cityId": 4108,
    "cityName": "Amandina",
    "stateId": 5245
  },
  {
    "cityId": 4109,
    "cityName": "Amolar",
    "stateId": 5245
  },
  {
    "cityId": 4110,
    "cityName": "Anastácio",
    "stateId": 5245
  },
  {
    "cityId": 4111,
    "cityName": "Anaurilândia",
    "stateId": 5245
  },
  {
    "cityId": 4112,
    "cityName": "Angélica",
    "stateId": 5245
  },
  {
    "cityId": 4113,
    "cityName": "Anhandui",
    "stateId": 5245
  },
  {
    "cityId": 4114,
    "cityName": "Antônio João",
    "stateId": 5245
  },
  {
    "cityId": 4115,
    "cityName": "Aparecida do Taboado",
    "stateId": 5245
  },
  {
    "cityId": 4116,
    "cityName": "Aquidauana",
    "stateId": 5245
  },
  {
    "cityId": 4117,
    "cityName": "Aral Moreira",
    "stateId": 5245
  },
  {
    "cityId": 4118,
    "cityName": "Arapuã",
    "stateId": 5245
  },
  {
    "cityId": 4119,
    "cityName": "Areado",
    "stateId": 5245
  },
  {
    "cityId": 4120,
    "cityName": "Árvore Grande",
    "stateId": 5245
  },
  {
    "cityId": 4121,
    "cityName": "Baianópolis",
    "stateId": 5245
  },
  {
    "cityId": 4122,
    "cityName": "Bálsamo",
    "stateId": 5245
  },
  {
    "cityId": 4123,
    "cityName": "Bandeirantes",
    "stateId": 5245
  },
  {
    "cityId": 4124,
    "cityName": "Bataguassu",
    "stateId": 5245
  },
  {
    "cityId": 4125,
    "cityName": "Batayporã",
    "stateId": 5245
  },
  {
    "cityId": 4126,
    "cityName": "Baús",
    "stateId": 5245
  },
  {
    "cityId": 4127,
    "cityName": "Bela Vista",
    "stateId": 5245
  },
  {
    "cityId": 4128,
    "cityName": "Bocajá",
    "stateId": 5245
  },
  {
    "cityId": 4129,
    "cityName": "Bocajá",
    "stateId": 5245
  },
  {
    "cityId": 4130,
    "cityName": "Bodoquena",
    "stateId": 5245
  },
  {
    "cityId": 4131,
    "cityName": "Bom Fim",
    "stateId": 5245
  },
  {
    "cityId": 4132,
    "cityName": "Bonito",
    "stateId": 5245
  },
  {
    "cityId": 4133,
    "cityName": "Boqueirão",
    "stateId": 5245
  },
  {
    "cityId": 4134,
    "cityName": "Brasilândia",
    "stateId": 5245
  },
  {
    "cityId": 4135,
    "cityName": "Caarapó",
    "stateId": 5245
  },
  {
    "cityId": 4136,
    "cityName": "Cabeceira do Apá",
    "stateId": 5245
  },
  {
    "cityId": 4137,
    "cityName": "Cachoeira",
    "stateId": 5245
  },
  {
    "cityId": 4138,
    "cityName": "Camapuã",
    "stateId": 5245
  },
  {
    "cityId": 4139,
    "cityName": "Camisão",
    "stateId": 5245
  },
  {
    "cityId": 4140,
    "cityName": "Campestre",
    "stateId": 5245
  },
  {
    "cityId": 4141,
    "cityName": "Campo Grande",
    "stateId": 5245
  },
  {
    "cityId": 4142,
    "cityName": "Capão Seco",
    "stateId": 5245
  },
  {
    "cityId": 4143,
    "cityName": "Caracol",
    "stateId": 5245
  },
  {
    "cityId": 4144,
    "cityName": "Carumbé",
    "stateId": 5245
  },
  {
    "cityId": 4145,
    "cityName": "Cassilândia",
    "stateId": 5245
  },
  {
    "cityId": 4146,
    "cityName": "Chapadão do Sul",
    "stateId": 5245
  },
  {
    "cityId": 4147,
    "cityName": "Cipolândia",
    "stateId": 5245
  },
  {
    "cityId": 4148,
    "cityName": "Coimbra",
    "stateId": 5245
  },
  {
    "cityId": 4149,
    "cityName": "Congonhas",
    "stateId": 5245
  },
  {
    "cityId": 4150,
    "cityName": "Corguinho",
    "stateId": 5245
  },
  {
    "cityId": 4151,
    "cityName": "Coronel Sapucaia",
    "stateId": 5245
  },
  {
    "cityId": 4152,
    "cityName": "Corumbá",
    "stateId": 5245
  },
  {
    "cityId": 4153,
    "cityName": "Costa Rica",
    "stateId": 5245
  },
  {
    "cityId": 4154,
    "cityName": "Coxim",
    "stateId": 5245
  },
  {
    "cityId": 4155,
    "cityName": "Cristalina",
    "stateId": 5245
  },
  {
    "cityId": 4156,
    "cityName": "Cruzaltina",
    "stateId": 5245
  },
  {
    "cityId": 4157,
    "cityName": "Culturama",
    "stateId": 5245
  },
  {
    "cityId": 4158,
    "cityName": "Cupins",
    "stateId": 5245
  },
  {
    "cityId": 4159,
    "cityName": "Debrasa",
    "stateId": 5245
  },
  {
    "cityId": 4160,
    "cityName": "Deodápolis",
    "stateId": 5245
  },
  {
    "cityId": 4161,
    "cityName": "Dois Irmãos do Buriti",
    "stateId": 5245
  },
  {
    "cityId": 4162,
    "cityName": "Douradina",
    "stateId": 5245
  },
  {
    "cityId": 4163,
    "cityName": "Dourados",
    "stateId": 5245
  },
  {
    "cityId": 4164,
    "cityName": "Eldorado",
    "stateId": 5245
  },
  {
    "cityId": 4165,
    "cityName": "Fátima do Sul",
    "stateId": 5245
  },
  {
    "cityId": 4166,
    "cityName": "Figueirão",
    "stateId": 5245
  },
  {
    "cityId": 4167,
    "cityName": "Garcias",
    "stateId": 5245
  },
  {
    "cityId": 4168,
    "cityName": "Glória de Dourados",
    "stateId": 5245
  },
  {
    "cityId": 4169,
    "cityName": "Guaçu",
    "stateId": 5245
  },
  {
    "cityId": 4170,
    "cityName": "Guaçulândia",
    "stateId": 5245
  },
  {
    "cityId": 4171,
    "cityName": "Guadalupe do Alto Paraná",
    "stateId": 5245
  },
  {
    "cityId": 4172,
    "cityName": "Guia Lopes da Laguna",
    "stateId": 5245
  },
  {
    "cityId": 4173,
    "cityName": "Iguatemi",
    "stateId": 5245
  },
  {
    "cityId": 4174,
    "cityName": "Ilha Comprida",
    "stateId": 5245
  },
  {
    "cityId": 4175,
    "cityName": "Ilha Grande",
    "stateId": 5245
  },
  {
    "cityId": 4176,
    "cityName": "Indaiá do Sul",
    "stateId": 5245
  },
  {
    "cityId": 4177,
    "cityName": "Indaiá Grande",
    "stateId": 5245
  },
  {
    "cityId": 4178,
    "cityName": "Indápolis",
    "stateId": 5245
  },
  {
    "cityId": 4179,
    "cityName": "Inocência",
    "stateId": 5245
  },
  {
    "cityId": 4180,
    "cityName": "Ipezal",
    "stateId": 5245
  },
  {
    "cityId": 4181,
    "cityName": "Itahum",
    "stateId": 5245
  },
  {
    "cityId": 4182,
    "cityName": "Itaporã",
    "stateId": 5245
  },
  {
    "cityId": 4183,
    "cityName": "Itaquiraí",
    "stateId": 5245
  },
  {
    "cityId": 4184,
    "cityName": "Ivinhema",
    "stateId": 5245
  },
  {
    "cityId": 4185,
    "cityName": "Jabuti",
    "stateId": 5245
  },
  {
    "cityId": 4186,
    "cityName": "Jacareí",
    "stateId": 5245
  },
  {
    "cityId": 4187,
    "cityName": "Japorã",
    "stateId": 5245
  },
  {
    "cityId": 4188,
    "cityName": "Jaraguari",
    "stateId": 5245
  },
  {
    "cityId": 4189,
    "cityName": "Jardim",
    "stateId": 5245
  },
  {
    "cityId": 4190,
    "cityName": "Jateí",
    "stateId": 5245
  },
  {
    "cityId": 4191,
    "cityName": "Jauru",
    "stateId": 5245
  },
  {
    "cityId": 4192,
    "cityName": "Juscelândia",
    "stateId": 5245
  },
  {
    "cityId": 4193,
    "cityName": "Jutí",
    "stateId": 5245
  },
  {
    "cityId": 4194,
    "cityName": "Ladário",
    "stateId": 5245
  },
  {
    "cityId": 4195,
    "cityName": "Lagoa Bonita",
    "stateId": 5245
  },
  {
    "cityId": 4196,
    "cityName": "Laguna Carapã",
    "stateId": 5245
  },
  {
    "cityId": 4197,
    "cityName": "Maracaju",
    "stateId": 5245
  },
  {
    "cityId": 4198,
    "cityName": "Miranda",
    "stateId": 5245
  },
  {
    "cityId": 4199,
    "cityName": "Montese",
    "stateId": 5245
  },
  {
    "cityId": 4200,
    "cityName": "Morangas",
    "stateId": 5245
  },
  {
    "cityId": 4201,
    "cityName": "Morraria do Sul",
    "stateId": 5245
  },
  {
    "cityId": 4202,
    "cityName": "Morumbi",
    "stateId": 5245
  },
  {
    "cityId": 4203,
    "cityName": "Mundo Novo",
    "stateId": 5245
  },
  {
    "cityId": 4204,
    "cityName": "Naviraí",
    "stateId": 5245
  },
  {
    "cityId": 4205,
    "cityName": "Nhecolândia",
    "stateId": 5245
  },
  {
    "cityId": 4206,
    "cityName": "Nioaque",
    "stateId": 5245
  },
  {
    "cityId": 4207,
    "cityName": "Nossa Senhora de Fátima",
    "stateId": 5245
  },
  {
    "cityId": 4208,
    "cityName": "Nova Alvorada do Sul",
    "stateId": 5245
  },
  {
    "cityId": 4209,
    "cityName": "Nova América",
    "stateId": 5245
  },
  {
    "cityId": 4210,
    "cityName": "Nova Andradina",
    "stateId": 5245
  },
  {
    "cityId": 4211,
    "cityName": "Nova Esperança",
    "stateId": 5245
  },
  {
    "cityId": 4212,
    "cityName": "Nova Esperança",
    "stateId": 5245
  },
  {
    "cityId": 4213,
    "cityName": "Nova Jales",
    "stateId": 5245
  },
  {
    "cityId": 4214,
    "cityName": "Novo Horizonte do Sul",
    "stateId": 5245
  },
  {
    "cityId": 4215,
    "cityName": "Oriente",
    "stateId": 5245
  },
  {
    "cityId": 4216,
    "cityName": "Paiaguás",
    "stateId": 5245
  },
  {
    "cityId": 4217,
    "cityName": "Palmeiras",
    "stateId": 5245
  },
  {
    "cityId": 4218,
    "cityName": "Panambi",
    "stateId": 5245
  },
  {
    "cityId": 4219,
    "cityName": "Paraíso",
    "stateId": 5245
  },
  {
    "cityId": 4220,
    "cityName": "Paranaíba",
    "stateId": 5245
  },
  {
    "cityId": 4221,
    "cityName": "Paranhos",
    "stateId": 5245
  },
  {
    "cityId": 4222,
    "cityName": "Pedro Gomes",
    "stateId": 5245
  },
  {
    "cityId": 4223,
    "cityName": "Picadinha",
    "stateId": 5245
  },
  {
    "cityId": 4224,
    "cityName": "Pirapora",
    "stateId": 5245
  },
  {
    "cityId": 4225,
    "cityName": "Piraputanga",
    "stateId": 5245
  },
  {
    "cityId": 4226,
    "cityName": "Ponta Porã",
    "stateId": 5245
  },
  {
    "cityId": 4227,
    "cityName": "Ponte Vermelha",
    "stateId": 5245
  },
  {
    "cityId": 4228,
    "cityName": "Pontinha do Cocho",
    "stateId": 5245
  },
  {
    "cityId": 4229,
    "cityName": "Porto Esperança",
    "stateId": 5245
  },
  {
    "cityId": 4230,
    "cityName": "Porto Murtinho",
    "stateId": 5245
  },
  {
    "cityId": 4231,
    "cityName": "Porto Vilma",
    "stateId": 5245
  },
  {
    "cityId": 4232,
    "cityName": "Porto XV de Novembro",
    "stateId": 5245
  },
  {
    "cityId": 4233,
    "cityName": "Presidente Castelo",
    "stateId": 5245
  },
  {
    "cityId": 4234,
    "cityName": "Prudêncio Thomaz",
    "stateId": 5245
  },
  {
    "cityId": 4235,
    "cityName": "Quebra Côco",
    "stateId": 5245
  },
  {
    "cityId": 4236,
    "cityName": "Quebracho",
    "stateId": 5245
  },
  {
    "cityId": 4237,
    "cityName": "Ribas do Rio Pardo",
    "stateId": 5245
  },
  {
    "cityId": 4238,
    "cityName": "Rio Brilhante",
    "stateId": 5245
  },
  {
    "cityId": 4239,
    "cityName": "Rio Negro",
    "stateId": 5245
  },
  {
    "cityId": 4240,
    "cityName": "Rio Verde de Mato Grosso",
    "stateId": 5245
  },
  {
    "cityId": 4241,
    "cityName": "Rochedinho",
    "stateId": 5245
  },
  {
    "cityId": 4242,
    "cityName": "Rochedo",
    "stateId": 5245
  },
  {
    "cityId": 4243,
    "cityName": "Sanga Puitã",
    "stateId": 5245
  },
  {
    "cityId": 4244,
    "cityName": "Santa Rita do Pardo",
    "stateId": 5245
  },
  {
    "cityId": 4245,
    "cityName": "Santa Terezinha",
    "stateId": 5245
  },
  {
    "cityId": 4246,
    "cityName": "São Gabriel do Oeste",
    "stateId": 5245
  },
  {
    "cityId": 4247,
    "cityName": "São João do Apore",
    "stateId": 5245
  },
  {
    "cityId": 4248,
    "cityName": "São José",
    "stateId": 5245
  },
  {
    "cityId": 4249,
    "cityName": "São José do Sucuriú",
    "stateId": 5245
  },
  {
    "cityId": 4250,
    "cityName": "São Pedro",
    "stateId": 5245
  },
  {
    "cityId": 4251,
    "cityName": "São Pedro",
    "stateId": 5245
  },
  {
    "cityId": 4252,
    "cityName": "São Romão",
    "stateId": 5245
  },
  {
    "cityId": 4253,
    "cityName": "Selvíria",
    "stateId": 5245
  },
  {
    "cityId": 4254,
    "cityName": "Sete Quedas",
    "stateId": 5245
  },
  {
    "cityId": 4255,
    "cityName": "Sidrolândia",
    "stateId": 5245
  },
  {
    "cityId": 4256,
    "cityName": "Sonora",
    "stateId": 5245
  },
  {
    "cityId": 4257,
    "cityName": "Tacuru",
    "stateId": 5245
  },
  {
    "cityId": 4258,
    "cityName": "Tamandaré",
    "stateId": 5245
  },
  {
    "cityId": 4259,
    "cityName": "Taquari",
    "stateId": 5245
  },
  {
    "cityId": 4260,
    "cityName": "Taquarussu",
    "stateId": 5245
  },
  {
    "cityId": 4261,
    "cityName": "Taunay",
    "stateId": 5245
  },
  {
    "cityId": 4262,
    "cityName": "Terenos",
    "stateId": 5245
  },
  {
    "cityId": 4263,
    "cityName": "Três Lagoas",
    "stateId": 5245
  },
  {
    "cityId": 4264,
    "cityName": "Velhacaria",
    "stateId": 5245
  },
  {
    "cityId": 4265,
    "cityName": "Vicentina",
    "stateId": 5245
  },
  {
    "cityId": 4266,
    "cityName": "Vila Formosa",
    "stateId": 5245
  },
  {
    "cityId": 4267,
    "cityName": "Vila Marques",
    "stateId": 5245
  },
  {
    "cityId": 4268,
    "cityName": "Vila Rica",
    "stateId": 5245
  },
  {
    "cityId": 4269,
    "cityName": "Vila União",
    "stateId": 5245
  },
  {
    "cityId": 4270,
    "cityName": "Vila Vargas",
    "stateId": 5245
  },
  {
    "cityId": 4271,
    "cityName": "Vista Alegre",
    "stateId": 5245
  },
  {
    "cityId": 4272,
    "cityName": "Acorizal",
    "stateId": 5246
  },
  {
    "cityId": 4273,
    "cityName": "Água Boa",
    "stateId": 5246
  },
  {
    "cityId": 4274,
    "cityName": "Água Fria",
    "stateId": 5246
  },
  {
    "cityId": 4275,
    "cityName": "Aguaçu",
    "stateId": 5246
  },
  {
    "cityId": 4276,
    "cityName": "Aguapeí",
    "stateId": 5246
  },
  {
    "cityId": 4277,
    "cityName": "Águas Claras",
    "stateId": 5246
  },
  {
    "cityId": 4278,
    "cityName": "Ainhumas",
    "stateId": 5246
  },
  {
    "cityId": 4279,
    "cityName": "Alcantilado",
    "stateId": 5246
  },
  {
    "cityId": 4280,
    "cityName": "Alta Floresta",
    "stateId": 5246
  },
  {
    "cityId": 4281,
    "cityName": "Alto Araguaia",
    "stateId": 5246
  },
  {
    "cityId": 4282,
    "cityName": "Alto Boa Vista",
    "stateId": 5246
  },
  {
    "cityId": 4283,
    "cityName": "Alto Coité",
    "stateId": 5246
  },
  {
    "cityId": 4284,
    "cityName": "Alto Garças",
    "stateId": 5246
  },
  {
    "cityId": 4285,
    "cityName": "Alto Juruena",
    "stateId": 5246
  },
  {
    "cityId": 4286,
    "cityName": "Alto Paraguai",
    "stateId": 5246
  },
  {
    "cityId": 4287,
    "cityName": "Alto Paraíso",
    "stateId": 5246
  },
  {
    "cityId": 4288,
    "cityName": "Alto Taquari",
    "stateId": 5246
  },
  {
    "cityId": 4289,
    "cityName": "Analândia do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4290,
    "cityName": "Apiacás",
    "stateId": 5246
  },
  {
    "cityId": 4291,
    "cityName": "Araguaiana",
    "stateId": 5246
  },
  {
    "cityId": 4292,
    "cityName": "Araguainha",
    "stateId": 5246
  },
  {
    "cityId": 4293,
    "cityName": "Araputanga",
    "stateId": 5246
  },
  {
    "cityId": 4294,
    "cityName": "Arenápolis",
    "stateId": 5246
  },
  {
    "cityId": 4295,
    "cityName": "Aripuanã",
    "stateId": 5246
  },
  {
    "cityId": 4296,
    "cityName": "Arruda",
    "stateId": 5246
  },
  {
    "cityId": 4297,
    "cityName": "Assari",
    "stateId": 5246
  },
  {
    "cityId": 4298,
    "cityName": "Barão de Melgaço",
    "stateId": 5246
  },
  {
    "cityId": 4299,
    "cityName": "Barra do Bugres",
    "stateId": 5246
  },
  {
    "cityId": 4300,
    "cityName": "Barra do Garças",
    "stateId": 5246
  },
  {
    "cityId": 4301,
    "cityName": "Batovi",
    "stateId": 5246
  },
  {
    "cityId": 4302,
    "cityName": "Baús",
    "stateId": 5246
  },
  {
    "cityId": 4303,
    "cityName": "Bauxi",
    "stateId": 5246
  },
  {
    "cityId": 4304,
    "cityName": "Bel Rios",
    "stateId": 5246
  },
  {
    "cityId": 4305,
    "cityName": "Bezerro Branco",
    "stateId": 5246
  },
  {
    "cityId": 4306,
    "cityName": "Boa Esperança",
    "stateId": 5246
  },
  {
    "cityId": 4307,
    "cityName": "Boa União",
    "stateId": 5246
  },
  {
    "cityId": 4308,
    "cityName": "Boa Vista",
    "stateId": 5246
  },
  {
    "cityId": 4309,
    "cityName": "Bom Sucesso",
    "stateId": 5246
  },
  {
    "cityId": 4310,
    "cityName": "Brasnorte",
    "stateId": 5246
  },
  {
    "cityId": 4311,
    "cityName": "Buriti",
    "stateId": 5246
  },
  {
    "cityId": 4312,
    "cityName": "Buriti",
    "stateId": 5246
  },
  {
    "cityId": 4313,
    "cityName": "Cáceres",
    "stateId": 5246
  },
  {
    "cityId": 4314,
    "cityName": "Caite",
    "stateId": 5246
  },
  {
    "cityId": 4315,
    "cityName": "Campinápolis",
    "stateId": 5246
  },
  {
    "cityId": 4316,
    "cityName": "Campo Novo do Parecis",
    "stateId": 5246
  },
  {
    "cityId": 4317,
    "cityName": "Campo Verde",
    "stateId": 5246
  },
  {
    "cityId": 4318,
    "cityName": "Campos de Júlio",
    "stateId": 5246
  },
  {
    "cityId": 4319,
    "cityName": "Campos Novos",
    "stateId": 5246
  },
  {
    "cityId": 4320,
    "cityName": "Canabrava do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4321,
    "cityName": "Canarana",
    "stateId": 5246
  },
  {
    "cityId": 4322,
    "cityName": "Cangas",
    "stateId": 5246
  },
  {
    "cityId": 4323,
    "cityName": "Capão Grande",
    "stateId": 5246
  },
  {
    "cityId": 4324,
    "cityName": "Capão Verde",
    "stateId": 5246
  },
  {
    "cityId": 4325,
    "cityName": "Caramujo",
    "stateId": 5246
  },
  {
    "cityId": 4326,
    "cityName": "Caravagio",
    "stateId": 5246
  },
  {
    "cityId": 4327,
    "cityName": "Carlinda",
    "stateId": 5246
  },
  {
    "cityId": 4328,
    "cityName": "Cassununga",
    "stateId": 5246
  },
  {
    "cityId": 4329,
    "cityName": "Castanheira",
    "stateId": 5246
  },
  {
    "cityId": 4330,
    "cityName": "Catuai",
    "stateId": 5246
  },
  {
    "cityId": 4331,
    "cityName": "Chapada dos Guimarães",
    "stateId": 5246
  },
  {
    "cityId": 4332,
    "cityName": "Cidade Morena",
    "stateId": 5246
  },
  {
    "cityId": 4333,
    "cityName": "Cláudia",
    "stateId": 5246
  },
  {
    "cityId": 4334,
    "cityName": "Cocalinho",
    "stateId": 5246
  },
  {
    "cityId": 4335,
    "cityName": "Colíder",
    "stateId": 5246
  },
  {
    "cityId": 4336,
    "cityName": "Colorado do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4337,
    "cityName": "Comodoro",
    "stateId": 5246
  },
  {
    "cityId": 4338,
    "cityName": "Confresa",
    "stateId": 5246
  },
  {
    "cityId": 4339,
    "cityName": "Coronel Ponce",
    "stateId": 5246
  },
  {
    "cityId": 4340,
    "cityName": "Cotrel",
    "stateId": 5246
  },
  {
    "cityId": 4341,
    "cityName": "Cotriguaçu",
    "stateId": 5246
  },
  {
    "cityId": 4342,
    "cityName": "Coxipó Açu",
    "stateId": 5246
  },
  {
    "cityId": 4343,
    "cityName": "Coxipó da Ponte",
    "stateId": 5246
  },
  {
    "cityId": 4344,
    "cityName": "Coxipó do Ouro",
    "stateId": 5246
  },
  {
    "cityId": 4345,
    "cityName": "Cristinópolis",
    "stateId": 5246
  },
  {
    "cityId": 4346,
    "cityName": "Cristo Rei",
    "stateId": 5246
  },
  {
    "cityId": 4347,
    "cityName": "Cuiabá",
    "stateId": 5246
  },
  {
    "cityId": 4348,
    "cityName": "Curvelândia",
    "stateId": 5246
  },
  {
    "cityId": 4349,
    "cityName": "Del Rios",
    "stateId": 5246
  },
  {
    "cityId": 4350,
    "cityName": "Denise",
    "stateId": 5246
  },
  {
    "cityId": 4351,
    "cityName": "Diamantino",
    "stateId": 5246
  },
  {
    "cityId": 4352,
    "cityName": "Dom Aquino",
    "stateId": 5246
  },
  {
    "cityId": 4353,
    "cityName": "Engenho",
    "stateId": 5246
  },
  {
    "cityId": 4354,
    "cityName": "Engenho Velho",
    "stateId": 5246
  },
  {
    "cityId": 4355,
    "cityName": "Entre Rios",
    "stateId": 5246
  },
  {
    "cityId": 4356,
    "cityName": "Estrela do Leste",
    "stateId": 5246
  },
  {
    "cityId": 4357,
    "cityName": "Faval",
    "stateId": 5246
  },
  {
    "cityId": 4358,
    "cityName": "Fazenda de Cima",
    "stateId": 5246
  },
  {
    "cityId": 4359,
    "cityName": "Feliz Natal",
    "stateId": 5246
  },
  {
    "cityId": 4360,
    "cityName": "Figueirópolis D'Oeste",
    "stateId": 5246
  },
  {
    "cityId": 4361,
    "cityName": "Filadélfia",
    "stateId": 5246
  },
  {
    "cityId": 4362,
    "cityName": "Flor da Serra",
    "stateId": 5246
  },
  {
    "cityId": 4363,
    "cityName": "Fontanilhas",
    "stateId": 5246
  },
  {
    "cityId": 4364,
    "cityName": "Gaúcha do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4365,
    "cityName": "General Carneiro",
    "stateId": 5246
  },
  {
    "cityId": 4366,
    "cityName": "Glória D'Oeste",
    "stateId": 5246
  },
  {
    "cityId": 4367,
    "cityName": "Guarantã do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4368,
    "cityName": "Guarita",
    "stateId": 5246
  },
  {
    "cityId": 4369,
    "cityName": "Guiratinga",
    "stateId": 5246
  },
  {
    "cityId": 4370,
    "cityName": "Horizonte do Oeste",
    "stateId": 5246
  },
  {
    "cityId": 4371,
    "cityName": "Indianápolis",
    "stateId": 5246
  },
  {
    "cityId": 4372,
    "cityName": "Indiavaí",
    "stateId": 5246
  },
  {
    "cityId": 4373,
    "cityName": "Irenópolis",
    "stateId": 5246
  },
  {
    "cityId": 4374,
    "cityName": "Itamarati Norte",
    "stateId": 5246
  },
  {
    "cityId": 4375,
    "cityName": "Itaúba",
    "stateId": 5246
  },
  {
    "cityId": 4376,
    "cityName": "Itiquira",
    "stateId": 5246
  },
  {
    "cityId": 4377,
    "cityName": "Jaciara",
    "stateId": 5246
  },
  {
    "cityId": 4378,
    "cityName": "Jangada",
    "stateId": 5246
  },
  {
    "cityId": 4379,
    "cityName": "Jarudore",
    "stateId": 5246
  },
  {
    "cityId": 4380,
    "cityName": "Jatobá",
    "stateId": 5246
  },
  {
    "cityId": 4381,
    "cityName": "Jauru",
    "stateId": 5246
  },
  {
    "cityId": 4382,
    "cityName": "Joselândia",
    "stateId": 5246
  },
  {
    "cityId": 4383,
    "cityName": "Juara",
    "stateId": 5246
  },
  {
    "cityId": 4384,
    "cityName": "Juína",
    "stateId": 5246
  },
  {
    "cityId": 4385,
    "cityName": "Juruena",
    "stateId": 5246
  },
  {
    "cityId": 4386,
    "cityName": "Juscimeira",
    "stateId": 5246
  },
  {
    "cityId": 4387,
    "cityName": "Lambari D'Oeste",
    "stateId": 5246
  },
  {
    "cityId": 4388,
    "cityName": "Lavouras",
    "stateId": 5246
  },
  {
    "cityId": 4389,
    "cityName": "Lucas do Rio Verde",
    "stateId": 5246
  },
  {
    "cityId": 4390,
    "cityName": "Lucialva",
    "stateId": 5246
  },
  {
    "cityId": 4391,
    "cityName": "Luciara",
    "stateId": 5246
  },
  {
    "cityId": 4392,
    "cityName": "Machado",
    "stateId": 5246
  },
  {
    "cityId": 4393,
    "cityName": "Marcelândia",
    "stateId": 5246
  },
  {
    "cityId": 4394,
    "cityName": "Marzagão",
    "stateId": 5246
  },
  {
    "cityId": 4395,
    "cityName": "Mata Dentro",
    "stateId": 5246
  },
  {
    "cityId": 4396,
    "cityName": "Matupá",
    "stateId": 5246
  },
  {
    "cityId": 4397,
    "cityName": "Mimoso",
    "stateId": 5246
  },
  {
    "cityId": 4398,
    "cityName": "Mirassol D'Oeste",
    "stateId": 5246
  },
  {
    "cityId": 4399,
    "cityName": "Nobres",
    "stateId": 5246
  },
  {
    "cityId": 4400,
    "cityName": "Nonoai do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4401,
    "cityName": "Nortelândia",
    "stateId": 5246
  },
  {
    "cityId": 4402,
    "cityName": "Nossa Senhora da Guia",
    "stateId": 5246
  },
  {
    "cityId": 4403,
    "cityName": "Nossa Senhora do Livramento",
    "stateId": 5246
  },
  {
    "cityId": 4404,
    "cityName": "Nova Alvorada",
    "stateId": 5246
  },
  {
    "cityId": 4405,
    "cityName": "Nova Bandeirantes",
    "stateId": 5246
  },
  {
    "cityId": 4406,
    "cityName": "Nova Brasilândia",
    "stateId": 5246
  },
  {
    "cityId": 4407,
    "cityName": "Nova Brasília",
    "stateId": 5246
  },
  {
    "cityId": 4408,
    "cityName": "Nova Canaã do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4409,
    "cityName": "Nova Catanduva",
    "stateId": 5246
  },
  {
    "cityId": 4410,
    "cityName": "Nova Galileia",
    "stateId": 5246
  },
  {
    "cityId": 4411,
    "cityName": "Nova Guarita",
    "stateId": 5246
  },
  {
    "cityId": 4412,
    "cityName": "Nova Lacerda",
    "stateId": 5246
  },
  {
    "cityId": 4413,
    "cityName": "Nova Marilândia",
    "stateId": 5246
  },
  {
    "cityId": 4414,
    "cityName": "Nova Maringá",
    "stateId": 5246
  },
  {
    "cityId": 4415,
    "cityName": "Nova Monte Verde",
    "stateId": 5246
  },
  {
    "cityId": 4416,
    "cityName": "Nova Mutum",
    "stateId": 5246
  },
  {
    "cityId": 4417,
    "cityName": "Nova Olímpia",
    "stateId": 5246
  },
  {
    "cityId": 4418,
    "cityName": "Nova Ubiratã",
    "stateId": 5246
  },
  {
    "cityId": 4419,
    "cityName": "Nova Xavantina",
    "stateId": 5246
  },
  {
    "cityId": 4420,
    "cityName": "Novo Diamantino",
    "stateId": 5246
  },
  {
    "cityId": 4421,
    "cityName": "Novo Eldorado",
    "stateId": 5246
  },
  {
    "cityId": 4422,
    "cityName": "Novo Horizonte do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4423,
    "cityName": "Novo Mundo",
    "stateId": 5246
  },
  {
    "cityId": 4424,
    "cityName": "Novo Paraná",
    "stateId": 5246
  },
  {
    "cityId": 4425,
    "cityName": "Novo São Joaquim",
    "stateId": 5246
  },
  {
    "cityId": 4426,
    "cityName": "Padronal",
    "stateId": 5246
  },
  {
    "cityId": 4427,
    "cityName": "Pai André",
    "stateId": 5246
  },
  {
    "cityId": 4428,
    "cityName": "Paraíso do Leste",
    "stateId": 5246
  },
  {
    "cityId": 4429,
    "cityName": "Paranaitá",
    "stateId": 5246
  },
  {
    "cityId": 4430,
    "cityName": "Paranatinga",
    "stateId": 5246
  },
  {
    "cityId": 4431,
    "cityName": "Passagem da Conceição",
    "stateId": 5246
  },
  {
    "cityId": 4432,
    "cityName": "Pedra Preta",
    "stateId": 5246
  },
  {
    "cityId": 4433,
    "cityName": "Peixoto de Azevedo",
    "stateId": 5246
  },
  {
    "cityId": 4434,
    "cityName": "Pirizal",
    "stateId": 5246
  },
  {
    "cityId": 4435,
    "cityName": "Placa de Santo Antônio",
    "stateId": 5246
  },
  {
    "cityId": 4436,
    "cityName": "Planalto da Serra",
    "stateId": 5246
  },
  {
    "cityId": 4437,
    "cityName": "Poconé",
    "stateId": 5246
  },
  {
    "cityId": 4438,
    "cityName": "Pombas",
    "stateId": 5246
  },
  {
    "cityId": 4439,
    "cityName": "Pontal do Araguaia",
    "stateId": 5246
  },
  {
    "cityId": 4440,
    "cityName": "Ponte Branca",
    "stateId": 5246
  },
  {
    "cityId": 4441,
    "cityName": "Ponte de Pedra",
    "stateId": 5246
  },
  {
    "cityId": 4442,
    "cityName": "Pontes e Lacerda",
    "stateId": 5246
  },
  {
    "cityId": 4443,
    "cityName": "Pontinópolis",
    "stateId": 5246
  },
  {
    "cityId": 4444,
    "cityName": "Porto Alegre do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4445,
    "cityName": "Porto dos Gaúchos",
    "stateId": 5246
  },
  {
    "cityId": 4446,
    "cityName": "Porto Esperidião",
    "stateId": 5246
  },
  {
    "cityId": 4447,
    "cityName": "Porto Estrela",
    "stateId": 5246
  },
  {
    "cityId": 4448,
    "cityName": "Poxoréu",
    "stateId": 5246
  },
  {
    "cityId": 4449,
    "cityName": "Praia Rica",
    "stateId": 5246
  },
  {
    "cityId": 4450,
    "cityName": "Primavera",
    "stateId": 5246
  },
  {
    "cityId": 4451,
    "cityName": "Primavera do Leste",
    "stateId": 5246
  },
  {
    "cityId": 4452,
    "cityName": "Progresso",
    "stateId": 5246
  },
  {
    "cityId": 4453,
    "cityName": "Querência",
    "stateId": 5246
  },
  {
    "cityId": 4454,
    "cityName": "Rancharia",
    "stateId": 5246
  },
  {
    "cityId": 4455,
    "cityName": "Reserva do Cabaçal",
    "stateId": 5246
  },
  {
    "cityId": 4456,
    "cityName": "Ribeirão Cascalheira",
    "stateId": 5246
  },
  {
    "cityId": 4457,
    "cityName": "Ribeirão dos Cocais",
    "stateId": 5246
  },
  {
    "cityId": 4458,
    "cityName": "Ribeirãozinho",
    "stateId": 5246
  },
  {
    "cityId": 4459,
    "cityName": "Rio Branco",
    "stateId": 5246
  },
  {
    "cityId": 4460,
    "cityName": "Rio Manso",
    "stateId": 5246
  },
  {
    "cityId": 4461,
    "cityName": "Riolândia",
    "stateId": 5246
  },
  {
    "cityId": 4462,
    "cityName": "Rondonópolis",
    "stateId": 5246
  },
  {
    "cityId": 4463,
    "cityName": "Rosário Oeste",
    "stateId": 5246
  },
  {
    "cityId": 4464,
    "cityName": "Salto do Céu",
    "stateId": 5246
  },
  {
    "cityId": 4465,
    "cityName": "Sangradouro",
    "stateId": 5246
  },
  {
    "cityId": 4466,
    "cityName": "Santa Carmem",
    "stateId": 5246
  },
  {
    "cityId": 4467,
    "cityName": "Santa Elvira",
    "stateId": 5246
  },
  {
    "cityId": 4468,
    "cityName": "Santa Fé",
    "stateId": 5246
  },
  {
    "cityId": 4469,
    "cityName": "Santa Rita",
    "stateId": 5246
  },
  {
    "cityId": 4470,
    "cityName": "Santa Terezinha",
    "stateId": 5246
  },
  {
    "cityId": 4471,
    "cityName": "Santaninha",
    "stateId": 5246
  },
  {
    "cityId": 4472,
    "cityName": "Santo Afonso",
    "stateId": 5246
  },
  {
    "cityId": 4473,
    "cityName": "Santo Antônio do Leverger",
    "stateId": 5246
  },
  {
    "cityId": 4474,
    "cityName": "Santo Antônio do Rio Bonito",
    "stateId": 5246
  },
  {
    "cityId": 4475,
    "cityName": "São Cristovão",
    "stateId": 5246
  },
  {
    "cityId": 4476,
    "cityName": "Vale de São Domingos",
    "stateId": 5246
  },
  {
    "cityId": 4477,
    "cityName": "São Félix do Araguaia",
    "stateId": 5246
  },
  {
    "cityId": 4478,
    "cityName": "São Joaquim",
    "stateId": 5246
  },
  {
    "cityId": 4479,
    "cityName": "São Jorge",
    "stateId": 5246
  },
  {
    "cityId": 4480,
    "cityName": "São José",
    "stateId": 5246
  },
  {
    "cityId": 4481,
    "cityName": "São José do Apuí",
    "stateId": 5246
  },
  {
    "cityId": 4482,
    "cityName": "São José do Planalto",
    "stateId": 5246
  },
  {
    "cityId": 4483,
    "cityName": "São José do Povo",
    "stateId": 5246
  },
  {
    "cityId": 4484,
    "cityName": "São José do Rio Claro",
    "stateId": 5246
  },
  {
    "cityId": 4485,
    "cityName": "São José do Xingu",
    "stateId": 5246
  },
  {
    "cityId": 4486,
    "cityName": "São José dos Quatro Marcos",
    "stateId": 5246
  },
  {
    "cityId": 4487,
    "cityName": "São Lourenço de Fátima",
    "stateId": 5246
  },
  {
    "cityId": 4488,
    "cityName": "São Pedro da Cipa",
    "stateId": 5246
  },
  {
    "cityId": 4489,
    "cityName": "São Vicente",
    "stateId": 5246
  },
  {
    "cityId": 4490,
    "cityName": "Sapezal",
    "stateId": 5246
  },
  {
    "cityId": 4491,
    "cityName": "Selma",
    "stateId": 5246
  },
  {
    "cityId": 4492,
    "cityName": "Serra Nova Dourada",
    "stateId": 5246
  },
  {
    "cityId": 4493,
    "cityName": "Sinop",
    "stateId": 5246
  },
  {
    "cityId": 4494,
    "cityName": "Sonho Azul",
    "stateId": 5246
  },
  {
    "cityId": 4495,
    "cityName": "Sorriso",
    "stateId": 5246
  },
  {
    "cityId": 4496,
    "cityName": "Sumidouro",
    "stateId": 5246
  },
  {
    "cityId": 4497,
    "cityName": "Tabaporã",
    "stateId": 5246
  },
  {
    "cityId": 4498,
    "cityName": "Tangará da Serra",
    "stateId": 5246
  },
  {
    "cityId": 4499,
    "cityName": "Tapirapua",
    "stateId": 5246
  },
  {
    "cityId": 4500,
    "cityName": "Tapurah",
    "stateId": 5246
  },
  {
    "cityId": 4501,
    "cityName": "Terra Nova do Norte",
    "stateId": 5246
  },
  {
    "cityId": 4502,
    "cityName": "Terra Roxa",
    "stateId": 5246
  },
  {
    "cityId": 4503,
    "cityName": "Tesouro",
    "stateId": 5246
  },
  {
    "cityId": 4504,
    "cityName": "Toricueyje",
    "stateId": 5246
  },
  {
    "cityId": 4505,
    "cityName": "Torixoréu",
    "stateId": 5246
  },
  {
    "cityId": 4506,
    "cityName": "Três Pontes",
    "stateId": 5246
  },
  {
    "cityId": 4507,
    "cityName": "União do Sul",
    "stateId": 5246
  },
  {
    "cityId": 4508,
    "cityName": "Vale dos Sonhos",
    "stateId": 5246
  },
  {
    "cityId": 4509,
    "cityName": "Vale Rico",
    "stateId": 5246
  },
  {
    "cityId": 4510,
    "cityName": "Varginha",
    "stateId": 5246
  },
  {
    "cityId": 4511,
    "cityName": "Várzea Grande",
    "stateId": 5246
  },
  {
    "cityId": 4512,
    "cityName": "Vera",
    "stateId": 5246
  },
  {
    "cityId": 4513,
    "cityName": "Vila Atlântica",
    "stateId": 5246
  },
  {
    "cityId": 4514,
    "cityName": "Vila Bela da Santíssima Trindade",
    "stateId": 5246
  },
  {
    "cityId": 4515,
    "cityName": "Vila Bueno",
    "stateId": 5246
  },
  {
    "cityId": 4516,
    "cityName": "Vila Mutum",
    "stateId": 5246
  },
  {
    "cityId": 4517,
    "cityName": "Vila Operária",
    "stateId": 5246
  },
  {
    "cityId": 4518,
    "cityName": "Vila Paulista",
    "stateId": 5246
  },
  {
    "cityId": 4519,
    "cityName": "Vila Progresso",
    "stateId": 5246
  },
  {
    "cityId": 4520,
    "cityName": "Vila Rica",
    "stateId": 5246
  },
  {
    "cityId": 4521,
    "cityName": "Novo Santo Antônio",
    "stateId": 5246
  },
  {
    "cityId": 4522,
    "cityName": "Abaetetuba",
    "stateId": 5247
  },
  {
    "cityId": 4523,
    "cityName": "Abel Figueiredo",
    "stateId": 5247
  },
  {
    "cityId": 4524,
    "cityName": "Acará",
    "stateId": 5247
  },
  {
    "cityId": 4525,
    "cityName": "Afuá",
    "stateId": 5247
  },
  {
    "cityId": 4526,
    "cityName": "Agrópolis Bela Vista",
    "stateId": 5247
  },
  {
    "cityId": 4527,
    "cityName": "Água Azul do Norte",
    "stateId": 5247
  },
  {
    "cityId": 4528,
    "cityName": "Água Fria",
    "stateId": 5247
  },
  {
    "cityId": 4529,
    "cityName": "Alenquer",
    "stateId": 5247
  },
  {
    "cityId": 4530,
    "cityName": "Algodoal",
    "stateId": 5247
  },
  {
    "cityId": 4531,
    "cityName": "Almeirim",
    "stateId": 5247
  },
  {
    "cityId": 4532,
    "cityName": "Almoço",
    "stateId": 5247
  },
  {
    "cityId": 4533,
    "cityName": "Alta Pará",
    "stateId": 5247
  },
  {
    "cityId": 4534,
    "cityName": "Altamira",
    "stateId": 5247
  },
  {
    "cityId": 4535,
    "cityName": "Alter do Chão",
    "stateId": 5247
  },
  {
    "cityId": 4536,
    "cityName": "Alvorada",
    "stateId": 5247
  },
  {
    "cityId": 4537,
    "cityName": "Americano",
    "stateId": 5247
  },
  {
    "cityId": 4538,
    "cityName": "Anajás",
    "stateId": 5247
  },
  {
    "cityId": 4539,
    "cityName": "Ananindeua",
    "stateId": 5247
  },
  {
    "cityId": 4540,
    "cityName": "Anapu",
    "stateId": 5247
  },
  {
    "cityId": 4541,
    "cityName": "Antônio Lemos",
    "stateId": 5247
  },
  {
    "cityId": 4542,
    "cityName": "Apeú",
    "stateId": 5247
  },
  {
    "cityId": 4544,
    "cityName": "Apinagés",
    "stateId": 5247
  },
  {
    "cityId": 4545,
    "cityName": "Arapixuna",
    "stateId": 5247
  },
  {
    "cityId": 4546,
    "cityName": "Araquaim",
    "stateId": 5247
  },
  {
    "cityId": 4547,
    "cityName": "Arco-Íris",
    "stateId": 5247
  },
  {
    "cityId": 4548,
    "cityName": "Areias",
    "stateId": 5247
  },
  {
    "cityId": 4549,
    "cityName": "Arumanduba",
    "stateId": 5247
  },
  {
    "cityId": 4550,
    "cityName": "Aruri",
    "stateId": 5247
  },
  {
    "cityId": 4551,
    "cityName": "Aturiaí",
    "stateId": 5247
  },
  {
    "cityId": 4552,
    "cityName": "Augusto Corrêa",
    "stateId": 5247
  },
  {
    "cityId": 4553,
    "cityName": "Aurora do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4554,
    "cityName": "Aveiro",
    "stateId": 5247
  },
  {
    "cityId": 4555,
    "cityName": "Bagre",
    "stateId": 5247
  },
  {
    "cityId": 4556,
    "cityName": "Baião",
    "stateId": 5247
  },
  {
    "cityId": 4557,
    "cityName": "Bannach",
    "stateId": 5247
  },
  {
    "cityId": 4558,
    "cityName": "Barcarena",
    "stateId": 5247
  },
  {
    "cityId": 4559,
    "cityName": "Barreira Branca",
    "stateId": 5247
  },
  {
    "cityId": 4560,
    "cityName": "Barreira dos Campos",
    "stateId": 5247
  },
  {
    "cityId": 4561,
    "cityName": "Barreiras",
    "stateId": 5247
  },
  {
    "cityId": 4562,
    "cityName": "Baturité",
    "stateId": 5247
  },
  {
    "cityId": 4563,
    "cityName": "Beja",
    "stateId": 5247
  },
  {
    "cityId": 4564,
    "cityName": "Bela Vista do Caracol",
    "stateId": 5247
  },
  {
    "cityId": 4565,
    "cityName": "Belém",
    "stateId": 5247
  },
  {
    "cityId": 4566,
    "cityName": "Belterra",
    "stateId": 5247
  },
  {
    "cityId": 4567,
    "cityName": "Benevides",
    "stateId": 5247
  },
  {
    "cityId": 4568,
    "cityName": "Benfica",
    "stateId": 5247
  },
  {
    "cityId": 4569,
    "cityName": "Boa Esperança",
    "stateId": 5247
  },
  {
    "cityId": 4570,
    "cityName": "Boa Esperança",
    "stateId": 5247
  },
  {
    "cityId": 4571,
    "cityName": "Boa Fé",
    "stateId": 5247
  },
  {
    "cityId": 4572,
    "cityName": "Boa Sorte",
    "stateId": 5247
  },
  {
    "cityId": 4573,
    "cityName": "Boa Vista do Iririteua",
    "stateId": 5247
  },
  {
    "cityId": 4574,
    "cityName": "Boim",
    "stateId": 5247
  },
  {
    "cityId": 4575,
    "cityName": "Bom Jardim",
    "stateId": 5247
  },
  {
    "cityId": 4576,
    "cityName": "Bom Jesus do Tocantins",
    "stateId": 5247
  },
  {
    "cityId": 4577,
    "cityName": "Bonito",
    "stateId": 5247
  },
  {
    "cityId": 4578,
    "cityName": "Bragança",
    "stateId": 5247
  },
  {
    "cityId": 4579,
    "cityName": "Brasil Novo",
    "stateId": 5247
  },
  {
    "cityId": 4580,
    "cityName": "Brasília Legal",
    "stateId": 5247
  },
  {
    "cityId": 4581,
    "cityName": "Brejo do Meio",
    "stateId": 5247
  },
  {
    "cityId": 4582,
    "cityName": "Brejo Grande do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4583,
    "cityName": "Breu Branco",
    "stateId": 5247
  },
  {
    "cityId": 4584,
    "cityName": "Breves",
    "stateId": 5247
  },
  {
    "cityId": 4585,
    "cityName": "Bujaru",
    "stateId": 5247
  },
  {
    "cityId": 4586,
    "cityName": "Cachoeira do Piriá",
    "stateId": 5247
  },
  {
    "cityId": 4587,
    "cityName": "Cachoeira do Arari",
    "stateId": 5247
  },
  {
    "cityId": 4588,
    "cityName": "Cafezal",
    "stateId": 5247
  },
  {
    "cityId": 4589,
    "cityName": "Cairari",
    "stateId": 5247
  },
  {
    "cityId": 4590,
    "cityName": "Caju",
    "stateId": 5247
  },
  {
    "cityId": 4591,
    "cityName": "Câmara do Marajó",
    "stateId": 5247
  },
  {
    "cityId": 4592,
    "cityName": "Cambuquira",
    "stateId": 5247
  },
  {
    "cityId": 4593,
    "cityName": "Cametá",
    "stateId": 5247
  },
  {
    "cityId": 4594,
    "cityName": "Camiranga",
    "stateId": 5247
  },
  {
    "cityId": 4595,
    "cityName": "Canaã dos Carajás",
    "stateId": 5247
  },
  {
    "cityId": 4596,
    "cityName": "Capanema",
    "stateId": 5247
  },
  {
    "cityId": 4597,
    "cityName": "Capitão Poço",
    "stateId": 5247
  },
  {
    "cityId": 4598,
    "cityName": "Caracará do Arari",
    "stateId": 5247
  },
  {
    "cityId": 4599,
    "cityName": "Carajás",
    "stateId": 5247
  },
  {
    "cityId": 4600,
    "cityName": "Carapajó",
    "stateId": 5247
  },
  {
    "cityId": 4601,
    "cityName": "Caraparu",
    "stateId": 5247
  },
  {
    "cityId": 4602,
    "cityName": "Caratateua",
    "stateId": 5247
  },
  {
    "cityId": 4603,
    "cityName": "Caripi",
    "stateId": 5247
  },
  {
    "cityId": 4604,
    "cityName": "Carrazedo",
    "stateId": 5247
  },
  {
    "cityId": 4605,
    "cityName": "Castanhal",
    "stateId": 5247
  },
  {
    "cityId": 4606,
    "cityName": "Castelo dos Sonhos",
    "stateId": 5247
  },
  {
    "cityId": 4607,
    "cityName": "Chaves",
    "stateId": 5247
  },
  {
    "cityId": 4608,
    "cityName": "Colares",
    "stateId": 5247
  },
  {
    "cityId": 4609,
    "cityName": "Conceição",
    "stateId": 5247
  },
  {
    "cityId": 4610,
    "cityName": "Conceição do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4611,
    "cityName": "Concórdia do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4612,
    "cityName": "Condeixa",
    "stateId": 5247
  },
  {
    "cityId": 4613,
    "cityName": "Coqueiro",
    "stateId": 5247
  },
  {
    "cityId": 4614,
    "cityName": "Cripurizão",
    "stateId": 5247
  },
  {
    "cityId": 4615,
    "cityName": "Cripurizinho",
    "stateId": 5247
  },
  {
    "cityId": 4616,
    "cityName": "Cuiú-Cuiú",
    "stateId": 5247
  },
  {
    "cityId": 4617,
    "cityName": "Cumaru do Norte",
    "stateId": 5247
  },
  {
    "cityId": 4618,
    "cityName": "Curionópolis",
    "stateId": 5247
  },
  {
    "cityId": 4619,
    "cityName": "Curralinho",
    "stateId": 5247
  },
  {
    "cityId": 4620,
    "cityName": "Curuá",
    "stateId": 5247
  },
  {
    "cityId": 4621,
    "cityName": "Curuaí",
    "stateId": 5247
  },
  {
    "cityId": 4622,
    "cityName": "Curuçá",
    "stateId": 5247
  },
  {
    "cityId": 4623,
    "cityName": "Curuçambaba",
    "stateId": 5247
  },
  {
    "cityId": 4624,
    "cityName": "Curumu",
    "stateId": 5247
  },
  {
    "cityId": 4625,
    "cityName": "Dom Eliseu",
    "stateId": 5247
  },
  {
    "cityId": 4626,
    "cityName": "Eldorado dos Carajás",
    "stateId": 5247
  },
  {
    "cityId": 4627,
    "cityName": "Emboraí",
    "stateId": 5247
  },
  {
    "cityId": 4628,
    "cityName": "Espírito Santo do Tauá",
    "stateId": 5247
  },
  {
    "cityId": 4629,
    "cityName": "Faro",
    "stateId": 5247
  },
  {
    "cityId": 4630,
    "cityName": "Fernandes Belo",
    "stateId": 5247
  },
  {
    "cityId": 4631,
    "cityName": "Flexal",
    "stateId": 5247
  },
  {
    "cityId": 4632,
    "cityName": "Floresta",
    "stateId": 5247
  },
  {
    "cityId": 4633,
    "cityName": "Floresta do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4634,
    "cityName": "Garrafão do Norte",
    "stateId": 5247
  },
  {
    "cityId": 4635,
    "cityName": "Goianésia do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4636,
    "cityName": "Gradaus",
    "stateId": 5247
  },
  {
    "cityId": 4637,
    "cityName": "Guajará-Açú",
    "stateId": 5247
  },
  {
    "cityId": 4638,
    "cityName": "Guajará-Miri",
    "stateId": 5247
  },
  {
    "cityId": 4639,
    "cityName": "Gurupá",
    "stateId": 5247
  },
  {
    "cityId": 4640,
    "cityName": "Gurupizinho",
    "stateId": 5247
  },
  {
    "cityId": 4641,
    "cityName": "Hidrelétrica Tucuruí",
    "stateId": 5247
  },
  {
    "cityId": 4642,
    "cityName": "Iataí",
    "stateId": 5247
  },
  {
    "cityId": 4643,
    "cityName": "Icoaraci",
    "stateId": 5247
  },
  {
    "cityId": 4644,
    "cityName": "Igarapé da Lama",
    "stateId": 5247
  },
  {
    "cityId": 4645,
    "cityName": "Igarapé-Açu",
    "stateId": 5247
  },
  {
    "cityId": 4646,
    "cityName": "Igarapé-Miri",
    "stateId": 5247
  },
  {
    "cityId": 4647,
    "cityName": "Inanu",
    "stateId": 5247
  },
  {
    "cityId": 4648,
    "cityName": "Inhangapi",
    "stateId": 5247
  },
  {
    "cityId": 4649,
    "cityName": "Ipixuna do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4650,
    "cityName": "Irituia",
    "stateId": 5247
  },
  {
    "cityId": 4651,
    "cityName": "Itaituba",
    "stateId": 5247
  },
  {
    "cityId": 4652,
    "cityName": "Itapixuna",
    "stateId": 5247
  },
  {
    "cityId": 4653,
    "cityName": "Itatupã",
    "stateId": 5247
  },
  {
    "cityId": 4654,
    "cityName": "Itupiranga",
    "stateId": 5247
  },
  {
    "cityId": 4655,
    "cityName": "Jacareacanga",
    "stateId": 5247
  },
  {
    "cityId": 4656,
    "cityName": "Jacundá",
    "stateId": 5247
  },
  {
    "cityId": 4657,
    "cityName": "Jaguarari",
    "stateId": 5247
  },
  {
    "cityId": 4658,
    "cityName": "Jamanxinzinho",
    "stateId": 5247
  },
  {
    "cityId": 4659,
    "cityName": "Jambuaçu",
    "stateId": 5247
  },
  {
    "cityId": 4660,
    "cityName": "Jandiaí",
    "stateId": 5247
  },
  {
    "cityId": 4661,
    "cityName": "Japerica",
    "stateId": 5247
  },
  {
    "cityId": 4662,
    "cityName": "Joana Coeli",
    "stateId": 5247
  },
  {
    "cityId": 4663,
    "cityName": "Joana Peres",
    "stateId": 5247
  },
  {
    "cityId": 4664,
    "cityName": "Joanes",
    "stateId": 5247
  },
  {
    "cityId": 4665,
    "cityName": "Juabá",
    "stateId": 5247
  },
  {
    "cityId": 4666,
    "cityName": "Jubim",
    "stateId": 5247
  },
  {
    "cityId": 4667,
    "cityName": "Juruti",
    "stateId": 5247
  },
  {
    "cityId": 4668,
    "cityName": "Km 19",
    "stateId": 5247
  },
  {
    "cityId": 4669,
    "cityName": "Km 26",
    "stateId": 5247
  },
  {
    "cityId": 4670,
    "cityName": "Lauro Sodré",
    "stateId": 5247
  },
  {
    "cityId": 4671,
    "cityName": "Ligação do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4672,
    "cityName": "Limoeiro do Ajuru",
    "stateId": 5247
  },
  {
    "cityId": 4673,
    "cityName": "Mãe do Rio",
    "stateId": 5247
  },
  {
    "cityId": 4674,
    "cityName": "Magalhães Barata",
    "stateId": 5247
  },
  {
    "cityId": 4675,
    "cityName": "Maiauata",
    "stateId": 5247
  },
  {
    "cityId": 4676,
    "cityName": "Manjeiro",
    "stateId": 5247
  },
  {
    "cityId": 4677,
    "cityName": "Marabá",
    "stateId": 5247
  },
  {
    "cityId": 4678,
    "cityName": "Maracanã",
    "stateId": 5247
  },
  {
    "cityId": 4679,
    "cityName": "Marajoara",
    "stateId": 5247
  },
  {
    "cityId": 4680,
    "cityName": "Marapanim",
    "stateId": 5247
  },
  {
    "cityId": 4681,
    "cityName": "Marituba",
    "stateId": 5247
  },
  {
    "cityId": 4682,
    "cityName": "Marudá",
    "stateId": 5247
  },
  {
    "cityId": 4683,
    "cityName": "Mata Geral",
    "stateId": 5247
  },
  {
    "cityId": 4684,
    "cityName": "Matapiquara",
    "stateId": 5247
  },
  {
    "cityId": 4685,
    "cityName": "Medicilândia",
    "stateId": 5247
  },
  {
    "cityId": 4686,
    "cityName": "Melgaço",
    "stateId": 5247
  },
  {
    "cityId": 4687,
    "cityName": "Menino Deus do Anapu",
    "stateId": 5247
  },
  {
    "cityId": 4688,
    "cityName": "Meruú",
    "stateId": 5247
  },
  {
    "cityId": 4689,
    "cityName": "Mirasselvas",
    "stateId": 5247
  },
  {
    "cityId": 4690,
    "cityName": "Miritituba",
    "stateId": 5247
  },
  {
    "cityId": 4691,
    "cityName": "Mocajuba",
    "stateId": 5247
  },
  {
    "cityId": 4692,
    "cityName": "Moiraba",
    "stateId": 5247
  },
  {
    "cityId": 4693,
    "cityName": "Moju",
    "stateId": 5247
  },
  {
    "cityId": 4694,
    "cityName": "Monsaras",
    "stateId": 5247
  },
  {
    "cityId": 4695,
    "cityName": "Monte Alegre",
    "stateId": 5247
  },
  {
    "cityId": 4696,
    "cityName": "Monte Alegre do Mau",
    "stateId": 5247
  },
  {
    "cityId": 4697,
    "cityName": "Monte Dourado",
    "stateId": 5247
  },
  {
    "cityId": 4698,
    "cityName": "Morada Nova",
    "stateId": 5247
  },
  {
    "cityId": 4699,
    "cityName": "Mosqueiro",
    "stateId": 5247
  },
  {
    "cityId": 4700,
    "cityName": "Muaná",
    "stateId": 5247
  },
  {
    "cityId": 4701,
    "cityName": "Mujuí dos Campos",
    "stateId": 5247
  },
  {
    "cityId": 4702,
    "cityName": "Murajá",
    "stateId": 5247
  },
  {
    "cityId": 4703,
    "cityName": "Murucupi",
    "stateId": 5247
  },
  {
    "cityId": 4704,
    "cityName": "Murumuru",
    "stateId": 5247
  },
  {
    "cityId": 4705,
    "cityName": "Muta",
    "stateId": 5247
  },
  {
    "cityId": 4706,
    "cityName": "Mutucal",
    "stateId": 5247
  },
  {
    "cityId": 4707,
    "cityName": "Nazaré de Mocajuba",
    "stateId": 5247
  },
  {
    "cityId": 4708,
    "cityName": "Nazaré dos Patos",
    "stateId": 5247
  },
  {
    "cityId": 4709,
    "cityName": "Nova Esperança do Piriá",
    "stateId": 5247
  },
  {
    "cityId": 4710,
    "cityName": "Nova Ipixuna",
    "stateId": 5247
  },
  {
    "cityId": 4711,
    "cityName": "Nova Mocajuba",
    "stateId": 5247
  },
  {
    "cityId": 4712,
    "cityName": "Nova Timboteua",
    "stateId": 5247
  },
  {
    "cityId": 4713,
    "cityName": "Novo Planalto",
    "stateId": 5247
  },
  {
    "cityId": 4714,
    "cityName": "Novo Progresso",
    "stateId": 5247
  },
  {
    "cityId": 4715,
    "cityName": "Novo Repartimento",
    "stateId": 5247
  },
  {
    "cityId": 4716,
    "cityName": "Núcleo Urbano Quilômetro 30",
    "stateId": 5247
  },
  {
    "cityId": 4717,
    "cityName": "Óbidos",
    "stateId": 5247
  },
  {
    "cityId": 4718,
    "cityName": "Oeiras do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4719,
    "cityName": "Oriximiná",
    "stateId": 5247
  },
  {
    "cityId": 4720,
    "cityName": "Osvaldilândia",
    "stateId": 5247
  },
  {
    "cityId": 4722,
    "cityName": "Ourém",
    "stateId": 5247
  },
  {
    "cityId": 4723,
    "cityName": "Ourilândia do Norte",
    "stateId": 5247
  },
  {
    "cityId": 4724,
    "cityName": "Outeiro",
    "stateId": 5247
  },
  {
    "cityId": 4725,
    "cityName": "Pacajá",
    "stateId": 5247
  },
  {
    "cityId": 4726,
    "cityName": "Pacoval",
    "stateId": 5247
  },
  {
    "cityId": 4727,
    "cityName": "Palestina do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4728,
    "cityName": "Paragominas",
    "stateId": 5247
  },
  {
    "cityId": 4729,
    "cityName": "Paratins",
    "stateId": 5247
  },
  {
    "cityId": 4730,
    "cityName": "Parauapebas",
    "stateId": 5247
  },
  {
    "cityId": 4731,
    "cityName": "Pau D'Arco",
    "stateId": 5247
  },
  {
    "cityId": 4732,
    "cityName": "Pedreira",
    "stateId": 5247
  },
  {
    "cityId": 4733,
    "cityName": "Peixe-Boi",
    "stateId": 5247
  },
  {
    "cityId": 4734,
    "cityName": "Penhalonga",
    "stateId": 5247
  },
  {
    "cityId": 4735,
    "cityName": "Perseverança",
    "stateId": 5247
  },
  {
    "cityId": 4736,
    "cityName": "Pesqueiro",
    "stateId": 5247
  },
  {
    "cityId": 4737,
    "cityName": "Piabas",
    "stateId": 5247
  },
  {
    "cityId": 4738,
    "cityName": "Piçarra",
    "stateId": 5247
  },
  {
    "cityId": 4739,
    "cityName": "Piçarra",
    "stateId": 5247
  },
  {
    "cityId": 4740,
    "cityName": "Pinhal",
    "stateId": 5247
  },
  {
    "cityId": 4741,
    "cityName": "Piraquara",
    "stateId": 5247
  },
  {
    "cityId": 4742,
    "cityName": "Piriá",
    "stateId": 5247
  },
  {
    "cityId": 4743,
    "cityName": "Placas",
    "stateId": 5247
  },
  {
    "cityId": 4744,
    "cityName": "Ponta de Pedras",
    "stateId": 5247
  },
  {
    "cityId": 4745,
    "cityName": "Ponta de Ramos",
    "stateId": 5247
  },
  {
    "cityId": 4746,
    "cityName": "Portel",
    "stateId": 5247
  },
  {
    "cityId": 4747,
    "cityName": "Porto de Moz",
    "stateId": 5247
  },
  {
    "cityId": 4748,
    "cityName": "Porto Salvo",
    "stateId": 5247
  },
  {
    "cityId": 4749,
    "cityName": "Porto Trombetas",
    "stateId": 5247
  },
  {
    "cityId": 4750,
    "cityName": "Prainha",
    "stateId": 5247
  },
  {
    "cityId": 4751,
    "cityName": "Primavera",
    "stateId": 5247
  },
  {
    "cityId": 4752,
    "cityName": "Quatipuru",
    "stateId": 5247
  },
  {
    "cityId": 4753,
    "cityName": "Quatro Bocas",
    "stateId": 5247
  },
  {
    "cityId": 4754,
    "cityName": "Redenção",
    "stateId": 5247
  },
  {
    "cityId": 4755,
    "cityName": "Remansão",
    "stateId": 5247
  },
  {
    "cityId": 4756,
    "cityName": "Repartimento",
    "stateId": 5247
  },
  {
    "cityId": 4757,
    "cityName": "Rio Maria",
    "stateId": 5247
  },
  {
    "cityId": 4758,
    "cityName": "Rio Vermelho",
    "stateId": 5247
  },
  {
    "cityId": 4759,
    "cityName": "Riozinho",
    "stateId": 5247
  },
  {
    "cityId": 4760,
    "cityName": "Rondon do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4761,
    "cityName": "Rurópolis",
    "stateId": 5247
  },
  {
    "cityId": 4762,
    "cityName": "Salinópolis",
    "stateId": 5247
  },
  {
    "cityId": 4763,
    "cityName": "Salvaterra",
    "stateId": 5247
  },
  {
    "cityId": 4764,
    "cityName": "Santa Bárbara do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4765,
    "cityName": "Santa Cruz do Arari",
    "stateId": 5247
  },
  {
    "cityId": 4766,
    "cityName": "Santa Isabel do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4767,
    "cityName": "Santa Luzia do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4768,
    "cityName": "Santa Maria",
    "stateId": 5247
  },
  {
    "cityId": 4769,
    "cityName": "Santa Maria das Barreiras",
    "stateId": 5247
  },
  {
    "cityId": 4770,
    "cityName": "Santa Maria do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4771,
    "cityName": "Santa Rosa da Vigia",
    "stateId": 5247
  },
  {
    "cityId": 4772,
    "cityName": "Santa Terezinha",
    "stateId": 5247
  },
  {
    "cityId": 4773,
    "cityName": "Santana do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4774,
    "cityName": "Santarém",
    "stateId": 5247
  },
  {
    "cityId": 4775,
    "cityName": "Santarém Novo",
    "stateId": 5247
  },
  {
    "cityId": 4776,
    "cityName": "Santo Antônio",
    "stateId": 5247
  },
  {
    "cityId": 4777,
    "cityName": "Santo Antônio do Tauá",
    "stateId": 5247
  },
  {
    "cityId": 4778,
    "cityName": "São Caetano de Odivelas",
    "stateId": 5247
  },
  {
    "cityId": 4779,
    "cityName": "São Domingos do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4780,
    "cityName": "São Domingos do Capim",
    "stateId": 5247
  },
  {
    "cityId": 4781,
    "cityName": "São Félix do Xingu",
    "stateId": 5247
  },
  {
    "cityId": 4782,
    "cityName": "São Francisco",
    "stateId": 5247
  },
  {
    "cityId": 4783,
    "cityName": "São Francisco da Jararaca",
    "stateId": 5247
  },
  {
    "cityId": 4784,
    "cityName": "São Francisco do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4785,
    "cityName": "São Geraldo do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4786,
    "cityName": "São João da Ponta",
    "stateId": 5247
  },
  {
    "cityId": 4787,
    "cityName": "São João de Pirabas",
    "stateId": 5247
  },
  {
    "cityId": 4788,
    "cityName": "São João do Acangata",
    "stateId": 5247
  },
  {
    "cityId": 4789,
    "cityName": "São João do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4790,
    "cityName": "São João do Piriá",
    "stateId": 5247
  },
  {
    "cityId": 4791,
    "cityName": "São João dos Ramos",
    "stateId": 5247
  },
  {
    "cityId": 4792,
    "cityName": "São Joaquim do Tapará",
    "stateId": 5247
  },
  {
    "cityId": 4793,
    "cityName": "São Jorge",
    "stateId": 5247
  },
  {
    "cityId": 4794,
    "cityName": "São José do Gurupi",
    "stateId": 5247
  },
  {
    "cityId": 4795,
    "cityName": "São José do Piriá",
    "stateId": 5247
  },
  {
    "cityId": 4796,
    "cityName": "São Luiz do Tapajós",
    "stateId": 5247
  },
  {
    "cityId": 4797,
    "cityName": "São Miguel do Guamá",
    "stateId": 5247
  },
  {
    "cityId": 4798,
    "cityName": "São Miguel dos Macacos",
    "stateId": 5247
  },
  {
    "cityId": 4799,
    "cityName": "São Pedro de Viseu",
    "stateId": 5247
  },
  {
    "cityId": 4800,
    "cityName": "São Pedro do Capim",
    "stateId": 5247
  },
  {
    "cityId": 4801,
    "cityName": "São Raimundo de Borralhos",
    "stateId": 5247
  },
  {
    "cityId": 4802,
    "cityName": "São Raimundo do Araguaia",
    "stateId": 5247
  },
  {
    "cityId": 4803,
    "cityName": "São Raimundo dos Furtados",
    "stateId": 5247
  },
  {
    "cityId": 4804,
    "cityName": "São Roberto",
    "stateId": 5247
  },
  {
    "cityId": 4805,
    "cityName": "São Sebastião da Boa Vista",
    "stateId": 5247
  },
  {
    "cityId": 4806,
    "cityName": "São Sebastião de Viçosa",
    "stateId": 5247
  },
  {
    "cityId": 4807,
    "cityName": "Sapucaia",
    "stateId": 5247
  },
  {
    "cityId": 4808,
    "cityName": "Senador José Porfírio",
    "stateId": 5247
  },
  {
    "cityId": 4809,
    "cityName": "Serra Pelada",
    "stateId": 5247
  },
  {
    "cityId": 4810,
    "cityName": "Soure",
    "stateId": 5247
  },
  {
    "cityId": 4811,
    "cityName": "Tailândia",
    "stateId": 5247
  },
  {
    "cityId": 4812,
    "cityName": "Tatuteua",
    "stateId": 5247
  },
  {
    "cityId": 4813,
    "cityName": "Tauari",
    "stateId": 5247
  },
  {
    "cityId": 4814,
    "cityName": "Tauarizinho",
    "stateId": 5247
  },
  {
    "cityId": 4815,
    "cityName": "Tentugal",
    "stateId": 5247
  },
  {
    "cityId": 4816,
    "cityName": "Terra Alta",
    "stateId": 5247
  },
  {
    "cityId": 4817,
    "cityName": "Terra Santa",
    "stateId": 5247
  },
  {
    "cityId": 4818,
    "cityName": "Tijoca",
    "stateId": 5247
  },
  {
    "cityId": 4819,
    "cityName": "Timboteua",
    "stateId": 5247
  },
  {
    "cityId": 4820,
    "cityName": "Tomé-Açú",
    "stateId": 5247
  },
  {
    "cityId": 4821,
    "cityName": "Tracuateua",
    "stateId": 5247
  },
  {
    "cityId": 4822,
    "cityName": "Trairão",
    "stateId": 5247
  },
  {
    "cityId": 4823,
    "cityName": "Tucumã",
    "stateId": 5247
  },
  {
    "cityId": 4824,
    "cityName": "Tucuruí",
    "stateId": 5247
  },
  {
    "cityId": 4825,
    "cityName": "Ulianópolis",
    "stateId": 5247
  },
  {
    "cityId": 4826,
    "cityName": "Uruará",
    "stateId": 5247
  },
  {
    "cityId": 4827,
    "cityName": "Urucuri",
    "stateId": 5247
  },
  {
    "cityId": 4828,
    "cityName": "Urucuriteua",
    "stateId": 5247
  },
  {
    "cityId": 4829,
    "cityName": "Val-de-Cães",
    "stateId": 5247
  },
  {
    "cityId": 4830,
    "cityName": "Veiros",
    "stateId": 5247
  },
  {
    "cityId": 4831,
    "cityName": "Vigia",
    "stateId": 5247
  },
  {
    "cityId": 4832,
    "cityName": "Vila do Carmo do Tocantins",
    "stateId": 5247
  },
  {
    "cityId": 4833,
    "cityName": "Vila dos Cabanos",
    "stateId": 5247
  },
  {
    "cityId": 4834,
    "cityName": "Vila França",
    "stateId": 5247
  },
  {
    "cityId": 4835,
    "cityName": "Vila Goreth",
    "stateId": 5247
  },
  {
    "cityId": 4836,
    "cityName": "Vila Isol",
    "stateId": 5247
  },
  {
    "cityId": 4837,
    "cityName": "Vila Nova",
    "stateId": 5247
  },
  {
    "cityId": 4838,
    "cityName": "Vila Planalto",
    "stateId": 5247
  },
  {
    "cityId": 4839,
    "cityName": "Vila Santa Fé",
    "stateId": 5247
  },
  {
    "cityId": 4840,
    "cityName": "Vila Socorro",
    "stateId": 5247
  },
  {
    "cityId": 4841,
    "cityName": "Vilarinho do Monte",
    "stateId": 5247
  },
  {
    "cityId": 4842,
    "cityName": "Viseu",
    "stateId": 5247
  },
  {
    "cityId": 4843,
    "cityName": "Vista Alegre",
    "stateId": 5247
  },
  {
    "cityId": 4844,
    "cityName": "Vista Alegre do Pará",
    "stateId": 5247
  },
  {
    "cityId": 4845,
    "cityName": "Vitória do Xingu",
    "stateId": 5247
  },
  {
    "cityId": 4846,
    "cityName": "Xinguara",
    "stateId": 5247
  },
  {
    "cityId": 4847,
    "cityName": "Xinguarinha",
    "stateId": 5247
  },
  {
    "cityId": 4848,
    "cityName": "Água Branca",
    "stateId": 5248
  },
  {
    "cityId": 4849,
    "cityName": "Aguiar",
    "stateId": 5248
  },
  {
    "cityId": 4850,
    "cityName": "Alagoa Grande",
    "stateId": 5248
  },
  {
    "cityId": 4851,
    "cityName": "Alagoa Nova",
    "stateId": 5248
  },
  {
    "cityId": 4852,
    "cityName": "Alagoinha",
    "stateId": 5248
  },
  {
    "cityId": 4853,
    "cityName": "Alcantil",
    "stateId": 5248
  },
  {
    "cityId": 4854,
    "cityName": "Algodão de Jandaíra",
    "stateId": 5248
  },
  {
    "cityId": 4855,
    "cityName": "Alhandra",
    "stateId": 5248
  },
  {
    "cityId": 4856,
    "cityName": "Amparo",
    "stateId": 5248
  },
  {
    "cityId": 4857,
    "cityName": "Aparecida",
    "stateId": 5248
  },
  {
    "cityId": 4858,
    "cityName": "Araçagi",
    "stateId": 5248
  },
  {
    "cityId": 4859,
    "cityName": "Arara",
    "stateId": 5248
  },
  {
    "cityId": 4860,
    "cityName": "Araruna",
    "stateId": 5248
  },
  {
    "cityId": 4861,
    "cityName": "Areia",
    "stateId": 5248
  },
  {
    "cityId": 4862,
    "cityName": "Areia de Baraúnas",
    "stateId": 5248
  },
  {
    "cityId": 4863,
    "cityName": "Areial",
    "stateId": 5248
  },
  {
    "cityId": 4864,
    "cityName": "Areias",
    "stateId": 5248
  },
  {
    "cityId": 4865,
    "cityName": "Aroeiras",
    "stateId": 5248
  },
  {
    "cityId": 4866,
    "cityName": "Riachão do Bacamarte",
    "stateId": 5248
  },
  {
    "cityId": 4867,
    "cityName": "Assunção",
    "stateId": 5248
  },
  {
    "cityId": 4868,
    "cityName": "Baía da Traição",
    "stateId": 5248
  },
  {
    "cityId": 4869,
    "cityName": "Balanços",
    "stateId": 5248
  },
  {
    "cityId": 4870,
    "cityName": "Bananeiras",
    "stateId": 5248
  },
  {
    "cityId": 4871,
    "cityName": "Baraúna",
    "stateId": 5248
  },
  {
    "cityId": 4872,
    "cityName": "Barra de Santa Rosa",
    "stateId": 5248
  },
  {
    "cityId": 4873,
    "cityName": "Barra de Santana",
    "stateId": 5248
  },
  {
    "cityId": 4874,
    "cityName": "Barra de São Miguel",
    "stateId": 5248
  },
  {
    "cityId": 4875,
    "cityName": "Barra do Camaratuba",
    "stateId": 5248
  },
  {
    "cityId": 4876,
    "cityName": "Bayeux",
    "stateId": 5248
  },
  {
    "cityId": 4877,
    "cityName": "Belém",
    "stateId": 5248
  },
  {
    "cityId": 4878,
    "cityName": "Belém do Brejo do Cruz",
    "stateId": 5248
  },
  {
    "cityId": 4879,
    "cityName": "Bernardino Batista",
    "stateId": 5248
  },
  {
    "cityId": 4880,
    "cityName": "Boa Ventura",
    "stateId": 5248
  },
  {
    "cityId": 4881,
    "cityName": "Boa Vista",
    "stateId": 5248
  },
  {
    "cityId": 4882,
    "cityName": "Bom Jesus",
    "stateId": 5248
  },
  {
    "cityId": 4883,
    "cityName": "Bom Sucesso",
    "stateId": 5248
  },
  {
    "cityId": 4884,
    "cityName": "Bom Sucesso",
    "stateId": 5248
  },
  {
    "cityId": 4885,
    "cityName": "Bonito de Santa Fé",
    "stateId": 5248
  },
  {
    "cityId": 4886,
    "cityName": "Boqueirão",
    "stateId": 5248
  },
  {
    "cityId": 4887,
    "cityName": "Borborema",
    "stateId": 5248
  },
  {
    "cityId": 4888,
    "cityName": "Brejo do Cruz",
    "stateId": 5248
  },
  {
    "cityId": 4889,
    "cityName": "Brejo dos Santos",
    "stateId": 5248
  },
  {
    "cityId": 4890,
    "cityName": "Caaporã",
    "stateId": 5248
  },
  {
    "cityId": 4891,
    "cityName": "Cabaceiras",
    "stateId": 5248
  },
  {
    "cityId": 4892,
    "cityName": "Cabedelo",
    "stateId": 5248
  },
  {
    "cityId": 4893,
    "cityName": "Cachoeira",
    "stateId": 5248
  },
  {
    "cityId": 4894,
    "cityName": "Cachoeira dos Índios",
    "stateId": 5248
  },
  {
    "cityId": 4895,
    "cityName": "Cachoeirinha",
    "stateId": 5248
  },
  {
    "cityId": 4896,
    "cityName": "Cacimba de Areia",
    "stateId": 5248
  },
  {
    "cityId": 4897,
    "cityName": "Cacimba de Dentro",
    "stateId": 5248
  },
  {
    "cityId": 4898,
    "cityName": "Cacimbas",
    "stateId": 5248
  },
  {
    "cityId": 4899,
    "cityName": "Caiçara",
    "stateId": 5248
  },
  {
    "cityId": 4900,
    "cityName": "Cajazeiras",
    "stateId": 5248
  },
  {
    "cityId": 4901,
    "cityName": "Cajazeirinhas",
    "stateId": 5248
  },
  {
    "cityId": 4902,
    "cityName": "Caldas Brandão",
    "stateId": 5248
  },
  {
    "cityId": 4903,
    "cityName": "Camalaú",
    "stateId": 5248
  },
  {
    "cityId": 4904,
    "cityName": "Campina Grande",
    "stateId": 5248
  },
  {
    "cityId": 4905,
    "cityName": "Campo Alegre",
    "stateId": 5248
  },
  {
    "cityId": 4906,
    "cityName": "Campo Grande",
    "stateId": 5248
  },
  {
    "cityId": 4907,
    "cityName": "Camurupim",
    "stateId": 5248
  },
  {
    "cityId": 4908,
    "cityName": "Capim",
    "stateId": 5248
  },
  {
    "cityId": 4909,
    "cityName": "Caraúbas",
    "stateId": 5248
  },
  {
    "cityId": 4910,
    "cityName": "Cardoso",
    "stateId": 5248
  },
  {
    "cityId": 4911,
    "cityName": "Carrapateira",
    "stateId": 5248
  },
  {
    "cityId": 4912,
    "cityName": "Casinha do Homem",
    "stateId": 5248
  },
  {
    "cityId": 4913,
    "cityName": "Casserengue",
    "stateId": 5248
  },
  {
    "cityId": 4914,
    "cityName": "Catingueira",
    "stateId": 5248
  },
  {
    "cityId": 4915,
    "cityName": "Catolé",
    "stateId": 5248
  },
  {
    "cityId": 4916,
    "cityName": "Catolé do Rocha",
    "stateId": 5248
  },
  {
    "cityId": 4917,
    "cityName": "Caturité",
    "stateId": 5248
  },
  {
    "cityId": 4918,
    "cityName": "Cepilho",
    "stateId": 5248
  },
  {
    "cityId": 4919,
    "cityName": "Conceição",
    "stateId": 5248
  },
  {
    "cityId": 4920,
    "cityName": "Condado",
    "stateId": 5248
  },
  {
    "cityId": 4921,
    "cityName": "Conde",
    "stateId": 5248
  },
  {
    "cityId": 4922,
    "cityName": "Congo",
    "stateId": 5248
  },
  {
    "cityId": 4923,
    "cityName": "Coremas",
    "stateId": 5248
  },
  {
    "cityId": 4924,
    "cityName": "Coronel Maia",
    "stateId": 5248
  },
  {
    "cityId": 4925,
    "cityName": "Coxixola",
    "stateId": 5248
  },
  {
    "cityId": 4926,
    "cityName": "Cruz do Espírito Santo",
    "stateId": 5248
  },
  {
    "cityId": 4927,
    "cityName": "Cubati",
    "stateId": 5248
  },
  {
    "cityId": 4928,
    "cityName": "Cuité",
    "stateId": 5248
  },
  {
    "cityId": 4929,
    "cityName": "Cuité de Mamanguape",
    "stateId": 5248
  },
  {
    "cityId": 4930,
    "cityName": "Cuitegi",
    "stateId": 5248
  },
  {
    "cityId": 4931,
    "cityName": "Cupissura",
    "stateId": 5248
  },
  {
    "cityId": 4932,
    "cityName": "Curral de Cima",
    "stateId": 5248
  },
  {
    "cityId": 4933,
    "cityName": "Curral Velho",
    "stateId": 5248
  },
  {
    "cityId": 4934,
    "cityName": "Damião",
    "stateId": 5248
  },
  {
    "cityId": 4935,
    "cityName": "Desterro",
    "stateId": 5248
  },
  {
    "cityId": 4936,
    "cityName": "Diamante",
    "stateId": 5248
  },
  {
    "cityId": 4937,
    "cityName": "Dona Inês",
    "stateId": 5248
  },
  {
    "cityId": 4938,
    "cityName": "Duas Estradas",
    "stateId": 5248
  },
  {
    "cityId": 4939,
    "cityName": "Emas",
    "stateId": 5248
  },
  {
    "cityId": 4940,
    "cityName": "Engenheiro Ávidos",
    "stateId": 5248
  },
  {
    "cityId": 4941,
    "cityName": "Esperança",
    "stateId": 5248
  },
  {
    "cityId": 4942,
    "cityName": "Fagundes",
    "stateId": 5248
  },
  {
    "cityId": 4943,
    "cityName": "Fátima",
    "stateId": 5248
  },
  {
    "cityId": 4944,
    "cityName": "Fazenda Nova",
    "stateId": 5248
  },
  {
    "cityId": 4945,
    "cityName": "Forte Velho",
    "stateId": 5248
  },
  {
    "cityId": 4946,
    "cityName": "Frei Martinho",
    "stateId": 5248
  },
  {
    "cityId": 4947,
    "cityName": "Gado Bravo",
    "stateId": 5248
  },
  {
    "cityId": 4948,
    "cityName": "Galante",
    "stateId": 5248
  },
  {
    "cityId": 4949,
    "cityName": "Guarabira",
    "stateId": 5248
  },
  {
    "cityId": 4950,
    "cityName": "Guarita",
    "stateId": 5248
  },
  {
    "cityId": 4951,
    "cityName": "Gurinhém",
    "stateId": 5248
  },
  {
    "cityId": 4952,
    "cityName": "Gurjão",
    "stateId": 5248
  },
  {
    "cityId": 4953,
    "cityName": "Ibiara",
    "stateId": 5248
  },
  {
    "cityId": 4954,
    "cityName": "Igaracy",
    "stateId": 5248
  },
  {
    "cityId": 4955,
    "cityName": "Imaculada",
    "stateId": 5248
  },
  {
    "cityId": 4956,
    "cityName": "Ingá",
    "stateId": 5248
  },
  {
    "cityId": 4957,
    "cityName": "Itabaiana",
    "stateId": 5248
  },
  {
    "cityId": 4958,
    "cityName": "Itajubatiba",
    "stateId": 5248
  },
  {
    "cityId": 4959,
    "cityName": "Itaporanga",
    "stateId": 5248
  },
  {
    "cityId": 4960,
    "cityName": "Itapororoca",
    "stateId": 5248
  },
  {
    "cityId": 4961,
    "cityName": "Itatuba",
    "stateId": 5248
  },
  {
    "cityId": 4962,
    "cityName": "Jacaraú",
    "stateId": 5248
  },
  {
    "cityId": 4963,
    "cityName": "Jericó",
    "stateId": 5248
  },
  {
    "cityId": 4964,
    "cityName": "João Pessoa",
    "stateId": 5248
  },
  {
    "cityId": 4965,
    "cityName": "Juarez Távora",
    "stateId": 5248
  },
  {
    "cityId": 4966,
    "cityName": "Juazeirinho",
    "stateId": 5248
  },
  {
    "cityId": 4967,
    "cityName": "Junco do Seridó",
    "stateId": 5248
  },
  {
    "cityId": 4968,
    "cityName": "Juripiranga",
    "stateId": 5248
  },
  {
    "cityId": 4969,
    "cityName": "Juru",
    "stateId": 5248
  },
  {
    "cityId": 4970,
    "cityName": "Lagoa",
    "stateId": 5248
  },
  {
    "cityId": 4971,
    "cityName": "Lagoa de Dentro",
    "stateId": 5248
  },
  {
    "cityId": 4972,
    "cityName": "Lagoa de Dentro",
    "stateId": 5248
  },
  {
    "cityId": 4973,
    "cityName": "Lagoa Seca",
    "stateId": 5248
  },
  {
    "cityId": 4974,
    "cityName": "Lastro",
    "stateId": 5248
  },
  {
    "cityId": 4975,
    "cityName": "Lerolândia",
    "stateId": 5248
  },
  {
    "cityId": 4976,
    "cityName": "Livramento",
    "stateId": 5248
  },
  {
    "cityId": 4977,
    "cityName": "Logradouro",
    "stateId": 5248
  },
  {
    "cityId": 4978,
    "cityName": "Lucena",
    "stateId": 5248
  },
  {
    "cityId": 4979,
    "cityName": "Mãe D'Água",
    "stateId": 5248
  },
  {
    "cityId": 4980,
    "cityName": "Maia",
    "stateId": 5248
  },
  {
    "cityId": 4981,
    "cityName": "Malta",
    "stateId": 5248
  },
  {
    "cityId": 4982,
    "cityName": "Mamanguape",
    "stateId": 5248
  },
  {
    "cityId": 4983,
    "cityName": "Manaíra",
    "stateId": 5248
  },
  {
    "cityId": 4984,
    "cityName": "Marcação",
    "stateId": 5248
  },
  {
    "cityId": 4985,
    "cityName": "Mari",
    "stateId": 5248
  },
  {
    "cityId": 4986,
    "cityName": "Marizópolis",
    "stateId": 5248
  },
  {
    "cityId": 4987,
    "cityName": "Massaranduba",
    "stateId": 5248
  },
  {
    "cityId": 4988,
    "cityName": "Mata Limpa",
    "stateId": 5248
  },
  {
    "cityId": 4989,
    "cityName": "Mata Virgem",
    "stateId": 5248
  },
  {
    "cityId": 4990,
    "cityName": "Mataraca",
    "stateId": 5248
  },
  {
    "cityId": 4991,
    "cityName": "Matinhas",
    "stateId": 5248
  },
  {
    "cityId": 4992,
    "cityName": "Mato Grosso",
    "stateId": 5248
  },
  {
    "cityId": 4993,
    "cityName": "Maturéia",
    "stateId": 5248
  },
  {
    "cityId": 4994,
    "cityName": "Melo",
    "stateId": 5248
  },
  {
    "cityId": 4995,
    "cityName": "Mogeiro",
    "stateId": 5248
  },
  {
    "cityId": 4996,
    "cityName": "Montadas",
    "stateId": 5248
  },
  {
    "cityId": 4997,
    "cityName": "Monte Horebe",
    "stateId": 5248
  },
  {
    "cityId": 4998,
    "cityName": "Monteiro",
    "stateId": 5248
  },
  {
    "cityId": 4999,
    "cityName": "Montevidéu",
    "stateId": 5248
  },
  {
    "cityId": 5000,
    "cityName": "Mulungu",
    "stateId": 5248
  },
  {
    "cityId": 5001,
    "cityName": "Muquém",
    "stateId": 5248
  },
  {
    "cityId": 5002,
    "cityName": "Natuba",
    "stateId": 5248
  },
  {
    "cityId": 5003,
    "cityName": "Nazaré",
    "stateId": 5248
  },
  {
    "cityId": 5004,
    "cityName": "Nazarezinho",
    "stateId": 5248
  },
  {
    "cityId": 5005,
    "cityName": "Nossa Senhora do Livramento",
    "stateId": 5248
  },
  {
    "cityId": 5006,
    "cityName": "Nova Floresta",
    "stateId": 5248
  },
  {
    "cityId": 5007,
    "cityName": "Nova Olinda",
    "stateId": 5248
  },
  {
    "cityId": 5008,
    "cityName": "Nova Palmeira",
    "stateId": 5248
  },
  {
    "cityId": 5009,
    "cityName": "Núcleo N 2",
    "stateId": 5248
  },
  {
    "cityId": 5010,
    "cityName": "Núcleo N 3",
    "stateId": 5248
  },
  {
    "cityId": 5011,
    "cityName": "Odilândia",
    "stateId": 5248
  },
  {
    "cityId": 5012,
    "cityName": "Olho D'Água",
    "stateId": 5248
  },
  {
    "cityId": 5013,
    "cityName": "Olivedos",
    "stateId": 5248
  },
  {
    "cityId": 5014,
    "cityName": "Ouro Velho",
    "stateId": 5248
  },
  {
    "cityId": 5015,
    "cityName": "Parari",
    "stateId": 5248
  },
  {
    "cityId": 5016,
    "cityName": "Passagem",
    "stateId": 5248
  },
  {
    "cityId": 5017,
    "cityName": "Patos",
    "stateId": 5248
  },
  {
    "cityId": 5018,
    "cityName": "Paulista",
    "stateId": 5248
  },
  {
    "cityId": 5019,
    "cityName": "Pedra Branca",
    "stateId": 5248
  },
  {
    "cityId": 5020,
    "cityName": "Pedra Lavrada",
    "stateId": 5248
  },
  {
    "cityId": 5021,
    "cityName": "Pedras de Fogo",
    "stateId": 5248
  },
  {
    "cityId": 5022,
    "cityName": "Pedro Régis",
    "stateId": 5248
  },
  {
    "cityId": 5023,
    "cityName": "Pelo Sinal",
    "stateId": 5248
  },
  {
    "cityId": 5024,
    "cityName": "Pereiros",
    "stateId": 5248
  },
  {
    "cityId": 5025,
    "cityName": "Piancó",
    "stateId": 5248
  },
  {
    "cityId": 5026,
    "cityName": "Picuí",
    "stateId": 5248
  },
  {
    "cityId": 5027,
    "cityName": "Pilar",
    "stateId": 5248
  },
  {
    "cityId": 5028,
    "cityName": "Pilões",
    "stateId": 5248
  },
  {
    "cityId": 5029,
    "cityName": "Pilõezinhos",
    "stateId": 5248
  },
  {
    "cityId": 5030,
    "cityName": "Pindurão",
    "stateId": 5248
  },
  {
    "cityId": 5031,
    "cityName": "Pio X",
    "stateId": 5248
  },
  {
    "cityId": 5032,
    "cityName": "Pirauá",
    "stateId": 5248
  },
  {
    "cityId": 5033,
    "cityName": "Pirpirituba",
    "stateId": 5248
  },
  {
    "cityId": 5034,
    "cityName": "Pitanga de Estrada",
    "stateId": 5248
  },
  {
    "cityId": 5035,
    "cityName": "Pitimbu",
    "stateId": 5248
  },
  {
    "cityId": 5036,
    "cityName": "Pocinhos",
    "stateId": 5248
  },
  {
    "cityId": 5037,
    "cityName": "Poço Dantas",
    "stateId": 5248
  },
  {
    "cityId": 5038,
    "cityName": "Poço de José de Moura",
    "stateId": 5248
  },
  {
    "cityId": 5039,
    "cityName": "Pombal",
    "stateId": 5248
  },
  {
    "cityId": 5040,
    "cityName": "Porteirinha de Pedra",
    "stateId": 5248
  },
  {
    "cityId": 5041,
    "cityName": "Prata",
    "stateId": 5248
  },
  {
    "cityId": 5042,
    "cityName": "Princesa Isabel",
    "stateId": 5248
  },
  {
    "cityId": 5043,
    "cityName": "Puxinanã",
    "stateId": 5248
  },
  {
    "cityId": 5044,
    "cityName": "Queimadas",
    "stateId": 5248
  },
  {
    "cityId": 5045,
    "cityName": "Quixabá",
    "stateId": 5248
  },
  {
    "cityId": 5046,
    "cityName": "Quixadá",
    "stateId": 5248
  },
  {
    "cityId": 5047,
    "cityName": "Remígio",
    "stateId": 5248
  },
  {
    "cityId": 5048,
    "cityName": "Riachão",
    "stateId": 5248
  },
  {
    "cityId": 5049,
    "cityName": "Riachão do Poço",
    "stateId": 5248
  },
  {
    "cityId": 5050,
    "cityName": "Riacho de Santo Antônio",
    "stateId": 5248
  },
  {
    "cityId": 5051,
    "cityName": "Riacho dos Cavalos",
    "stateId": 5248
  },
  {
    "cityId": 5052,
    "cityName": "Ribeira",
    "stateId": 5248
  },
  {
    "cityId": 5053,
    "cityName": "Rio Tinto",
    "stateId": 5248
  },
  {
    "cityId": 5054,
    "cityName": "Rua Nova",
    "stateId": 5248
  },
  {
    "cityId": 5055,
    "cityName": "Salema",
    "stateId": 5248
  },
  {
    "cityId": 5056,
    "cityName": "Salgadinho",
    "stateId": 5248
  },
  {
    "cityId": 5057,
    "cityName": "Salgado de São Félix",
    "stateId": 5248
  },
  {
    "cityId": 5058,
    "cityName": "Santa Cecília de Umbuzeiro",
    "stateId": 5248
  },
  {
    "cityId": 5059,
    "cityName": "Santa Cruz",
    "stateId": 5248
  },
  {
    "cityId": 5060,
    "cityName": "Santa Gertrudes",
    "stateId": 5248
  },
  {
    "cityId": 5061,
    "cityName": "Santa Helena",
    "stateId": 5248
  },
  {
    "cityId": 5062,
    "cityName": "Santa Inês",
    "stateId": 5248
  },
  {
    "cityId": 5063,
    "cityName": "Santa Luzia",
    "stateId": 5248
  },
  {
    "cityId": 5064,
    "cityName": "Santa Luzia do Cariri",
    "stateId": 5248
  },
  {
    "cityId": 5065,
    "cityName": "Santa Maria",
    "stateId": 5248
  },
  {
    "cityId": 5066,
    "cityName": "Santa Rita",
    "stateId": 5248
  },
  {
    "cityId": 5067,
    "cityName": "Santa Rita",
    "stateId": 5248
  },
  {
    "cityId": 5068,
    "cityName": "Santa Teresinha",
    "stateId": 5248
  },
  {
    "cityId": 5069,
    "cityName": "Santa Terezinha",
    "stateId": 5248
  },
  {
    "cityId": 5070,
    "cityName": "Santana de Mangueira",
    "stateId": 5248
  },
  {
    "cityId": 5071,
    "cityName": "Santana dos Garrotes",
    "stateId": 5248
  },
  {
    "cityId": 5072,
    "cityName": "Santarém",
    "stateId": 5248
  },
  {
    "cityId": 5073,
    "cityName": "Santo André",
    "stateId": 5248
  },
  {
    "cityId": 5074,
    "cityName": "São Bento",
    "stateId": 5248
  },
  {
    "cityId": 5075,
    "cityName": "São Bentinho",
    "stateId": 5248
  },
  {
    "cityId": 5076,
    "cityName": "São Domingos de Pombal",
    "stateId": 5248
  },
  {
    "cityId": 5077,
    "cityName": "São Domingos do Cariri",
    "stateId": 5248
  },
  {
    "cityId": 5078,
    "cityName": "São Francisco",
    "stateId": 5248
  },
  {
    "cityId": 5079,
    "cityName": "São Gonçalo",
    "stateId": 5248
  },
  {
    "cityId": 5080,
    "cityName": "São João Bosco",
    "stateId": 5248
  },
  {
    "cityId": 5081,
    "cityName": "São João do Cariri",
    "stateId": 5248
  },
  {
    "cityId": 5082,
    "cityName": "São João do Rio do Peixe",
    "stateId": 5248
  },
  {
    "cityId": 5083,
    "cityName": "São João do Tigre",
    "stateId": 5248
  },
  {
    "cityId": 5084,
    "cityName": "São José da Lagoa Tapada",
    "stateId": 5248
  },
  {
    "cityId": 5085,
    "cityName": "São José da Mata",
    "stateId": 5248
  },
  {
    "cityId": 5086,
    "cityName": "São José de Caiana",
    "stateId": 5248
  },
  {
    "cityId": 5087,
    "cityName": "São José de Espinharas",
    "stateId": 5248
  },
  {
    "cityId": 5088,
    "cityName": "São José de Marimbas",
    "stateId": 5248
  },
  {
    "cityId": 5089,
    "cityName": "São José de Piranhas",
    "stateId": 5248
  },
  {
    "cityId": 5090,
    "cityName": "São José de Princesa",
    "stateId": 5248
  },
  {
    "cityId": 5091,
    "cityName": "São José do Bonfim",
    "stateId": 5248
  },
  {
    "cityId": 5092,
    "cityName": "São José do Brejo do Cruz",
    "stateId": 5248
  },
  {
    "cityId": 5093,
    "cityName": "São José do Sabugi",
    "stateId": 5248
  },
  {
    "cityId": 5094,
    "cityName": "São José dos Cordeiros",
    "stateId": 5248
  },
  {
    "cityId": 5095,
    "cityName": "São José dos Ramos",
    "stateId": 5248
  },
  {
    "cityId": 5096,
    "cityName": "São Mamede",
    "stateId": 5248
  },
  {
    "cityId": 5097,
    "cityName": "São Miguel de Taipu",
    "stateId": 5248
  },
  {
    "cityId": 5098,
    "cityName": "São Pedro",
    "stateId": 5248
  },
  {
    "cityId": 5099,
    "cityName": "São Sebastião de Lagoa de Roça",
    "stateId": 5248
  },
  {
    "cityId": 5100,
    "cityName": "São Sebastião do Umbuzeiro",
    "stateId": 5248
  },
  {
    "cityId": 5101,
    "cityName": "Sapé",
    "stateId": 5248
  },
  {
    "cityId": 5102,
    "cityName": "Seridó",
    "stateId": 5248
  },
  {
    "cityId": 5103,
    "cityName": "São Vicente do Seridó",
    "stateId": 5248
  },
  {
    "cityId": 5104,
    "cityName": "Serra Branca",
    "stateId": 5248
  },
  {
    "cityId": 5105,
    "cityName": "Serra da Raiz",
    "stateId": 5248
  },
  {
    "cityId": 5106,
    "cityName": "Serra Grande",
    "stateId": 5248
  },
  {
    "cityId": 5107,
    "cityName": "Serra Redonda",
    "stateId": 5248
  },
  {
    "cityId": 5108,
    "cityName": "Serraria",
    "stateId": 5248
  },
  {
    "cityId": 5109,
    "cityName": "Sertãozinho",
    "stateId": 5248
  },
  {
    "cityId": 5110,
    "cityName": "Sobrado",
    "stateId": 5248
  },
  {
    "cityId": 5111,
    "cityName": "Solânea",
    "stateId": 5248
  },
  {
    "cityId": 5112,
    "cityName": "Soledade",
    "stateId": 5248
  },
  {
    "cityId": 5113,
    "cityName": "Sossego",
    "stateId": 5248
  },
  {
    "cityId": 5114,
    "cityName": "Sousa",
    "stateId": 5248
  },
  {
    "cityId": 5115,
    "cityName": "Sucuru",
    "stateId": 5248
  },
  {
    "cityId": 5116,
    "cityName": "Sumé",
    "stateId": 5248
  },
  {
    "cityId": 5117,
    "cityName": "Campo de Santana",
    "stateId": 5248
  },
  {
    "cityId": 5119,
    "cityName": "Tambauzinho",
    "stateId": 5248
  },
  {
    "cityId": 5120,
    "cityName": "Taperoá",
    "stateId": 5248
  },
  {
    "cityId": 5121,
    "cityName": "Tavares",
    "stateId": 5248
  },
  {
    "cityId": 5122,
    "cityName": "Teixeira",
    "stateId": 5248
  },
  {
    "cityId": 5123,
    "cityName": "Tenório",
    "stateId": 5248
  },
  {
    "cityId": 5124,
    "cityName": "Triunfo",
    "stateId": 5248
  },
  {
    "cityId": 5125,
    "cityName": "Uiraúna",
    "stateId": 5248
  },
  {
    "cityId": 5126,
    "cityName": "Umari",
    "stateId": 5248
  },
  {
    "cityId": 5127,
    "cityName": "Umbuzeiro",
    "stateId": 5248
  },
  {
    "cityId": 5128,
    "cityName": "Várzea",
    "stateId": 5248
  },
  {
    "cityId": 5129,
    "cityName": "Várzea Comprida",
    "stateId": 5248
  },
  {
    "cityId": 5130,
    "cityName": "Várzea Nova",
    "stateId": 5248
  },
  {
    "cityId": 5131,
    "cityName": "Vazante",
    "stateId": 5248
  },
  {
    "cityId": 5132,
    "cityName": "Vieirópolis",
    "stateId": 5248
  },
  {
    "cityId": 5133,
    "cityName": "Vista Serrana",
    "stateId": 5248
  },
  {
    "cityId": 5134,
    "cityName": "Zabelê",
    "stateId": 5248
  },
  {
    "cityId": 5135,
    "cityName": "Abreu e Lima",
    "stateId": 5249
  },
  {
    "cityId": 5136,
    "cityName": "Afogados da Ingazeira",
    "stateId": 5249
  },
  {
    "cityId": 5137,
    "cityName": "Afrânio",
    "stateId": 5249
  },
  {
    "cityId": 5138,
    "cityName": "Agrestina",
    "stateId": 5249
  },
  {
    "cityId": 5139,
    "cityName": "Água Fria",
    "stateId": 5249
  },
  {
    "cityId": 5140,
    "cityName": "Água Preta",
    "stateId": 5249
  },
  {
    "cityId": 5141,
    "cityName": "Águas Belas",
    "stateId": 5249
  },
  {
    "cityId": 5142,
    "cityName": "Airi",
    "stateId": 5249
  },
  {
    "cityId": 5143,
    "cityName": "Alagoinha",
    "stateId": 5249
  },
  {
    "cityId": 5144,
    "cityName": "Albuquerque Né",
    "stateId": 5249
  },
  {
    "cityId": 5145,
    "cityName": "Algodões",
    "stateId": 5249
  },
  {
    "cityId": 5146,
    "cityName": "Aliança",
    "stateId": 5249
  },
  {
    "cityId": 5147,
    "cityName": "Altinho",
    "stateId": 5249
  },
  {
    "cityId": 5148,
    "cityName": "Amaraji",
    "stateId": 5249
  },
  {
    "cityId": 5149,
    "cityName": "Ameixas",
    "stateId": 5249
  },
  {
    "cityId": 5150,
    "cityName": "Angelim",
    "stateId": 5249
  },
  {
    "cityId": 5151,
    "cityName": "Apoti",
    "stateId": 5249
  },
  {
    "cityId": 5152,
    "cityName": "Araçoiaba",
    "stateId": 5249
  },
  {
    "cityId": 5153,
    "cityName": "Araripina",
    "stateId": 5249
  },
  {
    "cityId": 5154,
    "cityName": "Arcoverde",
    "stateId": 5249
  },
  {
    "cityId": 5155,
    "cityName": "Aripibu",
    "stateId": 5249
  },
  {
    "cityId": 5156,
    "cityName": "Arizona",
    "stateId": 5249
  },
  {
    "cityId": 5157,
    "cityName": "Barra de Farias",
    "stateId": 5249
  },
  {
    "cityId": 5158,
    "cityName": "Barra de Guabiraba",
    "stateId": 5249
  },
  {
    "cityId": 5159,
    "cityName": "Barra de São Pedro",
    "stateId": 5249
  },
  {
    "cityId": 5160,
    "cityName": "Barra do Brejo",
    "stateId": 5249
  },
  {
    "cityId": 5161,
    "cityName": "Barra do Chata",
    "stateId": 5249
  },
  {
    "cityId": 5162,
    "cityName": "Barra do Jardim",
    "stateId": 5249
  },
  {
    "cityId": 5163,
    "cityName": "Barra do Riachão",
    "stateId": 5249
  },
  {
    "cityId": 5164,
    "cityName": "Barra do Sirinhaém",
    "stateId": 5249
  },
  {
    "cityId": 5165,
    "cityName": "Barreiros",
    "stateId": 5249
  },
  {
    "cityId": 5166,
    "cityName": "Batateira",
    "stateId": 5249
  },
  {
    "cityId": 5167,
    "cityName": "Belém de Maria",
    "stateId": 5249
  },
  {
    "cityId": 5168,
    "cityName": "Belém do São Francisco",
    "stateId": 5249
  },
  {
    "cityId": 5169,
    "cityName": "Belo Jardim",
    "stateId": 5249
  },
  {
    "cityId": 5170,
    "cityName": "Bengalas",
    "stateId": 5249
  },
  {
    "cityId": 5171,
    "cityName": "Bentivi",
    "stateId": 5249
  },
  {
    "cityId": 5172,
    "cityName": "Bernardo Vieira",
    "stateId": 5249
  },
  {
    "cityId": 5173,
    "cityName": "Betânia",
    "stateId": 5249
  },
  {
    "cityId": 5174,
    "cityName": "Bezerros",
    "stateId": 5249
  },
  {
    "cityId": 5175,
    "cityName": "Bizarra",
    "stateId": 5249
  },
  {
    "cityId": 5176,
    "cityName": "Boas Novas",
    "stateId": 5249
  },
  {
    "cityId": 5177,
    "cityName": "Bodocó",
    "stateId": 5249
  },
  {
    "cityId": 5178,
    "cityName": "Bom Conselho",
    "stateId": 5249
  },
  {
    "cityId": 5179,
    "cityName": "Bom Jardim",
    "stateId": 5249
  },
  {
    "cityId": 5180,
    "cityName": "Bom Nome",
    "stateId": 5249
  },
  {
    "cityId": 5181,
    "cityName": "Bonfim",
    "stateId": 5249
  },
  {
    "cityId": 5182,
    "cityName": "Bonito",
    "stateId": 5249
  },
  {
    "cityId": 5183,
    "cityName": "Brejão",
    "stateId": 5249
  },
  {
    "cityId": 5184,
    "cityName": "Brejinho",
    "stateId": 5249
  },
  {
    "cityId": 5185,
    "cityName": "Brejo da Madre de Deus",
    "stateId": 5249
  },
  {
    "cityId": 5186,
    "cityName": "Buenos Aires",
    "stateId": 5249
  },
  {
    "cityId": 5187,
    "cityName": "Buíque",
    "stateId": 5249
  },
  {
    "cityId": 5188,
    "cityName": "Cabanas",
    "stateId": 5249
  },
  {
    "cityId": 5189,
    "cityName": "Cabo de Santo Agostinho",
    "stateId": 5249
  },
  {
    "cityId": 5190,
    "cityName": "Cabrobó",
    "stateId": 5249
  },
  {
    "cityId": 5191,
    "cityName": "Cachoeira do Roberto",
    "stateId": 5249
  },
  {
    "cityId": 5192,
    "cityName": "Cachoeirinha",
    "stateId": 5249
  },
  {
    "cityId": 5193,
    "cityName": "Caetés",
    "stateId": 5249
  },
  {
    "cityId": 5194,
    "cityName": "Caiçarinha da Penha",
    "stateId": 5249
  },
  {
    "cityId": 5195,
    "cityName": "Calçado",
    "stateId": 5249
  },
  {
    "cityId": 5196,
    "cityName": "Caldeirões",
    "stateId": 5249
  },
  {
    "cityId": 5197,
    "cityName": "Calumbi",
    "stateId": 5249
  },
  {
    "cityId": 5198,
    "cityName": "Camaragibe",
    "stateId": 5249
  },
  {
    "cityId": 5199,
    "cityName": "Camela",
    "stateId": 5249
  },
  {
    "cityId": 5200,
    "cityName": "Camocim de São Félix",
    "stateId": 5249
  },
  {
    "cityId": 5201,
    "cityName": "Camutanga",
    "stateId": 5249
  },
  {
    "cityId": 5202,
    "cityName": "Canaã",
    "stateId": 5249
  },
  {
    "cityId": 5203,
    "cityName": "Canhotinho",
    "stateId": 5249
  },
  {
    "cityId": 5204,
    "cityName": "Capoeiras",
    "stateId": 5249
  },
  {
    "cityId": 5205,
    "cityName": "Caraíba",
    "stateId": 5249
  },
  {
    "cityId": 5206,
    "cityName": "Caraibeiras",
    "stateId": 5249
  },
  {
    "cityId": 5207,
    "cityName": "Carapotos",
    "stateId": 5249
  },
  {
    "cityId": 5208,
    "cityName": "Carice",
    "stateId": 5249
  },
  {
    "cityId": 5209,
    "cityName": "Carima",
    "stateId": 5249
  },
  {
    "cityId": 5210,
    "cityName": "Caririmirim",
    "stateId": 5249
  },
  {
    "cityId": 5211,
    "cityName": "Carnaíba",
    "stateId": 5249
  },
  {
    "cityId": 5212,
    "cityName": "Carnaubeira da Penha",
    "stateId": 5249
  },
  {
    "cityId": 5213,
    "cityName": "Carneiro",
    "stateId": 5249
  },
  {
    "cityId": 5214,
    "cityName": "Carpina",
    "stateId": 5249
  },
  {
    "cityId": 5215,
    "cityName": "Carqueja",
    "stateId": 5249
  },
  {
    "cityId": 5216,
    "cityName": "Caruaru",
    "stateId": 5249
  },
  {
    "cityId": 5217,
    "cityName": "Casinhas",
    "stateId": 5249
  },
  {
    "cityId": 5218,
    "cityName": "Catende",
    "stateId": 5249
  },
  {
    "cityId": 5219,
    "cityName": "Catimbaú",
    "stateId": 5249
  },
  {
    "cityId": 5220,
    "cityName": "Catolé",
    "stateId": 5249
  },
  {
    "cityId": 5222,
    "cityName": "Cedro",
    "stateId": 5249
  },
  {
    "cityId": 5223,
    "cityName": "Chã de Alegria",
    "stateId": 5249
  },
  {
    "cityId": 5224,
    "cityName": "Chã do Rocha",
    "stateId": 5249
  },
  {
    "cityId": 5225,
    "cityName": "Chã Grande",
    "stateId": 5249
  },
  {
    "cityId": 5226,
    "cityName": "Cimbres",
    "stateId": 5249
  },
  {
    "cityId": 5227,
    "cityName": "Clarana",
    "stateId": 5249
  },
  {
    "cityId": 5228,
    "cityName": "Cocau",
    "stateId": 5249
  },
  {
    "cityId": 5229,
    "cityName": "Cocau",
    "stateId": 5249
  },
  {
    "cityId": 5230,
    "cityName": "Conceição das Crioulas",
    "stateId": 5249
  },
  {
    "cityId": 5231,
    "cityName": "Condado",
    "stateId": 5249
  },
  {
    "cityId": 5232,
    "cityName": "Correntes",
    "stateId": 5249
  },
  {
    "cityId": 5233,
    "cityName": "Cortês",
    "stateId": 5249
  },
  {
    "cityId": 5234,
    "cityName": "Couro D'Antas",
    "stateId": 5249
  },
  {
    "cityId": 5235,
    "cityName": "Cristália",
    "stateId": 5249
  },
  {
    "cityId": 5236,
    "cityName": "Cruanji",
    "stateId": 5249
  },
  {
    "cityId": 5237,
    "cityName": "Cruzes",
    "stateId": 5249
  },
  {
    "cityId": 5238,
    "cityName": "Cuiambuca",
    "stateId": 5249
  },
  {
    "cityId": 5239,
    "cityName": "Cumaru",
    "stateId": 5249
  },
  {
    "cityId": 5240,
    "cityName": "Cupira",
    "stateId": 5249
  },
  {
    "cityId": 5241,
    "cityName": "Curral Queimado",
    "stateId": 5249
  },
  {
    "cityId": 5242,
    "cityName": "Custódia",
    "stateId": 5249
  },
  {
    "cityId": 5243,
    "cityName": "Dois Leões",
    "stateId": 5249
  },
  {
    "cityId": 5244,
    "cityName": "Dormentes",
    "stateId": 5249
  },
  {
    "cityId": 5245,
    "cityName": "Entroncamento",
    "stateId": 5249
  },
  {
    "cityId": 5246,
    "cityName": "Escada",
    "stateId": 5249
  },
  {
    "cityId": 5247,
    "cityName": "Espírito Santo",
    "stateId": 5249
  },
  {
    "cityId": 5248,
    "cityName": "Exu",
    "stateId": 5249
  },
  {
    "cityId": 5249,
    "cityName": "Fazenda Nova",
    "stateId": 5249
  },
  {
    "cityId": 5250,
    "cityName": "Feira Nova",
    "stateId": 5249
  },
  {
    "cityId": 5251,
    "cityName": "Feitoria",
    "stateId": 5249
  },
  {
    "cityId": 5252,
    "cityName": "Fernando de Noronha",
    "stateId": 5249
  },
  {
    "cityId": 5253,
    "cityName": "Ferreiros",
    "stateId": 5249
  },
  {
    "cityId": 5254,
    "cityName": "Flores",
    "stateId": 5249
  },
  {
    "cityId": 5255,
    "cityName": "Floresta",
    "stateId": 5249
  },
  {
    "cityId": 5256,
    "cityName": "Frei Miguelinho",
    "stateId": 5249
  },
  {
    "cityId": 5257,
    "cityName": "Frexeiras",
    "stateId": 5249
  },
  {
    "cityId": 5258,
    "cityName": "Gameleira",
    "stateId": 5249
  },
  {
    "cityId": 5259,
    "cityName": "Garanhuns",
    "stateId": 5249
  },
  {
    "cityId": 5260,
    "cityName": "Glória do Goitá",
    "stateId": 5249
  },
  {
    "cityId": 5261,
    "cityName": "Goiana",
    "stateId": 5249
  },
  {
    "cityId": 5262,
    "cityName": "Gonçalves Ferreira",
    "stateId": 5249
  },
  {
    "cityId": 5263,
    "cityName": "Granito",
    "stateId": 5249
  },
  {
    "cityId": 5264,
    "cityName": "Gravatá",
    "stateId": 5249
  },
  {
    "cityId": 5265,
    "cityName": "Gravatá do Ibiapina",
    "stateId": 5249
  },
  {
    "cityId": 5266,
    "cityName": "Grotão",
    "stateId": 5249
  },
  {
    "cityId": 5267,
    "cityName": "Guanumbi",
    "stateId": 5249
  },
  {
    "cityId": 5268,
    "cityName": "Henrique Dias",
    "stateId": 5249
  },
  {
    "cityId": 5269,
    "cityName": "Iateca",
    "stateId": 5249
  },
  {
    "cityId": 5270,
    "cityName": "Iati",
    "stateId": 5249
  },
  {
    "cityId": 5271,
    "cityName": "Ibimirim",
    "stateId": 5249
  },
  {
    "cityId": 5272,
    "cityName": "Ibirajuba",
    "stateId": 5249
  },
  {
    "cityId": 5273,
    "cityName": "Ibiranga",
    "stateId": 5249
  },
  {
    "cityId": 5274,
    "cityName": "Ibiratinga",
    "stateId": 5249
  },
  {
    "cityId": 5275,
    "cityName": "Ibitiranga",
    "stateId": 5249
  },
  {
    "cityId": 5276,
    "cityName": "Ibó",
    "stateId": 5249
  },
  {
    "cityId": 5277,
    "cityName": "Icaiçara",
    "stateId": 5249
  },
  {
    "cityId": 5278,
    "cityName": "Igapó",
    "stateId": 5249
  },
  {
    "cityId": 5279,
    "cityName": "Igarapeassu",
    "stateId": 5249
  },
  {
    "cityId": 5280,
    "cityName": "Igarapeba",
    "stateId": 5249
  },
  {
    "cityId": 5281,
    "cityName": "Igarassu",
    "stateId": 5249
  },
  {
    "cityId": 5282,
    "cityName": "Iguaraci",
    "stateId": 5249
  },
  {
    "cityId": 5283,
    "cityName": "Inajá",
    "stateId": 5249
  },
  {
    "cityId": 5284,
    "cityName": "Ingazeira",
    "stateId": 5249
  },
  {
    "cityId": 5285,
    "cityName": "Ipojuca",
    "stateId": 5249
  },
  {
    "cityId": 5286,
    "cityName": "Ipubi",
    "stateId": 5249
  },
  {
    "cityId": 5287,
    "cityName": "Ipuera",
    "stateId": 5249
  },
  {
    "cityId": 5288,
    "cityName": "Iraguaçu",
    "stateId": 5249
  },
  {
    "cityId": 5289,
    "cityName": "Irajaí",
    "stateId": 5249
  },
  {
    "cityId": 5290,
    "cityName": "Iratama",
    "stateId": 5249
  },
  {
    "cityId": 5291,
    "cityName": "Itacuruba",
    "stateId": 5249
  },
  {
    "cityId": 5292,
    "cityName": "Itaíba",
    "stateId": 5249
  },
  {
    "cityId": 5293,
    "cityName": "Ilha de Itamaracá",
    "stateId": 5249
  },
  {
    "cityId": 5294,
    "cityName": "Itambé",
    "stateId": 5249
  },
  {
    "cityId": 5295,
    "cityName": "Itapetim",
    "stateId": 5249
  },
  {
    "cityId": 5296,
    "cityName": "Itapissuma",
    "stateId": 5249
  },
  {
    "cityId": 5297,
    "cityName": "Itaquitinga",
    "stateId": 5249
  },
  {
    "cityId": 5298,
    "cityName": "Ituguaçu",
    "stateId": 5249
  },
  {
    "cityId": 5299,
    "cityName": "Iuiteporã",
    "stateId": 5249
  },
  {
    "cityId": 5300,
    "cityName": "Jabitaca",
    "stateId": 5249
  },
  {
    "cityId": 5302,
    "cityName": "Jaboatão dos Guararapes",
    "stateId": 5249
  },
  {
    "cityId": 5303,
    "cityName": "Japecanga",
    "stateId": 5249
  },
  {
    "cityId": 5304,
    "cityName": "Jaqueira",
    "stateId": 5249
  },
  {
    "cityId": 5305,
    "cityName": "Jataúba",
    "stateId": 5249
  },
  {
    "cityId": 5306,
    "cityName": "Jatiúca",
    "stateId": 5249
  },
  {
    "cityId": 5307,
    "cityName": "Jatobá",
    "stateId": 5249
  },
  {
    "cityId": 5308,
    "cityName": "Jenipapo",
    "stateId": 5249
  },
  {
    "cityId": 5309,
    "cityName": "João Alfredo",
    "stateId": 5249
  },
  {
    "cityId": 5310,
    "cityName": "Joaquim Nabuco",
    "stateId": 5249
  },
  {
    "cityId": 5311,
    "cityName": "José da Costa",
    "stateId": 5249
  },
  {
    "cityId": 5312,
    "cityName": "José Mariano",
    "stateId": 5249
  },
  {
    "cityId": 5313,
    "cityName": "Juçaral",
    "stateId": 5249
  },
  {
    "cityId": 5314,
    "cityName": "Jucati",
    "stateId": 5249
  },
  {
    "cityId": 5315,
    "cityName": "Jupi",
    "stateId": 5249
  },
  {
    "cityId": 5316,
    "cityName": "Jurema",
    "stateId": 5249
  },
  {
    "cityId": 5317,
    "cityName": "Jutaí",
    "stateId": 5249
  },
  {
    "cityId": 5318,
    "cityName": "Lagoa",
    "stateId": 5249
  },
  {
    "cityId": 5319,
    "cityName": "Lagoa de São José",
    "stateId": 5249
  },
  {
    "cityId": 5320,
    "cityName": "Lagoa do Barro",
    "stateId": 5249
  },
  {
    "cityId": 5321,
    "cityName": "Lagoa do Carro",
    "stateId": 5249
  },
  {
    "cityId": 5322,
    "cityName": "Lagoa do Itaenga",
    "stateId": 5249
  },
  {
    "cityId": 5323,
    "cityName": "Lagoa do Ouro",
    "stateId": 5249
  },
  {
    "cityId": 5324,
    "cityName": "Lagoa do Souza",
    "stateId": 5249
  },
  {
    "cityId": 5325,
    "cityName": "Lagoa dos Gatos",
    "stateId": 5249
  },
  {
    "cityId": 5326,
    "cityName": "Lagoa Grande",
    "stateId": 5249
  },
  {
    "cityId": 5327,
    "cityName": "Laje de São José",
    "stateId": 5249
  },
  {
    "cityId": 5328,
    "cityName": "Laje Grande",
    "stateId": 5249
  },
  {
    "cityId": 5329,
    "cityName": "Lajedo",
    "stateId": 5249
  },
  {
    "cityId": 5330,
    "cityName": "Lajedo do Cedro",
    "stateId": 5249
  },
  {
    "cityId": 5331,
    "cityName": "Limoeiro",
    "stateId": 5249
  },
  {
    "cityId": 5332,
    "cityName": "Livramento do Tiúma",
    "stateId": 5249
  },
  {
    "cityId": 5333,
    "cityName": "Luanda",
    "stateId": 5249
  },
  {
    "cityId": 5334,
    "cityName": "Macaparana",
    "stateId": 5249
  },
  {
    "cityId": 5335,
    "cityName": "Machados",
    "stateId": 5249
  },
  {
    "cityId": 5336,
    "cityName": "Macujé",
    "stateId": 5249
  },
  {
    "cityId": 5337,
    "cityName": "Manari",
    "stateId": 5249
  },
  {
    "cityId": 5338,
    "cityName": "Mandacaia",
    "stateId": 5249
  },
  {
    "cityId": 5339,
    "cityName": "Mandacaru",
    "stateId": 5249
  },
  {
    "cityId": 5340,
    "cityName": "Maniçoba",
    "stateId": 5249
  },
  {
    "cityId": 5341,
    "cityName": "Maraial",
    "stateId": 5249
  },
  {
    "cityId": 5342,
    "cityName": "Maravilha",
    "stateId": 5249
  },
  {
    "cityId": 5343,
    "cityName": "Mimoso",
    "stateId": 5249
  },
  {
    "cityId": 5344,
    "cityName": "Miracica",
    "stateId": 5249
  },
  {
    "cityId": 5345,
    "cityName": "Mirandiba",
    "stateId": 5249
  },
  {
    "cityId": 5346,
    "cityName": "Morais",
    "stateId": 5249
  },
  {
    "cityId": 5347,
    "cityName": "Moreilândia",
    "stateId": 5249
  },
  {
    "cityId": 5348,
    "cityName": "Moreno",
    "stateId": 5249
  },
  {
    "cityId": 5349,
    "cityName": "Moxotó",
    "stateId": 5249
  },
  {
    "cityId": 5350,
    "cityName": "Mulungu",
    "stateId": 5249
  },
  {
    "cityId": 5351,
    "cityName": "Murupé",
    "stateId": 5249
  },
  {
    "cityId": 5352,
    "cityName": "Mutuca",
    "stateId": 5249
  },
  {
    "cityId": 5353,
    "cityName": "Nascente",
    "stateId": 5249
  },
  {
    "cityId": 5355,
    "cityName": "Nazaré da Mata",
    "stateId": 5249
  },
  {
    "cityId": 5356,
    "cityName": "Negras",
    "stateId": 5249
  },
  {
    "cityId": 5357,
    "cityName": "Nossa Senhora da Luz",
    "stateId": 5249
  },
  {
    "cityId": 5358,
    "cityName": "Nossa Senhora do Carmo",
    "stateId": 5249
  },
  {
    "cityId": 5359,
    "cityName": "Nossa Senhora do Ó",
    "stateId": 5249
  },
  {
    "cityId": 5360,
    "cityName": "Nova Cruz",
    "stateId": 5249
  },
  {
    "cityId": 5361,
    "cityName": "Olho D'Água de Dentro",
    "stateId": 5249
  },
  {
    "cityId": 5362,
    "cityName": "Olinda",
    "stateId": 5249
  },
  {
    "cityId": 5363,
    "cityName": "Oratório",
    "stateId": 5249
  },
  {
    "cityId": 5364,
    "cityName": "Ori",
    "stateId": 5249
  },
  {
    "cityId": 5365,
    "cityName": "Orobó",
    "stateId": 5249
  },
  {
    "cityId": 5366,
    "cityName": "Orocó",
    "stateId": 5249
  },
  {
    "cityId": 5367,
    "cityName": "Ouricuri",
    "stateId": 5249
  },
  {
    "cityId": 5368,
    "cityName": "Pajeú",
    "stateId": 5249
  },
  {
    "cityId": 5369,
    "cityName": "Palmares",
    "stateId": 5249
  },
  {
    "cityId": 5370,
    "cityName": "Palmeirina",
    "stateId": 5249
  },
  {
    "cityId": 5371,
    "cityName": "Panelas",
    "stateId": 5249
  },
  {
    "cityId": 5372,
    "cityName": "Pão de Açúcar",
    "stateId": 5249
  },
  {
    "cityId": 5373,
    "cityName": "Pão de Açúcar do Poção",
    "stateId": 5249
  },
  {
    "cityId": 5374,
    "cityName": "Papagaio",
    "stateId": 5249
  },
  {
    "cityId": 5375,
    "cityName": "Paquevira",
    "stateId": 5249
  },
  {
    "cityId": 5376,
    "cityName": "Pará",
    "stateId": 5249
  },
  {
    "cityId": 5377,
    "cityName": "Paranatama",
    "stateId": 5249
  },
  {
    "cityId": 5379,
    "cityName": "Parnamirim",
    "stateId": 5249
  },
  {
    "cityId": 5380,
    "cityName": "Passagem do Tó",
    "stateId": 5249
  },
  {
    "cityId": 5381,
    "cityName": "Passira",
    "stateId": 5249
  },
  {
    "cityId": 5382,
    "cityName": "Pau Ferro",
    "stateId": 5249
  },
  {
    "cityId": 5383,
    "cityName": "Paudalho",
    "stateId": 5249
  },
  {
    "cityId": 5384,
    "cityName": "Paulista",
    "stateId": 5249
  },
  {
    "cityId": 5385,
    "cityName": "Pedra",
    "stateId": 5249
  },
  {
    "cityId": 5386,
    "cityName": "Perpétuo Socorro",
    "stateId": 5249
  },
  {
    "cityId": 5387,
    "cityName": "Pesqueira",
    "stateId": 5249
  },
  {
    "cityId": 5388,
    "cityName": "Petrolândia",
    "stateId": 5249
  },
  {
    "cityId": 5389,
    "cityName": "Petrolina",
    "stateId": 5249
  },
  {
    "cityId": 5390,
    "cityName": "Pirituba",
    "stateId": 5249
  },
  {
    "cityId": 5391,
    "cityName": "Poção",
    "stateId": 5249
  },
  {
    "cityId": 5392,
    "cityName": "Poção de Afrânio",
    "stateId": 5249
  },
  {
    "cityId": 5393,
    "cityName": "Poço Comprido",
    "stateId": 5249
  },
  {
    "cityId": 5394,
    "cityName": "Poço Fundo",
    "stateId": 5249
  },
  {
    "cityId": 5395,
    "cityName": "Pombos",
    "stateId": 5249
  },
  {
    "cityId": 5396,
    "cityName": "Pontas de Pedra",
    "stateId": 5249
  },
  {
    "cityId": 5399,
    "cityName": "Primavera",
    "stateId": 5249
  },
  {
    "cityId": 5400,
    "cityName": "Quipapá",
    "stateId": 5249
  },
  {
    "cityId": 5401,
    "cityName": "Quitimbu",
    "stateId": 5249
  },
  {
    "cityId": 5402,
    "cityName": "Quixabá",
    "stateId": 5249
  },
  {
    "cityId": 5403,
    "cityName": "Rainha Isabel",
    "stateId": 5249
  },
  {
    "cityId": 5404,
    "cityName": "Rajada",
    "stateId": 5249
  },
  {
    "cityId": 5405,
    "cityName": "Rancharia",
    "stateId": 5249
  },
  {
    "cityId": 5406,
    "cityName": "Recife",
    "stateId": 5249
  },
  {
    "cityId": 5407,
    "cityName": "Riacho das Almas",
    "stateId": 5249
  },
  {
    "cityId": 5408,
    "cityName": "Riacho do Meio",
    "stateId": 5249
  },
  {
    "cityId": 5409,
    "cityName": "Riacho Fechado",
    "stateId": 5249
  },
  {
    "cityId": 5410,
    "cityName": "Riacho Pequeno",
    "stateId": 5249
  },
  {
    "cityId": 5411,
    "cityName": "Ribeirão",
    "stateId": 5249
  },
  {
    "cityId": 5412,
    "cityName": "Rio da Barra",
    "stateId": 5249
  },
  {
    "cityId": 5413,
    "cityName": "Rio Formoso",
    "stateId": 5249
  },
  {
    "cityId": 5414,
    "cityName": "Sairé",
    "stateId": 5249
  },
  {
    "cityId": 5415,
    "cityName": "Salgadinho",
    "stateId": 5249
  },
  {
    "cityId": 5416,
    "cityName": "Salgueiro",
    "stateId": 5249
  },
  {
    "cityId": 5417,
    "cityName": "Saloá",
    "stateId": 5249
  },
  {
    "cityId": 5418,
    "cityName": "Salobro",
    "stateId": 5249
  },
  {
    "cityId": 5419,
    "cityName": "Sanharó",
    "stateId": 5249
  },
  {
    "cityId": 5420,
    "cityName": "Santa Cruz",
    "stateId": 5249
  },
  {
    "cityId": 5421,
    "cityName": "Santa Cruz da Baixa Verde",
    "stateId": 5249
  },
  {
    "cityId": 5422,
    "cityName": "Santa Cruz do Capibaribe",
    "stateId": 5249
  },
  {
    "cityId": 5423,
    "cityName": "Santa Filomena",
    "stateId": 5249
  },
  {
    "cityId": 5424,
    "cityName": "Santa Maria da Boa Vista",
    "stateId": 5249
  },
  {
    "cityId": 5425,
    "cityName": "Santa Maria do Cambucá",
    "stateId": 5249
  },
  {
    "cityId": 5426,
    "cityName": "Santa Rita",
    "stateId": 5249
  },
  {
    "cityId": 5427,
    "cityName": "Santa Terezinha",
    "stateId": 5249
  },
  {
    "cityId": 5428,
    "cityName": "Santa Terezinha",
    "stateId": 5249
  },
  {
    "cityId": 5429,
    "cityName": "Santana de São Joaquim",
    "stateId": 5249
  },
  {
    "cityId": 5430,
    "cityName": "Santo Agostinho",
    "stateId": 5249
  },
  {
    "cityId": 5431,
    "cityName": "Santo Antônio das Queimadas",
    "stateId": 5249
  },
  {
    "cityId": 5432,
    "cityName": "Santo Antônio dos Palmares",
    "stateId": 5249
  },
  {
    "cityId": 5433,
    "cityName": "São Benedito do Sul",
    "stateId": 5249
  },
  {
    "cityId": 5434,
    "cityName": "São Bento do Una",
    "stateId": 5249
  },
  {
    "cityId": 5435,
    "cityName": "São Caetano do Navio",
    "stateId": 5249
  },
  {
    "cityId": 5436,
    "cityName": "São Caitano",
    "stateId": 5249
  },
  {
    "cityId": 5437,
    "cityName": "São Domingos",
    "stateId": 5249
  },
  {
    "cityId": 5438,
    "cityName": "São João",
    "stateId": 5249
  },
  {
    "cityId": 5439,
    "cityName": "São Joaquim do Monte",
    "stateId": 5249
  },
  {
    "cityId": 5440,
    "cityName": "São José",
    "stateId": 5249
  },
  {
    "cityId": 5441,
    "cityName": "São José da Coroa Grande",
    "stateId": 5249
  },
  {
    "cityId": 5442,
    "cityName": "São José do Belmonte",
    "stateId": 5249
  },
  {
    "cityId": 5443,
    "cityName": "São José do Egito",
    "stateId": 5249
  },
  {
    "cityId": 5444,
    "cityName": "São Lázaro",
    "stateId": 5249
  },
  {
    "cityId": 5445,
    "cityName": "São Lourenço da Mata",
    "stateId": 5249
  },
  {
    "cityId": 5446,
    "cityName": "São Pedro",
    "stateId": 5249
  },
  {
    "cityId": 5447,
    "cityName": "São Vicente",
    "stateId": 5249
  },
  {
    "cityId": 5448,
    "cityName": "São Vicente Ferrer",
    "stateId": 5249
  },
  {
    "cityId": 5449,
    "cityName": "Sapucarana",
    "stateId": 5249
  },
  {
    "cityId": 5450,
    "cityName": "Saué",
    "stateId": 5249
  },
  {
    "cityId": 5451,
    "cityName": "Serra Branca",
    "stateId": 5249
  },
  {
    "cityId": 5452,
    "cityName": "Serra do Vento",
    "stateId": 5249
  },
  {
    "cityId": 5453,
    "cityName": "Serra Talhada",
    "stateId": 5249
  },
  {
    "cityId": 5454,
    "cityName": "Serrita",
    "stateId": 5249
  },
  {
    "cityId": 5455,
    "cityName": "Serrolândia",
    "stateId": 5249
  },
  {
    "cityId": 5456,
    "cityName": "Sertânia",
    "stateId": 5249
  },
  {
    "cityId": 5457,
    "cityName": "Sertãozinho de Baixo",
    "stateId": 5249
  },
  {
    "cityId": 5458,
    "cityName": "Siriji",
    "stateId": 5249
  },
  {
    "cityId": 5459,
    "cityName": "Sirinhaém",
    "stateId": 5249
  },
  {
    "cityId": 5460,
    "cityName": "Sítio dos Nunes",
    "stateId": 5249
  },
  {
    "cityId": 5461,
    "cityName": "Solidão",
    "stateId": 5249
  },
  {
    "cityId": 5462,
    "cityName": "Surubim",
    "stateId": 5249
  },
  {
    "cityId": 5463,
    "cityName": "Tabira",
    "stateId": 5249
  },
  {
    "cityId": 5464,
    "cityName": "Tabocas",
    "stateId": 5249
  },
  {
    "cityId": 5465,
    "cityName": "Tacaimbó",
    "stateId": 5249
  },
  {
    "cityId": 5466,
    "cityName": "Tacaratu",
    "stateId": 5249
  },
  {
    "cityId": 5467,
    "cityName": "Tamandaré",
    "stateId": 5249
  },
  {
    "cityId": 5468,
    "cityName": "Tamboatá",
    "stateId": 5249
  },
  {
    "cityId": 5469,
    "cityName": "Tapiraim",
    "stateId": 5249
  },
  {
    "cityId": 5470,
    "cityName": "Taquaritinga do Norte",
    "stateId": 5249
  },
  {
    "cityId": 5471,
    "cityName": "Tara",
    "stateId": 5249
  },
  {
    "cityId": 5472,
    "cityName": "Tauapiranga",
    "stateId": 5249
  },
  {
    "cityId": 5473,
    "cityName": "Tejucupapo",
    "stateId": 5249
  },
  {
    "cityId": 5474,
    "cityName": "Terezinha",
    "stateId": 5249
  },
  {
    "cityId": 5475,
    "cityName": "Terra Nova",
    "stateId": 5249
  },
  {
    "cityId": 5476,
    "cityName": "Timbaúba",
    "stateId": 5249
  },
  {
    "cityId": 5477,
    "cityName": "Timorante",
    "stateId": 5249
  },
  {
    "cityId": 5478,
    "cityName": "Toritama",
    "stateId": 5249
  },
  {
    "cityId": 5479,
    "cityName": "Tracunhaém",
    "stateId": 5249
  },
  {
    "cityId": 5480,
    "cityName": "Trapiá",
    "stateId": 5249
  },
  {
    "cityId": 5481,
    "cityName": "Três Ladeiras",
    "stateId": 5249
  },
  {
    "cityId": 5482,
    "cityName": "Trindade",
    "stateId": 5249
  },
  {
    "cityId": 5483,
    "cityName": "Triunfo",
    "stateId": 5249
  },
  {
    "cityId": 5484,
    "cityName": "Tupanaci",
    "stateId": 5249
  },
  {
    "cityId": 5485,
    "cityName": "Tupanatinga",
    "stateId": 5249
  },
  {
    "cityId": 5486,
    "cityName": "Tupaóca",
    "stateId": 5249
  },
  {
    "cityId": 5487,
    "cityName": "Tuparetama",
    "stateId": 5249
  },
  {
    "cityId": 5488,
    "cityName": "Umãs",
    "stateId": 5249
  },
  {
    "cityId": 5489,
    "cityName": "Umburetama",
    "stateId": 5249
  },
  {
    "cityId": 5490,
    "cityName": "Upatininga",
    "stateId": 5249
  },
  {
    "cityId": 5491,
    "cityName": "Urimama",
    "stateId": 5249
  },
  {
    "cityId": 5492,
    "cityName": "Uruçu-Mirim",
    "stateId": 5249
  },
  {
    "cityId": 5493,
    "cityName": "Urucubá",
    "stateId": 5249
  },
  {
    "cityId": 5494,
    "cityName": "Vasques",
    "stateId": 5249
  },
  {
    "cityId": 5495,
    "cityName": "Veneza",
    "stateId": 5249
  },
  {
    "cityId": 5496,
    "cityName": "Venturosa",
    "stateId": 5249
  },
  {
    "cityId": 5497,
    "cityName": "Verdejante",
    "stateId": 5249
  },
  {
    "cityId": 5498,
    "cityName": "Vertente do Lério",
    "stateId": 5249
  },
  {
    "cityId": 5499,
    "cityName": "Vertentes",
    "stateId": 5249
  },
  {
    "cityId": 5500,
    "cityName": "Vicência",
    "stateId": 5249
  },
  {
    "cityId": 5501,
    "cityName": "Vila Nova",
    "stateId": 5249
  },
  {
    "cityId": 5502,
    "cityName": "Viração",
    "stateId": 5249
  },
  {
    "cityId": 5503,
    "cityName": "Vitória de Santo Antão",
    "stateId": 5249
  },
  {
    "cityId": 5504,
    "cityName": "Volta do Moxotó",
    "stateId": 5249
  },
  {
    "cityId": 5505,
    "cityName": "Xexéu",
    "stateId": 5249
  },
  {
    "cityId": 5506,
    "cityName": "Xucuru",
    "stateId": 5249
  },
  {
    "cityId": 5507,
    "cityName": "Zé Gomes",
    "stateId": 5249
  },
  {
    "cityId": 5508,
    "cityName": "Acauã",
    "stateId": 5250
  },
  {
    "cityId": 5509,
    "cityName": "Agricolândia",
    "stateId": 5250
  },
  {
    "cityId": 5510,
    "cityName": "Água Branca",
    "stateId": 5250
  },
  {
    "cityId": 5511,
    "cityName": "Alagoinha do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5512,
    "cityName": "Alegrete do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5513,
    "cityName": "Alto Longá",
    "stateId": 5250
  },
  {
    "cityId": 5514,
    "cityName": "Altos",
    "stateId": 5250
  },
  {
    "cityId": 5515,
    "cityName": "Alvorada do Gurguéia",
    "stateId": 5250
  },
  {
    "cityId": 5516,
    "cityName": "Amarante",
    "stateId": 5250
  },
  {
    "cityId": 5517,
    "cityName": "Angical do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5518,
    "cityName": "Anísio de Abreu",
    "stateId": 5250
  },
  {
    "cityId": 5519,
    "cityName": "Antônio Almeida",
    "stateId": 5250
  },
  {
    "cityId": 5520,
    "cityName": "Aroazes",
    "stateId": 5250
  },
  {
    "cityId": 5521,
    "cityName": "Arraial",
    "stateId": 5250
  },
  {
    "cityId": 5522,
    "cityName": "Assunção do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5523,
    "cityName": "Avelino Lopes",
    "stateId": 5250
  },
  {
    "cityId": 5524,
    "cityName": "Baixa Grande do Ribeiro",
    "stateId": 5250
  },
  {
    "cityId": 5525,
    "cityName": "Barra D'Alcântara",
    "stateId": 5250
  },
  {
    "cityId": 5526,
    "cityName": "Barras",
    "stateId": 5250
  },
  {
    "cityId": 5527,
    "cityName": "Barreiras do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5528,
    "cityName": "Barro Duro",
    "stateId": 5250
  },
  {
    "cityId": 5529,
    "cityName": "Batalha",
    "stateId": 5250
  },
  {
    "cityId": 5530,
    "cityName": "Bela Vista do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5531,
    "cityName": "Belém do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5532,
    "cityName": "Beneditinos",
    "stateId": 5250
  },
  {
    "cityId": 5533,
    "cityName": "Bertolínia",
    "stateId": 5250
  },
  {
    "cityId": 5534,
    "cityName": "Betânia do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5535,
    "cityName": "Boa Hora",
    "stateId": 5250
  },
  {
    "cityId": 5536,
    "cityName": "Bocaina",
    "stateId": 5250
  },
  {
    "cityId": 5537,
    "cityName": "Bom Jesus",
    "stateId": 5250
  },
  {
    "cityId": 5538,
    "cityName": "Bom Princípio do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5539,
    "cityName": "Bonfim do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5540,
    "cityName": "Boqueirão do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5541,
    "cityName": "Brasileira",
    "stateId": 5250
  },
  {
    "cityId": 5542,
    "cityName": "Brejo do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5543,
    "cityName": "Buriti dos Lopes",
    "stateId": 5250
  },
  {
    "cityId": 5544,
    "cityName": "Buriti dos Montes",
    "stateId": 5250
  },
  {
    "cityId": 5545,
    "cityName": "Cabeceiras do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5546,
    "cityName": "Cajazeiras do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5547,
    "cityName": "Cajueiro da Praia",
    "stateId": 5250
  },
  {
    "cityId": 5548,
    "cityName": "Caldeirão Grande do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5549,
    "cityName": "Campinas do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5550,
    "cityName": "Campo Alegre do Fidalgo",
    "stateId": 5250
  },
  {
    "cityId": 5551,
    "cityName": "Campo Grande do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5552,
    "cityName": "Campo Largo do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5553,
    "cityName": "Campo Maior",
    "stateId": 5250
  },
  {
    "cityId": 5554,
    "cityName": "Canavieira",
    "stateId": 5250
  },
  {
    "cityId": 5555,
    "cityName": "Canto do Buriti",
    "stateId": 5250
  },
  {
    "cityId": 5556,
    "cityName": "Capitão de Campos",
    "stateId": 5250
  },
  {
    "cityId": 5557,
    "cityName": "Capitão Gervásio Oliveira",
    "stateId": 5250
  },
  {
    "cityId": 5558,
    "cityName": "Caracol",
    "stateId": 5250
  },
  {
    "cityId": 5559,
    "cityName": "Caraúbas do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5560,
    "cityName": "Caridade do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5561,
    "cityName": "Castelo do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5562,
    "cityName": "Caxingó",
    "stateId": 5250
  },
  {
    "cityId": 5563,
    "cityName": "Cocal",
    "stateId": 5250
  },
  {
    "cityId": 5564,
    "cityName": "Cocal de Telha",
    "stateId": 5250
  },
  {
    "cityId": 5565,
    "cityName": "Cocal dos Alves",
    "stateId": 5250
  },
  {
    "cityId": 5566,
    "cityName": "Coivaras",
    "stateId": 5250
  },
  {
    "cityId": 5567,
    "cityName": "Colônia do Gurguéia",
    "stateId": 5250
  },
  {
    "cityId": 5568,
    "cityName": "Colônia do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5569,
    "cityName": "Conceição do Canindé",
    "stateId": 5250
  },
  {
    "cityId": 5570,
    "cityName": "Coronel José Dias",
    "stateId": 5250
  },
  {
    "cityId": 5571,
    "cityName": "Corrente",
    "stateId": 5250
  },
  {
    "cityId": 5572,
    "cityName": "Cristalândia do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5573,
    "cityName": "Cristino Castro",
    "stateId": 5250
  },
  {
    "cityId": 5574,
    "cityName": "Curimatá",
    "stateId": 5250
  },
  {
    "cityId": 5575,
    "cityName": "Currais",
    "stateId": 5250
  },
  {
    "cityId": 5576,
    "cityName": "Curral Novo do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5577,
    "cityName": "Curralinhos",
    "stateId": 5250
  },
  {
    "cityId": 5578,
    "cityName": "Demerval Lobão",
    "stateId": 5250
  },
  {
    "cityId": 5579,
    "cityName": "Dirceu Arcoverde",
    "stateId": 5250
  },
  {
    "cityId": 5580,
    "cityName": "Dom Expedito Lopes",
    "stateId": 5250
  },
  {
    "cityId": 5581,
    "cityName": "Dom Inocêncio",
    "stateId": 5250
  },
  {
    "cityId": 5582,
    "cityName": "Domingos Mourão",
    "stateId": 5250
  },
  {
    "cityId": 5583,
    "cityName": "Elesbão Veloso",
    "stateId": 5250
  },
  {
    "cityId": 5584,
    "cityName": "Eliseu Martins",
    "stateId": 5250
  },
  {
    "cityId": 5585,
    "cityName": "Esperantina",
    "stateId": 5250
  },
  {
    "cityId": 5586,
    "cityName": "Fartura do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5587,
    "cityName": "Flores do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5588,
    "cityName": "Floresta do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5589,
    "cityName": "Floriano",
    "stateId": 5250
  },
  {
    "cityId": 5590,
    "cityName": "Francinópolis",
    "stateId": 5250
  },
  {
    "cityId": 5591,
    "cityName": "Francisco Ayres",
    "stateId": 5250
  },
  {
    "cityId": 5592,
    "cityName": "Francisco Macedo",
    "stateId": 5250
  },
  {
    "cityId": 5593,
    "cityName": "Francisco Santos",
    "stateId": 5250
  },
  {
    "cityId": 5594,
    "cityName": "Fronteiras",
    "stateId": 5250
  },
  {
    "cityId": 5595,
    "cityName": "Geminiano",
    "stateId": 5250
  },
  {
    "cityId": 5596,
    "cityName": "Gilbués",
    "stateId": 5250
  },
  {
    "cityId": 5597,
    "cityName": "Guadalupe",
    "stateId": 5250
  },
  {
    "cityId": 5598,
    "cityName": "Guaribas",
    "stateId": 5250
  },
  {
    "cityId": 5599,
    "cityName": "Hugo Napoleão",
    "stateId": 5250
  },
  {
    "cityId": 5600,
    "cityName": "Ilha Grande",
    "stateId": 5250
  },
  {
    "cityId": 5601,
    "cityName": "Inhuma",
    "stateId": 5250
  },
  {
    "cityId": 5602,
    "cityName": "Ipiranga do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5603,
    "cityName": "Isaías Coelho",
    "stateId": 5250
  },
  {
    "cityId": 5604,
    "cityName": "Itainópolis",
    "stateId": 5250
  },
  {
    "cityId": 5605,
    "cityName": "Itaueira",
    "stateId": 5250
  },
  {
    "cityId": 5606,
    "cityName": "Jacobina do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5607,
    "cityName": "Jaicós",
    "stateId": 5250
  },
  {
    "cityId": 5608,
    "cityName": "Jardim do Mulato",
    "stateId": 5250
  },
  {
    "cityId": 5609,
    "cityName": "Jatobá do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5610,
    "cityName": "Jerumenha",
    "stateId": 5250
  },
  {
    "cityId": 5611,
    "cityName": "João Costa",
    "stateId": 5250
  },
  {
    "cityId": 5612,
    "cityName": "Joaquim Pires",
    "stateId": 5250
  },
  {
    "cityId": 5613,
    "cityName": "Joca Marques",
    "stateId": 5250
  },
  {
    "cityId": 5614,
    "cityName": "José de Freitas",
    "stateId": 5250
  },
  {
    "cityId": 5615,
    "cityName": "Juazeiro do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5616,
    "cityName": "Júlio Borges",
    "stateId": 5250
  },
  {
    "cityId": 5617,
    "cityName": "Jurema",
    "stateId": 5250
  },
  {
    "cityId": 5618,
    "cityName": "Lagoa Alegre",
    "stateId": 5250
  },
  {
    "cityId": 5619,
    "cityName": "Lagoa de São Francisco",
    "stateId": 5250
  },
  {
    "cityId": 5620,
    "cityName": "Lagoa do Barro do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5621,
    "cityName": "Lagoa do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5622,
    "cityName": "Lagoa do Sítio",
    "stateId": 5250
  },
  {
    "cityId": 5623,
    "cityName": "Lagoinha do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5624,
    "cityName": "Landri Sales",
    "stateId": 5250
  },
  {
    "cityId": 5625,
    "cityName": "Luís Correia",
    "stateId": 5250
  },
  {
    "cityId": 5626,
    "cityName": "Luzilândia",
    "stateId": 5250
  },
  {
    "cityId": 5627,
    "cityName": "Madeiro",
    "stateId": 5250
  },
  {
    "cityId": 5628,
    "cityName": "Manoel Emídio",
    "stateId": 5250
  },
  {
    "cityId": 5629,
    "cityName": "Marcolândia",
    "stateId": 5250
  },
  {
    "cityId": 5630,
    "cityName": "Marcos Parente",
    "stateId": 5250
  },
  {
    "cityId": 5631,
    "cityName": "Massapê do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5632,
    "cityName": "Matias Olímpio",
    "stateId": 5250
  },
  {
    "cityId": 5633,
    "cityName": "Miguel Alves",
    "stateId": 5250
  },
  {
    "cityId": 5634,
    "cityName": "Miguel Leão",
    "stateId": 5250
  },
  {
    "cityId": 5635,
    "cityName": "Milton Brandão",
    "stateId": 5250
  },
  {
    "cityId": 5636,
    "cityName": "Monsenhor Gil",
    "stateId": 5250
  },
  {
    "cityId": 5637,
    "cityName": "Monsenhor Hipólito",
    "stateId": 5250
  },
  {
    "cityId": 5638,
    "cityName": "Monte Alegre do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5639,
    "cityName": "Morro Cabeça no Tempo",
    "stateId": 5250
  },
  {
    "cityId": 5640,
    "cityName": "Morro do Chapéu do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5641,
    "cityName": "Murici dos Portelas",
    "stateId": 5250
  },
  {
    "cityId": 5642,
    "cityName": "Nazaré do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5643,
    "cityName": "Nossa Senhora de Nazaré",
    "stateId": 5250
  },
  {
    "cityId": 5644,
    "cityName": "Nossa Senhora dos Remédios",
    "stateId": 5250
  },
  {
    "cityId": 5645,
    "cityName": "Nova Santa Rita",
    "stateId": 5250
  },
  {
    "cityId": 5646,
    "cityName": "Novo Nilo",
    "stateId": 5250
  },
  {
    "cityId": 5647,
    "cityName": "Novo Oriente do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5648,
    "cityName": "Novo Santo Antônio",
    "stateId": 5250
  },
  {
    "cityId": 5649,
    "cityName": "Oeiras",
    "stateId": 5250
  },
  {
    "cityId": 5650,
    "cityName": "Olho D'Água do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5651,
    "cityName": "Padre Marcos",
    "stateId": 5250
  },
  {
    "cityId": 5652,
    "cityName": "Paes Landim",
    "stateId": 5250
  },
  {
    "cityId": 5653,
    "cityName": "Pajeú do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5654,
    "cityName": "Palmeira do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5655,
    "cityName": "Palmeirais",
    "stateId": 5250
  },
  {
    "cityId": 5656,
    "cityName": "Paquetá",
    "stateId": 5250
  },
  {
    "cityId": 5657,
    "cityName": "Parnaguá",
    "stateId": 5250
  },
  {
    "cityId": 5658,
    "cityName": "Parnaíba",
    "stateId": 5250
  },
  {
    "cityId": 5659,
    "cityName": "Passagem Franca do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5660,
    "cityName": "Patos do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5661,
    "cityName": "Paulistana",
    "stateId": 5250
  },
  {
    "cityId": 5662,
    "cityName": "Pavussu",
    "stateId": 5250
  },
  {
    "cityId": 5663,
    "cityName": "Pedro II",
    "stateId": 5250
  },
  {
    "cityId": 5664,
    "cityName": "Pedro Laurentino",
    "stateId": 5250
  },
  {
    "cityId": 5665,
    "cityName": "Picos",
    "stateId": 5250
  },
  {
    "cityId": 5666,
    "cityName": "Pimenteiras",
    "stateId": 5250
  },
  {
    "cityId": 5667,
    "cityName": "Pio IX",
    "stateId": 5250
  },
  {
    "cityId": 5668,
    "cityName": "Piracuruca",
    "stateId": 5250
  },
  {
    "cityId": 5669,
    "cityName": "Piripiri",
    "stateId": 5250
  },
  {
    "cityId": 5670,
    "cityName": "Porto",
    "stateId": 5250
  },
  {
    "cityId": 5671,
    "cityName": "Porto Alegre do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5672,
    "cityName": "Prata do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5673,
    "cityName": "Queimada Nova",
    "stateId": 5250
  },
  {
    "cityId": 5674,
    "cityName": "Redenção do Gurguéia",
    "stateId": 5250
  },
  {
    "cityId": 5675,
    "cityName": "Regeneração",
    "stateId": 5250
  },
  {
    "cityId": 5676,
    "cityName": "Riacho Frio",
    "stateId": 5250
  },
  {
    "cityId": 5677,
    "cityName": "Ribeira do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5678,
    "cityName": "Ribeiro Gonçalves",
    "stateId": 5250
  },
  {
    "cityId": 5679,
    "cityName": "Rio Grande do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5680,
    "cityName": "Santa Cruz do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5681,
    "cityName": "Santa Cruz dos Milagres",
    "stateId": 5250
  },
  {
    "cityId": 5682,
    "cityName": "Santa Filomena",
    "stateId": 5250
  },
  {
    "cityId": 5683,
    "cityName": "Santa Luz",
    "stateId": 5250
  },
  {
    "cityId": 5684,
    "cityName": "Santa Rosa do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5685,
    "cityName": "Santana do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5686,
    "cityName": "Santo Antônio de Lisboa",
    "stateId": 5250
  },
  {
    "cityId": 5687,
    "cityName": "Santo Antônio dos Milagres",
    "stateId": 5250
  },
  {
    "cityId": 5688,
    "cityName": "Santo Inácio do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5689,
    "cityName": "São Braz do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5690,
    "cityName": "São Félix do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5691,
    "cityName": "São Francisco de Assis do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5692,
    "cityName": "São Francisco do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5693,
    "cityName": "São Gonçalo do Gurguéia",
    "stateId": 5250
  },
  {
    "cityId": 5694,
    "cityName": "São Gonçalo do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5695,
    "cityName": "São João da Canabrava",
    "stateId": 5250
  },
  {
    "cityId": 5696,
    "cityName": "São João da Fronteira",
    "stateId": 5250
  },
  {
    "cityId": 5697,
    "cityName": "São João da Serra",
    "stateId": 5250
  },
  {
    "cityId": 5698,
    "cityName": "São João da Varjota",
    "stateId": 5250
  },
  {
    "cityId": 5699,
    "cityName": "São João do Arraial",
    "stateId": 5250
  },
  {
    "cityId": 5700,
    "cityName": "São João do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5701,
    "cityName": "São José do Divino",
    "stateId": 5250
  },
  {
    "cityId": 5702,
    "cityName": "São José do Peixe",
    "stateId": 5250
  },
  {
    "cityId": 5703,
    "cityName": "São José do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5704,
    "cityName": "São Julião",
    "stateId": 5250
  },
  {
    "cityId": 5705,
    "cityName": "São Lourenço do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5706,
    "cityName": "São Luís do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5707,
    "cityName": "São Miguel da Baixa Grande",
    "stateId": 5250
  },
  {
    "cityId": 5708,
    "cityName": "São Miguel do Fidalgo",
    "stateId": 5250
  },
  {
    "cityId": 5709,
    "cityName": "São Miguel do Tapuio",
    "stateId": 5250
  },
  {
    "cityId": 5710,
    "cityName": "São Pedro do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5711,
    "cityName": "São Raimundo Nonato",
    "stateId": 5250
  },
  {
    "cityId": 5712,
    "cityName": "Sebastião Barros",
    "stateId": 5250
  },
  {
    "cityId": 5713,
    "cityName": "Sebastião Leal",
    "stateId": 5250
  },
  {
    "cityId": 5714,
    "cityName": "Sigefredo Pacheco",
    "stateId": 5250
  },
  {
    "cityId": 5715,
    "cityName": "Simões",
    "stateId": 5250
  },
  {
    "cityId": 5716,
    "cityName": "Simplício Mendes",
    "stateId": 5250
  },
  {
    "cityId": 5717,
    "cityName": "Socorro do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5718,
    "cityName": "Sussuapara",
    "stateId": 5250
  },
  {
    "cityId": 5719,
    "cityName": "Tamboril do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5720,
    "cityName": "Tanque do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5721,
    "cityName": "Teresina",
    "stateId": 5250
  },
  {
    "cityId": 5722,
    "cityName": "União",
    "stateId": 5250
  },
  {
    "cityId": 5723,
    "cityName": "Uruçuí",
    "stateId": 5250
  },
  {
    "cityId": 5724,
    "cityName": "Valença do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5725,
    "cityName": "Várzea Branca",
    "stateId": 5250
  },
  {
    "cityId": 5726,
    "cityName": "Várzea Grande",
    "stateId": 5250
  },
  {
    "cityId": 5727,
    "cityName": "Vera Mendes",
    "stateId": 5250
  },
  {
    "cityId": 5728,
    "cityName": "Vila Nova do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 5729,
    "cityName": "Wall Ferraz",
    "stateId": 5250
  },
  {
    "cityId": 5730,
    "cityName": "Abapã",
    "stateId": 5251
  },
  {
    "cityId": 5731,
    "cityName": "Abatiá",
    "stateId": 5251
  },
  {
    "cityId": 5732,
    "cityName": "Acampamento das Minas",
    "stateId": 5251
  },
  {
    "cityId": 5733,
    "cityName": "Açungui",
    "stateId": 5251
  },
  {
    "cityId": 5734,
    "cityName": "Adhemar de Barros",
    "stateId": 5251
  },
  {
    "cityId": 5735,
    "cityName": "Adrianópolis",
    "stateId": 5251
  },
  {
    "cityId": 5736,
    "cityName": "Agostinho",
    "stateId": 5251
  },
  {
    "cityId": 5737,
    "cityName": "Água Azul",
    "stateId": 5251
  },
  {
    "cityId": 5738,
    "cityName": "Água Boa",
    "stateId": 5251
  },
  {
    "cityId": 5739,
    "cityName": "Água Branca",
    "stateId": 5251
  },
  {
    "cityId": 5741,
    "cityName": "Água Mineral",
    "stateId": 5251
  },
  {
    "cityId": 5742,
    "cityName": "Água Vermelha",
    "stateId": 5251
  },
  {
    "cityId": 5743,
    "cityName": "Agudos do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5744,
    "cityName": "Alecrim",
    "stateId": 5251
  },
  {
    "cityId": 5745,
    "cityName": "Alexandra",
    "stateId": 5251
  },
  {
    "cityId": 5746,
    "cityName": "Almirante Tamandaré",
    "stateId": 5251
  },
  {
    "cityId": 5747,
    "cityName": "Altamira do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 5748,
    "cityName": "Altaneira",
    "stateId": 5251
  },
  {
    "cityId": 5749,
    "cityName": "Alto Alegre",
    "stateId": 5251
  },
  {
    "cityId": 5750,
    "cityName": "Alto Alegre",
    "stateId": 5251
  },
  {
    "cityId": 5751,
    "cityName": "Alto Alegre do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 5752,
    "cityName": "Alto Amparo",
    "stateId": 5251
  },
  {
    "cityId": 5753,
    "cityName": "Alto do Amparo",
    "stateId": 5251
  },
  {
    "cityId": 5754,
    "cityName": "Alto Pará",
    "stateId": 5251
  },
  {
    "cityId": 5755,
    "cityName": "Alto Paraná",
    "stateId": 5251
  },
  {
    "cityId": 5756,
    "cityName": "Alto Piquiri",
    "stateId": 5251
  },
  {
    "cityId": 5757,
    "cityName": "Alto Porã",
    "stateId": 5251
  },
  {
    "cityId": 5758,
    "cityName": "Alto Sabiá",
    "stateId": 5251
  },
  {
    "cityId": 5759,
    "cityName": "Alto Santa Fé",
    "stateId": 5251
  },
  {
    "cityId": 5760,
    "cityName": "Alto São João",
    "stateId": 5251
  },
  {
    "cityId": 5761,
    "cityName": "Altônia",
    "stateId": 5251
  },
  {
    "cityId": 5762,
    "cityName": "Alvorada do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 5763,
    "cityName": "Alvorada do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5764,
    "cityName": "Amaporã",
    "stateId": 5251
  },
  {
    "cityId": 5765,
    "cityName": "Amorinha",
    "stateId": 5251
  },
  {
    "cityId": 5766,
    "cityName": "Ampére",
    "stateId": 5251
  },
  {
    "cityId": 5767,
    "cityName": "Anahy",
    "stateId": 5251
  },
  {
    "cityId": 5768,
    "cityName": "Andirá",
    "stateId": 5251
  },
  {
    "cityId": 5769,
    "cityName": "Andorinhas",
    "stateId": 5251
  },
  {
    "cityId": 5770,
    "cityName": "Angai",
    "stateId": 5251
  },
  {
    "cityId": 5771,
    "cityName": "Ângulo",
    "stateId": 5251
  },
  {
    "cityId": 5772,
    "cityName": "Antas",
    "stateId": 5251
  },
  {
    "cityId": 5773,
    "cityName": "Antas",
    "stateId": 5251
  },
  {
    "cityId": 5774,
    "cityName": "Antonina",
    "stateId": 5251
  },
  {
    "cityId": 5775,
    "cityName": "Antônio Brandão de Oliveira",
    "stateId": 5251
  },
  {
    "cityId": 5776,
    "cityName": "Antônio Olinto",
    "stateId": 5251
  },
  {
    "cityId": 5777,
    "cityName": "Anunciação",
    "stateId": 5251
  },
  {
    "cityId": 5778,
    "cityName": "Aparecida do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 5779,
    "cityName": "Apiaba",
    "stateId": 5251
  },
  {
    "cityId": 5780,
    "cityName": "Apucarana",
    "stateId": 5251
  },
  {
    "cityId": 5781,
    "cityName": "Aquidaban",
    "stateId": 5251
  },
  {
    "cityId": 5782,
    "cityName": "Aranha",
    "stateId": 5251
  },
  {
    "cityId": 5783,
    "cityName": "Arapongas",
    "stateId": 5251
  },
  {
    "cityId": 5784,
    "cityName": "Arapoti",
    "stateId": 5251
  },
  {
    "cityId": 5785,
    "cityName": "Arapuã",
    "stateId": 5251
  },
  {
    "cityId": 5786,
    "cityName": "Arapuan",
    "stateId": 5251
  },
  {
    "cityId": 5787,
    "cityName": "Ararapira",
    "stateId": 5251
  },
  {
    "cityId": 5788,
    "cityName": "Araruna",
    "stateId": 5251
  },
  {
    "cityId": 5789,
    "cityName": "Araucária",
    "stateId": 5251
  },
  {
    "cityId": 5790,
    "cityName": "Areia Branca dos Assis",
    "stateId": 5251
  },
  {
    "cityId": 5791,
    "cityName": "Areias",
    "stateId": 5251
  },
  {
    "cityId": 5792,
    "cityName": "Aricanduva",
    "stateId": 5251
  },
  {
    "cityId": 5793,
    "cityName": "Ariranha do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 5794,
    "cityName": "Aroeira",
    "stateId": 5251
  },
  {
    "cityId": 5795,
    "cityName": "Arquimedes",
    "stateId": 5251
  },
  {
    "cityId": 5796,
    "cityName": "Assaí",
    "stateId": 5251
  },
  {
    "cityId": 5797,
    "cityName": "Assis Chateaubriand",
    "stateId": 5251
  },
  {
    "cityId": 5798,
    "cityName": "Astorga",
    "stateId": 5251
  },
  {
    "cityId": 5799,
    "cityName": "Atalaia",
    "stateId": 5251
  },
  {
    "cityId": 5800,
    "cityName": "Aurora do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 5801,
    "cityName": "Bairro Cachoeira",
    "stateId": 5251
  },
  {
    "cityId": 5803,
    "cityName": "Bairro Limoeiro",
    "stateId": 5251
  },
  {
    "cityId": 5804,
    "cityName": "Balsa Nova",
    "stateId": 5251
  },
  {
    "cityId": 5805,
    "cityName": "Bandeirantes",
    "stateId": 5251
  },
  {
    "cityId": 5806,
    "cityName": "Bandeirantes d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 5807,
    "cityName": "Banhado",
    "stateId": 5251
  },
  {
    "cityId": 5808,
    "cityName": "Barão de Lucena",
    "stateId": 5251
  },
  {
    "cityId": 5809,
    "cityName": "Barbosa Ferraz",
    "stateId": 5251
  },
  {
    "cityId": 5810,
    "cityName": "Barra Bonita",
    "stateId": 5251
  },
  {
    "cityId": 5811,
    "cityName": "Barra Bonita",
    "stateId": 5251
  },
  {
    "cityId": 5812,
    "cityName": "Barra do Jacaré",
    "stateId": 5251
  },
  {
    "cityId": 5813,
    "cityName": "Barra Grande",
    "stateId": 5251
  },
  {
    "cityId": 5815,
    "cityName": "Barra Grande",
    "stateId": 5251
  },
  {
    "cityId": 5816,
    "cityName": "Barra Grande",
    "stateId": 5251
  },
  {
    "cityId": 5818,
    "cityName": "Barra Santa Salete",
    "stateId": 5251
  },
  {
    "cityId": 5819,
    "cityName": "Barracão",
    "stateId": 5251
  },
  {
    "cityId": 5821,
    "cityName": "Barreiro",
    "stateId": 5251
  },
  {
    "cityId": 5822,
    "cityName": "Barreiro",
    "stateId": 5251
  },
  {
    "cityId": 5823,
    "cityName": "Barreiro",
    "stateId": 5251
  },
  {
    "cityId": 5825,
    "cityName": "Barreiros",
    "stateId": 5251
  },
  {
    "cityId": 5826,
    "cityName": "Barrinha",
    "stateId": 5251
  },
  {
    "cityId": 5827,
    "cityName": "Barro Preto",
    "stateId": 5251
  },
  {
    "cityId": 5828,
    "cityName": "Barro Preto",
    "stateId": 5251
  },
  {
    "cityId": 5829,
    "cityName": "Barro Preto",
    "stateId": 5251
  },
  {
    "cityId": 5830,
    "cityName": "Bateias",
    "stateId": 5251
  },
  {
    "cityId": 5831,
    "cityName": "Baulândia",
    "stateId": 5251
  },
  {
    "cityId": 5832,
    "cityName": "Bela Vista",
    "stateId": 5251
  },
  {
    "cityId": 5833,
    "cityName": "Bela Vista",
    "stateId": 5251
  },
  {
    "cityId": 5834,
    "cityName": "Bela Vista da Caroba",
    "stateId": 5251
  },
  {
    "cityId": 5835,
    "cityName": "Bela Vista do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 5836,
    "cityName": "Bela Vista do Paraíso",
    "stateId": 5251
  },
  {
    "cityId": 5837,
    "cityName": "Bela Vista do Piquiri",
    "stateId": 5251
  },
  {
    "cityId": 5838,
    "cityName": "Bela Vista do Tapiracui",
    "stateId": 5251
  },
  {
    "cityId": 5839,
    "cityName": "Bentópolis",
    "stateId": 5251
  },
  {
    "cityId": 5840,
    "cityName": "Bernardelli",
    "stateId": 5251
  },
  {
    "cityId": 5841,
    "cityName": "Betaras",
    "stateId": 5251
  },
  {
    "cityId": 5842,
    "cityName": "Bituruna",
    "stateId": 5251
  },
  {
    "cityId": 5843,
    "cityName": "Boa Esperança",
    "stateId": 5251
  },
  {
    "cityId": 5845,
    "cityName": "Boa Esperança do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 5846,
    "cityName": "Boa Ventura de São Roque",
    "stateId": 5251
  },
  {
    "cityId": 5847,
    "cityName": "Boa Vista",
    "stateId": 5251
  },
  {
    "cityId": 5848,
    "cityName": "Boa Vista",
    "stateId": 5251
  },
  {
    "cityId": 5849,
    "cityName": "Boa Vista",
    "stateId": 5251
  },
  {
    "cityId": 5850,
    "cityName": "Boa Vista",
    "stateId": 5251
  },
  {
    "cityId": 5851,
    "cityName": "Boa Vista",
    "stateId": 5251
  },
  {
    "cityId": 5852,
    "cityName": "Boa Vista da Aparecida",
    "stateId": 5251
  },
  {
    "cityId": 5853,
    "cityName": "Bocaina",
    "stateId": 5251
  },
  {
    "cityId": 5854,
    "cityName": "Bocaiúva do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5855,
    "cityName": "Bom Jardim do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5856,
    "cityName": "Bom Jesus do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5857,
    "cityName": "Bom Progresso",
    "stateId": 5251
  },
  {
    "cityId": 5858,
    "cityName": "Bom Retiro",
    "stateId": 5251
  },
  {
    "cityId": 5859,
    "cityName": "Bom Retiro",
    "stateId": 5251
  },
  {
    "cityId": 5860,
    "cityName": "Bom Retiro",
    "stateId": 5251
  },
  {
    "cityId": 5861,
    "cityName": "Bom Retiro",
    "stateId": 5251
  },
  {
    "cityId": 5862,
    "cityName": "Bom Sucesso",
    "stateId": 5251
  },
  {
    "cityId": 5863,
    "cityName": "Bom Sucesso",
    "stateId": 5251
  },
  {
    "cityId": 5864,
    "cityName": "Bom Sucesso do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5865,
    "cityName": "Borda do Campo",
    "stateId": 5251
  },
  {
    "cityId": 5867,
    "cityName": "Borman",
    "stateId": 5251
  },
  {
    "cityId": 5868,
    "cityName": "Borrazópolis",
    "stateId": 5251
  },
  {
    "cityId": 5869,
    "cityName": "Botuquara",
    "stateId": 5251
  },
  {
    "cityId": 5870,
    "cityName": "Bourbonia",
    "stateId": 5251
  },
  {
    "cityId": 5871,
    "cityName": "Braganey",
    "stateId": 5251
  },
  {
    "cityId": 5872,
    "cityName": "Bragantina",
    "stateId": 5251
  },
  {
    "cityId": 5873,
    "cityName": "Brasilândia do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5874,
    "cityName": "Bugre",
    "stateId": 5251
  },
  {
    "cityId": 5875,
    "cityName": "Bulcão",
    "stateId": 5251
  },
  {
    "cityId": 5876,
    "cityName": "Cabrito",
    "stateId": 5251
  },
  {
    "cityId": 5877,
    "cityName": "Cacatu",
    "stateId": 5251
  },
  {
    "cityId": 5878,
    "cityName": "Cachoeira",
    "stateId": 5251
  },
  {
    "cityId": 5879,
    "cityName": "Cachoeira de Cima",
    "stateId": 5251
  },
  {
    "cityId": 5880,
    "cityName": "Cachoeira de São José",
    "stateId": 5251
  },
  {
    "cityId": 5881,
    "cityName": "Cachoeira do Espírito Santo",
    "stateId": 5251
  },
  {
    "cityId": 5882,
    "cityName": "Colônia Cachoeira",
    "stateId": 5251
  },
  {
    "cityId": 5883,
    "cityName": "Cachoeirinha",
    "stateId": 5251
  },
  {
    "cityId": 5884,
    "cityName": "Cadeadinho",
    "stateId": 5251
  },
  {
    "cityId": 5885,
    "cityName": "Caetano Mendes",
    "stateId": 5251
  },
  {
    "cityId": 5886,
    "cityName": "Cafeara",
    "stateId": 5251
  },
  {
    "cityId": 5887,
    "cityName": "Cafeeiros",
    "stateId": 5251
  },
  {
    "cityId": 5888,
    "cityName": "Cafelândia",
    "stateId": 5251
  },
  {
    "cityId": 5889,
    "cityName": "Cafezal do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5890,
    "cityName": "Caitá",
    "stateId": 5251
  },
  {
    "cityId": 5892,
    "cityName": "Califórnia",
    "stateId": 5251
  },
  {
    "cityId": 5893,
    "cityName": "Calógeras",
    "stateId": 5251
  },
  {
    "cityId": 5894,
    "cityName": "Cambará",
    "stateId": 5251
  },
  {
    "cityId": 5895,
    "cityName": "Cambé",
    "stateId": 5251
  },
  {
    "cityId": 5896,
    "cityName": "Cambiju",
    "stateId": 5251
  },
  {
    "cityId": 5897,
    "cityName": "Cambira",
    "stateId": 5251
  },
  {
    "cityId": 5898,
    "cityName": "Campestrinho",
    "stateId": 5251
  },
  {
    "cityId": 5899,
    "cityName": "Campina",
    "stateId": 5251
  },
  {
    "cityId": 5900,
    "cityName": "Campina",
    "stateId": 5251
  },
  {
    "cityId": 5901,
    "cityName": "Campina",
    "stateId": 5251
  },
  {
    "cityId": 5902,
    "cityName": "Campina da Lagoa",
    "stateId": 5251
  },
  {
    "cityId": 5903,
    "cityName": "Campina do Miranguava",
    "stateId": 5251
  },
  {
    "cityId": 5904,
    "cityName": "Campina do Simão",
    "stateId": 5251
  },
  {
    "cityId": 5905,
    "cityName": "Campina dos Furtados",
    "stateId": 5251
  },
  {
    "cityId": 5906,
    "cityName": "Campina Grande do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5907,
    "cityName": "Campinas",
    "stateId": 5251
  },
  {
    "cityId": 5908,
    "cityName": "Campo Bonito",
    "stateId": 5251
  },
  {
    "cityId": 5909,
    "cityName": "Campo do Meio",
    "stateId": 5251
  },
  {
    "cityId": 5910,
    "cityName": "Campo do Tenente",
    "stateId": 5251
  },
  {
    "cityId": 5911,
    "cityName": "Campo Largo",
    "stateId": 5251
  },
  {
    "cityId": 5912,
    "cityName": "Campo Largo da Roseira",
    "stateId": 5251
  },
  {
    "cityId": 5913,
    "cityName": "Campo Magro",
    "stateId": 5251
  },
  {
    "cityId": 5914,
    "cityName": "Campo Mourão",
    "stateId": 5251
  },
  {
    "cityId": 5915,
    "cityName": "Cândido de Abreu",
    "stateId": 5251
  },
  {
    "cityId": 5916,
    "cityName": "Candói",
    "stateId": 5251
  },
  {
    "cityId": 5917,
    "cityName": "Canela",
    "stateId": 5251
  },
  {
    "cityId": 5918,
    "cityName": "Cantagalo",
    "stateId": 5251
  },
  {
    "cityId": 5919,
    "cityName": "Canzianópolis",
    "stateId": 5251
  },
  {
    "cityId": 5920,
    "cityName": "Capanema",
    "stateId": 5251
  },
  {
    "cityId": 5921,
    "cityName": "Capão Alto",
    "stateId": 5251
  },
  {
    "cityId": 5922,
    "cityName": "Capão Alto",
    "stateId": 5251
  },
  {
    "cityId": 5923,
    "cityName": "Capão Bonito",
    "stateId": 5251
  },
  {
    "cityId": 5924,
    "cityName": "Capão da Lagoa",
    "stateId": 5251
  },
  {
    "cityId": 5925,
    "cityName": "Capão Grande",
    "stateId": 5251
  },
  {
    "cityId": 5926,
    "cityName": "Capão Rico",
    "stateId": 5251
  },
  {
    "cityId": 5927,
    "cityName": "Capitão Leônidas Marques",
    "stateId": 5251
  },
  {
    "cityId": 5928,
    "cityName": "Capivara",
    "stateId": 5251
  },
  {
    "cityId": 5929,
    "cityName": "Capoeirinha",
    "stateId": 5251
  },
  {
    "cityId": 5930,
    "cityName": "Cara Pintado",
    "stateId": 5251
  },
  {
    "cityId": 5931,
    "cityName": "Cará-Cará",
    "stateId": 5251
  },
  {
    "cityId": 5932,
    "cityName": "Carajá",
    "stateId": 5251
  },
  {
    "cityId": 5933,
    "cityName": "Carambeí",
    "stateId": 5251
  },
  {
    "cityId": 5934,
    "cityName": "Caramuru",
    "stateId": 5251
  },
  {
    "cityId": 5935,
    "cityName": "Caratuva",
    "stateId": 5251
  },
  {
    "cityId": 5936,
    "cityName": "Carazinho",
    "stateId": 5251
  },
  {
    "cityId": 5937,
    "cityName": "Carbonera",
    "stateId": 5251
  },
  {
    "cityId": 5938,
    "cityName": "Carlópolis",
    "stateId": 5251
  },
  {
    "cityId": 5939,
    "cityName": "Casa Nova",
    "stateId": 5251
  },
  {
    "cityId": 5940,
    "cityName": "Cascatinha",
    "stateId": 5251
  },
  {
    "cityId": 5941,
    "cityName": "Cascavel",
    "stateId": 5251
  },
  {
    "cityId": 5942,
    "cityName": "Castro",
    "stateId": 5251
  },
  {
    "cityId": 5943,
    "cityName": "Catanduvas",
    "stateId": 5251
  },
  {
    "cityId": 5944,
    "cityName": "Catanduvas",
    "stateId": 5251
  },
  {
    "cityId": 5945,
    "cityName": "Catanduvas do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5946,
    "cityName": "Catarinenses",
    "stateId": 5251
  },
  {
    "cityId": 5947,
    "cityName": "Caxambu",
    "stateId": 5251
  },
  {
    "cityId": 5948,
    "cityName": "Centenário",
    "stateId": 5251
  },
  {
    "cityId": 5949,
    "cityName": "Centenário do Sul",
    "stateId": 5251
  },
  {
    "cityId": 5950,
    "cityName": "Central Lupion",
    "stateId": 5251
  },
  {
    "cityId": 5951,
    "cityName": "Centralito",
    "stateId": 5251
  },
  {
    "cityId": 5952,
    "cityName": "Centro Novo",
    "stateId": 5251
  },
  {
    "cityId": 5953,
    "cityName": "Cerne",
    "stateId": 5251
  },
  {
    "cityId": 5954,
    "cityName": "Cerrado Grande",
    "stateId": 5251
  },
  {
    "cityId": 5955,
    "cityName": "Cerro Azul",
    "stateId": 5251
  },
  {
    "cityId": 5956,
    "cityName": "Cerro Velho",
    "stateId": 5251
  },
  {
    "cityId": 5957,
    "cityName": "Céu Azul",
    "stateId": 5251
  },
  {
    "cityId": 5958,
    "cityName": "Chopinzinho",
    "stateId": 5251
  },
  {
    "cityId": 5959,
    "cityName": "Cianorte",
    "stateId": 5251
  },
  {
    "cityId": 5960,
    "cityName": "Cidade Gaúcha",
    "stateId": 5251
  },
  {
    "cityId": 5961,
    "cityName": "Cintra Pimentel",
    "stateId": 5251
  },
  {
    "cityId": 5962,
    "cityName": "Clevelândia",
    "stateId": 5251
  },
  {
    "cityId": 5963,
    "cityName": "Coitinho",
    "stateId": 5251
  },
  {
    "cityId": 5964,
    "cityName": "Colombo",
    "stateId": 5251
  },
  {
    "cityId": 5965,
    "cityName": "Colônia Acioli",
    "stateId": 5251
  },
  {
    "cityId": 5966,
    "cityName": "Colônia Castelhanos",
    "stateId": 5251
  },
  {
    "cityId": 5967,
    "cityName": "Colônia Castrolânda",
    "stateId": 5251
  },
  {
    "cityId": 5968,
    "cityName": "Colônia Centenário",
    "stateId": 5251
  },
  {
    "cityId": 5969,
    "cityName": "Colônia Cristina",
    "stateId": 5251
  },
  {
    "cityId": 5970,
    "cityName": "Colônia Dom Carlos",
    "stateId": 5251
  },
  {
    "cityId": 5971,
    "cityName": "Colônia Esperança",
    "stateId": 5251
  },
  {
    "cityId": 5972,
    "cityName": "Colônia General Carneiro",
    "stateId": 5251
  },
  {
    "cityId": 5973,
    "cityName": "Colônia Iapó",
    "stateId": 5251
  },
  {
    "cityId": 5974,
    "cityName": "Colônia Melissa",
    "stateId": 5251
  },
  {
    "cityId": 5975,
    "cityName": "Colônia Murici",
    "stateId": 5251
  },
  {
    "cityId": 5976,
    "cityName": "Colônia Padre Paulo",
    "stateId": 5251
  },
  {
    "cityId": 5977,
    "cityName": "Colônia Pereira",
    "stateId": 5251
  },
  {
    "cityId": 5978,
    "cityName": "Colônia Santos Andrade",
    "stateId": 5251
  },
  {
    "cityId": 5979,
    "cityName": "Colônia São José",
    "stateId": 5251
  },
  {
    "cityId": 5980,
    "cityName": "Colônia Sapucaí",
    "stateId": 5251
  },
  {
    "cityId": 5981,
    "cityName": "Colônia Saúde",
    "stateId": 5251
  },
  {
    "cityId": 5982,
    "cityName": "Colônia Tapera",
    "stateId": 5251
  },
  {
    "cityId": 5983,
    "cityName": "Colorado",
    "stateId": 5251
  },
  {
    "cityId": 5984,
    "cityName": "Comur",
    "stateId": 5251
  },
  {
    "cityId": 5986,
    "cityName": "Conceição",
    "stateId": 5251
  },
  {
    "cityId": 5987,
    "cityName": "Conchas Velhas",
    "stateId": 5251
  },
  {
    "cityId": 5988,
    "cityName": "Conciolândia",
    "stateId": 5251
  },
  {
    "cityId": 5989,
    "cityName": "Congonhas",
    "stateId": 5251
  },
  {
    "cityId": 5990,
    "cityName": "Congonhinhas",
    "stateId": 5251
  },
  {
    "cityId": 5991,
    "cityName": "Conselheiro Mairinck",
    "stateId": 5251
  },
  {
    "cityId": 5992,
    "cityName": "Conselheiro Zacarias",
    "stateId": 5251
  },
  {
    "cityId": 5993,
    "cityName": "Contenda",
    "stateId": 5251
  },
  {
    "cityId": 5994,
    "cityName": "Copacabana do Norte",
    "stateId": 5251
  },
  {
    "cityId": 5995,
    "cityName": "Corbélia",
    "stateId": 5251
  },
  {
    "cityId": 5996,
    "cityName": "Cornélio Procópio",
    "stateId": 5251
  },
  {
    "cityId": 5997,
    "cityName": "Coronel Domingos Soares",
    "stateId": 5251
  },
  {
    "cityId": 5998,
    "cityName": "Coronel Firmino Martins",
    "stateId": 5251
  },
  {
    "cityId": 5999,
    "cityName": "Coronel Vivida",
    "stateId": 5251
  },
  {
    "cityId": 6000,
    "cityName": "Correia de Freitas",
    "stateId": 5251
  },
  {
    "cityId": 6001,
    "cityName": "Corumbataí do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6002,
    "cityName": "Corvo",
    "stateId": 5251
  },
  {
    "cityId": 6003,
    "cityName": "Costeira",
    "stateId": 5251
  },
  {
    "cityId": 6004,
    "cityName": "Covó",
    "stateId": 5251
  },
  {
    "cityId": 6005,
    "cityName": "Coxilha Rica",
    "stateId": 5251
  },
  {
    "cityId": 6006,
    "cityName": "Cristo Rei",
    "stateId": 5251
  },
  {
    "cityId": 6007,
    "cityName": "Cristo Rei",
    "stateId": 5251
  },
  {
    "cityId": 6008,
    "cityName": "Cruz Machado",
    "stateId": 5251
  },
  {
    "cityId": 6009,
    "cityName": "Cruzeiro do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6010,
    "cityName": "Cruzeiro do Norte",
    "stateId": 5251
  },
  {
    "cityId": 6011,
    "cityName": "Cruzeiro do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6012,
    "cityName": "Cruzeiro do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6013,
    "cityName": "Cruzmaltina",
    "stateId": 5251
  },
  {
    "cityId": 6014,
    "cityName": "Cunhaporanga",
    "stateId": 5251
  },
  {
    "cityId": 6015,
    "cityName": "Curitiba",
    "stateId": 5251
  },
  {
    "cityId": 6016,
    "cityName": "Curiúva",
    "stateId": 5251
  },
  {
    "cityId": 6017,
    "cityName": "Curucaca",
    "stateId": 5251
  },
  {
    "cityId": 6018,
    "cityName": "Deputado José Afonso",
    "stateId": 5251
  },
  {
    "cityId": 6019,
    "cityName": "Despique",
    "stateId": 5251
  },
  {
    "cityId": 6021,
    "cityName": "Dez de Maio",
    "stateId": 5251
  },
  {
    "cityId": 6023,
    "cityName": "Diamante d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6024,
    "cityName": "Diamante do Norte",
    "stateId": 5251
  },
  {
    "cityId": 6025,
    "cityName": "Diamante do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6026,
    "cityName": "Doce Grande",
    "stateId": 5251
  },
  {
    "cityId": 6027,
    "cityName": "Dois Irmãos",
    "stateId": 5251
  },
  {
    "cityId": 6028,
    "cityName": "Dois Irmãos",
    "stateId": 5251
  },
  {
    "cityId": 6029,
    "cityName": "Dois Marcos",
    "stateId": 5251
  },
  {
    "cityId": 6030,
    "cityName": "Dois Vizinhos",
    "stateId": 5251
  },
  {
    "cityId": 6031,
    "cityName": "Dom Rodrigo",
    "stateId": 5251
  },
  {
    "cityId": 6032,
    "cityName": "Dorizon",
    "stateId": 5251
  },
  {
    "cityId": 6033,
    "cityName": "Douradina",
    "stateId": 5251
  },
  {
    "cityId": 6034,
    "cityName": "Doutor Antônio Paranhos",
    "stateId": 5251
  },
  {
    "cityId": 6035,
    "cityName": "Doutor Camargo",
    "stateId": 5251
  },
  {
    "cityId": 6036,
    "cityName": "Doutor Ernesto",
    "stateId": 5251
  },
  {
    "cityId": 6037,
    "cityName": "Doutor Oliveira Castro",
    "stateId": 5251
  },
  {
    "cityId": 6038,
    "cityName": "Doutor Ulysses",
    "stateId": 5251
  },
  {
    "cityId": 6039,
    "cityName": "Eduardo Xavier da Silva",
    "stateId": 5251
  },
  {
    "cityId": 6040,
    "cityName": "Emboguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6041,
    "cityName": "Emboque",
    "stateId": 5251
  },
  {
    "cityId": 6042,
    "cityName": "Emboque",
    "stateId": 5251
  },
  {
    "cityId": 6043,
    "cityName": "Encantado d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6044,
    "cityName": "Encruzilhada",
    "stateId": 5251
  },
  {
    "cityId": 6045,
    "cityName": "Encruzilhada",
    "stateId": 5251
  },
  {
    "cityId": 6046,
    "cityName": "Enéas Marques",
    "stateId": 5251
  },
  {
    "cityId": 6047,
    "cityName": "Engenheiro Beltrão",
    "stateId": 5251
  },
  {
    "cityId": 6048,
    "cityName": "Entre Rios",
    "stateId": 5251
  },
  {
    "cityId": 6049,
    "cityName": "Entre Rios do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6050,
    "cityName": "Esperança do Norte",
    "stateId": 5251
  },
  {
    "cityId": 6051,
    "cityName": "Esperança Nova",
    "stateId": 5251
  },
  {
    "cityId": 6052,
    "cityName": "Espigão Alto do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6053,
    "cityName": "Espírito Santo",
    "stateId": 5251
  },
  {
    "cityId": 6054,
    "cityName": "Estação General Lúcio",
    "stateId": 5251
  },
  {
    "cityId": 6055,
    "cityName": "Estação Roça Nova",
    "stateId": 5251
  },
  {
    "cityId": 6056,
    "cityName": "Europa",
    "stateId": 5251
  },
  {
    "cityId": 6057,
    "cityName": "Euzébio de Oliveira",
    "stateId": 5251
  },
  {
    "cityId": 6058,
    "cityName": "Faisqueira",
    "stateId": 5251
  },
  {
    "cityId": 6059,
    "cityName": "Farol",
    "stateId": 5251
  },
  {
    "cityId": 6060,
    "cityName": "Faxina",
    "stateId": 5251
  },
  {
    "cityId": 6061,
    "cityName": "Faxinal",
    "stateId": 5251
  },
  {
    "cityId": 6062,
    "cityName": "Faxinal do Céu",
    "stateId": 5251
  },
  {
    "cityId": 6063,
    "cityName": "Faxinal dos Elias",
    "stateId": 5251
  },
  {
    "cityId": 6064,
    "cityName": "Faxinal Santa Cruz",
    "stateId": 5251
  },
  {
    "cityId": 6065,
    "cityName": "Fazenda do Brigadeiro",
    "stateId": 5251
  },
  {
    "cityId": 6066,
    "cityName": "Fazenda dos Barbosas",
    "stateId": 5251
  },
  {
    "cityId": 6067,
    "cityName": "Fazenda Jangada",
    "stateId": 5251
  },
  {
    "cityId": 6068,
    "cityName": "Fazenda Rio Grande",
    "stateId": 5251
  },
  {
    "cityId": 6069,
    "cityName": "Fazenda Salmo 23",
    "stateId": 5251
  },
  {
    "cityId": 6070,
    "cityName": "Fazendinha",
    "stateId": 5251
  },
  {
    "cityId": 6071,
    "cityName": "Felpudo",
    "stateId": 5251
  },
  {
    "cityId": 6072,
    "cityName": "Fênix",
    "stateId": 5251
  },
  {
    "cityId": 6073,
    "cityName": "Fernandes Pinheiro",
    "stateId": 5251
  },
  {
    "cityId": 6074,
    "cityName": "Fernão Dias",
    "stateId": 5251
  },
  {
    "cityId": 6075,
    "cityName": "Ferraria",
    "stateId": 5251
  },
  {
    "cityId": 6076,
    "cityName": "Ferreiras",
    "stateId": 5251
  },
  {
    "cityId": 6077,
    "cityName": "Figueira",
    "stateId": 5251
  },
  {
    "cityId": 6078,
    "cityName": "Figueira do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6079,
    "cityName": "Fiusas",
    "stateId": 5251
  },
  {
    "cityId": 6080,
    "cityName": "Flor da Serra",
    "stateId": 5251
  },
  {
    "cityId": 6081,
    "cityName": "Flor da Serra do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6082,
    "cityName": "Floraí",
    "stateId": 5251
  },
  {
    "cityId": 6083,
    "cityName": "Floresta",
    "stateId": 5251
  },
  {
    "cityId": 6084,
    "cityName": "Florestópolis",
    "stateId": 5251
  },
  {
    "cityId": 6085,
    "cityName": "Floriano",
    "stateId": 5251
  },
  {
    "cityId": 6086,
    "cityName": "Flórida",
    "stateId": 5251
  },
  {
    "cityId": 6087,
    "cityName": "Florópolis",
    "stateId": 5251
  },
  {
    "cityId": 6088,
    "cityName": "Fluviópolis",
    "stateId": 5251
  },
  {
    "cityId": 6089,
    "cityName": "Formigone",
    "stateId": 5251
  },
  {
    "cityId": 6090,
    "cityName": "Formosa do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6091,
    "cityName": "Foz do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6092,
    "cityName": "Foz do Jordão",
    "stateId": 5251
  },
  {
    "cityId": 6093,
    "cityName": "Francisco Alves",
    "stateId": 5251
  },
  {
    "cityId": 6094,
    "cityName": "Francisco Beltrão",
    "stateId": 5251
  },
  {
    "cityId": 6095,
    "cityName": "Francisco Frederico Teixeira Guimarães",
    "stateId": 5251
  },
  {
    "cityId": 6096,
    "cityName": "Frei Timóteo",
    "stateId": 5251
  },
  {
    "cityId": 6097,
    "cityName": "Fueros",
    "stateId": 5251
  },
  {
    "cityId": 6098,
    "cityName": "Fundão",
    "stateId": 5251
  },
  {
    "cityId": 6099,
    "cityName": "Gamadinho",
    "stateId": 5251
  },
  {
    "cityId": 6100,
    "cityName": "Gamela",
    "stateId": 5251
  },
  {
    "cityId": 6101,
    "cityName": "Gaúcha",
    "stateId": 5251
  },
  {
    "cityId": 6102,
    "cityName": "Gavião",
    "stateId": 5251
  },
  {
    "cityId": 6103,
    "cityName": "Gavião",
    "stateId": 5251
  },
  {
    "cityId": 6104,
    "cityName": "General Carneiro",
    "stateId": 5251
  },
  {
    "cityId": 6105,
    "cityName": "General Osório",
    "stateId": 5251
  },
  {
    "cityId": 6106,
    "cityName": "Geremia Lunardelli",
    "stateId": 5251
  },
  {
    "cityId": 6107,
    "cityName": "Godoy Moreira",
    "stateId": 5251
  },
  {
    "cityId": 6108,
    "cityName": "Goioerê",
    "stateId": 5251
  },
  {
    "cityId": 6109,
    "cityName": "Goioxim",
    "stateId": 5251
  },
  {
    "cityId": 6110,
    "cityName": "Góis",
    "stateId": 5251
  },
  {
    "cityId": 6111,
    "cityName": "Gonçalves Júnior",
    "stateId": 5251
  },
  {
    "cityId": 6112,
    "cityName": "Graciosa",
    "stateId": 5251
  },
  {
    "cityId": 6113,
    "cityName": "Grandes Rios",
    "stateId": 5251
  },
  {
    "cityId": 6114,
    "cityName": "Guaiporã",
    "stateId": 5251
  },
  {
    "cityId": 6115,
    "cityName": "Guaíra",
    "stateId": 5251
  },
  {
    "cityId": 6116,
    "cityName": "Guairaçá",
    "stateId": 5251
  },
  {
    "cityId": 6117,
    "cityName": "Guairaçá",
    "stateId": 5251
  },
  {
    "cityId": 6118,
    "cityName": "Guairaçá",
    "stateId": 5251
  },
  {
    "cityId": 6119,
    "cityName": "Guajuvira",
    "stateId": 5251
  },
  {
    "cityId": 6120,
    "cityName": "Guamiranga",
    "stateId": 5251
  },
  {
    "cityId": 6121,
    "cityName": "Guamirim",
    "stateId": 5251
  },
  {
    "cityId": 6122,
    "cityName": "Guapirama",
    "stateId": 5251
  },
  {
    "cityId": 6123,
    "cityName": "Guaporé",
    "stateId": 5251
  },
  {
    "cityId": 6124,
    "cityName": "Guaporema",
    "stateId": 5251
  },
  {
    "cityId": 6125,
    "cityName": "Guará",
    "stateId": 5251
  },
  {
    "cityId": 6126,
    "cityName": "Guaraci",
    "stateId": 5251
  },
  {
    "cityId": 6127,
    "cityName": "Guaragi",
    "stateId": 5251
  },
  {
    "cityId": 6128,
    "cityName": "Guaraituba",
    "stateId": 5251
  },
  {
    "cityId": 6129,
    "cityName": "Guarani",
    "stateId": 5251
  },
  {
    "cityId": 6130,
    "cityName": "Guaraniaçu",
    "stateId": 5251
  },
  {
    "cityId": 6131,
    "cityName": "Guarapuava",
    "stateId": 5251
  },
  {
    "cityId": 6132,
    "cityName": "Guarapuavinha",
    "stateId": 5251
  },
  {
    "cityId": 6133,
    "cityName": "Guaraqueçaba",
    "stateId": 5251
  },
  {
    "cityId": 6134,
    "cityName": "Guararema",
    "stateId": 5251
  },
  {
    "cityId": 6135,
    "cityName": "Guaratuba",
    "stateId": 5251
  },
  {
    "cityId": 6136,
    "cityName": "Guaraúna",
    "stateId": 5251
  },
  {
    "cityId": 6137,
    "cityName": "Guaravera",
    "stateId": 5251
  },
  {
    "cityId": 6138,
    "cityName": "Guardamoria",
    "stateId": 5251
  },
  {
    "cityId": 6139,
    "cityName": "Harmonia",
    "stateId": 5251
  },
  {
    "cityId": 6140,
    "cityName": "Herculândia",
    "stateId": 5251
  },
  {
    "cityId": 6141,
    "cityName": "Herval Grande",
    "stateId": 5251
  },
  {
    "cityId": 6142,
    "cityName": "Herveira",
    "stateId": 5251
  },
  {
    "cityId": 6143,
    "cityName": "Herveira",
    "stateId": 5251
  },
  {
    "cityId": 6144,
    "cityName": "Hidrelétrica Itaipu",
    "stateId": 5251
  },
  {
    "cityId": 6145,
    "cityName": "Honório Serpa",
    "stateId": 5251
  },
  {
    "cityId": 6146,
    "cityName": "Iarama",
    "stateId": 5251
  },
  {
    "cityId": 6147,
    "cityName": "Ibaiti",
    "stateId": 5251
  },
  {
    "cityId": 6148,
    "cityName": "Ibema",
    "stateId": 5251
  },
  {
    "cityId": 6149,
    "cityName": "Ibiaci",
    "stateId": 5251
  },
  {
    "cityId": 6150,
    "cityName": "Ibiporã",
    "stateId": 5251
  },
  {
    "cityId": 6151,
    "cityName": "Içara",
    "stateId": 5251
  },
  {
    "cityId": 6152,
    "cityName": "Icaraíma",
    "stateId": 5251
  },
  {
    "cityId": 6153,
    "cityName": "Icatu",
    "stateId": 5251
  },
  {
    "cityId": 6154,
    "cityName": "Igrejinha",
    "stateId": 5251
  },
  {
    "cityId": 6155,
    "cityName": "Iguaraçu",
    "stateId": 5251
  },
  {
    "cityId": 6156,
    "cityName": "Iguatemi",
    "stateId": 5251
  },
  {
    "cityId": 6157,
    "cityName": "Iguatu",
    "stateId": 5251
  },
  {
    "cityId": 6158,
    "cityName": "Iguiporã",
    "stateId": 5251
  },
  {
    "cityId": 6159,
    "cityName": "Ilha do Mel",
    "stateId": 5251
  },
  {
    "cityId": 6160,
    "cityName": "Ilha dos Valadares",
    "stateId": 5251
  },
  {
    "cityId": 6161,
    "cityName": "Imbaú",
    "stateId": 5251
  },
  {
    "cityId": 6162,
    "cityName": "Imbauzinho",
    "stateId": 5251
  },
  {
    "cityId": 6163,
    "cityName": "Imbituva",
    "stateId": 5251
  },
  {
    "cityId": 6164,
    "cityName": "Inácio Martins",
    "stateId": 5251
  },
  {
    "cityId": 6165,
    "cityName": "Inajá",
    "stateId": 5251
  },
  {
    "cityId": 6166,
    "cityName": "Independência",
    "stateId": 5251
  },
  {
    "cityId": 6167,
    "cityName": "Indianópolis",
    "stateId": 5251
  },
  {
    "cityId": 6168,
    "cityName": "Inspetor Carvalho",
    "stateId": 5251
  },
  {
    "cityId": 6169,
    "cityName": "Invernada",
    "stateId": 5251
  },
  {
    "cityId": 6170,
    "cityName": "Invernadinha",
    "stateId": 5251
  },
  {
    "cityId": 6171,
    "cityName": "Iolópolis",
    "stateId": 5251
  },
  {
    "cityId": 6172,
    "cityName": "Ipiranga",
    "stateId": 5251
  },
  {
    "cityId": 6173,
    "cityName": "Ipiranga",
    "stateId": 5251
  },
  {
    "cityId": 6174,
    "cityName": "Vila Ipiranga",
    "stateId": 5251
  },
  {
    "cityId": 6175,
    "cityName": "Iporã",
    "stateId": 5251
  },
  {
    "cityId": 6176,
    "cityName": "Iracema do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6177,
    "cityName": "Irapuan",
    "stateId": 5251
  },
  {
    "cityId": 6178,
    "cityName": "Irati",
    "stateId": 5251
  },
  {
    "cityId": 6179,
    "cityName": "Irerê",
    "stateId": 5251
  },
  {
    "cityId": 6180,
    "cityName": "Iretama",
    "stateId": 5251
  },
  {
    "cityId": 6181,
    "cityName": "Itaguajé",
    "stateId": 5251
  },
  {
    "cityId": 6182,
    "cityName": "Itaiacoca",
    "stateId": 5251
  },
  {
    "cityId": 6183,
    "cityName": "Itaipulândia",
    "stateId": 5251
  },
  {
    "cityId": 6184,
    "cityName": "Itambaracá",
    "stateId": 5251
  },
  {
    "cityId": 6185,
    "cityName": "Itambé",
    "stateId": 5251
  },
  {
    "cityId": 6186,
    "cityName": "Itambé",
    "stateId": 5251
  },
  {
    "cityId": 6187,
    "cityName": "Itapanhacanga",
    "stateId": 5251
  },
  {
    "cityId": 6188,
    "cityName": "Itapara",
    "stateId": 5251
  },
  {
    "cityId": 6189,
    "cityName": "Itapejara d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6190,
    "cityName": "Itaperuçu",
    "stateId": 5251
  },
  {
    "cityId": 6191,
    "cityName": "Itaqui",
    "stateId": 5251
  },
  {
    "cityId": 6192,
    "cityName": "Itaúna do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6193,
    "cityName": "Itinga",
    "stateId": 5251
  },
  {
    "cityId": 6194,
    "cityName": "Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6195,
    "cityName": "Ivailândia",
    "stateId": 5251
  },
  {
    "cityId": 6196,
    "cityName": "Ivaiporã",
    "stateId": 5251
  },
  {
    "cityId": 6197,
    "cityName": "Ivaitinga",
    "stateId": 5251
  },
  {
    "cityId": 6198,
    "cityName": "Ivaté",
    "stateId": 5251
  },
  {
    "cityId": 6199,
    "cityName": "Ivatuba",
    "stateId": 5251
  },
  {
    "cityId": 6200,
    "cityName": "Jaborandi",
    "stateId": 5251
  },
  {
    "cityId": 6201,
    "cityName": "Jaboti",
    "stateId": 5251
  },
  {
    "cityId": 6202,
    "cityName": "Jaboticabal",
    "stateId": 5251
  },
  {
    "cityId": 6203,
    "cityName": "Jaburu",
    "stateId": 5251
  },
  {
    "cityId": 6204,
    "cityName": "Jacaré",
    "stateId": 5251
  },
  {
    "cityId": 6205,
    "cityName": "Jacarezinho",
    "stateId": 5251
  },
  {
    "cityId": 6206,
    "cityName": "Jaciaba",
    "stateId": 5251
  },
  {
    "cityId": 6207,
    "cityName": "Jacutinga",
    "stateId": 5251
  },
  {
    "cityId": 6208,
    "cityName": "Jacutinga",
    "stateId": 5251
  },
  {
    "cityId": 6209,
    "cityName": "Jacutinga",
    "stateId": 5251
  },
  {
    "cityId": 6210,
    "cityName": "Jacutinga",
    "stateId": 5251
  },
  {
    "cityId": 6211,
    "cityName": "Jaguapitã",
    "stateId": 5251
  },
  {
    "cityId": 6212,
    "cityName": "Jaguariaíva",
    "stateId": 5251
  },
  {
    "cityId": 6213,
    "cityName": "Jandaia do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6214,
    "cityName": "Jangada",
    "stateId": 5251
  },
  {
    "cityId": 6215,
    "cityName": "Jangada do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6216,
    "cityName": "Janiópolis",
    "stateId": 5251
  },
  {
    "cityId": 6217,
    "cityName": "Japira",
    "stateId": 5251
  },
  {
    "cityId": 6218,
    "cityName": "Japurá",
    "stateId": 5251
  },
  {
    "cityId": 6219,
    "cityName": "Jaracatiá",
    "stateId": 5251
  },
  {
    "cityId": 6220,
    "cityName": "Jardim",
    "stateId": 5251
  },
  {
    "cityId": 6221,
    "cityName": "Jardim Alegre",
    "stateId": 5251
  },
  {
    "cityId": 6222,
    "cityName": "Jardim Olinda",
    "stateId": 5251
  },
  {
    "cityId": 6223,
    "cityName": "Jardim Paredão",
    "stateId": 5251
  },
  {
    "cityId": 6225,
    "cityName": "Jandinópolis",
    "stateId": 5251
  },
  {
    "cityId": 6226,
    "cityName": "Jataizinho",
    "stateId": 5251
  },
  {
    "cityId": 6227,
    "cityName": "Javacaé",
    "stateId": 5251
  },
  {
    "cityId": 6228,
    "cityName": "Jesuítas",
    "stateId": 5251
  },
  {
    "cityId": 6229,
    "cityName": "Joá",
    "stateId": 5251
  },
  {
    "cityId": 6230,
    "cityName": "Joaquim Távora",
    "stateId": 5251
  },
  {
    "cityId": 6231,
    "cityName": "Colônia Jordãozinho",
    "stateId": 5251
  },
  {
    "cityId": 6232,
    "cityName": "José Lacerda",
    "stateId": 5251
  },
  {
    "cityId": 6233,
    "cityName": "Juciara",
    "stateId": 5251
  },
  {
    "cityId": 6234,
    "cityName": "Jundiaí do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6235,
    "cityName": "Juranda",
    "stateId": 5251
  },
  {
    "cityId": 6236,
    "cityName": "Jussara",
    "stateId": 5251
  },
  {
    "cityId": 6237,
    "cityName": "Juvinópolis",
    "stateId": 5251
  },
  {
    "cityId": 6238,
    "cityName": "Kaloré",
    "stateId": 5251
  },
  {
    "cityId": 6239,
    "cityName": "km 30",
    "stateId": 5251
  },
  {
    "cityId": 6240,
    "cityName": "Lagoa",
    "stateId": 5251
  },
  {
    "cityId": 6241,
    "cityName": "Lagoa",
    "stateId": 5251
  },
  {
    "cityId": 6242,
    "cityName": "Lagoa Bonita",
    "stateId": 5251
  },
  {
    "cityId": 6243,
    "cityName": "Lagoa dos Ribas",
    "stateId": 5251
  },
  {
    "cityId": 6244,
    "cityName": "Lagoa Dourada",
    "stateId": 5251
  },
  {
    "cityId": 6245,
    "cityName": "Lagoa Seca",
    "stateId": 5251
  },
  {
    "cityId": 6246,
    "cityName": "Lagoa Verde",
    "stateId": 5251
  },
  {
    "cityId": 6247,
    "cityName": "Lagoinha",
    "stateId": 5251
  },
  {
    "cityId": 6248,
    "cityName": "Lajeado",
    "stateId": 5251
  },
  {
    "cityId": 6249,
    "cityName": "Lajeado",
    "stateId": 5251
  },
  {
    "cityId": 6250,
    "cityName": "Lajeado",
    "stateId": 5251
  },
  {
    "cityId": 6251,
    "cityName": "Lajeado Bonito",
    "stateId": 5251
  },
  {
    "cityId": 6252,
    "cityName": "Lajeado Grande",
    "stateId": 5251
  },
  {
    "cityId": 6253,
    "cityName": "Lambari",
    "stateId": 5251
  },
  {
    "cityId": 6254,
    "cityName": "Lapa",
    "stateId": 5251
  },
  {
    "cityId": 6255,
    "cityName": "Laranja Azeda",
    "stateId": 5251
  },
  {
    "cityId": 6256,
    "cityName": "Laranjal",
    "stateId": 5251
  },
  {
    "cityId": 6257,
    "cityName": "Laranjeiras do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6258,
    "cityName": "Lavra",
    "stateId": 5251
  },
  {
    "cityId": 6259,
    "cityName": "Lavrinha",
    "stateId": 5251
  },
  {
    "cityId": 6260,
    "cityName": "Lavrinha",
    "stateId": 5251
  },
  {
    "cityId": 6261,
    "cityName": "Leópolis",
    "stateId": 5251
  },
  {
    "cityId": 6262,
    "cityName": "Lerroville",
    "stateId": 5251
  },
  {
    "cityId": 6263,
    "cityName": "Lidianópolis",
    "stateId": 5251
  },
  {
    "cityId": 6264,
    "cityName": "Lindoeste",
    "stateId": 5251
  },
  {
    "cityId": 6265,
    "cityName": "Linha Giacomini",
    "stateId": 5251
  },
  {
    "cityId": 6266,
    "cityName": "Loanda",
    "stateId": 5251
  },
  {
    "cityId": 6267,
    "cityName": "Lobato",
    "stateId": 5251
  },
  {
    "cityId": 6268,
    "cityName": "Londrina",
    "stateId": 5251
  },
  {
    "cityId": 6269,
    "cityName": "Lopei",
    "stateId": 5251
  },
  {
    "cityId": 6270,
    "cityName": "Lovat",
    "stateId": 5251
  },
  {
    "cityId": 6271,
    "cityName": "Luar",
    "stateId": 5251
  },
  {
    "cityId": 6272,
    "cityName": "Luiziana",
    "stateId": 5251
  },
  {
    "cityId": 6273,
    "cityName": "Lunardelli",
    "stateId": 5251
  },
  {
    "cityId": 6274,
    "cityName": "Lupionópolis",
    "stateId": 5251
  },
  {
    "cityId": 6275,
    "cityName": "Macaco",
    "stateId": 5251
  },
  {
    "cityId": 6276,
    "cityName": "Macucos",
    "stateId": 5251
  },
  {
    "cityId": 6277,
    "cityName": "Mairá",
    "stateId": 5251
  },
  {
    "cityId": 6278,
    "cityName": "Maitá",
    "stateId": 5251
  },
  {
    "cityId": 6279,
    "cityName": "Mallet",
    "stateId": 5251
  },
  {
    "cityId": 6280,
    "cityName": "Malu",
    "stateId": 5251
  },
  {
    "cityId": 6281,
    "cityName": "Mamborê",
    "stateId": 5251
  },
  {
    "cityId": 6282,
    "cityName": "Mandaçaia",
    "stateId": 5251
  },
  {
    "cityId": 6283,
    "cityName": "Mandaguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6284,
    "cityName": "Mandaguari",
    "stateId": 5251
  },
  {
    "cityId": 6285,
    "cityName": "Mandiocaba",
    "stateId": 5251
  },
  {
    "cityId": 6286,
    "cityName": "Mandirituba",
    "stateId": 5251
  },
  {
    "cityId": 6287,
    "cityName": "Manfrinópolis",
    "stateId": 5251
  },
  {
    "cityId": 6288,
    "cityName": "Mangueirinha",
    "stateId": 5251
  },
  {
    "cityId": 6289,
    "cityName": "Manoel Ribas",
    "stateId": 5251
  },
  {
    "cityId": 6290,
    "cityName": "Marabá",
    "stateId": 5251
  },
  {
    "cityId": 6291,
    "cityName": "Maracanã",
    "stateId": 5251
  },
  {
    "cityId": 6292,
    "cityName": "Marajó",
    "stateId": 5251
  },
  {
    "cityId": 6293,
    "cityName": "Maravilha",
    "stateId": 5251
  },
  {
    "cityId": 6294,
    "cityName": "Maravilha",
    "stateId": 5251
  },
  {
    "cityId": 6295,
    "cityName": "Marcelino",
    "stateId": 5251
  },
  {
    "cityId": 6296,
    "cityName": "Marcionópolis",
    "stateId": 5251
  },
  {
    "cityId": 6297,
    "cityName": "Marechal Cândido Rondon",
    "stateId": 5251
  },
  {
    "cityId": 6298,
    "cityName": "Margarida",
    "stateId": 5251
  },
  {
    "cityId": 6299,
    "cityName": "Maria Helena",
    "stateId": 5251
  },
  {
    "cityId": 6300,
    "cityName": "Maria Luiza",
    "stateId": 5251
  },
  {
    "cityId": 6301,
    "cityName": "Marialva",
    "stateId": 5251
  },
  {
    "cityId": 6302,
    "cityName": "Mariental",
    "stateId": 5251
  },
  {
    "cityId": 6303,
    "cityName": "Marilândia do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6304,
    "cityName": "Marilena",
    "stateId": 5251
  },
  {
    "cityId": 6305,
    "cityName": "Marilu",
    "stateId": 5251
  },
  {
    "cityId": 6306,
    "cityName": "Mariluz",
    "stateId": 5251
  },
  {
    "cityId": 6307,
    "cityName": "Marimbondo",
    "stateId": 5251
  },
  {
    "cityId": 6308,
    "cityName": "Maringá",
    "stateId": 5251
  },
  {
    "cityId": 6309,
    "cityName": "Mariópolis",
    "stateId": 5251
  },
  {
    "cityId": 6310,
    "cityName": "Maripá",
    "stateId": 5251
  },
  {
    "cityId": 6311,
    "cityName": "Maristela",
    "stateId": 5251
  },
  {
    "cityId": 6312,
    "cityName": "Mariza",
    "stateId": 5251
  },
  {
    "cityId": 6313,
    "cityName": "Marmelândia",
    "stateId": 5251
  },
  {
    "cityId": 6314,
    "cityName": "Marmeleiro",
    "stateId": 5251
  },
  {
    "cityId": 6315,
    "cityName": "Marquês de Abrantes",
    "stateId": 5251
  },
  {
    "cityId": 6316,
    "cityName": "Marquinho",
    "stateId": 5251
  },
  {
    "cityId": 6317,
    "cityName": "Marrecas",
    "stateId": 5251
  },
  {
    "cityId": 6318,
    "cityName": "Martins",
    "stateId": 5251
  },
  {
    "cityId": 6319,
    "cityName": "Marumbi",
    "stateId": 5251
  },
  {
    "cityId": 6320,
    "cityName": "Matelândia",
    "stateId": 5251
  },
  {
    "cityId": 6321,
    "cityName": "Matinhos",
    "stateId": 5251
  },
  {
    "cityId": 6322,
    "cityName": "Matinhos",
    "stateId": 5251
  },
  {
    "cityId": 6323,
    "cityName": "Mato Queimado",
    "stateId": 5251
  },
  {
    "cityId": 6324,
    "cityName": "Mato Rico",
    "stateId": 5251
  },
  {
    "cityId": 6325,
    "cityName": "Mauá da Serra",
    "stateId": 5251
  },
  {
    "cityId": 6326,
    "cityName": "Medianeira",
    "stateId": 5251
  },
  {
    "cityId": 6327,
    "cityName": "Meia-Lua",
    "stateId": 5251
  },
  {
    "cityId": 6328,
    "cityName": "Memória",
    "stateId": 5251
  },
  {
    "cityId": 6329,
    "cityName": "Mendeslândia",
    "stateId": 5251
  },
  {
    "cityId": 6330,
    "cityName": "Mercedes",
    "stateId": 5251
  },
  {
    "cityId": 6331,
    "cityName": "Mirador",
    "stateId": 5251
  },
  {
    "cityId": 6332,
    "cityName": "Miranda",
    "stateId": 5251
  },
  {
    "cityId": 6333,
    "cityName": "Mirante do Piquiri",
    "stateId": 5251
  },
  {
    "cityId": 6334,
    "cityName": "Miraselva",
    "stateId": 5251
  },
  {
    "cityId": 6335,
    "cityName": "Missal",
    "stateId": 5251
  },
  {
    "cityId": 6336,
    "cityName": "Monjolinho",
    "stateId": 5251
  },
  {
    "cityId": 6337,
    "cityName": "Monte Real",
    "stateId": 5251
  },
  {
    "cityId": 6338,
    "cityName": "Moreira Sales",
    "stateId": 5251
  },
  {
    "cityId": 6339,
    "cityName": "Morretes",
    "stateId": 5251
  },
  {
    "cityId": 6340,
    "cityName": "Morro Alto",
    "stateId": 5251
  },
  {
    "cityId": 6341,
    "cityName": "Morro Inglês",
    "stateId": 5251
  },
  {
    "cityId": 6342,
    "cityName": "Munhoz de Melo",
    "stateId": 5251
  },
  {
    "cityId": 6343,
    "cityName": "Natingui",
    "stateId": 5251
  },
  {
    "cityId": 6344,
    "cityName": "Nilza",
    "stateId": 5251
  },
  {
    "cityId": 6345,
    "cityName": "Nordestina",
    "stateId": 5251
  },
  {
    "cityId": 6346,
    "cityName": "Nossa Senhora Aparecida",
    "stateId": 5251
  },
  {
    "cityId": 6347,
    "cityName": "Nossa Senhora da Aparecida",
    "stateId": 5251
  },
  {
    "cityId": 6348,
    "cityName": "Nossa Senhora da Candelária",
    "stateId": 5251
  },
  {
    "cityId": 6349,
    "cityName": "Nossa Senhora das Graças",
    "stateId": 5251
  },
  {
    "cityId": 6350,
    "cityName": "Nossa Senhora de Lourdes",
    "stateId": 5251
  },
  {
    "cityId": 6351,
    "cityName": "Nossa Senhora do Carmo",
    "stateId": 5251
  },
  {
    "cityId": 6352,
    "cityName": "Nova Aliança do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6353,
    "cityName": "Nova Altamira",
    "stateId": 5251
  },
  {
    "cityId": 6354,
    "cityName": "Nova América da Colina",
    "stateId": 5251
  },
  {
    "cityId": 6355,
    "cityName": "Nova Amoreira",
    "stateId": 5251
  },
  {
    "cityId": 6356,
    "cityName": "Nova Aurora",
    "stateId": 5251
  },
  {
    "cityId": 6357,
    "cityName": "Nova Bilac",
    "stateId": 5251
  },
  {
    "cityId": 6358,
    "cityName": "Nova Brasília",
    "stateId": 5251
  },
  {
    "cityId": 6359,
    "cityName": "Nova Brasília",
    "stateId": 5251
  },
  {
    "cityId": 6360,
    "cityName": "Nova Brasília do Itararé",
    "stateId": 5251
  },
  {
    "cityId": 6361,
    "cityName": "Nova Cantu",
    "stateId": 5251
  },
  {
    "cityId": 6362,
    "cityName": "Nova Concórdia",
    "stateId": 5251
  },
  {
    "cityId": 6363,
    "cityName": "Concórdia do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6364,
    "cityName": "Nova Esperança",
    "stateId": 5251
  },
  {
    "cityId": 6365,
    "cityName": "Nova Esperança do Sudoeste",
    "stateId": 5251
  },
  {
    "cityId": 6366,
    "cityName": "Nova Fátima",
    "stateId": 5251
  },
  {
    "cityId": 6367,
    "cityName": "Nova Laranjeiras",
    "stateId": 5251
  },
  {
    "cityId": 6368,
    "cityName": "Nova Londrina",
    "stateId": 5251
  },
  {
    "cityId": 6369,
    "cityName": "Nova Lourdes",
    "stateId": 5251
  },
  {
    "cityId": 6370,
    "cityName": "Nova Olímpia",
    "stateId": 5251
  },
  {
    "cityId": 6371,
    "cityName": "Nova Prata do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6372,
    "cityName": "Nova Riqueza",
    "stateId": 5251
  },
  {
    "cityId": 6373,
    "cityName": "Nova Santa Bárbara",
    "stateId": 5251
  },
  {
    "cityId": 6374,
    "cityName": "Nova Santa Rosa",
    "stateId": 5251
  },
  {
    "cityId": 6375,
    "cityName": "Nova Tebas",
    "stateId": 5251
  },
  {
    "cityId": 6376,
    "cityName": "Nova Tirol",
    "stateId": 5251
  },
  {
    "cityId": 6377,
    "cityName": "Nova Videira",
    "stateId": 5251
  },
  {
    "cityId": 6378,
    "cityName": "Novo Horizonte",
    "stateId": 5251
  },
  {
    "cityId": 6379,
    "cityName": "Novo Horizonte",
    "stateId": 5251
  },
  {
    "cityId": 6380,
    "cityName": "Novo Itacolomi",
    "stateId": 5251
  },
  {
    "cityId": 6381,
    "cityName": "Novo Jardim",
    "stateId": 5251
  },
  {
    "cityId": 6382,
    "cityName": "Novo Sarandi",
    "stateId": 5251
  },
  {
    "cityId": 6383,
    "cityName": "Novo Sobradinho",
    "stateId": 5251
  },
  {
    "cityId": 6384,
    "cityName": "Novo Três Passos",
    "stateId": 5251
  },
  {
    "cityId": 6385,
    "cityName": "Olaria",
    "stateId": 5251
  },
  {
    "cityId": 6386,
    "cityName": "Olaria",
    "stateId": 5251
  },
  {
    "cityId": 6387,
    "cityName": "Olho Agudo",
    "stateId": 5251
  },
  {
    "cityId": 6388,
    "cityName": "Olho d'Água",
    "stateId": 5251
  },
  {
    "cityId": 6389,
    "cityName": "Oroité",
    "stateId": 5251
  },
  {
    "cityId": 6390,
    "cityName": "Ortigueira",
    "stateId": 5251
  },
  {
    "cityId": 6391,
    "cityName": "Ourilândia",
    "stateId": 5251
  },
  {
    "cityId": 6392,
    "cityName": "Ourizona",
    "stateId": 5251
  },
  {
    "cityId": 6393,
    "cityName": "Ouro Verde do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6394,
    "cityName": "Ouro Verde do Piquiri",
    "stateId": 5251
  },
  {
    "cityId": 6395,
    "cityName": "Padre Ponciano",
    "stateId": 5251
  },
  {
    "cityId": 6396,
    "cityName": "Paiçandu",
    "stateId": 5251
  },
  {
    "cityId": 6397,
    "cityName": "Paiol de Baixo",
    "stateId": 5251
  },
  {
    "cityId": 6398,
    "cityName": "Paiol Queimado",
    "stateId": 5251
  },
  {
    "cityId": 6400,
    "cityName": "Paiquerê",
    "stateId": 5251
  },
  {
    "cityId": 6401,
    "cityName": "Palmas",
    "stateId": 5251
  },
  {
    "cityId": 6402,
    "cityName": "Palmeira",
    "stateId": 5251
  },
  {
    "cityId": 6403,
    "cityName": "Palmeirinha",
    "stateId": 5251
  },
  {
    "cityId": 6404,
    "cityName": "Palmeirinha",
    "stateId": 5251
  },
  {
    "cityId": 6405,
    "cityName": "Palmira",
    "stateId": 5251
  },
  {
    "cityId": 6406,
    "cityName": "Palmital",
    "stateId": 5251
  },
  {
    "cityId": 6407,
    "cityName": "Palmital",
    "stateId": 5251
  },
  {
    "cityId": 6408,
    "cityName": "Palmital de São Silvestre",
    "stateId": 5251
  },
  {
    "cityId": 6409,
    "cityName": "Palmitópolis",
    "stateId": 5251
  },
  {
    "cityId": 6410,
    "cityName": "Palotina",
    "stateId": 5251
  },
  {
    "cityId": 6411,
    "cityName": "Panema",
    "stateId": 5251
  },
  {
    "cityId": 6412,
    "cityName": "Pangaré",
    "stateId": 5251
  },
  {
    "cityId": 6413,
    "cityName": "Papagaios Novos",
    "stateId": 5251
  },
  {
    "cityId": 6414,
    "cityName": "Paraíso do Norte",
    "stateId": 5251
  },
  {
    "cityId": 6415,
    "cityName": "Paraná d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6416,
    "cityName": "Paranacity",
    "stateId": 5251
  },
  {
    "cityId": 6417,
    "cityName": "Paranagi",
    "stateId": 5251
  },
  {
    "cityId": 6418,
    "cityName": "Paranaguá",
    "stateId": 5251
  },
  {
    "cityId": 6419,
    "cityName": "Paranapoema",
    "stateId": 5251
  },
  {
    "cityId": 6420,
    "cityName": "Paranavaí",
    "stateId": 5251
  },
  {
    "cityId": 6421,
    "cityName": "Passa Una",
    "stateId": 5251
  },
  {
    "cityId": 6422,
    "cityName": "Passo da Ilha",
    "stateId": 5251
  },
  {
    "cityId": 6423,
    "cityName": "Passo dos Pupos",
    "stateId": 5251
  },
  {
    "cityId": 6424,
    "cityName": "Passo Fundo",
    "stateId": 5251
  },
  {
    "cityId": 6425,
    "cityName": "Passo Liso",
    "stateId": 5251
  },
  {
    "cityId": 6426,
    "cityName": "Pato Bragado",
    "stateId": 5251
  },
  {
    "cityId": 6427,
    "cityName": "Pato Branco",
    "stateId": 5251
  },
  {
    "cityId": 6428,
    "cityName": "Patos Velhos",
    "stateId": 5251
  },
  {
    "cityId": 6429,
    "cityName": "Pau d'Alho do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6430,
    "cityName": "Paula Freitas",
    "stateId": 5251
  },
  {
    "cityId": 6431,
    "cityName": "Paulistânia",
    "stateId": 5251
  },
  {
    "cityId": 6432,
    "cityName": "Paulo Frontin",
    "stateId": 5251
  },
  {
    "cityId": 6433,
    "cityName": "Peabiru",
    "stateId": 5251
  },
  {
    "cityId": 6434,
    "cityName": "Pedra Branca do Araraquara",
    "stateId": 5251
  },
  {
    "cityId": 6435,
    "cityName": "Pedras",
    "stateId": 5251
  },
  {
    "cityId": 6436,
    "cityName": "Pedro Lustosa",
    "stateId": 5251
  },
  {
    "cityId": 6437,
    "cityName": "Pelado",
    "stateId": 5251
  },
  {
    "cityId": 6438,
    "cityName": "Perobal",
    "stateId": 5251
  },
  {
    "cityId": 6439,
    "cityName": "Pérola",
    "stateId": 5251
  },
  {
    "cityId": 6440,
    "cityName": "Pérola d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6441,
    "cityName": "Pérola Independente",
    "stateId": 5251
  },
  {
    "cityId": 6442,
    "cityName": "Piassuguera",
    "stateId": 5251
  },
  {
    "cityId": 6443,
    "cityName": "Piên",
    "stateId": 5251
  },
  {
    "cityId": 6444,
    "cityName": "Pinaré",
    "stateId": 5251
  },
  {
    "cityId": 6445,
    "cityName": "Pinhais",
    "stateId": 5251
  },
  {
    "cityId": 6446,
    "cityName": "Pinhal de São Bento",
    "stateId": 5251
  },
  {
    "cityId": 6448,
    "cityName": "Pinhalão",
    "stateId": 5251
  },
  {
    "cityId": 6449,
    "cityName": "Pinhalzinho",
    "stateId": 5251
  },
  {
    "cityId": 6450,
    "cityName": "Pinhalzinho",
    "stateId": 5251
  },
  {
    "cityId": 6451,
    "cityName": "Pinhalzinho",
    "stateId": 5251
  },
  {
    "cityId": 6452,
    "cityName": "Pinhalzinho",
    "stateId": 5251
  },
  {
    "cityId": 6453,
    "cityName": "Pinhalzinho",
    "stateId": 5251
  },
  {
    "cityId": 6454,
    "cityName": "Pinhão",
    "stateId": 5251
  },
  {
    "cityId": 6455,
    "cityName": "Pinheiro",
    "stateId": 5251
  },
  {
    "cityId": 6456,
    "cityName": "Piquirivaí",
    "stateId": 5251
  },
  {
    "cityId": 6457,
    "cityName": "Piracema",
    "stateId": 5251
  },
  {
    "cityId": 6458,
    "cityName": "Piraí do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6459,
    "cityName": "Pirapó",
    "stateId": 5251
  },
  {
    "cityId": 6460,
    "cityName": "Piraquara",
    "stateId": 5251
  },
  {
    "cityId": 6461,
    "cityName": "Piriquitos",
    "stateId": 5251
  },
  {
    "cityId": 6462,
    "cityName": "Pitanga",
    "stateId": 5251
  },
  {
    "cityId": 6463,
    "cityName": "Pitangueiras",
    "stateId": 5251
  },
  {
    "cityId": 6464,
    "cityName": "Pitangui",
    "stateId": 5251
  },
  {
    "cityId": 6465,
    "cityName": "Planaltina do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 6466,
    "cityName": "Planalto",
    "stateId": 5251
  },
  {
    "cityId": 6467,
    "cityName": "Pocinho",
    "stateId": 5251
  },
  {
    "cityId": 6468,
    "cityName": "Pocinho",
    "stateId": 5251
  },
  {
    "cityId": 6469,
    "cityName": "Poema",
    "stateId": 5251
  },
  {
    "cityId": 6470,
    "cityName": "Ponta do Pasto",
    "stateId": 5251
  },
  {
    "cityId": 6471,
    "cityName": "Ponta Grossa",
    "stateId": 5251
  },
  {
    "cityId": 6472,
    "cityName": "Pontal do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 6473,
    "cityName": "Porecatu",
    "stateId": 5251
  },
  {
    "cityId": 6474,
    "cityName": "Portão",
    "stateId": 5251
  },
  {
    "cityId": 6475,
    "cityName": "Porteira Preta",
    "stateId": 5251
  },
  {
    "cityId": 6476,
    "cityName": "Porto Amazonas",
    "stateId": 5251
  },
  {
    "cityId": 6477,
    "cityName": "Porto Barreiro",
    "stateId": 5251
  },
  {
    "cityId": 6478,
    "cityName": "Porto Belo",
    "stateId": 5251
  },
  {
    "cityId": 6479,
    "cityName": "Porto Brasílio",
    "stateId": 5251
  },
  {
    "cityId": 6480,
    "cityName": "Porto Camargo",
    "stateId": 5251
  },
  {
    "cityId": 6481,
    "cityName": "Porto de Cima",
    "stateId": 5251
  },
  {
    "cityId": 6482,
    "cityName": "Porto Meira",
    "stateId": 5251
  },
  {
    "cityId": 6483,
    "cityName": "Porto Mendes",
    "stateId": 5251
  },
  {
    "cityId": 6484,
    "cityName": "Porto Rico",
    "stateId": 5251
  },
  {
    "cityId": 6485,
    "cityName": "Porto San Juan",
    "stateId": 5251
  },
  {
    "cityId": 6486,
    "cityName": "Porto Santana",
    "stateId": 5251
  },
  {
    "cityId": 6487,
    "cityName": "Porto São Carlos",
    "stateId": 5251
  },
  {
    "cityId": 6488,
    "cityName": "Porto São José",
    "stateId": 5251
  },
  {
    "cityId": 6489,
    "cityName": "Porto Vitória",
    "stateId": 5251
  },
  {
    "cityId": 6490,
    "cityName": "Prado Ferreira",
    "stateId": 5251
  },
  {
    "cityId": 6491,
    "cityName": "Pranchita",
    "stateId": 5251
  },
  {
    "cityId": 6492,
    "cityName": "Prata",
    "stateId": 5251
  },
  {
    "cityId": 6493,
    "cityName": "Prata Um",
    "stateId": 5251
  },
  {
    "cityId": 6494,
    "cityName": "Presidente Castelo Branco",
    "stateId": 5251
  },
  {
    "cityId": 6495,
    "cityName": "Presidente Kennedy",
    "stateId": 5251
  },
  {
    "cityId": 6496,
    "cityName": "Primeiro de Maio",
    "stateId": 5251
  },
  {
    "cityId": 6497,
    "cityName": "Progresso",
    "stateId": 5251
  },
  {
    "cityId": 6498,
    "cityName": "Prudentópolis",
    "stateId": 5251
  },
  {
    "cityId": 6499,
    "cityName": "Pulinópolis",
    "stateId": 5251
  },
  {
    "cityId": 6500,
    "cityName": "Quatiguá",
    "stateId": 5251
  },
  {
    "cityId": 6501,
    "cityName": "Quatro Barras",
    "stateId": 5251
  },
  {
    "cityId": 6502,
    "cityName": "Quatro Pontes",
    "stateId": 5251
  },
  {
    "cityId": 6503,
    "cityName": "Quebra Freio",
    "stateId": 5251
  },
  {
    "cityId": 6504,
    "cityName": "Quedas do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6505,
    "cityName": "Queimados",
    "stateId": 5251
  },
  {
    "cityId": 6506,
    "cityName": "Querência do Norte",
    "stateId": 5251
  },
  {
    "cityId": 6507,
    "cityName": "Quinta do Sol",
    "stateId": 5251
  },
  {
    "cityId": 6508,
    "cityName": "Quinzópolis",
    "stateId": 5251
  },
  {
    "cityId": 6509,
    "cityName": "Quitandinha",
    "stateId": 5251
  },
  {
    "cityId": 6510,
    "cityName": "Ramilândia",
    "stateId": 5251
  },
  {
    "cityId": 6511,
    "cityName": "Rancho Alegre",
    "stateId": 5251
  },
  {
    "cityId": 6512,
    "cityName": "Rancho Alegre d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6513,
    "cityName": "Realeza",
    "stateId": 5251
  },
  {
    "cityId": 6514,
    "cityName": "Rebouças",
    "stateId": 5251
  },
  {
    "cityId": 6515,
    "cityName": "Região dos Valos",
    "stateId": 5251
  },
  {
    "cityId": 6516,
    "cityName": "Reianópolis",
    "stateId": 5251
  },
  {
    "cityId": 6517,
    "cityName": "Renascença",
    "stateId": 5251
  },
  {
    "cityId": 6518,
    "cityName": "Reserva",
    "stateId": 5251
  },
  {
    "cityId": 6519,
    "cityName": "Reserva do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6520,
    "cityName": "Retiro",
    "stateId": 5251
  },
  {
    "cityId": 6521,
    "cityName": "Retiro Grande",
    "stateId": 5251
  },
  {
    "cityId": 6522,
    "cityName": "Ribeirão Bonito",
    "stateId": 5251
  },
  {
    "cityId": 6523,
    "cityName": "Ribeirão Claro",
    "stateId": 5251
  },
  {
    "cityId": 6524,
    "cityName": "Ribeirão do Pinhal",
    "stateId": 5251
  },
  {
    "cityId": 6525,
    "cityName": "Ribeirão do Pinheiro",
    "stateId": 5251
  },
  {
    "cityId": 6526,
    "cityName": "Rio Abaixo",
    "stateId": 5251
  },
  {
    "cityId": 6527,
    "cityName": "Rio Azul",
    "stateId": 5251
  },
  {
    "cityId": 6528,
    "cityName": "Rio Bom",
    "stateId": 5251
  },
  {
    "cityId": 6529,
    "cityName": "Rio Bonito",
    "stateId": 5251
  },
  {
    "cityId": 6530,
    "cityName": "Rio Bonito do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6531,
    "cityName": "Rio Branco do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6532,
    "cityName": "Rio Branco do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6533,
    "cityName": "Rio Claro do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6534,
    "cityName": "Rio da Prata",
    "stateId": 5251
  },
  {
    "cityId": 6535,
    "cityName": "Rio da Prata",
    "stateId": 5251
  },
  {
    "cityId": 6536,
    "cityName": "Rio das Antas",
    "stateId": 5251
  },
  {
    "cityId": 6537,
    "cityName": "Rio das Mortes",
    "stateId": 5251
  },
  {
    "cityId": 6538,
    "cityName": "Rio das Pedras",
    "stateId": 5251
  },
  {
    "cityId": 6539,
    "cityName": "Rio das Pedras",
    "stateId": 5251
  },
  {
    "cityId": 6540,
    "cityName": "Rio das Pombas",
    "stateId": 5251
  },
  {
    "cityId": 6541,
    "cityName": "Rio do Mato",
    "stateId": 5251
  },
  {
    "cityId": 6542,
    "cityName": "Rio do Salto",
    "stateId": 5251
  },
  {
    "cityId": 6543,
    "cityName": "Rio Negro",
    "stateId": 5251
  },
  {
    "cityId": 6544,
    "cityName": "Rio Novo",
    "stateId": 5251
  },
  {
    "cityId": 6545,
    "cityName": "Rio Novo",
    "stateId": 5251
  },
  {
    "cityId": 6546,
    "cityName": "Rio Pinheiro",
    "stateId": 5251
  },
  {
    "cityId": 6547,
    "cityName": "Rio Quatorze",
    "stateId": 5251
  },
  {
    "cityId": 6548,
    "cityName": "Rio Saltinho",
    "stateId": 5251
  },
  {
    "cityId": 6549,
    "cityName": "Rio Saudade",
    "stateId": 5251
  },
  {
    "cityId": 6550,
    "cityName": "Rio Verde",
    "stateId": 5251
  },
  {
    "cityId": 6551,
    "cityName": "Roberto Silveira",
    "stateId": 5251
  },
  {
    "cityId": 6552,
    "cityName": "Roça Velha",
    "stateId": 5251
  },
  {
    "cityId": 6553,
    "cityName": "Roça Velha",
    "stateId": 5251
  },
  {
    "cityId": 6554,
    "cityName": "Rolândia",
    "stateId": 5251
  },
  {
    "cityId": 6555,
    "cityName": "Romeópolis",
    "stateId": 5251
  },
  {
    "cityId": 6556,
    "cityName": "Roncador",
    "stateId": 5251
  },
  {
    "cityId": 6557,
    "cityName": "Rondinha",
    "stateId": 5251
  },
  {
    "cityId": 6558,
    "cityName": "Rondon",
    "stateId": 5251
  },
  {
    "cityId": 6559,
    "cityName": "Rosário do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6560,
    "cityName": "Sabáudia",
    "stateId": 5251
  },
  {
    "cityId": 6561,
    "cityName": "Sagrada Família",
    "stateId": 5251
  },
  {
    "cityId": 6562,
    "cityName": "Salgado Filho",
    "stateId": 5251
  },
  {
    "cityId": 6563,
    "cityName": "Salles de Oliveira",
    "stateId": 5251
  },
  {
    "cityId": 6564,
    "cityName": "Saltinho do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6565,
    "cityName": "Salto do Itararé",
    "stateId": 5251
  },
  {
    "cityId": 6566,
    "cityName": "Salto do Lontra",
    "stateId": 5251
  },
  {
    "cityId": 6567,
    "cityName": "Salto Portão",
    "stateId": 5251
  },
  {
    "cityId": 6568,
    "cityName": "Colônia Samambaia",
    "stateId": 5251
  },
  {
    "cityId": 6569,
    "cityName": "Santa Amélia",
    "stateId": 5251
  },
  {
    "cityId": 6570,
    "cityName": "Santa Cecília do Pavão",
    "stateId": 5251
  },
  {
    "cityId": 6571,
    "cityName": "Santa Clara",
    "stateId": 5251
  },
  {
    "cityId": 6572,
    "cityName": "Santa Cruz de Monte Castelo",
    "stateId": 5251
  },
  {
    "cityId": 6573,
    "cityName": "Santa Eliza",
    "stateId": 5251
  },
  {
    "cityId": 6574,
    "cityName": "Santa Esmeralda",
    "stateId": 5251
  },
  {
    "cityId": 6575,
    "cityName": "Santa Fé",
    "stateId": 5251
  },
  {
    "cityId": 6576,
    "cityName": "Santa Fé do Pirapó",
    "stateId": 5251
  },
  {
    "cityId": 6577,
    "cityName": "Santa Helena",
    "stateId": 5251
  },
  {
    "cityId": 6578,
    "cityName": "Santa Inês",
    "stateId": 5251
  },
  {
    "cityId": 6579,
    "cityName": "Santa Isabel do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6580,
    "cityName": "Santa Izabel do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6581,
    "cityName": "Santa Lúcia",
    "stateId": 5251
  },
  {
    "cityId": 6582,
    "cityName": "Santa Lurdes",
    "stateId": 5251
  },
  {
    "cityId": 6583,
    "cityName": "Santa Luzia da Alvorada",
    "stateId": 5251
  },
  {
    "cityId": 6584,
    "cityName": "Santa Margarida",
    "stateId": 5251
  },
  {
    "cityId": 6585,
    "cityName": "Santa Margarida",
    "stateId": 5251
  },
  {
    "cityId": 6586,
    "cityName": "Santa Maria",
    "stateId": 5251
  },
  {
    "cityId": 6587,
    "cityName": "Santa Maria do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6588,
    "cityName": "Santa Maria do Rio do Peixe",
    "stateId": 5251
  },
  {
    "cityId": 6589,
    "cityName": "Santa Mariana",
    "stateId": 5251
  },
  {
    "cityId": 6590,
    "cityName": "Santa Mônica",
    "stateId": 5251
  },
  {
    "cityId": 6591,
    "cityName": "Santa Quitéria",
    "stateId": 5251
  },
  {
    "cityId": 6592,
    "cityName": "Santa Quitéria",
    "stateId": 5251
  },
  {
    "cityId": 6593,
    "cityName": "Santa Rita",
    "stateId": 5251
  },
  {
    "cityId": 6594,
    "cityName": "Santa Rita",
    "stateId": 5251
  },
  {
    "cityId": 6595,
    "cityName": "Santa Rita do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6596,
    "cityName": "Santa Rosa",
    "stateId": 5251
  },
  {
    "cityId": 6597,
    "cityName": "Santa Tereza do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6598,
    "cityName": "Santa Terezinha de Itaipu",
    "stateId": 5251
  },
  {
    "cityId": 6599,
    "cityName": "Santa Zélia",
    "stateId": 5251
  },
  {
    "cityId": 6600,
    "cityName": "Santana",
    "stateId": 5251
  },
  {
    "cityId": 6601,
    "cityName": "Santana do Itararé",
    "stateId": 5251
  },
  {
    "cityId": 6602,
    "cityName": "Santo Antônio",
    "stateId": 5251
  },
  {
    "cityId": 6603,
    "cityName": "Santo Antônio",
    "stateId": 5251
  },
  {
    "cityId": 6604,
    "cityName": "Santo Antônio da Platina",
    "stateId": 5251
  },
  {
    "cityId": 6605,
    "cityName": "Santo Antônio do Caiuá",
    "stateId": 5251
  },
  {
    "cityId": 6606,
    "cityName": "Santo Antônio do Iratim",
    "stateId": 5251
  },
  {
    "cityId": 6607,
    "cityName": "Santo Antônio do Palmital",
    "stateId": 5251
  },
  {
    "cityId": 6608,
    "cityName": "Santo Antônio do Paraíso",
    "stateId": 5251
  },
  {
    "cityId": 6609,
    "cityName": "Santo Antônio do Sudoeste",
    "stateId": 5251
  },
  {
    "cityId": 6610,
    "cityName": "Santo Inácio",
    "stateId": 5251
  },
  {
    "cityId": 6611,
    "cityName": "Santo Rei",
    "stateId": 5251
  },
  {
    "cityId": 6613,
    "cityName": "São Braz",
    "stateId": 5251
  },
  {
    "cityId": 6614,
    "cityName": "São Braz",
    "stateId": 5251
  },
  {
    "cityId": 6615,
    "cityName": "São Camilo",
    "stateId": 5251
  },
  {
    "cityId": 6616,
    "cityName": "São Carlos do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6617,
    "cityName": "São Cirilo",
    "stateId": 5251
  },
  {
    "cityId": 6618,
    "cityName": "São Clemente",
    "stateId": 5251
  },
  {
    "cityId": 6619,
    "cityName": "São Cristovão",
    "stateId": 5251
  },
  {
    "cityId": 6620,
    "cityName": "São Cristovão",
    "stateId": 5251
  },
  {
    "cityId": 6621,
    "cityName": "São Domingos",
    "stateId": 5251
  },
  {
    "cityId": 6622,
    "cityName": "São Domingos",
    "stateId": 5251
  },
  {
    "cityId": 6623,
    "cityName": "São Domingos",
    "stateId": 5251
  },
  {
    "cityId": 6624,
    "cityName": "São Francisco",
    "stateId": 5251
  },
  {
    "cityId": 6625,
    "cityName": "São Francisco",
    "stateId": 5251
  },
  {
    "cityId": 6626,
    "cityName": "São Francisco",
    "stateId": 5251
  },
  {
    "cityId": 6627,
    "cityName": "São Francisco de Imbaú",
    "stateId": 5251
  },
  {
    "cityId": 6628,
    "cityName": "São Francisco de Sales",
    "stateId": 5251
  },
  {
    "cityId": 6629,
    "cityName": "São Gabriel",
    "stateId": 5251
  },
  {
    "cityId": 6630,
    "cityName": "São Gabriel",
    "stateId": 5251
  },
  {
    "cityId": 6631,
    "cityName": "São Gotardo",
    "stateId": 5251
  },
  {
    "cityId": 6632,
    "cityName": "São Jerônimo da Serra",
    "stateId": 5251
  },
  {
    "cityId": 6633,
    "cityName": "São João",
    "stateId": 5251
  },
  {
    "cityId": 6634,
    "cityName": "São João",
    "stateId": 5251
  },
  {
    "cityId": 6636,
    "cityName": "São João",
    "stateId": 5251
  },
  {
    "cityId": 6637,
    "cityName": "São João d'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6639,
    "cityName": "São João do Caiuá",
    "stateId": 5251
  },
  {
    "cityId": 6640,
    "cityName": "São João do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6641,
    "cityName": "São João do Pinhal",
    "stateId": 5251
  },
  {
    "cityId": 6642,
    "cityName": "São João do Triunfo",
    "stateId": 5251
  },
  {
    "cityId": 6643,
    "cityName": "São Joaquim",
    "stateId": 5251
  },
  {
    "cityId": 6644,
    "cityName": "São Joaquim",
    "stateId": 5251
  },
  {
    "cityId": 6645,
    "cityName": "São Joaquim do Pontal",
    "stateId": 5251
  },
  {
    "cityId": 6646,
    "cityName": "São Jorge D'Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6647,
    "cityName": "São Jorge do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6648,
    "cityName": "São Jorge do Patrocínio",
    "stateId": 5251
  },
  {
    "cityId": 6649,
    "cityName": "São José",
    "stateId": 5251
  },
  {
    "cityId": 6650,
    "cityName": "São José",
    "stateId": 5251
  },
  {
    "cityId": 6651,
    "cityName": "São José da Boa Vista",
    "stateId": 5251
  },
  {
    "cityId": 6652,
    "cityName": "São José das Palmeiras",
    "stateId": 5251
  },
  {
    "cityId": 6653,
    "cityName": "São José do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6654,
    "cityName": "São José do Itavo",
    "stateId": 5251
  },
  {
    "cityId": 6655,
    "cityName": "São José do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6656,
    "cityName": "São José dos Pinhais",
    "stateId": 5251
  },
  {
    "cityId": 6657,
    "cityName": "São Judas Tadeu",
    "stateId": 5251
  },
  {
    "cityId": 6658,
    "cityName": "São Leonardo",
    "stateId": 5251
  },
  {
    "cityId": 6659,
    "cityName": "São Lourenço",
    "stateId": 5251
  },
  {
    "cityId": 6660,
    "cityName": "São Lourenço",
    "stateId": 5251
  },
  {
    "cityId": 6662,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6663,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6664,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6665,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6666,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6667,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6668,
    "cityName": "São Luiz",
    "stateId": 5251
  },
  {
    "cityId": 6669,
    "cityName": "São Luiz do Purunã",
    "stateId": 5251
  },
  {
    "cityId": 6670,
    "cityName": "São Manoel do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 6671,
    "cityName": "São Marcos",
    "stateId": 5251
  },
  {
    "cityId": 6672,
    "cityName": "São Marcos",
    "stateId": 5251
  },
  {
    "cityId": 6673,
    "cityName": "São Martinho",
    "stateId": 5251
  },
  {
    "cityId": 6674,
    "cityName": "São Mateus do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6675,
    "cityName": "São Miguel",
    "stateId": 5251
  },
  {
    "cityId": 6676,
    "cityName": "São Miguel",
    "stateId": 5251
  },
  {
    "cityId": 6677,
    "cityName": "São Miguel",
    "stateId": 5251
  },
  {
    "cityId": 6678,
    "cityName": "São Miguel do Cambui",
    "stateId": 5251
  },
  {
    "cityId": 6679,
    "cityName": "São Miguel do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6680,
    "cityName": "São Paulo",
    "stateId": 5251
  },
  {
    "cityId": 6681,
    "cityName": "São Pedro",
    "stateId": 5251
  },
  {
    "cityId": 6682,
    "cityName": "São Pedro",
    "stateId": 5251
  },
  {
    "cityId": 6683,
    "cityName": "São Pedro",
    "stateId": 5251
  },
  {
    "cityId": 6684,
    "cityName": "São Pedro",
    "stateId": 5251
  },
  {
    "cityId": 6685,
    "cityName": "São Pedro",
    "stateId": 5251
  },
  {
    "cityId": 6686,
    "cityName": "São Pedro do Florido",
    "stateId": 5251
  },
  {
    "cityId": 6687,
    "cityName": "São Pedro do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6688,
    "cityName": "São Pedro do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6689,
    "cityName": "São Pedro do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 6690,
    "cityName": "São Pedro Lopei",
    "stateId": 5251
  },
  {
    "cityId": 6691,
    "cityName": "São Pio X",
    "stateId": 5251
  },
  {
    "cityId": 6692,
    "cityName": "São Roque",
    "stateId": 5251
  },
  {
    "cityId": 6693,
    "cityName": "São Roque",
    "stateId": 5251
  },
  {
    "cityId": 6694,
    "cityName": "São Roque",
    "stateId": 5251
  },
  {
    "cityId": 6695,
    "cityName": "São Roque do Pinhal",
    "stateId": 5251
  },
  {
    "cityId": 6696,
    "cityName": "São Salvador",
    "stateId": 5251
  },
  {
    "cityId": 6697,
    "cityName": "São Sebastião",
    "stateId": 5251
  },
  {
    "cityId": 6698,
    "cityName": "São Sebastião",
    "stateId": 5251
  },
  {
    "cityId": 6699,
    "cityName": "São Sebastião",
    "stateId": 5251
  },
  {
    "cityId": 6700,
    "cityName": "São Sebastião",
    "stateId": 5251
  },
  {
    "cityId": 6701,
    "cityName": "São Sebastião",
    "stateId": 5251
  },
  {
    "cityId": 6702,
    "cityName": "São Sebastião da Amoreira",
    "stateId": 5251
  },
  {
    "cityId": 6703,
    "cityName": "São Silvestre",
    "stateId": 5251
  },
  {
    "cityId": 6704,
    "cityName": "São Tomé",
    "stateId": 5251
  },
  {
    "cityId": 6705,
    "cityName": "São Valentim",
    "stateId": 5251
  },
  {
    "cityId": 6706,
    "cityName": "São Vicente",
    "stateId": 5251
  },
  {
    "cityId": 6707,
    "cityName": "Sapé",
    "stateId": 5251
  },
  {
    "cityId": 6708,
    "cityName": "Sapopema",
    "stateId": 5251
  },
  {
    "cityId": 6709,
    "cityName": "Sarandi",
    "stateId": 5251
  },
  {
    "cityId": 6710,
    "cityName": "Sarandi",
    "stateId": 5251
  },
  {
    "cityId": 6711,
    "cityName": "Saudade do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6712,
    "cityName": "Sede Alvorada",
    "stateId": 5251
  },
  {
    "cityId": 6713,
    "cityName": "Sede Chaparral",
    "stateId": 5251
  },
  {
    "cityId": 6714,
    "cityName": "Sede Nova Sant'Ana",
    "stateId": 5251
  },
  {
    "cityId": 6715,
    "cityName": "Sede Progresso",
    "stateId": 5251
  },
  {
    "cityId": 6716,
    "cityName": "Sede Progresso",
    "stateId": 5251
  },
  {
    "cityId": 6717,
    "cityName": "Selva",
    "stateId": 5251
  },
  {
    "cityId": 6718,
    "cityName": "Sengés",
    "stateId": 5251
  },
  {
    "cityId": 6719,
    "cityName": "Senhor Bom Jesus dos Gramados",
    "stateId": 5251
  },
  {
    "cityId": 6720,
    "cityName": "Serra dos Dourados",
    "stateId": 5251
  },
  {
    "cityId": 6721,
    "cityName": "Serra Negra",
    "stateId": 5251
  },
  {
    "cityId": 6722,
    "cityName": "Serranópolis do Iguaçu",
    "stateId": 5251
  },
  {
    "cityId": 6723,
    "cityName": "Serraria Klas",
    "stateId": 5251
  },
  {
    "cityId": 6724,
    "cityName": "Serrinha",
    "stateId": 5251
  },
  {
    "cityId": 6726,
    "cityName": "Serrinha",
    "stateId": 5251
  },
  {
    "cityId": 6727,
    "cityName": "Sertaneja",
    "stateId": 5251
  },
  {
    "cityId": 6728,
    "cityName": "Sertanópolis",
    "stateId": 5251
  },
  {
    "cityId": 6729,
    "cityName": "Sertãozinho",
    "stateId": 5251
  },
  {
    "cityId": 6731,
    "cityName": "Sete Saltos de Cima",
    "stateId": 5251
  },
  {
    "cityId": 6733,
    "cityName": "Siqueira Belo",
    "stateId": 5251
  },
  {
    "cityId": 6734,
    "cityName": "Siqueira Campos",
    "stateId": 5251
  },
  {
    "cityId": 6735,
    "cityName": "Socavão",
    "stateId": 5251
  },
  {
    "cityId": 6736,
    "cityName": "Colônia Socorro",
    "stateId": 5251
  },
  {
    "cityId": 6737,
    "cityName": "Sulina",
    "stateId": 5251
  },
  {
    "cityId": 6738,
    "cityName": "Sumaré",
    "stateId": 5251
  },
  {
    "cityId": 6739,
    "cityName": "Sussui",
    "stateId": 5251
  },
  {
    "cityId": 6740,
    "cityName": "Sutis",
    "stateId": 5251
  },
  {
    "cityId": 6741,
    "cityName": "Taipa",
    "stateId": 5251
  },
  {
    "cityId": 6742,
    "cityName": "Tamarana",
    "stateId": 5251
  },
  {
    "cityId": 6743,
    "cityName": "Tambarutaca",
    "stateId": 5251
  },
  {
    "cityId": 6744,
    "cityName": "Tamboara",
    "stateId": 5251
  },
  {
    "cityId": 6745,
    "cityName": "Tanque Grande",
    "stateId": 5251
  },
  {
    "cityId": 6746,
    "cityName": "Tapejara",
    "stateId": 5251
  },
  {
    "cityId": 6747,
    "cityName": "Tapira",
    "stateId": 5251
  },
  {
    "cityId": 6748,
    "cityName": "Tapui",
    "stateId": 5251
  },
  {
    "cityId": 6749,
    "cityName": "Taquara",
    "stateId": 5251
  },
  {
    "cityId": 6750,
    "cityName": "Taquari dos Polacos",
    "stateId": 5251
  },
  {
    "cityId": 6751,
    "cityName": "Taquari dos Russos",
    "stateId": 5251
  },
  {
    "cityId": 6752,
    "cityName": "Taquaruna",
    "stateId": 5251
  },
  {
    "cityId": 6753,
    "cityName": "Teixeira Soares",
    "stateId": 5251
  },
  {
    "cityId": 6754,
    "cityName": "Telêmaco Borba",
    "stateId": 5251
  },
  {
    "cityId": 6755,
    "cityName": "Teolândia",
    "stateId": 5251
  },
  {
    "cityId": 6756,
    "cityName": "Tereza Breda",
    "stateId": 5251
  },
  {
    "cityId": 6757,
    "cityName": "Tereza Cristina",
    "stateId": 5251
  },
  {
    "cityId": 6758,
    "cityName": "Terra Boa",
    "stateId": 5251
  },
  {
    "cityId": 6759,
    "cityName": "Terra Nova",
    "stateId": 5251
  },
  {
    "cityId": 6760,
    "cityName": "Terra Rica",
    "stateId": 5251
  },
  {
    "cityId": 6761,
    "cityName": "Terra Roxa",
    "stateId": 5251
  },
  {
    "cityId": 6762,
    "cityName": "Tibagi",
    "stateId": 5251
  },
  {
    "cityId": 6763,
    "cityName": "Tijucas do Sul",
    "stateId": 5251
  },
  {
    "cityId": 6764,
    "cityName": "Tijuco Preto",
    "stateId": 5251
  },
  {
    "cityId": 6765,
    "cityName": "Tijuco Preto",
    "stateId": 5251
  },
  {
    "cityId": 6766,
    "cityName": "Timbu Velho",
    "stateId": 5251
  },
  {
    "cityId": 6767,
    "cityName": "Tindiquera",
    "stateId": 5251
  },
  {
    "cityId": 6768,
    "cityName": "Tiradentes",
    "stateId": 5251
  },
  {
    "cityId": 6769,
    "cityName": "Tiradentes",
    "stateId": 5251
  },
  {
    "cityId": 6770,
    "cityName": "Toledo",
    "stateId": 5251
  },
  {
    "cityId": 6771,
    "cityName": "Tomaz Coelho",
    "stateId": 5251
  },
  {
    "cityId": 6772,
    "cityName": "Tomazina",
    "stateId": 5251
  },
  {
    "cityId": 6774,
    "cityName": "Três Barras do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 6775,
    "cityName": "Três Bicos",
    "stateId": 5251
  },
  {
    "cityId": 6776,
    "cityName": "Três Bocas",
    "stateId": 5251
  },
  {
    "cityId": 6777,
    "cityName": "Três Capões",
    "stateId": 5251
  },
  {
    "cityId": 6778,
    "cityName": "Três Córregos",
    "stateId": 5251
  },
  {
    "cityId": 6779,
    "cityName": "Três Lagoas",
    "stateId": 5251
  },
  {
    "cityId": 6780,
    "cityName": "Três Pinheiros",
    "stateId": 5251
  },
  {
    "cityId": 6781,
    "cityName": "Três Placas",
    "stateId": 5251
  },
  {
    "cityId": 6782,
    "cityName": "Triângulo",
    "stateId": 5251
  },
  {
    "cityId": 6783,
    "cityName": "Trindade",
    "stateId": 5251
  },
  {
    "cityId": 6784,
    "cityName": "Triolândia",
    "stateId": 5251
  },
  {
    "cityId": 6785,
    "cityName": "Tronco",
    "stateId": 5251
  },
  {
    "cityId": 6786,
    "cityName": "Tunas do Paraná",
    "stateId": 5251
  },
  {
    "cityId": 6787,
    "cityName": "Tuneiras do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6788,
    "cityName": "Tupãssi",
    "stateId": 5251
  },
  {
    "cityId": 6789,
    "cityName": "Tupinambá",
    "stateId": 5251
  },
  {
    "cityId": 6790,
    "cityName": "Turvo",
    "stateId": 5251
  },
  {
    "cityId": 6791,
    "cityName": "Ubaldino Taques",
    "stateId": 5251
  },
  {
    "cityId": 6792,
    "cityName": "Ubauna",
    "stateId": 5251
  },
  {
    "cityId": 6793,
    "cityName": "Ubiratã",
    "stateId": 5251
  },
  {
    "cityId": 6794,
    "cityName": "Umuarama",
    "stateId": 5251
  },
  {
    "cityId": 6795,
    "cityName": "União",
    "stateId": 5251
  },
  {
    "cityId": 6796,
    "cityName": "União da Vitória",
    "stateId": 5251
  },
  {
    "cityId": 6797,
    "cityName": "União do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6798,
    "cityName": "Uniflor",
    "stateId": 5251
  },
  {
    "cityId": 6799,
    "cityName": "Uraí",
    "stateId": 5251
  },
  {
    "cityId": 6801,
    "cityName": "Uvaia",
    "stateId": 5251
  },
  {
    "cityId": 6802,
    "cityName": "Valentins",
    "stateId": 5251
  },
  {
    "cityId": 6803,
    "cityName": "Valério",
    "stateId": 5251
  },
  {
    "cityId": 6804,
    "cityName": "Vargeado",
    "stateId": 5251
  },
  {
    "cityId": 6805,
    "cityName": "Vassoural",
    "stateId": 5251
  },
  {
    "cityId": 6806,
    "cityName": "Ventania",
    "stateId": 5251
  },
  {
    "cityId": 6807,
    "cityName": "Vera Cruz do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 6808,
    "cityName": "Vera Guarani",
    "stateId": 5251
  },
  {
    "cityId": 6809,
    "cityName": "Verê",
    "stateId": 5251
  },
  {
    "cityId": 6810,
    "cityName": "Vida Nova",
    "stateId": 5251
  },
  {
    "cityId": 6811,
    "cityName": "Vidigal",
    "stateId": 5251
  },
  {
    "cityId": 6812,
    "cityName": "Alto Paraíso",
    "stateId": 5251
  },
  {
    "cityId": 6813,
    "cityName": "Vila Diniz",
    "stateId": 5251
  },
  {
    "cityId": 6814,
    "cityName": "Vila dos Roldos",
    "stateId": 5251
  },
  {
    "cityId": 6815,
    "cityName": "Vila Flórida",
    "stateId": 5251
  },
  {
    "cityId": 6816,
    "cityName": "Vila Gandhi",
    "stateId": 5251
  },
  {
    "cityId": 6817,
    "cityName": "Vila Guay",
    "stateId": 5251
  },
  {
    "cityId": 6818,
    "cityName": "Vila Nova",
    "stateId": 5251
  },
  {
    "cityId": 6819,
    "cityName": "Vila Nova",
    "stateId": 5251
  },
  {
    "cityId": 6820,
    "cityName": "Vila Nova de Florença",
    "stateId": 5251
  },
  {
    "cityId": 6821,
    "cityName": "Vila Paraíso",
    "stateId": 5251
  },
  {
    "cityId": 6822,
    "cityName": "Vila Reis",
    "stateId": 5251
  },
  {
    "cityId": 6823,
    "cityName": "Vila Rica do Ivaí",
    "stateId": 5251
  },
  {
    "cityId": 6825,
    "cityName": "Vila Silva Jardim",
    "stateId": 5251
  },
  {
    "cityId": 6826,
    "cityName": "Vila Velha",
    "stateId": 5251
  },
  {
    "cityId": 6827,
    "cityName": "Virmond",
    "stateId": 5251
  },
  {
    "cityId": 6828,
    "cityName": "Vista Alegre",
    "stateId": 5251
  },
  {
    "cityId": 6829,
    "cityName": "Vista Alegre",
    "stateId": 5251
  },
  {
    "cityId": 6830,
    "cityName": "Vista Bonita",
    "stateId": 5251
  },
  {
    "cityId": 6831,
    "cityName": "Colônia Vitória",
    "stateId": 5251
  },
  {
    "cityId": 6832,
    "cityName": "Vitorino",
    "stateId": 5251
  },
  {
    "cityId": 6833,
    "cityName": "Warta",
    "stateId": 5251
  },
  {
    "cityId": 6834,
    "cityName": "Wenceslau Braz",
    "stateId": 5251
  },
  {
    "cityId": 6835,
    "cityName": "Xambrê",
    "stateId": 5251
  },
  {
    "cityId": 6836,
    "cityName": "Xaxim",
    "stateId": 5251
  },
  {
    "cityId": 6837,
    "cityName": "Yolanda",
    "stateId": 5251
  },
  {
    "cityId": 6838,
    "cityName": "4º Centenário",
    "stateId": 5251
  },
  {
    "cityId": 6839,
    "cityName": "Abarracamento",
    "stateId": 5252
  },
  {
    "cityId": 6840,
    "cityName": "Ilha Grande",
    "stateId": 5252
  },
  {
    "cityId": 6841,
    "cityName": "Afonso Arinos",
    "stateId": 5252
  },
  {
    "cityId": 6842,
    "cityName": "Agulhas Negras",
    "stateId": 5252
  },
  {
    "cityId": 6843,
    "cityName": "Amparo",
    "stateId": 5252
  },
  {
    "cityId": 6844,
    "cityName": "Andrade Pinto",
    "stateId": 5252
  },
  {
    "cityId": 6845,
    "cityName": "Angra dos Reis",
    "stateId": 5252
  },
  {
    "cityId": 6846,
    "cityName": "Anta",
    "stateId": 5252
  },
  {
    "cityId": 6847,
    "cityName": "Aperibé",
    "stateId": 5252
  },
  {
    "cityId": 6848,
    "cityName": "Araruama",
    "stateId": 5252
  },
  {
    "cityId": 6849,
    "cityName": "Areal",
    "stateId": 5252
  },
  {
    "cityId": 6850,
    "cityName": "Armação dos Búzios",
    "stateId": 5252
  },
  {
    "cityId": 6851,
    "cityName": "Arraial do Cabo",
    "stateId": 5252
  },
  {
    "cityId": 6852,
    "cityName": "Arrozal",
    "stateId": 5252
  },
  {
    "cityId": 6853,
    "cityName": "Avelar",
    "stateId": 5252
  },
  {
    "cityId": 6854,
    "cityName": "Bacaxá",
    "stateId": 5252
  },
  {
    "cityId": 6855,
    "cityName": "Baltazar",
    "stateId": 5252
  },
  {
    "cityId": 6856,
    "cityName": "Banquete",
    "stateId": 5252
  },
  {
    "cityId": 6857,
    "cityName": "Barão de Juparana",
    "stateId": 5252
  },
  {
    "cityId": 6858,
    "cityName": "Barcelos",
    "stateId": 5252
  },
  {
    "cityId": 6859,
    "cityName": "Barra Alegre",
    "stateId": 5252
  },
  {
    "cityId": 6860,
    "cityName": "Barra de Macaé",
    "stateId": 5252
  },
  {
    "cityId": 6861,
    "cityName": "Barra de São João",
    "stateId": 5252
  },
  {
    "cityId": 6862,
    "cityName": "Barra do Piraí",
    "stateId": 5252
  },
  {
    "cityId": 6863,
    "cityName": "Barra Mansa",
    "stateId": 5252
  },
  {
    "cityId": 6864,
    "cityName": "Barra Seca",
    "stateId": 5252
  },
  {
    "cityId": 6865,
    "cityName": "Belford Roxo",
    "stateId": 5252
  },
  {
    "cityId": 6866,
    "cityName": "Bemposta",
    "stateId": 5252
  },
  {
    "cityId": 6867,
    "cityName": "Boa Esperança",
    "stateId": 5252
  },
  {
    "cityId": 6868,
    "cityName": "Boa Sorte",
    "stateId": 5252
  },
  {
    "cityId": 6869,
    "cityName": "Boa Ventura",
    "stateId": 5252
  },
  {
    "cityId": 6870,
    "cityName": "Bom Jardim",
    "stateId": 5252
  },
  {
    "cityId": 6871,
    "cityName": "Bom Jesus do Itabapoana",
    "stateId": 5252
  },
  {
    "cityId": 6872,
    "cityName": "Bom Jesus do Querendo",
    "stateId": 5252
  },
  {
    "cityId": 6873,
    "cityName": "Cabo Frio",
    "stateId": 5252
  },
  {
    "cityId": 6874,
    "cityName": "Cabuçu",
    "stateId": 5252
  },
  {
    "cityId": 6875,
    "cityName": "Cachoeiras de Macacu",
    "stateId": 5252
  },
  {
    "cityId": 6876,
    "cityName": "Cachoeiros",
    "stateId": 5252
  },
  {
    "cityId": 6877,
    "cityName": "Calheiros",
    "stateId": 5252
  },
  {
    "cityId": 6878,
    "cityName": "Cambiasca",
    "stateId": 5252
  },
  {
    "cityId": 6879,
    "cityName": "Cambuci",
    "stateId": 5252
  },
  {
    "cityId": 6880,
    "cityName": "Campo do Coelho",
    "stateId": 5252
  },
  {
    "cityId": 6881,
    "cityName": "Campos dos Goytacazes",
    "stateId": 5252
  },
  {
    "cityId": 6882,
    "cityName": "Campos Elíseos",
    "stateId": 5252
  },
  {
    "cityId": 6883,
    "cityName": "Cantagalo",
    "stateId": 5252
  },
  {
    "cityId": 6884,
    "cityName": "Carabuçu",
    "stateId": 5252
  },
  {
    "cityId": 6885,
    "cityName": "Carapebus",
    "stateId": 5252
  },
  {
    "cityId": 6886,
    "cityName": "Cardoso Moreira",
    "stateId": 5252
  },
  {
    "cityId": 6887,
    "cityName": "Carmo",
    "stateId": 5252
  },
  {
    "cityId": 6888,
    "cityName": "Cascatinha",
    "stateId": 5252
  },
  {
    "cityId": 6889,
    "cityName": "Casimiro de Abreu",
    "stateId": 5252
  },
  {
    "cityId": 6890,
    "cityName": "Cava",
    "stateId": 5252
  },
  {
    "cityId": 6891,
    "cityName": "Coelho da Rocha",
    "stateId": 5252
  },
  {
    "cityId": 6892,
    "cityName": "Colônia",
    "stateId": 5252
  },
  {
    "cityId": 6893,
    "cityName": "Comendador Levy Gasparian",
    "stateId": 5252
  },
  {
    "cityId": 6894,
    "cityName": "Comendador Venâncio",
    "stateId": 5252
  },
  {
    "cityId": 6895,
    "cityName": "Conceição de Jacareí",
    "stateId": 5252
  },
  {
    "cityId": 6896,
    "cityName": "Conceição de Macabu",
    "stateId": 5252
  },
  {
    "cityId": 6897,
    "cityName": "Conrado",
    "stateId": 5252
  },
  {
    "cityId": 6898,
    "cityName": "Conselheiro Paulino",
    "stateId": 5252
  },
  {
    "cityId": 6899,
    "cityName": "Conservatória",
    "stateId": 5252
  },
  {
    "cityId": 6900,
    "cityName": "Cordeiro",
    "stateId": 5252
  },
  {
    "cityId": 6901,
    "cityName": "Coroa Grande",
    "stateId": 5252
  },
  {
    "cityId": 6902,
    "cityName": "Correas",
    "stateId": 5252
  },
  {
    "cityId": 6903,
    "cityName": "Córrego da Prata",
    "stateId": 5252
  },
  {
    "cityId": 6904,
    "cityName": "Córrego do Ouro",
    "stateId": 5252
  },
  {
    "cityId": 6905,
    "cityName": "Correntezas",
    "stateId": 5252
  },
  {
    "cityId": 6906,
    "cityName": "Cunhambebe",
    "stateId": 5252
  },
  {
    "cityId": 6907,
    "cityName": "Dorândia",
    "stateId": 5252
  },
  {
    "cityId": 6908,
    "cityName": "Dores de Macabu",
    "stateId": 5252
  },
  {
    "cityId": 6909,
    "cityName": "Doutor Elias",
    "stateId": 5252
  },
  {
    "cityId": 6910,
    "cityName": "Doutor Loreti",
    "stateId": 5252
  },
  {
    "cityId": 6911,
    "cityName": "Duas Barras",
    "stateId": 5252
  },
  {
    "cityId": 6912,
    "cityName": "Duque de Caxias",
    "stateId": 5252
  },
  {
    "cityId": 6913,
    "cityName": "Engenheiro Passos",
    "stateId": 5252
  },
  {
    "cityId": 6914,
    "cityName": "Engenheiro Paulo de Frontin",
    "stateId": 5252
  },
  {
    "cityId": 6915,
    "cityName": "Engenheiro Pedreira",
    "stateId": 5252
  },
  {
    "cityId": 6916,
    "cityName": "Estrada Nova",
    "stateId": 5252
  },
  {
    "cityId": 6917,
    "cityName": "Euclidelândia",
    "stateId": 5252
  },
  {
    "cityId": 6918,
    "cityName": "Falcão",
    "stateId": 5252
  },
  {
    "cityId": 6919,
    "cityName": "Floriano",
    "stateId": 5252
  },
  {
    "cityId": 6920,
    "cityName": "Fumaça",
    "stateId": 5252
  },
  {
    "cityId": 6921,
    "cityName": "Funil",
    "stateId": 5252
  },
  {
    "cityId": 6922,
    "cityName": "Gaviões",
    "stateId": 5252
  },
  {
    "cityId": 6923,
    "cityName": "Getulândia",
    "stateId": 5252
  },
  {
    "cityId": 6924,
    "cityName": "Glicério",
    "stateId": 5252
  },
  {
    "cityId": 6925,
    "cityName": "Goitacazes",
    "stateId": 5252
  },
  {
    "cityId": 6926,
    "cityName": "Governador Portela",
    "stateId": 5252
  },
  {
    "cityId": 6927,
    "cityName": "Guapimirim",
    "stateId": 5252
  },
  {
    "cityId": 6928,
    "cityName": "Guia de Pacobaíba",
    "stateId": 5252
  },
  {
    "cityId": 6929,
    "cityName": "Ibitiguaçu",
    "stateId": 5252
  },
  {
    "cityId": 6930,
    "cityName": "Ibitioca",
    "stateId": 5252
  },
  {
    "cityId": 6931,
    "cityName": "Ibituporanga",
    "stateId": 5252
  },
  {
    "cityId": 6932,
    "cityName": "Iguaba Grande",
    "stateId": 5252
  },
  {
    "cityId": 6933,
    "cityName": "Imbariê",
    "stateId": 5252
  },
  {
    "cityId": 6934,
    "cityName": "Inconfidência",
    "stateId": 5252
  },
  {
    "cityId": 6935,
    "cityName": "Inhomirim",
    "stateId": 5252
  },
  {
    "cityId": 6936,
    "cityName": "Inoã",
    "stateId": 5252
  },
  {
    "cityId": 6937,
    "cityName": "Ipiabás",
    "stateId": 5252
  },
  {
    "cityId": 6938,
    "cityName": "Ipiiba",
    "stateId": 5252
  },
  {
    "cityId": 6939,
    "cityName": "Ipuca",
    "stateId": 5252
  },
  {
    "cityId": 6940,
    "cityName": "Itabapoana",
    "stateId": 5252
  },
  {
    "cityId": 6941,
    "cityName": "Itaboraí",
    "stateId": 5252
  },
  {
    "cityId": 6942,
    "cityName": "Itacurussá",
    "stateId": 5252
  },
  {
    "cityId": 6943,
    "cityName": "Itaguaí",
    "stateId": 5252
  },
  {
    "cityId": 6944,
    "cityName": "Itaipava",
    "stateId": 5252
  },
  {
    "cityId": 6945,
    "cityName": "Itaipu",
    "stateId": 5252
  },
  {
    "cityId": 6946,
    "cityName": "Itajara",
    "stateId": 5252
  },
  {
    "cityId": 6947,
    "cityName": "Italva",
    "stateId": 5252
  },
  {
    "cityId": 6948,
    "cityName": "Itambi",
    "stateId": 5252
  },
  {
    "cityId": 6949,
    "cityName": "Itaocara",
    "stateId": 5252
  },
  {
    "cityId": 6950,
    "cityName": "Itaperuna",
    "stateId": 5252
  },
  {
    "cityId": 6951,
    "cityName": "Itatiaia",
    "stateId": 5252
  },
  {
    "cityId": 6953,
    "cityName": "Jaguarembé",
    "stateId": 5252
  },
  {
    "cityId": 6954,
    "cityName": "Jamapará",
    "stateId": 5252
  },
  {
    "cityId": 6955,
    "cityName": "Japeri",
    "stateId": 5252
  },
  {
    "cityId": 6956,
    "cityName": "Japuíba",
    "stateId": 5252
  },
  {
    "cityId": 6957,
    "cityName": "Laje do Muriaé",
    "stateId": 5252
  },
  {
    "cityId": 6958,
    "cityName": "Laranjais",
    "stateId": 5252
  },
  {
    "cityId": 6959,
    "cityName": "Lídice",
    "stateId": 5252
  },
  {
    "cityId": 6960,
    "cityName": "Lumiar",
    "stateId": 5252
  },
  {
    "cityId": 6961,
    "cityName": "Macabuzinho",
    "stateId": 5252
  },
  {
    "cityId": 6962,
    "cityName": "Macaé",
    "stateId": 5252
  },
  {
    "cityId": 6963,
    "cityName": "Macuco",
    "stateId": 5252
  },
  {
    "cityId": 6964,
    "cityName": "Magé",
    "stateId": 5252
  },
  {
    "cityId": 6965,
    "cityName": "Mambucaba",
    "stateId": 5252
  },
  {
    "cityId": 6966,
    "cityName": "Mangaratiba",
    "stateId": 5252
  },
  {
    "cityId": 6967,
    "cityName": "Maniva",
    "stateId": 5252
  },
  {
    "cityId": 6968,
    "cityName": "Manoel Ribeiro",
    "stateId": 5252
  },
  {
    "cityId": 6969,
    "cityName": "Manuel Duarte",
    "stateId": 5252
  },
  {
    "cityId": 6970,
    "cityName": "Marangatu",
    "stateId": 5252
  },
  {
    "cityId": 6971,
    "cityName": "Maricá",
    "stateId": 5252
  },
  {
    "cityId": 6972,
    "cityName": "Mendes",
    "stateId": 5252
  },
  {
    "cityId": 6973,
    "cityName": "Mesquita",
    "stateId": 5252
  },
  {
    "cityId": 6974,
    "cityName": "Miguel Pereira",
    "stateId": 5252
  },
  {
    "cityId": 6975,
    "cityName": "Miracema",
    "stateId": 5252
  },
  {
    "cityId": 6976,
    "cityName": "Monnerat",
    "stateId": 5252
  },
  {
    "cityId": 6977,
    "cityName": "Monjolo",
    "stateId": 5252
  },
  {
    "cityId": 6978,
    "cityName": "Monte Alegre",
    "stateId": 5252
  },
  {
    "cityId": 6979,
    "cityName": "Monte Verde",
    "stateId": 5252
  },
  {
    "cityId": 6980,
    "cityName": "Monumento",
    "stateId": 5252
  },
  {
    "cityId": 6981,
    "cityName": "Morangaba",
    "stateId": 5252
  },
  {
    "cityId": 6982,
    "cityName": "Morro do Côco",
    "stateId": 5252
  },
  {
    "cityId": 6983,
    "cityName": "Morro Grande",
    "stateId": 5252
  },
  {
    "cityId": 6984,
    "cityName": "Mussurepe",
    "stateId": 5252
  },
  {
    "cityId": 6985,
    "cityName": "Natividade",
    "stateId": 5252
  },
  {
    "cityId": 6986,
    "cityName": "Neves",
    "stateId": 5252
  },
  {
    "cityId": 6987,
    "cityName": "Nhunguaçu",
    "stateId": 5252
  },
  {
    "cityId": 6988,
    "cityName": "Nilópolis",
    "stateId": 5252
  },
  {
    "cityId": 6989,
    "cityName": "Niterói",
    "stateId": 5252
  },
  {
    "cityId": 6990,
    "cityName": "Nossa Senhora da Aparecida",
    "stateId": 5252
  },
  {
    "cityId": 6991,
    "cityName": "Nossa Senhora da Penha",
    "stateId": 5252
  },
  {
    "cityId": 6992,
    "cityName": "Nossa Senhora do Amparo",
    "stateId": 5252
  },
  {
    "cityId": 6993,
    "cityName": "Nova Friburgo",
    "stateId": 5252
  },
  {
    "cityId": 6994,
    "cityName": "Nova Iguaçu",
    "stateId": 5252
  },
  {
    "cityId": 6995,
    "cityName": "Olinda",
    "stateId": 5252
  },
  {
    "cityId": 6996,
    "cityName": "Ourânia",
    "stateId": 5252
  },
  {
    "cityId": 6997,
    "cityName": "Papucaia",
    "stateId": 5252
  },
  {
    "cityId": 6998,
    "cityName": "Paquequer Pequeno",
    "stateId": 5252
  },
  {
    "cityId": 6999,
    "cityName": "Paracambi",
    "stateId": 5252
  },
  {
    "cityId": 7000,
    "cityName": "Paraíba do Sul",
    "stateId": 5252
  },
  {
    "cityId": 7001,
    "cityName": "Paraíso do Tobias",
    "stateId": 5252
  },
  {
    "cityId": 7002,
    "cityName": "Paraoquena",
    "stateId": 5252
  },
  {
    "cityId": 7003,
    "cityName": "Parapeúna",
    "stateId": 5252
  },
  {
    "cityId": 7004,
    "cityName": "Parati",
    "stateId": 5252
  },
  {
    "cityId": 7005,
    "cityName": "Parati Mirim",
    "stateId": 5252
  },
  {
    "cityId": 7006,
    "cityName": "Passa Três",
    "stateId": 5252
  },
  {
    "cityId": 7007,
    "cityName": "Paty do Alferes",
    "stateId": 5252
  },
  {
    "cityId": 7008,
    "cityName": "Pedra Selada",
    "stateId": 5252
  },
  {
    "cityId": 7009,
    "cityName": "Pedro do Rio",
    "stateId": 5252
  },
  {
    "cityId": 7010,
    "cityName": "Penedo",
    "stateId": 5252
  },
  {
    "cityId": 7011,
    "cityName": "Pentagna",
    "stateId": 5252
  },
  {
    "cityId": 7012,
    "cityName": "Petrópolis",
    "stateId": 5252
  },
  {
    "cityId": 7013,
    "cityName": "Piabetá",
    "stateId": 5252
  },
  {
    "cityId": 7014,
    "cityName": "Pião",
    "stateId": 5252
  },
  {
    "cityId": 7015,
    "cityName": "Pinheiral",
    "stateId": 5252
  },
  {
    "cityId": 7016,
    "cityName": "Pipeiras",
    "stateId": 5252
  },
  {
    "cityId": 7017,
    "cityName": "Piraí",
    "stateId": 5252
  },
  {
    "cityId": 7018,
    "cityName": "Pirangaí",
    "stateId": 5252
  },
  {
    "cityId": 7019,
    "cityName": "Pirapetinga de Bom Jesus",
    "stateId": 5252
  },
  {
    "cityId": 7020,
    "cityName": "Porciúncula",
    "stateId": 5252
  },
  {
    "cityId": 7021,
    "cityName": "Portela",
    "stateId": 5252
  },
  {
    "cityId": 7023,
    "cityName": "Porto Real",
    "stateId": 5252
  },
  {
    "cityId": 7024,
    "cityName": "Porto Velho do Cunha",
    "stateId": 5252
  },
  {
    "cityId": 7025,
    "cityName": "Posse",
    "stateId": 5252
  },
  {
    "cityId": 7027,
    "cityName": "Pureza",
    "stateId": 5252
  },
  {
    "cityId": 7028,
    "cityName": "Purilândia",
    "stateId": 5252
  },
  {
    "cityId": 7029,
    "cityName": "Quartéis",
    "stateId": 5252
  },
  {
    "cityId": 7030,
    "cityName": "Quatis",
    "stateId": 5252
  },
  {
    "cityId": 7031,
    "cityName": "Queimados",
    "stateId": 5252
  },
  {
    "cityId": 7032,
    "cityName": "Quissamã",
    "stateId": 5252
  },
  {
    "cityId": 7033,
    "cityName": "Raposo",
    "stateId": 5252
  },
  {
    "cityId": 7034,
    "cityName": "Renascença",
    "stateId": 5252
  },
  {
    "cityId": 7035,
    "cityName": "Resende",
    "stateId": 5252
  },
  {
    "cityId": 7036,
    "cityName": "Retiro do Muriaé",
    "stateId": 5252
  },
  {
    "cityId": 7037,
    "cityName": "Rialto",
    "stateId": 5252
  },
  {
    "cityId": 7038,
    "cityName": "Ribeirão de São Joaquim",
    "stateId": 5252
  },
  {
    "cityId": 7039,
    "cityName": "Rio Bonito",
    "stateId": 5252
  },
  {
    "cityId": 7040,
    "cityName": "Rio Claro",
    "stateId": 5252
  },
  {
    "cityId": 7041,
    "cityName": "Rio das Flores",
    "stateId": 5252
  },
  {
    "cityId": 7042,
    "cityName": "Rio das Ostras",
    "stateId": 5252
  },
  {
    "cityId": 7043,
    "cityName": "Rio de Janeiro",
    "stateId": 5252
  },
  {
    "cityId": 7044,
    "cityName": "Riograndina",
    "stateId": 5252
  },
  {
    "cityId": 7045,
    "cityName": "Rosal",
    "stateId": 5252
  },
  {
    "cityId": 7046,
    "cityName": "Sacra Família do Tinguá",
    "stateId": 5252
  },
  {
    "cityId": 7047,
    "cityName": "Salutaris",
    "stateId": 5252
  },
  {
    "cityId": 7048,
    "cityName": "Sambaetiba",
    "stateId": 5252
  },
  {
    "cityId": 7049,
    "cityName": "Sampaio Correia",
    "stateId": 5252
  },
  {
    "cityId": 7050,
    "cityName": "Sana",
    "stateId": 5252
  },
  {
    "cityId": 7051,
    "cityName": "Santa Clara",
    "stateId": 5252
  },
  {
    "cityId": 7052,
    "cityName": "Santa Cruz",
    "stateId": 5252
  },
  {
    "cityId": 7053,
    "cityName": "Santa Cruz da Serra",
    "stateId": 5252
  },
  {
    "cityId": 7054,
    "cityName": "Santa Isabel",
    "stateId": 5252
  },
  {
    "cityId": 7055,
    "cityName": "Santa Isabel do Rio Preto",
    "stateId": 5252
  },
  {
    "cityId": 7056,
    "cityName": "Santa Maria",
    "stateId": 5252
  },
  {
    "cityId": 7057,
    "cityName": "Santa Maria Madalena",
    "stateId": 5252
  },
  {
    "cityId": 7058,
    "cityName": "Santa Rita da Floresta",
    "stateId": 5252
  },
  {
    "cityId": 7059,
    "cityName": "Santanésia",
    "stateId": 5252
  },
  {
    "cityId": 7060,
    "cityName": "Santo Aleixo",
    "stateId": 5252
  },
  {
    "cityId": 7061,
    "cityName": "Santo Amaro de Campos",
    "stateId": 5252
  },
  {
    "cityId": 7062,
    "cityName": "Santo Antônio de Pádua",
    "stateId": 5252
  },
  {
    "cityId": 7063,
    "cityName": "Santo Antônio do Imbé",
    "stateId": 5252
  },
  {
    "cityId": 7064,
    "cityName": "Santo Eduardo",
    "stateId": 5252
  },
  {
    "cityId": 7065,
    "cityName": "São Fidélis",
    "stateId": 5252
  },
  {
    "cityId": 7066,
    "cityName": "São Francisco de Itabapoana",
    "stateId": 5252
  },
  {
    "cityId": 7067,
    "cityName": "São Gonçalo",
    "stateId": 5252
  },
  {
    "cityId": 7068,
    "cityName": "São João da Barra",
    "stateId": 5252
  },
  {
    "cityId": 7069,
    "cityName": "São João de Meriti",
    "stateId": 5252
  },
  {
    "cityId": 7070,
    "cityName": "São João do Paraíso",
    "stateId": 5252
  },
  {
    "cityId": 7071,
    "cityName": "São João Marcos",
    "stateId": 5252
  },
  {
    "cityId": 7072,
    "cityName": "São Joaquim",
    "stateId": 5252
  },
  {
    "cityId": 7073,
    "cityName": "São José de Ubá",
    "stateId": 5252
  },
  {
    "cityId": 7074,
    "cityName": "São José do Ribeirão",
    "stateId": 5252
  },
  {
    "cityId": 7075,
    "cityName": "São José do Turvo",
    "stateId": 5252
  },
  {
    "cityId": 7076,
    "cityName": "São José do Vale do Rio Preto",
    "stateId": 5252
  },
  {
    "cityId": 7077,
    "cityName": "São Mateus",
    "stateId": 5252
  },
  {
    "cityId": 7078,
    "cityName": "São Pedro da Aldeia",
    "stateId": 5252
  },
  {
    "cityId": 7079,
    "cityName": "São Sebastião de Campos",
    "stateId": 5252
  },
  {
    "cityId": 7080,
    "cityName": "São Sebastião do Alto",
    "stateId": 5252
  },
  {
    "cityId": 7081,
    "cityName": "São Sebastião do Paraíba",
    "stateId": 5252
  },
  {
    "cityId": 7082,
    "cityName": "São Sebastião dos Ferreiros",
    "stateId": 5252
  },
  {
    "cityId": 7083,
    "cityName": "São Vicente de Paula",
    "stateId": 5252
  },
  {
    "cityId": 7084,
    "cityName": "Sapucaia",
    "stateId": 5252
  },
  {
    "cityId": 7085,
    "cityName": "Saquarema",
    "stateId": 5252
  },
  {
    "cityId": 7086,
    "cityName": "Saracuruna",
    "stateId": 5252
  },
  {
    "cityId": 7087,
    "cityName": "Sebastião de Lacerda",
    "stateId": 5252
  },
  {
    "cityId": 7088,
    "cityName": "Seropédica",
    "stateId": 5252
  },
  {
    "cityId": 7089,
    "cityName": "Serrinha",
    "stateId": 5252
  },
  {
    "cityId": 7090,
    "cityName": "Sete Pontes",
    "stateId": 5252
  },
  {
    "cityId": 7091,
    "cityName": "Silva Jardim",
    "stateId": 5252
  },
  {
    "cityId": 7092,
    "cityName": "Sodrelândia",
    "stateId": 5252
  },
  {
    "cityId": 7093,
    "cityName": "Sossego",
    "stateId": 5252
  },
  {
    "cityId": 7094,
    "cityName": "Subaio",
    "stateId": 5252
  },
  {
    "cityId": 7095,
    "cityName": "Sumidouro",
    "stateId": 5252
  },
  {
    "cityId": 7096,
    "cityName": "Suruí",
    "stateId": 5252
  },
  {
    "cityId": 7097,
    "cityName": "Taboas",
    "stateId": 5252
  },
  {
    "cityId": 7098,
    "cityName": "Tamoios",
    "stateId": 5252
  },
  {
    "cityId": 7099,
    "cityName": "Tanguá",
    "stateId": 5252
  },
  {
    "cityId": 7100,
    "cityName": "Tapera",
    "stateId": 5252
  },
  {
    "cityId": 7101,
    "cityName": "Tarituba",
    "stateId": 5252
  },
  {
    "cityId": 7102,
    "cityName": "Teresópolis",
    "stateId": 5252
  },
  {
    "cityId": 7103,
    "cityName": "Tocos",
    "stateId": 5252
  },
  {
    "cityId": 7104,
    "cityName": "Trajano de Morais",
    "stateId": 5252
  },
  {
    "cityId": 7105,
    "cityName": "Travessão",
    "stateId": 5252
  },
  {
    "cityId": 7106,
    "cityName": "Três Irmãos",
    "stateId": 5252
  },
  {
    "cityId": 7107,
    "cityName": "Três Rios",
    "stateId": 5252
  },
  {
    "cityId": 7108,
    "cityName": "Triunfo",
    "stateId": 5252
  },
  {
    "cityId": 7109,
    "cityName": "Valão do Barro",
    "stateId": 5252
  },
  {
    "cityId": 7110,
    "cityName": "Valença",
    "stateId": 5252
  },
  {
    "cityId": 7111,
    "cityName": "Vargem Alegre",
    "stateId": 5252
  },
  {
    "cityId": 7112,
    "cityName": "Varre-Sai",
    "stateId": 5252
  },
  {
    "cityId": 7113,
    "cityName": "Vassouras",
    "stateId": 5252
  },
  {
    "cityId": 7114,
    "cityName": "Venda das Flores",
    "stateId": 5252
  },
  {
    "cityId": 7116,
    "cityName": "Vila da Grama",
    "stateId": 5252
  },
  {
    "cityId": 7118,
    "cityName": "Vila Muriqui",
    "stateId": 5252
  },
  {
    "cityId": 7119,
    "cityName": "Vila Nova de Campos",
    "stateId": 5252
  },
  {
    "cityId": 7120,
    "cityName": "Visconde de Imbé",
    "stateId": 5252
  },
  {
    "cityId": 7121,
    "cityName": "Volta Redonda",
    "stateId": 5252
  },
  {
    "cityId": 7122,
    "cityName": "Werneck",
    "stateId": 5252
  },
  {
    "cityId": 7123,
    "cityName": "Xerém",
    "stateId": 5252
  },
  {
    "cityId": 7124,
    "cityName": "Acari",
    "stateId": 5253
  },
  {
    "cityId": 7125,
    "cityName": "Açu",
    "stateId": 5253
  },
  {
    "cityId": 7126,
    "cityName": "Afonso Bezerra",
    "stateId": 5253
  },
  {
    "cityId": 7127,
    "cityName": "Água Nova",
    "stateId": 5253
  },
  {
    "cityId": 7128,
    "cityName": "Alexandria",
    "stateId": 5253
  },
  {
    "cityId": 7129,
    "cityName": "Almino Afonso",
    "stateId": 5253
  },
  {
    "cityId": 7130,
    "cityName": "Alto do Rodrigues",
    "stateId": 5253
  },
  {
    "cityId": 7131,
    "cityName": "Angicos",
    "stateId": 5253
  },
  {
    "cityId": 7132,
    "cityName": "Antônio Martins",
    "stateId": 5253
  },
  {
    "cityId": 7133,
    "cityName": "Apodi",
    "stateId": 5253
  },
  {
    "cityId": 7134,
    "cityName": "Areia Branca",
    "stateId": 5253
  },
  {
    "cityId": 7135,
    "cityName": "Arez",
    "stateId": 5253
  },
  {
    "cityId": 7136,
    "cityName": "Baía Formosa",
    "stateId": 5253
  },
  {
    "cityId": 7137,
    "cityName": "Barão de Serra Branca",
    "stateId": 5253
  },
  {
    "cityId": 7138,
    "cityName": "Baraúna",
    "stateId": 5253
  },
  {
    "cityId": 7139,
    "cityName": "Barcelona",
    "stateId": 5253
  },
  {
    "cityId": 7140,
    "cityName": "Belo Horizonte",
    "stateId": 5253
  },
  {
    "cityId": 7141,
    "cityName": "Bento Fernandes",
    "stateId": 5253
  },
  {
    "cityId": 7142,
    "cityName": "Boa Saúde",
    "stateId": 5253
  },
  {
    "cityId": 7143,
    "cityName": "Bodó",
    "stateId": 5253
  },
  {
    "cityId": 7144,
    "cityName": "Bom Jesus",
    "stateId": 5253
  },
  {
    "cityId": 7145,
    "cityName": "Brejinho",
    "stateId": 5253
  },
  {
    "cityId": 7146,
    "cityName": "Caiçara do Norte",
    "stateId": 5253
  },
  {
    "cityId": 7147,
    "cityName": "Caiçara do Rio do Vento",
    "stateId": 5253
  },
  {
    "cityId": 7148,
    "cityName": "Caicó",
    "stateId": 5253
  },
  {
    "cityId": 7149,
    "cityName": "Campo Grande",
    "stateId": 5253
  },
  {
    "cityId": 7150,
    "cityName": "Campo Redondo",
    "stateId": 5253
  },
  {
    "cityId": 7151,
    "cityName": "Canguaretama",
    "stateId": 5253
  },
  {
    "cityId": 7152,
    "cityName": "Caraúbas",
    "stateId": 5253
  },
  {
    "cityId": 7153,
    "cityName": "Carnaúba dos Dantas",
    "stateId": 5253
  },
  {
    "cityId": 7154,
    "cityName": "Carnaubais",
    "stateId": 5253
  },
  {
    "cityId": 7155,
    "cityName": "Ceará-Mirim",
    "stateId": 5253
  },
  {
    "cityId": 7156,
    "cityName": "Cerro Corá",
    "stateId": 5253
  },
  {
    "cityId": 7157,
    "cityName": "Coronel Ezequiel",
    "stateId": 5253
  },
  {
    "cityId": 7158,
    "cityName": "Coronel João Pessoa",
    "stateId": 5253
  },
  {
    "cityId": 7159,
    "cityName": "Córrego de São Mateus",
    "stateId": 5253
  },
  {
    "cityId": 7160,
    "cityName": "Cruzeta",
    "stateId": 5253
  },
  {
    "cityId": 7161,
    "cityName": "Currais Novos",
    "stateId": 5253
  },
  {
    "cityId": 7162,
    "cityName": "Doutor Severiano",
    "stateId": 5253
  },
  {
    "cityId": 7163,
    "cityName": "Encanto",
    "stateId": 5253
  },
  {
    "cityId": 7164,
    "cityName": "Equador",
    "stateId": 5253
  },
  {
    "cityId": 7165,
    "cityName": "Espírito Santo",
    "stateId": 5253
  },
  {
    "cityId": 7166,
    "cityName": "Paraú",
    "stateId": 5253
  },
  {
    "cityId": 7167,
    "cityName": "Extremoz",
    "stateId": 5253
  },
  {
    "cityId": 7168,
    "cityName": "Felipe Guerra",
    "stateId": 5253
  },
  {
    "cityId": 7169,
    "cityName": "Fernando Pedroza",
    "stateId": 5253
  },
  {
    "cityId": 7170,
    "cityName": "Firmamento",
    "stateId": 5253
  },
  {
    "cityId": 7171,
    "cityName": "Florânia",
    "stateId": 5253
  },
  {
    "cityId": 7172,
    "cityName": "Francisco Dantas",
    "stateId": 5253
  },
  {
    "cityId": 7173,
    "cityName": "Frutuoso Gomes",
    "stateId": 5253
  },
  {
    "cityId": 7174,
    "cityName": "Galinhos",
    "stateId": 5253
  },
  {
    "cityId": 7175,
    "cityName": "Gameleira",
    "stateId": 5253
  },
  {
    "cityId": 7176,
    "cityName": "Goianinha",
    "stateId": 5253
  },
  {
    "cityId": 7177,
    "cityName": "Governador Dix-Sept Rosado",
    "stateId": 5253
  },
  {
    "cityId": 7178,
    "cityName": "Grossos",
    "stateId": 5253
  },
  {
    "cityId": 7179,
    "cityName": "Guamaré",
    "stateId": 5253
  },
  {
    "cityId": 7180,
    "cityName": "Ielmo Marinho",
    "stateId": 5253
  },
  {
    "cityId": 7181,
    "cityName": "Igreja Nova",
    "stateId": 5253
  },
  {
    "cityId": 7182,
    "cityName": "Ipanguaçu",
    "stateId": 5253
  },
  {
    "cityId": 7183,
    "cityName": "Ipiranga",
    "stateId": 5253
  },
  {
    "cityId": 7184,
    "cityName": "Ipueira",
    "stateId": 5253
  },
  {
    "cityId": 7185,
    "cityName": "Itajá",
    "stateId": 5253
  },
  {
    "cityId": 7186,
    "cityName": "Itaú",
    "stateId": 5253
  },
  {
    "cityId": 7187,
    "cityName": "Jaçanã",
    "stateId": 5253
  },
  {
    "cityId": 7188,
    "cityName": "Jandaíra",
    "stateId": 5253
  },
  {
    "cityId": 7189,
    "cityName": "Janduís",
    "stateId": 5253
  },
  {
    "cityId": 7190,
    "cityName": "Japi",
    "stateId": 5253
  },
  {
    "cityId": 7191,
    "cityName": "Jardim de Angicos",
    "stateId": 5253
  },
  {
    "cityId": 7192,
    "cityName": "Jardim de Piranhas",
    "stateId": 5253
  },
  {
    "cityId": 7193,
    "cityName": "Jardim do Seridó",
    "stateId": 5253
  },
  {
    "cityId": 7194,
    "cityName": "João Câmara",
    "stateId": 5253
  },
  {
    "cityId": 7195,
    "cityName": "João Dias",
    "stateId": 5253
  },
  {
    "cityId": 7196,
    "cityName": "José da Penha",
    "stateId": 5253
  },
  {
    "cityId": 7197,
    "cityName": "Jucurutu",
    "stateId": 5253
  },
  {
    "cityId": 7198,
    "cityName": "Jundiá",
    "stateId": 5253
  },
  {
    "cityId": 7199,
    "cityName": "Lagoa D'Anta",
    "stateId": 5253
  },
  {
    "cityId": 7200,
    "cityName": "Lagoa de Pedras",
    "stateId": 5253
  },
  {
    "cityId": 7201,
    "cityName": "Lagoa de Velhos",
    "stateId": 5253
  },
  {
    "cityId": 7202,
    "cityName": "Lagoa Nova",
    "stateId": 5253
  },
  {
    "cityId": 7203,
    "cityName": "Lagoa Salgada",
    "stateId": 5253
  },
  {
    "cityId": 7204,
    "cityName": "Lajes",
    "stateId": 5253
  },
  {
    "cityId": 7205,
    "cityName": "Lajes Pintadas",
    "stateId": 5253
  },
  {
    "cityId": 7206,
    "cityName": "Lucrécia",
    "stateId": 5253
  },
  {
    "cityId": 7207,
    "cityName": "Luís Gomes",
    "stateId": 5253
  },
  {
    "cityId": 7208,
    "cityName": "Macaíba",
    "stateId": 5253
  },
  {
    "cityId": 7209,
    "cityName": "Macau",
    "stateId": 5253
  },
  {
    "cityId": 7210,
    "cityName": "Major Felipe",
    "stateId": 5253
  },
  {
    "cityId": 7211,
    "cityName": "Major Sales",
    "stateId": 5253
  },
  {
    "cityId": 7212,
    "cityName": "Marcelino Vieira",
    "stateId": 5253
  },
  {
    "cityId": 7213,
    "cityName": "Martins",
    "stateId": 5253
  },
  {
    "cityId": 7214,
    "cityName": "Mata de São Braz",
    "stateId": 5253
  },
  {
    "cityId": 7215,
    "cityName": "Maxaranguape",
    "stateId": 5253
  },
  {
    "cityId": 7216,
    "cityName": "Messias Targino",
    "stateId": 5253
  },
  {
    "cityId": 7217,
    "cityName": "Montanhas",
    "stateId": 5253
  },
  {
    "cityId": 7218,
    "cityName": "Monte Alegre",
    "stateId": 5253
  },
  {
    "cityId": 7219,
    "cityName": "Monte das Gameleiras",
    "stateId": 5253
  },
  {
    "cityId": 7220,
    "cityName": "Mossoró",
    "stateId": 5253
  },
  {
    "cityId": 7221,
    "cityName": "Natal",
    "stateId": 5253
  },
  {
    "cityId": 7222,
    "cityName": "Nísia Floresta",
    "stateId": 5253
  },
  {
    "cityId": 7223,
    "cityName": "Nova Cruz",
    "stateId": 5253
  },
  {
    "cityId": 7224,
    "cityName": "Olho-D'Água do Borges",
    "stateId": 5253
  },
  {
    "cityId": 7225,
    "cityName": "Ouro Branco",
    "stateId": 5253
  },
  {
    "cityId": 7226,
    "cityName": "Paraná",
    "stateId": 5253
  },
  {
    "cityId": 7227,
    "cityName": "Parazinho",
    "stateId": 5253
  },
  {
    "cityId": 7228,
    "cityName": "Parelhas",
    "stateId": 5253
  },
  {
    "cityId": 7229,
    "cityName": "Parnamirim",
    "stateId": 5253
  },
  {
    "cityId": 7230,
    "cityName": "Passa e Fica",
    "stateId": 5253
  },
  {
    "cityId": 7231,
    "cityName": "Passagem",
    "stateId": 5253
  },
  {
    "cityId": 7232,
    "cityName": "Patu",
    "stateId": 5253
  },
  {
    "cityId": 7233,
    "cityName": "Pau dos Ferros",
    "stateId": 5253
  },
  {
    "cityId": 7234,
    "cityName": "Pedra Grande",
    "stateId": 5253
  },
  {
    "cityId": 7235,
    "cityName": "Pedra Preta",
    "stateId": 5253
  },
  {
    "cityId": 7236,
    "cityName": "Pedro Avelino",
    "stateId": 5253
  },
  {
    "cityId": 7237,
    "cityName": "Pedro Velho",
    "stateId": 5253
  },
  {
    "cityId": 7238,
    "cityName": "Pendências",
    "stateId": 5253
  },
  {
    "cityId": 7239,
    "cityName": "Pilões",
    "stateId": 5253
  },
  {
    "cityId": 7240,
    "cityName": "Poço Branco",
    "stateId": 5253
  },
  {
    "cityId": 7241,
    "cityName": "Portalegre",
    "stateId": 5253
  },
  {
    "cityId": 7242,
    "cityName": "Porto do Mangue",
    "stateId": 5253
  },
  {
    "cityId": 7243,
    "cityName": "Pureza",
    "stateId": 5253
  },
  {
    "cityId": 7244,
    "cityName": "Rafael Fernandes",
    "stateId": 5253
  },
  {
    "cityId": 7245,
    "cityName": "Rafael Godeiro",
    "stateId": 5253
  },
  {
    "cityId": 7246,
    "cityName": "Riacho da Cruz",
    "stateId": 5253
  },
  {
    "cityId": 7247,
    "cityName": "Riacho de Santana",
    "stateId": 5253
  },
  {
    "cityId": 7248,
    "cityName": "Riachuelo",
    "stateId": 5253
  },
  {
    "cityId": 7249,
    "cityName": "Rio do Fogo",
    "stateId": 5253
  },
  {
    "cityId": 7250,
    "cityName": "Rodolfo Fernandes",
    "stateId": 5253
  },
  {
    "cityId": 7251,
    "cityName": "Rosário",
    "stateId": 5253
  },
  {
    "cityId": 7252,
    "cityName": "Ruy Barbosa",
    "stateId": 5253
  },
  {
    "cityId": 7253,
    "cityName": "Salva Vida",
    "stateId": 5253
  },
  {
    "cityId": 7254,
    "cityName": "Santa Cruz",
    "stateId": 5253
  },
  {
    "cityId": 7255,
    "cityName": "Santa Fé",
    "stateId": 5253
  },
  {
    "cityId": 7256,
    "cityName": "Santa Maria",
    "stateId": 5253
  },
  {
    "cityId": 7257,
    "cityName": "Santa Teresa",
    "stateId": 5253
  },
  {
    "cityId": 7258,
    "cityName": "Santana do Matos",
    "stateId": 5253
  },
  {
    "cityId": 7259,
    "cityName": "Santana do Seridó",
    "stateId": 5253
  },
  {
    "cityId": 7260,
    "cityName": "Santo Antônio",
    "stateId": 5253
  },
  {
    "cityId": 7261,
    "cityName": "Santo Antônio do Potengi",
    "stateId": 5253
  },
  {
    "cityId": 7262,
    "cityName": "São Bento do Norte",
    "stateId": 5253
  },
  {
    "cityId": 7263,
    "cityName": "São Bento do Trairi",
    "stateId": 5253
  },
  {
    "cityId": 7264,
    "cityName": "São Bernardo",
    "stateId": 5253
  },
  {
    "cityId": 7265,
    "cityName": "São Fernando",
    "stateId": 5253
  },
  {
    "cityId": 7266,
    "cityName": "São Francisco do Oeste",
    "stateId": 5253
  },
  {
    "cityId": 7267,
    "cityName": "São Geraldo",
    "stateId": 5253
  },
  {
    "cityId": 7268,
    "cityName": "São Gonçalo do Amarante",
    "stateId": 5253
  },
  {
    "cityId": 7269,
    "cityName": "São João do Sabugi",
    "stateId": 5253
  },
  {
    "cityId": 7270,
    "cityName": "São José da Passagem",
    "stateId": 5253
  },
  {
    "cityId": 7271,
    "cityName": "São José de Mipibu",
    "stateId": 5253
  },
  {
    "cityId": 7272,
    "cityName": "São José do Campestre",
    "stateId": 5253
  },
  {
    "cityId": 7273,
    "cityName": "São José do Seridó",
    "stateId": 5253
  },
  {
    "cityId": 7274,
    "cityName": "São Miguel",
    "stateId": 5253
  },
  {
    "cityId": 7275,
    "cityName": "São Miguel de Touros",
    "stateId": 5253
  },
  {
    "cityId": 7276,
    "cityName": "São Paulo do Potengi",
    "stateId": 5253
  },
  {
    "cityId": 7277,
    "cityName": "São Pedro",
    "stateId": 5253
  },
  {
    "cityId": 7278,
    "cityName": "São Rafael",
    "stateId": 5253
  },
  {
    "cityId": 7279,
    "cityName": "São Tomé",
    "stateId": 5253
  },
  {
    "cityId": 7280,
    "cityName": "São Vicente",
    "stateId": 5253
  },
  {
    "cityId": 7281,
    "cityName": "Senador Elói de Souza",
    "stateId": 5253
  },
  {
    "cityId": 7282,
    "cityName": "Senador Georgino Avelino",
    "stateId": 5253
  },
  {
    "cityId": 7283,
    "cityName": "Serra Caiada",
    "stateId": 5253
  },
  {
    "cityId": 7284,
    "cityName": "Serra da Tapuia",
    "stateId": 5253
  },
  {
    "cityId": 7285,
    "cityName": "Serra de São Bento",
    "stateId": 5253
  },
  {
    "cityId": 7286,
    "cityName": "Serra do Mel",
    "stateId": 5253
  },
  {
    "cityId": 7287,
    "cityName": "Serra Negra do Norte",
    "stateId": 5253
  },
  {
    "cityId": 7288,
    "cityName": "Serrinha",
    "stateId": 5253
  },
  {
    "cityId": 7289,
    "cityName": "Serrinha dos Pintos",
    "stateId": 5253
  },
  {
    "cityId": 7290,
    "cityName": "Severiano Melo",
    "stateId": 5253
  },
  {
    "cityId": 7291,
    "cityName": "Sítio Novo",
    "stateId": 5253
  },
  {
    "cityId": 7292,
    "cityName": "Taboleiro Grande",
    "stateId": 5253
  },
  {
    "cityId": 7293,
    "cityName": "Taipu",
    "stateId": 5253
  },
  {
    "cityId": 7294,
    "cityName": "Tangará",
    "stateId": 5253
  },
  {
    "cityId": 7295,
    "cityName": "Tenente Ananias",
    "stateId": 5253
  },
  {
    "cityId": 7296,
    "cityName": "Tenente Laurentino Cruz",
    "stateId": 5253
  },
  {
    "cityId": 7297,
    "cityName": "Tibau",
    "stateId": 5253
  },
  {
    "cityId": 7298,
    "cityName": "Tibau do Sul",
    "stateId": 5253
  },
  {
    "cityId": 7299,
    "cityName": "Timbaúba dos Batistas",
    "stateId": 5253
  },
  {
    "cityId": 7300,
    "cityName": "Touros",
    "stateId": 5253
  },
  {
    "cityId": 7301,
    "cityName": "Trairi",
    "stateId": 5253
  },
  {
    "cityId": 7302,
    "cityName": "Triunfo Potiguar",
    "stateId": 5253
  },
  {
    "cityId": 7303,
    "cityName": "Umarizal",
    "stateId": 5253
  },
  {
    "cityId": 7304,
    "cityName": "Upanema",
    "stateId": 5253
  },
  {
    "cityId": 7305,
    "cityName": "Várzea",
    "stateId": 5253
  },
  {
    "cityId": 7306,
    "cityName": "Venha-Ver",
    "stateId": 5253
  },
  {
    "cityId": 7307,
    "cityName": "Vera Cruz",
    "stateId": 5253
  },
  {
    "cityId": 7308,
    "cityName": "Viçosa",
    "stateId": 5253
  },
  {
    "cityId": 7309,
    "cityName": "Vila Flor",
    "stateId": 5253
  },
  {
    "cityId": 7310,
    "cityName": "Abunã",
    "stateId": 5254
  },
  {
    "cityId": 7311,
    "cityName": "Alto Alegre dos Parecis",
    "stateId": 5254
  },
  {
    "cityId": 7312,
    "cityName": "Alta Floresta D'Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7313,
    "cityName": "Alto Paraíso",
    "stateId": 5254
  },
  {
    "cityId": 7314,
    "cityName": "Alvorada D'Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7315,
    "cityName": "Ariquemes",
    "stateId": 5254
  },
  {
    "cityId": 7316,
    "cityName": "Buritis",
    "stateId": 5254
  },
  {
    "cityId": 7317,
    "cityName": "Cabixi",
    "stateId": 5254
  },
  {
    "cityId": 7318,
    "cityName": "Cacaulândia",
    "stateId": 5254
  },
  {
    "cityId": 7319,
    "cityName": "Cacoal",
    "stateId": 5254
  },
  {
    "cityId": 7320,
    "cityName": "Calama",
    "stateId": 5254
  },
  {
    "cityId": 7321,
    "cityName": "Campo Novo de Rondônia",
    "stateId": 5254
  },
  {
    "cityId": 7322,
    "cityName": "Candeias do Jamari",
    "stateId": 5254
  },
  {
    "cityId": 7323,
    "cityName": "Castanheiras",
    "stateId": 5254
  },
  {
    "cityId": 7324,
    "cityName": "Cerejeiras",
    "stateId": 5254
  },
  {
    "cityId": 7325,
    "cityName": "Chupinguaia",
    "stateId": 5254
  },
  {
    "cityId": 7326,
    "cityName": "Colorado do Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7327,
    "cityName": "Corumbiara",
    "stateId": 5254
  },
  {
    "cityId": 7328,
    "cityName": "Costa Marques",
    "stateId": 5254
  },
  {
    "cityId": 7329,
    "cityName": "Cujubim",
    "stateId": 5254
  },
  {
    "cityId": 7330,
    "cityName": "Espigão do Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7331,
    "cityName": "Governador Jorge Teixeira",
    "stateId": 5254
  },
  {
    "cityId": 7332,
    "cityName": "Guajará-Mirim",
    "stateId": 5254
  },
  {
    "cityId": 7333,
    "cityName": "Jaci Paraná",
    "stateId": 5254
  },
  {
    "cityId": 7334,
    "cityName": "Itapuã do Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7335,
    "cityName": "Jaru",
    "stateId": 5254
  },
  {
    "cityId": 7336,
    "cityName": "Ji-Paraná",
    "stateId": 5254
  },
  {
    "cityId": 7337,
    "cityName": "Machadinho D'Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7338,
    "cityName": "Marco Rondon",
    "stateId": 5254
  },
  {
    "cityId": 7339,
    "cityName": "Ministro Andreazza",
    "stateId": 5254
  },
  {
    "cityId": 7340,
    "cityName": "Mirante da Serra",
    "stateId": 5254
  },
  {
    "cityId": 7341,
    "cityName": "Monte Negro",
    "stateId": 5254
  },
  {
    "cityId": 7342,
    "cityName": "Nova Brasilândia D'Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7343,
    "cityName": "Nova Mamoré",
    "stateId": 5254
  },
  {
    "cityId": 7344,
    "cityName": "Nova União",
    "stateId": 5254
  },
  {
    "cityId": 7345,
    "cityName": "Nova Vida",
    "stateId": 5254
  },
  {
    "cityId": 7346,
    "cityName": "Novo Horizonte do Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7347,
    "cityName": "Ouro Preto do Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7348,
    "cityName": "Parecis",
    "stateId": 5254
  },
  {
    "cityId": 7349,
    "cityName": "Pedras Negras",
    "stateId": 5254
  },
  {
    "cityId": 7350,
    "cityName": "Pimenta Bueno",
    "stateId": 5254
  },
  {
    "cityId": 7351,
    "cityName": "Pimenteiras do Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7352,
    "cityName": "Porto Velho",
    "stateId": 5254
  },
  {
    "cityId": 7353,
    "cityName": "Presidente Médici",
    "stateId": 5254
  },
  {
    "cityId": 7354,
    "cityName": "Primavera de Rondônia",
    "stateId": 5254
  },
  {
    "cityId": 7355,
    "cityName": "Príncipe da Beira",
    "stateId": 5254
  },
  {
    "cityId": 7356,
    "cityName": "Rio Crespo",
    "stateId": 5254
  },
  {
    "cityId": 7357,
    "cityName": "Riozinho",
    "stateId": 5254
  },
  {
    "cityId": 7358,
    "cityName": "Rolim de Moura",
    "stateId": 5254
  },
  {
    "cityId": 7359,
    "cityName": "Santa Luzia D'Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7360,
    "cityName": "São Felipe D'Oeste",
    "stateId": 5254
  },
  {
    "cityId": 7361,
    "cityName": "São Francisco do Guaporé",
    "stateId": 5254
  },
  {
    "cityId": 7362,
    "cityName": "São Miguel do Guaporé",
    "stateId": 5254
  },
  {
    "cityId": 7363,
    "cityName": "Seringueiras",
    "stateId": 5254
  },
  {
    "cityId": 7364,
    "cityName": "Tabajara",
    "stateId": 5254
  },
  {
    "cityId": 7365,
    "cityName": "Teixeirópolis",
    "stateId": 5254
  },
  {
    "cityId": 7366,
    "cityName": "Theobroma",
    "stateId": 5254
  },
  {
    "cityId": 7367,
    "cityName": "Urupá",
    "stateId": 5254
  },
  {
    "cityId": 7368,
    "cityName": "Vale do Anari",
    "stateId": 5254
  },
  {
    "cityId": 7369,
    "cityName": "Vale do Paraíso",
    "stateId": 5254
  },
  {
    "cityId": 7370,
    "cityName": "Extrema",
    "stateId": 5254
  },
  {
    "cityId": 7371,
    "cityName": "Vilhena",
    "stateId": 5254
  },
  {
    "cityId": 7372,
    "cityName": "Vista Alegre do Abunã",
    "stateId": 5254
  },
  {
    "cityId": 7373,
    "cityName": "Alto Alegre",
    "stateId": 5255
  },
  {
    "cityId": 7374,
    "cityName": "Amajari",
    "stateId": 5255
  },
  {
    "cityId": 7375,
    "cityName": "Boa Vista",
    "stateId": 5255
  },
  {
    "cityId": 7376,
    "cityName": "Bonfim",
    "stateId": 5255
  },
  {
    "cityId": 7377,
    "cityName": "Cantá",
    "stateId": 5255
  },
  {
    "cityId": 7378,
    "cityName": "Caracaraí",
    "stateId": 5255
  },
  {
    "cityId": 7379,
    "cityName": "Caroebe",
    "stateId": 5255
  },
  {
    "cityId": 7380,
    "cityName": "Iracema",
    "stateId": 5255
  },
  {
    "cityId": 7381,
    "cityName": "Mucajaí",
    "stateId": 5255
  },
  {
    "cityId": 7382,
    "cityName": "Normandia",
    "stateId": 5255
  },
  {
    "cityId": 7383,
    "cityName": "Pacaraima",
    "stateId": 5255
  },
  {
    "cityId": 7384,
    "cityName": "Rorainópolis",
    "stateId": 5255
  },
  {
    "cityId": 7385,
    "cityName": "São João da Baliza",
    "stateId": 5255
  },
  {
    "cityId": 7386,
    "cityName": "São Luiz",
    "stateId": 5255
  },
  {
    "cityId": 7387,
    "cityName": "Uiramutã",
    "stateId": 5255
  },
  {
    "cityId": 7388,
    "cityName": "Aceguá",
    "stateId": 5256
  },
  {
    "cityId": 7389,
    "cityName": "Afonso Rodrigues",
    "stateId": 5256
  },
  {
    "cityId": 7390,
    "cityName": "Água Santa",
    "stateId": 5256
  },
  {
    "cityId": 7391,
    "cityName": "Águas Claras",
    "stateId": 5256
  },
  {
    "cityId": 7392,
    "cityName": "Agudo",
    "stateId": 5256
  },
  {
    "cityId": 7393,
    "cityName": "Ajuricaba",
    "stateId": 5256
  },
  {
    "cityId": 7394,
    "cityName": "Albardão",
    "stateId": 5256
  },
  {
    "cityId": 7395,
    "cityName": "Alecrim",
    "stateId": 5256
  },
  {
    "cityId": 7396,
    "cityName": "Alegrete",
    "stateId": 5256
  },
  {
    "cityId": 7397,
    "cityName": "Alegria",
    "stateId": 5256
  },
  {
    "cityId": 7398,
    "cityName": "Alfredo Brenner",
    "stateId": 5256
  },
  {
    "cityId": 7399,
    "cityName": "Almirante Tamandaré do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7400,
    "cityName": "Alpestre",
    "stateId": 5256
  },
  {
    "cityId": 7401,
    "cityName": "Alto Alegre",
    "stateId": 5256
  },
  {
    "cityId": 7402,
    "cityName": "Alto Alegre",
    "stateId": 5256
  },
  {
    "cityId": 7403,
    "cityName": "Alto da União",
    "stateId": 5256
  },
  {
    "cityId": 7404,
    "cityName": "Alto Feliz",
    "stateId": 5256
  },
  {
    "cityId": 7405,
    "cityName": "Alto Paredão",
    "stateId": 5256
  },
  {
    "cityId": 7406,
    "cityName": "Alto Recreio",
    "stateId": 5256
  },
  {
    "cityId": 7407,
    "cityName": "Alto Uruguai",
    "stateId": 5256
  },
  {
    "cityId": 7408,
    "cityName": "Alvorada",
    "stateId": 5256
  },
  {
    "cityId": 7409,
    "cityName": "Amaral Ferrador",
    "stateId": 5256
  },
  {
    "cityId": 7410,
    "cityName": "Ametista do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7411,
    "cityName": "André da Rocha",
    "stateId": 5256
  },
  {
    "cityId": 7412,
    "cityName": "Anta Gorda",
    "stateId": 5256
  },
  {
    "cityId": 7413,
    "cityName": "Antônio Kerpel",
    "stateId": 5256
  },
  {
    "cityId": 7414,
    "cityName": "Antônio Prado",
    "stateId": 5256
  },
  {
    "cityId": 7415,
    "cityName": "Arambaré",
    "stateId": 5256
  },
  {
    "cityId": 7416,
    "cityName": "Araricá",
    "stateId": 5256
  },
  {
    "cityId": 7417,
    "cityName": "Aratiba",
    "stateId": 5256
  },
  {
    "cityId": 7418,
    "cityName": "Arco Verde",
    "stateId": 5256
  },
  {
    "cityId": 7419,
    "cityName": "Arco-Íris",
    "stateId": 5256
  },
  {
    "cityId": 7420,
    "cityName": "Arroio Canoas",
    "stateId": 5256
  },
  {
    "cityId": 7421,
    "cityName": "Arroio do Meio",
    "stateId": 5256
  },
  {
    "cityId": 7422,
    "cityName": "Arroio do Padre",
    "stateId": 5256
  },
  {
    "cityId": 7423,
    "cityName": "Arroio do Sal",
    "stateId": 5256
  },
  {
    "cityId": 7424,
    "cityName": "Arroio do Só",
    "stateId": 5256
  },
  {
    "cityId": 7425,
    "cityName": "Arroio do Tigre",
    "stateId": 5256
  },
  {
    "cityId": 7426,
    "cityName": "Arroio dos Ratos",
    "stateId": 5256
  },
  {
    "cityId": 7427,
    "cityName": "Arroio Grande",
    "stateId": 5256
  },
  {
    "cityId": 7428,
    "cityName": "Arroio Grande",
    "stateId": 5256
  },
  {
    "cityId": 7429,
    "cityName": "Arroio Grande",
    "stateId": 5256
  },
  {
    "cityId": 7430,
    "cityName": "Árvore Só",
    "stateId": 5256
  },
  {
    "cityId": 7431,
    "cityName": "Arvorezinha",
    "stateId": 5256
  },
  {
    "cityId": 7432,
    "cityName": "Atafona",
    "stateId": 5256
  },
  {
    "cityId": 7433,
    "cityName": "Atiaçu",
    "stateId": 5256
  },
  {
    "cityId": 7434,
    "cityName": "Augusto Pestana",
    "stateId": 5256
  },
  {
    "cityId": 7435,
    "cityName": "Áurea",
    "stateId": 5256
  },
  {
    "cityId": 7436,
    "cityName": "Avelino Paranhos",
    "stateId": 5256
  },
  {
    "cityId": 7437,
    "cityName": "Azevedo Sodré",
    "stateId": 5256
  },
  {
    "cityId": 7438,
    "cityName": "Bacupari",
    "stateId": 5256
  },
  {
    "cityId": 7439,
    "cityName": "Bagé",
    "stateId": 5256
  },
  {
    "cityId": 7440,
    "cityName": "Baliza",
    "stateId": 5256
  },
  {
    "cityId": 7441,
    "cityName": "Balneário Pinhal",
    "stateId": 5256
  },
  {
    "cityId": 7442,
    "cityName": "Banhado do Colégio",
    "stateId": 5256
  },
  {
    "cityId": 7443,
    "cityName": "Barão",
    "stateId": 5256
  },
  {
    "cityId": 7444,
    "cityName": "Barão de Cotegipe",
    "stateId": 5256
  },
  {
    "cityId": 7445,
    "cityName": "Barão do Triunfo",
    "stateId": 5256
  },
  {
    "cityId": 7446,
    "cityName": "Barra do Guarita",
    "stateId": 5256
  },
  {
    "cityId": 7447,
    "cityName": "Barra do Ouro",
    "stateId": 5256
  },
  {
    "cityId": 7448,
    "cityName": "Barra do Quaraí",
    "stateId": 5256
  },
  {
    "cityId": 7449,
    "cityName": "Barra do Ribeiro",
    "stateId": 5256
  },
  {
    "cityId": 7450,
    "cityName": "Barra do Rio Azul",
    "stateId": 5256
  },
  {
    "cityId": 7451,
    "cityName": "Barra Funda",
    "stateId": 5256
  },
  {
    "cityId": 7452,
    "cityName": "Barracão",
    "stateId": 5256
  },
  {
    "cityId": 7453,
    "cityName": "Barreirinho",
    "stateId": 5256
  },
  {
    "cityId": 7454,
    "cityName": "Barreiro",
    "stateId": 5256
  },
  {
    "cityId": 7455,
    "cityName": "Barro Preto",
    "stateId": 5256
  },
  {
    "cityId": 7456,
    "cityName": "Barro Vermelho",
    "stateId": 5256
  },
  {
    "cityId": 7457,
    "cityName": "Barro Vermelho",
    "stateId": 5256
  },
  {
    "cityId": 7458,
    "cityName": "Barros Cassal",
    "stateId": 5256
  },
  {
    "cityId": 7459,
    "cityName": "Basílio",
    "stateId": 5256
  },
  {
    "cityId": 7460,
    "cityName": "Bela Vista",
    "stateId": 5256
  },
  {
    "cityId": 7461,
    "cityName": "Bela Vista",
    "stateId": 5256
  },
  {
    "cityId": 7462,
    "cityName": "Bela Vista",
    "stateId": 5256
  },
  {
    "cityId": 7463,
    "cityName": "Bela Vista",
    "stateId": 5256
  },
  {
    "cityId": 7464,
    "cityName": "Beluno",
    "stateId": 5256
  },
  {
    "cityId": 7465,
    "cityName": "Benjamin Constant do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7466,
    "cityName": "Bento Gonçalves",
    "stateId": 5256
  },
  {
    "cityId": 7467,
    "cityName": "Bexiga",
    "stateId": 5256
  },
  {
    "cityId": 7468,
    "cityName": "Boa Esperança",
    "stateId": 5256
  },
  {
    "cityId": 7469,
    "cityName": "Boa Vista",
    "stateId": 5256
  },
  {
    "cityId": 7470,
    "cityName": "Boa Vista",
    "stateId": 5256
  },
  {
    "cityId": 7471,
    "cityName": "Boa Vista",
    "stateId": 5256
  },
  {
    "cityId": 7472,
    "cityName": "Boa Vista das Missões",
    "stateId": 5256
  },
  {
    "cityId": 7473,
    "cityName": "Boa Vista do Buricá",
    "stateId": 5256
  },
  {
    "cityId": 7474,
    "cityName": "Boa Vista do Cadeado",
    "stateId": 5256
  },
  {
    "cityId": 7475,
    "cityName": "Boa Vista do Incra",
    "stateId": 5256
  },
  {
    "cityId": 7476,
    "cityName": "Boa Vista do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7477,
    "cityName": "Boca do Monte",
    "stateId": 5256
  },
  {
    "cityId": 7478,
    "cityName": "Boi Preto",
    "stateId": 5256
  },
  {
    "cityId": 7479,
    "cityName": "Bojuru",
    "stateId": 5256
  },
  {
    "cityId": 7480,
    "cityName": "Bom Jardim",
    "stateId": 5256
  },
  {
    "cityId": 7481,
    "cityName": "Bom Jesus",
    "stateId": 5256
  },
  {
    "cityId": 7482,
    "cityName": "Bom Princípio",
    "stateId": 5256
  },
  {
    "cityId": 7483,
    "cityName": "Bom Progresso",
    "stateId": 5256
  },
  {
    "cityId": 7484,
    "cityName": "Bom Retiro",
    "stateId": 5256
  },
  {
    "cityId": 7485,
    "cityName": "Bom Retiro do Guaíba",
    "stateId": 5256
  },
  {
    "cityId": 7486,
    "cityName": "Bom Retiro do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7487,
    "cityName": "Bonito",
    "stateId": 5256
  },
  {
    "cityId": 7488,
    "cityName": "Boqueirão",
    "stateId": 5256
  },
  {
    "cityId": 7489,
    "cityName": "Vila Boqueirão",
    "stateId": 5256
  },
  {
    "cityId": 7490,
    "cityName": "Boqueirão do Leão",
    "stateId": 5256
  },
  {
    "cityId": 7491,
    "cityName": "Bororé",
    "stateId": 5256
  },
  {
    "cityId": 7492,
    "cityName": "Bossoroca",
    "stateId": 5256
  },
  {
    "cityId": 7493,
    "cityName": "Botucaraí",
    "stateId": 5256
  },
  {
    "cityId": 7494,
    "cityName": "Braga",
    "stateId": 5256
  },
  {
    "cityId": 7495,
    "cityName": "Brochier",
    "stateId": 5256
  },
  {
    "cityId": 7496,
    "cityName": "Buriti",
    "stateId": 5256
  },
  {
    "cityId": 7497,
    "cityName": "Butiá",
    "stateId": 5256
  },
  {
    "cityId": 7498,
    "cityName": "Butiás",
    "stateId": 5256
  },
  {
    "cityId": 7499,
    "cityName": "Caçapava do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7500,
    "cityName": "Cacequi",
    "stateId": 5256
  },
  {
    "cityId": 7501,
    "cityName": "Cachoeira do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7502,
    "cityName": "Cachoeirinha",
    "stateId": 5256
  },
  {
    "cityId": 7503,
    "cityName": "Cacique Doble",
    "stateId": 5256
  },
  {
    "cityId": 7504,
    "cityName": "Cadorna",
    "stateId": 5256
  },
  {
    "cityId": 7505,
    "cityName": "Caibaté",
    "stateId": 5256
  },
  {
    "cityId": 7506,
    "cityName": "Caiçara",
    "stateId": 5256
  },
  {
    "cityId": 7507,
    "cityName": "Camaquã",
    "stateId": 5256
  },
  {
    "cityId": 7508,
    "cityName": "Camargo",
    "stateId": 5256
  },
  {
    "cityId": 7509,
    "cityName": "Cambará do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7510,
    "cityName": "Camobi",
    "stateId": 5256
  },
  {
    "cityId": 7511,
    "cityName": "Campestre Baixo",
    "stateId": 5256
  },
  {
    "cityId": 7512,
    "cityName": "Campestre da Serra",
    "stateId": 5256
  },
  {
    "cityId": 7513,
    "cityName": "Campina das Missões",
    "stateId": 5256
  },
  {
    "cityId": 7514,
    "cityName": "Campina Redonda",
    "stateId": 5256
  },
  {
    "cityId": 7515,
    "cityName": "Campinas",
    "stateId": 5256
  },
  {
    "cityId": 7516,
    "cityName": "Campinas do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7517,
    "cityName": "Campo Bom",
    "stateId": 5256
  },
  {
    "cityId": 7518,
    "cityName": "Campo Branco",
    "stateId": 5256
  },
  {
    "cityId": 7519,
    "cityName": "Campo do Meio",
    "stateId": 5256
  },
  {
    "cityId": 7520,
    "cityName": "Campo Novo",
    "stateId": 5256
  },
  {
    "cityId": 7521,
    "cityName": "Campo Novo",
    "stateId": 5256
  },
  {
    "cityId": 7522,
    "cityName": "Campo Santo",
    "stateId": 5256
  },
  {
    "cityId": 7523,
    "cityName": "Campo Seco",
    "stateId": 5256
  },
  {
    "cityId": 7524,
    "cityName": "Campo Vicente",
    "stateId": 5256
  },
  {
    "cityId": 7525,
    "cityName": "Campos Borges",
    "stateId": 5256
  },
  {
    "cityId": 7526,
    "cityName": "Candelária",
    "stateId": 5256
  },
  {
    "cityId": 7527,
    "cityName": "Cândido Freire",
    "stateId": 5256
  },
  {
    "cityId": 7528,
    "cityName": "Cândido Godói",
    "stateId": 5256
  },
  {
    "cityId": 7529,
    "cityName": "Candiota",
    "stateId": 5256
  },
  {
    "cityId": 7530,
    "cityName": "Canela",
    "stateId": 5256
  },
  {
    "cityId": 7531,
    "cityName": "Canguçu",
    "stateId": 5256
  },
  {
    "cityId": 7532,
    "cityName": "Canhembora",
    "stateId": 5256
  },
  {
    "cityId": 7533,
    "cityName": "Canoas",
    "stateId": 5256
  },
  {
    "cityId": 7534,
    "cityName": "Canudos do Vale",
    "stateId": 5256
  },
  {
    "cityId": 7535,
    "cityName": "Capané",
    "stateId": 5256
  },
  {
    "cityId": 7536,
    "cityName": "Capão Bonito do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7537,
    "cityName": "Capão Bonito",
    "stateId": 5256
  },
  {
    "cityId": 7538,
    "cityName": "Capão Comprido",
    "stateId": 5256
  },
  {
    "cityId": 7539,
    "cityName": "Capão da Canoa",
    "stateId": 5256
  },
  {
    "cityId": 7540,
    "cityName": "Capão da Porteira",
    "stateId": 5256
  },
  {
    "cityId": 7541,
    "cityName": "Capão do Cedro",
    "stateId": 5256
  },
  {
    "cityId": 7542,
    "cityName": "Capão do Cipó",
    "stateId": 5256
  },
  {
    "cityId": 7543,
    "cityName": "Capão do Leão",
    "stateId": 5256
  },
  {
    "cityId": 7544,
    "cityName": "Capela de Santana",
    "stateId": 5256
  },
  {
    "cityId": 7545,
    "cityName": "Capela Velha",
    "stateId": 5256
  },
  {
    "cityId": 7546,
    "cityName": "Capinzal",
    "stateId": 5256
  },
  {
    "cityId": 7547,
    "cityName": "Capitão",
    "stateId": 5256
  },
  {
    "cityId": 7548,
    "cityName": "Capivari do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7549,
    "cityName": "Capivarita",
    "stateId": 5256
  },
  {
    "cityId": 7550,
    "cityName": "Capo-Erê",
    "stateId": 5256
  },
  {
    "cityId": 7551,
    "cityName": "Capoeira Grande",
    "stateId": 5256
  },
  {
    "cityId": 7552,
    "cityName": "Caraá",
    "stateId": 5256
  },
  {
    "cityId": 7553,
    "cityName": "Carajá Seival",
    "stateId": 5256
  },
  {
    "cityId": 7554,
    "cityName": "Carazinho",
    "stateId": 5256
  },
  {
    "cityId": 7555,
    "cityName": "Carlos Barbosa",
    "stateId": 5256
  },
  {
    "cityId": 7556,
    "cityName": "Carlos Gomes",
    "stateId": 5256
  },
  {
    "cityId": 7557,
    "cityName": "Carovi",
    "stateId": 5256
  },
  {
    "cityId": 7558,
    "cityName": "Casca",
    "stateId": 5256
  },
  {
    "cityId": 7559,
    "cityName": "Cascata",
    "stateId": 5256
  },
  {
    "cityId": 7560,
    "cityName": "Cascata",
    "stateId": 5256
  },
  {
    "cityId": 7561,
    "cityName": "Caseiros",
    "stateId": 5256
  },
  {
    "cityId": 7562,
    "cityName": "Castelinho",
    "stateId": 5256
  },
  {
    "cityId": 7563,
    "cityName": "Catuípe",
    "stateId": 5256
  },
  {
    "cityId": 7564,
    "cityName": "Cavajureta",
    "stateId": 5256
  },
  {
    "cityId": 7565,
    "cityName": "Caverá",
    "stateId": 5256
  },
  {
    "cityId": 7566,
    "cityName": "Caxias do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7567,
    "cityName": "Cazuza Ferreira",
    "stateId": 5256
  },
  {
    "cityId": 7568,
    "cityName": "Cedro Marcado",
    "stateId": 5256
  },
  {
    "cityId": 7569,
    "cityName": "Centenário",
    "stateId": 5256
  },
  {
    "cityId": 7570,
    "cityName": "Centenário",
    "stateId": 5256
  },
  {
    "cityId": 7571,
    "cityName": "Centro Linha Brasil",
    "stateId": 5256
  },
  {
    "cityId": 7572,
    "cityName": "Cerrito",
    "stateId": 5256
  },
  {
    "cityId": 7573,
    "cityName": "Cerrito Alegre",
    "stateId": 5256
  },
  {
    "cityId": 7574,
    "cityName": "Cerrito do Ouro ou Vila do Cerrito",
    "stateId": 5256
  },
  {
    "cityId": 7575,
    "cityName": "Cerro Alto",
    "stateId": 5256
  },
  {
    "cityId": 7576,
    "cityName": "Cerro Branco",
    "stateId": 5256
  },
  {
    "cityId": 7577,
    "cityName": "Cerro Claro",
    "stateId": 5256
  },
  {
    "cityId": 7578,
    "cityName": "Cerro do Martins",
    "stateId": 5256
  },
  {
    "cityId": 7579,
    "cityName": "Cerro do Roque",
    "stateId": 5256
  },
  {
    "cityId": 7580,
    "cityName": "Cerro Grande",
    "stateId": 5256
  },
  {
    "cityId": 7581,
    "cityName": "Cerro Grande do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7582,
    "cityName": "Cerro Largo",
    "stateId": 5256
  },
  {
    "cityId": 7583,
    "cityName": "Chapada",
    "stateId": 5256
  },
  {
    "cityId": 7584,
    "cityName": "Chapada",
    "stateId": 5256
  },
  {
    "cityId": 7585,
    "cityName": "Charqueadas",
    "stateId": 5256
  },
  {
    "cityId": 7586,
    "cityName": "Charrua",
    "stateId": 5256
  },
  {
    "cityId": 7587,
    "cityName": "Chiapetta",
    "stateId": 5256
  },
  {
    "cityId": 7588,
    "cityName": "Chicolomã",
    "stateId": 5256
  },
  {
    "cityId": 7589,
    "cityName": "Chimarrão",
    "stateId": 5256
  },
  {
    "cityId": 7590,
    "cityName": "Chorão",
    "stateId": 5256
  },
  {
    "cityId": 7591,
    "cityName": "Chuí",
    "stateId": 5256
  },
  {
    "cityId": 7592,
    "cityName": "Chuvisca",
    "stateId": 5256
  },
  {
    "cityId": 7593,
    "cityName": "Cidreira",
    "stateId": 5256
  },
  {
    "cityId": 7594,
    "cityName": "Cinqüentenário",
    "stateId": 5256
  },
  {
    "cityId": 7595,
    "cityName": "Ciríaco",
    "stateId": 5256
  },
  {
    "cityId": 7596,
    "cityName": "Clara",
    "stateId": 5256
  },
  {
    "cityId": 7597,
    "cityName": "Clemente Argolo",
    "stateId": 5256
  },
  {
    "cityId": 7598,
    "cityName": "Coimbra",
    "stateId": 5256
  },
  {
    "cityId": 7599,
    "cityName": "Colinas",
    "stateId": 5256
  },
  {
    "cityId": 7600,
    "cityName": "Colônia das Almas",
    "stateId": 5256
  },
  {
    "cityId": 7601,
    "cityName": "Colônia Medeiros",
    "stateId": 5256
  },
  {
    "cityId": 7602,
    "cityName": "Colônia Municipal",
    "stateId": 5256
  },
  {
    "cityId": 7603,
    "cityName": "Colônia Nova",
    "stateId": 5256
  },
  {
    "cityId": 7604,
    "cityName": "Colônia São João",
    "stateId": 5256
  },
  {
    "cityId": 7605,
    "cityName": "Colônia Z-3",
    "stateId": 5256
  },
  {
    "cityId": 7606,
    "cityName": "Coloninha",
    "stateId": 5256
  },
  {
    "cityId": 7607,
    "cityName": "Colorado",
    "stateId": 5256
  },
  {
    "cityId": 7608,
    "cityName": "Comandai",
    "stateId": 5256
  },
  {
    "cityId": 7609,
    "cityName": "Condor",
    "stateId": 5256
  },
  {
    "cityId": 7610,
    "cityName": "Consolata",
    "stateId": 5256
  },
  {
    "cityId": 7611,
    "cityName": "Constantina",
    "stateId": 5256
  },
  {
    "cityId": 7612,
    "cityName": "Coqueiro Baixo",
    "stateId": 5256
  },
  {
    "cityId": 7613,
    "cityName": "Coqueiros do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7614,
    "cityName": "Cordilheira",
    "stateId": 5256
  },
  {
    "cityId": 7615,
    "cityName": "Coroados",
    "stateId": 5256
  },
  {
    "cityId": 7616,
    "cityName": "Coronel Barros",
    "stateId": 5256
  },
  {
    "cityId": 7617,
    "cityName": "Coronel Bicaco",
    "stateId": 5256
  },
  {
    "cityId": 7618,
    "cityName": "Coronel Finzito",
    "stateId": 5256
  },
  {
    "cityId": 7619,
    "cityName": "Coronel Pilar",
    "stateId": 5256
  },
  {
    "cityId": 7620,
    "cityName": "Coronel Teixeira",
    "stateId": 5256
  },
  {
    "cityId": 7621,
    "cityName": "Cortado",
    "stateId": 5256
  },
  {
    "cityId": 7622,
    "cityName": "Costa da Cadeia",
    "stateId": 5256
  },
  {
    "cityId": 7623,
    "cityName": "Costão",
    "stateId": 5256
  },
  {
    "cityId": 7624,
    "cityName": "Cotiporã",
    "stateId": 5256
  },
  {
    "cityId": 7625,
    "cityName": "Coxilha",
    "stateId": 5256
  },
  {
    "cityId": 7626,
    "cityName": "Coxilha Grande",
    "stateId": 5256
  },
  {
    "cityId": 7627,
    "cityName": "Cr-1",
    "stateId": 5256
  },
  {
    "cityId": 7628,
    "cityName": "Crissiumal",
    "stateId": 5256
  },
  {
    "cityId": 7629,
    "cityName": "Cristal",
    "stateId": 5256
  },
  {
    "cityId": 7630,
    "cityName": "Cristal do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7631,
    "cityName": "Criúva",
    "stateId": 5256
  },
  {
    "cityId": 7632,
    "cityName": "Cruz Alta",
    "stateId": 5256
  },
  {
    "cityId": 7633,
    "cityName": "Cruzaltense",
    "stateId": 5256
  },
  {
    "cityId": 7634,
    "cityName": "Cruzeiro",
    "stateId": 5256
  },
  {
    "cityId": 7635,
    "cityName": "Cruzeiro do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7636,
    "cityName": "Curral Alto",
    "stateId": 5256
  },
  {
    "cityId": 7637,
    "cityName": "Curumim",
    "stateId": 5256
  },
  {
    "cityId": 7638,
    "cityName": "Daltro Filho",
    "stateId": 5256
  },
  {
    "cityId": 7639,
    "cityName": "Daltro Filho",
    "stateId": 5256
  },
  {
    "cityId": 7640,
    "cityName": "Daltro Filho",
    "stateId": 5256
  },
  {
    "cityId": 7641,
    "cityName": "Dário Lassance",
    "stateId": 5256
  },
  {
    "cityId": 7642,
    "cityName": "David Canabarro",
    "stateId": 5256
  },
  {
    "cityId": 7643,
    "cityName": "Delfina",
    "stateId": 5256
  },
  {
    "cityId": 7644,
    "cityName": "Deodoro",
    "stateId": 5256
  },
  {
    "cityId": 7645,
    "cityName": "Depósito",
    "stateId": 5256
  },
  {
    "cityId": 7646,
    "cityName": "Derrubadas",
    "stateId": 5256
  },
  {
    "cityId": 7647,
    "cityName": "Dezesseis de Novembro",
    "stateId": 5256
  },
  {
    "cityId": 7648,
    "cityName": "Dilermando de Aguiar",
    "stateId": 5256
  },
  {
    "cityId": 7649,
    "cityName": "Divino",
    "stateId": 5256
  },
  {
    "cityId": 7650,
    "cityName": "Dois Irmãos",
    "stateId": 5256
  },
  {
    "cityId": 7651,
    "cityName": "Dois Irmãos das Missões",
    "stateId": 5256
  },
  {
    "cityId": 7652,
    "cityName": "Dois Lajeados",
    "stateId": 5256
  },
  {
    "cityId": 7653,
    "cityName": "São José do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7654,
    "cityName": "Dom Feliciano",
    "stateId": 5256
  },
  {
    "cityId": 7655,
    "cityName": "Dom Feliciano",
    "stateId": 5256
  },
  {
    "cityId": 7656,
    "cityName": "Dom Pedrito",
    "stateId": 5256
  },
  {
    "cityId": 7657,
    "cityName": "Dom Pedro de Alcântara",
    "stateId": 5256
  },
  {
    "cityId": 7658,
    "cityName": "Dona Francisca",
    "stateId": 5256
  },
  {
    "cityId": 7659,
    "cityName": "Dona Otília",
    "stateId": 5256
  },
  {
    "cityId": 7660,
    "cityName": "Dourado",
    "stateId": 5256
  },
  {
    "cityId": 7661,
    "cityName": "Bozano",
    "stateId": 5256
  },
  {
    "cityId": 7662,
    "cityName": "Doutor Edgardo Pereira Velho",
    "stateId": 5256
  },
  {
    "cityId": 7663,
    "cityName": "Doutor Maurício Cardoso",
    "stateId": 5256
  },
  {
    "cityId": 7664,
    "cityName": "Doutor Ricardo",
    "stateId": 5256
  },
  {
    "cityId": 7665,
    "cityName": "Eldorado do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7666,
    "cityName": "Eletra",
    "stateId": 5256
  },
  {
    "cityId": 7667,
    "cityName": "Encantado",
    "stateId": 5256
  },
  {
    "cityId": 7668,
    "cityName": "Encruzilhada",
    "stateId": 5256
  },
  {
    "cityId": 7669,
    "cityName": "Encruzilhada do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7670,
    "cityName": "Engenho Velho",
    "stateId": 5256
  },
  {
    "cityId": 7671,
    "cityName": "Entre Rios do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7672,
    "cityName": "Entre-Ijuís",
    "stateId": 5256
  },
  {
    "cityId": 7673,
    "cityName": "Entrepelado",
    "stateId": 5256
  },
  {
    "cityId": 7674,
    "cityName": "Erebango",
    "stateId": 5256
  },
  {
    "cityId": 7675,
    "cityName": "Erechim",
    "stateId": 5256
  },
  {
    "cityId": 7676,
    "cityName": "Ernestina",
    "stateId": 5256
  },
  {
    "cityId": 7677,
    "cityName": "Ernesto Alves",
    "stateId": 5256
  },
  {
    "cityId": 7678,
    "cityName": "Erval Grande",
    "stateId": 5256
  },
  {
    "cityId": 7679,
    "cityName": "Erval Seco",
    "stateId": 5256
  },
  {
    "cityId": 7680,
    "cityName": "Erveiras",
    "stateId": 5256
  },
  {
    "cityId": 7681,
    "cityName": "Esmeralda",
    "stateId": 5256
  },
  {
    "cityId": 7682,
    "cityName": "Esperança do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7683,
    "cityName": "Espigão",
    "stateId": 5256
  },
  {
    "cityId": 7684,
    "cityName": "Espigão Alto",
    "stateId": 5256
  },
  {
    "cityId": 7685,
    "cityName": "Espinilho Grande",
    "stateId": 5256
  },
  {
    "cityId": 7686,
    "cityName": "Espírito Santo",
    "stateId": 5256
  },
  {
    "cityId": 7687,
    "cityName": "Espumoso",
    "stateId": 5256
  },
  {
    "cityId": 7688,
    "cityName": "Esquina Araújo",
    "stateId": 5256
  },
  {
    "cityId": 7689,
    "cityName": "Esquina Bom Sucesso",
    "stateId": 5256
  },
  {
    "cityId": 7690,
    "cityName": "Esquina Gaúcha",
    "stateId": 5256
  },
  {
    "cityId": 7691,
    "cityName": "Esquina Gaúcha",
    "stateId": 5256
  },
  {
    "cityId": 7692,
    "cityName": "Esquina Ipiranga",
    "stateId": 5256
  },
  {
    "cityId": 7693,
    "cityName": "Esquina Piratini",
    "stateId": 5256
  },
  {
    "cityId": 7694,
    "cityName": "Estação",
    "stateId": 5256
  },
  {
    "cityId": 7695,
    "cityName": "Estância Grande",
    "stateId": 5256
  },
  {
    "cityId": 7696,
    "cityName": "Estância Velha",
    "stateId": 5256
  },
  {
    "cityId": 7697,
    "cityName": "Esteio",
    "stateId": 5256
  },
  {
    "cityId": 7698,
    "cityName": "Esteira",
    "stateId": 5256
  },
  {
    "cityId": 7699,
    "cityName": "Estreito",
    "stateId": 5256
  },
  {
    "cityId": 7700,
    "cityName": "Estrela",
    "stateId": 5256
  },
  {
    "cityId": 7701,
    "cityName": "Estrela",
    "stateId": 5256
  },
  {
    "cityId": 7702,
    "cityName": "Estrela Velha",
    "stateId": 5256
  },
  {
    "cityId": 7703,
    "cityName": "Eugênio de Castro",
    "stateId": 5256
  },
  {
    "cityId": 7704,
    "cityName": "Evangelista",
    "stateId": 5256
  },
  {
    "cityId": 7705,
    "cityName": "Fagundes Varela",
    "stateId": 5256
  },
  {
    "cityId": 7706,
    "cityName": "Fão",
    "stateId": 5256
  },
  {
    "cityId": 7707,
    "cityName": "Faria Lemos",
    "stateId": 5256
  },
  {
    "cityId": 7708,
    "cityName": "Farinhas",
    "stateId": 5256
  },
  {
    "cityId": 7709,
    "cityName": "Farrapos",
    "stateId": 5256
  },
  {
    "cityId": 7710,
    "cityName": "Farroupilha",
    "stateId": 5256
  },
  {
    "cityId": 7711,
    "cityName": "Faxinal",
    "stateId": 5256
  },
  {
    "cityId": 7712,
    "cityName": "Faxinal do Soturno",
    "stateId": 5256
  },
  {
    "cityId": 7713,
    "cityName": "Faxinalzinho",
    "stateId": 5256
  },
  {
    "cityId": 7714,
    "cityName": "Fazenda Fialho",
    "stateId": 5256
  },
  {
    "cityId": 7715,
    "cityName": "Fazenda Souza",
    "stateId": 5256
  },
  {
    "cityId": 7716,
    "cityName": "Fazenda Vilanova",
    "stateId": 5256
  },
  {
    "cityId": 7717,
    "cityName": "Feliz",
    "stateId": 5256
  },
  {
    "cityId": 7718,
    "cityName": "Ferreira",
    "stateId": 5256
  },
  {
    "cityId": 7719,
    "cityName": "Flores da Cunha",
    "stateId": 5256
  },
  {
    "cityId": 7720,
    "cityName": "Floresta",
    "stateId": 5256
  },
  {
    "cityId": 7721,
    "cityName": "Floresta",
    "stateId": 5256
  },
  {
    "cityId": 7722,
    "cityName": "Floriano Peixoto",
    "stateId": 5256
  },
  {
    "cityId": 7723,
    "cityName": "Flórida",
    "stateId": 5256
  },
  {
    "cityId": 7724,
    "cityName": "Fontoura Xavier",
    "stateId": 5256
  },
  {
    "cityId": 7725,
    "cityName": "Formigueiro",
    "stateId": 5256
  },
  {
    "cityId": 7726,
    "cityName": "Formosa",
    "stateId": 5256
  },
  {
    "cityId": 7727,
    "cityName": "Forninho",
    "stateId": 5256
  },
  {
    "cityId": 7728,
    "cityName": "Forquetinha",
    "stateId": 5256
  },
  {
    "cityId": 7729,
    "cityName": "Fortaleza dos Valos",
    "stateId": 5256
  },
  {
    "cityId": 7730,
    "cityName": "Frederico Westphalen",
    "stateId": 5256
  },
  {
    "cityId": 7731,
    "cityName": "Frei Sebastião",
    "stateId": 5256
  },
  {
    "cityId": 7732,
    "cityName": "Freire",
    "stateId": 5256
  },
  {
    "cityId": 7733,
    "cityName": "Garibaldi",
    "stateId": 5256
  },
  {
    "cityId": 7734,
    "cityName": "Garibaldina",
    "stateId": 5256
  },
  {
    "cityId": 7735,
    "cityName": "Garruchos",
    "stateId": 5256
  },
  {
    "cityId": 7736,
    "cityName": "Gaurama",
    "stateId": 5256
  },
  {
    "cityId": 7737,
    "cityName": "General Câmara",
    "stateId": 5256
  },
  {
    "cityId": 7738,
    "cityName": "Gentil",
    "stateId": 5256
  },
  {
    "cityId": 7739,
    "cityName": "Getúlio Vargas",
    "stateId": 5256
  },
  {
    "cityId": 7740,
    "cityName": "Giruá",
    "stateId": 5256
  },
  {
    "cityId": 7741,
    "cityName": "Glória",
    "stateId": 5256
  },
  {
    "cityId": 7742,
    "cityName": "Glorinha",
    "stateId": 5256
  },
  {
    "cityId": 7743,
    "cityName": "Goio-En",
    "stateId": 5256
  },
  {
    "cityId": 7744,
    "cityName": "Gramado",
    "stateId": 5256
  },
  {
    "cityId": 7745,
    "cityName": "Gramado dos Loureiros",
    "stateId": 5256
  },
  {
    "cityId": 7746,
    "cityName": "Gramado São Pedro",
    "stateId": 5256
  },
  {
    "cityId": 7747,
    "cityName": "Gramado Xavier",
    "stateId": 5256
  },
  {
    "cityId": 7748,
    "cityName": "Gravataí",
    "stateId": 5256
  },
  {
    "cityId": 7749,
    "cityName": "Guabiju",
    "stateId": 5256
  },
  {
    "cityId": 7750,
    "cityName": "Guaíba",
    "stateId": 5256
  },
  {
    "cityId": 7751,
    "cityName": "Guajuviras",
    "stateId": 5256
  },
  {
    "cityId": 7752,
    "cityName": "Guaporé",
    "stateId": 5256
  },
  {
    "cityId": 7753,
    "cityName": "Guarani das Missões",
    "stateId": 5256
  },
  {
    "cityId": 7754,
    "cityName": "Guassupi",
    "stateId": 5256
  },
  {
    "cityId": 7755,
    "cityName": "Harmonia",
    "stateId": 5256
  },
  {
    "cityId": 7756,
    "cityName": "Herval",
    "stateId": 5256
  },
  {
    "cityId": 7757,
    "cityName": "Herveiras",
    "stateId": 5256
  },
  {
    "cityId": 7758,
    "cityName": "Hidráulica",
    "stateId": 5256
  },
  {
    "cityId": 7759,
    "cityName": "Horizontina",
    "stateId": 5256
  },
  {
    "cityId": 7760,
    "cityName": "Hulha Negra",
    "stateId": 5256
  },
  {
    "cityId": 7761,
    "cityName": "Humaitá",
    "stateId": 5256
  },
  {
    "cityId": 7762,
    "cityName": "Ibarama",
    "stateId": 5256
  },
  {
    "cityId": 7763,
    "cityName": "Ibaré",
    "stateId": 5256
  },
  {
    "cityId": 7764,
    "cityName": "Ibiaçá",
    "stateId": 5256
  },
  {
    "cityId": 7765,
    "cityName": "Ibiraiaras",
    "stateId": 5256
  },
  {
    "cityId": 7766,
    "cityName": "Ibirapuitã",
    "stateId": 5256
  },
  {
    "cityId": 7767,
    "cityName": "Ibirubá",
    "stateId": 5256
  },
  {
    "cityId": 7768,
    "cityName": "Igrejinha",
    "stateId": 5256
  },
  {
    "cityId": 7769,
    "cityName": "Igrejinha",
    "stateId": 5256
  },
  {
    "cityId": 7770,
    "cityName": "Ijucapirama",
    "stateId": 5256
  },
  {
    "cityId": 7771,
    "cityName": "Ijuí",
    "stateId": 5256
  },
  {
    "cityId": 7772,
    "cityName": "Ilha dos Marinheiros",
    "stateId": 5256
  },
  {
    "cityId": 7773,
    "cityName": "Ilópolis",
    "stateId": 5256
  },
  {
    "cityId": 7774,
    "cityName": "Imbé",
    "stateId": 5256
  },
  {
    "cityId": 7775,
    "cityName": "Imigrante",
    "stateId": 5256
  },
  {
    "cityId": 7776,
    "cityName": "Independência",
    "stateId": 5256
  },
  {
    "cityId": 7777,
    "cityName": "Inhacorá",
    "stateId": 5256
  },
  {
    "cityId": 7778,
    "cityName": "Ipê",
    "stateId": 5256
  },
  {
    "cityId": 7779,
    "cityName": "Ipiranga",
    "stateId": 5256
  },
  {
    "cityId": 7780,
    "cityName": "Ipiranga do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7781,
    "cityName": "Ipuaçu",
    "stateId": 5256
  },
  {
    "cityId": 7782,
    "cityName": "Iraí",
    "stateId": 5256
  },
  {
    "cityId": 7783,
    "cityName": "Iruí",
    "stateId": 5256
  },
  {
    "cityId": 7784,
    "cityName": "Itaara",
    "stateId": 5256
  },
  {
    "cityId": 7785,
    "cityName": "Itacolomi",
    "stateId": 5256
  },
  {
    "cityId": 7786,
    "cityName": "Itacurubi",
    "stateId": 5256
  },
  {
    "cityId": 7787,
    "cityName": "Itacurubi",
    "stateId": 5256
  },
  {
    "cityId": 7788,
    "cityName": "Itaí",
    "stateId": 5256
  },
  {
    "cityId": 7789,
    "cityName": "Itaimbezinho",
    "stateId": 5256
  },
  {
    "cityId": 7790,
    "cityName": "Itão",
    "stateId": 5256
  },
  {
    "cityId": 7791,
    "cityName": "Itapuã",
    "stateId": 5256
  },
  {
    "cityId": 7792,
    "cityName": "Itapucá",
    "stateId": 5256
  },
  {
    "cityId": 7793,
    "cityName": "Itapucá",
    "stateId": 5256
  },
  {
    "cityId": 7794,
    "cityName": "Itaqui",
    "stateId": 5256
  },
  {
    "cityId": 7795,
    "cityName": "Itati",
    "stateId": 5256
  },
  {
    "cityId": 7796,
    "cityName": "Itatiba do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7797,
    "cityName": "Itaúba",
    "stateId": 5256
  },
  {
    "cityId": 7798,
    "cityName": "Ituim",
    "stateId": 5256
  },
  {
    "cityId": 7799,
    "cityName": "Ivaí",
    "stateId": 5256
  },
  {
    "cityId": 7800,
    "cityName": "Ivorá",
    "stateId": 5256
  },
  {
    "cityId": 7801,
    "cityName": "Ivoti",
    "stateId": 5256
  },
  {
    "cityId": 7802,
    "cityName": "Jaboticaba",
    "stateId": 5256
  },
  {
    "cityId": 7803,
    "cityName": "Jacuizinho",
    "stateId": 5256
  },
  {
    "cityId": 7804,
    "cityName": "Jacutinga",
    "stateId": 5256
  },
  {
    "cityId": 7805,
    "cityName": "Jaguarão",
    "stateId": 5256
  },
  {
    "cityId": 7806,
    "cityName": "Jaguarete",
    "stateId": 5256
  },
  {
    "cityId": 7807,
    "cityName": "Jaguari",
    "stateId": 5256
  },
  {
    "cityId": 7808,
    "cityName": "Jansen",
    "stateId": 5256
  },
  {
    "cityId": 7809,
    "cityName": "Jaquirana",
    "stateId": 5256
  },
  {
    "cityId": 7810,
    "cityName": "Jari",
    "stateId": 5256
  },
  {
    "cityId": 7811,
    "cityName": "Jazidas ou Capela São Vicente",
    "stateId": 5256
  },
  {
    "cityId": 7812,
    "cityName": "João Arregui",
    "stateId": 5256
  },
  {
    "cityId": 7813,
    "cityName": "João Rodrigues",
    "stateId": 5256
  },
  {
    "cityId": 7814,
    "cityName": "Joça Tavares",
    "stateId": 5256
  },
  {
    "cityId": 7815,
    "cityName": "Jóia",
    "stateId": 5256
  },
  {
    "cityId": 7816,
    "cityName": "José Otávio",
    "stateId": 5256
  },
  {
    "cityId": 7817,
    "cityName": "Juá",
    "stateId": 5256
  },
  {
    "cityId": 7818,
    "cityName": "Júlio de Castilhos",
    "stateId": 5256
  },
  {
    "cityId": 7819,
    "cityName": "Lagoa Bonita do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7820,
    "cityName": "Lagoa dos Três Cantos",
    "stateId": 5256
  },
  {
    "cityId": 7821,
    "cityName": "Lagoa Vermelha",
    "stateId": 5256
  },
  {
    "cityId": 7822,
    "cityName": "Lagoão",
    "stateId": 5256
  },
  {
    "cityId": 7823,
    "cityName": "Lajeado",
    "stateId": 5256
  },
  {
    "cityId": 7824,
    "cityName": "Lajeado Bonito",
    "stateId": 5256
  },
  {
    "cityId": 7825,
    "cityName": "Lajeado Bonito",
    "stateId": 5256
  },
  {
    "cityId": 7826,
    "cityName": "Lajeado Cerne",
    "stateId": 5256
  },
  {
    "cityId": 7827,
    "cityName": "Lajeado do Bugre",
    "stateId": 5256
  },
  {
    "cityId": 7828,
    "cityName": "Lajeado Grande",
    "stateId": 5256
  },
  {
    "cityId": 7829,
    "cityName": "Lajeado Grande",
    "stateId": 5256
  },
  {
    "cityId": 7830,
    "cityName": "Lara",
    "stateId": 5256
  },
  {
    "cityId": 7831,
    "cityName": "Laranjeira",
    "stateId": 5256
  },
  {
    "cityId": 7832,
    "cityName": "Lava-Pés",
    "stateId": 5256
  },
  {
    "cityId": 7833,
    "cityName": "Lavras do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7834,
    "cityName": "Leonel Rocha",
    "stateId": 5256
  },
  {
    "cityId": 7835,
    "cityName": "Liberato Salzano",
    "stateId": 5256
  },
  {
    "cityId": 7836,
    "cityName": "Lindolfo Collor",
    "stateId": 5256
  },
  {
    "cityId": 7837,
    "cityName": "Linha Comprida",
    "stateId": 5256
  },
  {
    "cityId": 7838,
    "cityName": "Linha Nova",
    "stateId": 5256
  },
  {
    "cityId": 7839,
    "cityName": "Linha Vitória",
    "stateId": 5256
  },
  {
    "cityId": 7840,
    "cityName": "Loreto",
    "stateId": 5256
  },
  {
    "cityId": 7841,
    "cityName": "Maçambará",
    "stateId": 5256
  },
  {
    "cityId": 7842,
    "cityName": "Machadinho",
    "stateId": 5256
  },
  {
    "cityId": 7843,
    "cityName": "Magistério",
    "stateId": 5256
  },
  {
    "cityId": 7844,
    "cityName": "Mampituba",
    "stateId": 5256
  },
  {
    "cityId": 7845,
    "cityName": "Manchinha",
    "stateId": 5256
  },
  {
    "cityId": 7846,
    "cityName": "Mangueiras",
    "stateId": 5256
  },
  {
    "cityId": 7847,
    "cityName": "Manoel Viana",
    "stateId": 5256
  },
  {
    "cityId": 7848,
    "cityName": "Maquiné",
    "stateId": 5256
  },
  {
    "cityId": 7849,
    "cityName": "Maratá",
    "stateId": 5256
  },
  {
    "cityId": 7850,
    "cityName": "Marau",
    "stateId": 5256
  },
  {
    "cityId": 7851,
    "cityName": "Marcelino Ramos",
    "stateId": 5256
  },
  {
    "cityId": 7852,
    "cityName": "Marcorama",
    "stateId": 5256
  },
  {
    "cityId": 7853,
    "cityName": "Mariana Pimentel",
    "stateId": 5256
  },
  {
    "cityId": 7854,
    "cityName": "Mariano Moro",
    "stateId": 5256
  },
  {
    "cityId": 7855,
    "cityName": "Mariante",
    "stateId": 5256
  },
  {
    "cityId": 7856,
    "cityName": "Mariápolis",
    "stateId": 5256
  },
  {
    "cityId": 7857,
    "cityName": "Marques de Souza",
    "stateId": 5256
  },
  {
    "cityId": 7858,
    "cityName": "Mata",
    "stateId": 5256
  },
  {
    "cityId": 7859,
    "cityName": "Matarazzo",
    "stateId": 5256
  },
  {
    "cityId": 7860,
    "cityName": "Mato Castelhano",
    "stateId": 5256
  },
  {
    "cityId": 7861,
    "cityName": "Mato Grande",
    "stateId": 5256
  },
  {
    "cityId": 7862,
    "cityName": "Mato Leitão",
    "stateId": 5256
  },
  {
    "cityId": 7863,
    "cityName": "Mato Queimado",
    "stateId": 5256
  },
  {
    "cityId": 7864,
    "cityName": "Mauá",
    "stateId": 5256
  },
  {
    "cityId": 7865,
    "cityName": "Mauá",
    "stateId": 5256
  },
  {
    "cityId": 7866,
    "cityName": "Maximiliano de Almeida",
    "stateId": 5256
  },
  {
    "cityId": 7867,
    "cityName": "Medianeira",
    "stateId": 5256
  },
  {
    "cityId": 7868,
    "cityName": "Minas do Leão",
    "stateId": 5256
  },
  {
    "cityId": 7869,
    "cityName": "Miraguaí",
    "stateId": 5256
  },
  {
    "cityId": 7870,
    "cityName": "Miraguaia",
    "stateId": 5256
  },
  {
    "cityId": 7871,
    "cityName": "Mirim",
    "stateId": 5256
  },
  {
    "cityId": 7872,
    "cityName": "Mirim",
    "stateId": 5256
  },
  {
    "cityId": 7873,
    "cityName": "Montauri",
    "stateId": 5256
  },
  {
    "cityId": 7874,
    "cityName": "Monte Alegre",
    "stateId": 5256
  },
  {
    "cityId": 7875,
    "cityName": "Monte Alegre",
    "stateId": 5256
  },
  {
    "cityId": 7876,
    "cityName": "Monte Alegre dos Campos",
    "stateId": 5256
  },
  {
    "cityId": 7877,
    "cityName": "Monte Alverne",
    "stateId": 5256
  },
  {
    "cityId": 7878,
    "cityName": "Monte Belo do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7879,
    "cityName": "Monte Bonito",
    "stateId": 5256
  },
  {
    "cityId": 7880,
    "cityName": "Montenegro",
    "stateId": 5256
  },
  {
    "cityId": 7881,
    "cityName": "Mormaço",
    "stateId": 5256
  },
  {
    "cityId": 7882,
    "cityName": "Morrinhos",
    "stateId": 5256
  },
  {
    "cityId": 7883,
    "cityName": "Morrinhos do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7884,
    "cityName": "Morro Alto",
    "stateId": 5256
  },
  {
    "cityId": 7885,
    "cityName": "Morro Azul",
    "stateId": 5256
  },
  {
    "cityId": 7886,
    "cityName": "Morro Redondo",
    "stateId": 5256
  },
  {
    "cityId": 7887,
    "cityName": "Morro Reuter",
    "stateId": 5256
  },
  {
    "cityId": 7888,
    "cityName": "Morungava",
    "stateId": 5256
  },
  {
    "cityId": 7889,
    "cityName": "Mostardas",
    "stateId": 5256
  },
  {
    "cityId": 7890,
    "cityName": "Muçum",
    "stateId": 5256
  },
  {
    "cityId": 7891,
    "cityName": "Muitos Capões",
    "stateId": 5256
  },
  {
    "cityId": 7892,
    "cityName": "Muliterno",
    "stateId": 5256
  },
  {
    "cityId": 7893,
    "cityName": "Não-Me-Toque",
    "stateId": 5256
  },
  {
    "cityId": 7894,
    "cityName": "Nazaré",
    "stateId": 5256
  },
  {
    "cityId": 7895,
    "cityName": "Nicolau Vergueiro",
    "stateId": 5256
  },
  {
    "cityId": 7896,
    "cityName": "Nonoai",
    "stateId": 5256
  },
  {
    "cityId": 7897,
    "cityName": "Nossa Senhora Aparecida",
    "stateId": 5256
  },
  {
    "cityId": 7898,
    "cityName": "Nossa Senhora da Conceição",
    "stateId": 5256
  },
  {
    "cityId": 7899,
    "cityName": "Nova Alvorada",
    "stateId": 5256
  },
  {
    "cityId": 7900,
    "cityName": "Nova Araçá",
    "stateId": 5256
  },
  {
    "cityId": 7901,
    "cityName": "Nova Bassano",
    "stateId": 5256
  },
  {
    "cityId": 7902,
    "cityName": "Nova Boa Vista",
    "stateId": 5256
  },
  {
    "cityId": 7903,
    "cityName": "Nova Bréscia",
    "stateId": 5256
  },
  {
    "cityId": 7904,
    "cityName": "Nova Candelária",
    "stateId": 5256
  },
  {
    "cityId": 7905,
    "cityName": "Nova Esperança do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7906,
    "cityName": "Nova Hartz",
    "stateId": 5256
  },
  {
    "cityId": 7907,
    "cityName": "Nova Milano",
    "stateId": 5256
  },
  {
    "cityId": 7908,
    "cityName": "Nova Pádua",
    "stateId": 5256
  },
  {
    "cityId": 7909,
    "cityName": "Nova Palma",
    "stateId": 5256
  },
  {
    "cityId": 7910,
    "cityName": "Nova Petrópolis",
    "stateId": 5256
  },
  {
    "cityId": 7911,
    "cityName": "Nova Prata",
    "stateId": 5256
  },
  {
    "cityId": 7912,
    "cityName": "Nova Ramada",
    "stateId": 5256
  },
  {
    "cityId": 7913,
    "cityName": "Nova Roma do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7914,
    "cityName": "Nova Santa Rita",
    "stateId": 5256
  },
  {
    "cityId": 7915,
    "cityName": "Nova Sardenha",
    "stateId": 5256
  },
  {
    "cityId": 7916,
    "cityName": "Novo Barreiro",
    "stateId": 5256
  },
  {
    "cityId": 7917,
    "cityName": "Novo Cabrais",
    "stateId": 5256
  },
  {
    "cityId": 7918,
    "cityName": "Novo Hamburgo",
    "stateId": 5256
  },
  {
    "cityId": 7919,
    "cityName": "Novo Horizonte",
    "stateId": 5256
  },
  {
    "cityId": 7920,
    "cityName": "Novo Machado",
    "stateId": 5256
  },
  {
    "cityId": 7921,
    "cityName": "Novo Planalto",
    "stateId": 5256
  },
  {
    "cityId": 7922,
    "cityName": "Novo Tiradentes",
    "stateId": 5256
  },
  {
    "cityId": 7923,
    "cityName": "Vila Oliva",
    "stateId": 5256
  },
  {
    "cityId": 7924,
    "cityName": "Oralina",
    "stateId": 5256
  },
  {
    "cityId": 7925,
    "cityName": "Osório",
    "stateId": 5256
  },
  {
    "cityId": 7926,
    "cityName": "Osvaldo Cruz",
    "stateId": 5256
  },
  {
    "cityId": 7927,
    "cityName": "Osvaldo Kroeff",
    "stateId": 5256
  },
  {
    "cityId": 7928,
    "cityName": "Otávio Rocha",
    "stateId": 5256
  },
  {
    "cityId": 7929,
    "cityName": "Pacheca",
    "stateId": 5256
  },
  {
    "cityId": 7930,
    "cityName": "Padilha",
    "stateId": 5256
  },
  {
    "cityId": 7931,
    "cityName": "Padre Gonzales",
    "stateId": 5256
  },
  {
    "cityId": 7932,
    "cityName": "Paim Filho",
    "stateId": 5256
  },
  {
    "cityId": 7933,
    "cityName": "Palmares do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7934,
    "cityName": "Palmas",
    "stateId": 5256
  },
  {
    "cityId": 7935,
    "cityName": "Palmeira das Missões",
    "stateId": 5256
  },
  {
    "cityId": 7936,
    "cityName": "Palmitinho",
    "stateId": 5256
  },
  {
    "cityId": 7937,
    "cityName": "Pampeiro",
    "stateId": 5256
  },
  {
    "cityId": 7938,
    "cityName": "Panambi",
    "stateId": 5256
  },
  {
    "cityId": 7939,
    "cityName": "Pântano Grande",
    "stateId": 5256
  },
  {
    "cityId": 7940,
    "cityName": "Paraí",
    "stateId": 5256
  },
  {
    "cityId": 7941,
    "cityName": "Paraíso do Sul",
    "stateId": 5256
  },
  {
    "cityId": 7942,
    "cityName": "Pareci Novo",
    "stateId": 5256
  },
  {
    "cityId": 7943,
    "cityName": "Parobé",
    "stateId": 5256
  },
  {
    "cityId": 7944,
    "cityName": "Passa Sete",
    "stateId": 5256
  },
  {
    "cityId": 7945,
    "cityName": "Passinhos",
    "stateId": 5256
  },
  {
    "cityId": 7946,
    "cityName": "Passo Burmann",
    "stateId": 5256
  },
  {
    "cityId": 7947,
    "cityName": "Passo da Areia",
    "stateId": 5256
  },
  {
    "cityId": 7948,
    "cityName": "Passo da Caveira",
    "stateId": 5256
  },
  {
    "cityId": 7949,
    "cityName": "Passo das Pedras",
    "stateId": 5256
  },
  {
    "cityId": 7950,
    "cityName": "Passo do Adão",
    "stateId": 5256
  },
  {
    "cityId": 7951,
    "cityName": "Passo do Sabão",
    "stateId": 5256
  },
  {
    "cityId": 7952,
    "cityName": "Passo do Sobrado",
    "stateId": 5256
  },
  {
    "cityId": 7953,
    "cityName": "Passo Fundo",
    "stateId": 5256
  },
  {
    "cityId": 7954,
    "cityName": "Passo Novo",
    "stateId": 5256
  },
  {
    "cityId": 7955,
    "cityName": "Passo Raso",
    "stateId": 5256
  },
  {
    "cityId": 7956,
    "cityName": "Paulo Bento",
    "stateId": 5256
  },
  {
    "cityId": 7957,
    "cityName": "Pavão",
    "stateId": 5256
  },
  {
    "cityId": 7958,
    "cityName": "Paverama",
    "stateId": 5256
  },
  {
    "cityId": 7959,
    "cityName": "Pedras Altas",
    "stateId": 5256
  },
  {
    "cityId": 7960,
    "cityName": "Pedreiras",
    "stateId": 5256
  },
  {
    "cityId": 7961,
    "cityName": "Pedro Garcia",
    "stateId": 5256
  },
  {
    "cityId": 7962,
    "cityName": "Pedro Osório",
    "stateId": 5256
  },
  {
    "cityId": 7963,
    "cityName": "Pedro Paiva",
    "stateId": 5256
  },
  {
    "cityId": 7964,
    "cityName": "Pejuçara",
    "stateId": 5256
  },
  {
    "cityId": 7965,
    "cityName": "Pelotas",
    "stateId": 5256
  },
  {
    "cityId": 7966,
    "cityName": "Picada Café",
    "stateId": 5256
  },
  {
    "cityId": 7967,
    "cityName": "Pinhal",
    "stateId": 5256
  },
  {
    "cityId": 7968,
    "cityName": "Pinhal",
    "stateId": 5256
  },
  {
    "cityId": 7969,
    "cityName": "Pinhal",
    "stateId": 5256
  },
  {
    "cityId": 7970,
    "cityName": "Pinhal",
    "stateId": 5256
  },
  {
    "cityId": 7971,
    "cityName": "Pinhal Alto",
    "stateId": 5256
  },
  {
    "cityId": 7972,
    "cityName": "Pinhal da Serra",
    "stateId": 5256
  },
  {
    "cityId": 7973,
    "cityName": "Pinhal Grande",
    "stateId": 5256
  },
  {
    "cityId": 7974,
    "cityName": "Pinhalzinho",
    "stateId": 5256
  },
  {
    "cityId": 7975,
    "cityName": "Pinhalzinho",
    "stateId": 5256
  },
  {
    "cityId": 7976,
    "cityName": "Pinheirinho do Vale",
    "stateId": 5256
  },
  {
    "cityId": 7977,
    "cityName": "Pinheiro Machado",
    "stateId": 5256
  },
  {
    "cityId": 7978,
    "cityName": "Pinheiro Machado",
    "stateId": 5256
  },
  {
    "cityId": 7979,
    "cityName": "Pinheiro Marcado",
    "stateId": 5256
  },
  {
    "cityId": 7980,
    "cityName": "Pinto Bandeira",
    "stateId": 5256
  },
  {
    "cityId": 7981,
    "cityName": "Piraí",
    "stateId": 5256
  },
  {
    "cityId": 7982,
    "cityName": "Pirapó",
    "stateId": 5256
  },
  {
    "cityId": 7983,
    "cityName": "Piratini",
    "stateId": 5256
  },
  {
    "cityId": 7984,
    "cityName": "Pitanga",
    "stateId": 5256
  },
  {
    "cityId": 7985,
    "cityName": "Planalto",
    "stateId": 5256
  },
  {
    "cityId": 7986,
    "cityName": "Planalto",
    "stateId": 5256
  },
  {
    "cityId": 7987,
    "cityName": "Plano Alto",
    "stateId": 5256
  },
  {
    "cityId": 7988,
    "cityName": "Poço das Antas",
    "stateId": 5256
  },
  {
    "cityId": 7989,
    "cityName": "Polígono do Erval",
    "stateId": 5256
  },
  {
    "cityId": 7990,
    "cityName": "Pólo Petroquímico de Triunfo",
    "stateId": 5256
  },
  {
    "cityId": 7991,
    "cityName": "Pontão",
    "stateId": 5256
  },
  {
    "cityId": 7992,
    "cityName": "Ponte Preta",
    "stateId": 5256
  },
  {
    "cityId": 7993,
    "cityName": "Portão",
    "stateId": 5256
  },
  {
    "cityId": 7994,
    "cityName": "Porto Alegre",
    "stateId": 5256
  },
  {
    "cityId": 7995,
    "cityName": "Porto Batista",
    "stateId": 5256
  },
  {
    "cityId": 7996,
    "cityName": "Porto Lucena",
    "stateId": 5256
  },
  {
    "cityId": 7997,
    "cityName": "Porto Mauá",
    "stateId": 5256
  },
  {
    "cityId": 7998,
    "cityName": "Porto Vera Cruz",
    "stateId": 5256
  },
  {
    "cityId": 7999,
    "cityName": "Porto Xavier",
    "stateId": 5256
  },
  {
    "cityId": 8000,
    "cityName": "Pouso Novo",
    "stateId": 5256
  },
  {
    "cityId": 8001,
    "cityName": "Povo Novo",
    "stateId": 5256
  },
  {
    "cityId": 8002,
    "cityName": "Povoado Tozzo",
    "stateId": 5256
  },
  {
    "cityId": 8003,
    "cityName": "Pranchada",
    "stateId": 5256
  },
  {
    "cityId": 8004,
    "cityName": "Pratos",
    "stateId": 5256
  },
  {
    "cityId": 8005,
    "cityName": "Presidente Lucena",
    "stateId": 5256
  },
  {
    "cityId": 8006,
    "cityName": "Progresso",
    "stateId": 5256
  },
  {
    "cityId": 8007,
    "cityName": "Progresso",
    "stateId": 5256
  },
  {
    "cityId": 8008,
    "cityName": "Progresso",
    "stateId": 5256
  },
  {
    "cityId": 8009,
    "cityName": "Progresso",
    "stateId": 5256
  },
  {
    "cityId": 8010,
    "cityName": "Protásio Alves",
    "stateId": 5256
  },
  {
    "cityId": 8011,
    "cityName": "Pulador",
    "stateId": 5256
  },
  {
    "cityId": 8012,
    "cityName": "Putinga",
    "stateId": 5256
  },
  {
    "cityId": 8013,
    "cityName": "Quaraí",
    "stateId": 5256
  },
  {
    "cityId": 8014,
    "cityName": "Quaraim",
    "stateId": 5256
  },
  {
    "cityId": 8015,
    "cityName": "Quatro Irmãos",
    "stateId": 5256
  },
  {
    "cityId": 8016,
    "cityName": "Quevedos",
    "stateId": 5256
  },
  {
    "cityId": 8017,
    "cityName": "Quilombo",
    "stateId": 5256
  },
  {
    "cityId": 8018,
    "cityName": "Quinta",
    "stateId": 5256
  },
  {
    "cityId": 8019,
    "cityName": "Quintão",
    "stateId": 5256
  },
  {
    "cityId": 8020,
    "cityName": "Quinze de Novembro",
    "stateId": 5256
  },
  {
    "cityId": 8021,
    "cityName": "Quitéria",
    "stateId": 5256
  },
  {
    "cityId": 8022,
    "cityName": "Rancho Velho",
    "stateId": 5256
  },
  {
    "cityId": 8023,
    "cityName": "Redentora",
    "stateId": 5256
  },
  {
    "cityId": 8024,
    "cityName": "Refugiado",
    "stateId": 5256
  },
  {
    "cityId": 8025,
    "cityName": "Relvado",
    "stateId": 5256
  },
  {
    "cityId": 8026,
    "cityName": "Restinga Seca",
    "stateId": 5256
  },
  {
    "cityId": 8027,
    "cityName": "Restinga Seca",
    "stateId": 5256
  },
  {
    "cityId": 8028,
    "cityName": "Rincão de São Pedro",
    "stateId": 5256
  },
  {
    "cityId": 8029,
    "cityName": "Rincão Del Rei",
    "stateId": 5256
  },
  {
    "cityId": 8030,
    "cityName": "Rincão do Cristóvão Pereira",
    "stateId": 5256
  },
  {
    "cityId": 8031,
    "cityName": "Rincão do Meio",
    "stateId": 5256
  },
  {
    "cityId": 8032,
    "cityName": "Rincão do Segredo",
    "stateId": 5256
  },
  {
    "cityId": 8033,
    "cityName": "Rincão Doce",
    "stateId": 5256
  },
  {
    "cityId": 8034,
    "cityName": "Rincão dos Kroeff",
    "stateId": 5256
  },
  {
    "cityId": 8035,
    "cityName": "Rincão dos Mendes",
    "stateId": 5256
  },
  {
    "cityId": 8036,
    "cityName": "Rincão Vermelho",
    "stateId": 5256
  },
  {
    "cityId": 8037,
    "cityName": "Rio Azul",
    "stateId": 5256
  },
  {
    "cityId": 8038,
    "cityName": "Rio Branco",
    "stateId": 5256
  },
  {
    "cityId": 8039,
    "cityName": "Rio da Ilha",
    "stateId": 5256
  },
  {
    "cityId": 8040,
    "cityName": "Rio dos Índios",
    "stateId": 5256
  },
  {
    "cityId": 8041,
    "cityName": "Rio Grande",
    "stateId": 5256
  },
  {
    "cityId": 8042,
    "cityName": "Rio Pardinho",
    "stateId": 5256
  },
  {
    "cityId": 8043,
    "cityName": "Rio Pardo",
    "stateId": 5256
  },
  {
    "cityId": 8044,
    "cityName": "Rio Telha",
    "stateId": 5256
  },
  {
    "cityId": 8045,
    "cityName": "Rio Tigre",
    "stateId": 5256
  },
  {
    "cityId": 8046,
    "cityName": "Rio Toldo",
    "stateId": 5256
  },
  {
    "cityId": 8047,
    "cityName": "Riozinho",
    "stateId": 5256
  },
  {
    "cityId": 8048,
    "cityName": "Roca Sales",
    "stateId": 5256
  },
  {
    "cityId": 8049,
    "cityName": "Rodeio Bonito",
    "stateId": 5256
  },
  {
    "cityId": 8050,
    "cityName": "Rolador",
    "stateId": 5256
  },
  {
    "cityId": 8051,
    "cityName": "Rolante",
    "stateId": 5256
  },
  {
    "cityId": 8052,
    "cityName": "Rolantinho da Figueira",
    "stateId": 5256
  },
  {
    "cityId": 8053,
    "cityName": "Ronda Alta",
    "stateId": 5256
  },
  {
    "cityId": 8054,
    "cityName": "Rondinha",
    "stateId": 5256
  },
  {
    "cityId": 8055,
    "cityName": "Roque Gonzales",
    "stateId": 5256
  },
  {
    "cityId": 8056,
    "cityName": "Rosário",
    "stateId": 5256
  },
  {
    "cityId": 8057,
    "cityName": "Rosário do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8058,
    "cityName": "Sagrada Família",
    "stateId": 5256
  },
  {
    "cityId": 8059,
    "cityName": "Saicã",
    "stateId": 5256
  },
  {
    "cityId": 8060,
    "cityName": "Saldanha Marinho",
    "stateId": 5256
  },
  {
    "cityId": 8061,
    "cityName": "Saltinho",
    "stateId": 5256
  },
  {
    "cityId": 8062,
    "cityName": "Saltinho",
    "stateId": 5256
  },
  {
    "cityId": 8063,
    "cityName": "Salto",
    "stateId": 5256
  },
  {
    "cityId": 8064,
    "cityName": "Salto do Jacuí",
    "stateId": 5256
  },
  {
    "cityId": 8065,
    "cityName": "Salvador das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8066,
    "cityName": "Salvador do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8067,
    "cityName": "Sananduva",
    "stateId": 5256
  },
  {
    "cityId": 8068,
    "cityName": "Sant'auta",
    "stateId": 5256
  },
  {
    "cityId": 8069,
    "cityName": "Santa Bárbara",
    "stateId": 5256
  },
  {
    "cityId": 8070,
    "cityName": "Santa Bárbara",
    "stateId": 5256
  },
  {
    "cityId": 8071,
    "cityName": "Santa Bárbara do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8072,
    "cityName": "Santa Catarina",
    "stateId": 5256
  },
  {
    "cityId": 8073,
    "cityName": "Santa Cecília do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8074,
    "cityName": "Santa Clara do Ingaí",
    "stateId": 5256
  },
  {
    "cityId": 8075,
    "cityName": "Santa Clara do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8076,
    "cityName": "Santa Cristina",
    "stateId": 5256
  },
  {
    "cityId": 8077,
    "cityName": "Santa Cruz",
    "stateId": 5256
  },
  {
    "cityId": 8078,
    "cityName": "Santa Cruz da Concórdia",
    "stateId": 5256
  },
  {
    "cityId": 8079,
    "cityName": "Santa Cruz do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8080,
    "cityName": "Santa Flora",
    "stateId": 5256
  },
  {
    "cityId": 8081,
    "cityName": "Santa Inês",
    "stateId": 5256
  },
  {
    "cityId": 8082,
    "cityName": "Santa Izabel do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8083,
    "cityName": "Santa Lúcia",
    "stateId": 5256
  },
  {
    "cityId": 8084,
    "cityName": "Santa Lúcia do Piaí",
    "stateId": 5256
  },
  {
    "cityId": 8085,
    "cityName": "Santa Luíza",
    "stateId": 5256
  },
  {
    "cityId": 8086,
    "cityName": "Santa Luzia",
    "stateId": 5256
  },
  {
    "cityId": 8087,
    "cityName": "Santa Maria",
    "stateId": 5256
  },
  {
    "cityId": 8088,
    "cityName": "Santa Maria do Herval",
    "stateId": 5256
  },
  {
    "cityId": 8089,
    "cityName": "Santa Rita do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8090,
    "cityName": "Santa Rosa",
    "stateId": 5256
  },
  {
    "cityId": 8091,
    "cityName": "Santa Rosa",
    "stateId": 5256
  },
  {
    "cityId": 8092,
    "cityName": "Santa Silvana",
    "stateId": 5256
  },
  {
    "cityId": 8093,
    "cityName": "Santa Teresinha",
    "stateId": 5256
  },
  {
    "cityId": 8094,
    "cityName": "Santa Tereza",
    "stateId": 5256
  },
  {
    "cityId": 8095,
    "cityName": "Santa Tereza",
    "stateId": 5256
  },
  {
    "cityId": 8096,
    "cityName": "Santa Vitória do Palmar",
    "stateId": 5256
  },
  {
    "cityId": 8097,
    "cityName": "Santana",
    "stateId": 5256
  },
  {
    "cityId": 8098,
    "cityName": "Santana",
    "stateId": 5256
  },
  {
    "cityId": 8099,
    "cityName": "Santana",
    "stateId": 5256
  },
  {
    "cityId": 8100,
    "cityName": "Santana da Boa Vista",
    "stateId": 5256
  },
  {
    "cityId": 8101,
    "cityName": "Santana do Livramento",
    "stateId": 5256
  },
  {
    "cityId": 8102,
    "cityName": "Santiago",
    "stateId": 5256
  },
  {
    "cityId": 8103,
    "cityName": "Santo Amaro do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8104,
    "cityName": "Santo Ângelo",
    "stateId": 5256
  },
  {
    "cityId": 8105,
    "cityName": "Santo Antônio",
    "stateId": 5256
  },
  {
    "cityId": 8106,
    "cityName": "Santo Antônio",
    "stateId": 5256
  },
  {
    "cityId": 8107,
    "cityName": "Santo Antônio",
    "stateId": 5256
  },
  {
    "cityId": 8108,
    "cityName": "Santo Antônio da Patrulha",
    "stateId": 5256
  },
  {
    "cityId": 8109,
    "cityName": "Santo Antônio das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8110,
    "cityName": "Santo Antônio de Castro",
    "stateId": 5256
  },
  {
    "cityId": 8111,
    "cityName": "Santo Antônio do Bom Retiro",
    "stateId": 5256
  },
  {
    "cityId": 8112,
    "cityName": "Santo Antônio do Palma",
    "stateId": 5256
  },
  {
    "cityId": 8113,
    "cityName": "Santo Antônio do Planalto",
    "stateId": 5256
  },
  {
    "cityId": 8114,
    "cityName": "Santo Augusto",
    "stateId": 5256
  },
  {
    "cityId": 8115,
    "cityName": "Santo Cristo",
    "stateId": 5256
  },
  {
    "cityId": 8116,
    "cityName": "Santo Expedito do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8117,
    "cityName": "Santo Inácio",
    "stateId": 5256
  },
  {
    "cityId": 8118,
    "cityName": "São Bento",
    "stateId": 5256
  },
  {
    "cityId": 8119,
    "cityName": "São Bom Jesus",
    "stateId": 5256
  },
  {
    "cityId": 8120,
    "cityName": "São Borja",
    "stateId": 5256
  },
  {
    "cityId": 8121,
    "cityName": "São Carlos",
    "stateId": 5256
  },
  {
    "cityId": 8122,
    "cityName": "São Domingos do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8123,
    "cityName": "São Francisco",
    "stateId": 5256
  },
  {
    "cityId": 8124,
    "cityName": "São Francisco de Assis",
    "stateId": 5256
  },
  {
    "cityId": 8125,
    "cityName": "São Francisco de Paula",
    "stateId": 5256
  },
  {
    "cityId": 8126,
    "cityName": "São Gabriel",
    "stateId": 5256
  },
  {
    "cityId": 8127,
    "cityName": "São Jerônimo",
    "stateId": 5256
  },
  {
    "cityId": 8128,
    "cityName": "São João",
    "stateId": 5256
  },
  {
    "cityId": 8129,
    "cityName": "São João Batista",
    "stateId": 5256
  },
  {
    "cityId": 8130,
    "cityName": "São João Bosco",
    "stateId": 5256
  },
  {
    "cityId": 8131,
    "cityName": "São João da Urtiga",
    "stateId": 5256
  },
  {
    "cityId": 8132,
    "cityName": "São João do Polesine",
    "stateId": 5256
  },
  {
    "cityId": 8133,
    "cityName": "São Jorge",
    "stateId": 5256
  },
  {
    "cityId": 8134,
    "cityName": "São José",
    "stateId": 5256
  },
  {
    "cityId": 8135,
    "cityName": "São José",
    "stateId": 5256
  },
  {
    "cityId": 8136,
    "cityName": "São José da Glória",
    "stateId": 5256
  },
  {
    "cityId": 8137,
    "cityName": "São José das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8138,
    "cityName": "São José de Castro",
    "stateId": 5256
  },
  {
    "cityId": 8139,
    "cityName": "São José do Centro",
    "stateId": 5256
  },
  {
    "cityId": 8140,
    "cityName": "São José do Herval",
    "stateId": 5256
  },
  {
    "cityId": 8141,
    "cityName": "São José do Hortêncio",
    "stateId": 5256
  },
  {
    "cityId": 8142,
    "cityName": "São José do Inhacorá",
    "stateId": 5256
  },
  {
    "cityId": 8143,
    "cityName": "São José do Norte",
    "stateId": 5256
  },
  {
    "cityId": 8144,
    "cityName": "São José do Ouro",
    "stateId": 5256
  },
  {
    "cityId": 8145,
    "cityName": "São José dos Ausentes",
    "stateId": 5256
  },
  {
    "cityId": 8146,
    "cityName": "São Leopoldo",
    "stateId": 5256
  },
  {
    "cityId": 8147,
    "cityName": "São Lourenço das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8148,
    "cityName": "São Lourenço do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8149,
    "cityName": "São Luís Rei",
    "stateId": 5256
  },
  {
    "cityId": 8150,
    "cityName": "São Luiz",
    "stateId": 5256
  },
  {
    "cityId": 8151,
    "cityName": "São Luiz Gonzaga",
    "stateId": 5256
  },
  {
    "cityId": 8152,
    "cityName": "São Manuel",
    "stateId": 5256
  },
  {
    "cityId": 8153,
    "cityName": "São Marcos",
    "stateId": 5256
  },
  {
    "cityId": 8154,
    "cityName": "São Marcos",
    "stateId": 5256
  },
  {
    "cityId": 8155,
    "cityName": "São Martinho",
    "stateId": 5256
  },
  {
    "cityId": 8156,
    "cityName": "São Martinho da Serra",
    "stateId": 5256
  },
  {
    "cityId": 8157,
    "cityName": "São Miguel",
    "stateId": 5256
  },
  {
    "cityId": 8158,
    "cityName": "São Miguel das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8159,
    "cityName": "São Nicolau",
    "stateId": 5256
  },
  {
    "cityId": 8160,
    "cityName": "São Paulo",
    "stateId": 5256
  },
  {
    "cityId": 8161,
    "cityName": "São Paulo das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8162,
    "cityName": "São Pedro",
    "stateId": 5256
  },
  {
    "cityId": 8163,
    "cityName": "São Pedro das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8164,
    "cityName": "São Pedro da Serra",
    "stateId": 5256
  },
  {
    "cityId": 8165,
    "cityName": "São Pedro do Butiá",
    "stateId": 5256
  },
  {
    "cityId": 8166,
    "cityName": "São Pedro do Iraxim",
    "stateId": 5256
  },
  {
    "cityId": 8167,
    "cityName": "São Pedro do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8168,
    "cityName": "São Roque",
    "stateId": 5256
  },
  {
    "cityId": 8169,
    "cityName": "São Sebastião",
    "stateId": 5256
  },
  {
    "cityId": 8170,
    "cityName": "São Sebastião",
    "stateId": 5256
  },
  {
    "cityId": 8171,
    "cityName": "São Sebastião do Caí",
    "stateId": 5256
  },
  {
    "cityId": 8172,
    "cityName": "São Sepé",
    "stateId": 5256
  },
  {
    "cityId": 8173,
    "cityName": "São Simão",
    "stateId": 5256
  },
  {
    "cityId": 8174,
    "cityName": "São Valentim",
    "stateId": 5256
  },
  {
    "cityId": 8175,
    "cityName": "São Valentim",
    "stateId": 5256
  },
  {
    "cityId": 8176,
    "cityName": "São Valentim do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8177,
    "cityName": "São Valério do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8178,
    "cityName": "São Vendelino",
    "stateId": 5256
  },
  {
    "cityId": 8179,
    "cityName": "São Vicente do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8180,
    "cityName": "Sapiranga",
    "stateId": 5256
  },
  {
    "cityId": 8181,
    "cityName": "Sapucaia do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8182,
    "cityName": "Sarandi",
    "stateId": 5256
  },
  {
    "cityId": 8183,
    "cityName": "Scharlau",
    "stateId": 5256
  },
  {
    "cityId": 8184,
    "cityName": "Seberi",
    "stateId": 5256
  },
  {
    "cityId": 8185,
    "cityName": "Vila Seca",
    "stateId": 5256
  },
  {
    "cityId": 8186,
    "cityName": "Sede Aurora",
    "stateId": 5256
  },
  {
    "cityId": 8187,
    "cityName": "Sede Nova",
    "stateId": 5256
  },
  {
    "cityId": 8188,
    "cityName": "Segredo",
    "stateId": 5256
  },
  {
    "cityId": 8189,
    "cityName": "Segredo",
    "stateId": 5256
  },
  {
    "cityId": 8190,
    "cityName": "Seival",
    "stateId": 5256
  },
  {
    "cityId": 8191,
    "cityName": "Selbach",
    "stateId": 5256
  },
  {
    "cityId": 8192,
    "cityName": "Senador Salgado Filho",
    "stateId": 5256
  },
  {
    "cityId": 8193,
    "cityName": "Sentinela do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8194,
    "cityName": "Serafim Schmidt",
    "stateId": 5256
  },
  {
    "cityId": 8195,
    "cityName": "Serafina Corrêa",
    "stateId": 5256
  },
  {
    "cityId": 8196,
    "cityName": "Sério",
    "stateId": 5256
  },
  {
    "cityId": 8197,
    "cityName": "Serra dos Gregórios",
    "stateId": 5256
  },
  {
    "cityId": 8198,
    "cityName": "Serrinha",
    "stateId": 5256
  },
  {
    "cityId": 8199,
    "cityName": "Sertão",
    "stateId": 5256
  },
  {
    "cityId": 8200,
    "cityName": "Sertão Santana",
    "stateId": 5256
  },
  {
    "cityId": 8201,
    "cityName": "Sertãozinho",
    "stateId": 5256
  },
  {
    "cityId": 8202,
    "cityName": "Sete de Setembro",
    "stateId": 5256
  },
  {
    "cityId": 8203,
    "cityName": "Sete de Setembro",
    "stateId": 5256
  },
  {
    "cityId": 8204,
    "cityName": "Sete de Setembro",
    "stateId": 5256
  },
  {
    "cityId": 8205,
    "cityName": "Sete Lagoas",
    "stateId": 5256
  },
  {
    "cityId": 8206,
    "cityName": "Severiano de Almeida",
    "stateId": 5256
  },
  {
    "cityId": 8207,
    "cityName": "Silva Jardim",
    "stateId": 5256
  },
  {
    "cityId": 8208,
    "cityName": "Silveira",
    "stateId": 5256
  },
  {
    "cityId": 8209,
    "cityName": "Silveira Martins",
    "stateId": 5256
  },
  {
    "cityId": 8210,
    "cityName": "Sinimbu",
    "stateId": 5256
  },
  {
    "cityId": 8211,
    "cityName": "Sírio",
    "stateId": 5256
  },
  {
    "cityId": 8212,
    "cityName": "Sítio Gabriel",
    "stateId": 5256
  },
  {
    "cityId": 8213,
    "cityName": "Sobradinho",
    "stateId": 5256
  },
  {
    "cityId": 8214,
    "cityName": "Soledade",
    "stateId": 5256
  },
  {
    "cityId": 8215,
    "cityName": "Souza Ramos",
    "stateId": 5256
  },
  {
    "cityId": 8216,
    "cityName": "Suspiro",
    "stateId": 5256
  },
  {
    "cityId": 8217,
    "cityName": "Tabaí",
    "stateId": 5256
  },
  {
    "cityId": 8218,
    "cityName": "Tabajara",
    "stateId": 5256
  },
  {
    "cityId": 8219,
    "cityName": "Taim",
    "stateId": 5256
  },
  {
    "cityId": 8220,
    "cityName": "Tainhas",
    "stateId": 5256
  },
  {
    "cityId": 8221,
    "cityName": "Tamanduá",
    "stateId": 5256
  },
  {
    "cityId": 8222,
    "cityName": "Tanque",
    "stateId": 5256
  },
  {
    "cityId": 8223,
    "cityName": "Tapejara",
    "stateId": 5256
  },
  {
    "cityId": 8224,
    "cityName": "Tapera",
    "stateId": 5256
  },
  {
    "cityId": 8225,
    "cityName": "Tapera",
    "stateId": 5256
  },
  {
    "cityId": 8226,
    "cityName": "Tapes",
    "stateId": 5256
  },
  {
    "cityId": 8227,
    "cityName": "Taquara",
    "stateId": 5256
  },
  {
    "cityId": 8228,
    "cityName": "Taquari",
    "stateId": 5256
  },
  {
    "cityId": 8229,
    "cityName": "Taquarichim",
    "stateId": 5256
  },
  {
    "cityId": 8230,
    "cityName": "Taquaruçu do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8231,
    "cityName": "Tavares",
    "stateId": 5256
  },
  {
    "cityId": 8232,
    "cityName": "Tenente Portela",
    "stateId": 5256
  },
  {
    "cityId": 8233,
    "cityName": "Terra de Areia",
    "stateId": 5256
  },
  {
    "cityId": 8234,
    "cityName": "Tesouras",
    "stateId": 5256
  },
  {
    "cityId": 8235,
    "cityName": "Teutônia",
    "stateId": 5256
  },
  {
    "cityId": 8236,
    "cityName": "Tiaraju",
    "stateId": 5256
  },
  {
    "cityId": 8237,
    "cityName": "Timbaúva",
    "stateId": 5256
  },
  {
    "cityId": 8238,
    "cityName": "Tiradentes do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8239,
    "cityName": "Toropi",
    "stateId": 5256
  },
  {
    "cityId": 8240,
    "cityName": "Toroquá",
    "stateId": 5256
  },
  {
    "cityId": 8241,
    "cityName": "Torquato Severo",
    "stateId": 5256
  },
  {
    "cityId": 8242,
    "cityName": "Torres",
    "stateId": 5256
  },
  {
    "cityId": 8243,
    "cityName": "Torrinhas",
    "stateId": 5256
  },
  {
    "cityId": 8244,
    "cityName": "Touro Passo",
    "stateId": 5256
  },
  {
    "cityId": 8245,
    "cityName": "Tramandaí",
    "stateId": 5256
  },
  {
    "cityId": 8246,
    "cityName": "Travesseiro",
    "stateId": 5256
  },
  {
    "cityId": 8247,
    "cityName": "Trentin",
    "stateId": 5256
  },
  {
    "cityId": 8248,
    "cityName": "Três Arroios",
    "stateId": 5256
  },
  {
    "cityId": 8249,
    "cityName": "Três Barras",
    "stateId": 5256
  },
  {
    "cityId": 8250,
    "cityName": "Três Cachoeiras",
    "stateId": 5256
  },
  {
    "cityId": 8251,
    "cityName": "Três Coroas",
    "stateId": 5256
  },
  {
    "cityId": 8252,
    "cityName": "Três de Maio",
    "stateId": 5256
  },
  {
    "cityId": 8253,
    "cityName": "Três Forquilhas",
    "stateId": 5256
  },
  {
    "cityId": 8254,
    "cityName": "Três Palmeiras",
    "stateId": 5256
  },
  {
    "cityId": 8255,
    "cityName": "Três Passos",
    "stateId": 5256
  },
  {
    "cityId": 8256,
    "cityName": "Três Vendas",
    "stateId": 5256
  },
  {
    "cityId": 8257,
    "cityName": "Trindade do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8258,
    "cityName": "Triunfo",
    "stateId": 5256
  },
  {
    "cityId": 8259,
    "cityName": "Tronqueiras",
    "stateId": 5256
  },
  {
    "cityId": 8260,
    "cityName": "Tucunduva",
    "stateId": 5256
  },
  {
    "cityId": 8261,
    "cityName": "Tuiuti",
    "stateId": 5256
  },
  {
    "cityId": 8262,
    "cityName": "Tunas",
    "stateId": 5256
  },
  {
    "cityId": 8263,
    "cityName": "Túnel Verde",
    "stateId": 5256
  },
  {
    "cityId": 8264,
    "cityName": "Tupanci do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8265,
    "cityName": "Tupanciretã",
    "stateId": 5256
  },
  {
    "cityId": 8266,
    "cityName": "Tupancy ou Vila Block",
    "stateId": 5256
  },
  {
    "cityId": 8267,
    "cityName": "Tupandi",
    "stateId": 5256
  },
  {
    "cityId": 8268,
    "cityName": "Tupantuba",
    "stateId": 5256
  },
  {
    "cityId": 8269,
    "cityName": "Tuparendi",
    "stateId": 5256
  },
  {
    "cityId": 8270,
    "cityName": "Tupi Silveira",
    "stateId": 5256
  },
  {
    "cityId": 8271,
    "cityName": "Tupinambá",
    "stateId": 5256
  },
  {
    "cityId": 8272,
    "cityName": "Turuçu",
    "stateId": 5256
  },
  {
    "cityId": 8273,
    "cityName": "Turvinho",
    "stateId": 5256
  },
  {
    "cityId": 8274,
    "cityName": "Ubiretama",
    "stateId": 5256
  },
  {
    "cityId": 8275,
    "cityName": "Umbu",
    "stateId": 5256
  },
  {
    "cityId": 8276,
    "cityName": "União da Serra",
    "stateId": 5256
  },
  {
    "cityId": 8277,
    "cityName": "Unistalda",
    "stateId": 5256
  },
  {
    "cityId": 8278,
    "cityName": "Uruguaiana",
    "stateId": 5256
  },
  {
    "cityId": 8279,
    "cityName": "Vacacai",
    "stateId": 5256
  },
  {
    "cityId": 8280,
    "cityName": "Vacaria",
    "stateId": 5256
  },
  {
    "cityId": 8281,
    "cityName": "Valdástico",
    "stateId": 5256
  },
  {
    "cityId": 8282,
    "cityName": "Vale do Rio Cai",
    "stateId": 5256
  },
  {
    "cityId": 8283,
    "cityName": "Vale do Sol",
    "stateId": 5256
  },
  {
    "cityId": 8284,
    "cityName": "Vale Real",
    "stateId": 5256
  },
  {
    "cityId": 8285,
    "cityName": "Vale Veneto",
    "stateId": 5256
  },
  {
    "cityId": 8286,
    "cityName": "Vale Verde",
    "stateId": 5256
  },
  {
    "cityId": 8287,
    "cityName": "Vanini",
    "stateId": 5256
  },
  {
    "cityId": 8288,
    "cityName": "Venâncio Aires",
    "stateId": 5256
  },
  {
    "cityId": 8289,
    "cityName": "Vera Cruz",
    "stateId": 5256
  },
  {
    "cityId": 8290,
    "cityName": "Veranópolis",
    "stateId": 5256
  },
  {
    "cityId": 8291,
    "cityName": "Vertentes",
    "stateId": 5256
  },
  {
    "cityId": 8292,
    "cityName": "Vespasiano Correa",
    "stateId": 5256
  },
  {
    "cityId": 8293,
    "cityName": "Viadutos",
    "stateId": 5256
  },
  {
    "cityId": 8294,
    "cityName": "Viamão",
    "stateId": 5256
  },
  {
    "cityId": 8295,
    "cityName": "Vicente Dutra",
    "stateId": 5256
  },
  {
    "cityId": 8296,
    "cityName": "Victor Graeff",
    "stateId": 5256
  },
  {
    "cityId": 8297,
    "cityName": "Vila Bender",
    "stateId": 5256
  },
  {
    "cityId": 8298,
    "cityName": "Vila Cruz",
    "stateId": 5256
  },
  {
    "cityId": 8299,
    "cityName": "Vila Flores",
    "stateId": 5256
  },
  {
    "cityId": 8300,
    "cityName": "Vila Langaro",
    "stateId": 5256
  },
  {
    "cityId": 8301,
    "cityName": "Vila Laranjeira",
    "stateId": 5256
  },
  {
    "cityId": 8302,
    "cityName": "Vila Maria",
    "stateId": 5256
  },
  {
    "cityId": 8303,
    "cityName": "Vila Nova do Sul",
    "stateId": 5256
  },
  {
    "cityId": 8304,
    "cityName": "Vila Rica",
    "stateId": 5256
  },
  {
    "cityId": 8305,
    "cityName": "Vila Seca",
    "stateId": 5256
  },
  {
    "cityId": 8306,
    "cityName": "Vila Turvo",
    "stateId": 5256
  },
  {
    "cityId": 8307,
    "cityName": "Vista Alegre",
    "stateId": 5256
  },
  {
    "cityId": 8308,
    "cityName": "Vista Alegre",
    "stateId": 5256
  },
  {
    "cityId": 8309,
    "cityName": "Vista Alegre do Prata",
    "stateId": 5256
  },
  {
    "cityId": 8310,
    "cityName": "Vista Gaúcha",
    "stateId": 5256
  },
  {
    "cityId": 8311,
    "cityName": "Vitória",
    "stateId": 5256
  },
  {
    "cityId": 8312,
    "cityName": "Vitória das Missões",
    "stateId": 5256
  },
  {
    "cityId": 8313,
    "cityName": "Volta Alegre",
    "stateId": 5256
  },
  {
    "cityId": 8314,
    "cityName": "Volta Fechada",
    "stateId": 5256
  },
  {
    "cityId": 8315,
    "cityName": "Volta Grande",
    "stateId": 5256
  },
  {
    "cityId": 8316,
    "cityName": "Xadrez",
    "stateId": 5256
  },
  {
    "cityId": 8317,
    "cityName": "Xangri-Lá",
    "stateId": 5256
  },
  {
    "cityId": 8318,
    "cityName": "Novo Xingu",
    "stateId": 5256
  },
  {
    "cityId": 8319,
    "cityName": "Abdon Batista",
    "stateId": 5257
  },
  {
    "cityId": 8320,
    "cityName": "Abelardo Luz",
    "stateId": 5257
  },
  {
    "cityId": 8321,
    "cityName": "Agrolândia",
    "stateId": 5257
  },
  {
    "cityId": 8322,
    "cityName": "Agronômica",
    "stateId": 5257
  },
  {
    "cityId": 8323,
    "cityName": "Água Doce",
    "stateId": 5257
  },
  {
    "cityId": 8324,
    "cityName": "Águas Brancas",
    "stateId": 5257
  },
  {
    "cityId": 8325,
    "cityName": "Águas Claras",
    "stateId": 5257
  },
  {
    "cityId": 8326,
    "cityName": "Águas de Chapecó",
    "stateId": 5257
  },
  {
    "cityId": 8327,
    "cityName": "Águas Frias",
    "stateId": 5257
  },
  {
    "cityId": 8328,
    "cityName": "Águas Mornas",
    "stateId": 5257
  },
  {
    "cityId": 8329,
    "cityName": "Aguti",
    "stateId": 5257
  },
  {
    "cityId": 8330,
    "cityName": "Aiurê",
    "stateId": 5257
  },
  {
    "cityId": 8331,
    "cityName": "Alfredo Wagner",
    "stateId": 5257
  },
  {
    "cityId": 8332,
    "cityName": "Alto Alegre",
    "stateId": 5257
  },
  {
    "cityId": 8333,
    "cityName": "Alto Bela Vista",
    "stateId": 5257
  },
  {
    "cityId": 8334,
    "cityName": "Alto da Serra",
    "stateId": 5257
  },
  {
    "cityId": 8335,
    "cityName": "Anchieta",
    "stateId": 5257
  },
  {
    "cityId": 8336,
    "cityName": "Angelina",
    "stateId": 5257
  },
  {
    "cityId": 8337,
    "cityName": "Anita Garibaldi",
    "stateId": 5257
  },
  {
    "cityId": 8338,
    "cityName": "Anitápolis",
    "stateId": 5257
  },
  {
    "cityId": 8339,
    "cityName": "Anta Gorda",
    "stateId": 5257
  },
  {
    "cityId": 8340,
    "cityName": "Antônio Carlos",
    "stateId": 5257
  },
  {
    "cityId": 8341,
    "cityName": "Apiúna",
    "stateId": 5257
  },
  {
    "cityId": 8342,
    "cityName": "Arabutã",
    "stateId": 5257
  },
  {
    "cityId": 8343,
    "cityName": "Araquari",
    "stateId": 5257
  },
  {
    "cityId": 8344,
    "cityName": "Araranguá",
    "stateId": 5257
  },
  {
    "cityId": 8345,
    "cityName": "Armazém",
    "stateId": 5257
  },
  {
    "cityId": 8346,
    "cityName": "Arnópolis",
    "stateId": 5257
  },
  {
    "cityId": 8347,
    "cityName": "Arroio Trinta",
    "stateId": 5257
  },
  {
    "cityId": 8348,
    "cityName": "Arvoredo",
    "stateId": 5257
  },
  {
    "cityId": 8349,
    "cityName": "Ascurra",
    "stateId": 5257
  },
  {
    "cityId": 8350,
    "cityName": "Atalanta",
    "stateId": 5257
  },
  {
    "cityId": 8351,
    "cityName": "Aterrado Torto",
    "stateId": 5257
  },
  {
    "cityId": 8352,
    "cityName": "Aurora",
    "stateId": 5257
  },
  {
    "cityId": 8353,
    "cityName": "Azambuja",
    "stateId": 5257
  },
  {
    "cityId": 8354,
    "cityName": "Baia Alta",
    "stateId": 5257
  },
  {
    "cityId": 8355,
    "cityName": "Balneário Arroio do Silva",
    "stateId": 5257
  },
  {
    "cityId": 8356,
    "cityName": "Balneário Barra do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8357,
    "cityName": "Balneário Camboriú",
    "stateId": 5257
  },
  {
    "cityId": 8358,
    "cityName": "Balneário Gaivota",
    "stateId": 5257
  },
  {
    "cityId": 8359,
    "cityName": "Balneário Morro dos Conventos",
    "stateId": 5257
  },
  {
    "cityId": 8360,
    "cityName": "Bandeirante",
    "stateId": 5257
  },
  {
    "cityId": 8361,
    "cityName": "Barra Bonita",
    "stateId": 5257
  },
  {
    "cityId": 8362,
    "cityName": "Barra Clara",
    "stateId": 5257
  },
  {
    "cityId": 8363,
    "cityName": "Barra da Lagoa",
    "stateId": 5257
  },
  {
    "cityId": 8364,
    "cityName": "Barra da Prata",
    "stateId": 5257
  },
  {
    "cityId": 8365,
    "cityName": "Barra Fria",
    "stateId": 5257
  },
  {
    "cityId": 8366,
    "cityName": "Barra Grande",
    "stateId": 5257
  },
  {
    "cityId": 8367,
    "cityName": "Barra Velha",
    "stateId": 5257
  },
  {
    "cityId": 8368,
    "cityName": "Barreiros",
    "stateId": 5257
  },
  {
    "cityId": 8369,
    "cityName": "Barro Branco",
    "stateId": 5257
  },
  {
    "cityId": 8370,
    "cityName": "Bateias de Baixo",
    "stateId": 5257
  },
  {
    "cityId": 8371,
    "cityName": "Bela Vista",
    "stateId": 5257
  },
  {
    "cityId": 8372,
    "cityName": "Bela Vista do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8373,
    "cityName": "Bela Vista do Toldo",
    "stateId": 5257
  },
  {
    "cityId": 8374,
    "cityName": "Belmonte",
    "stateId": 5257
  },
  {
    "cityId": 8375,
    "cityName": "Benedito Novo",
    "stateId": 5257
  },
  {
    "cityId": 8376,
    "cityName": "Biguaçu",
    "stateId": 5257
  },
  {
    "cityId": 8377,
    "cityName": "Blumenau",
    "stateId": 5257
  },
  {
    "cityId": 8378,
    "cityName": "Bocaína do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8379,
    "cityName": "Boiteuxburgo",
    "stateId": 5257
  },
  {
    "cityId": 8380,
    "cityName": "Bom Jardim da Serra",
    "stateId": 5257
  },
  {
    "cityId": 8381,
    "cityName": "Bom Jesus",
    "stateId": 5257
  },
  {
    "cityId": 8382,
    "cityName": "Bom Jesus do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8383,
    "cityName": "Bom Retiro",
    "stateId": 5257
  },
  {
    "cityId": 8384,
    "cityName": "Bom Sucesso",
    "stateId": 5257
  },
  {
    "cityId": 8385,
    "cityName": "Bombinhas",
    "stateId": 5257
  },
  {
    "cityId": 8386,
    "cityName": "Botuverá",
    "stateId": 5257
  },
  {
    "cityId": 8387,
    "cityName": "Braço do Norte",
    "stateId": 5257
  },
  {
    "cityId": 8388,
    "cityName": "Braço do Trombudo",
    "stateId": 5257
  },
  {
    "cityId": 8389,
    "cityName": "Brunópolis",
    "stateId": 5257
  },
  {
    "cityId": 8390,
    "cityName": "Brusque",
    "stateId": 5257
  },
  {
    "cityId": 8391,
    "cityName": "Caçador",
    "stateId": 5257
  },
  {
    "cityId": 8392,
    "cityName": "Cachoeira de Fátima",
    "stateId": 5257
  },
  {
    "cityId": 8393,
    "cityName": "Cachoeira do Bom Jesus",
    "stateId": 5257
  },
  {
    "cityId": 8394,
    "cityName": "Caibi",
    "stateId": 5257
  },
  {
    "cityId": 8395,
    "cityName": "Calmon",
    "stateId": 5257
  },
  {
    "cityId": 8396,
    "cityName": "Camboriú",
    "stateId": 5257
  },
  {
    "cityId": 8397,
    "cityName": "Cambuinzal",
    "stateId": 5257
  },
  {
    "cityId": 8398,
    "cityName": "Campeche",
    "stateId": 5257
  },
  {
    "cityId": 8399,
    "cityName": "Campinas",
    "stateId": 5257
  },
  {
    "cityId": 8400,
    "cityName": "Campo Alegre",
    "stateId": 5257
  },
  {
    "cityId": 8401,
    "cityName": "Campo Belo do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8402,
    "cityName": "Campo Erê",
    "stateId": 5257
  },
  {
    "cityId": 8403,
    "cityName": "Campos Novos",
    "stateId": 5257
  },
  {
    "cityId": 8404,
    "cityName": "Canasvieiras",
    "stateId": 5257
  },
  {
    "cityId": 8405,
    "cityName": "Canelinha",
    "stateId": 5257
  },
  {
    "cityId": 8406,
    "cityName": "Canoas",
    "stateId": 5257
  },
  {
    "cityId": 8407,
    "cityName": "Canoinhas",
    "stateId": 5257
  },
  {
    "cityId": 8408,
    "cityName": "Capão Alto",
    "stateId": 5257
  },
  {
    "cityId": 8409,
    "cityName": "Capinzal",
    "stateId": 5257
  },
  {
    "cityId": 8410,
    "cityName": "Capivari de Baixo",
    "stateId": 5257
  },
  {
    "cityId": 8411,
    "cityName": "Caraíba",
    "stateId": 5257
  },
  {
    "cityId": 8412,
    "cityName": "Catanduvas",
    "stateId": 5257
  },
  {
    "cityId": 8413,
    "cityName": "Catuíra",
    "stateId": 5257
  },
  {
    "cityId": 8414,
    "cityName": "Caxambu do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8415,
    "cityName": "Cedro Alto",
    "stateId": 5257
  },
  {
    "cityId": 8416,
    "cityName": "Celso Ramos",
    "stateId": 5257
  },
  {
    "cityId": 8417,
    "cityName": "Cerro Negro",
    "stateId": 5257
  },
  {
    "cityId": 8418,
    "cityName": "Chapadão do Lageado",
    "stateId": 5257
  },
  {
    "cityId": 8419,
    "cityName": "Chapecó",
    "stateId": 5257
  },
  {
    "cityId": 8420,
    "cityName": "Claraíba",
    "stateId": 5257
  },
  {
    "cityId": 8421,
    "cityName": "Cocal do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8422,
    "cityName": "Colônia Santa Tereza",
    "stateId": 5257
  },
  {
    "cityId": 8424,
    "cityName": "Concórdia",
    "stateId": 5257
  },
  {
    "cityId": 8425,
    "cityName": "Cordilheira Alta",
    "stateId": 5257
  },
  {
    "cityId": 8426,
    "cityName": "Coronel Freitas",
    "stateId": 5257
  },
  {
    "cityId": 8427,
    "cityName": "Coronel Martins",
    "stateId": 5257
  },
  {
    "cityId": 8428,
    "cityName": "Correia Pinto",
    "stateId": 5257
  },
  {
    "cityId": 8429,
    "cityName": "Corupá",
    "stateId": 5257
  },
  {
    "cityId": 8430,
    "cityName": "Criciúma",
    "stateId": 5257
  },
  {
    "cityId": 8431,
    "cityName": "Cunha Porã",
    "stateId": 5257
  },
  {
    "cityId": 8432,
    "cityName": "Cunhataí",
    "stateId": 5257
  },
  {
    "cityId": 8433,
    "cityName": "Curitibanos",
    "stateId": 5257
  },
  {
    "cityId": 8434,
    "cityName": "Dal Pai",
    "stateId": 5257
  },
  {
    "cityId": 8435,
    "cityName": "Dalbérgia",
    "stateId": 5257
  },
  {
    "cityId": 8436,
    "cityName": "Descanso",
    "stateId": 5257
  },
  {
    "cityId": 8437,
    "cityName": "Dionísio Cerqueira",
    "stateId": 5257
  },
  {
    "cityId": 8438,
    "cityName": "Dona Emma",
    "stateId": 5257
  },
  {
    "cityId": 8439,
    "cityName": "Doutor Pedrinho",
    "stateId": 5257
  },
  {
    "cityId": 8440,
    "cityName": "Engenho Velho",
    "stateId": 5257
  },
  {
    "cityId": 8441,
    "cityName": "Enseada de Brito",
    "stateId": 5257
  },
  {
    "cityId": 8442,
    "cityName": "Entre Rios",
    "stateId": 5257
  },
  {
    "cityId": 8443,
    "cityName": "Ermo",
    "stateId": 5257
  },
  {
    "cityId": 8444,
    "cityName": "Erval Velho",
    "stateId": 5257
  },
  {
    "cityId": 8445,
    "cityName": "Espinilho",
    "stateId": 5257
  },
  {
    "cityId": 8446,
    "cityName": "Estação Cocal",
    "stateId": 5257
  },
  {
    "cityId": 8447,
    "cityName": "Faxinal dos Guedes",
    "stateId": 5257
  },
  {
    "cityId": 8448,
    "cityName": "Fazenda Zandavalli",
    "stateId": 5257
  },
  {
    "cityId": 8449,
    "cityName": "Felipe Schmidt",
    "stateId": 5257
  },
  {
    "cityId": 8450,
    "cityName": "Figueira",
    "stateId": 5257
  },
  {
    "cityId": 8451,
    "cityName": "Flor do Sertão",
    "stateId": 5257
  },
  {
    "cityId": 8452,
    "cityName": "Florianópolis",
    "stateId": 5257
  },
  {
    "cityId": 8453,
    "cityName": "Formosa do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8454,
    "cityName": "Forquilhinha",
    "stateId": 5257
  },
  {
    "cityId": 8455,
    "cityName": "Fragosos",
    "stateId": 5257
  },
  {
    "cityId": 8456,
    "cityName": "Fraiburgo",
    "stateId": 5257
  },
  {
    "cityId": 8457,
    "cityName": "Frederico Wastner",
    "stateId": 5257
  },
  {
    "cityId": 8458,
    "cityName": "Frei Rogério",
    "stateId": 5257
  },
  {
    "cityId": 8459,
    "cityName": "Galvão",
    "stateId": 5257
  },
  {
    "cityId": 8460,
    "cityName": "Garcia",
    "stateId": 5257
  },
  {
    "cityId": 8461,
    "cityName": "Garopaba",
    "stateId": 5257
  },
  {
    "cityId": 8462,
    "cityName": "Garuva",
    "stateId": 5257
  },
  {
    "cityId": 8463,
    "cityName": "Gaspar",
    "stateId": 5257
  },
  {
    "cityId": 8464,
    "cityName": "Goio-En",
    "stateId": 5257
  },
  {
    "cityId": 8465,
    "cityName": "Governador Celso Ramos",
    "stateId": 5257
  },
  {
    "cityId": 8466,
    "cityName": "Grão Pará",
    "stateId": 5257
  },
  {
    "cityId": 8467,
    "cityName": "Grápia",
    "stateId": 5257
  },
  {
    "cityId": 8468,
    "cityName": "Gravatal",
    "stateId": 5257
  },
  {
    "cityId": 8469,
    "cityName": "Guabiruba",
    "stateId": 5257
  },
  {
    "cityId": 8470,
    "cityName": "Guaporanga",
    "stateId": 5257
  },
  {
    "cityId": 8471,
    "cityName": "Guaraciaba",
    "stateId": 5257
  },
  {
    "cityId": 8472,
    "cityName": "Guaramirim",
    "stateId": 5257
  },
  {
    "cityId": 8473,
    "cityName": "Guarujá do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8474,
    "cityName": "Guatá",
    "stateId": 5257
  },
  {
    "cityId": 8475,
    "cityName": "Guatambú",
    "stateId": 5257
  },
  {
    "cityId": 8476,
    "cityName": "Hercílio Luz",
    "stateId": 5257
  },
  {
    "cityId": 8477,
    "cityName": "Herciliópolis",
    "stateId": 5257
  },
  {
    "cityId": 8478,
    "cityName": "Herval D'Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8479,
    "cityName": "Ibiam",
    "stateId": 5257
  },
  {
    "cityId": 8480,
    "cityName": "Ibicaré",
    "stateId": 5257
  },
  {
    "cityId": 8481,
    "cityName": "Ibicuí",
    "stateId": 5257
  },
  {
    "cityId": 8482,
    "cityName": "Ibirama",
    "stateId": 5257
  },
  {
    "cityId": 8483,
    "cityName": "Içara",
    "stateId": 5257
  },
  {
    "cityId": 8484,
    "cityName": "Ilhota",
    "stateId": 5257
  },
  {
    "cityId": 8485,
    "cityName": "Imaruí",
    "stateId": 5257
  },
  {
    "cityId": 8486,
    "cityName": "Imbituba",
    "stateId": 5257
  },
  {
    "cityId": 8487,
    "cityName": "Imbuia",
    "stateId": 5257
  },
  {
    "cityId": 8488,
    "cityName": "Indaial",
    "stateId": 5257
  },
  {
    "cityId": 8489,
    "cityName": "Índios",
    "stateId": 5257
  },
  {
    "cityId": 8490,
    "cityName": "Ingleses do Rio Vermelho",
    "stateId": 5257
  },
  {
    "cityId": 8491,
    "cityName": "Invernada",
    "stateId": 5257
  },
  {
    "cityId": 8492,
    "cityName": "Iomerê",
    "stateId": 5257
  },
  {
    "cityId": 8493,
    "cityName": "Ipira",
    "stateId": 5257
  },
  {
    "cityId": 8494,
    "cityName": "Ipoméia",
    "stateId": 5257
  },
  {
    "cityId": 8495,
    "cityName": "Iporã do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8496,
    "cityName": "Ipuaçu",
    "stateId": 5257
  },
  {
    "cityId": 8497,
    "cityName": "Ipumirim",
    "stateId": 5257
  },
  {
    "cityId": 8498,
    "cityName": "Iraceminha",
    "stateId": 5257
  },
  {
    "cityId": 8499,
    "cityName": "Irakitan",
    "stateId": 5257
  },
  {
    "cityId": 8500,
    "cityName": "Irani",
    "stateId": 5257
  },
  {
    "cityId": 8501,
    "cityName": "Iraputã",
    "stateId": 5257
  },
  {
    "cityId": 8502,
    "cityName": "Irati",
    "stateId": 5257
  },
  {
    "cityId": 8503,
    "cityName": "Irineópolis",
    "stateId": 5257
  },
  {
    "cityId": 8504,
    "cityName": "Itá",
    "stateId": 5257
  },
  {
    "cityId": 8505,
    "cityName": "Itaió",
    "stateId": 5257
  },
  {
    "cityId": 8506,
    "cityName": "Itaiópolis",
    "stateId": 5257
  },
  {
    "cityId": 8507,
    "cityName": "Itajaí",
    "stateId": 5257
  },
  {
    "cityId": 8508,
    "cityName": "Itajubá",
    "stateId": 5257
  },
  {
    "cityId": 8509,
    "cityName": "Itapema",
    "stateId": 5257
  },
  {
    "cityId": 8510,
    "cityName": "Itapiranga",
    "stateId": 5257
  },
  {
    "cityId": 8511,
    "cityName": "Itapoá",
    "stateId": 5257
  },
  {
    "cityId": 8512,
    "cityName": "Itapocu",
    "stateId": 5257
  },
  {
    "cityId": 8513,
    "cityName": "Itoupava",
    "stateId": 5257
  },
  {
    "cityId": 8514,
    "cityName": "Ituporanga",
    "stateId": 5257
  },
  {
    "cityId": 8515,
    "cityName": "Jaborá",
    "stateId": 5257
  },
  {
    "cityId": 8516,
    "cityName": "Jacinto Machado",
    "stateId": 5257
  },
  {
    "cityId": 8517,
    "cityName": "Jaguaruna",
    "stateId": 5257
  },
  {
    "cityId": 8518,
    "cityName": "Jaraguá do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8519,
    "cityName": "Jardinópolis",
    "stateId": 5257
  },
  {
    "cityId": 8520,
    "cityName": "Joaçaba",
    "stateId": 5257
  },
  {
    "cityId": 8521,
    "cityName": "Joinville",
    "stateId": 5257
  },
  {
    "cityId": 8522,
    "cityName": "José Boiteux",
    "stateId": 5257
  },
  {
    "cityId": 8523,
    "cityName": "Jupiá",
    "stateId": 5257
  },
  {
    "cityId": 8524,
    "cityName": "Lacerdópolis",
    "stateId": 5257
  },
  {
    "cityId": 8525,
    "cityName": "Lages",
    "stateId": 5257
  },
  {
    "cityId": 8526,
    "cityName": "Lagoa",
    "stateId": 5257
  },
  {
    "cityId": 8527,
    "cityName": "Lagoa da Estiva",
    "stateId": 5257
  },
  {
    "cityId": 8528,
    "cityName": "Laguna",
    "stateId": 5257
  },
  {
    "cityId": 8529,
    "cityName": "Lajeado Grande",
    "stateId": 5257
  },
  {
    "cityId": 8530,
    "cityName": "Laurentino",
    "stateId": 5257
  },
  {
    "cityId": 8531,
    "cityName": "Lauro Müller",
    "stateId": 5257
  },
  {
    "cityId": 8532,
    "cityName": "Leão",
    "stateId": 5257
  },
  {
    "cityId": 8533,
    "cityName": "Lebon Régis",
    "stateId": 5257
  },
  {
    "cityId": 8534,
    "cityName": "Leoberto Leal",
    "stateId": 5257
  },
  {
    "cityId": 8535,
    "cityName": "Lindóia do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8536,
    "cityName": "Linha das Palmeiras",
    "stateId": 5257
  },
  {
    "cityId": 8537,
    "cityName": "Lontras",
    "stateId": 5257
  },
  {
    "cityId": 8538,
    "cityName": "Lourdes",
    "stateId": 5257
  },
  {
    "cityId": 8539,
    "cityName": "Luiz Alves",
    "stateId": 5257
  },
  {
    "cityId": 8540,
    "cityName": "Luzerna",
    "stateId": 5257
  },
  {
    "cityId": 8541,
    "cityName": "Machados",
    "stateId": 5257
  },
  {
    "cityId": 8542,
    "cityName": "Macieira",
    "stateId": 5257
  },
  {
    "cityId": 8543,
    "cityName": "Mafra",
    "stateId": 5257
  },
  {
    "cityId": 8544,
    "cityName": "Major Gercino",
    "stateId": 5257
  },
  {
    "cityId": 8545,
    "cityName": "Major Vieira",
    "stateId": 5257
  },
  {
    "cityId": 8546,
    "cityName": "Maracajá",
    "stateId": 5257
  },
  {
    "cityId": 8547,
    "cityName": "Marari",
    "stateId": 5257
  },
  {
    "cityId": 8548,
    "cityName": "Maratá",
    "stateId": 5257
  },
  {
    "cityId": 8549,
    "cityName": "Maravilha",
    "stateId": 5257
  },
  {
    "cityId": 8550,
    "cityName": "Marcílio Dias",
    "stateId": 5257
  },
  {
    "cityId": 8551,
    "cityName": "Marechal Bormann",
    "stateId": 5257
  },
  {
    "cityId": 8552,
    "cityName": "Marema",
    "stateId": 5257
  },
  {
    "cityId": 8553,
    "cityName": "Mariflor",
    "stateId": 5257
  },
  {
    "cityId": 8554,
    "cityName": "Marombas",
    "stateId": 5257
  },
  {
    "cityId": 8555,
    "cityName": "Marombas Bossardi",
    "stateId": 5257
  },
  {
    "cityId": 8556,
    "cityName": "Massaranduba",
    "stateId": 5257
  },
  {
    "cityId": 8557,
    "cityName": "Matos Costa",
    "stateId": 5257
  },
  {
    "cityId": 8558,
    "cityName": "Meleiro",
    "stateId": 5257
  },
  {
    "cityId": 8559,
    "cityName": "Mirador",
    "stateId": 5257
  },
  {
    "cityId": 8560,
    "cityName": "Mirim",
    "stateId": 5257
  },
  {
    "cityId": 8561,
    "cityName": "Mirim Doce",
    "stateId": 5257
  },
  {
    "cityId": 8562,
    "cityName": "Modelo",
    "stateId": 5257
  },
  {
    "cityId": 8563,
    "cityName": "Mondaí",
    "stateId": 5257
  },
  {
    "cityId": 8564,
    "cityName": "Monte Alegre",
    "stateId": 5257
  },
  {
    "cityId": 8565,
    "cityName": "Monte Carlo",
    "stateId": 5257
  },
  {
    "cityId": 8566,
    "cityName": "Monte Castelo",
    "stateId": 5257
  },
  {
    "cityId": 8567,
    "cityName": "Morro Chato",
    "stateId": 5257
  },
  {
    "cityId": 8568,
    "cityName": "Morro da Fumaça",
    "stateId": 5257
  },
  {
    "cityId": 8570,
    "cityName": "Morro Grande",
    "stateId": 5257
  },
  {
    "cityId": 8571,
    "cityName": "Navegantes",
    "stateId": 5257
  },
  {
    "cityId": 8572,
    "cityName": "Nossa Senhora de Caravaggio",
    "stateId": 5257
  },
  {
    "cityId": 8573,
    "cityName": "Nova Cultura",
    "stateId": 5257
  },
  {
    "cityId": 8574,
    "cityName": "Nova Erechim",
    "stateId": 5257
  },
  {
    "cityId": 8575,
    "cityName": "Nova Guarita",
    "stateId": 5257
  },
  {
    "cityId": 8576,
    "cityName": "Nova Itaberaba",
    "stateId": 5257
  },
  {
    "cityId": 8577,
    "cityName": "Nova Petrópolis",
    "stateId": 5257
  },
  {
    "cityId": 8578,
    "cityName": "Nova Teutônia",
    "stateId": 5257
  },
  {
    "cityId": 8579,
    "cityName": "Nova Trento",
    "stateId": 5257
  },
  {
    "cityId": 8580,
    "cityName": "Nova Veneza",
    "stateId": 5257
  },
  {
    "cityId": 8581,
    "cityName": "Novo Horizonte",
    "stateId": 5257
  },
  {
    "cityId": 8582,
    "cityName": "Orleans",
    "stateId": 5257
  },
  {
    "cityId": 8583,
    "cityName": "Otacílio Costa",
    "stateId": 5257
  },
  {
    "cityId": 8584,
    "cityName": "Ouro",
    "stateId": 5257
  },
  {
    "cityId": 8585,
    "cityName": "Ouro Verde",
    "stateId": 5257
  },
  {
    "cityId": 8586,
    "cityName": "Ouro Verde",
    "stateId": 5257
  },
  {
    "cityId": 8587,
    "cityName": "Paial",
    "stateId": 5257
  },
  {
    "cityId": 8588,
    "cityName": "Painel",
    "stateId": 5257
  },
  {
    "cityId": 8589,
    "cityName": "Palhoça",
    "stateId": 5257
  },
  {
    "cityId": 8590,
    "cityName": "Palma Sola",
    "stateId": 5257
  },
  {
    "cityId": 8591,
    "cityName": "Palmeira",
    "stateId": 5257
  },
  {
    "cityId": 8592,
    "cityName": "Palmitos",
    "stateId": 5257
  },
  {
    "cityId": 8593,
    "cityName": "Pântano do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8594,
    "cityName": "Papanduva",
    "stateId": 5257
  },
  {
    "cityId": 8595,
    "cityName": "Paraíso",
    "stateId": 5257
  },
  {
    "cityId": 8596,
    "cityName": "Passo de Torres",
    "stateId": 5257
  },
  {
    "cityId": 8597,
    "cityName": "Passo Manso",
    "stateId": 5257
  },
  {
    "cityId": 8598,
    "cityName": "Passos Maia",
    "stateId": 5257
  },
  {
    "cityId": 8599,
    "cityName": "Paula Pereira",
    "stateId": 5257
  },
  {
    "cityId": 8600,
    "cityName": "Paulo Lopes",
    "stateId": 5257
  },
  {
    "cityId": 8601,
    "cityName": "Pedras Grandes",
    "stateId": 5257
  },
  {
    "cityId": 8602,
    "cityName": "Penha",
    "stateId": 5257
  },
  {
    "cityId": 8603,
    "cityName": "Pericó",
    "stateId": 5257
  },
  {
    "cityId": 8604,
    "cityName": "Peritiba",
    "stateId": 5257
  },
  {
    "cityId": 8605,
    "cityName": "Pescaria Brava",
    "stateId": 5257
  },
  {
    "cityId": 8606,
    "cityName": "Petrolândia",
    "stateId": 5257
  },
  {
    "cityId": 8607,
    "cityName": "Balneário Piçarras",
    "stateId": 5257
  },
  {
    "cityId": 8608,
    "cityName": "Pindotiba",
    "stateId": 5257
  },
  {
    "cityId": 8609,
    "cityName": "Pinhalzinho",
    "stateId": 5257
  },
  {
    "cityId": 8610,
    "cityName": "Pinheiral",
    "stateId": 5257
  },
  {
    "cityId": 8611,
    "cityName": "Pinheiro Preto",
    "stateId": 5257
  },
  {
    "cityId": 8612,
    "cityName": "Pinheiros",
    "stateId": 5257
  },
  {
    "cityId": 8613,
    "cityName": "Pirabeiraba",
    "stateId": 5257
  },
  {
    "cityId": 8614,
    "cityName": "Piratuba",
    "stateId": 5257
  },
  {
    "cityId": 8615,
    "cityName": "Planalto",
    "stateId": 5257
  },
  {
    "cityId": 8616,
    "cityName": "Planalto Alegre",
    "stateId": 5257
  },
  {
    "cityId": 8617,
    "cityName": "Poço Preto",
    "stateId": 5257
  },
  {
    "cityId": 8618,
    "cityName": "Pomerode",
    "stateId": 5257
  },
  {
    "cityId": 8619,
    "cityName": "Ponte Alta",
    "stateId": 5257
  },
  {
    "cityId": 8620,
    "cityName": "Ponte Alta do Norte",
    "stateId": 5257
  },
  {
    "cityId": 8621,
    "cityName": "Ponte Serrada",
    "stateId": 5257
  },
  {
    "cityId": 8622,
    "cityName": "Porto Belo",
    "stateId": 5257
  },
  {
    "cityId": 8623,
    "cityName": "Porto União",
    "stateId": 5257
  },
  {
    "cityId": 8624,
    "cityName": "Pouso Redondo",
    "stateId": 5257
  },
  {
    "cityId": 8625,
    "cityName": "Praia Grande",
    "stateId": 5257
  },
  {
    "cityId": 8626,
    "cityName": "Prata",
    "stateId": 5257
  },
  {
    "cityId": 8627,
    "cityName": "Presidente Castelo Branco",
    "stateId": 5257
  },
  {
    "cityId": 8628,
    "cityName": "Presidente Getúlio",
    "stateId": 5257
  },
  {
    "cityId": 8629,
    "cityName": "Presidente Juscelino",
    "stateId": 5257
  },
  {
    "cityId": 8630,
    "cityName": "Presidente Kennedy",
    "stateId": 5257
  },
  {
    "cityId": 8631,
    "cityName": "Presidente Nereu",
    "stateId": 5257
  },
  {
    "cityId": 8632,
    "cityName": "Princesa",
    "stateId": 5257
  },
  {
    "cityId": 8633,
    "cityName": "Quarta Linha",
    "stateId": 5257
  },
  {
    "cityId": 8634,
    "cityName": "Quilombo",
    "stateId": 5257
  },
  {
    "cityId": 8635,
    "cityName": "Quilômetro Doze",
    "stateId": 5257
  },
  {
    "cityId": 8636,
    "cityName": "Rancho Queimado",
    "stateId": 5257
  },
  {
    "cityId": 8637,
    "cityName": "Ratones",
    "stateId": 5257
  },
  {
    "cityId": 8638,
    "cityName": "Residência Fuck",
    "stateId": 5257
  },
  {
    "cityId": 8639,
    "cityName": "Ribeirão da Ilha",
    "stateId": 5257
  },
  {
    "cityId": 8640,
    "cityName": "Ribeirão Pequeno",
    "stateId": 5257
  },
  {
    "cityId": 8641,
    "cityName": "Rio Antinha",
    "stateId": 5257
  },
  {
    "cityId": 8642,
    "cityName": "Rio Bonito",
    "stateId": 5257
  },
  {
    "cityId": 8643,
    "cityName": "Rio D'Una",
    "stateId": 5257
  },
  {
    "cityId": 8644,
    "cityName": "Rio da Anta",
    "stateId": 5257
  },
  {
    "cityId": 8645,
    "cityName": "Rio da Luz",
    "stateId": 5257
  },
  {
    "cityId": 8646,
    "cityName": "Rio das Antas",
    "stateId": 5257
  },
  {
    "cityId": 8647,
    "cityName": "Rio das Furnas",
    "stateId": 5257
  },
  {
    "cityId": 8648,
    "cityName": "Rio do Campo",
    "stateId": 5257
  },
  {
    "cityId": 8649,
    "cityName": "Rio do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8650,
    "cityName": "Rio do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8651,
    "cityName": "Rio dos Bugres",
    "stateId": 5257
  },
  {
    "cityId": 8652,
    "cityName": "Rio dos Cedros",
    "stateId": 5257
  },
  {
    "cityId": 8653,
    "cityName": "Rio Fortuna",
    "stateId": 5257
  },
  {
    "cityId": 8654,
    "cityName": "Rio Maina",
    "stateId": 5257
  },
  {
    "cityId": 8655,
    "cityName": "Rio Negrinho",
    "stateId": 5257
  },
  {
    "cityId": 8656,
    "cityName": "Rio Preto do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8657,
    "cityName": "Rio Rufino",
    "stateId": 5257
  },
  {
    "cityId": 8658,
    "cityName": "Riqueza",
    "stateId": 5257
  },
  {
    "cityId": 8659,
    "cityName": "Rodeio",
    "stateId": 5257
  },
  {
    "cityId": 8660,
    "cityName": "Romelândia",
    "stateId": 5257
  },
  {
    "cityId": 8661,
    "cityName": "Sai",
    "stateId": 5257
  },
  {
    "cityId": 8662,
    "cityName": "Salete",
    "stateId": 5257
  },
  {
    "cityId": 8663,
    "cityName": "Saltinho",
    "stateId": 5257
  },
  {
    "cityId": 8664,
    "cityName": "Salto Veloso",
    "stateId": 5257
  },
  {
    "cityId": 8665,
    "cityName": "Sanga da Toca",
    "stateId": 5257
  },
  {
    "cityId": 8666,
    "cityName": "Sangão",
    "stateId": 5257
  },
  {
    "cityId": 8667,
    "cityName": "Santa Cecília",
    "stateId": 5257
  },
  {
    "cityId": 8668,
    "cityName": "Santa Cruz do Timbó",
    "stateId": 5257
  },
  {
    "cityId": 8669,
    "cityName": "Santa Helena",
    "stateId": 5257
  },
  {
    "cityId": 8670,
    "cityName": "Santa Helena",
    "stateId": 5257
  },
  {
    "cityId": 8671,
    "cityName": "Santa Izabel",
    "stateId": 5257
  },
  {
    "cityId": 8672,
    "cityName": "Santa Lúcia",
    "stateId": 5257
  },
  {
    "cityId": 8673,
    "cityName": "Santa Lúcia",
    "stateId": 5257
  },
  {
    "cityId": 8674,
    "cityName": "Santa Maria",
    "stateId": 5257
  },
  {
    "cityId": 8675,
    "cityName": "Santa Rosa de Lima",
    "stateId": 5257
  },
  {
    "cityId": 8676,
    "cityName": "Santa Rosa do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8677,
    "cityName": "Santa Terezinha",
    "stateId": 5257
  },
  {
    "cityId": 8678,
    "cityName": "Santa Terezinha do Progresso",
    "stateId": 5257
  },
  {
    "cityId": 8679,
    "cityName": "Santa Terezinha do Salto",
    "stateId": 5257
  },
  {
    "cityId": 8680,
    "cityName": "Santiago do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8681,
    "cityName": "Santo Amaro da Imperatriz",
    "stateId": 5257
  },
  {
    "cityId": 8682,
    "cityName": "Santo Antônio de Lisboa",
    "stateId": 5257
  },
  {
    "cityId": 8683,
    "cityName": "São Bento Baixo",
    "stateId": 5257
  },
  {
    "cityId": 8684,
    "cityName": "São Bento do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8685,
    "cityName": "São Bernardino",
    "stateId": 5257
  },
  {
    "cityId": 8686,
    "cityName": "São Bonifácio",
    "stateId": 5257
  },
  {
    "cityId": 8687,
    "cityName": "São Carlos",
    "stateId": 5257
  },
  {
    "cityId": 8688,
    "cityName": "São Cristóvão",
    "stateId": 5257
  },
  {
    "cityId": 8689,
    "cityName": "São Cristóvão do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8690,
    "cityName": "São Defende",
    "stateId": 5257
  },
  {
    "cityId": 8691,
    "cityName": "São Domingos",
    "stateId": 5257
  },
  {
    "cityId": 8692,
    "cityName": "São Francisco do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8693,
    "cityName": "São Gabriel",
    "stateId": 5257
  },
  {
    "cityId": 8694,
    "cityName": "São João Batista",
    "stateId": 5257
  },
  {
    "cityId": 8695,
    "cityName": "São João do Itaperiú",
    "stateId": 5257
  },
  {
    "cityId": 8696,
    "cityName": "São João do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8697,
    "cityName": "São João do Rio Vermelho",
    "stateId": 5257
  },
  {
    "cityId": 8698,
    "cityName": "São João do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8699,
    "cityName": "São Joaquim",
    "stateId": 5257
  },
  {
    "cityId": 8700,
    "cityName": "São José",
    "stateId": 5257
  },
  {
    "cityId": 8701,
    "cityName": "São José do Cedro",
    "stateId": 5257
  },
  {
    "cityId": 8702,
    "cityName": "São José do Cerrito",
    "stateId": 5257
  },
  {
    "cityId": 8703,
    "cityName": "São José do Laranjal",
    "stateId": 5257
  },
  {
    "cityId": 8704,
    "cityName": "São Leonardo",
    "stateId": 5257
  },
  {
    "cityId": 8705,
    "cityName": "São Lourenço do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8706,
    "cityName": "São Ludgero",
    "stateId": 5257
  },
  {
    "cityId": 8707,
    "cityName": "São Martinho",
    "stateId": 5257
  },
  {
    "cityId": 8708,
    "cityName": "São Miguel do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8709,
    "cityName": "São Miguel da Boa Vista",
    "stateId": 5257
  },
  {
    "cityId": 8710,
    "cityName": "São Miguel da Serra",
    "stateId": 5257
  },
  {
    "cityId": 8711,
    "cityName": "São Pedro de Alcântara",
    "stateId": 5257
  },
  {
    "cityId": 8712,
    "cityName": "São Pedro Tobias",
    "stateId": 5257
  },
  {
    "cityId": 8713,
    "cityName": "São Roque",
    "stateId": 5257
  },
  {
    "cityId": 8714,
    "cityName": "São Sebastião do Arvoredo",
    "stateId": 5257
  },
  {
    "cityId": 8715,
    "cityName": "São Sebastião do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8716,
    "cityName": "Sapiranga",
    "stateId": 5257
  },
  {
    "cityId": 8717,
    "cityName": "Saudades",
    "stateId": 5257
  },
  {
    "cityId": 8718,
    "cityName": "Schroeder",
    "stateId": 5257
  },
  {
    "cityId": 8719,
    "cityName": "Seara",
    "stateId": 5257
  },
  {
    "cityId": 8720,
    "cityName": "Sede Oldemburg",
    "stateId": 5257
  },
  {
    "cityId": 8721,
    "cityName": "Serra Alta",
    "stateId": 5257
  },
  {
    "cityId": 8723,
    "cityName": "Siderópolis",
    "stateId": 5257
  },
  {
    "cityId": 8724,
    "cityName": "Sombrio",
    "stateId": 5257
  },
  {
    "cityId": 8725,
    "cityName": "Sorocaba do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8726,
    "cityName": "Sul Brasil",
    "stateId": 5257
  },
  {
    "cityId": 8727,
    "cityName": "Taió",
    "stateId": 5257
  },
  {
    "cityId": 8728,
    "cityName": "Tangará",
    "stateId": 5257
  },
  {
    "cityId": 8729,
    "cityName": "Taquara Verde",
    "stateId": 5257
  },
  {
    "cityId": 8730,
    "cityName": "Taquaras",
    "stateId": 5257
  },
  {
    "cityId": 8731,
    "cityName": "Tigipió",
    "stateId": 5257
  },
  {
    "cityId": 8732,
    "cityName": "Tigrinhos",
    "stateId": 5257
  },
  {
    "cityId": 8733,
    "cityName": "Tijucas",
    "stateId": 5257
  },
  {
    "cityId": 8734,
    "cityName": "Timbé do Sul",
    "stateId": 5257
  },
  {
    "cityId": 8735,
    "cityName": "Timbó",
    "stateId": 5257
  },
  {
    "cityId": 8736,
    "cityName": "Timbó Grande",
    "stateId": 5257
  },
  {
    "cityId": 8737,
    "cityName": "Três Barras",
    "stateId": 5257
  },
  {
    "cityId": 8738,
    "cityName": "Treviso",
    "stateId": 5257
  },
  {
    "cityId": 8739,
    "cityName": "Treze de Maio",
    "stateId": 5257
  },
  {
    "cityId": 8740,
    "cityName": "Treze Tílias",
    "stateId": 5257
  },
  {
    "cityId": 8741,
    "cityName": "Trombudo Central",
    "stateId": 5257
  },
  {
    "cityId": 8742,
    "cityName": "Tubarão",
    "stateId": 5257
  },
  {
    "cityId": 8743,
    "cityName": "Tunápolis",
    "stateId": 5257
  },
  {
    "cityId": 8744,
    "cityName": "Tupitinga",
    "stateId": 5257
  },
  {
    "cityId": 8745,
    "cityName": "Turvo",
    "stateId": 5257
  },
  {
    "cityId": 8746,
    "cityName": "União do Oeste",
    "stateId": 5257
  },
  {
    "cityId": 8747,
    "cityName": "Urubici",
    "stateId": 5257
  },
  {
    "cityId": 8748,
    "cityName": "Uruguai",
    "stateId": 5257
  },
  {
    "cityId": 8749,
    "cityName": "Urupema",
    "stateId": 5257
  },
  {
    "cityId": 8750,
    "cityName": "Urussanga",
    "stateId": 5257
  },
  {
    "cityId": 8751,
    "cityName": "Vargeão",
    "stateId": 5257
  },
  {
    "cityId": 8752,
    "cityName": "Vargem",
    "stateId": 5257
  },
  {
    "cityId": 8753,
    "cityName": "Vargem Bonita",
    "stateId": 5257
  },
  {
    "cityId": 8754,
    "cityName": "Vargem do Cedro",
    "stateId": 5257
  },
  {
    "cityId": 8755,
    "cityName": "Vidal Ramos",
    "stateId": 5257
  },
  {
    "cityId": 8756,
    "cityName": "Videira",
    "stateId": 5257
  },
  {
    "cityId": 8757,
    "cityName": "Vila Conceição",
    "stateId": 5257
  },
  {
    "cityId": 8758,
    "cityName": "Vila de Volta Grande",
    "stateId": 5257
  },
  {
    "cityId": 8759,
    "cityName": "Vila Milani",
    "stateId": 5257
  },
  {
    "cityId": 8760,
    "cityName": "Vila Nova",
    "stateId": 5257
  },
  {
    "cityId": 8761,
    "cityName": "Vítor Meireles",
    "stateId": 5257
  },
  {
    "cityId": 8762,
    "cityName": "Witmarsum",
    "stateId": 5257
  },
  {
    "cityId": 8763,
    "cityName": "Xanxerê",
    "stateId": 5257
  },
  {
    "cityId": 8764,
    "cityName": "Xavantina",
    "stateId": 5257
  },
  {
    "cityId": 8765,
    "cityName": "Xaxim",
    "stateId": 5257
  },
  {
    "cityId": 8766,
    "cityName": "Zortéa",
    "stateId": 5257
  },
  {
    "cityId": 8767,
    "cityName": "Altos Verdes",
    "stateId": 5258
  },
  {
    "cityId": 8768,
    "cityName": "Amparo de São Francisco",
    "stateId": 5258
  },
  {
    "cityId": 8769,
    "cityName": "Aquidabã",
    "stateId": 5258
  },
  {
    "cityId": 8770,
    "cityName": "Aracaju",
    "stateId": 5258
  },
  {
    "cityId": 8771,
    "cityName": "Arauá",
    "stateId": 5258
  },
  {
    "cityId": 8772,
    "cityName": "Areia Branca",
    "stateId": 5258
  },
  {
    "cityId": 8773,
    "cityName": "Areia Branca",
    "stateId": 5258
  },
  {
    "cityId": 8774,
    "cityName": "Barra dos Coqueiros",
    "stateId": 5258
  },
  {
    "cityId": 8775,
    "cityName": "Barracas",
    "stateId": 5258
  },
  {
    "cityId": 8776,
    "cityName": "Boquim",
    "stateId": 5258
  },
  {
    "cityId": 8777,
    "cityName": "Brejo Grande",
    "stateId": 5258
  },
  {
    "cityId": 8778,
    "cityName": "Campo do Brito",
    "stateId": 5258
  },
  {
    "cityId": 8779,
    "cityName": "Canhoba",
    "stateId": 5258
  },
  {
    "cityId": 8780,
    "cityName": "Canindé de São Francisco",
    "stateId": 5258
  },
  {
    "cityId": 8781,
    "cityName": "Capela",
    "stateId": 5258
  },
  {
    "cityId": 8782,
    "cityName": "Carira",
    "stateId": 5258
  },
  {
    "cityId": 8783,
    "cityName": "Carmópolis",
    "stateId": 5258
  },
  {
    "cityId": 8784,
    "cityName": "Cedro de São João",
    "stateId": 5258
  },
  {
    "cityId": 8785,
    "cityName": "Cristinápolis",
    "stateId": 5258
  },
  {
    "cityId": 8786,
    "cityName": "Cumbe",
    "stateId": 5258
  },
  {
    "cityId": 8787,
    "cityName": "Divina Pastora",
    "stateId": 5258
  },
  {
    "cityId": 8788,
    "cityName": "Estância",
    "stateId": 5258
  },
  {
    "cityId": 8789,
    "cityName": "Feira Nova",
    "stateId": 5258
  },
  {
    "cityId": 8790,
    "cityName": "Frei Paulo",
    "stateId": 5258
  },
  {
    "cityId": 8791,
    "cityName": "Gararu",
    "stateId": 5258
  },
  {
    "cityId": 8792,
    "cityName": "General Maynard",
    "stateId": 5258
  },
  {
    "cityId": 8793,
    "cityName": "Graccho Cardoso",
    "stateId": 5258
  },
  {
    "cityId": 8794,
    "cityName": "Ilha das Flores",
    "stateId": 5258
  },
  {
    "cityId": 8795,
    "cityName": "Indiaroba",
    "stateId": 5258
  },
  {
    "cityId": 8796,
    "cityName": "Itabaiana",
    "stateId": 5258
  },
  {
    "cityId": 8797,
    "cityName": "Itabaianinha",
    "stateId": 5258
  },
  {
    "cityId": 8798,
    "cityName": "Itabi",
    "stateId": 5258
  },
  {
    "cityId": 8799,
    "cityName": "Itaporanga D'Ajuda",
    "stateId": 5258
  },
  {
    "cityId": 8800,
    "cityName": "Japaratuba",
    "stateId": 5258
  },
  {
    "cityId": 8801,
    "cityName": "Japoatã",
    "stateId": 5258
  },
  {
    "cityId": 8802,
    "cityName": "Lagarto",
    "stateId": 5258
  },
  {
    "cityId": 8803,
    "cityName": "Lagoa Funda",
    "stateId": 5258
  },
  {
    "cityId": 8804,
    "cityName": "Laranjeiras",
    "stateId": 5258
  },
  {
    "cityId": 8805,
    "cityName": "Macambira",
    "stateId": 5258
  },
  {
    "cityId": 8806,
    "cityName": "Malhada dos Bois",
    "stateId": 5258
  },
  {
    "cityId": 8807,
    "cityName": "Malhador",
    "stateId": 5258
  },
  {
    "cityId": 8808,
    "cityName": "Maruim",
    "stateId": 5258
  },
  {
    "cityId": 8809,
    "cityName": "Miranda",
    "stateId": 5258
  },
  {
    "cityId": 8810,
    "cityName": "Moita Bonita",
    "stateId": 5258
  },
  {
    "cityId": 8811,
    "cityName": "Monte Alegre de Sergipe",
    "stateId": 5258
  },
  {
    "cityId": 8812,
    "cityName": "Mosqueiro",
    "stateId": 5258
  },
  {
    "cityId": 8813,
    "cityName": "Muribeca",
    "stateId": 5258
  },
  {
    "cityId": 8814,
    "cityName": "Neópolis",
    "stateId": 5258
  },
  {
    "cityId": 8815,
    "cityName": "Nossa Senhora Aparecida",
    "stateId": 5258
  },
  {
    "cityId": 8816,
    "cityName": "Nossa Senhora da Glória",
    "stateId": 5258
  },
  {
    "cityId": 8817,
    "cityName": "Nossa Senhora das Dores",
    "stateId": 5258
  },
  {
    "cityId": 8818,
    "cityName": "Nossa Senhora de Lourdes",
    "stateId": 5258
  },
  {
    "cityId": 8819,
    "cityName": "Nossa Senhora do Socorro",
    "stateId": 5258
  },
  {
    "cityId": 8820,
    "cityName": "Pacatuba",
    "stateId": 5258
  },
  {
    "cityId": 8821,
    "cityName": "Palmares",
    "stateId": 5258
  },
  {
    "cityId": 8822,
    "cityName": "Pedra Mole",
    "stateId": 5258
  },
  {
    "cityId": 8823,
    "cityName": "Pedras",
    "stateId": 5258
  },
  {
    "cityId": 8824,
    "cityName": "Pedrinhas",
    "stateId": 5258
  },
  {
    "cityId": 8825,
    "cityName": "Pinhão",
    "stateId": 5258
  },
  {
    "cityId": 8826,
    "cityName": "Pirambu",
    "stateId": 5258
  },
  {
    "cityId": 8827,
    "cityName": "Poço Redondo",
    "stateId": 5258
  },
  {
    "cityId": 8828,
    "cityName": "Poço Verde",
    "stateId": 5258
  },
  {
    "cityId": 8829,
    "cityName": "Porto da Folha",
    "stateId": 5258
  },
  {
    "cityId": 8830,
    "cityName": "Propriá",
    "stateId": 5258
  },
  {
    "cityId": 8831,
    "cityName": "Riachão do Dantas",
    "stateId": 5258
  },
  {
    "cityId": 8832,
    "cityName": "Riachuelo",
    "stateId": 5258
  },
  {
    "cityId": 8833,
    "cityName": "Ribeirópolis",
    "stateId": 5258
  },
  {
    "cityId": 8834,
    "cityName": "Rosário do Catete",
    "stateId": 5258
  },
  {
    "cityId": 8835,
    "cityName": "Salgado",
    "stateId": 5258
  },
  {
    "cityId": 8836,
    "cityName": "Samambaia",
    "stateId": 5258
  },
  {
    "cityId": 8837,
    "cityName": "Santa Luzia do Itanhy",
    "stateId": 5258
  },
  {
    "cityId": 8838,
    "cityName": "Santa Rosa de Lima",
    "stateId": 5258
  },
  {
    "cityId": 8839,
    "cityName": "Santana do São Francisco",
    "stateId": 5258
  },
  {
    "cityId": 8840,
    "cityName": "Santo Amaro das Brotas",
    "stateId": 5258
  },
  {
    "cityId": 8841,
    "cityName": "São Cristóvão",
    "stateId": 5258
  },
  {
    "cityId": 8842,
    "cityName": "São Domingos",
    "stateId": 5258
  },
  {
    "cityId": 8843,
    "cityName": "São Francisco",
    "stateId": 5258
  },
  {
    "cityId": 8844,
    "cityName": "São José",
    "stateId": 5258
  },
  {
    "cityId": 8845,
    "cityName": "São Mateus da Palestina",
    "stateId": 5258
  },
  {
    "cityId": 8846,
    "cityName": "São Miguel do Aleixo",
    "stateId": 5258
  },
  {
    "cityId": 8847,
    "cityName": "Simão Dias",
    "stateId": 5258
  },
  {
    "cityId": 8848,
    "cityName": "Siriri",
    "stateId": 5258
  },
  {
    "cityId": 8849,
    "cityName": "Telha",
    "stateId": 5258
  },
  {
    "cityId": 8850,
    "cityName": "Tobias Barreto",
    "stateId": 5258
  },
  {
    "cityId": 8851,
    "cityName": "Tomar do Geru",
    "stateId": 5258
  },
  {
    "cityId": 8852,
    "cityName": "Umbaúba",
    "stateId": 5258
  },
  {
    "cityId": 8853,
    "cityName": "Adamantina",
    "stateId": 5259
  },
  {
    "cityId": 8854,
    "cityName": "Adolfo",
    "stateId": 5259
  },
  {
    "cityId": 8855,
    "cityName": "Agisse",
    "stateId": 5259
  },
  {
    "cityId": 8856,
    "cityName": "Água Vermelha",
    "stateId": 5259
  },
  {
    "cityId": 8857,
    "cityName": "Aguaí",
    "stateId": 5259
  },
  {
    "cityId": 8858,
    "cityName": "Águas da Prata",
    "stateId": 5259
  },
  {
    "cityId": 8859,
    "cityName": "Águas de Lindóia",
    "stateId": 5259
  },
  {
    "cityId": 8860,
    "cityName": "Águas de Santa Bárbara",
    "stateId": 5259
  },
  {
    "cityId": 8861,
    "cityName": "Águas de São Pedro",
    "stateId": 5259
  },
  {
    "cityId": 8862,
    "cityName": "Agudos",
    "stateId": 5259
  },
  {
    "cityId": 8863,
    "cityName": "Agulha",
    "stateId": 5259
  },
  {
    "cityId": 8864,
    "cityName": "Ajapi",
    "stateId": 5259
  },
  {
    "cityId": 8865,
    "cityName": "Alambari",
    "stateId": 5259
  },
  {
    "cityId": 8866,
    "cityName": "Alberto Moreira",
    "stateId": 5259
  },
  {
    "cityId": 8867,
    "cityName": "Aldeia",
    "stateId": 5259
  },
  {
    "cityId": 8868,
    "cityName": "Aldeia de Carapicuíba",
    "stateId": 5259
  },
  {
    "cityId": 8869,
    "cityName": "Alfredo Guedes",
    "stateId": 5259
  },
  {
    "cityId": 8870,
    "cityName": "Alfredo Marcondes",
    "stateId": 5259
  },
  {
    "cityId": 8871,
    "cityName": "Altair",
    "stateId": 5259
  },
  {
    "cityId": 8872,
    "cityName": "Altinópolis",
    "stateId": 5259
  },
  {
    "cityId": 8873,
    "cityName": "Alto Alegre",
    "stateId": 5259
  },
  {
    "cityId": 8874,
    "cityName": "Alto Porã",
    "stateId": 5259
  },
  {
    "cityId": 8875,
    "cityName": "Alumínio",
    "stateId": 5259
  },
  {
    "cityId": 8876,
    "cityName": "Álvares Florence",
    "stateId": 5259
  },
  {
    "cityId": 8877,
    "cityName": "Álvares Machado",
    "stateId": 5259
  },
  {
    "cityId": 8878,
    "cityName": "Álvaro de Carvalho",
    "stateId": 5259
  },
  {
    "cityId": 8879,
    "cityName": "Alvinlândia",
    "stateId": 5259
  },
  {
    "cityId": 8880,
    "cityName": "Amadeu Amaral",
    "stateId": 5259
  },
  {
    "cityId": 8881,
    "cityName": "Amandaba",
    "stateId": 5259
  },
  {
    "cityId": 8882,
    "cityName": "Ameliópolis",
    "stateId": 5259
  },
  {
    "cityId": 8883,
    "cityName": "Americana",
    "stateId": 5259
  },
  {
    "cityId": 8884,
    "cityName": "Américo Brasiliense",
    "stateId": 5259
  },
  {
    "cityId": 8885,
    "cityName": "Américo de Campos",
    "stateId": 5259
  },
  {
    "cityId": 8886,
    "cityName": "Amparo",
    "stateId": 5259
  },
  {
    "cityId": 8887,
    "cityName": "Ana Dias",
    "stateId": 5259
  },
  {
    "cityId": 8888,
    "cityName": "Analândia",
    "stateId": 5259
  },
  {
    "cityId": 8889,
    "cityName": "Anápolis",
    "stateId": 5259
  },
  {
    "cityId": 8890,
    "cityName": "Andes",
    "stateId": 5259
  },
  {
    "cityId": 8891,
    "cityName": "Andradina",
    "stateId": 5259
  },
  {
    "cityId": 8892,
    "cityName": "Angatuba",
    "stateId": 5259
  },
  {
    "cityId": 8893,
    "cityName": "Anhembi",
    "stateId": 5259
  },
  {
    "cityId": 8894,
    "cityName": "Anhumas",
    "stateId": 5259
  },
  {
    "cityId": 8896,
    "cityName": "Aparecida",
    "stateId": 5259
  },
  {
    "cityId": 8897,
    "cityName": "Aparecida D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 8898,
    "cityName": "Aparecida de Monte Alto",
    "stateId": 5259
  },
  {
    "cityId": 8899,
    "cityName": "Aparecida de São Manuel",
    "stateId": 5259
  },
  {
    "cityId": 8900,
    "cityName": "Aparecida do Bonito",
    "stateId": 5259
  },
  {
    "cityId": 8901,
    "cityName": "Apiaí",
    "stateId": 5259
  },
  {
    "cityId": 8902,
    "cityName": "Apiaí-Mirim",
    "stateId": 5259
  },
  {
    "cityId": 8903,
    "cityName": "Arabela",
    "stateId": 5259
  },
  {
    "cityId": 8904,
    "cityName": "Aracaçu",
    "stateId": 5259
  },
  {
    "cityId": 8905,
    "cityName": "Araçaíba",
    "stateId": 5259
  },
  {
    "cityId": 8906,
    "cityName": "Araçariguama",
    "stateId": 5259
  },
  {
    "cityId": 8907,
    "cityName": "Araçatuba",
    "stateId": 5259
  },
  {
    "cityId": 8908,
    "cityName": "Araçoiaba da Serra",
    "stateId": 5259
  },
  {
    "cityId": 8909,
    "cityName": "Aramina",
    "stateId": 5259
  },
  {
    "cityId": 8910,
    "cityName": "Arandu",
    "stateId": 5259
  },
  {
    "cityId": 8911,
    "cityName": "Arapeí",
    "stateId": 5259
  },
  {
    "cityId": 8912,
    "cityName": "Araraquara",
    "stateId": 5259
  },
  {
    "cityId": 8913,
    "cityName": "Araras",
    "stateId": 5259
  },
  {
    "cityId": 8914,
    "cityName": "Araxás",
    "stateId": 5259
  },
  {
    "cityId": 8915,
    "cityName": "Arcadas",
    "stateId": 5259
  },
  {
    "cityId": 8916,
    "cityName": "Arco-Íris",
    "stateId": 5259
  },
  {
    "cityId": 8917,
    "cityName": "Arealva",
    "stateId": 5259
  },
  {
    "cityId": 8918,
    "cityName": "Areias",
    "stateId": 5259
  },
  {
    "cityId": 8919,
    "cityName": "Areiópolis",
    "stateId": 5259
  },
  {
    "cityId": 8920,
    "cityName": "Ariranha",
    "stateId": 5259
  },
  {
    "cityId": 8921,
    "cityName": "Ariri",
    "stateId": 5259
  },
  {
    "cityId": 8922,
    "cityName": "Ártemis",
    "stateId": 5259
  },
  {
    "cityId": 8923,
    "cityName": "Artur Nogueira",
    "stateId": 5259
  },
  {
    "cityId": 8924,
    "cityName": "Arujá",
    "stateId": 5259
  },
  {
    "cityId": 8925,
    "cityName": "Aspásia",
    "stateId": 5259
  },
  {
    "cityId": 8926,
    "cityName": "Assis",
    "stateId": 5259
  },
  {
    "cityId": 8927,
    "cityName": "Assistência",
    "stateId": 5259
  },
  {
    "cityId": 8928,
    "cityName": "Atibaia",
    "stateId": 5259
  },
  {
    "cityId": 8929,
    "cityName": "Atlântida",
    "stateId": 5259
  },
  {
    "cityId": 8930,
    "cityName": "Auriflama",
    "stateId": 5259
  },
  {
    "cityId": 8931,
    "cityName": "Avaí",
    "stateId": 5259
  },
  {
    "cityId": 8932,
    "cityName": "Avanhandava",
    "stateId": 5259
  },
  {
    "cityId": 8933,
    "cityName": "Avaré",
    "stateId": 5259
  },
  {
    "cityId": 8934,
    "cityName": "Avencas",
    "stateId": 5259
  },
  {
    "cityId": 8935,
    "cityName": "Bacaetava",
    "stateId": 5259
  },
  {
    "cityId": 8936,
    "cityName": "Bacuriti",
    "stateId": 5259
  },
  {
    "cityId": 8937,
    "cityName": "Bady Bassitt",
    "stateId": 5259
  },
  {
    "cityId": 8938,
    "cityName": "Baguaçu",
    "stateId": 5259
  },
  {
    "cityId": 8939,
    "cityName": "Bairro Alto",
    "stateId": 5259
  },
  {
    "cityId": 8940,
    "cityName": "Balbinos",
    "stateId": 5259
  },
  {
    "cityId": 8941,
    "cityName": "Bálsamo",
    "stateId": 5259
  },
  {
    "cityId": 8942,
    "cityName": "Bananal",
    "stateId": 5259
  },
  {
    "cityId": 8943,
    "cityName": "Bandeirantes D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 8944,
    "cityName": "Barão Ataliba Nogueira",
    "stateId": 5259
  },
  {
    "cityId": 8945,
    "cityName": "Barão de Antonina",
    "stateId": 5259
  },
  {
    "cityId": 8946,
    "cityName": "Barão de Geraldo",
    "stateId": 5259
  },
  {
    "cityId": 8947,
    "cityName": "Barbosa",
    "stateId": 5259
  },
  {
    "cityId": 8948,
    "cityName": "Bariri",
    "stateId": 5259
  },
  {
    "cityId": 8949,
    "cityName": "Barra Bonita",
    "stateId": 5259
  },
  {
    "cityId": 8950,
    "cityName": "Barra do Chapéu",
    "stateId": 5259
  },
  {
    "cityId": 8951,
    "cityName": "Barra do Turvo",
    "stateId": 5259
  },
  {
    "cityId": 8952,
    "cityName": "Barra Dourada",
    "stateId": 5259
  },
  {
    "cityId": 8953,
    "cityName": "Barrânia",
    "stateId": 5259
  },
  {
    "cityId": 8954,
    "cityName": "Barretos",
    "stateId": 5259
  },
  {
    "cityId": 8955,
    "cityName": "Barrinha",
    "stateId": 5259
  },
  {
    "cityId": 8956,
    "cityName": "Barueri",
    "stateId": 5259
  },
  {
    "cityId": 8957,
    "cityName": "Bastos",
    "stateId": 5259
  },
  {
    "cityId": 8958,
    "cityName": "Batatais",
    "stateId": 5259
  },
  {
    "cityId": 8959,
    "cityName": "Batatuba",
    "stateId": 5259
  },
  {
    "cityId": 8960,
    "cityName": "Batista Botelho",
    "stateId": 5259
  },
  {
    "cityId": 8961,
    "cityName": "Bauru",
    "stateId": 5259
  },
  {
    "cityId": 8962,
    "cityName": "Bebedouro",
    "stateId": 5259
  },
  {
    "cityId": 8963,
    "cityName": "Bela Floresta",
    "stateId": 5259
  },
  {
    "cityId": 8964,
    "cityName": "Bela Vista São-Carlense",
    "stateId": 5259
  },
  {
    "cityId": 8965,
    "cityName": "Bento de Abreu",
    "stateId": 5259
  },
  {
    "cityId": 8966,
    "cityName": "Bernardino de Campos",
    "stateId": 5259
  },
  {
    "cityId": 8967,
    "cityName": "Bertioga",
    "stateId": 5259
  },
  {
    "cityId": 8968,
    "cityName": "Bilac",
    "stateId": 5259
  },
  {
    "cityId": 8969,
    "cityName": "Birigüi",
    "stateId": 5259
  },
  {
    "cityId": 8970,
    "cityName": "Biritiba-Mirim",
    "stateId": 5259
  },
  {
    "cityId": 8971,
    "cityName": "Biritiba-Ussu",
    "stateId": 5259
  },
  {
    "cityId": 8972,
    "cityName": "Boa Esperança do Sul",
    "stateId": 5259
  },
  {
    "cityId": 8973,
    "cityName": "Boa Vista dos Andradas",
    "stateId": 5259
  },
  {
    "cityId": 8974,
    "cityName": "Boa Vista Paulista",
    "stateId": 5259
  },
  {
    "cityId": 8975,
    "cityName": "Bocaina",
    "stateId": 5259
  },
  {
    "cityId": 8976,
    "cityName": "Bofete",
    "stateId": 5259
  },
  {
    "cityId": 8977,
    "cityName": "Boituva",
    "stateId": 5259
  },
  {
    "cityId": 8978,
    "cityName": "Bom Fim do Bom Jesus",
    "stateId": 5259
  },
  {
    "cityId": 8979,
    "cityName": "Bom Jesus dos Perdões",
    "stateId": 5259
  },
  {
    "cityId": 8980,
    "cityName": "Bom Retiro da Esperança",
    "stateId": 5259
  },
  {
    "cityId": 8981,
    "cityName": "Bom Sucesso de Itararé",
    "stateId": 5259
  },
  {
    "cityId": 8982,
    "cityName": "Bonfim Paulista",
    "stateId": 5259
  },
  {
    "cityId": 8983,
    "cityName": "Borá",
    "stateId": 5259
  },
  {
    "cityId": 8984,
    "cityName": "Boracéia",
    "stateId": 5259
  },
  {
    "cityId": 8985,
    "cityName": "Borborema",
    "stateId": 5259
  },
  {
    "cityId": 8986,
    "cityName": "Borebi",
    "stateId": 5259
  },
  {
    "cityId": 8987,
    "cityName": "Botafogo",
    "stateId": 5259
  },
  {
    "cityId": 8988,
    "cityName": "Botelho",
    "stateId": 5259
  },
  {
    "cityId": 8989,
    "cityName": "Botucatu",
    "stateId": 5259
  },
  {
    "cityId": 8990,
    "cityName": "Botujuru",
    "stateId": 5259
  },
  {
    "cityId": 8991,
    "cityName": "Braço",
    "stateId": 5259
  },
  {
    "cityId": 8992,
    "cityName": "Bragança Paulista",
    "stateId": 5259
  },
  {
    "cityId": 8993,
    "cityName": "Brás Cubas",
    "stateId": 5259
  },
  {
    "cityId": 8994,
    "cityName": "Brasitânia",
    "stateId": 5259
  },
  {
    "cityId": 8995,
    "cityName": "Braúna",
    "stateId": 5259
  },
  {
    "cityId": 8996,
    "cityName": "Brejo Alegre",
    "stateId": 5259
  },
  {
    "cityId": 8997,
    "cityName": "Brodowski",
    "stateId": 5259
  },
  {
    "cityId": 8998,
    "cityName": "Brotas",
    "stateId": 5259
  },
  {
    "cityId": 8999,
    "cityName": "Bueno de Andrada",
    "stateId": 5259
  },
  {
    "cityId": 9000,
    "cityName": "Buri",
    "stateId": 5259
  },
  {
    "cityId": 9001,
    "cityName": "Buritama",
    "stateId": 5259
  },
  {
    "cityId": 9002,
    "cityName": "Buritizal",
    "stateId": 5259
  },
  {
    "cityId": 9003,
    "cityName": "Cabrália Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9004,
    "cityName": "Cabreúva",
    "stateId": 5259
  },
  {
    "cityId": 9005,
    "cityName": "Caçapava",
    "stateId": 5259
  },
  {
    "cityId": 9006,
    "cityName": "Cachoeira de Emas",
    "stateId": 5259
  },
  {
    "cityId": 9007,
    "cityName": "Cachoeira Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9008,
    "cityName": "Caconde",
    "stateId": 5259
  },
  {
    "cityId": 9009,
    "cityName": "Cafelândia",
    "stateId": 5259
  },
  {
    "cityId": 9010,
    "cityName": "Cafesópolis",
    "stateId": 5259
  },
  {
    "cityId": 9011,
    "cityName": "Caiabu",
    "stateId": 5259
  },
  {
    "cityId": 9012,
    "cityName": "Caibura",
    "stateId": 5259
  },
  {
    "cityId": 9013,
    "cityName": "Caieiras",
    "stateId": 5259
  },
  {
    "cityId": 9014,
    "cityName": "Caiuá",
    "stateId": 5259
  },
  {
    "cityId": 9015,
    "cityName": "Cajamar",
    "stateId": 5259
  },
  {
    "cityId": 9016,
    "cityName": "Cajati",
    "stateId": 5259
  },
  {
    "cityId": 9017,
    "cityName": "Cajobi",
    "stateId": 5259
  },
  {
    "cityId": 9018,
    "cityName": "Cajuru",
    "stateId": 5259
  },
  {
    "cityId": 9019,
    "cityName": "Cambaquara",
    "stateId": 5259
  },
  {
    "cityId": 9020,
    "cityName": "Cambaratiba",
    "stateId": 5259
  },
  {
    "cityId": 9021,
    "cityName": "Campestrinho",
    "stateId": 5259
  },
  {
    "cityId": 9022,
    "cityName": "Campina de Fora",
    "stateId": 5259
  },
  {
    "cityId": 9023,
    "cityName": "Campina do Monte Alegre",
    "stateId": 5259
  },
  {
    "cityId": 9024,
    "cityName": "Campinal",
    "stateId": 5259
  },
  {
    "cityId": 9025,
    "cityName": "Campinas",
    "stateId": 5259
  },
  {
    "cityId": 9026,
    "cityName": "Campo Limpo Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9027,
    "cityName": "Campos de Cunha",
    "stateId": 5259
  },
  {
    "cityId": 9028,
    "cityName": "Campos do Jordão",
    "stateId": 5259
  },
  {
    "cityId": 9029,
    "cityName": "Campos Novos Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9030,
    "cityName": "Cananéia",
    "stateId": 5259
  },
  {
    "cityId": 9031,
    "cityName": "Canas",
    "stateId": 5259
  },
  {
    "cityId": 9032,
    "cityName": "Candia",
    "stateId": 5259
  },
  {
    "cityId": 9033,
    "cityName": "Cândido Mota",
    "stateId": 5259
  },
  {
    "cityId": 9034,
    "cityName": "Cândido Rodrigues",
    "stateId": 5259
  },
  {
    "cityId": 9035,
    "cityName": "Canguera",
    "stateId": 5259
  },
  {
    "cityId": 9036,
    "cityName": "Canitar",
    "stateId": 5259
  },
  {
    "cityId": 9037,
    "cityName": "Capão Bonito",
    "stateId": 5259
  },
  {
    "cityId": 9038,
    "cityName": "Capela do Alto",
    "stateId": 5259
  },
  {
    "cityId": 9039,
    "cityName": "Capivari",
    "stateId": 5259
  },
  {
    "cityId": 9040,
    "cityName": "Capivari da Mata",
    "stateId": 5259
  },
  {
    "cityId": 9041,
    "cityName": "Caporanga",
    "stateId": 5259
  },
  {
    "cityId": 9042,
    "cityName": "Capuava",
    "stateId": 5259
  },
  {
    "cityId": 9043,
    "cityName": "Caraguatatuba",
    "stateId": 5259
  },
  {
    "cityId": 9044,
    "cityName": "Carapicuíba",
    "stateId": 5259
  },
  {
    "cityId": 9045,
    "cityName": "Cardeal",
    "stateId": 5259
  },
  {
    "cityId": 9046,
    "cityName": "Cardoso",
    "stateId": 5259
  },
  {
    "cityId": 9047,
    "cityName": "Nova Cardoso",
    "stateId": 5259
  },
  {
    "cityId": 9048,
    "cityName": "Caruara",
    "stateId": 5259
  },
  {
    "cityId": 9049,
    "cityName": "Casa Branca",
    "stateId": 5259
  },
  {
    "cityId": 9050,
    "cityName": "Cássia dos Coqueiros",
    "stateId": 5259
  },
  {
    "cityId": 9051,
    "cityName": "Castilho",
    "stateId": 5259
  },
  {
    "cityId": 9052,
    "cityName": "Catanduva",
    "stateId": 5259
  },
  {
    "cityId": 9053,
    "cityName": "Catiguá",
    "stateId": 5259
  },
  {
    "cityId": 9054,
    "cityName": "Catucaba",
    "stateId": 5259
  },
  {
    "cityId": 9055,
    "cityName": "Caucaia do Alto",
    "stateId": 5259
  },
  {
    "cityId": 9056,
    "cityName": "Cedral",
    "stateId": 5259
  },
  {
    "cityId": 9057,
    "cityName": "Cerqueira César",
    "stateId": 5259
  },
  {
    "cityId": 9058,
    "cityName": "Cerquilho",
    "stateId": 5259
  },
  {
    "cityId": 9059,
    "cityName": "Cesário Lange",
    "stateId": 5259
  },
  {
    "cityId": 9060,
    "cityName": "Cezar de Souza",
    "stateId": 5259
  },
  {
    "cityId": 9061,
    "cityName": "Charqueada",
    "stateId": 5259
  },
  {
    "cityId": 9062,
    "cityName": "Chavantes",
    "stateId": 5259
  },
  {
    "cityId": 9063,
    "cityName": "Cipó-Guaçu",
    "stateId": 5259
  },
  {
    "cityId": 9064,
    "cityName": "Clarinia",
    "stateId": 5259
  },
  {
    "cityId": 9065,
    "cityName": "Clementina",
    "stateId": 5259
  },
  {
    "cityId": 9066,
    "cityName": "Cocaes",
    "stateId": 5259
  },
  {
    "cityId": 9067,
    "cityName": "Colina",
    "stateId": 5259
  },
  {
    "cityId": 9068,
    "cityName": "Colômbia",
    "stateId": 5259
  },
  {
    "cityId": 9069,
    "cityName": "Conceição de Monte Alegre",
    "stateId": 5259
  },
  {
    "cityId": 9070,
    "cityName": "Conchal",
    "stateId": 5259
  },
  {
    "cityId": 9071,
    "cityName": "Conchas",
    "stateId": 5259
  },
  {
    "cityId": 9072,
    "cityName": "Cordeirópolis",
    "stateId": 5259
  },
  {
    "cityId": 9073,
    "cityName": "Coroados",
    "stateId": 5259
  },
  {
    "cityId": 9074,
    "cityName": "Coronel Goulart",
    "stateId": 5259
  },
  {
    "cityId": 9075,
    "cityName": "Coronel Macedo",
    "stateId": 5259
  },
  {
    "cityId": 9076,
    "cityName": "Corredeira",
    "stateId": 5259
  },
  {
    "cityId": 9077,
    "cityName": "Córrego Rico",
    "stateId": 5259
  },
  {
    "cityId": 9078,
    "cityName": "Corumbataí",
    "stateId": 5259
  },
  {
    "cityId": 9079,
    "cityName": "Cosmópolis",
    "stateId": 5259
  },
  {
    "cityId": 9080,
    "cityName": "Cosmorama",
    "stateId": 5259
  },
  {
    "cityId": 9081,
    "cityName": "Costa Machado",
    "stateId": 5259
  },
  {
    "cityId": 9082,
    "cityName": "Cotia",
    "stateId": 5259
  },
  {
    "cityId": 9083,
    "cityName": "Cravinhos",
    "stateId": 5259
  },
  {
    "cityId": 9084,
    "cityName": "Cristais Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9085,
    "cityName": "Cruz das Posses",
    "stateId": 5259
  },
  {
    "cityId": 9086,
    "cityName": "Cruzália",
    "stateId": 5259
  },
  {
    "cityId": 9087,
    "cityName": "Cruzeiro",
    "stateId": 5259
  },
  {
    "cityId": 9088,
    "cityName": "Cubatão",
    "stateId": 5259
  },
  {
    "cityId": 9089,
    "cityName": "Cuiabá Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9090,
    "cityName": "Cunha",
    "stateId": 5259
  },
  {
    "cityId": 9091,
    "cityName": "Curupá",
    "stateId": 5259
  },
  {
    "cityId": 9092,
    "cityName": "Dalas",
    "stateId": 5259
  },
  {
    "cityId": 9093,
    "cityName": "Descalvado",
    "stateId": 5259
  },
  {
    "cityId": 9094,
    "cityName": "Diadema",
    "stateId": 5259
  },
  {
    "cityId": 9095,
    "cityName": "Dirce Reis",
    "stateId": 5259
  },
  {
    "cityId": 9096,
    "cityName": "Dirceu",
    "stateId": 5259
  },
  {
    "cityId": 9097,
    "cityName": "Divinolândia",
    "stateId": 5259
  },
  {
    "cityId": 9098,
    "cityName": "Dobrada",
    "stateId": 5259
  },
  {
    "cityId": 9099,
    "cityName": "Dois Córregos",
    "stateId": 5259
  },
  {
    "cityId": 9100,
    "cityName": "Dolcinópolis",
    "stateId": 5259
  },
  {
    "cityId": 9101,
    "cityName": "Domélia",
    "stateId": 5259
  },
  {
    "cityId": 9102,
    "cityName": "Dourado",
    "stateId": 5259
  },
  {
    "cityId": 9103,
    "cityName": "Dracena",
    "stateId": 5259
  },
  {
    "cityId": 9104,
    "cityName": "Duartina",
    "stateId": 5259
  },
  {
    "cityId": 9105,
    "cityName": "Dumont",
    "stateId": 5259
  },
  {
    "cityId": 9106,
    "cityName": "Duplo Céu",
    "stateId": 5259
  },
  {
    "cityId": 9107,
    "cityName": "Echaporã",
    "stateId": 5259
  },
  {
    "cityId": 9108,
    "cityName": "Eldorado",
    "stateId": 5259
  },
  {
    "cityId": 9109,
    "cityName": "Eleutério",
    "stateId": 5259
  },
  {
    "cityId": 9110,
    "cityName": "Elias Fausto",
    "stateId": 5259
  },
  {
    "cityId": 9111,
    "cityName": "Elisiário",
    "stateId": 5259
  },
  {
    "cityId": 9112,
    "cityName": "Embaúba",
    "stateId": 5259
  },
  {
    "cityId": 9113,
    "cityName": "Embu",
    "stateId": 5259
  },
  {
    "cityId": 9114,
    "cityName": "Embu-Guaçu",
    "stateId": 5259
  },
  {
    "cityId": 9115,
    "cityName": "Emilianópolis",
    "stateId": 5259
  },
  {
    "cityId": 9116,
    "cityName": "Eneida",
    "stateId": 5259
  },
  {
    "cityId": 9117,
    "cityName": "Engenheiro Balduíno",
    "stateId": 5259
  },
  {
    "cityId": 9118,
    "cityName": "Engenheiro Coelho",
    "stateId": 5259
  },
  {
    "cityId": 9119,
    "cityName": "Engenheiro Maia",
    "stateId": 5259
  },
  {
    "cityId": 9120,
    "cityName": "Engenheiro Schmitt",
    "stateId": 5259
  },
  {
    "cityId": 9121,
    "cityName": "Esmeralda",
    "stateId": 5259
  },
  {
    "cityId": 9122,
    "cityName": "Esperança D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9123,
    "cityName": "Espigão",
    "stateId": 5259
  },
  {
    "cityId": 9124,
    "cityName": "Espírito Santo do Pinhal",
    "stateId": 5259
  },
  {
    "cityId": 9125,
    "cityName": "Espírito Santo do Turvo",
    "stateId": 5259
  },
  {
    "cityId": 9126,
    "cityName": "Estiva Gerbi",
    "stateId": 5259
  },
  {
    "cityId": 9127,
    "cityName": "Estrela D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9128,
    "cityName": "Estrela do Norte",
    "stateId": 5259
  },
  {
    "cityId": 9129,
    "cityName": "Euclides da Cunha Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9130,
    "cityName": "Eugênio de Melo",
    "stateId": 5259
  },
  {
    "cityId": 9131,
    "cityName": "Fartura",
    "stateId": 5259
  },
  {
    "cityId": 9132,
    "cityName": "Fátima",
    "stateId": 5259
  },
  {
    "cityId": 9133,
    "cityName": "Fátima Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9134,
    "cityName": "Fazenda Velha",
    "stateId": 5259
  },
  {
    "cityId": 9135,
    "cityName": "Fernando Prestes",
    "stateId": 5259
  },
  {
    "cityId": 9136,
    "cityName": "Fernandópolis",
    "stateId": 5259
  },
  {
    "cityId": 9137,
    "cityName": "Fernão",
    "stateId": 5259
  },
  {
    "cityId": 9138,
    "cityName": "Ferraz de Vasconcelos",
    "stateId": 5259
  },
  {
    "cityId": 9139,
    "cityName": "Flora Rica",
    "stateId": 5259
  },
  {
    "cityId": 9140,
    "cityName": "Floreal",
    "stateId": 5259
  },
  {
    "cityId": 9141,
    "cityName": "Floresta do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9142,
    "cityName": "Flórida Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9143,
    "cityName": "Florínia",
    "stateId": 5259
  },
  {
    "cityId": 9144,
    "cityName": "Franca",
    "stateId": 5259
  },
  {
    "cityId": 9145,
    "cityName": "Francisco Morato",
    "stateId": 5259
  },
  {
    "cityId": 9146,
    "cityName": "Franco da Rocha",
    "stateId": 5259
  },
  {
    "cityId": 9147,
    "cityName": "Frutal do Campo",
    "stateId": 5259
  },
  {
    "cityId": 9148,
    "cityName": "Gabriel Monteiro",
    "stateId": 5259
  },
  {
    "cityId": 9149,
    "cityName": "Gália",
    "stateId": 5259
  },
  {
    "cityId": 9150,
    "cityName": "Garça",
    "stateId": 5259
  },
  {
    "cityId": 9151,
    "cityName": "Gardênia",
    "stateId": 5259
  },
  {
    "cityId": 9152,
    "cityName": "Gastão Vidigal",
    "stateId": 5259
  },
  {
    "cityId": 9153,
    "cityName": "Gavião Peixoto",
    "stateId": 5259
  },
  {
    "cityId": 9154,
    "cityName": "General Salgado",
    "stateId": 5259
  },
  {
    "cityId": 9155,
    "cityName": "Getulina",
    "stateId": 5259
  },
  {
    "cityId": 9156,
    "cityName": "Glicério",
    "stateId": 5259
  },
  {
    "cityId": 9157,
    "cityName": "Gramadinho",
    "stateId": 5259
  },
  {
    "cityId": 9158,
    "cityName": "Guachos",
    "stateId": 5259
  },
  {
    "cityId": 9159,
    "cityName": "Guaianas",
    "stateId": 5259
  },
  {
    "cityId": 9160,
    "cityName": "Guaiçara",
    "stateId": 5259
  },
  {
    "cityId": 9161,
    "cityName": "Guaimbê",
    "stateId": 5259
  },
  {
    "cityId": 9162,
    "cityName": "Guaíra",
    "stateId": 5259
  },
  {
    "cityId": 9163,
    "cityName": "Guamium",
    "stateId": 5259
  },
  {
    "cityId": 9164,
    "cityName": "Guapiaçu",
    "stateId": 5259
  },
  {
    "cityId": 9165,
    "cityName": "Guapiara",
    "stateId": 5259
  },
  {
    "cityId": 9166,
    "cityName": "Guapiranga",
    "stateId": 5259
  },
  {
    "cityId": 9167,
    "cityName": "Guará",
    "stateId": 5259
  },
  {
    "cityId": 9168,
    "cityName": "Guaraçaí",
    "stateId": 5259
  },
  {
    "cityId": 9169,
    "cityName": "Guaraci",
    "stateId": 5259
  },
  {
    "cityId": 9170,
    "cityName": "Guaraciaba D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9171,
    "cityName": "Guarani D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9172,
    "cityName": "Guarantã",
    "stateId": 5259
  },
  {
    "cityId": 9173,
    "cityName": "Guarapiranga",
    "stateId": 5259
  },
  {
    "cityId": 9174,
    "cityName": "Guarapuá",
    "stateId": 5259
  },
  {
    "cityId": 9175,
    "cityName": "Guararapes",
    "stateId": 5259
  },
  {
    "cityId": 9176,
    "cityName": "Guararema",
    "stateId": 5259
  },
  {
    "cityId": 9177,
    "cityName": "Guaratinguetá",
    "stateId": 5259
  },
  {
    "cityId": 9178,
    "cityName": "Guareí",
    "stateId": 5259
  },
  {
    "cityId": 9179,
    "cityName": "Guariba",
    "stateId": 5259
  },
  {
    "cityId": 9180,
    "cityName": "Guariroba",
    "stateId": 5259
  },
  {
    "cityId": 9181,
    "cityName": "Guarizinho",
    "stateId": 5259
  },
  {
    "cityId": 9182,
    "cityName": "Guarujá",
    "stateId": 5259
  },
  {
    "cityId": 9183,
    "cityName": "Guarulhos",
    "stateId": 5259
  },
  {
    "cityId": 9184,
    "cityName": "Guatapará",
    "stateId": 5259
  },
  {
    "cityId": 9185,
    "cityName": "Guzolândia",
    "stateId": 5259
  },
  {
    "cityId": 9186,
    "cityName": "Herculândia",
    "stateId": 5259
  },
  {
    "cityId": 9187,
    "cityName": "Holambra",
    "stateId": 5259
  },
  {
    "cityId": 9188,
    "cityName": "Holambra II",
    "stateId": 5259
  },
  {
    "cityId": 9189,
    "cityName": "Hortolândia",
    "stateId": 5259
  },
  {
    "cityId": 9190,
    "cityName": "Iacanga",
    "stateId": 5259
  },
  {
    "cityId": 9191,
    "cityName": "Iacri",
    "stateId": 5259
  },
  {
    "cityId": 9192,
    "cityName": "Iaras",
    "stateId": 5259
  },
  {
    "cityId": 9193,
    "cityName": "Ibaté",
    "stateId": 5259
  },
  {
    "cityId": 9194,
    "cityName": "Ibiporanga",
    "stateId": 5259
  },
  {
    "cityId": 9195,
    "cityName": "Ibirá",
    "stateId": 5259
  },
  {
    "cityId": 9196,
    "cityName": "Ibirarema",
    "stateId": 5259
  },
  {
    "cityId": 9197,
    "cityName": "Ibitinga",
    "stateId": 5259
  },
  {
    "cityId": 9198,
    "cityName": "Ibitiruna",
    "stateId": 5259
  },
  {
    "cityId": 9199,
    "cityName": "Ibitiúva",
    "stateId": 5259
  },
  {
    "cityId": 9200,
    "cityName": "Ibitu",
    "stateId": 5259
  },
  {
    "cityId": 9201,
    "cityName": "Ibiúna",
    "stateId": 5259
  },
  {
    "cityId": 9202,
    "cityName": "Icém",
    "stateId": 5259
  },
  {
    "cityId": 9203,
    "cityName": "Ida Iolanda",
    "stateId": 5259
  },
  {
    "cityId": 9204,
    "cityName": "Iepê",
    "stateId": 5259
  },
  {
    "cityId": 9205,
    "cityName": "Igaçaba",
    "stateId": 5259
  },
  {
    "cityId": 9206,
    "cityName": "Igaraçu do Tietê",
    "stateId": 5259
  },
  {
    "cityId": 9207,
    "cityName": "Igaraí",
    "stateId": 5259
  },
  {
    "cityId": 9208,
    "cityName": "Igarapava",
    "stateId": 5259
  },
  {
    "cityId": 9209,
    "cityName": "Igaratá",
    "stateId": 5259
  },
  {
    "cityId": 9210,
    "cityName": "Iguape",
    "stateId": 5259
  },
  {
    "cityId": 9211,
    "cityName": "Ilha Comprida",
    "stateId": 5259
  },
  {
    "cityId": 9212,
    "cityName": "Ilha Diana",
    "stateId": 5259
  },
  {
    "cityId": 9213,
    "cityName": "Ilha Solteira",
    "stateId": 5259
  },
  {
    "cityId": 9214,
    "cityName": "Ilhabela",
    "stateId": 5259
  },
  {
    "cityId": 9215,
    "cityName": "Indaiá do Aguapeí",
    "stateId": 5259
  },
  {
    "cityId": 9216,
    "cityName": "Indaiatuba",
    "stateId": 5259
  },
  {
    "cityId": 9217,
    "cityName": "Indiana",
    "stateId": 5259
  },
  {
    "cityId": 9218,
    "cityName": "Indiaporã",
    "stateId": 5259
  },
  {
    "cityId": 9219,
    "cityName": "Ingás",
    "stateId": 5259
  },
  {
    "cityId": 9220,
    "cityName": "Inúbia Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9221,
    "cityName": "Ipaussu",
    "stateId": 5259
  },
  {
    "cityId": 9222,
    "cityName": "Iperó",
    "stateId": 5259
  },
  {
    "cityId": 9223,
    "cityName": "Ipeúna",
    "stateId": 5259
  },
  {
    "cityId": 9224,
    "cityName": "Ipiguá",
    "stateId": 5259
  },
  {
    "cityId": 9225,
    "cityName": "Iporanga",
    "stateId": 5259
  },
  {
    "cityId": 9226,
    "cityName": "Ipuã",
    "stateId": 5259
  },
  {
    "cityId": 9227,
    "cityName": "Iracemápolis",
    "stateId": 5259
  },
  {
    "cityId": 9228,
    "cityName": "Irapé",
    "stateId": 5259
  },
  {
    "cityId": 9229,
    "cityName": "Irapuã",
    "stateId": 5259
  },
  {
    "cityId": 9230,
    "cityName": "Irapuru",
    "stateId": 5259
  },
  {
    "cityId": 9231,
    "cityName": "Itaberá",
    "stateId": 5259
  },
  {
    "cityId": 9232,
    "cityName": "Itaboa",
    "stateId": 5259
  },
  {
    "cityId": 9233,
    "cityName": "Itaí",
    "stateId": 5259
  },
  {
    "cityId": 9234,
    "cityName": "Itaiúba",
    "stateId": 5259
  },
  {
    "cityId": 9235,
    "cityName": "Itajobi",
    "stateId": 5259
  },
  {
    "cityId": 9236,
    "cityName": "Itaju",
    "stateId": 5259
  },
  {
    "cityId": 9237,
    "cityName": "Itanhaém",
    "stateId": 5259
  },
  {
    "cityId": 9238,
    "cityName": "Itaóca",
    "stateId": 5259
  },
  {
    "cityId": 9239,
    "cityName": "Itapecerica da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9240,
    "cityName": "Itapetininga",
    "stateId": 5259
  },
  {
    "cityId": 9241,
    "cityName": "Itapeuna",
    "stateId": 5259
  },
  {
    "cityId": 9242,
    "cityName": "Itapeva",
    "stateId": 5259
  },
  {
    "cityId": 9243,
    "cityName": "Itapevi",
    "stateId": 5259
  },
  {
    "cityId": 9244,
    "cityName": "Itapira",
    "stateId": 5259
  },
  {
    "cityId": 9245,
    "cityName": "Itapirapuã Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9246,
    "cityName": "Itápolis",
    "stateId": 5259
  },
  {
    "cityId": 9247,
    "cityName": "Itaporanga",
    "stateId": 5259
  },
  {
    "cityId": 9248,
    "cityName": "Itapuí",
    "stateId": 5259
  },
  {
    "cityId": 9249,
    "cityName": "Itapura",
    "stateId": 5259
  },
  {
    "cityId": 9250,
    "cityName": "Itaquaquecetuba",
    "stateId": 5259
  },
  {
    "cityId": 9251,
    "cityName": "Itaqueri da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9252,
    "cityName": "Itararé",
    "stateId": 5259
  },
  {
    "cityId": 9253,
    "cityName": "Itariri",
    "stateId": 5259
  },
  {
    "cityId": 9254,
    "cityName": "Itatiba",
    "stateId": 5259
  },
  {
    "cityId": 9255,
    "cityName": "Itatinga",
    "stateId": 5259
  },
  {
    "cityId": 9256,
    "cityName": "Itirapina",
    "stateId": 5259
  },
  {
    "cityId": 9257,
    "cityName": "Itirapuã",
    "stateId": 5259
  },
  {
    "cityId": 9258,
    "cityName": "Itobi",
    "stateId": 5259
  },
  {
    "cityId": 9259,
    "cityName": "Itororó do Paranapanema",
    "stateId": 5259
  },
  {
    "cityId": 9260,
    "cityName": "Itu",
    "stateId": 5259
  },
  {
    "cityId": 9261,
    "cityName": "Itupeva",
    "stateId": 5259
  },
  {
    "cityId": 9262,
    "cityName": "Ituverava",
    "stateId": 5259
  },
  {
    "cityId": 9263,
    "cityName": "Iubatinga",
    "stateId": 5259
  },
  {
    "cityId": 9264,
    "cityName": "Jaborandi",
    "stateId": 5259
  },
  {
    "cityId": 9265,
    "cityName": "Jaboticabal",
    "stateId": 5259
  },
  {
    "cityId": 9266,
    "cityName": "Jacaré",
    "stateId": 5259
  },
  {
    "cityId": 9267,
    "cityName": "Jacareí",
    "stateId": 5259
  },
  {
    "cityId": 9268,
    "cityName": "Jaci",
    "stateId": 5259
  },
  {
    "cityId": 9269,
    "cityName": "Jaciporã",
    "stateId": 5259
  },
  {
    "cityId": 9270,
    "cityName": "Jacuba",
    "stateId": 5259
  },
  {
    "cityId": 9271,
    "cityName": "Jacupiranga",
    "stateId": 5259
  },
  {
    "cityId": 9272,
    "cityName": "Jafa",
    "stateId": 5259
  },
  {
    "cityId": 9273,
    "cityName": "Jaguariúna",
    "stateId": 5259
  },
  {
    "cityId": 9274,
    "cityName": "Jales",
    "stateId": 5259
  },
  {
    "cityId": 9275,
    "cityName": "Jamaica",
    "stateId": 5259
  },
  {
    "cityId": 9276,
    "cityName": "Jambeiro",
    "stateId": 5259
  },
  {
    "cityId": 9277,
    "cityName": "Jandira",
    "stateId": 5259
  },
  {
    "cityId": 9278,
    "cityName": "Jardim Belval",
    "stateId": 5259
  },
  {
    "cityId": 9279,
    "cityName": "Jardim Presidente Dutra",
    "stateId": 5259
  },
  {
    "cityId": 9280,
    "cityName": "Jardim Santa Luzia",
    "stateId": 5259
  },
  {
    "cityId": 9281,
    "cityName": "Jardim Silveira",
    "stateId": 5259
  },
  {
    "cityId": 9282,
    "cityName": "Jardinópolis",
    "stateId": 5259
  },
  {
    "cityId": 9283,
    "cityName": "Jarinu",
    "stateId": 5259
  },
  {
    "cityId": 9284,
    "cityName": "Jatobá",
    "stateId": 5259
  },
  {
    "cityId": 9285,
    "cityName": "Jaú",
    "stateId": 5259
  },
  {
    "cityId": 9286,
    "cityName": "Jeriquara",
    "stateId": 5259
  },
  {
    "cityId": 9287,
    "cityName": "Joanópolis",
    "stateId": 5259
  },
  {
    "cityId": 9288,
    "cityName": "João Ramalho",
    "stateId": 5259
  },
  {
    "cityId": 9289,
    "cityName": "Joaquim Egídio",
    "stateId": 5259
  },
  {
    "cityId": 9290,
    "cityName": "Jordanésia",
    "stateId": 5259
  },
  {
    "cityId": 9291,
    "cityName": "José Bonifácio",
    "stateId": 5259
  },
  {
    "cityId": 9292,
    "cityName": "Juliânia",
    "stateId": 5259
  },
  {
    "cityId": 9293,
    "cityName": "Júlio Mesquita",
    "stateId": 5259
  },
  {
    "cityId": 9294,
    "cityName": "Jumirim",
    "stateId": 5259
  },
  {
    "cityId": 9295,
    "cityName": "Jundiaí",
    "stateId": 5259
  },
  {
    "cityId": 9296,
    "cityName": "Jundiapeba",
    "stateId": 5259
  },
  {
    "cityId": 9297,
    "cityName": "Junqueira",
    "stateId": 5259
  },
  {
    "cityId": 9298,
    "cityName": "Junqueirópolis",
    "stateId": 5259
  },
  {
    "cityId": 9299,
    "cityName": "Juquiá",
    "stateId": 5259
  },
  {
    "cityId": 9300,
    "cityName": "Juquiratiba",
    "stateId": 5259
  },
  {
    "cityId": 9301,
    "cityName": "Juquitiba",
    "stateId": 5259
  },
  {
    "cityId": 9302,
    "cityName": "Juritis",
    "stateId": 5259
  },
  {
    "cityId": 9303,
    "cityName": "Jurucê",
    "stateId": 5259
  },
  {
    "cityId": 9304,
    "cityName": "Jurupeba",
    "stateId": 5259
  },
  {
    "cityId": 9305,
    "cityName": "Jurupema",
    "stateId": 5259
  },
  {
    "cityId": 9306,
    "cityName": "Lácio",
    "stateId": 5259
  },
  {
    "cityId": 9307,
    "cityName": "Lagoa Azul",
    "stateId": 5259
  },
  {
    "cityId": 9308,
    "cityName": "Lagoa Branca",
    "stateId": 5259
  },
  {
    "cityId": 9309,
    "cityName": "Lagoinha",
    "stateId": 5259
  },
  {
    "cityId": 9310,
    "cityName": "Laranjal Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9311,
    "cityName": "Laras",
    "stateId": 5259
  },
  {
    "cityId": 9312,
    "cityName": "Lauro Penteado",
    "stateId": 5259
  },
  {
    "cityId": 9313,
    "cityName": "Lavínia",
    "stateId": 5259
  },
  {
    "cityId": 9314,
    "cityName": "Lavrinhas",
    "stateId": 5259
  },
  {
    "cityId": 9315,
    "cityName": "Leme",
    "stateId": 5259
  },
  {
    "cityId": 9316,
    "cityName": "Lençóis Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9317,
    "cityName": "Limeira",
    "stateId": 5259
  },
  {
    "cityId": 9318,
    "cityName": "Lindóia",
    "stateId": 5259
  },
  {
    "cityId": 9319,
    "cityName": "Lins",
    "stateId": 5259
  },
  {
    "cityId": 9320,
    "cityName": "Lobo",
    "stateId": 5259
  },
  {
    "cityId": 9321,
    "cityName": "Lorena",
    "stateId": 5259
  },
  {
    "cityId": 9322,
    "cityName": "Lourdes",
    "stateId": 5259
  },
  {
    "cityId": 9323,
    "cityName": "Louveira",
    "stateId": 5259
  },
  {
    "cityId": 9324,
    "cityName": "Lucélia",
    "stateId": 5259
  },
  {
    "cityId": 9325,
    "cityName": "Lucianópolis",
    "stateId": 5259
  },
  {
    "cityId": 9326,
    "cityName": "Luís Antônio",
    "stateId": 5259
  },
  {
    "cityId": 9327,
    "cityName": "Luiziânia",
    "stateId": 5259
  },
  {
    "cityId": 9328,
    "cityName": "Lupércio",
    "stateId": 5259
  },
  {
    "cityId": 9329,
    "cityName": "Lusitânia",
    "stateId": 5259
  },
  {
    "cityId": 9330,
    "cityName": "Lutécia",
    "stateId": 5259
  },
  {
    "cityId": 9331,
    "cityName": "Macatuba",
    "stateId": 5259
  },
  {
    "cityId": 9332,
    "cityName": "Macaubal",
    "stateId": 5259
  },
  {
    "cityId": 9333,
    "cityName": "Macedônia",
    "stateId": 5259
  },
  {
    "cityId": 9334,
    "cityName": "Macucos",
    "stateId": 5259
  },
  {
    "cityId": 9335,
    "cityName": "Mágda",
    "stateId": 5259
  },
  {
    "cityId": 9336,
    "cityName": "Mailasqui",
    "stateId": 5259
  },
  {
    "cityId": 9337,
    "cityName": "Mairinque",
    "stateId": 5259
  },
  {
    "cityId": 9338,
    "cityName": "Mairiporã",
    "stateId": 5259
  },
  {
    "cityId": 9339,
    "cityName": "Major Prado",
    "stateId": 5259
  },
  {
    "cityId": 9340,
    "cityName": "Manduri",
    "stateId": 5259
  },
  {
    "cityId": 9341,
    "cityName": "Mangaratú",
    "stateId": 5259
  },
  {
    "cityId": 9342,
    "cityName": "Marabá Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9343,
    "cityName": "Maracaí",
    "stateId": 5259
  },
  {
    "cityId": 9344,
    "cityName": "Marapoama",
    "stateId": 5259
  },
  {
    "cityId": 9345,
    "cityName": "Marcondésia",
    "stateId": 5259
  },
  {
    "cityId": 9346,
    "cityName": "Maresias",
    "stateId": 5259
  },
  {
    "cityId": 9347,
    "cityName": "Mariápolis",
    "stateId": 5259
  },
  {
    "cityId": 9348,
    "cityName": "Marília",
    "stateId": 5259
  },
  {
    "cityId": 9349,
    "cityName": "Marinópolis",
    "stateId": 5259
  },
  {
    "cityId": 9350,
    "cityName": "Maristela",
    "stateId": 5259
  },
  {
    "cityId": 9351,
    "cityName": "Martim Francisco",
    "stateId": 5259
  },
  {
    "cityId": 9352,
    "cityName": "Martinho Prado Júnior",
    "stateId": 5259
  },
  {
    "cityId": 9353,
    "cityName": "Martinópolis",
    "stateId": 5259
  },
  {
    "cityId": 9354,
    "cityName": "Matão",
    "stateId": 5259
  },
  {
    "cityId": 9355,
    "cityName": "Mauá",
    "stateId": 5259
  },
  {
    "cityId": 9356,
    "cityName": "Mendonça",
    "stateId": 5259
  },
  {
    "cityId": 9357,
    "cityName": "Meridiano",
    "stateId": 5259
  },
  {
    "cityId": 9358,
    "cityName": "Mesópolis",
    "stateId": 5259
  },
  {
    "cityId": 9359,
    "cityName": "Miguelópolis",
    "stateId": 5259
  },
  {
    "cityId": 9360,
    "cityName": "Mineiros do Tietê",
    "stateId": 5259
  },
  {
    "cityId": 9361,
    "cityName": "Mira Estrela",
    "stateId": 5259
  },
  {
    "cityId": 9362,
    "cityName": "Miracatu",
    "stateId": 5259
  },
  {
    "cityId": 9363,
    "cityName": "Miraluz",
    "stateId": 5259
  },
  {
    "cityId": 9364,
    "cityName": "Mirandópolis",
    "stateId": 5259
  },
  {
    "cityId": 9365,
    "cityName": "Mirante do Paranapanema",
    "stateId": 5259
  },
  {
    "cityId": 9366,
    "cityName": "Mirassol",
    "stateId": 5259
  },
  {
    "cityId": 9367,
    "cityName": "Mirassolândia",
    "stateId": 5259
  },
  {
    "cityId": 9368,
    "cityName": "Mococa",
    "stateId": 5259
  },
  {
    "cityId": 9369,
    "cityName": "Mogi das Cruzes",
    "stateId": 5259
  },
  {
    "cityId": 9370,
    "cityName": "Mogi Guaçu",
    "stateId": 5259
  },
  {
    "cityId": 9371,
    "cityName": "Mogi Mirim",
    "stateId": 5259
  },
  {
    "cityId": 9372,
    "cityName": "Mombuca",
    "stateId": 5259
  },
  {
    "cityId": 9373,
    "cityName": "Monções",
    "stateId": 5259
  },
  {
    "cityId": 9374,
    "cityName": "Mongaguá",
    "stateId": 5259
  },
  {
    "cityId": 9375,
    "cityName": "Montalvão",
    "stateId": 5259
  },
  {
    "cityId": 9376,
    "cityName": "Monte Alegre do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9377,
    "cityName": "Monte Alto",
    "stateId": 5259
  },
  {
    "cityId": 9378,
    "cityName": "Monte Aprazível",
    "stateId": 5259
  },
  {
    "cityId": 9379,
    "cityName": "Monte Azul Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9380,
    "cityName": "Monte Cabrão",
    "stateId": 5259
  },
  {
    "cityId": 9381,
    "cityName": "Monte Castelo",
    "stateId": 5259
  },
  {
    "cityId": 9382,
    "cityName": "Monte Mor",
    "stateId": 5259
  },
  {
    "cityId": 9383,
    "cityName": "Monte Verde Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9384,
    "cityName": "Monteiro Lobato",
    "stateId": 5259
  },
  {
    "cityId": 9385,
    "cityName": "Moreira César",
    "stateId": 5259
  },
  {
    "cityId": 9386,
    "cityName": "Morro Agudo",
    "stateId": 5259
  },
  {
    "cityId": 9387,
    "cityName": "Morro do Alto",
    "stateId": 5259
  },
  {
    "cityId": 9388,
    "cityName": "Morungaba",
    "stateId": 5259
  },
  {
    "cityId": 9389,
    "cityName": "Mostardas",
    "stateId": 5259
  },
  {
    "cityId": 9390,
    "cityName": "Motuca",
    "stateId": 5259
  },
  {
    "cityId": 9391,
    "cityName": "Mourão",
    "stateId": 5259
  },
  {
    "cityId": 9392,
    "cityName": "Murutinga do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9393,
    "cityName": "Nantes",
    "stateId": 5259
  },
  {
    "cityId": 9394,
    "cityName": "Narandiba",
    "stateId": 5259
  },
  {
    "cityId": 9395,
    "cityName": "Natividade da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9396,
    "cityName": "Nazaré Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9397,
    "cityName": "Neves Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9398,
    "cityName": "Nhandeara",
    "stateId": 5259
  },
  {
    "cityId": 9399,
    "cityName": "Nipoã",
    "stateId": 5259
  },
  {
    "cityId": 9400,
    "cityName": "Nogueira",
    "stateId": 5259
  },
  {
    "cityId": 9401,
    "cityName": "Nossa Senhora do Remédio",
    "stateId": 5259
  },
  {
    "cityId": 9402,
    "cityName": "Nova Alexandria",
    "stateId": 5259
  },
  {
    "cityId": 9403,
    "cityName": "Nova Aliança",
    "stateId": 5259
  },
  {
    "cityId": 9404,
    "cityName": "Nova América",
    "stateId": 5259
  },
  {
    "cityId": 9405,
    "cityName": "Nova Aparecida",
    "stateId": 5259
  },
  {
    "cityId": 9406,
    "cityName": "Nova Campina",
    "stateId": 5259
  },
  {
    "cityId": 9407,
    "cityName": "Nova Canaã Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9408,
    "cityName": "Nova Castilho",
    "stateId": 5259
  },
  {
    "cityId": 9409,
    "cityName": "Nova Europa",
    "stateId": 5259
  },
  {
    "cityId": 9410,
    "cityName": "Nova Granada",
    "stateId": 5259
  },
  {
    "cityId": 9411,
    "cityName": "Nova Guataporanga",
    "stateId": 5259
  },
  {
    "cityId": 9412,
    "cityName": "Nova Independência",
    "stateId": 5259
  },
  {
    "cityId": 9413,
    "cityName": "Nova Itapirema",
    "stateId": 5259
  },
  {
    "cityId": 9414,
    "cityName": "Nova Luzitânia",
    "stateId": 5259
  },
  {
    "cityId": 9415,
    "cityName": "Nova Odessa",
    "stateId": 5259
  },
  {
    "cityId": 9416,
    "cityName": "Nova Pátria",
    "stateId": 5259
  },
  {
    "cityId": 9417,
    "cityName": "Nova Veneza",
    "stateId": 5259
  },
  {
    "cityId": 9418,
    "cityName": "Novais",
    "stateId": 5259
  },
  {
    "cityId": 9419,
    "cityName": "Novo Cravinhos",
    "stateId": 5259
  },
  {
    "cityId": 9420,
    "cityName": "Novo Horizonte",
    "stateId": 5259
  },
  {
    "cityId": 9421,
    "cityName": "Nuporanga",
    "stateId": 5259
  },
  {
    "cityId": 9422,
    "cityName": "Oásis",
    "stateId": 5259
  },
  {
    "cityId": 9423,
    "cityName": "Ocauçu",
    "stateId": 5259
  },
  {
    "cityId": 9424,
    "cityName": "Óleo",
    "stateId": 5259
  },
  {
    "cityId": 9425,
    "cityName": "Olímpia",
    "stateId": 5259
  },
  {
    "cityId": 9426,
    "cityName": "Oliveira Barros",
    "stateId": 5259
  },
  {
    "cityId": 9427,
    "cityName": "Onda Branca",
    "stateId": 5259
  },
  {
    "cityId": 9428,
    "cityName": "Onda Verde",
    "stateId": 5259
  },
  {
    "cityId": 9429,
    "cityName": "Oriente",
    "stateId": 5259
  },
  {
    "cityId": 9430,
    "cityName": "Orindiúva",
    "stateId": 5259
  },
  {
    "cityId": 9431,
    "cityName": "Orlândia",
    "stateId": 5259
  },
  {
    "cityId": 9432,
    "cityName": "Osasco",
    "stateId": 5259
  },
  {
    "cityId": 9433,
    "cityName": "Oscar Bressane",
    "stateId": 5259
  },
  {
    "cityId": 9434,
    "cityName": "Osvaldo Cruz",
    "stateId": 5259
  },
  {
    "cityId": 9435,
    "cityName": "Ourinhos",
    "stateId": 5259
  },
  {
    "cityId": 9436,
    "cityName": "Ouro Fino Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9437,
    "cityName": "Ouro Verde",
    "stateId": 5259
  },
  {
    "cityId": 9438,
    "cityName": "Ouroeste",
    "stateId": 5259
  },
  {
    "cityId": 9439,
    "cityName": "Pacaembu",
    "stateId": 5259
  },
  {
    "cityId": 9440,
    "cityName": "Padre Nóbrega",
    "stateId": 5259
  },
  {
    "cityId": 9441,
    "cityName": "Palestina",
    "stateId": 5259
  },
  {
    "cityId": 9442,
    "cityName": "Palmares Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9443,
    "cityName": "Palmeira D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9444,
    "cityName": "Palmeiras de São Paulo",
    "stateId": 5259
  },
  {
    "cityId": 9445,
    "cityName": "Palmital",
    "stateId": 5259
  },
  {
    "cityId": 9446,
    "cityName": "Panorama",
    "stateId": 5259
  },
  {
    "cityId": 9447,
    "cityName": "Paraguaçu Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9448,
    "cityName": "Paraibuna",
    "stateId": 5259
  },
  {
    "cityId": 9449,
    "cityName": "Paraíso",
    "stateId": 5259
  },
  {
    "cityId": 9450,
    "cityName": "Paraisolândia",
    "stateId": 5259
  },
  {
    "cityId": 9451,
    "cityName": "Paranabi",
    "stateId": 5259
  },
  {
    "cityId": 9452,
    "cityName": "Paranapanema",
    "stateId": 5259
  },
  {
    "cityId": 9453,
    "cityName": "Paranapiacaba",
    "stateId": 5259
  },
  {
    "cityId": 9454,
    "cityName": "Paranapuã",
    "stateId": 5259
  },
  {
    "cityId": 9455,
    "cityName": "Parapuã",
    "stateId": 5259
  },
  {
    "cityId": 9456,
    "cityName": "Pardinho",
    "stateId": 5259
  },
  {
    "cityId": 9457,
    "cityName": "Pariquera-Açu",
    "stateId": 5259
  },
  {
    "cityId": 9458,
    "cityName": "Parisi",
    "stateId": 5259
  },
  {
    "cityId": 9459,
    "cityName": "Parnaso",
    "stateId": 5259
  },
  {
    "cityId": 9460,
    "cityName": "Parque Meia Lua",
    "stateId": 5259
  },
  {
    "cityId": 9461,
    "cityName": "Paruru",
    "stateId": 5259
  },
  {
    "cityId": 9462,
    "cityName": "Patrocínio Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9463,
    "cityName": "Paulicéia",
    "stateId": 5259
  },
  {
    "cityId": 9464,
    "cityName": "Paulínia",
    "stateId": 5259
  },
  {
    "cityId": 9465,
    "cityName": "Paulistânia",
    "stateId": 5259
  },
  {
    "cityId": 9466,
    "cityName": "Paulo de Faria",
    "stateId": 5259
  },
  {
    "cityId": 9467,
    "cityName": "Paulópolis",
    "stateId": 5259
  },
  {
    "cityId": 9468,
    "cityName": "Pederneiras",
    "stateId": 5259
  },
  {
    "cityId": 9469,
    "cityName": "Pedra Bela",
    "stateId": 5259
  },
  {
    "cityId": 9470,
    "cityName": "Pedra Branca de Itararé",
    "stateId": 5259
  },
  {
    "cityId": 9471,
    "cityName": "Pedranópolis",
    "stateId": 5259
  },
  {
    "cityId": 9472,
    "cityName": "Pedregulho",
    "stateId": 5259
  },
  {
    "cityId": 9473,
    "cityName": "Pedreira",
    "stateId": 5259
  },
  {
    "cityId": 9474,
    "cityName": "Pedrinhas Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9475,
    "cityName": "Pedro Barros",
    "stateId": 5259
  },
  {
    "cityId": 9476,
    "cityName": "Pedro de Toledo",
    "stateId": 5259
  },
  {
    "cityId": 9477,
    "cityName": "Penápolis",
    "stateId": 5259
  },
  {
    "cityId": 9478,
    "cityName": "Pereira Barreto",
    "stateId": 5259
  },
  {
    "cityId": 9479,
    "cityName": "Pereiras",
    "stateId": 5259
  },
  {
    "cityId": 9480,
    "cityName": "Peruíbe",
    "stateId": 5259
  },
  {
    "cityId": 9481,
    "cityName": "Piacatu",
    "stateId": 5259
  },
  {
    "cityId": 9482,
    "cityName": "Picinguaba",
    "stateId": 5259
  },
  {
    "cityId": 9483,
    "cityName": "Piedade",
    "stateId": 5259
  },
  {
    "cityId": 9484,
    "cityName": "Pilar do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9485,
    "cityName": "Pindamonhangaba",
    "stateId": 5259
  },
  {
    "cityId": 9486,
    "cityName": "Pindorama",
    "stateId": 5259
  },
  {
    "cityId": 9487,
    "cityName": "Pinhalzinho",
    "stateId": 5259
  },
  {
    "cityId": 9488,
    "cityName": "Pinheiros",
    "stateId": 5259
  },
  {
    "cityId": 9489,
    "cityName": "Pioneiros",
    "stateId": 5259
  },
  {
    "cityId": 9490,
    "cityName": "Piquerobi",
    "stateId": 5259
  },
  {
    "cityId": 9491,
    "cityName": "Piquete",
    "stateId": 5259
  },
  {
    "cityId": 9492,
    "cityName": "Piracaia",
    "stateId": 5259
  },
  {
    "cityId": 9493,
    "cityName": "Piracicaba",
    "stateId": 5259
  },
  {
    "cityId": 9494,
    "cityName": "Piraju",
    "stateId": 5259
  },
  {
    "cityId": 9495,
    "cityName": "Pirajuí",
    "stateId": 5259
  },
  {
    "cityId": 9496,
    "cityName": "Pirambóia",
    "stateId": 5259
  },
  {
    "cityId": 9497,
    "cityName": "Pirangi",
    "stateId": 5259
  },
  {
    "cityId": 9498,
    "cityName": "Pirapitingui",
    "stateId": 5259
  },
  {
    "cityId": 9499,
    "cityName": "Pirapora do Bom Jesus",
    "stateId": 5259
  },
  {
    "cityId": 9500,
    "cityName": "Pirapozinho",
    "stateId": 5259
  },
  {
    "cityId": 9501,
    "cityName": "Pirassununga",
    "stateId": 5259
  },
  {
    "cityId": 9502,
    "cityName": "Piratininga",
    "stateId": 5259
  },
  {
    "cityId": 9503,
    "cityName": "Pitangueiras",
    "stateId": 5259
  },
  {
    "cityId": 9504,
    "cityName": "Planalto",
    "stateId": 5259
  },
  {
    "cityId": 9505,
    "cityName": "Planalto",
    "stateId": 5259
  },
  {
    "cityId": 9506,
    "cityName": "Planalto do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9507,
    "cityName": "Platina",
    "stateId": 5259
  },
  {
    "cityId": 9508,
    "cityName": "Poá",
    "stateId": 5259
  },
  {
    "cityId": 9509,
    "cityName": "Poloni",
    "stateId": 5259
  },
  {
    "cityId": 9510,
    "cityName": "Polvilho",
    "stateId": 5259
  },
  {
    "cityId": 9511,
    "cityName": "Pompéia",
    "stateId": 5259
  },
  {
    "cityId": 9512,
    "cityName": "Pongaí",
    "stateId": 5259
  },
  {
    "cityId": 9513,
    "cityName": "Pontal",
    "stateId": 5259
  },
  {
    "cityId": 9514,
    "cityName": "Pontalinda",
    "stateId": 5259
  },
  {
    "cityId": 9515,
    "cityName": "Pontes Gestal",
    "stateId": 5259
  },
  {
    "cityId": 9516,
    "cityName": "Populina",
    "stateId": 5259
  },
  {
    "cityId": 9517,
    "cityName": "Porangaba",
    "stateId": 5259
  },
  {
    "cityId": 9518,
    "cityName": "Porto Feliz",
    "stateId": 5259
  },
  {
    "cityId": 9519,
    "cityName": "Porto Ferreira",
    "stateId": 5259
  },
  {
    "cityId": 9520,
    "cityName": "Porto Novo",
    "stateId": 5259
  },
  {
    "cityId": 9521,
    "cityName": "Potim",
    "stateId": 5259
  },
  {
    "cityId": 9522,
    "cityName": "Potirendaba",
    "stateId": 5259
  },
  {
    "cityId": 9523,
    "cityName": "Potunduva",
    "stateId": 5259
  },
  {
    "cityId": 9524,
    "cityName": "Pracinha",
    "stateId": 5259
  },
  {
    "cityId": 9525,
    "cityName": "Pradínia",
    "stateId": 5259
  },
  {
    "cityId": 9526,
    "cityName": "Pradópolis",
    "stateId": 5259
  },
  {
    "cityId": 9527,
    "cityName": "Praia Grande",
    "stateId": 5259
  },
  {
    "cityId": 9528,
    "cityName": "Pratânia",
    "stateId": 5259
  },
  {
    "cityId": 9529,
    "cityName": "Presidente Alves",
    "stateId": 5259
  },
  {
    "cityId": 9530,
    "cityName": "Presidente Bernardes",
    "stateId": 5259
  },
  {
    "cityId": 9531,
    "cityName": "Presidente Epitácio",
    "stateId": 5259
  },
  {
    "cityId": 9532,
    "cityName": "Presidente Prudente",
    "stateId": 5259
  },
  {
    "cityId": 9533,
    "cityName": "Presidente Venceslau",
    "stateId": 5259
  },
  {
    "cityId": 9534,
    "cityName": "Primavera",
    "stateId": 5259
  },
  {
    "cityId": 9535,
    "cityName": "Promissão",
    "stateId": 5259
  },
  {
    "cityId": 9536,
    "cityName": "Prudêncio de Morais",
    "stateId": 5259
  },
  {
    "cityId": 9537,
    "cityName": "Quadra",
    "stateId": 5259
  },
  {
    "cityId": 9538,
    "cityName": "Quatá",
    "stateId": 5259
  },
  {
    "cityId": 9539,
    "cityName": "Queiroz",
    "stateId": 5259
  },
  {
    "cityId": 9540,
    "cityName": "Queluz",
    "stateId": 5259
  },
  {
    "cityId": 9541,
    "cityName": "Quintana",
    "stateId": 5259
  },
  {
    "cityId": 9542,
    "cityName": "Quiririm",
    "stateId": 5259
  },
  {
    "cityId": 9543,
    "cityName": "Rafard",
    "stateId": 5259
  },
  {
    "cityId": 9544,
    "cityName": "Rancharia",
    "stateId": 5259
  },
  {
    "cityId": 9545,
    "cityName": "Rechan",
    "stateId": 5259
  },
  {
    "cityId": 9546,
    "cityName": "Redenção da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9547,
    "cityName": "Regente Feijó",
    "stateId": 5259
  },
  {
    "cityId": 9548,
    "cityName": "Reginópolis",
    "stateId": 5259
  },
  {
    "cityId": 9549,
    "cityName": "Registro",
    "stateId": 5259
  },
  {
    "cityId": 9550,
    "cityName": "Restinga",
    "stateId": 5259
  },
  {
    "cityId": 9551,
    "cityName": "Riacho Grande",
    "stateId": 5259
  },
  {
    "cityId": 9552,
    "cityName": "Ribeira",
    "stateId": 5259
  },
  {
    "cityId": 9553,
    "cityName": "Ribeirão Bonito",
    "stateId": 5259
  },
  {
    "cityId": 9554,
    "cityName": "Ribeirão Branco",
    "stateId": 5259
  },
  {
    "cityId": 9555,
    "cityName": "Ribeirão Corrente",
    "stateId": 5259
  },
  {
    "cityId": 9556,
    "cityName": "Ribeirão do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9557,
    "cityName": "Ribeirão dos Índios",
    "stateId": 5259
  },
  {
    "cityId": 9558,
    "cityName": "Ribeirão Grande",
    "stateId": 5259
  },
  {
    "cityId": 9559,
    "cityName": "Ribeirão Pires",
    "stateId": 5259
  },
  {
    "cityId": 9560,
    "cityName": "Ribeirão Preto",
    "stateId": 5259
  },
  {
    "cityId": 9561,
    "cityName": "Ribeiro do Vale",
    "stateId": 5259
  },
  {
    "cityId": 9562,
    "cityName": "Ribeiro dos Santos",
    "stateId": 5259
  },
  {
    "cityId": 9563,
    "cityName": "Rifaina",
    "stateId": 5259
  },
  {
    "cityId": 9564,
    "cityName": "Rincão",
    "stateId": 5259
  },
  {
    "cityId": 9565,
    "cityName": "Rinópolis",
    "stateId": 5259
  },
  {
    "cityId": 9566,
    "cityName": "Rio Claro",
    "stateId": 5259
  },
  {
    "cityId": 9567,
    "cityName": "Rio das Pedras",
    "stateId": 5259
  },
  {
    "cityId": 9568,
    "cityName": "Rio Grande da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9569,
    "cityName": "Riolândia",
    "stateId": 5259
  },
  {
    "cityId": 9570,
    "cityName": "Riversul",
    "stateId": 5259
  },
  {
    "cityId": 9571,
    "cityName": "Roberto",
    "stateId": 5259
  },
  {
    "cityId": 9572,
    "cityName": "Rosália",
    "stateId": 5259
  },
  {
    "cityId": 9573,
    "cityName": "Rosana",
    "stateId": 5259
  },
  {
    "cityId": 9574,
    "cityName": "Roseira",
    "stateId": 5259
  },
  {
    "cityId": 9575,
    "cityName": "Rubiácea",
    "stateId": 5259
  },
  {
    "cityId": 9576,
    "cityName": "Rubião Júnior",
    "stateId": 5259
  },
  {
    "cityId": 9577,
    "cityName": "Rubinéia",
    "stateId": 5259
  },
  {
    "cityId": 9578,
    "cityName": "Ruilândia",
    "stateId": 5259
  },
  {
    "cityId": 9579,
    "cityName": "Sabaúna",
    "stateId": 5259
  },
  {
    "cityId": 9580,
    "cityName": "Sabino",
    "stateId": 5259
  },
  {
    "cityId": 9581,
    "cityName": "Sagres",
    "stateId": 5259
  },
  {
    "cityId": 9582,
    "cityName": "Sales",
    "stateId": 5259
  },
  {
    "cityId": 9583,
    "cityName": "Sales Oliveira",
    "stateId": 5259
  },
  {
    "cityId": 9584,
    "cityName": "Salesópolis",
    "stateId": 5259
  },
  {
    "cityId": 9585,
    "cityName": "Salmourão",
    "stateId": 5259
  },
  {
    "cityId": 9586,
    "cityName": "Saltinho",
    "stateId": 5259
  },
  {
    "cityId": 9587,
    "cityName": "Salto",
    "stateId": 5259
  },
  {
    "cityId": 9588,
    "cityName": "Salto de Pirapora",
    "stateId": 5259
  },
  {
    "cityId": 9589,
    "cityName": "Salto do Avanhandava",
    "stateId": 5259
  },
  {
    "cityId": 9590,
    "cityName": "Salto Grande",
    "stateId": 5259
  },
  {
    "cityId": 9591,
    "cityName": "Sandovalina",
    "stateId": 5259
  },
  {
    "cityId": 9592,
    "cityName": "Santa Adélia",
    "stateId": 5259
  },
  {
    "cityId": 9593,
    "cityName": "Santa Albertina",
    "stateId": 5259
  },
  {
    "cityId": 9594,
    "cityName": "Santa América",
    "stateId": 5259
  },
  {
    "cityId": 9595,
    "cityName": "Santa Bárbara D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9596,
    "cityName": "Santa Branca",
    "stateId": 5259
  },
  {
    "cityId": 9597,
    "cityName": "Santa Clara D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9598,
    "cityName": "Santa Cruz da Conceição",
    "stateId": 5259
  },
  {
    "cityId": 9599,
    "cityName": "Santa Cruz da Esperança",
    "stateId": 5259
  },
  {
    "cityId": 9600,
    "cityName": "Santa Cruz da Estrela",
    "stateId": 5259
  },
  {
    "cityId": 9601,
    "cityName": "Santa Cruz das Palmeiras",
    "stateId": 5259
  },
  {
    "cityId": 9602,
    "cityName": "Santa Cruz do Rio Pardo",
    "stateId": 5259
  },
  {
    "cityId": 9603,
    "cityName": "Santa Cruz dos Lopes",
    "stateId": 5259
  },
  {
    "cityId": 9604,
    "cityName": "Santa Ernestina",
    "stateId": 5259
  },
  {
    "cityId": 9605,
    "cityName": "Santa Eudóxia",
    "stateId": 5259
  },
  {
    "cityId": 9606,
    "cityName": "Santa Fé do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9607,
    "cityName": "Santa Gertrudes",
    "stateId": 5259
  },
  {
    "cityId": 9608,
    "cityName": "Santa Isabel",
    "stateId": 5259
  },
  {
    "cityId": 9609,
    "cityName": "Santa Isabel do Marinheiro",
    "stateId": 5259
  },
  {
    "cityId": 9610,
    "cityName": "Santa Lúcia",
    "stateId": 5259
  },
  {
    "cityId": 9611,
    "cityName": "Santa Margarida Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9612,
    "cityName": "Santa Maria da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9613,
    "cityName": "Santa Maria do Gurupá",
    "stateId": 5259
  },
  {
    "cityId": 9614,
    "cityName": "Santa Mercedes",
    "stateId": 5259
  },
  {
    "cityId": 9615,
    "cityName": "Santa Rita D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9616,
    "cityName": "Santa Rita do Passa Quatro",
    "stateId": 5259
  },
  {
    "cityId": 9617,
    "cityName": "Santa Rita do Ribeira",
    "stateId": 5259
  },
  {
    "cityId": 9618,
    "cityName": "Santa Rosa de Viterbo",
    "stateId": 5259
  },
  {
    "cityId": 9619,
    "cityName": "Santa Salete",
    "stateId": 5259
  },
  {
    "cityId": 9621,
    "cityName": "Santana da Ponte Pensa",
    "stateId": 5259
  },
  {
    "cityId": 9622,
    "cityName": "Santana de Parnaíba",
    "stateId": 5259
  },
  {
    "cityId": 9623,
    "cityName": "Santelmo",
    "stateId": 5259
  },
  {
    "cityId": 9624,
    "cityName": "Santo Anastácio",
    "stateId": 5259
  },
  {
    "cityId": 9625,
    "cityName": "Santo André",
    "stateId": 5259
  },
  {
    "cityId": 9626,
    "cityName": "Santo Antônio da Alegria",
    "stateId": 5259
  },
  {
    "cityId": 9627,
    "cityName": "Santo Antônio da Estiva",
    "stateId": 5259
  },
  {
    "cityId": 9628,
    "cityName": "Santo Antônio de Posse",
    "stateId": 5259
  },
  {
    "cityId": 9629,
    "cityName": "Santo Antônio do Aracanguá",
    "stateId": 5259
  },
  {
    "cityId": 9630,
    "cityName": "Santo Antônio do Jardim",
    "stateId": 5259
  },
  {
    "cityId": 9631,
    "cityName": "Santo Antônio do Paranapanema",
    "stateId": 5259
  },
  {
    "cityId": 9632,
    "cityName": "Santo Antônio do Pinhal",
    "stateId": 5259
  },
  {
    "cityId": 9633,
    "cityName": "Santo Antônio Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9634,
    "cityName": "Santo Expedito",
    "stateId": 5259
  },
  {
    "cityId": 9635,
    "cityName": "Santópolis do Aguapeí",
    "stateId": 5259
  },
  {
    "cityId": 9636,
    "cityName": "Santos",
    "stateId": 5259
  },
  {
    "cityId": 9637,
    "cityName": "São Benedito da Cachoeirinha",
    "stateId": 5259
  },
  {
    "cityId": 9638,
    "cityName": "São Benedito das Areias",
    "stateId": 5259
  },
  {
    "cityId": 9639,
    "cityName": "São Bento do Sapucaí",
    "stateId": 5259
  },
  {
    "cityId": 9640,
    "cityName": "São Bernardo do Campo",
    "stateId": 5259
  },
  {
    "cityId": 9641,
    "cityName": "São Berto",
    "stateId": 5259
  },
  {
    "cityId": 9642,
    "cityName": "São Caetano do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9643,
    "cityName": "São Carlos",
    "stateId": 5259
  },
  {
    "cityId": 9644,
    "cityName": "São Francisco",
    "stateId": 5259
  },
  {
    "cityId": 9645,
    "cityName": "São Francisco da Praia",
    "stateId": 5259
  },
  {
    "cityId": 9646,
    "cityName": "São Francisco Xavier",
    "stateId": 5259
  },
  {
    "cityId": 9647,
    "cityName": "São João da Boa Vista",
    "stateId": 5259
  },
  {
    "cityId": 9648,
    "cityName": "São João das Duas Pontes",
    "stateId": 5259
  },
  {
    "cityId": 9649,
    "cityName": "São João de Iracema",
    "stateId": 5259
  },
  {
    "cityId": 9650,
    "cityName": "São João de Itaguaçu",
    "stateId": 5259
  },
  {
    "cityId": 9651,
    "cityName": "São João do Marinheiro",
    "stateId": 5259
  },
  {
    "cityId": 9652,
    "cityName": "São João do Pau d'Alho",
    "stateId": 5259
  },
  {
    "cityId": 9653,
    "cityName": "São João Novo",
    "stateId": 5259
  },
  {
    "cityId": 9654,
    "cityName": "São Joaquim da Barra",
    "stateId": 5259
  },
  {
    "cityId": 9655,
    "cityName": "São José da Bela Vista",
    "stateId": 5259
  },
  {
    "cityId": 9656,
    "cityName": "São José das Laranjeiras",
    "stateId": 5259
  },
  {
    "cityId": 9657,
    "cityName": "São José do Barreiro",
    "stateId": 5259
  },
  {
    "cityId": 9658,
    "cityName": "São José do Rio Pardo",
    "stateId": 5259
  },
  {
    "cityId": 9659,
    "cityName": "São José do Rio Preto",
    "stateId": 5259
  },
  {
    "cityId": 9660,
    "cityName": "São José dos Campos",
    "stateId": 5259
  },
  {
    "cityId": 9661,
    "cityName": "São Lourenço da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9662,
    "cityName": "São Lourenço do Turvo",
    "stateId": 5259
  },
  {
    "cityId": 9663,
    "cityName": "São Luiz do Paraitinga",
    "stateId": 5259
  },
  {
    "cityId": 9664,
    "cityName": "São Luiz do Guaricanga",
    "stateId": 5259
  },
  {
    "cityId": 9665,
    "cityName": "São Manuel",
    "stateId": 5259
  },
  {
    "cityId": 9666,
    "cityName": "São Martinho D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9667,
    "cityName": "São Miguel Arcanjo",
    "stateId": 5259
  },
  {
    "cityId": 9668,
    "cityName": "São Paulo",
    "stateId": 5259
  },
  {
    "cityId": 9669,
    "cityName": "São Pedro",
    "stateId": 5259
  },
  {
    "cityId": 9670,
    "cityName": "São Pedro do Turvo",
    "stateId": 5259
  },
  {
    "cityId": 9671,
    "cityName": "São Roque",
    "stateId": 5259
  },
  {
    "cityId": 9672,
    "cityName": "São Roque da Fartura",
    "stateId": 5259
  },
  {
    "cityId": 9673,
    "cityName": "São Sebastião",
    "stateId": 5259
  },
  {
    "cityId": 9674,
    "cityName": "São Sebastião da Grama",
    "stateId": 5259
  },
  {
    "cityId": 9675,
    "cityName": "São Sebastião da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9676,
    "cityName": "São Silvestre de Jacareí",
    "stateId": 5259
  },
  {
    "cityId": 9677,
    "cityName": "São Simão",
    "stateId": 5259
  },
  {
    "cityId": 9678,
    "cityName": "São Vicente",
    "stateId": 5259
  },
  {
    "cityId": 9679,
    "cityName": "Sapezal",
    "stateId": 5259
  },
  {
    "cityId": 9680,
    "cityName": "Sarapuí",
    "stateId": 5259
  },
  {
    "cityId": 9681,
    "cityName": "Sarutaiá",
    "stateId": 5259
  },
  {
    "cityId": 9682,
    "cityName": "Sebastianópolis do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9683,
    "cityName": "Serra Azul",
    "stateId": 5259
  },
  {
    "cityId": 9684,
    "cityName": "Serra Negra",
    "stateId": 5259
  },
  {
    "cityId": 9685,
    "cityName": "Serrana",
    "stateId": 5259
  },
  {
    "cityId": 9686,
    "cityName": "Sertãozinho",
    "stateId": 5259
  },
  {
    "cityId": 9687,
    "cityName": "Sete Barras",
    "stateId": 5259
  },
  {
    "cityId": 9688,
    "cityName": "Severínia",
    "stateId": 5259
  },
  {
    "cityId": 9689,
    "cityName": "Silvânia",
    "stateId": 5259
  },
  {
    "cityId": 9690,
    "cityName": "Silveiras",
    "stateId": 5259
  },
  {
    "cityId": 9691,
    "cityName": "Simões",
    "stateId": 5259
  },
  {
    "cityId": 9692,
    "cityName": "Simonsen",
    "stateId": 5259
  },
  {
    "cityId": 9693,
    "cityName": "Socorro",
    "stateId": 5259
  },
  {
    "cityId": 9694,
    "cityName": "Sodrélia",
    "stateId": 5259
  },
  {
    "cityId": 9695,
    "cityName": "Solemar",
    "stateId": 5259
  },
  {
    "cityId": 9696,
    "cityName": "Sorocaba",
    "stateId": 5259
  },
  {
    "cityId": 9697,
    "cityName": "Sousas",
    "stateId": 5259
  },
  {
    "cityId": 9698,
    "cityName": "Sud Mennucci",
    "stateId": 5259
  },
  {
    "cityId": 9699,
    "cityName": "Suinana",
    "stateId": 5259
  },
  {
    "cityId": 9700,
    "cityName": "Sumaré",
    "stateId": 5259
  },
  {
    "cityId": 9701,
    "cityName": "Sussui",
    "stateId": 5259
  },
  {
    "cityId": 9702,
    "cityName": "Suzanápolis",
    "stateId": 5259
  },
  {
    "cityId": 9703,
    "cityName": "Suzano",
    "stateId": 5259
  },
  {
    "cityId": 9704,
    "cityName": "Tabajara",
    "stateId": 5259
  },
  {
    "cityId": 9705,
    "cityName": "Tabapuã",
    "stateId": 5259
  },
  {
    "cityId": 9706,
    "cityName": "Tabatinga",
    "stateId": 5259
  },
  {
    "cityId": 9707,
    "cityName": "Taboão da Serra",
    "stateId": 5259
  },
  {
    "cityId": 9708,
    "cityName": "Taciba",
    "stateId": 5259
  },
  {
    "cityId": 9709,
    "cityName": "Taguaí",
    "stateId": 5259
  },
  {
    "cityId": 9710,
    "cityName": "Taiaçu",
    "stateId": 5259
  },
  {
    "cityId": 9711,
    "cityName": "Taiacupeba",
    "stateId": 5259
  },
  {
    "cityId": 9712,
    "cityName": "Taiúva",
    "stateId": 5259
  },
  {
    "cityId": 9713,
    "cityName": "Talhado",
    "stateId": 5259
  },
  {
    "cityId": 9714,
    "cityName": "Tambaú",
    "stateId": 5259
  },
  {
    "cityId": 9715,
    "cityName": "Tanabi",
    "stateId": 5259
  },
  {
    "cityId": 9716,
    "cityName": "Tapinas",
    "stateId": 5259
  },
  {
    "cityId": 9717,
    "cityName": "Tapiraí",
    "stateId": 5259
  },
  {
    "cityId": 9718,
    "cityName": "Tapiratiba",
    "stateId": 5259
  },
  {
    "cityId": 9719,
    "cityName": "Taquaral",
    "stateId": 5259
  },
  {
    "cityId": 9720,
    "cityName": "Taquaritinga",
    "stateId": 5259
  },
  {
    "cityId": 9721,
    "cityName": "Taquarituba",
    "stateId": 5259
  },
  {
    "cityId": 9722,
    "cityName": "Taquarivaí",
    "stateId": 5259
  },
  {
    "cityId": 9723,
    "cityName": "Tarabai",
    "stateId": 5259
  },
  {
    "cityId": 9724,
    "cityName": "Tarumã",
    "stateId": 5259
  },
  {
    "cityId": 9725,
    "cityName": "Tatuí",
    "stateId": 5259
  },
  {
    "cityId": 9726,
    "cityName": "Taubaté",
    "stateId": 5259
  },
  {
    "cityId": 9727,
    "cityName": "Tecainda",
    "stateId": 5259
  },
  {
    "cityId": 9728,
    "cityName": "Tejupá",
    "stateId": 5259
  },
  {
    "cityId": 9729,
    "cityName": "Teodoro Sampaio",
    "stateId": 5259
  },
  {
    "cityId": 9730,
    "cityName": "Termas de Ibirá",
    "stateId": 5259
  },
  {
    "cityId": 9731,
    "cityName": "Terra Nova D'Oeste",
    "stateId": 5259
  },
  {
    "cityId": 9732,
    "cityName": "Terra Roxa",
    "stateId": 5259
  },
  {
    "cityId": 9733,
    "cityName": "Tibiriçá",
    "stateId": 5259
  },
  {
    "cityId": 9734,
    "cityName": "Tibiriçá do Paranapanema",
    "stateId": 5259
  },
  {
    "cityId": 9735,
    "cityName": "Tietê",
    "stateId": 5259
  },
  {
    "cityId": 9736,
    "cityName": "Timburi",
    "stateId": 5259
  },
  {
    "cityId": 9737,
    "cityName": "Toledo",
    "stateId": 5259
  },
  {
    "cityId": 9738,
    "cityName": "Torre de Pedra",
    "stateId": 5259
  },
  {
    "cityId": 9739,
    "cityName": "Torrinha",
    "stateId": 5259
  },
  {
    "cityId": 9740,
    "cityName": "Trabiju",
    "stateId": 5259
  },
  {
    "cityId": 9741,
    "cityName": "Tremembé",
    "stateId": 5259
  },
  {
    "cityId": 9742,
    "cityName": "Três Alianças",
    "stateId": 5259
  },
  {
    "cityId": 9743,
    "cityName": "Três Fronteiras",
    "stateId": 5259
  },
  {
    "cityId": 9744,
    "cityName": "Três Pontes",
    "stateId": 5259
  },
  {
    "cityId": 9746,
    "cityName": "Tuiuti",
    "stateId": 5259
  },
  {
    "cityId": 9747,
    "cityName": "Tujuguaba",
    "stateId": 5259
  },
  {
    "cityId": 9748,
    "cityName": "Tupã",
    "stateId": 5259
  },
  {
    "cityId": 9749,
    "cityName": "Tupi",
    "stateId": 5259
  },
  {
    "cityId": 9750,
    "cityName": "Tupi Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9751,
    "cityName": "Turiba do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9752,
    "cityName": "Turiúba",
    "stateId": 5259
  },
  {
    "cityId": 9753,
    "cityName": "Turmalina",
    "stateId": 5259
  },
  {
    "cityId": 9754,
    "cityName": "Turvínia",
    "stateId": 5259
  },
  {
    "cityId": 9755,
    "cityName": "Ubarana",
    "stateId": 5259
  },
  {
    "cityId": 9756,
    "cityName": "Ubatuba",
    "stateId": 5259
  },
  {
    "cityId": 9757,
    "cityName": "Ubirajara",
    "stateId": 5259
  },
  {
    "cityId": 9758,
    "cityName": "Uchoa",
    "stateId": 5259
  },
  {
    "cityId": 9759,
    "cityName": "União Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9760,
    "cityName": "Universo",
    "stateId": 5259
  },
  {
    "cityId": 9761,
    "cityName": "Urânia",
    "stateId": 5259
  },
  {
    "cityId": 9762,
    "cityName": "Uru",
    "stateId": 5259
  },
  {
    "cityId": 9763,
    "cityName": "Urupês",
    "stateId": 5259
  },
  {
    "cityId": 9764,
    "cityName": "Ururai",
    "stateId": 5259
  },
  {
    "cityId": 9765,
    "cityName": "Utinga",
    "stateId": 5259
  },
  {
    "cityId": 9766,
    "cityName": "Vale Formoso",
    "stateId": 5259
  },
  {
    "cityId": 9767,
    "cityName": "Valentim Gentil",
    "stateId": 5259
  },
  {
    "cityId": 9768,
    "cityName": "Valinhos",
    "stateId": 5259
  },
  {
    "cityId": 9769,
    "cityName": "Valparaíso",
    "stateId": 5259
  },
  {
    "cityId": 9770,
    "cityName": "Vanglória",
    "stateId": 5259
  },
  {
    "cityId": 9771,
    "cityName": "Vargem",
    "stateId": 5259
  },
  {
    "cityId": 9772,
    "cityName": "Vargem Grande do Sul",
    "stateId": 5259
  },
  {
    "cityId": 9773,
    "cityName": "Vargem Grande Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9774,
    "cityName": "Varpa",
    "stateId": 5259
  },
  {
    "cityId": 9775,
    "cityName": "Várzea Paulista",
    "stateId": 5259
  },
  {
    "cityId": 9776,
    "cityName": "Venda Branca",
    "stateId": 5259
  },
  {
    "cityId": 9777,
    "cityName": "Vera Cruz",
    "stateId": 5259
  },
  {
    "cityId": 9778,
    "cityName": "Vicente de Carvalho",
    "stateId": 5259
  },
  {
    "cityId": 9779,
    "cityName": "Vicentinópolis",
    "stateId": 5259
  },
  {
    "cityId": 9780,
    "cityName": "Vila Dirce",
    "stateId": 5259
  },
  {
    "cityId": 9781,
    "cityName": "Vila Nery",
    "stateId": 5259
  },
  {
    "cityId": 9782,
    "cityName": "Vila Xavier",
    "stateId": 5259
  },
  {
    "cityId": 9783,
    "cityName": "Vinhedo",
    "stateId": 5259
  },
  {
    "cityId": 9784,
    "cityName": "Viradouro",
    "stateId": 5259
  },
  {
    "cityId": 9785,
    "cityName": "Vista Alegre do Alto",
    "stateId": 5259
  },
  {
    "cityId": 9786,
    "cityName": "Vitória Brasil",
    "stateId": 5259
  },
  {
    "cityId": 9787,
    "cityName": "Vitoriana",
    "stateId": 5259
  },
  {
    "cityId": 9788,
    "cityName": "Votorantim",
    "stateId": 5259
  },
  {
    "cityId": 9789,
    "cityName": "Votuporanga",
    "stateId": 5259
  },
  {
    "cityId": 9790,
    "cityName": "Zacarias",
    "stateId": 5259
  },
  {
    "cityId": 9791,
    "cityName": "Abreulândia",
    "stateId": 5260
  },
  {
    "cityId": 9792,
    "cityName": "Aguiarnópolis",
    "stateId": 5260
  },
  {
    "cityId": 9793,
    "cityName": "Aliança do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9794,
    "cityName": "Almas",
    "stateId": 5260
  },
  {
    "cityId": 9795,
    "cityName": "Alvorada",
    "stateId": 5260
  },
  {
    "cityId": 9796,
    "cityName": "Anajanópolis",
    "stateId": 5260
  },
  {
    "cityId": 9797,
    "cityName": "Ananás",
    "stateId": 5260
  },
  {
    "cityId": 9798,
    "cityName": "Angico",
    "stateId": 5260
  },
  {
    "cityId": 9799,
    "cityName": "Aparecida do Rio Negro",
    "stateId": 5260
  },
  {
    "cityId": 9800,
    "cityName": "Apinajé",
    "stateId": 5260
  },
  {
    "cityId": 9801,
    "cityName": "Aragaçuí",
    "stateId": 5260
  },
  {
    "cityId": 9802,
    "cityName": "Aragominas",
    "stateId": 5260
  },
  {
    "cityId": 9803,
    "cityName": "Araguacema",
    "stateId": 5260
  },
  {
    "cityId": 9804,
    "cityName": "Araguaçu",
    "stateId": 5260
  },
  {
    "cityId": 9805,
    "cityName": "Araguaína",
    "stateId": 5260
  },
  {
    "cityId": 9806,
    "cityName": "Araguanã",
    "stateId": 5260
  },
  {
    "cityId": 9807,
    "cityName": "Araguatins",
    "stateId": 5260
  },
  {
    "cityId": 9808,
    "cityName": "Arapoema",
    "stateId": 5260
  },
  {
    "cityId": 9809,
    "cityName": "Arraias",
    "stateId": 5260
  },
  {
    "cityId": 9810,
    "cityName": "Augustinópolis",
    "stateId": 5260
  },
  {
    "cityId": 9811,
    "cityName": "Aurora do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9812,
    "cityName": "Axixá do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9813,
    "cityName": "Babaçulândia",
    "stateId": 5260
  },
  {
    "cityId": 9814,
    "cityName": "Bandeirantes do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9815,
    "cityName": "Barra do Grota",
    "stateId": 5260
  },
  {
    "cityId": 9816,
    "cityName": "Barra do Ouro",
    "stateId": 5260
  },
  {
    "cityId": 9817,
    "cityName": "Barrolândia",
    "stateId": 5260
  },
  {
    "cityId": 9818,
    "cityName": "Bernardo Sayão",
    "stateId": 5260
  },
  {
    "cityId": 9819,
    "cityName": "Bom Jesus do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9820,
    "cityName": "Brasilândia",
    "stateId": 5260
  },
  {
    "cityId": 9821,
    "cityName": "Brasilândia do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9822,
    "cityName": "Brejinho de Nazaré",
    "stateId": 5260
  },
  {
    "cityId": 9823,
    "cityName": "Buriti do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9824,
    "cityName": "Cachoeirinha",
    "stateId": 5260
  },
  {
    "cityId": 9825,
    "cityName": "Campos Lindos",
    "stateId": 5260
  },
  {
    "cityId": 9826,
    "cityName": "Cana Brava",
    "stateId": 5260
  },
  {
    "cityId": 9827,
    "cityName": "Cariri do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9828,
    "cityName": "Carmolândia",
    "stateId": 5260
  },
  {
    "cityId": 9829,
    "cityName": "Carrasco Bonito",
    "stateId": 5260
  },
  {
    "cityId": 9830,
    "cityName": "Cartucho",
    "stateId": 5260
  },
  {
    "cityId": 9831,
    "cityName": "Caseara",
    "stateId": 5260
  },
  {
    "cityId": 9832,
    "cityName": "Centenário",
    "stateId": 5260
  },
  {
    "cityId": 9833,
    "cityName": "Chapada de Areia",
    "stateId": 5260
  },
  {
    "cityId": 9834,
    "cityName": "Chapada da Natividade",
    "stateId": 5260
  },
  {
    "cityId": 9835,
    "cityName": "Cocalândia",
    "stateId": 5260
  },
  {
    "cityId": 9836,
    "cityName": "Cocalinho",
    "stateId": 5260
  },
  {
    "cityId": 9837,
    "cityName": "Colinas do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9838,
    "cityName": "Colméia",
    "stateId": 5260
  },
  {
    "cityId": 9839,
    "cityName": "Combinado",
    "stateId": 5260
  },
  {
    "cityId": 9840,
    "cityName": "Conceição do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9841,
    "cityName": "Correinha",
    "stateId": 5260
  },
  {
    "cityId": 9842,
    "cityName": "Couto de Magalhães",
    "stateId": 5260
  },
  {
    "cityId": 9843,
    "cityName": "Craolândia",
    "stateId": 5260
  },
  {
    "cityId": 9844,
    "cityName": "Cristalândia",
    "stateId": 5260
  },
  {
    "cityId": 9845,
    "cityName": "Crixás",
    "stateId": 5260
  },
  {
    "cityId": 9846,
    "cityName": "Crixás do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9847,
    "cityName": "Darcinópolis",
    "stateId": 5260
  },
  {
    "cityId": 9848,
    "cityName": "Dianópolis",
    "stateId": 5260
  },
  {
    "cityId": 9849,
    "cityName": "Divinópolis do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9850,
    "cityName": "Dois Irmãos do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9851,
    "cityName": "Duerê",
    "stateId": 5260
  },
  {
    "cityId": 9852,
    "cityName": "Escondido",
    "stateId": 5260
  },
  {
    "cityId": 9853,
    "cityName": "Esperantina",
    "stateId": 5260
  },
  {
    "cityId": 9854,
    "cityName": "Fátima",
    "stateId": 5260
  },
  {
    "cityId": 9855,
    "cityName": "Figueirópolis",
    "stateId": 5260
  },
  {
    "cityId": 9856,
    "cityName": "Filadélfia",
    "stateId": 5260
  },
  {
    "cityId": 9857,
    "cityName": "Formoso do Araguaia",
    "stateId": 5260
  },
  {
    "cityId": 9858,
    "cityName": "Fortaleza do Tabocão",
    "stateId": 5260
  },
  {
    "cityId": 9859,
    "cityName": "Goianorte",
    "stateId": 5260
  },
  {
    "cityId": 9860,
    "cityName": "Goiatins",
    "stateId": 5260
  },
  {
    "cityId": 9861,
    "cityName": "Guaraí",
    "stateId": 5260
  },
  {
    "cityId": 9862,
    "cityName": "Gurupi",
    "stateId": 5260
  },
  {
    "cityId": 9863,
    "cityName": "Ilha Barreira Branca",
    "stateId": 5260
  },
  {
    "cityId": 9864,
    "cityName": "Ipueiras",
    "stateId": 5260
  },
  {
    "cityId": 9865,
    "cityName": "Itacajá",
    "stateId": 5260
  },
  {
    "cityId": 9866,
    "cityName": "Itaguatins",
    "stateId": 5260
  },
  {
    "cityId": 9867,
    "cityName": "Itapiratins",
    "stateId": 5260
  },
  {
    "cityId": 9868,
    "cityName": "Itaporã do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9869,
    "cityName": "Jaú do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9870,
    "cityName": "Juarina",
    "stateId": 5260
  },
  {
    "cityId": 9871,
    "cityName": "Jussara",
    "stateId": 5260
  },
  {
    "cityId": 9872,
    "cityName": "Lagoa da Confusão",
    "stateId": 5260
  },
  {
    "cityId": 9873,
    "cityName": "Lagoa do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9874,
    "cityName": "Lajeado",
    "stateId": 5260
  },
  {
    "cityId": 9875,
    "cityName": "Lavandeira",
    "stateId": 5260
  },
  {
    "cityId": 9876,
    "cityName": "Lizarda",
    "stateId": 5260
  },
  {
    "cityId": 9877,
    "cityName": "Luzinópolis",
    "stateId": 5260
  },
  {
    "cityId": 9878,
    "cityName": "Marianópolis do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9879,
    "cityName": "Mateiros",
    "stateId": 5260
  },
  {
    "cityId": 9880,
    "cityName": "Maurilândia do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9881,
    "cityName": "Miracema do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9882,
    "cityName": "Mirandópolis",
    "stateId": 5260
  },
  {
    "cityId": 9883,
    "cityName": "Miranorte",
    "stateId": 5260
  },
  {
    "cityId": 9884,
    "cityName": "Monte do Carmo",
    "stateId": 5260
  },
  {
    "cityId": 9885,
    "cityName": "Monte Lindo",
    "stateId": 5260
  },
  {
    "cityId": 9886,
    "cityName": "Monte Santo do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9887,
    "cityName": "Palmeiras do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9888,
    "cityName": "Muricilândia",
    "stateId": 5260
  },
  {
    "cityId": 9889,
    "cityName": "Natal",
    "stateId": 5260
  },
  {
    "cityId": 9890,
    "cityName": "Natividade",
    "stateId": 5260
  },
  {
    "cityId": 9891,
    "cityName": "Nazaré",
    "stateId": 5260
  },
  {
    "cityId": 9892,
    "cityName": "Nova Olinda",
    "stateId": 5260
  },
  {
    "cityId": 9893,
    "cityName": "Nova Rosalândia",
    "stateId": 5260
  },
  {
    "cityId": 9894,
    "cityName": "Novo Acordo",
    "stateId": 5260
  },
  {
    "cityId": 9895,
    "cityName": "Novo Alegre",
    "stateId": 5260
  },
  {
    "cityId": 9896,
    "cityName": "Novo Horizonte",
    "stateId": 5260
  },
  {
    "cityId": 9897,
    "cityName": "Novo Jardim",
    "stateId": 5260
  },
  {
    "cityId": 9898,
    "cityName": "Oliveira de Fátima",
    "stateId": 5260
  },
  {
    "cityId": 9899,
    "cityName": "Palmas",
    "stateId": 5260
  },
  {
    "cityId": 9900,
    "cityName": "Palmeirante",
    "stateId": 5260
  },
  {
    "cityId": 9901,
    "cityName": "Palmeirópolis",
    "stateId": 5260
  },
  {
    "cityId": 9902,
    "cityName": "Paraíso do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9903,
    "cityName": "Paranã",
    "stateId": 5260
  },
  {
    "cityId": 9904,
    "cityName": "Pau D'Arco",
    "stateId": 5260
  },
  {
    "cityId": 9905,
    "cityName": "Pé da Serra",
    "stateId": 5260
  },
  {
    "cityId": 9906,
    "cityName": "Pedro Afonso",
    "stateId": 5260
  },
  {
    "cityId": 9907,
    "cityName": "Pedro Ludovico",
    "stateId": 5260
  },
  {
    "cityId": 9908,
    "cityName": "Peixe",
    "stateId": 5260
  },
  {
    "cityId": 9909,
    "cityName": "Peixe",
    "stateId": 5260
  },
  {
    "cityId": 9910,
    "cityName": "Pequizeiro",
    "stateId": 5260
  },
  {
    "cityId": 9911,
    "cityName": "Pilões",
    "stateId": 5260
  },
  {
    "cityId": 9912,
    "cityName": "Pindorama do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9913,
    "cityName": "Piraquê",
    "stateId": 5260
  },
  {
    "cityId": 9914,
    "cityName": "Pium",
    "stateId": 5260
  },
  {
    "cityId": 9915,
    "cityName": "Ponte Alta do Bom Jesus",
    "stateId": 5260
  },
  {
    "cityId": 9916,
    "cityName": "Ponte Alta do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9917,
    "cityName": "Pontes",
    "stateId": 5260
  },
  {
    "cityId": 9918,
    "cityName": "Porãozinho",
    "stateId": 5260
  },
  {
    "cityId": 9919,
    "cityName": "Porto Alegre do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9920,
    "cityName": "Porto Lemos",
    "stateId": 5260
  },
  {
    "cityId": 9921,
    "cityName": "Porto Nacional",
    "stateId": 5260
  },
  {
    "cityId": 9922,
    "cityName": "Praia Norte",
    "stateId": 5260
  },
  {
    "cityId": 9923,
    "cityName": "Presidente Kennedy",
    "stateId": 5260
  },
  {
    "cityId": 9924,
    "cityName": "Príncipe",
    "stateId": 5260
  },
  {
    "cityId": 9925,
    "cityName": "Pugmil",
    "stateId": 5260
  },
  {
    "cityId": 9926,
    "cityName": "Recursolândia",
    "stateId": 5260
  },
  {
    "cityId": 9927,
    "cityName": "Riachinho",
    "stateId": 5260
  },
  {
    "cityId": 9928,
    "cityName": "Rio da Conceição",
    "stateId": 5260
  },
  {
    "cityId": 9929,
    "cityName": "Rio dos Bois",
    "stateId": 5260
  },
  {
    "cityId": 9930,
    "cityName": "Rio Sono",
    "stateId": 5260
  },
  {
    "cityId": 9931,
    "cityName": "Sampaio",
    "stateId": 5260
  },
  {
    "cityId": 9932,
    "cityName": "Sandolândia",
    "stateId": 5260
  },
  {
    "cityId": 9933,
    "cityName": "Santa Fé do Araguaia",
    "stateId": 5260
  },
  {
    "cityId": 9934,
    "cityName": "Santa Maria do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9935,
    "cityName": "Santa Rita do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9936,
    "cityName": "Santa Rosa do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9937,
    "cityName": "Santa Tereza do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9938,
    "cityName": "Santa Terezinha do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9939,
    "cityName": "São Bento do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9940,
    "cityName": "São Félix do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9941,
    "cityName": "São Miguel do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9942,
    "cityName": "São Salvador do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9943,
    "cityName": "São Sebastião do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9944,
    "cityName": "São Valério da Natividade",
    "stateId": 5260
  },
  {
    "cityId": 9945,
    "cityName": "Silvanópolis",
    "stateId": 5260
  },
  {
    "cityId": 9946,
    "cityName": "Sítio Novo do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9947,
    "cityName": "Sucupira",
    "stateId": 5260
  },
  {
    "cityId": 9948,
    "cityName": "Taguatinga",
    "stateId": 5260
  },
  {
    "cityId": 9949,
    "cityName": "Taipas do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9950,
    "cityName": "Talismã",
    "stateId": 5260
  },
  {
    "cityId": 9951,
    "cityName": "Tamboril",
    "stateId": 5260
  },
  {
    "cityId": 9952,
    "cityName": "Taquaralto",
    "stateId": 5260
  },
  {
    "cityId": 9953,
    "cityName": "Taquarussu do Tocantins",
    "stateId": 5260
  },
  {
    "cityId": 9954,
    "cityName": "Tocantínia",
    "stateId": 5260
  },
  {
    "cityId": 9955,
    "cityName": "Tocantinópolis",
    "stateId": 5260
  },
  {
    "cityId": 9956,
    "cityName": "Tupirama",
    "stateId": 5260
  },
  {
    "cityId": 9957,
    "cityName": "Tupiratã",
    "stateId": 5260
  },
  {
    "cityId": 9958,
    "cityName": "Tupiratins",
    "stateId": 5260
  },
  {
    "cityId": 9959,
    "cityName": "Vênus",
    "stateId": 5260
  },
  {
    "cityId": 9960,
    "cityName": "Wanderlândia",
    "stateId": 5260
  },
  {
    "cityId": 9961,
    "cityName": "Xambioá",
    "stateId": 5260
  },
  {
    "cityId": 10089,
    "cityName": "Jequiá da Praia",
    "stateId": 5235
  },
  {
    "cityId": 10090,
    "cityName": "Ipiranga de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 10091,
    "cityName": "Conquista D'Oeste",
    "stateId": 5246
  },
  {
    "cityId": 10092,
    "cityName": "Colniza",
    "stateId": 5246
  },
  {
    "cityId": 10093,
    "cityName": "Rondolândia",
    "stateId": 5246
  },
  {
    "cityId": 10094,
    "cityName": "Santa Rita do Trivelato",
    "stateId": 5246
  },
  {
    "cityId": 10095,
    "cityName": "Nova Santa Helena",
    "stateId": 5246
  },
  {
    "cityId": 10096,
    "cityName": "Santo Antônio do Leste",
    "stateId": 5246
  },
  {
    "cityId": 10097,
    "cityName": "Nova Nazaré",
    "stateId": 5246
  },
  {
    "cityId": 10098,
    "cityName": "Santa Cruz do Xingu",
    "stateId": 5246
  },
  {
    "cityId": 10099,
    "cityName": "Bom Jesus do Araguaia",
    "stateId": 5246
  },
  {
    "cityId": 10100,
    "cityName": "Pau D'Arco do Piauí",
    "stateId": 5250
  },
  {
    "cityId": 10101,
    "cityName": "Westfália",
    "stateId": 5256
  },
  {
    "cityId": 10102,
    "cityName": "Santa Margarida do Sul",
    "stateId": 5256
  },
  {
    "cityId": 10103,
    "cityName": "Tio Hugo",
    "stateId": 5256
  },
  {
    "cityId": 10104,
    "cityName": "Canaúna",
    "stateId": 5239
  },
  {
    "cityId": 10105,
    "cityName": "São João do Norte",
    "stateId": 5241
  },
  {
    "cityId": 10106,
    "cityName": "São Raimundo da Pedra Menina",
    "stateId": 5241
  },
  {
    "cityId": 10107,
    "cityName": "Santa Cruz de Irupi",
    "stateId": 5241
  },
  {
    "cityId": 10108,
    "cityName": "São João do Príncipe",
    "stateId": 5241
  },
  {
    "cityId": 10109,
    "cityName": "Nossa Senhora de Fátima",
    "stateId": 5241
  },
  {
    "cityId": 10110,
    "cityName": "Santa Maria de Marechal",
    "stateId": 5241
  },
  {
    "cityId": 10111,
    "cityName": "Alto Castelinho",
    "stateId": 5241
  },
  {
    "cityId": 10112,
    "cityName": "Prosperidade",
    "stateId": 5241
  },
  {
    "cityId": 10113,
    "cityName": "São José de Fruteiras",
    "stateId": 5241
  },
  {
    "cityId": 10114,
    "cityName": "São Jorge da Barra Seca",
    "stateId": 5241
  },
  {
    "cityId": 10115,
    "cityName": "Jurama",
    "stateId": 5241
  },
  {
    "cityId": 10117,
    "cityName": "Sucanga",
    "stateId": 5244
  },
  {
    "cityId": 10120,
    "cityName": "Triângulo",
    "stateId": 5239
  },
  {
    "cityId": 10121,
    "cityName": "Cedro",
    "stateId": 5239
  },
  {
    "cityId": 10122,
    "cityName": "Patos dos Liberatos",
    "stateId": 5239
  },
  {
    "cityId": 10123,
    "cityName": "Campestre",
    "stateId": 5239
  },
  {
    "cityId": 10124,
    "cityName": "Timbaúba dos Marinheiros",
    "stateId": 5239
  },
  {
    "cityId": 10144,
    "cityName": "Rialma",
    "stateId": 5242
  },
  {
    "cityId": 10164,
    "cityName": "Jaboatão",
    "stateId": 5249
  },
  {
    "cityId": 10184,
    "cityName": "Navarro",
    "stateId": 5249
  },
  {
    "cityId": 10185,
    "cityName": "Cavaleiro",
    "stateId": 5249
  },
  {
    "cityId": 10186,
    "cityName": "Praia da Conceição",
    "stateId": 5249
  },
  {
    "cityId": 10187,
    "cityName": "Paratibe",
    "stateId": 5249
  },
  {
    "cityId": 10205,
    "cityName": "Chonin de Baixo",
    "stateId": 5244
  },
  {
    "cityId": 10206,
    "cityName": "JK",
    "stateId": 5242
  },
  {
    "cityId": 10207,
    "cityName": "Bezerra",
    "stateId": 5242
  },
  {
    "cityId": 10208,
    "cityName": "São Pedro da Serra",
    "stateId": 5252
  },
  {
    "cityId": 10209,
    "cityName": "Arraial D'Ajuda",
    "stateId": 5238
  },
  {
    "cityId": 10210,
    "cityName": "Trancoso",
    "stateId": 5238
  },
  {
    "cityId": 10211,
    "cityName": "Itabatã",
    "stateId": 5238
  },
  {
    "cityId": 10236,
    "cityName": "Taquaral de Guanhães",
    "stateId": 5244
  },
  {
    "cityId": 10237,
    "cityName": "São Jorge",
    "stateId": 5242
  },
  {
    "cityId": 10238,
    "cityName": "Campo Lindo",
    "stateId": 5242
  },
  {
    "cityId": 10369,
    "cityName": "Batatal",
    "stateId": 5252
  },
  {
    "cityId": 10389,
    "cityName": "Jordão",
    "stateId": 5251
  },
  {
    "cityId": 10390,
    "cityName": "Biscaia",
    "stateId": 5251
  },
  {
    "cityId": 10391,
    "cityName": "Campo do Bugre",
    "stateId": 5251
  },
  {
    "cityId": 10409,
    "cityName": "Serrinha",
    "stateId": 5252
  },
  {
    "cityId": 10429,
    "cityName": "Santa Cruz",
    "stateId": 5251
  },
  {
    "cityId": 10430,
    "cityName": "Caiva",
    "stateId": 5251
  },
  {
    "cityId": 10431,
    "cityName": "Itambezinho",
    "stateId": 5251
  },
  {
    "cityId": 10469,
    "cityName": "Girassol",
    "stateId": 5242
  },
  {
    "cityId": 10470,
    "cityName": "Edilândia",
    "stateId": 5242
  },
  {
    "cityId": 10471,
    "cityName": "Cacau Pirêra",
    "stateId": 5236
  },
  {
    "cityId": 10491,
    "cityName": "Ipituna",
    "stateId": 5252
  },
  {
    "cityId": 10591,
    "cityName": "Juscelândia",
    "stateId": 5242
  },
  {
    "cityId": 10611,
    "cityName": "Ilha de Bom Jesus dos Passos",
    "stateId": 5238
  },
  {
    "cityId": 10612,
    "cityName": "Ilha dos Frades",
    "stateId": 5238
  },
  {
    "cityId": 10652,
    "cityName": "Passo do Verde",
    "stateId": 5256
  },
  {
    "cityId": 10691,
    "cityName": "Jardim ABC de Goiás",
    "stateId": 5242
  },
  {
    "cityId": 10711,
    "cityName": "Barra do Sahy",
    "stateId": 5241
  },
  {
    "cityId": 10731,
    "cityName": "Bananas",
    "stateId": 5251
  },
  {
    "cityId": 10732,
    "cityName": "Candeia",
    "stateId": 5251
  },
  {
    "cityId": 10733,
    "cityName": "Guaraí",
    "stateId": 5251
  },
  {
    "cityId": 10734,
    "cityName": "Ouro Verde",
    "stateId": 5251
  },
  {
    "cityId": 10735,
    "cityName": "São Roque do Chopim",
    "stateId": 5251
  },
  {
    "cityId": 10751,
    "cityName": "Jorge Lacerda",
    "stateId": 5257
  },
  {
    "cityId": 10752,
    "cityName": "Idamar",
    "stateId": 5257
  },
  {
    "cityId": 10771,
    "cityName": "Penedo",
    "stateId": 5239
  },
  {
    "cityId": 10772,
    "cityName": "Umarizeiras",
    "stateId": 5239
  },
  {
    "cityId": 10773,
    "cityName": "Lages",
    "stateId": 5239
  },
  {
    "cityId": 10774,
    "cityName": "Cachoeira",
    "stateId": 5239
  },
  {
    "cityId": 10775,
    "cityName": "São João do Amanari",
    "stateId": 5239
  },
  {
    "cityId": 10776,
    "cityName": "Papara",
    "stateId": 5239
  },
  {
    "cityId": 10778,
    "cityName": "Manoel Guedes",
    "stateId": 5239
  },
  {
    "cityId": 10819,
    "cityName": "Olhos D'Água",
    "stateId": 5242
  },
  {
    "cityId": 10839,
    "cityName": "Mato Perso",
    "stateId": 5256
  },
  {
    "cityId": 10859,
    "cityName": "Pilões",
    "stateId": 5238
  },
  {
    "cityId": 10860,
    "cityName": "Pedra Alta",
    "stateId": 5238
  },
  {
    "cityId": 10861,
    "cityName": "Santa Cecília",
    "stateId": 5255
  },
  {
    "cityId": 10862,
    "cityName": "Félix Pinto",
    "stateId": 5255
  },
  {
    "cityId": 10863,
    "cityName": "Taboca",
    "stateId": 5255
  },
  {
    "cityId": 10864,
    "cityName": "Novo Paraíso",
    "stateId": 5255
  },
  {
    "cityId": 10979,
    "cityName": "Planalmira",
    "stateId": 5242
  },
  {
    "cityId": 11019,
    "cityName": "Aroeiras do Itaim",
    "stateId": 5250
  },
  {
    "cityId": 11020,
    "cityName": "Ipiranga do Norte",
    "stateId": 5246
  },
  {
    "cityId": 11021,
    "cityName": "Itanhangá",
    "stateId": 5246
  },
  {
    "cityId": 11059,
    "cityName": "Campinas",
    "stateId": 5234
  },
  {
    "cityId": 11060,
    "cityName": "Moraes Almeida",
    "stateId": 5247
  },
  {
    "cityId": 11080,
    "cityName": "Nova Casa Verde",
    "stateId": 5245
  },
  {
    "cityId": 11081,
    "cityName": "Pontal de Coruripe",
    "stateId": 5235
  },
  {
    "cityId": 11082,
    "cityName": "Botafogo",
    "stateId": 5235
  },
  {
    "cityId": 11083,
    "cityName": "Bonsucesso",
    "stateId": 5235
  },
  {
    "cityId": 11084,
    "cityName": "Vila Acari",
    "stateId": 5244
  },
  {
    "cityId": 11085,
    "cityName": "Chapéu D'Uvas",
    "stateId": 5244
  },
  {
    "cityId": 11086,
    "cityName": "Penido",
    "stateId": 5244
  },
  {
    "cityId": 11087,
    "cityName": "Monte Verde",
    "stateId": 5244
  },
  {
    "cityId": 11088,
    "cityName": "Pirapitinga",
    "stateId": 5244
  },
  {
    "cityId": 11089,
    "cityName": "Toledos",
    "stateId": 5244
  },
  {
    "cityId": 11090,
    "cityName": "Humaitá",
    "stateId": 5244
  },
  {
    "cityId": 11091,
    "cityName": "Ipaguaçú",
    "stateId": 5239
  },
  {
    "cityId": 11092,
    "cityName": "Arabá",
    "stateId": 5259
  },
  {
    "cityId": 11093,
    "cityName": "Peroba",
    "stateId": 5235
  },
  {
    "cityId": 11094,
    "cityName": "Passo do Goulart",
    "stateId": 5256
  },
  {
    "cityId": 11095,
    "cityName": "Agrovila São Sebastião do Caburi",
    "stateId": 5236
  },
  {
    "cityId": 11096,
    "cityName": "Novo Remanso",
    "stateId": 5236
  },
  {
    "cityId": 11097,
    "cityName": "Vila Fernando Ferrari",
    "stateId": 5256
  },
  {
    "cityId": 11098,
    "cityName": "Vale dos Vinhedos",
    "stateId": 5256
  },
  {
    "cityId": 11099,
    "cityName": "Borussia",
    "stateId": 5256
  },
  {
    "cityId": 11100,
    "cityName": "Aguapés",
    "stateId": 5256
  },
  {
    "cityId": 11101,
    "cityName": "Santa Luzia",
    "stateId": 5256
  },
  {
    "cityId": 11119,
    "cityName": "São Miguel de Pracuuba",
    "stateId": 5247
  },
  {
    "cityId": 11139,
    "cityName": "Cachoeira da Serra",
    "stateId": 5247
  },
  {
    "cityId": 11140,
    "cityName": "Caracol",
    "stateId": 5247
  },
  {
    "cityId": 11141,
    "cityName": "Alvorada",
    "stateId": 5247
  },
  {
    "cityId": 11142,
    "cityName": "Belo Monte",
    "stateId": 5247
  },
  {
    "cityId": 11159,
    "cityName": "Cuieiras",
    "stateId": 5249
  },
  {
    "cityId": 11179,
    "cityName": "Serrinha Velha",
    "stateId": 5256
  },
  {
    "cityId": 11199,
    "cityName": "São Luiz do Oeste",
    "stateId": 5251
  },
  {
    "cityId": 11219,
    "cityName": "Vila Mandi",
    "stateId": 5247
  },
  {
    "cityId": 11239,
    "cityName": "Conceição",
    "stateId": 5259
  },
  {
    "cityId": 11240,
    "cityName": "Lageado de Araçaíba",
    "stateId": 5259
  },
  {
    "cityId": 11241,
    "cityName": "Luziápolis",
    "stateId": 5235
  },
  {
    "cityId": 11261,
    "cityName": "Lagoa Seca",
    "stateId": 5244
  },
  {
    "cityId": 11262,
    "cityName": "Luiz Alves",
    "stateId": 5242
  },
  {
    "cityId": 11282,
    "cityName": "Frade",
    "stateId": 5252
  },
  {
    "cityId": 11283,
    "cityName": "Lagoa Grande",
    "stateId": 5235
  },
  {
    "cityId": 11303,
    "cityName": "Boqueirão",
    "stateId": 5235
  },
  {
    "cityId": 11323,
    "cityName": "Serra do Navio",
    "stateId": 5237
  },
  {
    "cityId": 11343,
    "cityName": "Charco",
    "stateId": 5251
  },
  {
    "cityId": 11344,
    "cityName": "Roda Velha",
    "stateId": 5238
  },
  {
    "cityId": 11346,
    "cityName": "Chaves",
    "stateId": 5244
  },
  {
    "cityId": 11366,
    "cityName": "Bom Princípio",
    "stateId": 5239
  },
  {
    "cityId": 11386,
    "cityName": "Barreiros",
    "stateId": 5238
  },
  {
    "cityId": 11406,
    "cityName": "Califórnia da Barra",
    "stateId": 5252
  },
  {
    "cityId": 11426,
    "cityName": "Nova Fátima",
    "stateId": 5242
  },
  {
    "cityId": 11446,
    "cityName": "São Carlos",
    "stateId": 5254
  },
  {
    "cityId": 11447,
    "cityName": "Nazaré",
    "stateId": 5254
  },
  {
    "cityId": 11448,
    "cityName": "Demarcação",
    "stateId": 5254
  },
  {
    "cityId": 11449,
    "cityName": "Mutum Paraná",
    "stateId": 5254
  },
  {
    "cityId": 11450,
    "cityName": "Fortaleza do Abunã",
    "stateId": 5254
  },
  {
    "cityId": 11451,
    "cityName": "Nova Califórnia",
    "stateId": 5254
  },
  {
    "cityId": 11452,
    "cityName": "Visconde de Mauá",
    "stateId": 5252
  },
  {
    "cityId": 11472,
    "cityName": "Arantina",
    "stateId": 5242
  },
  {
    "cityId": 11473,
    "cityName": "Comunidade Parque Boa Vista",
    "stateId": 5246
  },
  {
    "cityId": 11474,
    "cityName": "Três Barras da Estrada Real",
    "stateId": 5244
  },
  {
    "cityId": 11475,
    "cityName": "Ilha Encantada",
    "stateId": 5251
  },
  {
    "cityId": 11476,
    "cityName": "Ilha do Amparo",
    "stateId": 5251
  },
  {
    "cityId": 11477,
    "cityName": "Ilha de Eufrasina",
    "stateId": 5251
  },
  {
    "cityId": 11478,
    "cityName": "Ilha do Teixeira",
    "stateId": 5251
  },
  {
    "cityId": 11479,
    "cityName": "Ilha de São Miguel",
    "stateId": 5251
  },
  {
    "cityId": 11480,
    "cityName": "Porto Sauípe",
    "stateId": 5238
  },
  {
    "cityId": 11481,
    "cityName": "Santa Rita",
    "stateId": 5244
  },
  {
    "cityId": 11501,
    "cityName": "Trevo do José Rosário",
    "stateId": 5242
  },
  {
    "cityId": 11521,
    "cityName": "Souzalândia",
    "stateId": 5242
  },
  {
    "cityId": 11522,
    "cityName": "Santa Rosa",
    "stateId": 5239
  },
  {
    "cityId": 11523,
    "cityName": "Baixio das Palmeiras",
    "stateId": 5239
  },
  {
    "cityId": 11524,
    "cityName": "Belmonte",
    "stateId": 5239
  },
  {
    "cityId": 11525,
    "cityName": "Bela Vista",
    "stateId": 5239
  },
  {
    "cityId": 11526,
    "cityName": "Monte Alverne",
    "stateId": 5239
  },
  {
    "cityId": 11527,
    "cityName": "Campo Alegre",
    "stateId": 5239
  },
  {
    "cityId": 11528,
    "cityName": "Laranjeiras",
    "stateId": 5239
  },
  {
    "cityId": 11529,
    "cityName": "Pedras Brancas",
    "stateId": 5239
  },
  {
    "cityId": 11530,
    "cityName": "Passé",
    "stateId": 5238
  },
  {
    "cityId": 11531,
    "cityName": "Caroba",
    "stateId": 5238
  },
  {
    "cityId": 11532,
    "cityName": "Menino Jesus",
    "stateId": 5238
  },
  {
    "cityId": 11533,
    "cityName": "Madeira",
    "stateId": 5238
  },
  {
    "cityId": 11534,
    "cityName": "Passagem dos Teixeiras",
    "stateId": 5238
  },
  {
    "cityId": 11535,
    "cityName": "Cabôto",
    "stateId": 5238
  },
  {
    "cityId": 11536,
    "cityName": "Barbada",
    "stateId": 5239
  },
  {
    "cityId": 11537,
    "cityName": "Maravilha",
    "stateId": 5239
  },
  {
    "cityId": 11538,
    "cityName": "Monte Castelo",
    "stateId": 5239
  },
  {
    "cityId": 11539,
    "cityName": "Santa Rita",
    "stateId": 5239
  },
  {
    "cityId": 11540,
    "cityName": "Catolé da Pista",
    "stateId": 5239
  },
  {
    "cityId": 11541,
    "cityName": "Coité",
    "stateId": 5239
  },
  {
    "cityId": 11557,
    "cityName": "União da Floresta",
    "stateId": 5247
  },
  {
    "cityId": 11558,
    "cityName": "Taquaral",
    "stateId": 5256
  },
  {
    "cityId": 11559,
    "cityName": "Esperança",
    "stateId": 5256
  },
  {
    "cityId": 11560,
    "cityName": "Harmonia",
    "stateId": 5256
  },
  {
    "cityId": 11561,
    "cityName": "Faxinal",
    "stateId": 5256
  },
  {
    "cityId": 11562,
    "cityName": "Prado Novo",
    "stateId": 5256
  },
  {
    "cityId": 11563,
    "cityName": "Lagoa dos Patos",
    "stateId": 5256
  },
  {
    "cityId": 11564,
    "cityName": "Barra Avenida",
    "stateId": 5238
  },
  {
    "cityId": 11584,
    "cityName": "Posselândia",
    "stateId": 5242
  },
  {
    "cityId": 11585,
    "cityName": "Riacho Verde",
    "stateId": 5239
  },
  {
    "cityId": 11586,
    "cityName": "São Bernardo",
    "stateId": 5239
  },
  {
    "cityId": 11587,
    "cityName": "Forquilha",
    "stateId": 5239
  },
  {
    "cityId": 11607,
    "cityName": "Ribeirão da Folha",
    "stateId": 5244
  },
  {
    "cityId": 11608,
    "cityName": "Baixa Quente",
    "stateId": 5244
  },
  {
    "cityId": 11609,
    "cityName": "Cruzinha",
    "stateId": 5244
  },
  {
    "cityId": 11610,
    "cityName": "Lagoa Grande de Minas Novas",
    "stateId": 5244
  },
  {
    "cityId": 11611,
    "cityName": "São Sebastião",
    "stateId": 5244
  },
  {
    "cityId": 11627,
    "cityName": "Martinslândia",
    "stateId": 5239
  },
  {
    "cityId": 11628,
    "cityName": "Mocambo",
    "stateId": 5239
  },
  {
    "cityId": 11629,
    "cityName": "Paraiso",
    "stateId": 5239
  },
  {
    "cityId": 11630,
    "cityName": "Video",
    "stateId": 5239
  },
  {
    "cityId": 11631,
    "cityName": "Assis",
    "stateId": 5239
  },
  {
    "cityId": 11632,
    "cityName": "Curral Velho",
    "stateId": 5239
  },
  {
    "cityId": 11633,
    "cityName": "Lagoa das Pedras",
    "stateId": 5239
  },
  {
    "cityId": 11634,
    "cityName": "Realejo",
    "stateId": 5239
  },
  {
    "cityId": 11635,
    "cityName": "Barro Vermelho",
    "stateId": 5235
  },
  {
    "cityId": 11636,
    "cityName": "Ingá",
    "stateId": 5235
  },
  {
    "cityId": 11637,
    "cityName": "Retiro",
    "stateId": 5235
  },
  {
    "cityId": 11638,
    "cityName": "Riachão",
    "stateId": 5235
  },
  {
    "cityId": 11655,
    "cityName": "Caraíbas",
    "stateId": 5238
  },
  {
    "cityId": 11676,
    "cityName": "Prefeita Suely Pinheiro",
    "stateId": 5239
  },
  {
    "cityId": 11677,
    "cityName": "Pouso Alegre (Formiga)",
    "stateId": 5244
  },
  {
    "cityId": 11678,
    "cityName": "Boa Esperança",
    "stateId": 5244
  },
  {
    "cityId": 11679,
    "cityName": "Engenheiro Dolabela",
    "stateId": 5244
  },
  {
    "cityId": 11697,
    "cityName": "Barreiras",
    "stateId": 5235
  },
  {
    "cityId": 11698,
    "cityName": "Cana Brava",
    "stateId": 5235
  },
  {
    "cityId": 11699,
    "cityName": "Pé-de-Serra dos Mendes",
    "stateId": 5249
  },
  {
    "cityId": 11700,
    "cityName": "Alverne",
    "stateId": 5249
  },
  {
    "cityId": 11701,
    "cityName": "Sítio dos Remédios",
    "stateId": 5249
  },
  {
    "cityId": 11702,
    "cityName": "Serra Negra",
    "stateId": 5249
  },
  {
    "cityId": 11703,
    "cityName": "Cajazeiras",
    "stateId": 5249
  },
  {
    "cityId": 11704,
    "cityName": "Areias",
    "stateId": 5249
  },
  {
    "cityId": 11705,
    "cityName": "Logradouro dos Leões",
    "stateId": 5249
  },
  {
    "cityId": 11706,
    "cityName": "Cachoeira do Pinto",
    "stateId": 5249
  },
  {
    "cityId": 11707,
    "cityName": "Igreja Nova",
    "stateId": 5249
  },
  {
    "cityId": 11708,
    "cityName": "Cavalo Ruço de Deus",
    "stateId": 5249
  },
  {
    "cityId": 11709,
    "cityName": "Caldeirão",
    "stateId": 5249
  },
  {
    "cityId": 11710,
    "cityName": "Lagoa da Pedra",
    "stateId": 5249
  },
  {
    "cityId": 11711,
    "cityName": "Junco",
    "stateId": 5249
  },
  {
    "cityId": 11712,
    "cityName": "Usina Roçadinho",
    "stateId": 5249
  },
  {
    "cityId": 11713,
    "cityName": "Pau Amarelo",
    "stateId": 5249
  },
  {
    "cityId": 11714,
    "cityName": "Atapus",
    "stateId": 5249
  },
  {
    "cityId": 11715,
    "cityName": "Gambá",
    "stateId": 5249
  },
  {
    "cityId": 11716,
    "cityName": "Carne-de-Vaca",
    "stateId": 5249
  },
  {
    "cityId": 11717,
    "cityName": "São Lorenço",
    "stateId": 5249
  },
  {
    "cityId": 11718,
    "cityName": "São Severino",
    "stateId": 5249
  },
  {
    "cityId": 11719,
    "cityName": "Santa Rosa",
    "stateId": 5249
  },
  {
    "cityId": 11720,
    "cityName": "Jirau",
    "stateId": 5249
  },
  {
    "cityId": 11721,
    "cityName": "Neves",
    "stateId": 5249
  },
  {
    "cityId": 11722,
    "cityName": "Campo Alegre",
    "stateId": 5249
  },
  {
    "cityId": 11723,
    "cityName": "Vermelhos",
    "stateId": 5249
  },
  {
    "cityId": 11724,
    "cityName": "Matinada",
    "stateId": 5249
  },
  {
    "cityId": 11725,
    "cityName": "Santa Rita",
    "stateId": 5249
  },
  {
    "cityId": 11726,
    "cityName": "Tenebre",
    "stateId": 5249
  },
  {
    "cityId": 11727,
    "cityName": "Ipanema",
    "stateId": 5249
  },
  {
    "cityId": 11728,
    "cityName": "Rangel",
    "stateId": 5249
  },
  {
    "cityId": 11729,
    "cityName": "Pau Ferro",
    "stateId": 5249
  },
  {
    "cityId": 11730,
    "cityName": "Serrinha da Prata",
    "stateId": 5249
  },
  {
    "cityId": 11731,
    "cityName": "Caramuru",
    "stateId": 5249
  },
  {
    "cityId": 11732,
    "cityName": "Monte Alegre",
    "stateId": 5249
  },
  {
    "cityId": 11733,
    "cityName": "Santa Rosa",
    "stateId": 5249
  },
  {
    "cityId": 11734,
    "cityName": "São Francisco do Brígida",
    "stateId": 5249
  },
  {
    "cityId": 11735,
    "cityName": "Pernambuquinho",
    "stateId": 5249
  },
  {
    "cityId": 11736,
    "cityName": "Cruzeiro do Nordeste",
    "stateId": 5249
  },
  {
    "cityId": 11737,
    "cityName": "Moderna",
    "stateId": 5249
  },
  {
    "cityId": 11738,
    "cityName": "Umburanas",
    "stateId": 5249
  },
  {
    "cityId": 11739,
    "cityName": "Caroalina",
    "stateId": 5249
  },
  {
    "cityId": 11740,
    "cityName": "Valdemar Siqueira",
    "stateId": 5249
  },
  {
    "cityId": 11741,
    "cityName": "Santo Amaro",
    "stateId": 5249
  },
  {
    "cityId": 11742,
    "cityName": "Lagoa da Vaca",
    "stateId": 5249
  },
  {
    "cityId": 11743,
    "cityName": "Juca Ferrado",
    "stateId": 5249
  },
  {
    "cityId": 11744,
    "cityName": "Lério de Cima",
    "stateId": 5249
  },
  {
    "cityId": 11745,
    "cityName": "Mimoso",
    "stateId": 5249
  },
  {
    "cityId": 11746,
    "cityName": "Grossos",
    "stateId": 5249
  },
  {
    "cityId": 11747,
    "cityName": "São João do Ferraz",
    "stateId": 5249
  },
  {
    "cityId": 11748,
    "cityName": "Serra da Cachoeira",
    "stateId": 5249
  },
  {
    "cityId": 11749,
    "cityName": "Livramento",
    "stateId": 5249
  },
  {
    "cityId": 11754,
    "cityName": "Vila Bem-Querer",
    "stateId": 5244
  },
  {
    "cityId": 11755,
    "cityName": "Itapeaçu",
    "stateId": 5236
  },
  {
    "cityId": 11756,
    "cityName": "Vila Lindóia",
    "stateId": 5236
  },
  {
    "cityId": 11757,
    "cityName": "Santana",
    "stateId": 5236
  },
  {
    "cityId": 11758,
    "cityName": "Vila Zé Açu",
    "stateId": 5236
  },
  {
    "cityId": 11759,
    "cityName": "Vila Amazônia",
    "stateId": 5236
  },
  {
    "cityId": 11760,
    "cityName": "Novo Céu",
    "stateId": 5236
  },
  {
    "cityId": 11761,
    "cityName": "Purupuru",
    "stateId": 5236
  },
  {
    "cityId": 11762,
    "cityName": "Terra Nova",
    "stateId": 5236
  },
  {
    "cityId": 11763,
    "cityName": "Careiro Parauá",
    "stateId": 5236
  },
  {
    "cityId": 11764,
    "cityName": "Santo Antônio do Matupi",
    "stateId": 5236
  },
  {
    "cityId": 11765,
    "cityName": "Sacambu",
    "stateId": 5236
  },
  {
    "cityId": 11766,
    "cityName": "Caviana",
    "stateId": 5236
  },
  {
    "cityId": 11767,
    "cityName": "Lago do Jacaré",
    "stateId": 5236
  },
  {
    "cityId": 11768,
    "cityName": "Tuiué",
    "stateId": 5236
  },
  {
    "cityId": 11769,
    "cityName": "Campinas",
    "stateId": 5236
  },
  {
    "cityId": 11770,
    "cityName": "Araras",
    "stateId": 5236
  },
  {
    "cityId": 11771,
    "cityName": "Murituba",
    "stateId": 5236
  },
  {
    "cityId": 11772,
    "cityName": "Vila Bittencourt",
    "stateId": 5236
  },
  {
    "cityId": 11773,
    "cityName": "Limoeiro",
    "stateId": 5236
  },
  {
    "cityId": 11774,
    "cityName": "Feijoal",
    "stateId": 5236
  },
  {
    "cityId": 11775,
    "cityName": "Belém de Solimões",
    "stateId": 5236
  },
  {
    "cityId": 11776,
    "cityName": "Palmeiras",
    "stateId": 5236
  },
  {
    "cityId": 11777,
    "cityName": "Estirão do Equador",
    "stateId": 5236
  },
  {
    "cityId": 11778,
    "cityName": "Platô do Piquiá",
    "stateId": 5236
  },
  {
    "cityId": 11779,
    "cityName": "Baixa Grande",
    "stateId": 5235
  },
  {
    "cityId": 11780,
    "cityName": "Farrinheira",
    "stateId": 5235
  },
  {
    "cityId": 11781,
    "cityName": "Flexeiras",
    "stateId": 5235
  },
  {
    "cityId": 11782,
    "cityName": "Mangabeiras",
    "stateId": 5235
  },
  {
    "cityId": 11783,
    "cityName": "Mata Limpa",
    "stateId": 5235
  },
  {
    "cityId": 11784,
    "cityName": "Pilões",
    "stateId": 5235
  },
  {
    "cityId": 11785,
    "cityName": "Poço",
    "stateId": 5235
  },
  {
    "cityId": 11786,
    "cityName": "Poço da Pedra de Cima",
    "stateId": 5235
  },
  {
    "cityId": 11787,
    "cityName": "Poço de Baixo",
    "stateId": 5235
  },
  {
    "cityId": 11788,
    "cityName": "Poço de Santana",
    "stateId": 5235
  },
  {
    "cityId": 11794,
    "cityName": "Quati",
    "stateId": 5235
  },
  {
    "cityId": 11795,
    "cityName": "Retiro",
    "stateId": 5235
  },
  {
    "cityId": 11796,
    "cityName": "Rio dos Bichos",
    "stateId": 5235
  },
  {
    "cityId": 11797,
    "cityName": "São Lourenço",
    "stateId": 5235
  },
  {
    "cityId": 11798,
    "cityName": "Serra da Massa",
    "stateId": 5235
  },
  {
    "cityId": 11799,
    "cityName": "Tabocas",
    "stateId": 5235
  },
  {
    "cityId": 11800,
    "cityName": "Taquara",
    "stateId": 5235
  },
  {
    "cityId": 11801,
    "cityName": "Varginha",
    "stateId": 5235
  },
  {
    "cityId": 11802,
    "cityName": "Chã de Cruz",
    "stateId": 5249
  },
  {
    "cityId": 11803,
    "cityName": "Caraíbas",
    "stateId": 5249
  },
  {
    "cityId": 11804,
    "cityName": "Riacho do Meio",
    "stateId": 5249
  },
  {
    "cityId": 11805,
    "cityName": "Santa Luzia",
    "stateId": 5249
  },
  {
    "cityId": 11806,
    "cityName": "Nossa Senhora Aparecida",
    "stateId": 5249
  },
  {
    "cityId": 11807,
    "cityName": "Raiz",
    "stateId": 5249
  },
  {
    "cityId": 11808,
    "cityName": "Taboquinha",
    "stateId": 5249
  },
  {
    "cityId": 11809,
    "cityName": "Vila Nova",
    "stateId": 5249
  },
  {
    "cityId": 11810,
    "cityName": "Caramuru",
    "stateId": 5249
  },
  {
    "cityId": 11811,
    "cityName": "Caraúba Torta",
    "stateId": 5249
  },
  {
    "cityId": 11812,
    "cityName": "Cachoeira Seca",
    "stateId": 5249
  },
  {
    "cityId": 11813,
    "cityName": "Itaúna",
    "stateId": 5249
  },
  {
    "cityId": 11814,
    "cityName": "Jacaré Grande",
    "stateId": 5249
  },
  {
    "cityId": 11815,
    "cityName": "Juá",
    "stateId": 5249
  },
  {
    "cityId": 11816,
    "cityName": "Laje",
    "stateId": 5249
  },
  {
    "cityId": 11817,
    "cityName": "Malhada Barreiras Queimadas",
    "stateId": 5249
  },
  {
    "cityId": 11818,
    "cityName": "Rafael",
    "stateId": 5249
  },
  {
    "cityId": 11819,
    "cityName": "Patos",
    "stateId": 5249
  },
  {
    "cityId": 11820,
    "cityName": "Palmatória",
    "stateId": 5249
  },
  {
    "cityId": 11821,
    "cityName": "Jacarezinho",
    "stateId": 5249
  },
  {
    "cityId": 11822,
    "cityName": "Malhada de Pedra",
    "stateId": 5249
  },
  {
    "cityId": 11823,
    "cityName": "Pau-Santo",
    "stateId": 5249
  },
  {
    "cityId": 11824,
    "cityName": "Terra Vermelha",
    "stateId": 5249
  },
  {
    "cityId": 11825,
    "cityName": "Pelada",
    "stateId": 5249
  },
  {
    "cityId": 11826,
    "cityName": "Xicuru",
    "stateId": 5249
  },
  {
    "cityId": 11827,
    "cityName": "Rafael",
    "stateId": 5249
  },
  {
    "cityId": 11828,
    "cityName": "Murici",
    "stateId": 5249
  },
  {
    "cityId": 11829,
    "cityName": "Serrote dos Bois de Cima",
    "stateId": 5249
  },
  {
    "cityId": 11830,
    "cityName": "Xique-Xique",
    "stateId": 5249
  },
  {
    "cityId": 11831,
    "cityName": "Nova Descoberta",
    "stateId": 5249
  },
  {
    "cityId": 11832,
    "cityName": "Ns2 Núcleo de Serviços",
    "stateId": 5249
  },
  {
    "cityId": 11833,
    "cityName": "Pedrinhas",
    "stateId": 5249
  },
  {
    "cityId": 11841,
    "cityName": "Massangano",
    "stateId": 5249
  },
  {
    "cityId": 11843,
    "cityName": "Serrote do Urubu",
    "stateId": 5249
  },
  {
    "cityId": 11844,
    "cityName": "Vila Nossa Senhora Aparecida",
    "stateId": 5249
  },
  {
    "cityId": 11845,
    "cityName": "Uruás",
    "stateId": 5249
  },
  {
    "cityId": 11861,
    "cityName": "Km 25",
    "stateId": 5249
  },
  {
    "cityId": 11862,
    "cityName": "Vila Nova - N5",
    "stateId": 5249
  },
  {
    "cityId": 11874,
    "cityName": "Santo Antônio da Esperança",
    "stateId": 5242
  },
  {
    "cityId": 11875,
    "cityName": "Bom Jesus do Prata",
    "stateId": 5244
  },
  {
    "cityId": 11876,
    "cityName": "Rincão de São Miguel",
    "stateId": 5256
  },
  {
    "cityId": 11877,
    "cityName": "Itapororó",
    "stateId": 5256
  },
  {
    "cityId": 11878,
    "cityName": "Guassu Boi",
    "stateId": 5256
  },
  {
    "cityId": 11879,
    "cityName": "Inhanduí",
    "stateId": 5256
  },
  {
    "cityId": 11880,
    "cityName": "Vasco Alves",
    "stateId": 5256
  },
  {
    "cityId": 11881,
    "cityName": "Catimbau",
    "stateId": 5256
  },
  {
    "cityId": 11882,
    "cityName": "Durasnal",
    "stateId": 5256
  },
  {
    "cityId": 11896,
    "cityName": "Nazária",
    "stateId": 5250
  },
  {
    "cityId": 11897,
    "cityName": "Triunfo",
    "stateId": 5254
  },
  {
    "cityId": 11898,
    "cityName": "Novo Goiás",
    "stateId": 5242
  },
  {
    "cityId": 11899,
    "cityName": "São Pedro do Gavião",
    "stateId": 5239
  },
  {
    "cityId": 11900,
    "cityName": "Cafemirim",
    "stateId": 5244
  },
  {
    "cityId": 11920,
    "cityName": "União Bandeirante",
    "stateId": 5254
  },
  {
    "cityId": 11940,
    "cityName": "Conceição",
    "stateId": 5239
  },
  {
    "cityId": 11941,
    "cityName": "Juritianha",
    "stateId": 5239
  },
  {
    "cityId": 11942,
    "cityName": "Lagoa do Carneiro",
    "stateId": 5239
  },
  {
    "cityId": 11943,
    "cityName": "Santa Fé",
    "stateId": 5239
  },
  {
    "cityId": 11960,
    "cityName": "Passo Amarelo",
    "stateId": 5251
  },
  {
    "cityId": 11980,
    "cityName": "Bom Futuro",
    "stateId": 5254
  },
  {
    "cityId": 11981,
    "cityName": "Tarilândia",
    "stateId": 5254
  },
  {
    "cityId": 11982,
    "cityName": "Bom Jesus",
    "stateId": 5254
  },
  {
    "cityId": 11983,
    "cityName": "Santa Cruz",
    "stateId": 5254
  },
  {
    "cityId": 11984,
    "cityName": "Cedro",
    "stateId": 5251
  },
  {
    "cityId": 11985,
    "cityName": "Palmares",
    "stateId": 5238
  },
  {
    "cityId": 11986,
    "cityName": "Dantelândia",
    "stateId": 5238
  },
  {
    "cityId": 11987,
    "cityName": "Veredinha",
    "stateId": 5238
  },
  {
    "cityId": 11988,
    "cityName": "Matinha",
    "stateId": 5238
  },
  {
    "cityId": 11989,
    "cityName": "Cordeiro de Minas",
    "stateId": 5244
  },
  {
    "cityId": 12009,
    "cityName": "São Sebastião",
    "stateId": 5238
  },
  {
    "cityId": 12010,
    "cityName": "Cabeceira do Jibóia",
    "stateId": 5238
  },
  {
    "cityId": 12011,
    "cityName": "Pradoso",
    "stateId": 5238
  },
  {
    "cityId": 12012,
    "cityName": "Cercadinho",
    "stateId": 5238
  },
  {
    "cityId": 12031,
    "cityName": "Martinópolis",
    "stateId": 5251
  },
  {
    "cityId": 12032,
    "cityName": "Piratininga",
    "stateId": 5246
  },
  {
    "cityId": 12033,
    "cityName": "Santo Antonio do Rio Bonito",
    "stateId": 5246
  },
  {
    "cityId": 12034,
    "cityName": "Novo Mato Grosso",
    "stateId": 5246
  },
  {
    "cityId": 12035,
    "cityName": "Santa Terezinha do Rio Ferro",
    "stateId": 5246
  },
  {
    "cityId": 12036,
    "cityName": "Parque Água Limpa",
    "stateId": 5246
  },
  {
    "cityId": 12037,
    "cityName": "Rio XV de Baixo",
    "stateId": 5251
  },
  {
    "cityId": 12038,
    "cityName": "Palmital de Minas",
    "stateId": 5244
  },
  {
    "cityId": 12039,
    "cityName": "Ruralminas",
    "stateId": 5244
  },
  {
    "cityId": 12040,
    "cityName": "Pedras de Marilândia",
    "stateId": 5244
  },
  {
    "cityId": 12059,
    "cityName": "Pinheiro Grosso",
    "stateId": 5244
  },
  {
    "cityId": 12060,
    "cityName": "Ponte do Cosme",
    "stateId": 5244
  },
  {
    "cityId": 12061,
    "cityName": "Faria",
    "stateId": 5244
  },
  {
    "cityId": 12062,
    "cityName": "Várzea da Onça",
    "stateId": 5239
  },
  {
    "cityId": 12063,
    "cityName": "Mosquito",
    "stateId": 5239
  },
  {
    "cityId": 12064,
    "cityName": "Palmeiras",
    "stateId": 5254
  },
  {
    "cityId": 12065,
    "cityName": "Pedro Neca",
    "stateId": 5246
  },
  {
    "cityId": 12066,
    "cityName": "Bocaiuval",
    "stateId": 5246
  },
  {
    "cityId": 12067,
    "cityName": "Vila Cardoso",
    "stateId": 5246
  },
  {
    "cityId": 12068,
    "cityName": "Água da Prata",
    "stateId": 5246
  },
  {
    "cityId": 12069,
    "cityName": "Nova Primavera",
    "stateId": 5246
  },
  {
    "cityId": 12070,
    "cityName": "Nova Cáceres",
    "stateId": 5246
  },
  {
    "cityId": 12071,
    "cityName": "São José do Pingador",
    "stateId": 5246
  },
  {
    "cityId": 12072,
    "cityName": "Guariba",
    "stateId": 5246
  },
  {
    "cityId": 12073,
    "cityName": "Marechal Rondon",
    "stateId": 5246
  },
  {
    "cityId": 12074,
    "cityName": "Boa Vista de Pacarana",
    "stateId": 5254
  },
  {
    "cityId": 12075,
    "cityName": "Nova Esperança",
    "stateId": 5254
  },
  {
    "cityId": 12076,
    "cityName": "Glória de Cataguases",
    "stateId": 5244
  },
  {
    "cityId": 12077,
    "cityName": "Anil",
    "stateId": 5239
  },
  {
    "cityId": 12078,
    "cityName": "Faveira",
    "stateId": 5239
  },
  {
    "cityId": 12098,
    "cityName": "Ouro Preto",
    "stateId": 5251
  },
  {
    "cityId": 12099,
    "cityName": "Piquiri",
    "stateId": 5256
  },
  {
    "cityId": 12100,
    "cityName": "Guaporé",
    "stateId": 5254
  },
  {
    "cityId": 12101,
    "cityName": "Santa Bárbara",
    "stateId": 5251
  },
  {
    "cityId": 12102,
    "cityName": "Bom Recreio",
    "stateId": 5256
  },
  {
    "cityId": 12103,
    "cityName": "Sede Independência",
    "stateId": 5256
  },
  {
    "cityId": 12104,
    "cityName": "Veranópolis",
    "stateId": 5246
  },
  {
    "cityId": 12105,
    "cityName": "Antarí",
    "stateId": 5238
  },
  {
    "cityId": 12106,
    "cityName": "Pachecos",
    "stateId": 5252
  },
  {
    "cityId": 12107,
    "cityName": "Manilha",
    "stateId": 5252
  },
  {
    "cityId": 12108,
    "cityName": "Visconde de Itaboraí",
    "stateId": 5252
  },
  {
    "cityId": 12109,
    "cityName": "Bebelândia",
    "stateId": 5248
  },
  // {
  //   "cityId": 50001,
  //   "cityName": "- Não definida -",
  //   "stateId": 5234
  // },
  // {
  //   "cityId": 50002,
  //   "cityName": "- Não definida -",
  //   "stateId": 5235
  // },
  // {
  //   "cityId": 50003,
  //   "cityName": "- Não definida -",
  //   "stateId": 5236
  // },
  // {
  //   "cityId": 50004,
  //   "cityName": "- Não definida -",
  //   "stateId": 5237
  // },
  // {
  //   "cityId": 50005,
  //   "cityName": "- Não definida -",
  //   "stateId": 5238
  // },
  // {
  //   "cityId": 50006,
  //   "cityName": "- Não definida -",
  //   "stateId": 5239
  // },
  // {
  //   "cityId": 50007,
  //   "cityName": "- Não definida -",
  //   "stateId": 5240
  // },
  // {
  //   "cityId": 50008,
  //   "cityName": "- Não definida -",
  //   "stateId": 5241
  // },
  // {
  //   "cityId": 50009,
  //   "cityName": "- Não definida -",
  //   "stateId": 5242
  // },
  // {
  //   "cityId": 50010,
  //   "cityName": "- Não definida -",
  //   "stateId": 5243
  // },
  // {
  //   "cityId": 50011,
  //   "cityName": "- Não definida -",
  //   "stateId": 5244
  // },
  // {
  //   "cityId": 50012,
  //   "cityName": "- Não definida -",
  //   "stateId": 5245
  // },
  // {
  //   "cityId": 50013,
  //   "cityName": "- Não definida -",
  //   "stateId": 5246
  // },
  // {
  //   "cityId": 50014,
  //   "cityName": "- Não definida -",
  //   "stateId": 5247
  // },
  // {
  //   "cityId": 50015,
  //   "cityName": "- Não definida -",
  //   "stateId": 5248
  // },
  // {
  //   "cityId": 50016,
  //   "cityName": "- Não definida -",
  //   "stateId": 5249
  // },
  // {
  //   "cityId": 50017,
  //   "cityName": "- Não definida -",
  //   "stateId": 5250
  // },
  // {
  //   "cityId": 50018,
  //   "cityName": "- Não definida -",
  //   "stateId": 5251
  // },
  // {
  //   "cityId": 50019,
  //   "cityName": "- Não definida -",
  //   "stateId": 5252
  // },
  // {
  //   "cityId": 50020,
  //   "cityName": "- Não definida -",
  //   "stateId": 5253
  // },
  // {
  //   "cityId": 50021,
  //   "cityName": "- Não definida -",
  //   "stateId": 5254
  // },
  // {
  //   "cityId": 50022,
  //   "cityName": "- Não definida -",
  //   "stateId": 5255
  // },
  // {
  //   "cityId": 50023,
  //   "cityName": "- Não definida -",
  //   "stateId": 5256
  // },
  // {
  //   "cityId": 50024,
  //   "cityName": "- Não definida -",
  //   "stateId": 5257
  // },
  // {
  //   "cityId": 50025,
  //   "cityName": "- Não definida -",
  //   "stateId": 5258
  // },
  // {
  //   "cityId": 50026,
  //   "cityName": "- Não definida -",
  //   "stateId": 5259
  // },
  // {
  //   "cityId": 50027,
  //   "cityName": "- Não definida -",
  //   "stateId": 5260
  // },
  // {
  //   "cityId": 50028,
  //   "cityName": "- Não definida -",
  //   "stateId": 5261
  // }
])