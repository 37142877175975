import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import orderBy from 'lodash/orderBy';
import uuidv1 from 'uuid/v1';

import formOrigin from '@Constants/formOrigin';
import selectorLogin from '@Components/login/selector';
import * as actionConfig from '@Actions/config';
import * as router from '@Constants/router';
import typeForms from '@Constants/forms';
// import * as actionFormsAnswer from '@Components/forms/Answer/action';
import * as util from '@Util';
import * as actionModal from '@Common/modal/action';
import { buildFileStructure } from '@Util';
import systemConfigs from '@Constants/systemConfigs';
import * as formEngineActions from '@Components/common/formEngine/Create/action';
import selectorSection from '../../Sections/config/selector';

import types from './constant';
import selector from './selector';

const getListFormModelsFetchRequest = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_REQUEST,
});
const getListFormModelsFetchSuccess = (data) => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_SUCCESS,
  payload: data,
});
const getListFormModelsGlobalFetchSuccess = (data) => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_GLOBAL_FETCH_SUCCESS,
  payload: data,
});
const getListFormModelsFetchError = (data) => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_FORM_MODELS_FETCH_ERROR,
  payload: data,
});

const createFormEmptyFetchRequest = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_REQUEST,
});
const createFormEmptyFetchSuccess = (data) => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_SUCCESS,
  payload: data,
});
const createFormEmptyFetchError = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_CREATE_FORM_EMPTY_FETCH_ERROR,
});

const createDraftFetchRequest = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_REQUEST,
});
const createDraftFetchSuccess = (data) => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_SUCCESS,
  payload: data,
});
const createDraftFetchError = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_POST_DRAFT_FETCH_ERROR,
});

const getListDraftsFetchRequest = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_REQUEST,
});
const getListDraftsFetchSuccess = (data) => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_SUCCESS,
  payload: data,
});
const getListDraftsFetchError = () => ({
  type: types.PERFIL_CONFIGURATION_FIELDS_GET_LIST_DRAFTS_FETCH_ERROR,
});

const editFormModelVersionFetchRequest = () => ({
  type: types.PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_REQUEST,
});
const editFormModelVersionFetchSuccess = (data) => ({
  type: types.PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_SUCCESS,
  payload: data,
});
const editFormModelVersionFetchError = () => ({
  type: types.PERFIL_CONFIGURATION_EDIT_FORM_MODEL_VERSION_FETCH_ERROR,
});

export const setSidebarButtonSelect = data => ({
  type: types.SET_SIDEBAR_BUTTON_SELECT_FORM_PERFIL,
  payload: data
});
export const setFormsFilterForm = data => ({
  type: types.SET_FORMS_FILTER_FORM_PERFIL,
  payload: data,
});
export const setCategorySelected = data => ({
  type: types.SET_CATEGORY_SELECTED_FORM_PERFIL,
  payload: data,
});

export const formGetCategoriesModelsFetchRequestStatus = data => ({
  type: types.PERFIL_FORM_GET_CATEGORIES_MODELS_FETCH_REQUEST_STATUS,
  payload: data,
});

const formsGetCategoriesFormFetchSuccess = data => ({
  type: types.PERFIL_FORM_GET_CATEGORIES_FORM_FETCH_SUCCESS,
  payload: data,
});

const formGetCategoriesAllFetchSuccess = data => ({
  type: types.PERFIL_FORM_GET_CATEGORIES_ALL_FETCH_SUCCESS,
  payload: data,
});

const setAuthorsForms = data => ({
  type: types.SET_AUTHORS_FORMS_PERFIL,
  payload: data,
});

export const setFavorites = data => ({
  type: types.SET_FAVORITES_FORM_MODELS_PERFIL,
  payload: data
});

export const saveSectionAccountConfigFetchRequest = data => ({
  type: types.SAVE_SECTION_FORM_PERFIL_CONFIG_FETCH_REQUEST,
  payload: data,
});

export const setTabSelectedButtonSelectFromPerfil = data => ({
  type: types.SET_TAB_SELECTED_BUTTON_SELECT_FORM_PERFIL,
  payload: data,
});



export const showFormsAnswered = ({ formModelId }) => (
  dispatch,
  getState,
  api
) => {
  // const idModelForm = util.encrypt(formModelId.toString());
  // dispatch( actionConfig.routeRedirect( router.ROUTE_FORMS_MODEL_ID_LIST_ANSWER(idModelForm) ) );
};

export const setDraftById = (draftId) => (dispatch, getState, api) => {
  // dispatch(actionConfig.routeRedirect(`${router.FORMS}/${router.CREATE}/${draftId}`));
};

// ===== APIs =====
export const getListFormModels = () => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    dispatch(getListFormModelsFetchRequest());

    const response = await apiForms.form.getFormModels({ header });
    const formsModel = response.data;

    let listFormModels = [];
    let count = 1;

    for (let form of formsModel) {
      let created = form.createdAt
        ? moment(form.createdAt).format('DD/MM/YYYY')
        : null;

      listFormModels.push({
        ...form,
        key: count,
        created,
      });

      ++count;
    }

    listFormModels = orderBy(listFormModels, ['created'], ['desc']);

    dispatch(getListFormModelsFetchSuccess({ listFormModels }));
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(getListFormModelsFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const getListFormModelsGlobals = () => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  const groupPermission = selectorLogin.getAuthGroupPermission(state);
  const groupPermissionIds = groupPermission.map(item => item._id);

  header = { ...header, groupPermission: groupPermissionIds, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    dispatch(getListFormModelsFetchRequest());

    const response = await apiForms.form.getFormGlobals({ header });
    const formsModel = response.data;

    let listFormModelsGlobals = [];
    let count = 1;

    for (let form of formsModel) {
      let created = form.createdAt
        ? moment(form.createdAt).format('DD/MM/YYYY')
        : null;

      const authors = form.idAuthors ? form.idAuthors.split(',') : [];

      const categories = form.idCategories ? form.idCategories.split(',') : [];


      listFormModelsGlobals.push({
        ...form,
        authors,
        categories,
        key: count,
        created,
      });

      ++count;
    }

    listFormModelsGlobals = orderBy(listFormModelsGlobals, ['created'], ['desc']);

    dispatch(getListFormModelsGlobalFetchSuccess({ listFormModelsGlobals }));
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(getListFormModelsFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
  }
};

export const getListDrafts = () => async (dispatch, getState, { apiForms }) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    dispatch(getListDraftsFetchRequest());

    const response = await apiForms.form.getDrafts({ header });
    const _listDrafts = response.data;

    let listDrafts = [];
    let count = 1;

    for (let form of _listDrafts) {
      let created = form.createdAt
        ? moment(form.createdAt).format('DD/MM/YYYY')
        : null;

      listDrafts.push({
        ...form,
        key: count,
        created,
      });

      ++count;
    }

    listDrafts = orderBy(listDrafts, ['created'], ['desc']);

    dispatch(getListDraftsFetchSuccess({ listDrafts }));
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(getListDraftsFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const createFormEmpty = ({ formModelVersionId }) => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    if (!formModelVersionId) {
      toastr.warning('Atenção', 'Nâo foi possivel concluir a sua solicitação');
      return;
    }

    dispatch(createFormEmptyFetchRequest());

    let response = await apiForms.form.createFormEmpty({
      header,
      formModelVersionId,
    });
    let form = response.data.form;
    const urlParam = util.encrypt(form.formId.toString());

    dispatch([
      createFormEmptyFetchSuccess(),
      // actionFormsAnswer.buildFormModel({...response.data}),
      actionConfig.routeRedirect(
        `${router.FORMSENGINE}/${router.ANSWER}/${urlParam}`
      ),
    ]);
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(createFormEmptyFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const createDraft = () => async (dispatch, getState, { apiForms }) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    dispatch(createDraftFetchRequest());

    let resDraft = await apiForms.form.postDraft({ header });
    resDraft = resDraft.data[0];
    let draftId = resDraft._id;

    dispatch([
      createDraftFetchSuccess(),
      actionConfig.routeRedirect(
        `/${router.ROUTE_BASIC}/${router.FORMSENGINE}/${router.CREATE}/${draftId}/${formOrigin['FORM_PRONTUARIO']}?redirect=${typeForms.CONTEXT_REDIRECT_PERFIL_CONFIG_MEDICALRECORD_FORMS}`
      ),
    ]);
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(createDraftFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const editFormModelVersion = ({ formModelVersionId }) => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    dispatch(editFormModelVersionFetchRequest());

    //#region [GET][FORM MODEL BY ID]
    const resResponseForm = await apiForms.form.getFormModelVersionById({
      header,
      formModelVersionId,
    });
    const { form, formStyle, names } = resResponseForm.data;
    //#endregion

    //#region [POST][DRAFT]
    let resDraft = await apiForms.form.postDraft({ header });
    resDraft = resDraft.data[0];
    const draftId = resDraft._id;
    //#endregion

    //#region [BUILD][FORM][DRAFT]
    const newsSections = [];
    for (let _section of form.sections) {
      const newsModules = [];
      const modulesIds = [];
      const _sectionModel =
        names.sectionModelsNames.filter(
          (item) => item.sectionModelId === _section.sectionModelId
        )[0] || {};

      const { order: sectionOrder, sectionModelVersionId } = _section;

      for (let _module of _section.modules) {
        const newsFields = [];
        const fieldsIds = [];
        const _moduleModel =
          names.moduleModelsNames.filter(
            (item) => item.moduleModelId === _module.moduleModelId
          )[0] || {};
        const idModule = uuidv1();

        const { order: moduleOrder, moduleModelVersionId } = _module;

        for (let _field of _module.fields) {
          const _fieldModel =
            names.fieldModelsNames.filter(
              (item) => item.fieldModelId === _field.fieldModelId
            )[0] || {};
          const idField = uuidv1();

          const { order: fieldOrder, fieldModelVersionId } = _field;

          const fieldStyle = formStyle.style.filter(
            (item) =>
              item.sectionOrder === sectionOrder &&
              item.sectionModelVersionId === sectionModelVersionId &&
              item.moduleOrder === moduleOrder &&
              item.moduleModelVersionId === moduleModelVersionId &&
              item.fieldOrder === fieldOrder &&
              item.fieldModelVersionId === fieldModelVersionId
          )[0];

          const {
            size,
            enableNewLine,
            enableNewPage,
            enableInline,
            enableSelectListDesign,
            enableObservation,
            enablePeriodInterval,
          } = fieldStyle;

          newsFields.push({
            ..._field,
            id: idField,
            name: _fieldModel.name,
            title: _fieldModel.name,
            fieldName: _fieldModel.name,
            size,
            enableNewLine,
            enableNewPage,
            enableInline,
            enableSelectListDesign,
            enableObservation,
            enablePeriodInterval,
          });

          fieldsIds.push(idField);
        }

        newsModules.push({
          ..._module,
          fieldsIds,
          fields: newsFields,
          id: idModule,
          name: _moduleModel.name,
          title: _moduleModel.name,
        });

        modulesIds.push(idModule);
      }

      newsSections.push({
        ..._section,
        modulesIds,
        id: uuidv1(),
        modules: newsModules,
        name: _sectionModel.name,
        title: _sectionModel.name,
      });
    }
    //#endregion

    //#region [PUT][DRAFT]
    const paramsPut = {
      isAccountForm: false,
      draft: {
        _id: draftId,
        name: form.formName,
        typeForm: form.typeForm,
        description: form.description,
        attachPatientModel: form.attachPatientModel,
        workspaceId: form.workspaceId,
        sections: newsSections,
      },
    };

    await apiForms.form.putDraft({ header, params: paramsPut });
    //#endregion

    dispatch([
      setTabSelectedButtonSelectFromPerfil('1'),
      editFormModelVersionFetchSuccess(),
      actionConfig.routeRedirect(
        `/${router.ROUTE_BASIC}/${router.FORMSENGINE}/${router.CREATE}/${draftId}/${formOrigin['FORM_PRONTUARIO']}?redirect=${typeForms.CONTEXT_REDIRECT_PERFIL_CONFIG_MEDICALRECORD_FORMS}`
      ),
    ]);
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(editFormModelVersionFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const previewFormModelVersion =
  ({ formModelVersionId }) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      let header = selectorLogin.getHeaderConfig(state);
      header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

      try {
        dispatch(editFormModelVersionFetchRequest());

        const resResponseForm = await apiForms.form.getFormModelVersionById({
          header,
          formModelVersionId,
        });
        const { form, formStyle, names } = resResponseForm.data;
        const newsSections = [];
        for (let _section of form.sections) {
          const newsModules = [];
          const modulesIds = [];
          const _sectionModel =
            names.sectionModelsNames.filter(
              (item) => item.sectionModelId === _section.sectionModelId
            )[0] || {};

          const { order: sectionOrder, sectionModelVersionId } = _section;

          for (let _module of _section.modules) {
            const newsFields = [];
            const fieldsIds = [];
            const _moduleModel =
              names.moduleModelsNames.filter(
                (item) => item.moduleModelId === _module.moduleModelId
              )[0] || {};
            const idModule = uuidv1();

            const { order: moduleOrder, moduleModelVersionId } = _module;

            for (let _field of _module.fields) {
              const _fieldModel =
                names.fieldModelsNames.filter(
                  (item) => item.fieldModelId === _field.fieldModelId
                )[0] || {};
              const idField = uuidv1();

              const { order: fieldOrder, fieldModelVersionId } = _field;

              const fieldStyle = formStyle.style.filter(
                (item) =>
                  item.sectionOrder === sectionOrder &&
                  item.sectionModelVersionId === sectionModelVersionId &&
                  item.moduleOrder === moduleOrder &&
                  item.moduleModelVersionId === moduleModelVersionId &&
                  item.fieldOrder === fieldOrder &&
                  item.fieldModelVersionId === fieldModelVersionId
              )[0];

              const {
                size,
                enableNewLine,
                enableNewPage,
                enableInline,
                enableSelectListDesign,
                enableObservation,
                enablePeriodInterval,
              } = fieldStyle;

              newsFields.push({
                ..._field,
                id: idField,
                name: _fieldModel.name,
                title: _fieldModel.name,
                fieldName: _fieldModel.name,
                size,
                enableNewLine,
                enableNewPage,
                enableInline,
                enableSelectListDesign,
                enableObservation,
                enablePeriodInterval,
              });

              fieldsIds.push(idField);
            }

            newsModules.push({
              ..._module,
              fieldsIds,
              fields: newsFields,
              id: idModule,
              name: _moduleModel.name,
              title: _moduleModel.name,
            });

            modulesIds.push(idModule);
          }

          newsSections.push({
            ..._section,
            modulesIds,
            id: uuidv1(),
            modules: newsModules,
            name: _sectionModel.name,
            title: _sectionModel.name,
          });
        }


        const draftId = uuidv1();
        const draft = {
          _id: draftId,
          name: form.formName,
          description: form.description,
          attachPatientModel: form.attachPatientModel,
          workspaceId: form.workspaceId,
          sections: newsSections,
        };

        dispatch([
          formEngineActions.buildFormModelById({
            draft,
            draftId,
            formOriginId: formOrigin['FORM_PRONTUARIO'],
            acessedFrom: 'manager',
            ...names
          }),
          editFormModelVersionFetchSuccess()
        ]);
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        util.managerExceptionURIMessage({ error, customMsg });
        dispatch(editFormModelVersionFetchError());

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect('/'));
      }
    };

export const duplicateFormModelVersion = ({ formModelVersionId }) => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  try {
    dispatch(editFormModelVersionFetchRequest());

    //#region [GET][FORM MODEL BY ID]
    const resResponseForm = await apiForms.form.getFormModelVersionById({
      header,
      formModelVersionId,
    });
    const { form, formStyle, names } = resResponseForm.data;
    //#endregion

    //#region [POST][DRAFT]
    let resDraft = await apiForms.form.postDraft({
      header,
      params: { isAccountForm: true },
    });
    resDraft = resDraft.data[0];
    const draftId = resDraft._id;
    //#endregion

    //#region [BUILD][FORM][DRAFT]
    const newsSections = [];
    for (let _section of form.sections) {
      const newsModules = [];
      const modulesIds = [];
      const _sectionModel =
        names.sectionModelsNames.filter(
          (item) => item.sectionModelId === _section.sectionModelId
        )[0] || {};

      const { order: sectionOrder, sectionModelVersionId } = _section;

      for (let _module of _section.modules) {
        const newsFields = [];
        const fieldsIds = [];
        const _moduleModel =
          names.moduleModelsNames.filter(
            (item) => item.moduleModelId === _module.moduleModelId
          )[0] || {};
        const idModule = uuidv1();

        const { order: moduleOrder, moduleModelVersionId } = _module;

        for (let _field of _module.fields) {
          const _fieldModel =
            names.fieldModelsNames.filter(
              (item) => item.fieldModelId === _field.fieldModelId
            )[0] || {};
          const idField = uuidv1();

          const { order: fieldOrder, fieldModelVersionId } = _field;

          const fieldStyle = formStyle.style.filter(
            (item) =>
              item.sectionOrder === sectionOrder &&
              item.sectionModelVersionId === sectionModelVersionId &&
              item.moduleOrder === moduleOrder &&
              item.moduleModelVersionId === moduleModelVersionId &&
              item.fieldOrder === fieldOrder &&
              item.fieldModelVersionId === fieldModelVersionId
          )[0];

          const {
            size,
            enableNewLine,
            enableNewPage,
            enableInline,
            enableSelectListDesign,
            enableObservation,
            enablePeriodInterval,
          } = fieldStyle;

          newsFields.push({
            ..._field,
            id: idField,
            name: _fieldModel.name,
            title: _fieldModel.name,
            fieldName: _fieldModel.name,
            size,
            enableNewLine,
            enableNewPage,
            enableInline,
            enableSelectListDesign,
            enableObservation,
            enablePeriodInterval,
          });

          fieldsIds.push(idField);
        }

        newsModules.push({
          ..._module,
          fieldsIds,
          fields: newsFields,
          id: idModule,
          name: _moduleModel.name,
          title: _moduleModel.name,
        });

        modulesIds.push(idModule);
      }

      newsSections.push({
        ..._section,
        modulesIds,
        id: uuidv1(),
        modules: newsModules,
        name: _sectionModel.name,
        title: _sectionModel.name,
      });
    }
    //#endregion

    //#region [PUT][DRAFT]
    const paramsPut = {
      isAccountForm: true,
      draft: {
        _id: draftId,
        name: form.formName,
        typeForm: form.typeForm,
        description: form.description,
        attachPatientModel: form.attachPatientModel,
        workspaceId: form.workspaceId,
        sections: newsSections,
      },
    };

    await apiForms.form.putDraft({ header, params: paramsPut });
    //#endregion
    dispatch([
      formEngineActions.getPreviewDraftById({ draftId, formOriginId: formOrigin['FORM_PRONTUARIO'], acessedFrom: 'perfil' }),
    ]);
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(editFormModelVersionFetchError());

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
  }
};

export const getCategoriesForms =
  (isMountedStruture) =>
    async (dispatch, getState, { apiForms }) => {
      const state = getState();
      let header = selectorLogin.getHeaderConfig(state);

      header = {
        ...header,
      };

      try {
        dispatch(formGetCategoriesModelsFetchRequestStatus({ status: true }));

        let resResponse = (
          await apiForms.category.getCategory({
            header,
          })
        ).data;


        dispatch(formGetCategoriesAllFetchSuccess({ categoriesAllForms: resResponse.categories }));
        if (isMountedStruture) {
          dispatch(buildCategoriesStructure())
        }
      } catch (error) {
        const { msg } = error;
        const customMsg = !!msg;

        util.managerExceptionURIMessage({ error, customMsg });
        dispatch(formGetCategoriesModelsFetchRequestStatus({ status: false }));

        if (error && error.status === 401)
          dispatch(actionConfig.routeRedirect('/'));
      }
    };

export const buildCategoriesStructure = () => async (dispatch, getState, { apiDocs }) => {
  const state = getState();

  try {
    const categoriesAll = selector.getCategoriesAllForms(state);
    const categories = buildFileStructure(categoriesAll, [], true, null, 0);
    const categoriesFilters = categories.filter(category => category.root);
    const isCategorySelected = selector.getIsCategorySelected(state);
    const forms = selector.getListManagerFormModels(state);

    if (isCategorySelected.isSelected) {
      const formsFilterSelect = forms.filter(form => form.categoryId === isCategorySelected.selected.id);
      dispatch([
        setCategorySelected({ ...isCategorySelected, selected: { ...isCategorySelected.selected } }),
        setFormsFilterForm({ forms: formsFilterSelect, filter: true })
      ]);
    }
    if (isCategorySelected.categoryUndefined) {
      const formsFilter = forms.filter(form => !form.categoryId);
      dispatch(setFormsFilterForm({ forms: formsFilter, filter: true }));
    }

    dispatch(formsGetCategoriesFormFetchSuccess({ categoriesForms: categoriesFilters }));
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
  }
};

export const saveSectionAccountConfig = (data, modal) => async (
  dispatch,
  getState,
  { apiMedicalRecord }
) => {
  const state = getState();
  const medicalRecordsModelId = selectorSection.getMedicalRecordsModelId(state);
  let header = selectorLogin.getHeaderConfig(state);
  header = { ...header, formOrigin: formOrigin['FORM_PRONTUARIO'] };

  const params = {
    data,
    medicalRecordsModelId
  };


  try {
    dispatch(saveSectionAccountConfigFetchRequest(true));


    await apiMedicalRecord.section.putSectionManyWorkConfig({ header, params });

    dispatch([
      saveSectionAccountConfigFetchRequest(false),
      actionModal.onClose({ modal }),
    ]);
  } catch (error) {
    const { msg } = error;
    const customMsg = !!msg;

    util.managerExceptionURIMessage({ error, customMsg });
    dispatch(saveSectionAccountConfigFetchRequest(false));

    if (error && error.status === 401)
      dispatch(actionConfig.routeRedirect('/'));
    // window.location = systemConfigs.AUTH_DOMAIN;
  }
};

export const getAuthors = () => async (
  dispatch,
  getState,
  { apiDocs }
) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const {
      data,
    } = await apiDocs.authors.getAuthors({ header });

    dispatch([
      setAuthorsForms(data.authors || []),
    ]);
  } catch (error) {
    dispatch([
      actionSnack.showSnackbar({
        message: 'Ocorreu um erro',
        variant: 'error',
        autoHideDuration: 3000,
      })
    ]
    );
  }
};

export const saveFavoritesFormSelected = (favorites) => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);
  const params = {
    favorites
  }

  try {

     await apiForms.favorites.updateFavorites({ header, params });

  } catch (error) {
    dispatch([
      actionSnack.showSnackbar({
        message: 'Ocorreu um erro',
        variant: 'error',
        autoHideDuration: 3000,
      })
    ]
    );
  }
};

export const getListFavorites = () => async (
  dispatch,
  getState,
  { apiForms }
) => {
  const state = getState();
  const header = selectorLogin.getHeaderConfig(state);

  try {
    const {
      data,
    } = await apiForms.favorites.getFavorites({ header });

    dispatch([
      setFavorites(data || {}),
    ]);
  } catch (error) {
    dispatch([
      actionSnack.showSnackbar({
        message: 'Ocorreu um erro',
        variant: 'error',
        autoHideDuration: 3000,
      })
    ]
    );
  }
}