import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import Moment from 'moment';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { IntlProvider } from 'react-intl';
import messages from '@Util/messages';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import store from './store';
import { history } from './store';
import theme from './themeMaterial';


import { RECAPTCHA_KEY } from '@Constants/apiKey';

import 'typeface-roboto';
import 'moment/locale/pt-br';
import 'semantic-ui-css/semantic.css';
import 'react-block-ui/style.css';
import 'loaders.css/loaders.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { unregister } from './registerServiceWorker';
import registerTimeZone from './registerTimeZone';
import App from './App';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  enabled: process.env.NODE_ENV !== 'test',
  dsn: process.env.REACT_APP_SENTRY_TOKEN,
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 1.0,
});

ReactGA.initialize(
  'UA-163129052-1', //'UA-159145920-1',
  {
    debug: process.env.NODE_ENV !== 'production',
    titleCase: false,
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  }
);

Moment.locale('pt-br');
const rootElement = document.getElementById('app-root');
const locale = navigator.language || navigator.userLanguage;

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <IntlProvider locale={locale} messages={messages[locale]}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <ConfigProvider locale={ptBR}>
                <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                  <App history={history} />
                </GoogleReCaptchaProvider>
              </ConfigProvider>
            </MuiThemeProvider>
          </ConnectedRouter>
        </IntlProvider>
      </Provider>
    );
  }
}

render(<Root />, rootElement);
unregister();
registerTimeZone();

if (process.env.NODE_ENV !== 'production') {
  if (module.hot) {
    module.hot.accept('./App', () => render(<Root />, rootElement));
  }
}
