export const IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const GET_WORKSPACE_PROFILES_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getWorkspaceProfiles: true
      }
    }
  };
};

export const GET_WORKSPACE_PROFILES_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getWorkspaceProfiles: false
      }
    }
  };
};

export const GET_WORKSPACE_PROFILES_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getWorkspaceProfiles: false
      }
    }
  };
};

export const GET_WORKSPACE_PROFILE_BY_ID_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getWorkspaceProfileById: true
      }
    }
  };
};

export const GET_WORKSPACE_PROFILE_BY_ID_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getWorkspaceProfileById: false
      }
    }
  };
};

export const GET_WORKSPACE_PROFILE_BY_ID_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getWorkspaceProfileById: false
      }
    }
  };
};

export const POST_WORKSPACE_PROFILE_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postWorkspaceProfile: true
      }
    }
  };
};

export const POST_WORKSPACE_PROFILE_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postWorkspaceProfile: false
      }
    }
  };
};

export const POST_WORKSPACE_PROFILE_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        postWorkspaceProfile: false
      }
    }
  };
};

export const PUT_WORKSPACE_PROFILE_FETCH_REQUEST = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        putWorkspaceProfile: true
      }
    }
  };
};

export const PUT_WORKSPACE_PROFILE_FETCH_ERROR = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        putWorkspaceProfile: false
      }
    }
  };
};

export const PUT_WORKSPACE_PROFILE_FETCH_SUCCESS = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        putWorkspaceProfile: false
      }
    }
  };
};

export const DELETE_WORKSPACE_PROFILE_FETCH_REQUEST = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deleteWorkProfile: true
      }
    }
  };
};

export const DELETE_WORKSPACE_PROFILE_FETCH_ERROR = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deleteWorkProfile: false
      }
    }
  };
};

export const DELETE_WORKSPACE_PROFILE_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        deleteWorkProfile: false
      }
    }
  };
};

export const SET_PERMISSION = (section, state, action) => {
  const { listProfiles, listApps } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      api: {
        ...state[section].api,
        ...action.payload
      },
      search: {
        ...state[section].search,
        listProfiles,
        listApps
      }
    }
  };
};

export const SET_PERMISSION_UPDATE_PROFILES = (section, state, action) => {
  const { profiles, listProfiles } = action.payload;
  const _profiles = profiles || state[section].api.profiles;
  const _listProfiles = listProfiles || state[section].api.listProfiles;

  return {
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      api: {
        ...state[section].api,
        profiles: _profiles,
        listProfiles: _listProfiles
      },
      search: {
        ...state[section].search,
        listProfiles: _listProfiles
      }
    }
  };
};

export const SET_PERMISSION_UPDATE_FUNCTIONALITIES = (
  section,
  state,
  action
) => {
  const { apps, workspaces } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        apps,
        workspaces
      }
    }
  };
};

export const SET_SEARCH_PERFIL = (section, state, action) => {
  const { listProfiles } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      search: {
        ...state[section].search,
        listProfiles
      }
    }
  };
};

export const SET_PERFIL_SELECTED = (section, state, action) => {
  const { profileSelected } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        profileSelected: { ...profileSelected }
      }
    }
  };
};

export const SET_APP_FUNCTIONALITY = (section, state, action) => {
  const { functionality } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        functionality: { ...functionality }
      }
    }
  };
};

export const SET_APP_LIST_FUNCTIONALITY = (section, state, action) => {
  const { listFunctionality } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        listFunctionality: [...listFunctionality]
      }
    }
  };
};

export const APP_ID_SELECTED = (section, state, action) => {
  const { idSelected, workspaceChecked } = action.payload;
  const _workspaceChecked =
    typeof workspaceChecked === 'number'
      ? workspaceChecked
      : state[section].api.workspaceChecked;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        idSelected,
        workspaceChecked: _workspaceChecked
      }
    }
  };
};

export const SET_TOGGLE_CHECK_APPS = (section, state, action) => {
  const { apps } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        apps
      }
    }
  };
};

export const SET_TOGGLE_CHECK_FUNCTIONALITY = (section, state, action) => {
  const { apps } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        apps
      }
    }
  };
};

export const SET_TOGGLE_CHECK_WORKSPACE_FUNCTIONALITY = (
  section,
  state,
  action
) => {
  const { functionalities } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        workspaces: functionalities
      }
    }
  };
};

export const SET_LIST_PROFILES = (section, state, action) => {
  const { listProfiles } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        listProfiles
      },
      search: {
        ...state[section].search,
        listProfiles
      }
    }
  };
};

export const SET_PROFILES = (section, state, action) => {
  const { profiles } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        profiles
      }
    }
  };
};

export const SET_ADMIN_PERMISSION_PROFILE = (section, state, action) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        adminProfile: action.payload
      }
    }
  };
};
