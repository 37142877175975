import map from 'lodash/map';

import initialState from './initialState';

export const GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceAnswerPatientById: true
      }
    }
  });
};

export const GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { attendanceAnswerPatient, attendance } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        attendanceAnswerPatient,
        attendance,
      },
      fetching: {
        ...state[section].fetching,
        getAttendanceAnswerPatientById: false
      }
    }
  });
};

export const GET_ATTENDANCE_ANSWER_PATIENT_BY_ID_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getAttendanceAnswerPatientById: false
      }
    }
  });
}

export const GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_SUCCESS = (section, state, action) => {
  const { targetForm, formId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getFormAnswerPatient: false
      }
    }
  });
}

export const SET_TARGET_FORM_ANSWER_PATIENT = (section, state, action) => {
  const { targetForm, formId } = action.payload;
  return ({
    ...state,
    [section]: {
      ...state[section],
      forms: {
        ...state[section].forms,
        [targetForm]: formId
      },
    }
  });
}

export const GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_REQUEST =  (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getFormAnswerPatient: true
      }
    }
  });
}

export const GET_FORM_ANSWER_PATIENT_BY_ID_FETCH_ERROR  =  (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        getFormAnswerPatient: false
      }
    }
  });
}

export const SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_REQUEST = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveAttendanceAnswerPatientRelease: true
      }
    }
  });
}

export const saveModalAttendanceAnswerPatientReleaseFetchSucess = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveAttendanceAnswerPatientRelease: false
      }
    }
  });
}

export const SAVE_MODAL_ATTENDANCE_ANSWER_PATIENT_RELEASE_FETCH_ERROR = (section, state, action) => {
  return ({
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        saveAttendanceAnswerPatientRelease: false
      }
    }
  });
}



