export default ({
  isFirstLoading: true,
  fetching: {
    dashboardIsFetching: false
  },

  api: {
    dashboard: {
      malePatients: 0,
      femalePatients: 0,
      patients: 0,
      pendingMedicalRecordsAttendances: 0
    }
  }
});