export default {
  isFirstLoading: true,
  error: {
    done: false,
    messages: []
  },
  fetching: {
    sendInvite: false,
    sendInviteError: false,
    isGetUserByIdAccount: false,
    isGetUserByIdWorkspace: false,
    isToggleActiveAccount: false,
    isToggleAccessType: false,
    isSaveUpdate: false,
    isCheckingCep: false,
    workspaces: false,
    workspacesError: false
  },
  api: {
    initialValues: {},
    listCityIndexed: {},
    listState: [],
    workspaces: [],
    perfilAccount: [],
    perfilWorkspace: [],
    listWorkspace: [],
    councils: [],
    userViewed: {
      userId: 0,
      isWorkspaceOrigin: null,
      workspaceId: null
    },
    searchedCep: null,
    perfil: {
      user: {
        id: 0,
        cpf: null,
        sex: 0,
        name: '',
        email: '',
        birthdate: '',
        cel: '',
        phone: ''
      },
      address: {
        cep: null,
        stateId: 0,
        dropState: 0,
        cityId: 0,
        dropCity: 0,
        complement: null,
        numberAddress: null,
        neighborhood: '',
        address: ''
      },
      professional: {
        userId: 0,
        cityId: 0,
        humanId: 0,
        stateId: 0,
        dropCity: 0,
        dropState: 0,
        name: '',
        address: null,
        addressComplement: null,
        adressNumber: null,
        birthdate: null,
        cboId: null,
        cel: null,
        councilId: null,
        councilRegister: null,
        councilRegionId: null,
        stateCouncilId: null,
        cpf: null,
        email: null,
        isHealthProfessional: null,
        neighborhood: null,
        professionalPosition: null,
        rg: null,
        sex: null,
        tel: null
      },
      account: {
        id: 0,
        name: ''
      }
    }
  }
};
