import initialState from './initialState';

export const USER_UPDATE_IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const USER_UPDATE_SET_ACCOUNT = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      business: {
        ...state[section].api.business,
        ...action.payload
      }
    }
  }
});

export const USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      councils: [...action.payload]
    }
  }
});

export const USER_UPDATE_GET_PROFESSIONAL_COUNCILS_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    error: {
      ...state[section].error,
      done: true
    }
  }
});

export const USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCheckingCep: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isCheckingCep: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});

export const USER_UPDATE_OVERVIEW_CHECK_CEP_INFO_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  // const {user, address, account, workspaces, initialValues, newPerfilAccount, newPerfilWorkspace, professional} = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      fetching: {
        ...state[section].fetching,
        isCheckingCep: true
      },
      api: {
        ...state[section].api,
        searchedCep: action.payload
      }
    }
  };
};

export const USER_UPDATE_GET_WORKSPACES_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      workspaces: true,
      workspacesError: false
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_UPDATE_GET_WORKSPACES_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      workspaces: false,
      workspacesError: true
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});

export const USER_UPDATE_GET_WORKSPACES_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      workspaces: false
    },
    api: {
      ...state[section].api,
      listWorkspace: action.payload.listWorkspace
    }
  }
});

export const USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isGetUserByIdAccount: true
    },
    error: {
      done: false,
      messages: []
    },
    api: {
      ...state[section].api,
      perfil: {
        ...initialState.api.perfil
      }
    }
  }
});

export const USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isGetUserByIdAccount: false
    }
  }
});

export const USER_UPDATE_GET_USER_BY_ID_ACCOUNT_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const {
    user,
    address,
    account,
    workspaces,
    initialValues,
    newPerfilAccount,
    newPerfilWorkspace,
    professional
  } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      fetching: {
        ...state[section].fetching,
        isGetUserByIdAccount: false
      },
      api: {
        ...state[section].api,
        perfil: {
          ...state[section].api.perfil,
          user: {
            ...state[section].api.user,
            ...user
          },
          address: {
            ...state[section].api.address,
            ...address
          },
          professional: {
            ...state[section].api.professional,
            ...professional
          },
          account: {
            ...state[section].api.account,
            ...account
          },
          workspaces: [...workspaces],
          initialValues: { ...initialValues },
          perfilAccount: [...newPerfilAccount],
          perfilWorkspace: [...newPerfilWorkspace]
        }
      }
    }
  };
};

export const USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isGetUserByIdWorkspace: true
    },
    error: {
      done: false,
      messages: []
    },
    api: {
      ...state[section].api,
      perfil: {
        ...initialState.api.perfil
      }
    }
  }
});

export const USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isGetUserByIdWorkspace: false
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [...state[section].error.messages, action.payload.messages]
    }
  }
});

export const USER_UPDATE_GET_USER_BY_ID_WORKSPACE_FETCH_SUCCESS = (
  section,
  state,
  action
) => {
  const {
    user,
    address,
    workspaces,
    initialValues,
    newPerfilWorkspace,
    professional
  } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      isFirstLoading: false,
      fetching: {
        ...state[section].fetching,
        isGetUserByIdWorkspace: false
      },
      api: {
        ...state[section].api,
        perfil: {
          ...state[section].api.perfil,
          user: {
            ...state[section].api.user,
            ...user
          },
          address: {
            ...state[section].api.address,
            ...address
          },
          professional: {
            ...state[section].api.professional,
            ...professional
          },
          workspaces: [...workspaces],
          initialValues: { ...initialValues },
          perfilWorkspace: [...newPerfilWorkspace]
        }
      }
    }
  };
};

export const USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isToggleActiveAccount: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isToggleActiveAccount: false
    }
  }
});

export const USER_UPDATE_SET_UPDATE_PERMISSION_ACITVE_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isToggleActiveAccount: false
    }
  }
});

export const USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isToggleAccessType: true
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isToggleAccessType: false
    }
  }
});

export const USER_UPDATE_SET_UPDATE_ACCESS_TYPE_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isToggleAccessType: false
    }
  }
});

export const USER_UPDATE_SET_ALL_PERFIL = (section, state, action) => {
  const { perfilAccount, perfilWorkspace } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        perfilAccount,
        perfilWorkspace
      }
    }
  };
};

export const USER_UPDATE_SET_ONLY_PERFIL_WORK = (section, state, action) => {
  const { perfilWorkspace } = action.payload;

  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        perfilWorkspace
      }
    }
  };
};

export const USER_UPDATE_GET_STATE_CITY = (section, state, action) => {
  const { listCityIndexed, listState } = action.payload;
  return {
    ...state,
    [section]: {
      ...state[section],
      api: {
        ...state[section].api,
        listCityIndexed: {
          ...state[section].api.listCityIndexed,
          ...listCityIndexed
        },
        listState
      }
    }
  };
};

export const USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_REQUEST = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isSaveUpdate: true
    }
  }
});

export const USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_ERROR = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isSaveUpdate: false
    }
  }
});

export const USER_UPDATE_PROFESSIONAL_BY_ID_FETCH_SUCCESS = (
  section,
  state,
  action
) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isSaveUpdate: false
    }
  }
});

export const USER_UPDATE_SET_USER_VIEWED = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    api: {
      ...state[section].api,
      userViewed: { ...action.payload }
    }
  }
});
