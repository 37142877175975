/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import axios        from 'axios';

export const checkCepInformation = async (cep) => {
  const pathUrl = `${endpoints.API_CORE_ZIPCODE}/get/zip-code`;
  const params = { zipCode: cep }
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': endpoints.API_KEY_CORE_ZIPCODE
  }
  // debugger
  try {
    // debugger
    const response = (await axios({
      method: 'get',
      url: pathUrl,
      params,
      headers,
    })).data
    // debugger
    if (Number(response.statusCode) === 200) {
      const { data } = response
      const {
        addressWithType,
        neighborhood,
        zipCode,
        city: { name: cityName, idIBGE: cityIdIBGE, ddd },
        state: { name: stateName, shortName: stateShortName }
      } = data
      // debugger
      return {
        cep: !!zipCode ? zipCode : null,
        logradouro: !!addressWithType ? addressWithType : null,
        bairro: !!neighborhood ? neighborhood : null,
        cidade: !!cityName ? cityName : null,
        estado: !!stateShortName ? stateShortName : null,
        'estado_info': {
          nome: !!stateName ? stateName : null,
          'codigo_ibge': !!ddd ? ddd : null
        },
        'cidade_info': {
          'codigo_ibge': !!cityIdIBGE ? cityIdIBGE : null
        }

      }
    }
    else return null
  }
  catch (error) {
    // debugger
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
};

export const getStatesList = async () => {
  const pathUrl = `${endpoints.API_CORE_ZIPCODE}/get/states`;
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': endpoints.API_KEY_CORE_ZIPCODE
  }
  // debugger
  try {
    // debugger
    const response = (await axios({
      method: 'get',
      url: pathUrl,
      headers,
    })).data
    // debugger
    if (Number(response.statusCode) === 200) {
      const { data } = response
      const statesList = data.map((state) => ({
        name: state.name,
        shortName: state.shortName
      }))
      // debugger
      return statesList
    }
    else return null
  }
  catch (error) {
    // debugger
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
}

export const getStateCitiesList = async (stateShortName) => {
  const pathUrl = `${endpoints.API_CORE_ZIPCODE}/get/cities`;
  const params = { 'state-short-name': String(stateShortName).toLowerCase() }
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'x-api-key': endpoints.API_KEY_CORE_ZIPCODE
  }
  // debugger
  try {
    // debugger
    const response = (await axios({
      method: 'get',
      url: pathUrl,
      params,
      headers,
    })).data
    // debugger
    if (Number(response.statusCode) === 200) {
      const { data } = response
      const citiesList = data.map((city) => ({
        name: city.name,
        state: city.state.shortName,
        idIBGE: city.idIBGE,
        ddd: city.ddd
      }))
      // debugger
      return citiesList
    }
    else return null
  }
  catch (error) {
    // debugger
    if (error && error.response && error.response.status)
      throw ({status: error.response.status, userFeedback: error.response.data.userFeedback});
    throw error;
  }
}
