import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';

const PROFESSIONAL  = 'professional';

//#region GET
export const getSearchProfesional = async ({header, value}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PROFESSIONAL}?value=${value}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region POST
export const createProfissionalBasic = async ({header, params}) => {
  const pathUrl = `${endpoints.API_FORMS}/${PROFESSIONAL}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion