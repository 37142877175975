export default ({
  fetching: {
    createReferralModel: false,
    updateReferralModel: false,
    listReferralModel: false,
    getReferralModel: false
  },
  api: {
    referralList: [],
    referralModel: {
      referralModelId: null,
      name: '',
      content: ''
    }
  },
  forms: {
    modal: {
      name: {
        value: '',
        error: false
      },
      description: {
        value: '',
        error: false
      }
    }
  }
});