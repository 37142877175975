import * as endpoints from '@App/constants/endpoints';
import interceptor    from '../interceptor';
import { get }        from '../util';
import { post }       from '../util';
import { put }        from '../util';
import { del }        from '../util';

const MEDICALRECORD   = 'medicalrecords';
const ACCOUNT         = 'account';
const CONFIG          = 'config';
const SHARINGCONTROL  = 'sharingcontrol';
const ACCESSGROUP     = 'accessgroup';
const MEMBERS         = 'members';
const WORKSPACE       = 'workspace'   

//#region GET
export const getAccountConfig = async ({header}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCOUNT}/${CONFIG}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAccountConfigWorkspace = async ({header}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCOUNT}/${CONFIG}/${WORKSPACE}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getMedicalRecord = async ({header}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAccessGroup = async ({header}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCESSGROUP}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getMedicalRecordMembers = async ({header}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${MEMBERS}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};

export const getAccessGroupById = async ({header, groupAccessId}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCESSGROUP}/${groupAccessId}`;
  const axios   = interceptor(header);
  return await get(axios, pathUrl);
};
//#endregion

//#region PUT
export const putAccountConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCOUNT}/${CONFIG}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putMedicalRecordConfig = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${CONFIG}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};

export const putAccesGroup = async ({header, accessGroupId, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCESSGROUP}/${accessGroupId}`;
  const axios   = interceptor(header);
  return await put(axios, pathUrl, params);
};
//#endregion

//#region POST
export const postAccountConfigSharingControl = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCOUNT}/${CONFIG}/${SHARINGCONTROL}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const postAccesGroup = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCESSGROUP}`;
  const axios   = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region DELETE
export const deltAccountConfigSharingControl = async ({header, params}) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${MEDICALRECORD}/${ACCOUNT}/${CONFIG}/${SHARINGCONTROL}`;
  const axios   = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion
