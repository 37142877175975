export default {
  drawer: {
    done: false
  },
  error: {
    done    : false,
    messages: []
  },
  fetching: {
    stateAndCity: false
  },
  api: {
    listCityIndexed: {},
    listState: []
  },
  appBar: {
    context: null,
    goBackValue: null
  }
};