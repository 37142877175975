export const SECTION_REGISTER = 'register';

export const LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_REQUEST =
  'LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_REQUEST';
export const LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_ERROR =
  'LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_ERROR';
export const LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_SUCCESS =
  'LOGIN_RECOVER_UPDATE_PASSWORD_FETCH_SUCCESS';

export const LOGIN_SIGNIN_INVALID_EMAIL = 'LOGIN_SIGNIN_INVALID_EMAIL';
export const LOGIN_SIGNIN_INVALID_EMAIL_FORMAT =
  'LOGIN_SIGNIN_INVALID_EMAIL_FORMAT';
export const LOGIN_SIGNIN_INVALID_PASSWORD = 'LOGIN_SIGNIN_INVALID_PASSWORD';
export const LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT =
  'LOGIN_SIGNIN_INVALID_PASSWORD_FORMAT';

export const LOGIN_SIGNIN_FETCH_REQUEST = 'LOGIN_SIGNIN_FETCH_REQUEST';
export const LOGIN_SIGNIN_FETCH_ERROR = 'LOGIN_SIGNIN_FETCH_ERROR';
export const LOGIN_SIGNIN_FETCH_SUCCESS = 'LOGIN_SIGNIN_FETCH_SUCCESS';

export const LOGIN_REGISTER_GET_STATE = 'LOGIN_REGISTER_GET_STATE';
export const LOGIN_REGISTER_GET_CITY = 'LOGIN_REGISTER_GET_CITY';

export const LOGIN_SIGNIN_CLEAR_FORM_ERROR = 'LOGIN_SIGNIN_CLEAR_FORM_ERROR';
export const LOGIN_SIGNIN_AUTHENTICATED = 'LOGIN_SIGNIN_AUTHENTICATED';
export const LOGIN_SIGNIN_LOGOUT = 'LOGIN_SIGNIN_LOGOUT';

export const LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_REQUEST =
  'LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_REQUEST';
export const LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_ERROR =
  'LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_ERROR';
export const LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_SUCCESS =
  'LOGIN_REGISTER_GET_VALIDATE_STEP_FETCH_SUCCESS';

export const LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_REQUEST =
  'LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_REQUEST';
export const LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_ERROR =
  'LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_ERROR';
export const LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_SUCCESS =
  'LOGIN_REGISTER_CREATE_ACCOUNT_FETCH_SUCCESS';

export const LOGIN_REGISTER_STEP_NEXT = 'LOGIN_REGISTER_STEP_NEXT';
export const LOGIN_REGISTER_STEP_PREV = 'LOGIN_REGISTER_STEP_PREV';

export const LOGIN_REGISTER_RESET_COMPANY = 'LOGIN_REGISTER_RESET_COMPANY';

export const LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_REQUEST =
  'LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_REQUEST';
export const LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_ERROR =
  'LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_ERROR';
export const LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_SUCCESS =
  'LOGIN_REGISTER_VERIFY_RECAPTCHA_FETCH_SUCCESS';

export const LOGIN_RECOVER_SEND_EMAIL_FETCH_REQUEST =
  'LOGIN_RECOVER_SEND_EMAIL_FETCH_REQUEST';
export const LOGIN_RECOVER_SEND_EMAIL_FETCH_ERROR =
  'LOGIN_RECOVER_SEND_EMAIL_FETCH_ERROR';
export const LOGIN_RECOVER_SEND_EMAIL_FETCH_SUCCESS =
  'LOGIN_RECOVER_SEND_EMAIL_FETCH_SUCCESS';

export const LOGIN_RECOVER_VERIFY_TOKEN_FETCH_REQUEST =
  'LOGIN_RECOVER_VERIFY_TOKEN_FETCH_REQUEST';
export const LOGIN_RECOVER_VERIFY_TOKEN_FETCH_ERROR =
  'LOGIN_RECOVER_VERIFY_TOKEN_FETCH_ERROR';
export const LOGIN_RECOVER_VERIFY_TOKEN_FETCH_SUCCESS =
  'LOGIN_RECOVER_VERIFY_TOKEN_FETCH_SUCCESS';

export const LOGIN_SET_AUTH = 'LOGIN_SET_AUTH';
export const LOGIN_VERIFY_TOKEN = 'LOGIN_VERIFY_TOKEN';

export const LOGIN_SET_USER_NAME = 'LOGIN_SET_USER_NAME';

export const LOGIN_REGISTER_HIDE_SHOW_MESSAGE =
  'LOGIN_REGISTER_HIDE_SHOW_MESSAGE';

export const LOGIN_REGISTER_RESSEND_EMAIL_FETCH_REQUEST =
  'LOGIN_REGISTER_RESSEND_EMAIL_FETCH_REQUEST';
export const LOGIN_REGISTER_RESSEND_EMAIL_FETCH_ERROR =
  'LOGIN_REGISTER_RESSEND_EMAIL_FETCH_ERROR';
export const LOGIN_REGISTER_RESSEND_EMAIL_FETCH_SUCCESS =
  'LOGIN_REGISTER_RESSEND_EMAIL_FETCH_SUCCESS';

export const LOGIN_SET_AUTH_PERMISSION_REQUEST = 'LOGIN_SET_AUTH_PERMISSION_REQUEST';
export const LOGIN_SET_AUTH_PERMISSION = 'LOGIN_SET_AUTH_PERMISSION';
