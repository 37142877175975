export default  [
  'rgba(241, 90, 36, 0.8)',
  'rgba(64, 186, 213, 0.8)',
  'rgba(255, 167, 38, 0.8)',
  'rgba(139, 195, 74, 0.8)',
  'rgba(171, 71, 188, 0.8)',
  'rgba(244, 67, 54, 0.8)',
  'rgba(33, 150, 243, 0.8)',
  'rgba(76, 175, 80, 0.8)',
  'rgba(255, 152, 0, 0.8)',
  'rgba(121, 85, 72, 0.8)',
  'rgba(233, 30, 99, 0.8)',
  'rgba(0, 150, 136, 0.8)',
  'rgba(3, 169, 244, 0.8)',
  'rgba(156, 39, 176, 0.8)',
  'rgba(255, 87, 34, 0.8)',
  'rgba(0, 188, 212, 0.8)',
  'rgba(27, 94, 32, 0.8)',
  'rgba(255, 235, 59, 0.8)',
  'rgba(255, 193, 7, 0.8)',
  'rgba(96, 125, 139, 0.8)',
];
