import { createSelector } from 'reselect';

const getStateBillingByKey = (state, key) => state.billing.overview[key];
const getIsFetchingByKey = (state, key) => state.billing.overview.fetching[key];
const getApiContextByKey = (state, key) => state.billing.overview.api[key];
const getSectionsContextByKey = (state, key) => state.billing.overview.sections[key];

export default ({
  getSectionActiveKey: createSelector(
    state => getSectionsContextByKey(state, 'activeKey'),
    state => state
  ),
  getSectionDone: createSelector(
    state => getSectionsContextByKey(state, 'done'),
    state => state
  ),
  getPackageData: createSelector(
    state => getStateBillingByKey(state, 'packageData'),
    state => state
  ),
  getBillingData: createSelector(
    state => getStateBillingByKey(state, 'billingData'),
    state => state
  ),
  getBillingConfig: createSelector(
    state => getStateBillingByKey(state, 'billingConfig'),
    state => state
  ),
  getChangeUserSolicitation: createSelector(
    state => getStateBillingByKey(state, 'changeUserSolicitation'),
    state => state
  ),
  getNewBillingConfig: createSelector(
    state => getStateBillingByKey(state, 'newBillingConfig'),
    state => state
  ),
  getPaymentHistory: createSelector(
    state => getStateBillingByKey(state, 'paymentHistory'),
    state => state
  ),
  getAvailablePackages: createSelector(
    state => getApiContextByKey(state, 'plans'),
    state => state
  ),
  getPaymentInfo: createSelector(
    state => getStateBillingByKey(state, 'paymentInfo'),
    state => state
  ),
  getIsAddingCard: createSelector(
    state => getIsFetchingByKey(state, 'creatingCard'),
    state => state
  ),
  getIsUpdatingData: createSelector(
    state => getIsFetchingByKey(state, 'updatingData'),
    state => state
  ),
  getIsLoadingInfo: createSelector(
    state => getIsFetchingByKey(state, 'loadingInfo'),
    state => state
  ),
  getIsPaying: createSelector(
    state => getIsFetchingByKey(state, 'isPaying'),
    state => state
  ),
  getIsSolicitingChangeNumUsers: createSelector(
    state => getIsFetchingByKey(state, 'isSolicitingChangeNumUsers'),
    state => state
  ),
});