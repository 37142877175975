import { createTheme } from '@material-ui/core/styles';

const fontFamily = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
  'Sohne',
  'Meta Serif W01',
  'Roboto Mono',
  'AvenirNextRoundedW01',
  'Inter',
].join(',');

export default createTheme({
  palette: {
    primary: {
      main: '#286efa', //500
      light: '#4F88FA', //300
      dark: '#215BCD', //700
    },
    secondary: {
      main: '#673ab7', //500
      light: '#825DC4', //300
      dark: '#553096', //700
    },
  },
  typography: {
    useNextVariants: true,

    fontFamily,

    display4: {
      fontFamily,
    },

    display3: {
      fontFamily,
    },

    display2: {
      fontFamily,
    },

    display1: {
      fontFamily,
    },

    headline: {
      fontFamily,
    },

    title: {
      fontFamily,
    },

    subheading: {
      fontFamily,
    },

    body2: {
      fontFamily,
    },

    body1: {
      fontFamily,
    },

    caption: {
      fontFamily,
    },

    button: {
      fontFamily,
    },

    h1: {
      fontFamily,
    },

    h2: {
      fontFamily,
    },

    h3: {
      fontFamily,
    },

    h4: {
      fontFamily,
    },

    h5: {
      fontFamily,
    },

    h6: {
      fontFamily,
    },

    subtitle1: {
      fontFamily,
    },

    subtitle2: {
      fontFamily,
    },

    body1Next: {
      fontFamily,
    },

    body2Next: {
      fontFamily,
    },

    buttonNext: {
      fontFamily,
    },

    captionNext: {
      fontFamily,
    },

    overline: {
      fontFamily,
    },
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: '0px',
        marginRight: '16px',
      },
    },
    MuiListItemAvatar: {
      root: {
        minWidth: '0px',
      },
    },
    MuiListItemText: {
      root: {
        padding: '0 16px',
        margin: '0',
        marginTop: '0',
        marginBottom: '0',
      },
    },
    MuiTypography: {
      root: {
        display: 'block',
      },
      h6: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
      subtitle1: {
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
  },
});
