export const MODAL_CONFIGURATION_MEDICALRECORD_SECTIONS = (state, action) => {
  const { drawerState } = action.payload;
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      drawer: {
        ...state.medicalRecord.drawer,
        ...drawerState,
      }
    }
  });
};

export const MODAL_CONFIGURATION_MEDICALRECORD_TOGGLE_DRAWER = (state, action) => {

  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      drawer: {
        ...state.medicalRecord.drawer,
        isDone: !state.medicalRecord.drawer.isDone,
      }
    }
  });
}

export const OPEN_CONFIGURATION_MEDICALRECORD_TOGGLE_DRAWER = (state, action) => {
  return ({
    ...state,
    medicalRecord: {
      ...state.medicalRecord,
      drawer: {
        ...state.medicalRecord.drawer,
        isDone: action.payload,
      }
    }
  });
}