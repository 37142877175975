export const IS_FIRST_LOADING = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: action.payload
  }
});

export const MODAL_CLOSED = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    modal: {
      ...state[section].modal,
      create: false,
      update: false,
      user  : false
    }
  }
});

export const MODAL_UPDATE = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    modal: {
      ...state[section].modal,
      update: action.payload
    }
  }
});

export const GET_BY_ID_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isAccount: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const GET_BY_ID_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isAccount: false,
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const GET_BY_ID_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isAccount:  false
    },
    api: {
      ...state[section].api,
      business: {
        ...state[section].api.business,
        ...action.payload.business
      }
    }
  }
});

export const UPDATE_ACCOUNT_FETCH_REQUEST = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    fetching: {
      ...state[section].fetching,
      isUpdateBusiness: true,
    },
    error: {
      done: false,
      messages: []
    }
  }
});

export const UPDATE_ACCOUNT_FETCH_ERROR = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isUpdateBusiness: false,
    },
    error: {
      ...state[section].error,
      done: true,
      messages: [
        ...state[section].error.messages,
        action.payload.message
      ]
    }
  }
});

export const UPDATE_ACCOUNT_FETCH_SUCCESS = (section, state, action) => ({
  ...state,
  [section]: {
    ...state[section],
    isFirstLoading: false,
    fetching: {
      ...state[section].fetching,
      isUpdateBusiness:  false
    },
    api: {
      ...state[section].api,
      business: {
        ...state[section].api.business,
        account: action.payload.name
      }
    },
    modal: {
      ...state[section].modal,
      update: false
    }
  }
});