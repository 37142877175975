/*eslint-disable no-throw-literal*/
import * as endpoints from '@App/constants/endpoints';
import interceptor from './interceptor';

const axios = interceptor();
const CONFIRM = 'confirm';
const CORRECTION = 'correction';
const DELETE = 'delete';
const EMAIL = 'email';
const INVITE = 'invite';
const OPEN = 'open';
const RESEND = 'resend';

export const confirmInvite = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INVITE}/${CONFIRM}`;

  try {
    let response = await axios.put(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const createInvite = async invite => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INVITE}`;

  try {
    let response = await axios.post(pathUrl, invite);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const deleteInvite = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INVITE}/${DELETE}`;

  try {
    let response = await axios.delete(pathUrl, { data: { ...params } });
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const inviteEmailCorrection = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INVITE}/${CORRECTION}/${EMAIL}`;

  try {
    let response = await axios.put(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const openInvite = async token => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INVITE}/${OPEN}/${token}`;

  try {
    let response = await axios.get(pathUrl);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};

export const resendInvite = async params => {
  const pathUrl = `${endpoints.API_ACCOUNT}/${INVITE}/${RESEND}`;

  try {
    let response = await axios.post(pathUrl, params);
    return response.data;
  } catch (error) {
    if (error && error.response && error.response.status)
      throw {
        status: error.response.status,
        userFeedback: error.response.data.userFeedback
      };
    throw error;
  }
};
