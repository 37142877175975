export default {
  SECTION: 'business',
  BUSINESS_ADMIN: 'Administrador do Negócio',
  CONTEXT_MODAL_DELETE_PERFIL: 'PermissionBusinessPanelHeaderModalDeletePerfil',

  FORM_PERMISSION_BUSINESS_PANEL_CREATE:
    'form:permission:business:panel:create',
  FORM_PERMISSION_BUSINESS_PANEL_UPDATE:
    'form:permission:business:panel:update',

  MODAL_PERMISSION_BUSINESS_PANEL_CREATE:
    'modal:permission:business:panel:create',
  MODAL_PERMISSION_BUSINESS_PANEL_UPDATE:
    'modal:permission:business:panel:update',

  PERMISSION_BUSINESS_IS_FIRST_LOADING: 'PERMISSION_BUSINESS_IS_FIRST_LOADING',

  PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_REQUEST:
    'PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_REQUEST',
  PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_ERROR:
    'PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_ERROR',
  PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_SUCCESS:
    'PERMISSION_BUSINESS_GET_ACCOUNT_PROFILES_FETCH_SUCCESS',

  PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_REQUEST:
    'PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_REQUEST',
  PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_ERROR:
    'PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_ERROR',
  PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_SUCCESS:
    'PERMISSION_BUSINESS_GET_ACCOUNT_PROFILE_BY_ID_FETCH_SUCCESS',

  PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_REQUEST:
    'PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_REQUEST',
  PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_ERROR:
    'PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_ERROR',
  PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_SUCCESS:
    'PERMISSION_BUSINESS_POST_ACCOUNT_PROFILE_FETCH_SUCCESS',

  PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_REQUEST:
    'PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_REQUEST',
  PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_ERROR:
    'PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_ERROR',
  PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_SUCCESS:
    'PERMISSION_BUSINESS_PUT_ACCOUNT_PROFILE_FETCH_SUCCESS',

  PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_REQUEST:
    'PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_REQUEST',
  PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_ERROR:
    'PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_ERROR',
  PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_SUCCESS:
    'PERMISSION_BUSINESS_DELETE_ACCOUNT_PROFILE_FETCH_SUCCESS',

  PERMISSION_BUSINESS_SET_PERMISSION: 'PERMISSION_BUSINESS_SET_PERMISSION',

  PERMISSION_BUSINESS_SET_ADMIN_PERMISSION_PROFILE:
    'PERMISSION_BUSINESS_SET_ADMIN_PERMISSION_PROFILE',

  PERMISSION_BUSINESS_SET_SEARCH_PERFIL:
    'PERMISSION_BUSINESS_SET_SEARCH_PERFIL',
  PERMISSION_BUSINESS_SET_SEARCH_FUNCTIONALITIES:
    'PERMISSION_BUSINESS_SET_SEARCH_FUNCTIONALITIES',

  PERMISSION_BUSINESS_SET_PERMISSION_UPDATE_PROFILES:
    'PERMISSION_BUSINESS_SET_PERMISSION_UPDATE_PROFILES',
  PERMISSION_BUSINESS_SET_PERMISSION_UPDATE_FUNCTIONALITIES:
    'PERMISSION_BUSINESS_SET_PERMISSION_UPDATE_FUNCTIONALITIES',

  PERMISSION_BUSINESS_SET_PERFIL_SELECTED:
    'PERMISSION_BUSINESS_SET_PERFIL_SELECTED',

  PERMISSION_BUSINESS_SET_TOGGLE_CHECK_FUNCTIONALITY:
    'PERMISSION_BUSINESS_SET_TOGGLE_CHECK_FUNCTIONALITY',

  PERMISSION_BUSINESS_SET_LIST_PROFILES:
    'PERMISSION_BUSINESS_SET_LIST_PROFILES',
  PERMISSION_BUSINESS_SET_PROFILES: 'PERMISSION_BUSINESS_SET_PROFILES'
};
