export const styles = theme => ({
  blockUi: {
    height: '99vh',
    padding: '3em',
    '& .block-ui-overlay': {
      backgroundColor: 'transparent'
    },
    '& .block-ui-message': {
      fontSize: 16,
      color: '#848383',
      fontFamily: 'Roboto, sans-serif',
    }
  },
  errorPage: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    height: '100%'
  }
});