import _ from 'lodash';

export const SET_REPORT_NAME = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: action.payload.nameValue
      }
    }
  }
})

export const SET_REPORT_DESCRIPTION = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      description: {
        ...state.forms.modal.description,
        value: action.payload.descriptionValue
      }
    }
  }
})

export const RESET_REPORT_FORM = (state, action) => ({
  ...state,
  forms: {  
    ...state.forms,
    modal: {
      ...state.forms.modal,
      name: {
        ...state.forms.modal.name,
        value: '',
        error: false
      },
      description: {
        ...state.forms.modal.description,
        value: '',
        error: false
      }
    }
  }
})

export const PUSH_NEW_REPORT_TO_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    reportList: [
      ...state.api.reportList,
      { ...action.payload }
    ]
  }
})

export const UPDATE_ITEM_ON_LIST = (state, action) => ({
  ...state,
  api: {
    ...state.api,
    reportList: _.map([ ...state.api.reportList ], listItem => {
      if(listItem.id !== action.payload.id ) return listItem
      return action.payload;
    }) 
  }
})

export const LIST_REPORTS_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listReportModel: true
  }
})

export const LIST_REPORTS_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listReportModel: false
  }
})

export const LIST_REPORTS_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    listReportModel: false
  },
  api: {
    ...state.api,
    reportList: [ ...action.payload.list ]
  }
})

export const CREATE_REPORT_FETCH_REQUEST = (state, action) => ({ 
  ...state,
  fetching: {
    ...state.fetching,
    createReportModel: true
  }
})

export const CREATE_REPORT_FETCH_ERROR = (state, action) => ({
  ...state, 
  fetching: {
    ...state.fetching,
    createReportModel: false
  }
})

export const CREATE_REPORT_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    createReportModel: false
  } 
})

export const UPDATE_REPORT_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateReportModel: true
  }
})

export const UPDATE_REPORT_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateReportModel: false
  }
})

export const UPDATE_REPORT_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    updateReportModel: false
  }
})

export const GET_REPORT_FETCH_REQUEST = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getReportModel: true
  }
})

export const GET_REPORT_FETCH_ERROR = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getReportModel: false
  }
})

export const GET_REPORT_FETCH_SUCCESS = (state, action) => ({
  ...state,
  fetching: {
    ...state.fetching,
    getReportModel: false
  },
  api: {
    ...state.api,
    reportModel: { ...action.payload }
  }
})