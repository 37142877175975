import React      from 'react';
import { Route }  from 'react-router-dom';
import Loadable   from 'react-loadable';

import AppLoading from '@Common/appAsyncLoading';

import enhanceRouter    from '@App/hoc/enhanceRouter';
import protectedRouters from '@App/hoc/protectedRouters';

const asyncComponent = Component => {
  return Loadable({
    loader  : Component,
    loading : AppLoading,
    delay: 1000,
    timeout: 10000
  });
};

const lazyRouteProtectedName = ({ component: Component, ...rest }) => {

  return <Route {...rest} component={ enhanceRouter({...rest})(protectedRouters(asyncComponent(Component))) } />
};

lazyRouteProtectedName.defaultProps = {
  name      : '',
  nameDrawer: '',
  propsRoot : {}
};

export default lazyRouteProtectedName;