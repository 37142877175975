import * as endpoints from '@App/constants/endpoints';
import interceptor from '../interceptor';

import { get, del, post, put } from '../util';

const FILTER = 'filter';
const FORM = 'form'; 
const PATIENTS = 'patients';
const DATA = 'data';
const CORELINES = 'corelines';
const GROUP = 'group';

//#region GET
export const getFilters = async ({ header }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

export const getFilterById = async ({ header, filterId }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}/${filterId}`; 
  const axios = interceptor(header);
  return await get(axios, pathUrl);
};

//#region POST
export const createFilter = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}`; 
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const getDataByPatientIdsCorelines = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}/${PATIENTS}/${DATA}/${CORELINES}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const filterPatients = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}/${PATIENTS}/${DATA}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};

export const getDataGroupPatients = async ({ header, params }) => {
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}/${PATIENTS}/${GROUP}/${DATA}`;
  const axios = interceptor(header);
  return await post(axios, pathUrl, params);
};
//#endregion

//#region PUT
export const updateFilter = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}`; 
  const axios = interceptor(header);
  return await put(axios, pathUrl, params);
};

//#region DELETE
export const deleteFilter = async ({ header, params }) => { 
  const pathUrl = `${endpoints.API_MEDICALRECORD}/${FILTER}`;
  const axios = interceptor(header);
  return await del(axios, pathUrl, params);
};
//#endregion
