export default ({
  isFirstLoading: true,
  fetching: {
    getAccountConfig: false,
    putAccountConfig: false,
    delAccountConfig: false,
    postAccountConfig: false
  },
  api: {
    accountMedicalRecordsModel: {
      medicalRecordsModelId: 0,
      accountMedicalRecordsModelId: 0,
      accountId: 0,
      userIdCreated: 0,
      sections: [],
      createdAt: ''
    },
    accountConfig: {
      forceUseDefaultMedicalRecordsModel: false,
      forceWorkspaceContext: false,
      isMultiprofessional: false
    },
    accountSharingControlUsersConfig: {
      /**
       * @type {Object[]} private
       * @property {number} private[].userId
       * @property {string} private[].name
       * @property {(?string)} private[].sharingId
       */
      private: [],
      /**
       * @type {Number[]} sharing
      */
      sharing: []
    }
  }
});