import uuidv1     from 'uuid/v1';
import * as types from './constants';

const position = ({
  'top-right': {
    vertical    : 'top',
    horizontal  : 'right'
  },
  'top-left': {
    vertical    : 'top',
    horizontal  : 'left'
  },
  'bottom-right': {
    vertical    : 'bottom',
    horizontal  : 'right'
  },
  'bottom-left': {
    vertical    : 'bottom',
    horizontal  : 'left'
  }
});

const INITIAL_STATE = {
  done    : false,
  message : '',
  anchorOrigin: position['bottom-right'],
  autoHideDuration: 1500,
  idMessage: '',
  variant: 'default'
};

const SnackBarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SNACKBAR_SHOW: {
      const {message, variant, placement, autoHideDuration, ...rest} = action.payload;
      let _variant          = variant || 'default';
      let _autoHideDuration = (_variant === 'default') ? 1500 : autoHideDuration;

      return {
        ...state,
        done: true,
        idMessage: uuidv1(),
        message,
        variant: _variant,
        anchorOrigin: position[placement] || position['bottom-right'],
        autoHideDuration: _autoHideDuration,
        ...rest
      };
    }
    case types.SNACKBAR_HIDE: {
      return {
        ...state,
        done: false
      };
    }
    default:
      return state;
  }
};

export default SnackBarReducer;